import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { offersOperations, offersSelectors } from '../../../store/offers';
import { Container, EmptyListWithImage, Spinner } from '../../ReusableComponents';
import strings from '../../../localization';
import OffersList from './components/OffersList';
import { ModalsService } from '../../../services';
import NavigationService from '../../../services/NavigationService';
import screens from '../../../navigation/screens';
import modalTypes from '../../../constants/modalTypes';
import SellingOffer from './components/SellingOffer';
import { SELLING } from '../../../constants/offerTypes';

const ArchivedSellingOffers = ({
  archivedOffers,
  archivedOfferLoadersInfo,
  route,
  navigation,
  getArchivedOffers,
  unarchiveOffers,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);
  const [isArchiveLoading, setArchiveLoading] = useState(false);
  const [selectedOffersIds, setSelectedOfferIds] = useState([]);
  const isEditMode = route.params?.isEditMode ?? false;

  useEffect(() => {
    onFetchOffers();
    navigation.setParams({
      selectedOffersLength: 0,
    });
  }, []);

  useEffect(() => {
    navigation.setParams({
      onDonePress: () => {
        setSelectedOfferIds([]);
        navigation.setParams({ isEditMode: false, selectedOffersLength: 0 });
      },
      onUnarchivePress: () => {
        setSelectedOfferIds([]);
        navigation.setParams({ isEditMode: false, selectedOffersLength: 0 });
        setArchiveLoading(true);
        unarchiveOffers({ selectedOffersIds });
        setArchiveLoading(false);
      },
    });
  }, [selectedOffersIds]);

  const onSelectItem = ({ itemId, offerId }) => {
    if (!isEditMode) {
      NavigationService.navigate(screens.ItemDetails, { itemId });
      return;
    }
    let newSelectedOfferIds = null;

    if (R.includes(offerId, selectedOffersIds)) {
      newSelectedOfferIds = selectedOffersIds.filter((element) => element !== offerId);
    } else {
      newSelectedOfferIds = [...selectedOffersIds, offerId];
    }

    setSelectedOfferIds(newSelectedOfferIds);
    navigation.setParams({ selectedOffersLength: newSelectedOfferIds.length });
  };

  const onFetchOffers = async () => {
    await getArchivedOffers();
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await getArchivedOffers();
    setRefreshing(false);
  };

  const onLoadMoreArchivedOffer = () => {
    getArchivedOffers(true);
  };

  const onPerformRefundBySeller = async (offerId) => {
    ModalsService.showSwipeableModal(modalTypes.REFUND, {
      offerId,
    });
  };

  const onOpenChat = (chatId) => {
    NavigationService.push(screens.ChatRoom, { chatId });
  };

  const onOpenCreateNewItem = () => {
    NavigationService.navigate(screens.EditItemStack);
  };

  return (
    <Container>
      {isArchiveLoading && <Spinner />}
      <OffersList
        data={archivedOffers}
        isRefreshing={isRefreshing}
        isLoading={archivedOfferLoadersInfo.isLoading}
        isLoadingMore={archivedOfferLoadersInfo.isLoadingMore}
        onLoadMore={onLoadMoreArchivedOffer}
        onRefresh={onRefresh}
        ListEmptyComponent={() => (
          <EmptyListWithImage
            image="empty_state_selling_delivery"
            text={strings.emptyLists.empty_selling_pending_delivery_offers_list}
            buttonTitle={strings.emptyLists.upload_an_item}
            onPress={onOpenCreateNewItem}
          />
        )}
        renderItem={({ item }) => (
          <SellingOffer
            onSelectItem={onSelectItem}
            selectedOffersIds={selectedOffersIds}
            offerType={SELLING}
            offer={item}
            onOpenChat={onOpenChat}
            onPerformRefundBySeller={onPerformRefundBySeller}
          />
        )}
      />
    </Container>
  );
};

export default connect(
  (state) => ({
    archivedOffers: offersSelectors.getArchivedOffers(state),
    archivedOfferLoadersInfo: state.offers.sellingOffers.archived,
  }),
  (dispatch) => ({
    getArchivedOffers: (isLoadMore) => dispatch(offersOperations.getArchivedOffers({ isLoadMore })),
    unarchiveOffers: ({ selectedOffersIds }) =>
      dispatch(offersOperations.unarchiveOffers({ selectedOffersIds })),
  }),
)(ArchivedSellingOffers);
