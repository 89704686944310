import React from 'react';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { colors, dimensions, reusableStyles } from '../../styles';

export default ({ name, color, size, style, withoutRTLScale }) => (
  <FeatherIcon
    name={name}
    color={color || colors.gray}
    style={[!withoutRTLScale && reusableStyles.rtlScaleStyle, style]}
    size={size || dimensions.iconSize}
  />
);
