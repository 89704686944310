import React, { Fragment, memo } from 'react';
import { StyleSheet, Image } from 'react-native';
import reactStringReplace from 'react-string-replace';
import { useSelector } from 'react-redux';
import { ItemAvatar, Text, TouchableItem, AppImage, View } from '../../../../ReusableComponents';
import { getTimeForNotification } from '../../../../../utils/dateHelper';
import { leftToRightIsolate } from '../../../../../utils/stringHelper';
import { colors, dimensions, rs } from '../../../../../styles';
import uuid from 'uuid/v4';
import { isWeb } from '../../../../../utils/detectDevice';
import strings from '../../../../../localization';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: dimensions.medium,
    borderBottomWidth: isWeb ? 0.5 : 0,
  },
  descriptionContainer: {
    flex: 1,
    marginRight: dimensions.medium,
  },
  mainContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingRight: dimensions.medium,
  },
  avatarContainer: {
    backgroundColor: colors.transparent,
  },
  avatarImage: {
    height: 42,
    width: 42,
    marginHorizontal: dimensions.medium,
    alignItems: 'center',
    borderRadius: 21,
  },
  backgroundColor: {
    backgroundColor: isWeb ? '#e9eaec' : colors.grayLighter,
  },
});

const NotificationItem = memo(({ notification, onPress, language }) => {
  const {
    title,
    message,
    icon,
    created_at,
    is_opened,
    is_read,
    action_description,
    message_params,
  } = notification;
  const { item_image } = action_description;

  let replacedText = message;

  const theme = useSelector((state) => state.communityTheme.theme);

  message_params.forEach((el) => {
    replacedText = reactStringReplace(replacedText, el, (match, i) => (
      <Text semiBold key={uuid()} color={colors.gray}>
        {match}
      </Text>
    ));
  });

  return (
    <TouchableItem
      style={[s.contentContainer, !is_opened ? s.backgroundColor : {}]}
      onPress={() => onPress(notification)}
      useOpacity={false}
    >
      <Fragment>
        {!!item_image ? (
          <View style={rs.paddingHorizontal}>
            <ItemAvatar uri={item_image} />
          </View>
        ) : (
          <AppImage
            style={s.avatarImage}
            containerStyle={s.avatarContainer}
            source={{
              uri:
                theme === strings.main.app_name_sharingood.toLowerCase() ? 'logo_sharingood' : icon,
            }}
          />
        )}

        <View style={s.mainContainer}>
          <View style={s.descriptionContainer}>
            {/*<Text semiBold medium>*/}
            {/*  {title}*/}
            {/*</Text>*/}
            <Text color={colors.gray}>{replacedText}</Text>
            <Text xsmall color={colors.gray}>
              {getTimeForNotification(created_at)}
            </Text>
            {/* {language === 'he' && ( */}
            {/*  <Text style={s.infoText} color={colors.gray} xsmall> */}
            {/*     {getTimeForChatItem(created_at)} */}
            {/*  </Text> */}
            {/* )} */}
          </View>
        </View>
      </Fragment>
    </TouchableItem>
  );
});

export default NotificationItem;
