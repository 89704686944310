import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ButtonContainer, ContentContainer, Description, MainContainer, styles } from './styles';
import Button from '../../Buttons/Button';
import strings from '../../../../localization';
import { colors, dimensions, rs } from '../../../../styles';
import { Text } from '../../../ReusableComponents';
import Image from '../../Image';
import { getCurrentTheme } from '../../../../templates';

const s = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    height: 110,
    width: 110,
    borderRadius: 55,
    position: 'absolute',
    top: -45,
    alignItems: 'center',
    paddingTop: dimensions.medium,
  },
  image: {
    height: 64,
    width: 64,
  },
});

const Alert = ({ onPress, params }) => {
  const currentTheme = getCurrentTheme();
  return (
    <ContentContainer>
      <View style={s.mainContainer}>
        <Image source={{ uri: 'round_logo' }} style={s.image} />
      </View>
      <MainContainer>
        <Text
          style={[
            rs.marginTop,
            styles.title,
            {
              color: currentTheme.colors.secondaryLight,
            },
          ]}
          semiBold
        >
          {params.title ?? strings.common.confirm}
        </Text>
        <Description>{params.description}</Description>

        <ButtonContainer>
          <Button
            withMarginRight={!!params.onSubmit}
            title={params.cancelButtonTitle ?? strings.common.back}
            onPress={onPress}
          />
          {params.onSubmit && (
            <Button
              title={params.confirmButtonTitle ?? strings.common.confirm}
              onPress={() => {
                params.onSubmit();
                onPress();
              }}
            />
          )}
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default Alert;
