import React from 'react';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { ContentContainer, AdminAvatar, MainContainer, ButtonContainer } from './styles';
import strings from '../../../../localization';
import { colors, rs } from '../../../../styles';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { getCurrentTheme } from '../../../../templates';

const UserIsNotMember = ({ onPress }) => {
  const currentTheme = getCurrentTheme();
  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />
      <MainContainer>
        <Text
          large
          semiBold
          alignCenter
          color={currentTheme.colors.secondaryLight}
          style={rs.bigMarginBottom}
        >
          {strings.not_community_member.not_member_of_any_community_title}
        </Text>

        <Text medium alignCenter style={rs.paddingVertical}>
          {strings.not_community_member.not_member_of_any_community_description}
        </Text>

        <ButtonContainer>
          <Button
            withMarginRight
            title={strings.emptyLists.go_to_marketplace}
            onPress={() => {
              onPress();
              NavigationService.navigate(screens.FeedTab);
            }}
          />
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default UserIsNotMember;
