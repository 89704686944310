import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../../styles';
import { isRTL } from '../../../../utils/rtlHelper';
import { isAndroid, isIphoneX } from '../../../../utils/detectDevice';

const SLIDE_TOP_PADDING = dimensions.height * 0.08;
const IMAGE_WIDTH = dimensions.width * 0.9;
const IMAGE_HEIGHT = IMAGE_WIDTH * 0.76;
const BOTTOM_BUTTONS_PADDING = isIphoneX ? 120 : 50;

export default StyleSheet.create({
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
    marginBottom: 40,
  },
  pagination: {
    top: SLIDE_TOP_PADDING + IMAGE_HEIGHT + 170,
    bottom: undefined,
    flexDirection: isRTL && isAndroid ? 'row-reverse' : 'row',
  },
  dot: {
    borderWidth: 1,
    borderColor: colors.text,
  },
  buttonsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    bottom: BOTTOM_BUTTONS_PADDING,
    position: 'absolute',
  },
  nextButton: {
    paddingHorizontal: dimensions.doubleMedium,
  },
  getStartedButton: {
    height: 40,
    width: 160,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    position: 'absolute',
    bottom: BOTTOM_BUTTONS_PADDING - 10,

    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,

    elevation: 1,
  },
  slide: {
    flex: 1,
    paddingTop: SLIDE_TOP_PADDING,
    alignItems: 'center',
  },
  descriptionText: {
    lineHeight: 22,
  },
});
