import React, { useMemo, useRef, useEffect, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  useSharedValue,
  withTiming,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { Portal } from '@gorhom/portal';
import BottomSheet, { BottomSheetScrollView, BottomSheetBackdrop } from '@gorhom/bottom-sheet';
import strings from '../../../../../../localization';
import { NavigationService } from '../../../../../../services';
import screens from '../../../../../../navigation/screens';
import { Separator, Text, TouchableItem } from '../../../../../ReusableComponents';
import { dimensions, colors, rs } from '../../../../../../styles';
import { getPromotionInfo } from '../../../../../../store/promotion/selectors';
import { getCurrentTheme } from '../../../../../../templates';

const s = StyleSheet.create({
  container: {
    backgroundColor: colors.promotionBackground,
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.medium,
    flexDirection: 'row',
    alignItems: 'center',
  },
  seeMoreText: {
    textDecorationLine: 'underline',
  },
  progressBarOuter: {
    width: '60%',
    alignSelf: 'center',
    height: 30,
    backgroundColor: colors.grayLight,
    borderRadius: 10,
    overflow: 'hidden',
  },
  progressBarInner: {
    height: 30,
    backgroundColor: colors.promotionBackground,
  },
});

const AnimatedTouchableItem = Animated.createAnimatedComponent(TouchableOpacity);

const PromotionInfo = () => {
  const bottomSheetRef = useRef(null);
  const snapPoints = useMemo(() => ['30%'], []);
  const promotionInfo = useSelector(getPromotionInfo);
  const progress = useSharedValue(0);

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    if (promotionInfo.userProgress && promotionInfo.maxPointsBack) {
      progress.value = withTiming(
        (promotionInfo.userProgress / promotionInfo.maxPointsBack) * 100,
        { duration: 1000 },
      );
    }
  }, [promotionInfo]);

  const progressBarStyle = useAnimatedStyle(
    () => ({
      width: `${progress.value}%`,
      backgroundColor: promotionInfo.color || colors.promotionBackground,
    }),
    [promotionInfo],
  );

  const renderBackdrop = useCallback(
    (props) => <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} />,
    [],
  );

  const onButtonPress = () => {
    bottomSheetRef.current?.expand();
  };

  const onFAQPress = () => {
    bottomSheetRef.current?.forceClose({ duration: 100 });
    NavigationService.navigate(screens.PromotionFAQ);
  };

  const hasProgressBar = promotionInfo.maxPointsBack > 0;

  return (
    <Fragment>
      <AnimatedTouchableItem
        style={[
          s.container,
          { backgroundColor: promotionInfo.color || colors.promotionBackground },
        ]}
        onPress={onButtonPress}
        activeOpacity={0.8}
        entering={FadeIn.duration(600)}
        exiting={FadeOut}
      >
        <View style={[rs.row, rs.alignBaseline]}>
          <Text color={colors.grayLightest} style={rs.smallMarginRight} semiBold small>
            {strings.points_back.info_text}
          </Text>
          <Text style={s.seeMoreText} color={colors.grayLightest} medium>
            {strings.common.see_more}
          </Text>
        </View>
      </AnimatedTouchableItem>

      <Portal>
        <BottomSheet
          ref={bottomSheetRef}
          snapPoints={snapPoints}
          index={-1}
          renderBackdrop={renderBackdrop}
          enablePanDownToClose
          keyboardBehavior="interactive"
          keyboardBlurBehavior="restore"
          backdropComponent={renderBackdrop}
          animateOnMount
        >
          <BottomSheetScrollView contentContainerStyle={[rs.paddingHorizontal]}>
            <Text color={colors.text} style={rs.bigPaddingTop} title semiBold alignCenter>
              {hasProgressBar
                ? strings.formatString(
                    strings.points_back.you_earned_with_max,
                    promotionInfo.userProgress,
                    promotionInfo.maxPointsBack,
                  )
                : strings.formatString(strings.points_back.you_earned, promotionInfo.userProgress)}
            </Text>
            {hasProgressBar && (
              <View style={[s.progressBarOuter, rs.bigMarginVertical]}>
                <Animated.View style={[s.progressBarInner, progressBarStyle]} />
              </View>
            )}

            <View style={rs.marginVertical}>
              <Separator />
            </View>

            <TouchableItem onPress={onFAQPress}>
              <Text color={colors.gray} alignCenter>
                {strings.points_back.for_more_information}{' '}
                <Text semiBold color={currentTheme.colors.secondary}>
                  {strings.points_back.press_here}
                </Text>
              </Text>
            </TouchableItem>
          </BottomSheetScrollView>
        </BottomSheet>
      </Portal>
    </Fragment>
  );
};

export default PromotionInfo;
