import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { FontIcon, GoalIcon, Text, TouchableItem } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import rewardAmauntTypes from '../../../../../constants/rewardAmauntTypes';
import { colors, dimensions, rs } from '../../../../../styles';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginVertical: dimensions.halfMedium,
    borderRadius: 4,
    ...rs.shadow,
  },
  mainContainer: {
    flex: 1,
    paddingRight: dimensions.medium,
    paddingVertical: dimensions.medium,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
  },
  infoText: {
    marginVertical: 4,
  },
  icon: {
    paddingHorizontal: dimensions.medium,
  },
});

const RewardItem = memo(
  ({
    amountType,
    title,
    text,
    achieved,
    number,
    onPress,
    max_reward_amount,
    given_reward_amount,
    tokenName,
  }) => {
    const achievedText = strings.notifications.earned.replace('XX', given_reward_amount);

    const currentTheme = getCurrentTheme();

    return (
      <TouchableItem style={s.contentContainer} disabled={!onPress} onPress={onPress}>
        <Fragment>
          <FontIcon
            style={s.icon}
            color={achieved ? currentTheme.colors.secondary : colors.text}
            size={32}
            name="gift-2"
          />

          <View style={s.mainContainer}>
            <Text bold>{title}</Text>
            <Text style={s.infoText}>{text}</Text>
            {achieved ? (
              <Text bold color={currentTheme.colors.secondaryLight}>
                {given_reward_amount}{' '}
                <Text bold color={currentTheme.colors.secondaryLight}>
                  {achievedText}
                </Text>
              </Text>
            ) : (
              <Text color={currentTheme.colors.secondaryLight}>
                {amountType === rewardAmauntTypes.flexible
                  ? strings.notifications.earn_up_to
                  : strings.notifications.earn}{' '}
                <Text color={currentTheme.colors.secondaryLight}>{max_reward_amount}</Text>{' '}
                {strings.bank.tokens}
              </Text>
            )}
          </View>
        </Fragment>
      </TouchableItem>
    );
  },
);

export default RewardItem;
