import React, { Component } from 'react';
import { View } from 'react-native';
import MapView, { Circle, Marker } from 'react-native-maps';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import * as R from 'ramda';
import LoadingService from '../../../../services/LoadingService';
import MapCenter from '../../../../assets/web/images/map_marker.png';
import {
  ButtonContainer,
  CancelButton,
  ApplyButton,
  CancelText,
  ApplyText,
} from '../../LocationScreen/styles';
import { colors } from '../../../../styles';
import { GOOGLE_AUTOCOMPLETE_API_KEY } from '../../../../../config/google';
import strings from '../../../../localization';
import { isIos } from '../../../../utils/detectDevice';
import { isRTL } from '../../../../utils/rtlHelper';
import { getCurrentTheme } from '../../../../templates';

const formatCoordinate = (coordinate) => {
  // seventh decimal is worth up to 11mm of location accuracy
  const COORDINATE_ACCURACY = 7;
  return parseFloat(coordinate.toFixed(COORDINATE_ACCURACY));
};

const subLocalityTypes = ['sublocality_level_1', 'sublocality', 'political'];
const localityTypes = ['locality', 'political'];
const administrativeAreaTypes = ['administrative_area_level_2', 'political'];

export const getCityName = (result) => {
  const administrativeArea = R.find(R.propEq('types', administrativeAreaTypes))(result)?.long_name;
  const subLocality = R.find(R.propEq('types', subLocalityTypes))(result)?.long_name;
  const locality = R.find(R.propEq('types', localityTypes))(result)?.long_name;

  if (locality && !subLocality && !administrativeArea) {
    return locality;
  } else if (locality && subLocality) {
    return `${locality}, ${subLocality}`;
  } else if (locality && administrativeArea) {
    return locality;
  } else if (administrativeArea && !subLocality) {
    return administrativeArea;
  } else if (administrativeArea && subLocality) {
    return `${administrativeArea}, ${subLocality}`;
  }
};

class LocationView extends Component {
  state = {
    latitude: null,
    longitude: null,
    city: '',
  };

  setCoordinates = async (details, data) => {
    const {
      route: {
        params: { coordinates },
      },
    } = this.props;

    const city = coordinates ? coordinates.city : getCityName(details?.address_components);
    const latitude = coordinates ? coordinates.latitude : details?.geometry?.location?.lat;
    const longitude = coordinates ? coordinates.longitude : details?.geometry?.location?.lng;
    const formattedAddress = coordinates
      ? coordinates.formatted_address
      : details?.formatted_address ?? '';

    this.setState({
      latitude: formatCoordinate(latitude),
      longitude: formatCoordinate(longitude),
      city,
      formatted_address: formattedAddress,
    });
  };

  applyLocation = async () => {
    const { route } = this.props;

    LoadingService.showLoader();

    const selectLocations = route.params.selectLocations;
    const locationToSave = this.state;
    await selectLocations(locationToSave);

    LoadingService.hideLoader();
  };

  cancel = () => {
    this.props.navigation.pop();
  };

  componentDidMount() {
    const { route } = this.props;

    if (route.params?.coordinates) {
      this.setCoordinates();
    }
  }

  render() {
    const { latitude, longitude, city } = this.state;
    const { route } = this.props;

    const currentTheme = getCurrentTheme();

    return (
      <View style={{ flex: 1 }}>
        <MapView
          style={{
            height: '100%',
            zIndex: 0,
          }}
          region={{
            latitude: latitude || 31.0461,
            longitude: longitude || 34.8516,
            latitudeDelta: 0.05,
            longitudeDelta: 0.05,
          }}
          initialRegion={{
            latitude: 31.0461,
            longitude: 34.8516,
            latitudeDelta: 10,
            longitudeDelta: 10,
          }}
        >
          {longitude ? (
            <Circle
              center={{
                latitude,
                longitude,
                latitudeDelta: 0.05,
                longitudeDelta: 0.05,
              }}
              strokeColor="rgb(84,189,249)"
              fillColor="rgba(158,206,234,0.5)"
              radius={2000}
            />
          ) : null}
          {longitude ? (
            <Marker
              coordinate={{
                latitude,
                longitude,
                latitudeDelta: 0.05,
                longitudeDelta: 0.05,
              }}
              image={MapCenter}
            />
          ) : null}
        </MapView>
        {!route.params.coordinates && (
          <GooglePlacesAutocomplete
            placeholder={strings.other.search_by_city}
            minLength={2}
            autoFocus={false}
            returnKeyType="search"
            fetchDetails
            listViewDisplayed={false}
            renderDescription={(row) => row.description}
            onPress={(data, details = null) => {
              this.setCoordinates(details, data);
            }}
            query={{
              key: GOOGLE_AUTOCOMPLETE_API_KEY,
              language: 'en',
            }}
            GooglePlacesSearchQuery={{
              rankby: 'distance',
            }}
            styles={{
              textInputContainer: {
                width: '100%',
                zIndex: 999,
              },
              listView: {
                maxHeight: 200,
                backgroundColor: '#fff',
                zIndex: 1000,
              },
              description: {
                fontWeight: 'bold',
                zIndex: 999,
              },
              predefinedPlacesDescription: {
                color: 'black',
                backgroundColor: '#fff',
                zIndex: 999,
              },
              textInput: {
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                height: '100%',
                borderRadius: 0,
                ...(isIos && isRTL ? { textAlign: 'right' } : {}),
              },
              container: {
                zIndex: 999,
                position: 'absolute',
                width: '100%',
              },
            }}
            currentLocation={false}
            currentLocationLabel="Current location"
            nearbyPlacesAPI="GooglePlacesSearch"
            filterReverseGeocodingByTypes={['locality']}
            debounce={200}
          />
        )}
        <ButtonContainer>
          <CancelButton onPress={this.cancel}>
            <CancelText>{strings.common.cancel}</CancelText>
          </CancelButton>
          <ApplyButton
            style={city !== '' ? { backgroundColor: currentTheme.colors.secondary } : null}
            onPress={this.applyLocation}
          >
            <ApplyText style={city !== '' ? { color: colors.white } : null}>
              {strings.common.apply}
            </ApplyText>
          </ApplyButton>
        </ButtonContainer>
      </View>
    );
  }
}

export default LocationView;
