import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { PortalProvider } from '@gorhom/portal';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigCatProvider, createConsoleLogger, LogLevel, PollingMode } from 'configcat-react';
import { store, persistor } from '../store';
import screens from './screens';
import {
  FilterService,
  LoadingService,
  ModalsService,
  NavigationService,
  ToastsService,
} from '../services';
import { appOperations } from '../store/app';
import { authOperations } from '../store/auth';
import UnauthorizedUserStack from './stacks/UnauthorizedUserStack';
import {
  FilterBottomSheet,
  RootModal,
  RootLoaderWeb,
  Toast,
} from '../components/ReusableComponents';
import SuspendedUserStack from './stacks/SuspendedUserStack';
import AuthorizedUserDrawer from './stacks/AuthorizedUserDrawer';
import { CONFIG_CAT_DEV_KEY, CONFIG_CAT_PROD_KEY } from '../../config/configcat';
import { ENV } from '../../config/env';

const queryClient = new QueryClient();

const linking = {
  prefixes: ['http://localhost*', 'https://app-dev.shareittapp.com/'],
  config: {
    screens: {
      [screens.AuthorizedApplication]: {
        path: '/app',
        screens: {
          [screens.SignIn]: 'login',
          [screens.CodeVerification]: 'code_verification',
          [screens.Home]: 'home',
          [screens.ItemDetails]: 'item',
          [screens.Wishes]: 'wishes',
          [screens.MarketplaceTab]: 'marketplace',
          [screens.UserProfile]: 'user',
          [screens.Inbox]: 'chats',
          [screens.ChatRoom]: 'chat',
          [screens.Menu]: 'menu',
          [screens.PendingItems]: 'menu/pending_items',
          [screens.Settings]: 'settings',
          [screens.Support]: 'support',
          [screens.Location]: 'location',
          [screens.AchievedRewardDetails]: 'achieved_reward_details',
          [screens.EarnTokens]: 'earn_tokens',
          [screens.Rewards]: 'rewards',
          [screens.MapWithUserLocations]: 'map',
          [screens.SuspendedUser]: '*',
        },
      },
      NotFound: '*',
    },
  },
};

const App = () => {
  const dispatch = useDispatch();
  const { isAuthorized, isSuspended } = useSelector((state) => state.auth);
  const [isCheckingAuthorization, setCheckingAuthorization] = React.useState(true);

  React.useEffect(() => {
    dispatch(appOperations.setupLocalization());
    checkAuthorization();
  }, []);

  const checkAuthorization = async () => {
    await setCheckingAuthorization(true);
    await dispatch(authOperations.checkAuthorization());
    await setCheckingAuthorization(false);
  };

  return (
    <NavigationContainer linking={linking} ref={(ref) => NavigationService.init(ref)}>
      {!isCheckingAuthorization && (
        <>
          {isSuspended ? (
            <SuspendedUserStack />
          ) : isAuthorized ? (
            <AuthorizedUserDrawer />
          ) : (
            <UnauthorizedUserStack />
          )}
        </>
      )}
    </NavigationContainer>
  );
};

const AppWithStore = () => {
  const logger = createConsoleLogger(LogLevel.Info);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConfigCatProvider
          sdkKey={ENV === 'production' ? CONFIG_CAT_PROD_KEY : CONFIG_CAT_DEV_KEY}
          options={{ logger, pollIntervalSeconds: 10 }}
          pollingMode={PollingMode.AutoPoll}
        >
          <QueryClientProvider client={queryClient}>
            <PortalProvider>
              <ActionSheetProvider>
                <App />
              </ActionSheetProvider>

              <RootModal ref={(ref) => ModalsService.init(ref)} />
              <Toast ref={(ref) => ToastsService.init(ref)} />
              <FilterBottomSheet ref={(ref) => FilterService.init(ref)} />
              <RootLoaderWeb ref={(ref) => LoadingService.init(ref)} />
            </PortalProvider>
          </QueryClientProvider>
        </ConfigCatProvider>
      </PersistGate>
    </Provider>
  );
};

export default AppWithStore;
