import { StyleSheet } from 'react-native';
import { dimensions, colors } from '../../../../styles';

export default StyleSheet.create({
  primaryButtonContainer: {
    backgroundColor: colors.white,
    borderColor: colors.grayLight,
  },
  squareButtonContainer: {
    borderRadius: 5,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 40,
    marginHorizontal: 4,
    height: 28,
    paddingLeft: dimensions.halfMedium,
    borderRadius: 14,
    borderWidth: 1,
  },
  icon: {
    paddingHorizontal: dimensions.halfMedium,
    paddingTop: 2,
  },
});
