import React, { Fragment, memo } from 'react';
import { Portal } from '@gorhom/portal';
import { StyleSheet, View, ImageBackground, TouchableOpacity, ScrollView } from 'react-native';
import { connect } from 'react-redux';
import T from 'prop-types';
import debounce from 'debounce';
import { colors, dimensions, rs } from '../../../../styles';
import styles, { BUTTON_ITEM_HEIGHT } from './styles';
import Text from '../../Text';
import TouchableItem from '../../TouchableItem';
import Image from '../../Image';
import CheckBox from '../../CheckBox';
import strings from '../../../../localization';
import { isIos, isIphoneX } from '../../../../utils/detectDevice';

const Button = isIos ? TouchableItem : TouchableOpacity;

const s = StyleSheet.create({
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  container: {
    position: 'absolute',
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: dimensions.borderRadius,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 400,
    maxWidth: 1000,
  },
});

const Item = memo(({ isSelected, title, iconUri, onPress }) => (
  <Button style={styles.panelButton} onPress={onPress}>
    <Fragment>
      <View style={rs.row}>
        {!!iconUri && (
          <ImageBackground
            style={styles.itemImageBackground}
            source={{ uri: 'itemAvatarPlaceholder' }}
            resizeMode="cover"
            imageStyle={styles.itemImage}
          >
            <Image
              style={styles.itemImage}
              source={{ uri: iconUri }}
              resizeMode="cover"
            />
          </ImageBackground>
        )}

        <View style={[rs.row, rs.alignCenter]}>
          <Text>{title}</Text>
        </View>
      </View>
      <CheckBox
        disabled
        outerColor={colors.blueLighter}
        innerColor={colors.blueLighter}
        checked={isSelected}
        outerSize={21}
        filterSize={18}
        innerSize={14}
      />
    </Fragment>
  </Button>
));

Item.propTypes = {
  isSelected: T.bool,
};

class CommunityFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      options: [],
      optionKeyName: '',
      optionTitleName: '',
      iconName: '',
      selectedOptionIds: [],
      filterTitle: '',
      isMultipleSelection: true,
    };
  }

  componentDidCatch(...params) {
    console.warn('---------------BOOYAKA------------');
    console.warn(params);
  }

  renderInner = () => {
    const { options, optionKeyName, optionTitleName, iconName, selectedOptionIds } = this.state;
    return (
      <ScrollView style={styles.panel}>
        {options.map((option) => {
          const isSelected = selectedOptionIds.includes(option[optionKeyName]);

          return (
            <Item
              key={option[optionKeyName]}
              title={option[optionTitleName]}
              iconUri={option[iconName]}
              isSelected={isSelected}
              onPress={() => this.onSelect(option[optionKeyName])}
            />
          );
        })}
        {isIphoneX && <View style={styles.iphoneXBottomPadding} />}
      </ScrollView>
    );
  };

  renderHeader = () => {
    const { filterTitle, isMultipleSelection } = this.state;
    return (
      <View style={styles.panelHeaderWeb}>
        {isMultipleSelection && (
          <View style={styles.panelContainer}>
            <View />
            <TouchableItem
              onPress={() => {
                this.hide();
              }}
            >
              <Text medium semiBold color={colors.blue}>
                {strings.common.done}
              </Text>
            </TouchableItem>
          </View>
        )}
        <View style={styles.panelContainer}>
          <Text large semiBold>
            {filterTitle}
          </Text>
          {isMultipleSelection && (
            <TouchableItem onPress={this.onResetSelectedOptions}>
              <Text medium light color={colors.blue}>
                {strings.list_filters.clear_all}
              </Text>
            </TouchableItem>
          )}
        </View>
      </View>
    );
  };

  show = ({
    options,
    optionKeyName,
    optionTitleName,
    iconName,
    selectedOptionIds,
    onChange,
    filterTitle = strings.filters.filter_by_groups,
    isMultipleSelection = true,
  }) => {
    this.setState({
      isVisible: true,
      options,
      optionKeyName,
      optionTitleName,
      iconName,
      selectedOptionIds,
      filterTitle,
      isMultipleSelection,
    });

    this.onChange = onChange;
  };

  hide = () => {
    this.setState({ isVisible: false });
  };

  onSelect = debounce(
    (optionId) => {
      const { selectedOptionIds, isMultipleSelection } = this.state;

      let newSelectedOptionIds = [];

      if (isMultipleSelection) {
        newSelectedOptionIds = selectedOptionIds.includes(optionId)
          ? selectedOptionIds.filter((el) => el !== optionId)
          : [...selectedOptionIds, optionId];
      } else {
        newSelectedOptionIds = [optionId];
        this.hide();
      }

      this.setState({ selectedOptionIds: newSelectedOptionIds });
      this.onChange(newSelectedOptionIds);
    },
    300,
    true,
  );

  onResetSelectedOptions = () => {
    this.setState({ selectedOptionIds: [] });
    this.onChange([]);
  };

  render() {
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <Portal name="filterService">
        <TouchableItem onPress={this.hide} style={StyleSheet.absoluteFillObject}>
          <View style={s.backdrop} onPress={this.hide}>
            <View style={s.container}>
              {this.renderHeader()}
              {this.renderInner()}
            </View>
          </View>
        </TouchableItem>
      </Portal>
    );
  }
}
export default connect(null, null, null, { forwardRef: true })(CommunityFilter);
