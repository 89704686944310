import { ENV } from './env';

let rootCollectionName = '';

if (ENV === 'local') {
  // rootCollectionName = 'communities-local';
  rootCollectionName = 'communities-staging';
}

if (ENV === 'development') {
  rootCollectionName = 'communities-dev';
}

if (ENV === 'staging') {
  rootCollectionName = 'communities-staging';
}

if (ENV === 'production') {
  rootCollectionName = 'communities-prod';
}

export const ROOT_COLLECTION_NAME = rootCollectionName;

export const config = {
  apiKey: 'AIzaSyA9AQq6TKWsXQWVjDKIMFtbIhcYy35nLCw',
  authDomain: 'react-firebase-9d942.firebaseapp.com',
  databaseURL: 'https://react-firebase-9d942.firebaseio.com',
  projectId: 'react-firebase-9d942',
  storageBucket: 'react-firebase-9d942.appspot.com',
  messagingSenderId: '936456617497',
};
