import React, { memo } from 'react';
import { StyleSheet, Image, FlatList } from 'react-native';
import { View, Text, TouchableItem } from '../../../../../ReusableComponents';
import { dimensions, rs, colors } from '../../../../../../styles';
import strings from '../../../../../../localization';

const s = StyleSheet.create({
  contentContainer: {},
  listContentContainer: {
    paddingHorizontal: dimensions.halfMedium,
  },
  itemContainer: {
    flexDirection: 'row',
    marginHorizontal: dimensions.halfMedium,
    marginBottom: dimensions.medium,
    width: 220,
  },
  itemImage: {
    height: 70,
    width: 70,
    borderRadius: 3,
  },
  imageStroke: {
    borderWidth: dimensions.hairline,
    borderColor: colors.grayLight,
  },
  infoContainer: {
    paddingHorizontal: dimensions.medium,
    justifyContent: 'center',
    maxWidth: 200,
  },
});

const Item = memo(({ items, onSelect }) => {
  return (
    <View>
      {items.map((item) => (
        <TouchableItem onPress={() => onSelect(item)} style={s.itemContainer} key={item.id}>
          <Image
            source={{ uri: item.image || 'city_placeholder' }}
            style={[s.itemImage, item.image && s.imageStroke]}
            resizeMode="cover"
          />
          <View style={s.infoContainer}>
            <Text semiBold>{item.name}</Text>
            <Text style={rs.verySmallPaddingTop}>
              {item.active_items_count} {strings.items.items}
            </Text>
          </View>
        </TouchableItem>
      ))}
    </View>
  );
});

const HorizontalCitiesList = memo(({ data, onSelect, refToScroll }) => {
  return (
    <View style={s.contentContainer}>
      <FlatList
        ref={refToScroll}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={s.listContentContainer}
        // data={userInfoRewards}
        data={data}
        keyExtractor={(item) => item[0].id.toString()}
        renderItem={({ item }) => <Item items={item} onSelect={onSelect} />}
      />
    </View>
  );
});

export default HorizontalCitiesList;
