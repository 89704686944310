import * as R from 'ramda';
import uuid from 'uuid/v4';
import * as actions from './actions';
import chatApi from '../../api/chats';
import io from '../../api/io';
import { normalize } from '../../utils/stateHelper';
import delay from '../../utils/delay';
import chatTypes from '../../constants/chatTypes';
import { CHATS_LIMIT, MESSAGES_LIMIT } from '../../constants/listLimits';
import { LoadingService, NavigationService, ToastsService } from '../../services';
import strings from '../../localization';
import screens from '../../navigation/screens';
import { offersOperations, offersSelectors } from '../offers';
import { communitySelectors } from '../communityInfo';
import { appOperations } from '../app';
import { getIsTypingMessageByChatId, getMessagesList } from './selectors';
import { Alert } from 'react-native';
import { isWeb } from '../../utils/detectDevice';

let typingMessageTimeout = null;

const getAllChats = (isLoadMore) => async (dispatch, getState) => {
  const state = getState();

  const isAdmin = communitySelectors.getIsUserAdmin(state);
  const types = [chatTypes.BUY_CHAT, chatTypes.SELL_CHAT, chatTypes.PERSONAL_CHAT];

  if (!isAdmin) {
    types.push(chatTypes.SUPPORT);
  }

  await dispatch(getChats(types, 'allChats', isLoadMore));
};

const getBuyChats = (isLoadMore) => async (dispatch) => {
  await dispatch(getChats([chatTypes.BUY_CHAT], 'buyChats', isLoadMore));
};

const getSellChats = (isLoadMore) => async (dispatch) => {
  await dispatch(getChats([chatTypes.SELL_CHAT], 'sellChats', isLoadMore));
};

const getPersonalChats = (isLoadMore) => async (dispatch) => {
  await dispatch(getChats([chatTypes.PERSONAL_CHAT], 'personalChats', isLoadMore));
};

const getSearchedChats = ({ groupIds, types, isLoadMore = false, searchTerm = '' }) => async (
  dispatch,
) => {
  await dispatch(getChats(types, 'searchedChats', isLoadMore, groupIds, searchTerm));
};

const getArchivedChats = (communityIds, types, isLoadMore = false) => async (dispatch) => {
  await dispatch(getChats(types, 'archivedChats', isLoadMore, communityIds));
};

export const getChats = (
  types,
  entityName,
  isLoadMore = false,
  groupIds = [],
  searchTerm = '',
) => async (dispatch, getState) => {
  const { chats } = getState();
  const { isLoadingMore, isExistMore, chatIds } = chats[entityName];

  if (isLoadingMore || (isLoadMore && !isExistMore)) return;

  try {
    dispatch(
      actions.chatsStart({
        entityName,
        isLoadMore,
      }),
    );

    const skip = isLoadMore ? chatIds.length : 0;

    const data = await chatApi.getChatsByFilters({
      searchTerm,
      types,
      groupIds,
      skip,
      isOnlyArchivedChats: entityName === 'archivedChats',
    });

    const formattedChats = data.map((element) => ({
      ...element.chat,
      last_message_id: R.pathOr(null, ['last_message', 'id'], element),
    }));

    const formattedMessages = data.map((element) => element.last_message);

    const normalizedChats = normalize(formattedChats, 'chatIds', 'chatEntities');

    const normalizedMessages = normalize(formattedMessages, 'messageIds', 'messageEntities');

    dispatch(
      actions.chatsSuccess({
        entityName,
        isLoadMore,
        chatIds: normalizedChats.chatIds,
        chatEntities: normalizedChats.chatEntities,
        messageEntities: normalizedMessages.messageEntities,
        isExistMore: data.length === CHATS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.chatsError({ entityName }));
  }
};

const getSupportChatsForAdmins = ({
  isLoadMore = false,
  searchTerm = '',
  isSearchScreen = false,
}) => async (dispatch, getState) => {
  const entityName = isSearchScreen ? 'searchedChats' : 'adminSupportChats';
  const { chats } = getState();
  const { isLoadingMore, isExistMore, chatIds } = chats[entityName];

  if (isLoadingMore || (isLoadMore && !isExistMore)) return;

  try {
    dispatch(
      actions.chatsStart({
        entityName,
        isLoadMore,
      }),
    );

    const skip = isLoadMore ? chatIds.length : 0;

    const data = await chatApi.getSupportChatsForAdmins({
      searchTerm,
      skip,
    });

    const formattedChats = data.map((element) => ({
      ...element.chat,
      last_message_id: R.pathOr(null, ['last_message', 'id'], element),
    }));

    const formattedMessages = data.map((element) => element.last_message);

    const normalizedChats = normalize(formattedChats, 'chatIds', 'chatEntities');

    const normalizedMessages = normalize(formattedMessages, 'messageIds', 'messageEntities');

    dispatch(
      actions.chatsSuccess({
        entityName,
        isLoadMore,
        chatIds: normalizedChats.chatIds,
        chatEntities: normalizedChats.chatEntities,
        messageEntities: normalizedMessages.messageEntities,
        isExistMore: data.length === CHATS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.chatsError({ entityName }));
  }
};

const getChatMessages = (chatId, isLoadMore = false) => async (dispatch, getState) => {
  const { chats } = getState();

  const { isLoadingMore, isExistMore, messageIds = [] } = R.pathOr(
    {},
    ['chatMessages', chatId],
    chats,
  );

  if (isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.messagesStart({ chatId, isLoadMore }));

  const skip = isLoadMore ? messageIds.length : 0;
  try {
    const messages = await chatApi.getMessages({ chatId, skip });

    const normalizedMessages = normalize(messages, 'messageIds', 'messageEntities');

    dispatch(
      actions.messagesSuccess({
        chatId,
        isLoadMore,
        isExistMore: messages.length === MESSAGES_LIMIT,
        ...normalizedMessages,
      }),
    );
  } catch (e) {}
};

const sendMessage = (chatId, payload, payloadType, extra = null) => async (dispatch, getState) => {
  const { userInfo, communities } = getState();
  const isImageMessage = payloadType === 'image';
  const messageId = uuid();

  try {
    dispatch(
      actions.setMessage({
        message: {
          id: messageId,
          chat_id: chatId,
          payload: {
            content: payload,
            content_type: payloadType,
            extra,
          },
          created_at: new Date(),
          sender_id: userInfo.id,
          sender_image: userInfo.profile_image,
          received: false,
        },
      }),
    );

    const imageUrl = !isWeb && isImageMessage ? await chatApi.uploadChatImage(payload) : null;

    const payloadToSend = isImageMessage ? imageUrl : payload;

    const message = await chatApi.sendMessage({
      chatId,
      messageId,
      payload: isWeb ? payload : payloadToSend,
      payloadType,
      extra,
    });

    dispatch(actions.updateMessage({ message: { ...message, received: true } }));
  } catch (e) {
    if (R.path(['response', 'status'], e) === 403) {
      dispatch(actions.removeMessage({ chatId, messageId }));
      ToastsService.showError(strings.error_messages.error);
    }
  }
};

const sendMessageWithWishConnection = (chatId, payload, payloadType, wishId) => async (
  dispatch,
  getState,
) => {
  const { userInfo, communities } = getState();
  const messageId = uuid();

  try {
    const message = await chatApi.sendMessage({ chatId, messageId, payload, payloadType, wishId });

    await delay(300);
    dispatch(
      actions.setMessage({
        message: {
          id: messageId,
          chat_id: chatId,
          payload: {
            content: payload,
            content_type: payloadType,
          },
          created_at: new Date(),
          sender_id: userInfo.id,
          sender_image: userInfo.profile_image,
          received: false,
        },
      }),
    );
    dispatch(actions.updateMessage({ message: { ...message, received: true } }));
  } catch (e) {
    if (R.path(['response', 'status'], e) === 403) {
      ToastsService.showError(strings.error_messages.error);
    }
  }
};

export const getChatByItemId = (itemId) => async (dispatch, getState) => {
  dispatch(actions.chatInfoStart());
  try {
    const chat = await chatApi.getChatByItemId(itemId);

    if (!R.isEmpty(chat)) {
      dispatch(actions.addChatEntity({ chat }));
    }
    dispatch(actions.chatInfoSuccess());

    return chat;
  } catch (e) {
    dispatch(actions.chatInfoSuccess());
  }
};

export const getPersonalChatByUserId = (userId) => async (dispatch, getState) => {
  dispatch(actions.chatInfoStart());

  try {
    const chat = await chatApi.getPersonalChatByUserId(userId);

    if (!R.isEmpty(chat)) {
      dispatch(actions.addChatEntity({ chat }));

      // setTimeout(() => dispatch(actions.resetTemporaryChat()), 1000);
    }
    dispatch(actions.chatInfoSuccess());

    return chat;
  } catch (e) {
    dispatch(actions.chatInfoSuccess());
  }
};

export const createPersonalChat = (userId, payload, payloadType, wishId) => async (
  dispatch,
  getState,
) => {
  const { userInfo } = getState();

  try {
    const messageId = uuid();

    const chat = await chatApi.createPersonalChat({
      userId,
      messageId,
      payload,
      payloadType,
      wishId,
    });

    const message = {
      id: messageId,
      chat_id: chat.id,
      payload: {
        content: payload,
        content_type: payloadType,
      },
      created_at: new Date(),
      sender_id: userInfo.id,
      sender_image: userInfo.profile_image,
    };

    /** 3 chat tabs logic */
    // dispatch(actions.addNewChat({ chat, message, entityName: 'personalChats' }));
    dispatch(actions.addNewChat({ chat, message, entityName: 'allChats' }));

    return chat;
  } catch (e) {
    if (R.path(['response', 'status'], e) === 403) {
      ToastsService.showError(strings.error_messages.error);
    }
  }
};

export const createItemChat = (itemId, payload, payloadType) => async (dispatch, getState) => {
  const { userInfo } = getState();
  try {
    const messageId = uuid();

    const message = {
      id: messageId,
      chat_id: `TEMPORARY_CHAT_ID_${itemId}`,
      payload: {
        content: payload,
        content_type: payloadType,
      },
      created_at: new Date(),
      sender_id: userInfo.id,
      sender_image: userInfo.profile_image,
      received: false,
    };

    dispatch(actions.setMessage({ message }));

    const chat = await chatApi.createItemChat({ itemId, messageId, payload, payloadType });

    dispatch(
      actions.addNewChat({
        chat,
        message: {
          ...message,
          chat_id: chat.id,
        },
        /** 3 chat tabs logic */
        // entityName: 'buyChats',
        entityName: 'allChats',
      }),
    );

    return chat;
  } catch (e) {}
};

export const openItemChat = (item) => async (dispatch, getState) => {
  const state = getState();

  const offer = offersSelectors.getBuyingOfferByItemId(state, item.id);

  const chat = {
    id: `TEMPORARY_CHAT_ID_${item.id}`,
    item_id: item.id,
    item_title: item.title,
    item_image: item.item_image,
    group_id: item.group_id,
    seller_id: item.seller_id,
    interlocutor_id: item.seller_id,
    interlocutor_image: item.seller_image,
    interlocutor_name: item.seller_name,
    offer_id: R.propOr(null, 'id', offer),
    type: 'item',
  };

  dispatch(actions.addChatEntity({ chat }));

  NavigationService.push(screens.ChatRoom, {
    chatId: `TEMPORARY_CHAT_ID_${item.id}`,
  });
};

export const openItemChatFromOffer = (offer) => async (dispatch, getState) => {
  const { userInfo } = getState();

  const isCurrentUseBuyer = userInfo.id === Number(offer.buyer_id);

  const chat = {
    id: `TEMPORARY_CHAT_ID_${offer.item_id}`,
    item_id: offer.item_id,
    item_title: offer.item_name,
    item_image: offer.item_image,
    group_id: offer.group_id,
    seller_id: offer.seller_id,

    interlocutor_id: isCurrentUseBuyer ? offer.seller_id : offer.buyer_id,
    interlocutor_image: isCurrentUseBuyer ? offer.seller_image : offer.buyer_image,
    interlocutor_name: isCurrentUseBuyer ? offer.seller_name : offer.buyer_name,
    offer_id: offer.id,
    type: 'item',
  };

  dispatch(actions.addChatEntity({ chat }));

  NavigationService.push(screens.ChatRoom, {
    chatId: `TEMPORARY_CHAT_ID_${offer.item_id}`,
  });
};

export const openPersonalChat = (user, initialMessage = null) => async (dispatch, getState) => {
  const chat = {
    id: `TEMPORARY_CHAT_ID_${user.userId}`,

    interlocutor_id: user.userId,
    interlocutor_image: user.profileImage,
    interlocutor_name: user.selectedUserName,
    type: 'personal',
  };

  dispatch(actions.addChatEntity({ chat }));

  NavigationService.push(screens.ChatRoom, {
    chatId: `TEMPORARY_CHAT_ID_${user.userId}`,
    wishId: user.wishId,
    initialMessage,
  });
};

export const openSupportChat = () => async (dispatch) => {
  LoadingService.showLoader();
  try {
    const chat = await chatApi.getSupportChat();

    dispatch(actions.addChatEntity({ chat }));
    LoadingService.hideLoader();

    NavigationService.push(screens.ChatRoom, {
      chatId: chat.id,
    });
  } catch (e) {
    LoadingService.hideLoader();
  }
};

export const getChatById = (chatId, isShowLoader = false) => async (dispatch, getState) => {
  if (isShowLoader) {
    dispatch(actions.chatInfoStart());
  }

  try {
    const chat = await chatApi.getChatById(chatId);
    dispatch(actions.addChatEntity({ chat }));
    if (isShowLoader) {
      dispatch(actions.chatInfoSuccess());
    }
    if (chat.offer_id) {
      dispatch(offersOperations.updateOffer(chat.offer_id));
    }
  } catch (e) {
    if (isShowLoader) {
      dispatch(actions.chatInfoSuccess());
    }
  }
};

const onNewMessage = () => (dispatch, getState) => {
  try {
    io.onNewMessage(async (data) => {
      console.log('onNewMessage from SOCKET ----->>>>>>>>>>>>>>>', data);

      const store = getState();
      const { chats, userInfo } = store;
      const isAdmin = communitySelectors.getIsUserAdmin(store);
      const { allChats, adminSupportChats, insideChatRoomId } = chats;

      const message = R.path(['payload', 'message'], data);
      const { chat_id, is_read } = message;

      let chatInfo;

      const isListIncludesChat =
        allChats.chatIds.includes(Number(chat_id)) ||
        adminSupportChats.chatIds.includes(Number(chat_id));

      const isTypingMessage = getIsTypingMessageByChatId(getState(), message.chat_id);
      if (isTypingMessage) {
        clearTimeout(typingMessageTimeout);
        dispatch(actions.setUserTypingMessage({ chatId: message.chat_id, isTypingMessage: false }));
      }

      if (isListIncludesChat) {
        chatInfo = chats.chatEntities[chat_id];
        const isAdminSupportChat = isAdmin && chatInfo.type === 'support';

        dispatch(actions.setMessage({ message }));
        if (chats.insideChatRoomId !== chat_id && !is_read) {
          if (isAdminSupportChat) {
            dispatch(
              actions.increaseSupportChatUnreadCount({
                chatId: chat_id,
              }),
            );
          } else {
            dispatch(
              actions.increaseChatUnreadCount({
                chatId: chat_id,
              }),
            );
          }
        }
      } else {
        try {
          const chat = await chatApi.getChatById(message.chat_id);
          chatInfo = chat;
          // this check is actual until we don't have message history (sockets recovery)
          // as another event, if chat is already in list,
          // doesn't need to overwrite him and increase unread counter.
          // When chat history will be another event/request remove this part.
          const newState = getState();
          const isAlreadyInList = newState.chats.allChats.chatIds.includes(Number(chat.id));

          if (isAlreadyInList) {
            dispatch(actions.setMessage({ message }));
            return;
          }

          // if user is admin and chat is support, put it in adminSupportChats
          const entityName = isAdmin && chat.type === 'support' ? 'adminSupportChats' : 'allChats';

          dispatch(
            actions.addNewChat({
              chat,
              message,
              entityName,
            }),
          );

          if (chat.unread_count === 1) {
            if (isAdmin && chat.type === 'support') {
              dispatch(actions.increaseSupportChatsTotalUnreadCount());
            } else {
              dispatch(actions.increaseTotalUnreadCount());
            }
          } else if (chat.unread_count > 1) {
            // if chat came with unread count >= 1,
            // we don't know if it was 2 new messages (after last update total unread count)
            // or 1 old and 1 new message

            if (isAdmin && chat.type === 'support') {
              dispatch(getUnreadCountForAdminSupportChats());
            } else {
              dispatch(getUnreadCountForAllChats());
            }
          }
        } catch (e) {}
      }

      /** Show toast for chat message, avoid toast from another admins in the same chat */
      if (
        message.chat_id !== insideChatRoomId &&
        Number(chatInfo.interlocutor_id) === Number(message.sender_id)
      ) {
        ToastsService.showNotification({
          data,
          tokenIcon: message.sender_image,
          title: message.sender_name,
          body: message.payload.content,
          onPress: () =>
            NavigationService.push(screens.ChatRoom, {
              chatId: message.chat_id,
            }),
        });
      }

      if (chats.insideChatRoomId === message.chat_id) {
        try {
          if (isAdmin) {
            await chatApi.markAsReadForSupportAdmins(message.chat_id);
          } else {
            await chatApi.markAsRead(message.chat_id);
          }
        } catch (e) {}
      }
    });
  } catch (err) {}
};

const onMarkAsRead = () => (dispatch, getState) => {
  try {
    io.onMarkAsRead(async (data) => {
      console.log('onMarkAsRead from SOCKET ----->>>>>>>>>>>>>>>', data);
      const chatId = R.path(['payload', 'chat_id'], data);
      dispatch(actions.markChatAsRead({ chatId, isSupportChatForAdmin: true }));
    });
  } catch (err) {}
};

const onMarkMessageAsRead = () => (dispatch, getState) => {
  try {
    io.onMarkMessageAsRead(async (data) => {
      console.log('onMarkMessageAsRead from SOCKET ----->>>>>>>>>>>>>>>', data);

      const chatId = R.path(['payload', 'chat_id'], data);

      const patch = {};
      const chatMessages = getMessagesList(getState(), chatId);
      chatMessages.forEach((message) => {
        if (!message.read) {
          patch[message._id] = { is_read: true };
        }
      });

      dispatch(actions.markChatMessagesAsRead(patch));
    });
  } catch (error) {
    console.log(error);
  }
};

const onUserTypingMessage = () => (dispatch, getState) => {
  try {
    io.onTypingMessage(async (data) => {
      const state = getState();

      const chatId = R.path(['payload', 'chat_id'], data);
      const isChatExistsInRedux = !!state.chats.chatEntities[chatId];
      const isAlreadyTypingMessage = getIsTypingMessageByChatId(state, chatId);

      if (isChatExistsInRedux) {
        if (!isAlreadyTypingMessage) {
          dispatch(actions.setUserTypingMessage({ chatId, isTypingMessage: true }));
        }

        clearTimeout(typingMessageTimeout);
        typingMessageTimeout = setTimeout(() => {
          dispatch(actions.setUserTypingMessage({ chatId, isTypingMessage: false }));
        }, 8000);
      }
    });
  } catch (error) {
    console.log(error);
  }
};

const getUnreadCountForAllChats = () => async (dispatch, getState) => {
  try {
    const { unread_count } = await chatApi.getUnreadCountForAllChats();

    dispatch(actions.setTotalUnreadCount({ unreadCount: unread_count }));
  } catch (e) {}
};

const getUnreadCountForAdminSupportChats = () => async (dispatch, getState) => {
  try {
    const { unread_count } = await chatApi.getUnreadCountForAdminSupportChats();

    dispatch(actions.setSupportChatsTotalUnreadCount({ unreadCount: unread_count }));
  } catch (e) {}
};

// todo find better way when run this function
const markAsRead = (chatId) => async (dispatch, getState) => {
  const state = getState();

  const isAdmin = communitySelectors.getIsUserAdmin(state);

  const chat = R.pathOr({}, ['chats', 'chatEntities', chatId], state);
  // const entityName = isAdmin && chat.type === 'support' ? 'adminSupportChats' : 'allChats';
  const isSupportChatForAdmin = isAdmin && chat.type === 'support';

  if (R.propOr(0, 'unread_count', chat)) {
    dispatch(actions.markChatAsRead({ chatId, isSupportChatForAdmin }));
  }
  if (!chatId.toString().startsWith('TEMPORARY_CHAT_ID')) {
    try {
      if (isSupportChatForAdmin) {
        await chatApi.markAsReadForSupportAdmins(chatId);
      } else {
        await chatApi.markAsRead(chatId);
      }
    } catch (e) {}
  }
};

const sendMarkMessageAsRead = (chatId) => async () => {
  if (!chatId.toString().startsWith('TEMPORARY_CHAT_ID')) {
    await chatApi.sendMessageIsRead(chatId);
  }
};

const sendUserTypingMessage = (chatId) => async () => {
  if (!chatId.toString().startsWith('TEMPORARY_CHAT_ID')) {
    try {
      await chatApi.sendUserTypingMessage(chatId);
    } catch (error) {
      console.log(error);
    }
  }
};

const uploadChatImage = (image) => async () => {
  try {
    return await chatApi.uploadChatImage(image);
  } catch (e) {}
};

const archiveChats = (chatIds) => async (dispatch, getState) => {
  try {
    dispatch(actions.archiveChats({ chatIds }));
    const a = await chatApi.archiveChats({ chatIds });
  } catch (e) {}
};

const unarchiveChats = (chatIds) => async (dispatch, getState) => {
  try {
    dispatch(actions.unarchiveChats({ chatIds }));
    const a = await chatApi.unarchiveChats({ chatIds });
    dispatch(appOperations.fetchChatsData());
  } catch (e) {}
};

export default {
  getAllChats,
  getBuyChats,
  getSellChats,
  getPersonalChats,
  getSearchedChats,
  getArchivedChats,
  getSupportChatsForAdmins,
  getChatMessages,
  sendMessage,
  sendMessageWithWishConnection,
  sendUserTypingMessage,
  sendMarkMessageAsRead,

  getChatByItemId,
  getPersonalChatByUserId,

  createPersonalChat,
  createItemChat,

  openItemChat,
  openItemChatFromOffer,
  openPersonalChat,
  openSupportChat,

  getChatById,

  setInsideRoomId: actions.setInsideRoomId,
  resetSearchedChats: actions.resetSearchedChats,
  resetTemporaryChat: actions.resetTemporaryChat,

  onNewMessage,
  onMarkAsRead,
  onMarkMessageAsRead,
  onUserTypingMessage,

  getChats,
  markAsRead,
  getUnreadCountForAllChats,
  getUnreadCountForAdminSupportChats,
  uploadChatImage,
  archiveChats,
  unarchiveChats,
};
