import React from 'react';
import { connect } from 'react-redux';
import { communityOperations } from '../../../store/communityInfo';
import { Container } from '../../ReusableComponents';
import MainTab from './MainTab';

const Home = ({ getUserCommunity }) => {
  const onFetchData = async () => {
    await getUserCommunity();
  }

  React.useEffect(() => {
    onFetchData();
  }, []);
  return (
    <Container>
      <MainTab />
    </Container>
  );
};

export default connect(
  (state) => ({
    isLoading: state.notifications.isLoading,
  }),
  (dispatch) => ({
    getUserCommunity: () => dispatch(communityOperations.getUserCommunity()),
  }),
)(Home);
