import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { View, StyleSheet } from 'react-native';
import { mobileSize } from '../../../../styles/dimensions';
import Button from '../../Buttons/Button';
import { ContentContainer, AdminAvatar, MainContainer, styles } from './styles';
import strings from '../../../../localization';
import { rs } from '../../../../styles';
import { getCurrentTheme } from '../../../../templates';
import { Text } from '../../../ReusableComponents';

const marginSize = mobileSize ? 5 : 10;
const iconSize = mobileSize ? 44 : 52;

const Share = ({ onPress, params }) => {
  const currentTheme = getCurrentTheme();
  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />
      <MainContainer style={rs.alignCenter}>
        <Text
          style={[
            styles.title,
            {
              color: currentTheme.colors.secondaryLight,
            },
          ]}
          semiBold
        >
          {params.title ?? strings.invite_friends.share_link_for}
        </Text>

        <View style={[rs.row, rs.alignCenter]}>
          <FacebookShareButton
            style={{ marginRight: marginSize, marginLeft: marginSize }}
            url={params.link}
          >
            <FacebookIcon size={iconSize} round={true} />
          </FacebookShareButton>
          <TelegramShareButton style={{ marginRight: marginSize }} url={params.link}>
            <TelegramIcon size={iconSize} round={true} />
          </TelegramShareButton>
          <EmailShareButton style={{ marginRight: marginSize }} body={'Shareitt'} url={params.link}>
            <EmailIcon size={iconSize} round={true} />
          </EmailShareButton>
          <WhatsappShareButton style={{ marginRight: marginSize }} url={params.link}>
            <WhatsappIcon size={iconSize} round={true} />
          </WhatsappShareButton>
          <LinkedinShareButton style={{ marginRight: marginSize }} url={params.link}>
            <LinkedinIcon size={iconSize} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton style={{ marginRight: marginSize }} url={params.link}>
            <TwitterIcon size={iconSize} round={true} />
          </TwitterShareButton>
        </View>

        <View style={s.buttonContainer}>
          <Button title={strings.common.cancel} onPress={onPress} />
        </View>
      </MainContainer>
    </ContentContainer>
  );
};

const s = StyleSheet.create({
  buttonContainer: {
    width: '50%',
    height: 40,
    marginTop: 40,
    flexDirection: 'row',
  },
});

export default Share;
