import React from 'react';
import * as RNLocalize from 'react-native-localize';
import { WebView } from 'react-native-webview';
import {
  TERMS_AND_CONDITIONS_URL_EN,
  TERMS_AND_CONDITIONS_URL_HE,
  TERMS_AND_CONDITIONS_URL_BR,
  TERMS_AND_CONDITIONS_URL_DE,
} from '../../../../api/urls';
import { HIDE_SHAREITT_SITE_HEADER_JS } from '../../../../constants/webview';

export const getTermsAndConditionsUrl = (languageCode) => {
  switch (languageCode) {
    case 'pt':
    case 'br':
      return TERMS_AND_CONDITIONS_URL_BR;
    case 'he':
      return TERMS_AND_CONDITIONS_URL_HE;
    case 'de':
      return TERMS_AND_CONDITIONS_URL_DE;
    default:
      return TERMS_AND_CONDITIONS_URL_EN;
  }
};

const TermsAndConditions = () => {
  const languageCode = RNLocalize.getLocales()[0].languageCode;
  const sourceUrl = getTermsAndConditionsUrl(languageCode);

  return (
    <WebView
      source={{ uri: sourceUrl }}
      injectedJavaScript={HIDE_SHAREITT_SITE_HEADER_JS}
      // onMessage is necessary to set to handle injected javascript
      // for more information:
      // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#injectedjavascript
      onMessage={() => {}}
    />
  );
};

export default TermsAndConditions;
