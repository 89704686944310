/* eslint-disable react/destructuring-assignment,react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as R from 'ramda';
import { Alert, Keyboard, View } from 'react-native';
import { withConfigCatClient } from 'configcat-react';
import { lotsOperations } from '../../../../store/lots';
import { filterBadWords } from '../../../../utils/stringHelper';
import {
  Button,
  Separator,
  ItemDetailsButton,
  TouchableItem,
  Radio,
} from '../../../ReusableComponents';
import { ImagesSelector, TitleInput, DescriptionInput } from './components';
import s, { PostButtonContainer } from './styles';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { colors, dimensions, rs } from '../../../../styles';
import { groupsSelectors } from '../../../../store/groups';
import { getAppLanguage } from '../../../../store/app/selectors';
import screens from '../../../../navigation/screens';
import { LoadingService, ModalsService, ToastsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import ruleTypes from '../../../../constants/ruleTypes';
import Title from './components/Title';
import withSelectedGroupId from './SelectedCommunityContainer';
import Text from '../../../ReusableComponents/Text';
import { isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';
import { ITEM, ITEM_NUMBER, TALENT, TALENT_NUMBER } from '../../../../constants/lotTypes';
import { subCommunityOperations, subCommunitySelectors } from '../../../../store/subCommunities';
import { formatCommunityName } from '../../../../utils/formatCommunityName';
import { SUB_COMMUNITIES_KEY } from '../../../../constants/configcat';

class EditWish extends Component {
  state = {
    isEditFlow: false,

    id: '',
    title: '',
    description: '',
    images: ['', '', '', ''],
    imagesWeb: ['', '', '', ''],

    option: '',

    size: '',
    selectedCategory: {
      parentCategory: null,
      childCategory: null,
    },
    selectedSubCommunities: [],
    isShowSubCommunitiesValue: false,
  };

  async componentDidMount() {
    const { navigation, route } = this.props;

    navigation.setParams({
      onSubmit: this.onSubmit,
    });

    this.setupFeatureFlag();

    const wish = route.params?.wish;

    if (wish) {
      this.setPropsFromWish(wish);
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const newState = this.state;
    const newProps = this.props;

    if (oldProps?.lastUpdated !== this.props.lastUpdated) {
      this.setupFeatureFlag();
    }

    if (
      !R.equals(oldState.title, newState.title) ||
      !R.equals(oldState.description, newState.description) ||
      !R.equals(oldState.selectedCategory, newState.selectedCategory)
    ) {
      this.checkValidation();
    }

    // if (!R.equals(oldProps.groupInfo.group_id, newProps.groupInfo.group_id)) {
    //   if (!newProps.groupInfo.has_wishes) {
    //     newProps.setSelectedCommunityId(newProps.communitiesWithWishes[0].group_id);
    //   }
    //
    //   this.onItemRulesApproveCheck();
    // }
  }

  setupFeatureFlag() {
    const { getUserSubCommunities } = this.props;

    const IS_SHOW_SUB_COMMUNITIES_KEY = formatCommunityName(
      SUB_COMMUNITIES_KEY,
      this.props.communityInfo.name,
    );

    this.props.getValue(IS_SHOW_SUB_COMMUNITIES_KEY, false).then((isShowSubCommunitiesValue) => {
      this.setState({ isShowSubCommunitiesValue });

      if (isShowSubCommunitiesValue) {
        getUserSubCommunities();
      }
    });
  }

  setPropsFromWish = async (wish) => {
    const { setSelectedGroupId, route } = this.props;

    const images = route.params.images;

    const imgs = this.state.images;
    images.forEach((el, id) => {
      imgs[id] = el;
    });
    imgs.length = 4;

    const wishLocations = route.params.locations || [];
    const locations = wishLocations.map((el) => ({ ...el, isItemLocation: true }));

    this.setState({
      isEditFlow: true,
      ...wish,
      wishLocations: locations,
      images: imgs,
      imagesWeb: imgs,
      title: wish.title,
      description: wish.description,
      id: wish.id,
      isPayForShipping: wish.pay_for_shipping,
      isPayForBoxit: wish.pay_for_boxit,
      size: wish.size,
      selectedCategory: {
        parentCategory: {
          id: R.propOr(null, 'parent_category_id', wish),
          name: R.propOr(null, 'parent_category_name', wish),
        },
        childCategory: {
          id: R.propOr(null, 'category_id', wish),
          name: R.propOr(null, 'category_name', wish),
        },
      },
    });

    setTimeout(() => {
      setSelectedGroupId(wish.group_id);
    }, 100);
  };

  onOpenHomWishes = () => {
    NavigationService.navigate(screens.Wishes);
    ToastsService.showSuccess(strings.success_messages.wish_uploaded_successfully);
  };

  onSubmit = async () => {
    const { navigation, editWish, createNewWish, selectedGroupId, route } = this.props;

    const {
      title,
      description,
      images,
      imagesWeb,
      size,
      selectedCategory,
      id,
      selectedSubCommunities,
    } = this.state;

    if (route.params?.isValidate ?? false) {
      navigation.setParams({ isValidate: false }); // disable create same items
    } else {
      return;
    }

    Keyboard.dismiss();

    const submitWish = id ? editWish : createNewWish;

    LoadingService.showSuccessLoader();

    await submitWish({
      wishId: id,
      title,
      description,
      images: isWeb ? imagesWeb : images,
      category_id: R.pathOr(null, ['childCategory', 'id'], selectedCategory),
      size,
      group_id: selectedGroupId,
      item_type_id: this.state.option === TALENT ? TALENT_NUMBER : ITEM_NUMBER,
      sub_community_ids: selectedSubCommunities.map(({ id }) => id),
    });

    LoadingService.hideSuccessLoader({
      callback: () => {
        console.log('TEST _______________');
        id ? NavigationService.goBack() : this.onOpenHomWishes();
      },
    });
  };

  onInputChangesHandler = (value, stateName) => {
    this.setState((state, props) => ({
      [stateName]: props.appLanguage === 'en' ? filterBadWords(value) : value,
    }));
  };

  setImage = (images) => {
    this.setState({ images });
    this.checkValidation();
  };

  setWebImages = (imagesWeb) => {
    this.setState({ imagesWeb });
  };

  selectSize = (selectedSize) => {
    const { size } = this.state;

    this.setState({ size: selectedSize === size ? null : selectedSize });
  };

  onSetShipping = (shipping) => {
    const { locations, isPayForShipping, isPayForBoxit } = shipping;
    this.setState({ wishLocations: locations, isPayForShipping, isPayForBoxit });
    setTimeout(() => this.checkValidation(), 0);
  };

  onSelectSubCommunities = (subCommunities) => {
    this.setState({ selectedSubCommunities: subCommunities });
    NavigationService.goBack();
  };

  onOpenSubCommunityTags = () => {
    const { selectedSubCommunities } = this.state;

    NavigationService.navigate(screens.MultipleSubCommunitySelect, {
      selectedSubCommunities,
      onSubmit: this.onSelectSubCommunities,
    });
  };

  checkValidation = () => {
    const { navigation } = this.props;
    const {
      title,
      description,
      images,
      selectedCategory,
      // wishLocations,
      // isPayForBoxit,
      // isPayForShipping,
    } = this.state;

    navigation.setParams({
      isValidate:
        title.length > 0 &&
        description.length > 0 &&
        images.some((el) => !!el) &&
        !!R.path(['parentCategory', 'id'], selectedCategory),
      // (!!wishLocations.length || isPayForShipping || isPayForBoxit),
    });
  };

  onOpenRules = () => {
    ModalsService.showFullScreenModal(modalTypes.RULES, {
      type: ruleTypes.WISH_RULES,
    });
  };

  onItemRulesApproveCheck = () => {
    const { groupInfo } = this.props;
    if (!groupInfo.is_approved_wish_rules) {
      ModalsService.showFullScreenModal(modalTypes.RULES, {
        type: ruleTypes.WISH_RULES,
        communityId: groupInfo.group_id,
      });
    }
  };

  onSelectCategory = (selectedCategory) => {
    this.setState({
      selectedCategory,
    });
  };

  onOpenCategories = () => {
    const { groupInfo } = this.props;
    const { selectedCategory } = this.state;

    NavigationService.navigate(screens.Categories, {
      selectedCategoryId: R.path(['childCategory', 'id'], selectedCategory),
      categories: groupInfo.categories,
      onSelectCategory: this.onSelectCategory,
    });
  };

  onSelectGroup = (groupId) => {
    const { setSelectedGroupId } = this.props;
    const { selectedCategory } = this.state;

    if (selectedCategory.parentCategory) {
      if (isWeb) {
        ModalsService.showModal(modalTypes.ALERT, {
          title: strings.discounts.change_community_description,
          description: strings.lots.change_community_description,
          confirmButtonTitle: strings.common.ok,
          cancelButtonTitle: strings.common.cancel,
          onSubmit: () => {
            this.setState({
              selectedCategory: {
                parentCategory: null,
                childCategory: null,
              },
            });
            setSelectedGroupId(groupId);

            NavigationService.pop();
          },
        });
      } else {
        Alert.alert(
          strings.discounts.change_community_description,
          strings.lots.change_community_description,
          [
            {
              text: strings.common.ok,
              onPress: () => {
                this.setState({
                  selectedCategory: {
                    parentCategory: null,
                    childCategory: null,
                  },
                });
                setSelectedGroupId(groupId);

                NavigationService.pop();
              },
            },
            {
              text: strings.common.cancel,
              onPress: () => console.log('Cancel Pressed'),
              style: 'cancel',
            },
          ],
        );
      }
    } else {
      setSelectedGroupId(groupId);
      NavigationService.pop();
    }
  };

  onOpenGroups = () => {
    const { groupInfo, groupsWithWishes } = this.props;

    NavigationService.navigate(screens.Groups, {
      selectedGroupId: groupInfo.id,
      groups: groupsWithWishes,
      onSelectGroup: this.onSelectGroup,
    });
  };

  onSelectedRadioOption = (option) => {
    this.setState({ option });
  };

  render() {
    const { groupInfo, route, canTagSubCommunityToItems } = this.props;
    const {
      isEditFlow,
      imagesWeb,
      images,
      title,
      description,
      selectedCategory,
      selectedSubCommunities,
      isShowSubCommunitiesValue,
    } = this.state;

    const currentTheme = getCurrentTheme();

    const selectedSubCommunitiesValue = R.isEmpty(selectedSubCommunities)
      ? ''
      : selectedSubCommunities.length === 1
      ? selectedSubCommunities[0].name
      : strings.formatString(
          strings.lots.sub_community_multiple_tags,
          selectedSubCommunities[0].name,
          selectedSubCommunities.length - 1,
        );

    return (
      <KeyboardAwareScrollView
        extraScrollHeight={dimensions.medium}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
        style={{ backgroundColor: colors.grayLighter }}
      >
        <TouchableItem onPress={this.onOpenRules} style={s.rulesContainer}>
          <Text color={currentTheme.colors.secondary}>{strings.rules.wish_rules_title}</Text>
        </TouchableItem>

        <Title withPadding>{strings.lots.select_your_wish_type}</Title>
        <View style={s.containerRadio}>
          <Radio
            label="Item"
            isMarked={this.state.option === ITEM}
            onChangeMarked={() => this.onSelectedRadioOption(ITEM)}
            customStyle={{ marginBottom: 10 }}
          />
          <Radio
            label="Talent"
            isMarked={this.state.option === TALENT}
            onChangeMarked={() => this.onSelectedRadioOption(TALENT)}
          />
        </View>

        {isShowSubCommunitiesValue && canTagSubCommunityToItems && !isEditFlow && (
          <Fragment>
            <Title withPadding>{strings.lots.sub_community_tags_items}</Title>
            <ItemDetailsButton
              placeholder={strings.lots.sub_community_tags_placeholder_items}
              selectedValue={selectedSubCommunitiesValue}
              onPress={this.onOpenSubCommunityTags}
            />
          </Fragment>
        )}

        <ImagesSelector
          images={images}
          imagesWeb={imagesWeb}
          setImage={this.setImage}
          setWebImages={this.setWebImages}
        />

        <Title withPadding>{strings.lots.wish_description}</Title>

        <TitleInput
          value={title}
          placeholder={strings.lots.what_is_your_wish}
          onChangeText={(name) => this.onInputChangesHandler(name, 'title')}
        />
        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <DescriptionInput
          value={description}
          placeholder={strings.lots.describe_your_wish}
          onChangeText={(name) => this.onInputChangesHandler(name, 'description')}
        />

        <Title withPadding>{strings.lots.details}</Title>

        {!isEditFlow && (
          <Fragment>
            <ItemDetailsButton
              title={strings.lots.group}
              placeholder={strings.filters.select_group}
              selectedValue={groupInfo.group_name}
              onPress={this.onOpenGroups}
            />
            <View style={rs.paddingHorizontal}>
              <Separator />
            </View>
          </Fragment>
        )}

        <ItemDetailsButton
          title={strings.lots.category}
          placeholder={strings.filters.select_category}
          selectedValue={
            selectedCategory.parentCategory
              ? `${selectedCategory.parentCategory.name} : ${selectedCategory.childCategory.name}`
              : ''
          }
          onPress={this.onOpenCategories}
        />

        <PostButtonContainer style={isWeb && rs.marginBottom}>
          <Button
            disabled={!route.params?.isValidate ?? false}
            onPress={this.onSubmit}
            title={isEditFlow ? strings.common.save : strings.lots.create_lot_button}
          />
        </PostButtonContainer>
      </KeyboardAwareScrollView>
    );
  }
}

const EditWishWithSelectedCommunityId = withSelectedGroupId(
  connect(
    (state, { selectedGroupId }) => ({
      userInfo: state.userInfo,
      groupInfo: groupsSelectors.getGroupInfoById(state, selectedGroupId),
      communityInfo: state.communityInfo,

      groupsWithWishes: groupsSelectors.getCommunityGroupsWithWishes(state),

      savedLocations: state.shipping.locations,
      isLoadingManagementLot: state.lots.isLoadingManagementLot,
      appLanguage: getAppLanguage(state),
      canTagSubCommunityToItems: subCommunitySelectors.getCanTagSubCommunityToItems(state),
    }),
    (dispatch) => ({
      editWish: (args) => dispatch(lotsOperations.editWish(args)),
      createNewWish: (args) => dispatch(lotsOperations.createNewWish(args)),
      getUserSubCommunities: () => dispatch(subCommunityOperations.getUserSubCommunities()),
    }),
  )(EditWish),
);

export default withConfigCatClient(EditWishWithSelectedCommunityId);
