import React, { Fragment, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollView, View, TouchableOpacity } from 'react-native';
import Animated, { SlideInRight, SlideOutLeft } from 'react-native-reanimated';
import { Text, Container } from '../../ReusableComponents';
import s from './styles';
import { rs, colors } from '../../../styles';
import { NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import strings from '../../../localization';
import { chatsOperations } from '../../../store/chats';
import { getHasActivePromotion } from '../../../store/promotion/selectors';
import Image from '../../ReusableComponents/Image';

const AnimatedTouchableItem = Animated.createAnimatedComponent(TouchableOpacity);

const Item = memo(({ title, description, image, onSelect }) => (
  <AnimatedTouchableItem
    style={[s.itemContainer]}
    onPress={onSelect}
    entering={SlideInRight}
    exiting={SlideOutLeft}
  >
    <Fragment>
      <Image
        style={s.image}
        resizeMode="cover"
        source={{
          uri: image,
        }}
      />
      <View style={[rs.paddingHorizontal, rs.flex, rs.justifyCenter]}>
        <Text semiBold medium>
          {title}
        </Text>
        <Text color={colors.gray} style={rs.verySmallPaddingTop}>
          {description}
        </Text>
      </View>
    </Fragment>
  </AnimatedTouchableItem>
));

const HorizontalGroupsList = memo(() => {
  const dispatch = useDispatch();
  const hasActivePromotion = useSelector(getHasActivePromotion);

  const onOpenPromotionFAQ = () => {
    NavigationService.navigate(screens.PromotionFAQ);
  };

  const onOpenSupportChat = () => {
    dispatch(chatsOperations.openSupportChat());
  };
  const onOpenFAQ = () => {
    NavigationService.navigate(screens.FAQ);
  };
  const onOpenRules = () => {
    NavigationService.navigate(screens.CommunityRules);
  };
  const onOpenShippingInfo = () => {
    NavigationService.navigate(screens.ShippingInfo);
  };
  const onOpenGiftsInfo = () => {
    NavigationService.navigate(screens.GiftsInfo);
  };
  const onOpenPhotoTips = () => {
    NavigationService.navigate(screens.PhotoTips);
  };

  return (
    <Container>
      <Animated.ScrollView contentContainerStyle={s.listContentContainerStyle}>
        <Item
          title={strings.support.chat}
          description={strings.support.chat_description}
          image="all_you_need_to_know_in_10_secs"
          onSelect={onOpenSupportChat}
        />

        <Item
          title={strings.support.faq}
          description={strings.support.faq_description}
          image="faq"
          onSelect={onOpenFAQ}
        />

        <Item
          title={strings.rules.conduct_rules}
          description={strings.support.rules_description}
          image="community_rules"
          onSelect={onOpenRules}
        />

        <Item
          title={strings.home.tokens}
          description={strings.support.tokens_description}
          image="presents"
          onSelect={onOpenGiftsInfo}
        />

        <Item
          title={strings.home.shipping}
          description={strings.support.delivery_description}
          image="shipping"
          onSelect={onOpenShippingInfo}
        />

        <Item
          title={strings.home.image_pictures}
          description={strings.support.image_pictures_description}
          image="rules"
          onSelect={onOpenPhotoTips}
        />

        {hasActivePromotion && (
          <Item
            title={strings.support.promotion}
            description={strings.support.promotion_description}
            image="green_friday"
            onSelect={onOpenPromotionFAQ}
          />
        )}
      </Animated.ScrollView>
    </Container>
  );
});

export default HorizontalGroupsList;
