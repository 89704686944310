import { store } from '../store';
import { selectTheme } from '../store/communityTheme/selectors';

import { theme as shareittTheme } from './shareitt/theme';
import { theme as sharingoodTheme } from './sharingood/theme';

const themes = {
  shareitt: shareittTheme,
  sharingood: sharingoodTheme,
};

export function getCurrentTheme() {
  let currentTheme = selectTheme(store.getState());
  store.subscribe(() => {
    currentTheme = selectTheme(store.getState());
  });

  return themes[currentTheme];
}


export { themes }
