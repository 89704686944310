export default {
  /** STACKS */
  FilterStack: 'FilterStack',
  EditItemStack: 'EditItemStack',
  EditWishStack: 'EditWishStack',
  MainUserInfoStack: 'MainUserInfoStack',
  CitiesFilterStack: 'CitiesFilterStack',
  ApplyLegalDocumentsStack: 'ApplyLegalDocumentsStack',
  CampusEmailConfirmationStack: 'EmailConfirmationStack',
  GroupFilterStack: 'GroupFilterStack',
  WishFilterStack: 'WishFilterStack',
  DonatePointsStack: 'DonatePointsStack',
  UserPersonalInsightsStack: 'UserPersonalInsightsStack',
  EditTalentStack: 'EditTalentStack',

  /** MainSwitchNavigator * */

  LoadingApplication: 'LoadingApplication',
  AuthorizedApplication: 'AuthorizedApplication',
  UnauthorizedApplication: 'UnauthorizedApplication',
  SuspendedUser: 'SuspendedUser',
  ForceUpdate: 'ForceUpdate',
  ApplyLegalDocuments: 'ApplyLegalDocuments',

  /** UnauthorizedApplication * */

  OnboardingSlides: 'OnboardingSlides',
  SignIn: 'SignIn',
  CodeVerification: 'CodeVerification',
  EditUserInfo: 'EditUserInfo',
  EditNickname: 'EditNickname',
  AddBirthday: 'AddBirthday',
  AddGender: 'AddGender',
  CitiesFilter: 'CitiesFilter',

  /** AuthorizedApplication * */

  /* CreatingGroupNavigator */
  GroupsStack: 'GroupsStack',
  WelcomeScreen: 'WelcomeScreen',
  AddLocationScreen: 'AddLocationScreen',
  AddLocationGPSView: 'AddLocationGPSView',
  AddUserName: 'AddUserName',
  AddProfileImage: 'AddProfileImage',
  AddEmail: 'AddEmail',
  VerifyEmail: 'VerifyEmail',

  Rewards: 'Rewards',
  FAQ: 'FAQ',

  CommunitiesList: 'CommunitiesList',
  Contacts: 'Contacts',
  FeedSoldItems: 'FeedSoldItems',
  SoldItemDetails: 'SoldItemDetails',

  /* Modals (screens without TabBar in bottom) */

  ItemDetails: 'ItemDetails',
  SnapshotItemDetailsScreen: 'SnapshotItemDetailsScreen',
  UserProfileTabSnapshotItemDetails: 'UserProfileTabSnapshotItemDetails',
  FeedSnapshotItemDetails: 'FeedSnapshotItemDetails',
  FeedChatRoom: 'FeedChatRoom',
  BuyingOffersSearch: 'BuyingOffersSearch',
  SellingOffersSearch: 'SellingOffersSearch',
  WishFulfillmentOffers: 'WishFulfillmentOffers',
  SellingOffers: 'SellingOffers',
  ArchivedSellingOffers: 'ArchivedSellingOffers',
  BuyingOffers: 'BuyingOffers',
  Wishes: 'Wishes',
  Transactions: 'Transactions',
  Wallets: 'Wallets',
  CurrentUserItems: 'CurrentUserItems',
  UserItems: 'UserItems',
  TransactionDetails: 'TransactionDetails',
  Location: 'Location',
  CreateLocation: 'CreateLocation',
  EditItem: 'EditItem',
  EditWish: 'EditWish',
  WishDetails: 'WishDetails',
  Categories: 'Categories',
  MultipleCategories: 'MultipleCategories',
  Conditions: 'Conditions',
  RefundPolicies: 'RefundPolicies',
  MultipleConditions: 'MultipleConditions',
  Sizes: 'Sizes',
  MultipleSizes: 'MultipleSizes',
  Groups: 'Groups',
  PromoCode: 'PromoCode',
  Brands: 'Brands',
  MultipleBrands: 'MultipleBrands',
  ItemShipping: 'ItemShipping',
  ItemPickupLocations: 'ItemPickupLocations',
  WishShipping: 'WishShipping',
  DomesticShippingCalculation: 'DomesticShippingCalculation',

  Members: 'Members',
  RewardsScreen: 'RewardsScreen',
  InviteFriendsAbout: 'InviteFriendsAbout',
  InviteFriends: 'InviteFriends',
  EarnTokens: 'EarnTokens',
  Referrals: 'Referrals',

  Bank: 'Bank',
  IssueToken: 'IssueToken',
  IssueMemberList: 'IssueMemberList',
  SendTokensToBank: 'SendTokensToBank',
  UserProfile: 'UserProfile',
  CurrentUserProfile: 'CurrentUserProfile',
  Endorsements: 'Endorsements',
  MembersWhoEndorsed: 'MembersWhoEndorsed',
  AllBuyers: 'AllBuyers',

  SearchScreen: 'SearchScreen',
  WishesSearch: 'WishesSearch',

  /* TabNavigator */

  TabNavigator: 'TabNavigator',

  // Tabs
  FeedTab: 'FeedTab',
  DiscoveryTab: 'DiscoveryTab',
  MessagesTab: 'MessagesTab',
  MarketplaceTab: 'MarketplaceTab',
  WalletTab: 'WalletTab',
  HomeTab: 'HomeTab',
  UserProfileTab: 'UserProfileTab',
  NotificationsTab: 'NotificationsTab',

  // Modals

  AchievedRewardDetails: 'AchievedRewardDetails',
  NotAchievedRewardDetails: 'NotAchievedRewardDetails',
  BirthdayRewardDetails: 'BirthdayRewardDetails',

  // Screens
  Home: 'Home',
  Marketplace: 'Marketplace',
  GroupsList: 'GroupsList',
  Feed: 'Feed',
  Filter: 'Filter',
  Sorting: 'Sorting',
  FeedMembers: 'FeedMembers',
  Discovery: 'Discovery',
  Messages: 'Messages',
  Notifications: 'Notifications',
  SavedItems: 'SavedItems',
  PendingItems: 'PendingItems',
  Wallet: 'Wallet',
  Orders: 'Orders',
  Settings: 'Settings',
  Support: 'Support',
  Menu: 'Menu',
  Inbox: 'Inbox',
  ChatsSearchResults: 'ChatsSearchResults',
  AdminSupportChatsSearchResults: 'AdminSupportChatsSearchResults',
  ArchivedChats: 'ArchivedChats',
  TermsAndConditions: 'TermsAndConditions',
  PrivacyPolicy: 'PrivacyPolicy',
  AnalyticsPermission: 'AnalyticsPermission',
  CommunityRules: 'CommunityRules',
  ShippingInfo: 'ShippingInfo',
  ShortShareittInfo: 'ShortShareittInfo',
  GiftsInfo: 'GiftsInfo',
  PhotoTips: 'PhotoTips',
  PromotionFAQ: 'PromotionFAQ',
  ChatRoom: 'ChatRoom',
  UserLocations: 'UserLocations',
  FeedEditUserInfo: 'FeedEditUserInfo',
  Followers: 'Followers',
  SendNotifications: 'SendNotifications',
  Language: 'Language',
  TokensExchange: 'TokensExchange',
  AskToBuyTokensExchange: 'AskToBuyTokensExchange',
  ExchangeRules: 'ExchangeRules',
  LeaveCommunity: 'LeaveCommunity',
  MapWithUserLocations: 'MapWithUserLocations',
  UserTestimonial: 'UserTestimonial',
  HomeSearch: 'HomeSearch',
  ItemsSelectionScreen: 'ItemsSelectionScreen',
  AddCampusEmail: 'AddCampusEmail',
  VerifyCampusEmail: 'VerifyCampusEmail',
  NotificationTargetGroup: 'NotificationTargetGroup',
  NotificationAppAction: 'NotificationAppAction',
  WishGroupFilter: 'WishGroupFilter',
  CampusesFilter: 'CampusesFilter',
  FoundationsList: 'FoundationsList',
  DonatePoints: 'DonatePoints',
  UsersMap: 'UsersMap',
  RequiredReferralCode: 'RequiredReferralCode',
  MultipleSubCommunitySelect: 'MultipleSubCommunitySelect',

  /** SubCommunitiesStack **/
  MySubCommunitiesStack: 'MySubCommunitiesStack',
  MySubCommunitiesList: 'MySubCommunitiesList',
  JoinSubCommunityList: 'JoinSubCommunityList',
  JoinSubCommunityByCode: 'JoinSubCommunityByCode',
  
  /* UserPersonalInsightsStack */
  UserPersonalInsights: 'UserPersonalInsights',

  /* EditTalentStack */
  EditTalent: 'EditTalentScreen',
};
