import promotionsApi from '../../api/promotions';
import * as actions from './actions';
import { getCommunityId } from '../communityInfo/selectors';
import { ToastsService } from '../../services';
import strings from '../../localization';

export const getActivePointsBackPromotion = () => async (dispatch, getState) => {
  try {
    const communityId = getCommunityId(getState());
    const data = await promotionsApi.getActivePointsBackPromotion(communityId);

    if (!data) {
      dispatch(actions.resetPromotion());
      return;
    }

    dispatch(
      actions.setPromotion({
        id: data.id,
        startDate: data.start_date,
        endDate: data.end_date,
        percentBack: data.percent_back,
        userProgress: data.points_back,
        maxPointsBack: data.max_points_back,
        color: data.color,
      }),
    );
  } catch (error) {
    ToastsService.showError(strings.error_messages.error);
  }
};

export default { getActivePointsBackPromotion };
