import React from 'react';
import Button from '../../Buttons/Button';
import { MainContainer, Description, ButtonContainer, styles } from './styles';
import strings from '../../../../localization';
import CenteredView from '../../Containers/CenteredView';

import { NavigationService } from '../../../../services';
import { getCurrentTheme } from '../../../../templates';
import { Text } from '../../../ReusableComponents';

const Info = ({ onPress, params }) => {
  const currentTheme = getCurrentTheme();
  return (
    <CenteredView>
      <MainContainer>
        <Text
          style={[
            styles.title,
            {
              color: currentTheme.colors.secondaryLight,
            },
          ]}
          semiBold
        >
          {params.title}
        </Text>
        <Description>{params.description}</Description>

        <ButtonContainer>
          <Button
            title={strings.common.ok}
            onPress={() => {
              NavigationService.goBack();
              onPress();
            }}
          />
        </ButtonContainer>
      </MainContainer>
    </CenteredView>
  );
};

export default Info;
