import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const CustomSlider = ({ step, minimumValue, maximumValue, value, style, onValueChange }) => (
  <Slider
    step={step}
    style={{ style }}
    min={minimumValue}
    max={maximumValue}
    value={value}
    onChange={onValueChange}
    handleStyle={{
      width: 20,
      height: 20,
      top: 0,
    }}
  />
);

export default CustomSlider;
