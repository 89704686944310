import { createSelector } from 'reselect';
import { getIsCurrentDateInRange } from '../../utils/dateHelper';

export const getPromotionInfo = (state) => {
  return state.promotion;
};

export const getHasActivePromotion = createSelector([getPromotionInfo], (promotion) => {
  if (!promotion.id) {
    return false;
  }

  return getIsCurrentDateInRange({ start: promotion.startDate, end: promotion.endDate });
});

export default {
  getHasActivePromotion,
};
