import React from 'react';
import { View } from 'react-native';
import Text from '../Text';
import { colors, rs } from '../../../styles';
import strings from '../../../localization';
import TokenIcon from '../TokenIcon';
import Icon from '../Icon';
import TouchableItem from '../TouchableItem';
import s from './styles';
import { connect } from 'react-redux';
import { isWeb } from '../../../utils/detectDevice';
import Image from '../Image';
import { getCurrentTheme } from '../../../templates';
import { SHARINGOOD_COMMUNITY } from '../../../constants/communities';

const WishesInfoBanner = ({ onClose, communityName, iconUrl }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View style={s.contentContainer}>
      {isWeb ? <Image style={s.image} source={{ uri: iconUrl }} /> : <TokenIcon isBig />}
      <View style={s.textContainer}>
        <Text medium semiBold style={[s.text, rs.paddingHorizontal]} color={colors.gray}>
          {communityName === SHARINGOOD_COMMUNITY
            ? strings.home.wishes_banner_sharingood
            : strings.home.wishes_banner}{' '}
          <Text color={currentTheme.colors.primary} medium semiBold style={s.text}>
            {communityName === strings.main.app_name.toLowerCase() &&
              strings.home.token_banner_blue_text}
          </Text>
        </Text>
      </View>

      {!!onClose && (
        <TouchableItem onPress={onClose} style={s.closeIconButton}>
          <Icon name={'x'} size={16} color={colors.grayBorder} />
        </TouchableItem>
      )}
    </View>
  );
};

export default connect((state) => ({
  iconUrl: state.communityInfo.community_icon,
  communityName: state.communityTheme.theme,
}))(WishesInfoBanner);
