import React, { Component, Fragment } from 'react';
import s from './styles';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import { time } from '../../../../../utils/dateHelper';
import { Container, View, UserAvatar, Text, TouchableItem, Image } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import strings from '../../../../../localization';
import transactionInfo from '../getTransactionInfo';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';

class TransactionDetails extends Component {
  state = {
    transaction: {},
  };

  componentDidMount() {
    const { navigation, route } = this.props;
    const transaction = route.params.transaction;
    const info = transactionInfo(transaction) || {};
    navigation.setParams({
      headerBackgroundColor: info.isIncome ? colors.green : colors.orange,
      title: info.title,
    });

    this.setState({ transaction });
  }

  onOpenUserProfile = () => {
    const { route } = this.props;
    const transaction = route.params.transaction;
    NavigationService.push(screens.UserProfile, {
      name: transaction.user_name,
      userId: transaction.user_id,
      profile_image: transaction.user_avatar,
    });
  };

  render() {
    const { transaction } = this.state;

    const info = transactionInfo(transaction) || {};

    return (
      <Container>
        <View style={s.topContainer}>
          {info.type === 'TRANSFER' ? (
            <Image source={{ uri: transaction.item_image }} style={s.itemImage} />
          ) : (
            <UserAvatar withLogoPlaceholder big />
          )}

          {info.type !== 'WITHDRAW' && info.type !== 'ISSUE' && (
            <Text medium semiBold alignCenter style={[rs.paddingTop, rs.paddingHorizontal]}>
              {info.description}
            </Text>
          )}
        </View>

        {!!transaction.user_id && (
          <Fragment>
            <View style={s.sectionTitleContainer}>
              <Text medium bold>
                {strings.transactions.user}
              </Text>
            </View>
            <TouchableItem
              style={[rs.row, rs.paddingHorizontal, rs.paddingVertical, rs.alignCenter]}
              onPress={this.onOpenUserProfile}
            >
              <UserAvatar uri={transaction.user_avatar} />
              <Text>{transaction.user_name}</Text>
            </TouchableItem>
          </Fragment>
        )}

        <View style={s.sectionTitleContainer}>
          <Text medium bold>
            {strings.lots.details}
          </Text>
        </View>

        <View style={rs.paddingHorizontal}>
          <View style={s.infoContainer}>
            <Text>{strings.bank.amount}:</Text>
            <Text medium bold color={info.isIncome ? colors.green : colors.orange}>
              {info.isIncome ? '+' : '-'}
              {numberWithCommas(transaction.amount)} {transaction.token_name}
            </Text>
          </View>

          <View style={s.infoContainer}>
            <Text>{strings.buy_sell.when} </Text>
            <Text color={colors.gray}>{time(transaction.created_at)}</Text>
          </View>
        </View>

        {transaction.note && (
          <Fragment>
            <View style={s.sectionTitleContainer}>
              <Text medium semiBold>
                {strings.bank.note}
              </Text>
            </View>
            <View style={[rs.paddingHorizontal, rs.paddingVertical]}>
              <Text>{transaction.note}</Text>
            </View>
          </Fragment>
        )}

        {(info.type === 'EXCHANGE_TO' || info.type === 'EXCHANGE_FROM') && (
          <Fragment>
            <View style={s.sectionTitleContainer}>
              <Text medium semiBold>
                {strings.bank.note}
              </Text>
            </View>
            <View style={[rs.paddingHorizontal, rs.paddingVertical]}>
              <Text>{info.note}</Text>
            </View>
          </Fragment>
        )}
      </Container>
    );
  }
}

export default TransactionDetails;
