import React, { Fragment } from 'react';
import { colors, dimensions } from '../../styles';
import BackButton from './NavigationButtons/BackButton';
import NavigationButton from './NavigationButtons';
import Text from './Text';
import styled from 'styled-components/native';
import {isIos, isWeb} from '../../utils/detectDevice';
import { StyleSheet } from 'react-native';
import T from 'prop-types';

export const HeaderContainer = styled.View`
  padding-horizontal: ${dimensions.medium};
  height: ${dimensions.headerHeight - (isIos ? dimensions.statusBarHeight : 0)};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${colors.white};
  border-bottom-width: ${({ withoutBorder }) => (withoutBorder ? 0 : StyleSheet.hairlineWidth)};
  border-bottom-color: ${colors.gray};
`;

export const StatusBarPadding = styled.View`
  height: ${isIos ? dimensions.statusBarHeight : 0};
  background-color: ${colors.white};
`;

export const BackButtonContainer = styled.View`
  position: absolute;
  left: 0;
`;
export const SubmitButtonContainer = styled.View`
  position: absolute;
  right: 0;
`;

const ScreenHeader = ({
  headerTitle,
  onClose,
  submitButtonTitle,
  onSubmit,
  withoutBorder,
  withoutCancel,
  submitButton,
  headerTitleStyle,
}) => (
  <Fragment>
    <StatusBarPadding />
    <HeaderContainer withoutBorder={withoutBorder}>
      {!withoutCancel && (
        <BackButtonContainer>
          <BackButton isCancel={!isWeb} onPress={onClose} />
        </BackButtonContainer>
      )}
      <Text large style={headerTitleStyle}>{headerTitle}</Text>
      {submitButton && <SubmitButtonContainer>{submitButton()}</SubmitButtonContainer>}
      {!!submitButtonTitle && (
        <SubmitButtonContainer>
          <NavigationButton title={submitButtonTitle} onButtonPress={onSubmit} />
        </SubmitButtonContainer>
      )}
    </HeaderContainer>
  </Fragment>
);

ScreenHeader.propTypes = {
  headerTitle: T.string,
  submitButtonTitle: T.string,
  onClose: T.func,
  onSubmit: T.func,
  withoutBorder: T.bool,
};

export default ScreenHeader;
