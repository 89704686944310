import styled from 'styled-components/native';
import { dimensions, colors } from '../../../../styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  separator: {
    alignSelf: 'center',
    width: dimensions.width - dimensions.doubleMedium,
  },
  rulesContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: 20,
    backgroundColor: colors.white,
  },
  inputPrice: {
    width: 60,
    borderBottomWidth: 1,
    textAlign: 'center',
    paddingBottom: 6,
    color: colors.blueLight,
    borderBottomColor: colors.gray,
  },
  inputOriginalPrice: {
    width: 60,
    borderBottomWidth: 1,
    textAlign: 'center',
    paddingBottom: 6,
    color: colors.grayBorder,
    borderBottomColor: colors.grayBorder,
  },
  containerTalentAddress: {
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.medium,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerRadio: {
    flexDirection: 'column',
    padding: 10,
    backgroundColor: colors.white,
  },
});

export const PostButtonContainer = styled.View`
  flex: 1;
  padding-vertical: ${dimensions.commonHeight};
  padding-horizontal: ${dimensions.medium};
`;
