import React, { Component } from 'react';
import { Alert, Animated } from 'react-native';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { offersSelectors, offersOperations } from '../../../../store/offers';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import { Container } from '../../../ReusableComponents';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import ItemDetailsView from './components/ItemDetailsView';
import BottomBar from './components/BottomBar';
import screens from '../../../../navigation/screens';
import { isWeb } from '../../../../utils/detectDevice';
import { ModalsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';

@connectActionSheet
class ItemDetails extends Component {
  state = {
    item: {},
    scrollY: new Animated.Value(0),
  };

  async componentDidMount() {
    const { route, updateOffer, user } = this.props;
    const item = route.params.item;
    const isCurrentUserBuyer = Number(item.buyer_id) === user.id;

    this.setState({
      item,
    });

    if (isCurrentUserBuyer) {
      updateOffer(item.offer_id);
    }
  }

  handleActivateSoldItem = () => {
    const { item } = this.state;
    const { activateSoldItem } = this.props;

    if (isWeb) {
      ModalsService.showModal(modalTypes.ALERT, {
        title: strings.items.repost_item,
        description: strings.items.sure_repost,
        confirmButtonTitle: strings.items.repost,
        cancelButtonTitle: strings.common.cancel,
        onSubmit: () => {
          activateSoldItem(item.id);
        },
      });
    } else {
      Alert.alert(strings.items.repost_item, strings.items.sure_repost, [
        {
          text: strings.items.repost,
          onPress: () => {
            activateSoldItem(item.id);
          },
        },
        {
          text: strings.common.cancel,
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
      ]);
    }
  };

  onOpenSellerSoldItemActionSheet = () => {
    const { showActionSheetWithOptions } = this.props;

    const options = [strings.items.repost_item, strings.common.cancel];

    const cancelButtonIndex = 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          setTimeout(() => this.handleActivateSoldItem(), 500);
        }
      },
    );
  };

  onOpenBuyerPurchaseItemActionSheet = () => {
    const { item } = this.state;
    const { showActionSheetWithOptions } = this.props;

    const options = [strings.items.add_to_marketplace, strings.common.cancel];
    const buyerItem = R.pick(
      [
        'title',
        'parent_category_id',
        'parent_category_name',
        'category_id',
        'category_name',
        'brand_id',
        'brand_name',
        'description',
        'size_id',
        'size_title',
        'item_condition',
        'original_price',
        'price',
        'group_id',
      ],
      item,
    );

    const cancelButtonIndex = options.length - 1;
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          NavigationService.navigate(screens.EditItemStack, {
            screen: screens.EditItem,
            params: {
              item: buyerItem,
              images: item.images, // take from item in the future
            },
          });
        }
      },
    );
  };

  onOpenChatRoom = async () => {
    const { offer } = this.props;

    const chatId = offer.chat_room_id;

    if (!chatId) {
      return;
    }

    NavigationService.navigate(screens.ChatRoom, { chatId });
  };

  render() {
    const { item, scrollY } = this.state;
    const { wish, user } = this.props;

    const { seller_image, seller_name, seller_id, id } = item;

    const isCurrentUserSeller = Number(item.seller_id) === user.id;
    const isCurrentUserBuyer = Number(item.buyer_id) === user.id;
    const isWisher = Number(R.prop('wisher_id', wish)) === user.id;

    const isVisibleWishInfo = (isCurrentUserSeller && R.prop('wish_id', item)) || isWisher;

    let onOptionsButtonPress = null;

    if (isCurrentUserSeller) {
      onOptionsButtonPress = this.onOpenSellerSoldItemActionSheet;
    } else if (isCurrentUserBuyer) {
      onOptionsButtonPress = this.onOpenBuyerPurchaseItemActionSheet;
    }

    return (
      <Container>
        <ItemDetailsView
          isSoldItem
          scrollY={scrollY}
          item={item}
          onOptionsButtonPress={onOptionsButtonPress}
          isCurrentUser={isCurrentUserSeller}
          wishTitle={R.prop('title', wish)}
          isVisibleWishInfo={isVisibleWishInfo}
        />

        <BottomBar
          userAvatar={seller_image}
          userName={seller_name}
          isActive={false}
          userId={seller_id}
          isCurrentUser={isCurrentUserSeller}
          isCurrentUserBuyer={isCurrentUserBuyer}
          onOpenChatRoom={this.onOpenChatRoom}
        />
      </Container>
    );
  }
}

export default connect(
  (state, { route }) => ({
    user: state.userInfo,
    wish: lotsSelectors.getWishInfoByItemId(state, route.params.item.id),
    offer: offersSelectors.getOfferById(state, route.params.item.offer_id),
  }),
  (dispatch) => ({
    activateSoldItem: (itemId) => dispatch(lotsOperations.activateSoldItem(itemId)),
    updateOffer: (offerId) => dispatch(offersOperations.updateOffer(offerId)),
  }),
)(ItemDetails);
