import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';
import * as types from './types';
import { RESET_GROUP_STATE } from '../constants';
import { sortingTypes } from '../../constants/sortingTypes';
import * as homeTypes from '../home/types';

const entityInfoDefaultValue = {
  ids: [],
  totalAmount: 0,
  isLoading: false,
  isLoadingMore: false,
  isExistMore: true,
};

const entitySoldItemsInfoDefaultValue = {
  items: [],
  totalAmount: 0,
  isLoading: false,
  isLoadingMore: false,
  isExistMore: true,
};

const userLotsInitialState = {
  userActiveItems: entityInfoDefaultValue,
  userSoldItems: entitySoldItemsInfoDefaultValue,
  userPurchasedItems: entitySoldItemsInfoDefaultValue,
  userWishes: entityInfoDefaultValue,
};

export const initialState = {
  isLoadingManagementLot: false,

  isLoadingItems: false,
  isLoadingMoreItems: false,
  isRefreshingItems: false,
  isExistMoreItems: true,

  isLoadingWishes: false,
  isLoadingMoreWishes: false,
  isRefreshingWishes: false,
  isExistMoreWishes: true,

  feedLotFilters: {
    withShipping: false,
    selectedCities: [],
    selectedCampuses: [],
    selectedSizes: [],
    selectedBrands: [],
    selectedConditions: [],
    selectedCategoryIds: [],
    distance: null,
    isSearchByFollowings: false,
    isSearchByFavorites: false,
    isSearchByDiscount: false,
    isSearchByPrimaryLocation: false,
    isOnlyFeaturedItems: false,
    isOnlyVerifiedSellers: false,
    isOnlyPublic: false,
    searchTerm: null,
    coordinates: null,
    sortBy: sortingTypes.by_newest,
    priceRange: [0, 200],
    groupIds: [],
    schoolExtension: null,
    isSearchOnlyTalent: true,
    isSearchOnlyItem: true,
  },

  feedWishFilters: {
    searchTerm: null,
    groupIds: [],
  },

  savedItems: {
    ids: [],
    totalAmount: 0,
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,
  },

  pendingItems: {
    ids: [],
    totalAmount: 0,
    isLoading: false,
    isLoadingMore: false,
    isExistMore: true,
  },

  itemIds: [],
  itemEntities: {},
  itemsTotalCount: 0,

  wishIds: [],
  wishEntities: {},
  wishesTotalCount: 0,

  skillEntities: {},

  userLots: {
    // [userId]: {
    //   userActiveItems: {
    //     ids: [],
    //     totalAmount: 0,
    //     isLoading: false,
    //     isLoadingMore: false,
    //     isExistMore: true,
    //   },
    //
    //   userSoldItems: {
    //     items: [],
    //     totalAmount: 0,
    //     isLoading: false,
    //     isLoadingMore: false,
    //     isExistMore: true,
    //   },
    //
    //   userPurchasedItems: {
    //     items: [],
    //     totalAmount: 0,
    //     isLoading: false,
    //     isLoadingMore: false,
    //     isExistMore: true,
    //   },
    //
    //   userWishes: {
    //     ids: [],
    //     totalAmount: 0,
    //     isLoading: false,
    //     isLoadingMore: false,
    //     isExistMore: true,
    //   },
    // },
  },

  location: {},
};

const reducer = handleActions(
  {
    [types.LOTS_ERROR]: mergeDeep(() => ({
      isLoadingItems: false,
      isLoadingMoreItems: false,
      isRefreshingItems: false,

      isLoadingWishes: false,
      isLoadingMoreWishes: false,
      isRefreshingWishes: false,
    })),

    [types.ITEMS_START]: mergeDeep(() => ({
      isLoadingItems: true,
      itemIds: [],
    })),

    [types.ITEMS_MORE_START]: mergeDeep(() => ({
      isLoadingMoreItems: true,
    })),

    [types.ITEMS_REFRESH_START]: mergeDeep(() => ({
      isRefreshingItems: true,
    })),

    [types.ITEMS_SUCCESS]: mergeDeep(({ payload }, state) => ({
      itemEntities: payload.normalizedItems.itemEntities,
      itemIds: payload.normalizedItems.itemIds,
      itemsTotalCount: payload.total,
      isLoadingItems: false,
      isRefreshingItems: false,
      isExistMoreItems: payload.isExistMore,
    })),

    [types.ITEMS_MORE_SUCCESS]: mergeDeep(({ payload }, state) => ({
      itemEntities: payload.normalizedItems.itemEntities,
      itemIds: R.uniq([...state.itemIds, ...payload.normalizedItems.itemIds]),
      isLoadingMoreItems: false,
      isExistMoreItems: payload.isExistMore,
    })),

    [types.WISHES_START]: mergeDeep(() => ({
      isLoadingWishes: true,
    })),

    [types.WISHES_MORE_START]: mergeDeep(() => ({
      isLoadingMoreWishes: true,
    })),

    [types.WISHES_REFRESH_START]: mergeDeep(() => ({
      isRefreshingWishes: true,
    })),

    [types.WISHES_SUCCESS]: mergeDeep(({ payload }, state) => ({
      wishEntities: payload.normalizedWishes.wishEntities,
      wishIds: payload.normalizedWishes.wishIds,
      isLoadingWishes: false,
      isRefreshingWishes: false,
      isExistMoreWishes: payload.isExistMore,
      wishesTotalCount: payload.total,
    })),

    [types.WISHES_MORE_SUCCESS]: mergeDeep(({ payload }, state) => ({
      wishEntities: payload.normalizedWishes.wishEntities,
      wishIds: R.uniq([...state.wishIds, ...payload.normalizedWishes.wishIds]),
      isLoadingMoreWishes: false,
      isExistMoreWishes: payload.isExistMore,
    })),

    [types.ADD_ITEM_ENTITY]: mergeDeep(({ payload }) => ({
      itemEntities: {
        [payload.item.id]: payload.item,
      },
    })),

    [types.ADD_WISH_ENTITY]: mergeDeep(({ payload }) => ({
      wishEntities: {
        [payload.wish.id]: payload.wish,
      },
    })),

    [types.INITIALIZE_USER_LOTS_STATE]: mergeDeep(({ payload }) => ({
      userLots: {
        [payload.userId]: userLotsInitialState,
      },
    })),

    [types.USER_LOTS_START]: mergeDeep(({ payload }) => ({
      userLots: {
        [payload.userId]: {
          [payload.entityName]: {
            [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
          },
        },
      },
    })),

    [types.USER_LOTS_ERROR]: mergeDeep(({ payload }) => ({
      userLots: {
        [payload.userId]: {
          [payload.entityName]: {
            [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
          },
        },
      },
    })),

    [types.USER_LOTS_SUCCESS]: mergeDeep(({ payload }, state) => {
      const { userId, entityName, isLoadMore, ids, entities, totalAmount, isExistMore } = payload;

      return {
        [entityName === 'userWishes' ? 'wishEntities' : 'itemEntities']: entities,
        userLots: {
          [userId]: {
            [entityName]: {
              ids: isLoadMore
                ? [...R.path(['userLots', userId, entityName, 'ids'], state), ...ids]
                : ids,
              totalAmount,
              isExistMore,
              [isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
            },
          },
        },
      };
    }),

    [types.USER_SOLD_ITEMS_SUCCESS]: mergeDeep(({ payload }, state) => {
      const { items, userId, isLoadMore, totalAmount, isExistMore } = payload;

      return {
        userLots: {
          [userId]: {
            userSoldItems: {
              items: isLoadMore
                ? R.uniq([
                    ...R.path(['userLots', userId, 'userSoldItems', 'items'], state),
                    ...items,
                  ])
                : items,
              totalAmount,
              isExistMore,
              [isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
            },
          },
        },
      };
    }),

    [types.USER_PURCHASED_ITEMS_SUCCESS]: mergeDeep(({ payload }, state) => {
      const { items, userId, isLoadMore, totalAmount, isExistMore } = payload;

      return {
        userLots: {
          [userId]: {
            userPurchasedItems: {
              items: isLoadMore
                ? R.uniq([
                    ...R.path(['userLots', userId, 'userPurchasedItems', 'items'], state),
                    ...items,
                  ])
                : items,
              totalAmount,
              isExistMore,
              [isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
            },
          },
        },
      };
    }),

    [types.SAVED_ITEMS_START]: mergeDeep(({ payload }) => ({
      savedItems: {
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
      },
    })),
    [types.SAVED_ITEMS_ERROR]: mergeDeep(({ payload }) => ({
      savedItems: {
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
      },
    })),

    [types.SAVED_ITEMS_SUCCESS]: mergeDeep(({ payload }, state) => {
      const { ids, entities, isLoadMore, totalAmount, isExistMore } = payload;

      return {
        savedItems: {
          ids: isLoadMore ? R.uniq([...R.path(['savedItems', 'ids'], state), ...ids]) : ids,
          totalAmount,
          isExistMore,
          [isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
        },
        itemEntities: entities,
      };
    }),

    [types.PENDING_ITEMS_START]: mergeDeep(({ payload }) => ({
      pendingItems: {
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: true,
      },
    })),

    [types.PENDING_ITEMS_ERROR]: mergeDeep(({ payload }) => ({
      pendingItems: {
        [payload.isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
      },
    })),

    [types.PENDING_ITEMS_SUCCESS]: mergeDeep(({ payload }, state) => {
      const { ids, entities, isLoadMore, totalAmount, isExistMore } = payload;

      return {
        pendingItems: {
          ids: isLoadMore ? R.uniq([...R.path(['pendingItems', 'ids'], state), ...ids]) : ids,
          totalAmount,
          isExistMore,
          [isLoadMore ? 'isLoadingMore' : 'isLoading']: false,
        },
        itemEntities: entities,
      };
    }),

    [types.REMOVE_ITEM_FROM_PENDING_LIST]: mergeDeep(({ payload }, state) => ({
      pendingItems: {
        ids: state.pendingItems.ids.filter((id) => id !== payload.itemId),
      },
    })),

    [types.RESET_USER_LOTS]: mergeDeep(({ payload }) => ({
      userLots: {
        [payload.userId]: userLotsInitialState,
      },
    })),

    [types.CREATE_ITEM_SUCCESS]: mergeDeep(({ payload }, state) => ({
      itemEntities: {
        [payload.item.id]: {
          ...payload.item,
        },
      },
      isLoadingManagementLot: false,
    })),

    [types.CREATE_WISH_SUCCESS]: mergeDeep(({ payload }, state) => ({
      wishEntities: {
        [payload.wish.id]: {
          ...payload.wish,
        },
      },
      [types.CREATE_SKILL_SUCCESS]: mergeDeep(({ payload }, state) => ({
        skillEntities: {
          [payload.skill.id]: {
            ...payload.skill,
          },
        },
      })),
      wishIds: R.uniq([payload.wish.id, ...state.wishIds]),
      isLoadingManagementLot: false,
    })),

    [types.MANAGEMENT_LOT_START]: mergeDeep(() => ({
      isLoadingManagementLot: true,
    })),

    [types.MANAGEMENT_LOT_ERROR]: mergeDeep(() => ({
      isLoadingManagementLot: false,
    })),

    [types.REMOVE_ITEM]: mergeDeep(({ payload }, state) => {
      const { itemId, sellerId } = payload;
      const isUserItemsInStore = !R.isEmpty(
        R.pathOr([], ['userLots', sellerId, 'userActiveItems', 'ids'], state),
      );

      return {
        itemIds: state.itemIds.filter((id) => id !== itemId),

        userLots: isUserItemsInStore
          ? {
              [sellerId]: {
                userActiveItems: {
                  totalAmount: state.userLots[sellerId].userActiveItems.totalAmount - 1,
                  ids: state.userLots[sellerId].userActiveItems.ids.filter((id) => id !== itemId),
                },
              },
            }
          : {},
      };
    }),

    [types.REMOVE_WISH]: mergeDeep(({ payload }, state) => {
      const { wishId, wisherId } = payload;
      const isUserWishesInStore = !R.isEmpty(
        R.pathOr([], ['userLots', wisherId, 'userWishes', 'ids'], state),
      );

      return {
        wishIds: state.wishIds.filter((id) => id !== wishId),

        userLots: isUserWishesInStore
          ? {
              [wisherId]: {
                userWishes: {
                  totalAmount: state.userLots[wisherId].userWishes.totalAmount - 1,
                  ids: state.userLots[wisherId].userWishes.ids.filter((id) => id !== wishId),
                },
              },
            }
          : {},
      };
    }),

    [types.UPDATE_ITEM]: mergeDeep(({ payload }) => ({
      itemEntities: {
        [payload.item.id]: { ...payload.item },
      },
      isLoadingManagementLot: false,
    })),
    [types.RESET_ITEM]: mergeIn(({ payload: itemId }) => ({
      itemEntities: {
        [itemId]: {},
        isLoadingManagementLot: false,
      },
    })),

    [types.UPDATE_WISH]: mergeDeep(({ payload }) => ({
      wishEntities: {
        [payload.wish.id]: { ...payload.wish },
      },
      isLoadingManagementLot: false,
    })),

    [types.RESET_WISH]: mergeIn(({ payload: wishId }) => ({
      wishEntities: {
        [wishId]: {},
        isLoadingManagementLot: false,
      },
    })),

    [types.SAVE_LOCATION]: mergeIn((action) => ({
      location: action.location,
    })),

    [types.SET_FEED_LOT_FILTERS]: mergeDeep(({ payload }) => ({
      feedLotFilters: payload,
    })),

    [types.REPLACE_MARKETPLACE_FILTERS_WITH_NEW]: mergeDeep(({ payload }) => ({
      feedLotFilters: payload,
    })),

    [types.SET_FEED_WISH_FILTERS]: mergeDeep(({ payload }) => ({
      feedWishFilters: payload,
    })),

    [types.RESET_FEED_LOT_FILTERS]: mergeDeep(({ payload }, state) => ({
      feedLotFilters: {
        ...initialState.feedLotFilters,
        groupIds: state.feedLotFilters.groupIds,
      },
    })),

    [types.SET_FILTER_GROUP_ID]: mergeDeep(({ payload }) => ({
      feedLotFilters: {
        groupIds: payload.groupIds,

        selectedSizes: [],
        selectedBrands: [],
        selectedConditions: [],
        selectedCategoryIds: [],
      },
    })),

    [types.TOGGLE_ITEM_FAVORITES]: mergeDeep(({ payload }, state) => ({
      // mark item as favorite only if item object is in entity for handle home screen items
      itemEntities: R.isEmpty(R.pathOr({}, ['itemEntities', payload.itemId], state))
        ? {}
        : {
            [payload.itemId]: {
              mark_as_favorite: !payload.isFavorite,
            },
          },
    })),

    [types.TOGGLE_TOP_ITEM]: mergeDeep(({ payload }, state) => ({
      itemEntities: {
        [payload.itemId]: {
          is_top_item: payload.isTop,
        },
      },
    })),

    // [types.ITEM_WAS_ADDED_TO_FAVORITES]: mergeDeep(({ payload }, state) => ({
    //   itemEntities: {
    //     [payload.itemId]: {
    //       favorites_count: state.itemEntities[payload.itemId].favorites_count + 1,
    //     },
    //   },
    // })),

    [types.DISCOUNT_FAVORITE_ITEM]: mergeDeep(({ payload }) => ({
      itemEntities: {
        [payload.itemId]: {
          discount_rate: payload.discount_rate,
          discounted_price: payload.discounted_price,
        },
      },
    })),

    [types.SOLD_FAVORITE_ITEM]: mergeDeep(({ payload }) => ({
      itemEntities: {
        [payload.itemId]: {
          discount_rate: payload.discountRate,
          discounted_price: payload.discountedPrice,
        },
      },
    })),

    // [types.ITEM_WAS_SOLD]: mergeDeep(({ payload }, state) => ({
    //   itemIds: state.itemIds.filter((itemId) => itemId !== payload.soldItemId),
    // })),
    //
    // [homeTypes.SET_ITEMS_FROM_MEMBERS_I_FOLLOW]: mergeDeep(({ payload }, state) => ({
    //   itemEntities: payload.itemEntities,
    // })),
    //
    // [homeTypes.SET_ITEMS_NEAR_ME]: mergeDeep(({ payload }, state) => ({
    //   itemEntities: payload.itemEntities,
    // })),
    //
    // [homeTypes.SET_NEWEST_ITEMS_IN_COMMUNITY_GROUPS]: mergeDeep(({ payload }, state) => ({
    //   itemEntities: payload.itemEntities,
    // })),

    [types.RESET_ITEMS]: mergeIn(() => ({
      itemIds: [],
    })),

    /** reset feed and filters when select another community */

    [RESET_GROUP_STATE]: mergeIn(() => ({
      feedLotFilters: initialState.feedLotFilters,
      location: initialState.location,
      isLoadingManagementLot: initialState.isLoadingManagementLot,
      isLoadingItems: initialState.isLoadingItems,
      isLoadingMoreItems: initialState.isLoadingMoreItems,
      isRefreshingItems: initialState.isRefreshingItems,
      isExistMoreItems: initialState.isExistMoreItems,
      isLoadingWishes: initialState.isLoadingWishes,
      isLoadingMoreWishes: initialState.isLoadingMoreWishes,
      isRefreshingWishes: initialState.isRefreshingWishes,
      isExistMoreWishes: initialState.isExistMoreWishes,
    })),
  },
  initialState,
);

export default reducer;
