const theme = {
  colors: {
    primary: '#FCC78C',
    secondary: '#007A74',
    background: '#FFFFFF',
    primaryLight: '#FDE3C5',
    secondaryLight: '#80BCBC',
  },
};

export { theme };
