import { dimensions, fontSizes } from '../../../../styles';
import { StyleSheet } from 'react-native';
import { isSmallAndroid } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  image: {
    width: 200,
    height: dimensions.height * 0.14,
    marginTop: isSmallAndroid ? 0 : dimensions.medium,
  },
  imageWeb: {
    width: 200,
    height: dimensions.height * 0.12,
    marginTop: 0,
  },
  phoneImage: {
    maxWidth: 500,
    minWidth: 350,
    marginRight: dimensions.doubleMedium,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 1,
    maxWidth: 300,
    borderRadius: 10,
    height: 490,
  },
  titleWeb: {
    marginVertical: dimensions.medium,
  },
  headerTitleStyle: {
    fontSize: fontSizes.small,
    marginRight: dimensions.medium,
  },
});
