import * as React from 'react';
import LinearGradient from 'react-native-linear-gradient';
import { StyleSheet } from 'react-native';
import strings from '../../../localization';
import { colors, dimensions, rs } from '../../../styles';
import Text from '../Text';
import FontIcon from '../FontIcon';

const GlobalCommunityBadge = ({ style }) => (
  <LinearGradient
    colors={[colors.violet, colors.blueLighter]}
    style={[s.container, style]}
    start={{ x: 0, y: 0 }}
    end={{ x: 1, y: 0 }}
  >
    <FontIcon name="Settings_language" size={12} color={colors.white} style={rs.smallMarginRight} />
    <Text color={colors.white} xsmall>
      {strings.common.global}
    </Text>
  </LinearGradient>
);

const s = StyleSheet.create({
  container: {
    paddingHorizontal: dimensions.halfMedium,
    paddingVertical: 4,
    borderRadius: 12,
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
});

export default GlobalCommunityBadge;
