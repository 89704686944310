import React, { memo, useEffect, Fragment } from 'react';
import T from 'prop-types';
import moment from 'moment';
import { StyleSheet, Platform, View, Image } from 'react-native';
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import strings from '../../../../localization';
import { Text, TouchableItem, FastImage } from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';

const CARD_WIDTH = dimensions.width - 50;

const s = StyleSheet.create({
  cardContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 100,
    alignSelf: 'center',
    width: CARD_WIDTH,
    borderRadius: 12,
    minHeight: 150,
    backgroundColor: colors.white,
    ...Platform.select({
      ios: {
        shadowColor: colors.black,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
      },
      android: {
        elevation: 4,
      },
    }),
  },
  card: {
    padding: dimensions.medium,
    width: '100%',
    height: '100%',
    borderRadius: 12,
  },
  profileImage: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginRight: dimensions.halfMedium,
  },
  isVerifiedBadge: {
    height: 20,
    width: 15,
    marginLeft: dimensions.halfMedium,
  },
  joinedAt: {
    position: 'absolute',
    top: -dimensions.halfMedium,
    right: 0,
  },
  badge: {
    width: 30,
    height: 30,
    marginRight: dimensions.halfMedium,
  },
  textWithoutBadge: {
    marginLeft: 4,
  },
});

const UserCard = memo(({ user, onOpenUserProfile }) => {
  const bottom = useSharedValue(-dimensions.height);

  useEffect(() => {
    const isHidden = bottom.value < 0;

    if (user && isHidden) {
      bottom.value = withTiming(dimensions.doubleMedium * 2, { duration: 200 });
    }

    if (!user && !isHidden) {
      bottom.value = withTiming(-dimensions.height, { duration: 100 });
    }
  }, [user]);

  const animatedStyle = useAnimatedStyle(() => ({ bottom: bottom.value }), []);

  const joinedAtText =
    moment().diff(user?.joined_at, 'months') < 1
      ? strings.locations.new_member
      : `${strings.profile.joined} ${moment(user?.joined_at).format('MMM YYYY')}`;

  const onPress = () => {
    if (!user) {
      return;
    }
    onOpenUserProfile(user);
  };

  return (
    <Animated.View style={[s.cardContainer, animatedStyle]}>
      <TouchableItem onPress={onPress} disabled={!user} style={s.card} useOpacity={false}>
        <Fragment>
          {!!user && (
            <Fragment>
              <View style={[rs.row, rs.alignCenter]}>
                <FastImage
                  source={{ uri: user.profile_image }}
                  resizeMode="cover"
                  style={s.profileImage}
                />

                <View>
                  <View style={[rs.row, rs.alignCenter]}>
                    <Text color={colors.secondary} semiBold large>
                      {user.name}
                    </Text>
                    {user.is_verified && (
                      <Image
                        source={{ uri: 'verified_account' }}
                        resizeMode="contain"
                        style={s.isVerifiedBadge}
                      />
                    )}
                  </View>
                  {!!user.nickname && <Text medium>{`@${user.nickname}`}</Text>}
                </View>

                <Text style={s.joinedAt} color={colors.gray} xsmall>
                  {joinedAtText}
                </Text>
              </View>

              <View style={[rs.row, rs.alignCenter, rs.bigMarginTop]}>
                <Image source={{ uri: 'members_sold_to' }} resizeMode="contain" style={s.badge} />

                <Text numberOfLines={1} semiBold medium>
                  {user.member_received_item_count}{' '}
                  <Text medium color={colors.grey}>
                    {strings.user.members_sold_to_for_another_user}
                  </Text>
                </Text>
              </View>

              <View style={[rs.row, rs.alignCenter, rs.smallMarginTop]}>
                <Image source={{ uri: 'endorsments' }} resizeMode="contain" style={s.badge} />

                <Text numberOfLines={1} semiBold medium>
                  {user.endorsements_count}{' '}
                  <Text medium color={colors.grey}>
                    {strings.user.endorsements}
                  </Text>
                </Text>
              </View>

              <View style={[rs.row, rs.alignCenter, rs.marginTop]}>
                <Text numberOfLines={1} style={s.textWithoutBadge} semiBold medium>
                  {user.active_items_count}{' '}
                  <Text medium semiBold color={colors.grey}>
                    {strings.user.items_available}
                  </Text>
                </Text>
              </View>
            </Fragment>
          )}
        </Fragment>
      </TouchableItem>
    </Animated.View>
  );
});

UserCard.propTypes = {
  user: T.shape({
    id: T.number,
    name: T.string,
    nickname: T.string,
    profile_image: T.string,
    joined_at: T.string,
    is_verified: T.bool,
    active_items_count: T.number,
    member_received_item_count: T.number,
    endorsements_count: T.number,
  }),
};

export default UserCard;
