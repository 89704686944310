import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View, TouchableOpacity, Pressable, Alert, Animated } from 'react-native';
import { DrawerItem } from '@react-navigation/drawer';
import { colors, dimensions, rs } from '../../styles';
import { TouchableItem, Text, Image, Icon, FontIcon } from '../../components/ReusableComponents';
import { ModalsService, NavigationService } from '../../services';
import Popover, { usePopover } from '../../components/ReusableComponents/PortalPopover';
import Separator from '../../components/ReusableComponents/Separator';
import strings from '../../localization';
import { authOperations } from '../../store/auth';
import { useGetWindowCurrentWidth } from '../../utils/getWindowCurrentWidth';
import screens from '../screens';
import { ENV } from '../../../config/env';
import { getCommunityId, getIsUserAdmin } from '../../store/communityInfo/selectors';
import { appOperations } from '../../store/app';
import appLanguages from '../../constants/appLanguages';
import { PopoverPosition } from '../../components/ReusableComponents/PortalPopover/Popover';
import { getPrivacyPolicyUrl } from '../../components/Screens/AuthorizationGroup/PrivacyPolicyScreen';
import { getTermsAndConditionsUrl } from '../../components/Screens/AuthorizationGroup/TermsAndConditionsScreen';
import { getAppLanguage } from '../../store/app/selectors';
import modalTypes from '../../constants/modalTypes';
import guideTypes from '../../constants/guidelTypes';

const s = StyleSheet.create({
  popoverOption: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: dimensions.small,
    paddingVertical: 10,
    // backgroundColor: colors.text,
    width: 'auto',
    borderRadius: dimensions.borderRadius,
  },
  drawerItem: {
    width: 216,
    height: 44,
  },
  drawerItemWithOpenNotification: {
    width: 44,
    height: 44,
    borderRadius: 32,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  label: {
    marginLeft: -dimensions.medium,
    width: 200,
  },
  separator: {
    paddingHorizontal: dimensions.medium,
  },
  smallMarginHorizontal: {
    marginHorizontal: 5,
  },
});

const UserPopover = React.memo(
  ({
    type,
    toggleDrawer,
    isOpenNotification,
    scaleItemContainer,
    toggleNotification,
    toggleDrawerWidth,
  }) => {
    const isCurrentUserAdmin = useSelector(getIsUserAdmin);
    const communityInfoId = useSelector(getCommunityId);
    const appLanguage = useSelector(getAppLanguage);
    const isShowReviewModal = useSelector(
      (state) => state.guides.showAgain[guideTypes.ITEM_REVIEW],
    );

    const settingsOptions = [
      // {
      //   key: 'member_requests',
      //   title: strings.members.member_requests,
      //   iconName: 'members',
      //   screen: screens.MemberRequests,
      //   withAdminAccess: true,
      // },
      // {
      //   key: 'bank',
      //   title: strings.settings.tokens_bank,
      //   iconName: 'Settings_tokens-bank',
      //   screen: screens.Bank,
      //   withAdminAccess: true,
      // },
      {
        key: 'issue_tokens',
        title: strings.bank.issue_token,
        iconName: 'Settings_tokens-bank',
        screen: screens.IssueToken,
        withAdminAccess: true,
      },
      // {
      //   ...(communityInfoId === 1 &&
      //     isCurrentUserAdmin && {
      //       key: 'send_tokens',
      //       title: strings.bank.transfer_tokens,
      //       iconName: 'Settings_tokens-bank',
      //       screen: screens.SendTokensToBank,
      //       withAdminAccess: true,
      //     }),
      // },
      {
        ...((communityInfoId === 1 || communityInfoId === 2) && {
          key: 'send_tokens',
          title: strings.bank.transfer_tokens,
          iconName: 'Settings_tokens-bank',
          screen: screens.SendTokensToBank,
          withAdminAccess: true,
        }),
      },
      // {
      //   key: 'my_address',
      //   title: strings.settings.your_addresses,
      //   iconName: 'Settings_Location',
      //   screen: screens.UserLocations,
      //   withAdminAccess: false,
      // },
      // {
      //   key: 'edit',
      //   title: strings.settings.edit_community,
      //   iconName: 'pen',
      //   screen: screens.EditGroup,
      //   withAdminAccess: true,
      // },
      {
        key: 'community_notifications',
        title: strings.settings.community_notifications,
        iconName: 'settings_community-notifications',
        screen: screens.SendNotifications,
        withAdminAccess: true,
      },
      {
        key: 'promoCode',
        title: strings.promo_codes.redeem_code,
        iconName: 'redeem_code',
        screen: screens.PromoCode,
        withAdminAccess: false,
      },
      {
        key: 'terms_and_conditions',
        title: strings.login.terms_and_conditions,
        iconName: 'terms_and_conditions',
        screen: screens.TermsAndConditions,
        withAdminAccess: false,
      },
      {
        key: 'privacy_policy',
        title: strings.login.privacy_policy,
        iconName: 'privacy_policy',
        screen: screens.PrivacyPolicy,
        withAdminAccess: false,
      },
      {
        key: 'deactivate_account',
        title: strings.settings.delete_account,
        iconName: 'leave-community',
        withAdminAccess: false,
      },
      // {
      //   key: 'token_exchange',
      //   title: strings.tokens_exchange.title,
      //   iconName: 'transactions',
      //   screen: screens.TokensExchange,
      //   withAdminAccess: false,
      // },
    ];

    if (ENV !== 'production') {
      settingsOptions.push({
        key: 'logout',
        title: 'Log out',
        iconName: 'leave-community',
        withAdminAccess: false,
      });
    }

    const profileOptions = [
      {
        key: 'editProfile',
        title: strings.login.edit_profile,
        iconName: 'Profile-icon',
        screen: screens.EditUserInfo,
        withAdminAccess: false,
      },
      {
        key: 'transactions',
        title: strings.settings.transactions,
        iconName: 'Wallet',
        screen: screens.Transactions,
        withAdminAccess: false,
      },
    ];

    const getLanguageTitle = (language) => {
      switch (language) {
        case appLanguages.EN:
          return strings.languages.english;
        case appLanguages.HE:
          return strings.languages.hebrew;
        case appLanguages.BR:
          return strings.languages.portuguese;
        case appLanguages.DE:
          return strings.languages.german;
        default:
          return strings.languages.language;
      }
    };

    const languageOptions = Object.values(appLanguages).map((languageCode) => {
      return {
        key: languageCode,
        title: getLanguageTitle(languageCode),
        withAdminAccess: false,
      };
    });

    const postOptions = [
      {
        key: 'items',
        title: strings.items.item,
        iconName: 'Items',
        screen: screens.EditItemStack,
        withAdminAccess: false,
      },
      {
        key: 'wishes',
        title: strings.wishes.wish,
        iconName: 'Wish',
        screen: screens.EditWishStack,
        withAdminAccess: false,
      },
    ];

    const chooseOptionsType = (type) => {
      switch (type) {
        case 'settings':
          return {
            options: settingsOptions,
            icon: 'Menu_settings',
            title: strings.settings.settings,
          };
        case 'profile':
          return {
            options: profileOptions,
            icon: 'NavBar_Menu',
            title: strings.profile.profile,
          };
        case 'languages':
          return {
            options: languageOptions,
            icon: 'Settings_language',
            title: strings.languages.language,
          };
        case 'post':
          return {
            options: postOptions,
            icon: 'NavBar_Upload',
            title: strings.items.add_item,
            position: PopoverPosition.bottom,
          };
      }
    };

    const options = chooseOptionsType(type).options;
    const position = chooseOptionsType(type).position || PopoverPosition.top;

    const [isHovered, setHovered] = useState(false);
    const wrapperRef = React.useRef(null);
    const { isMobileWindow, windowCurrentWidth } = useGetWindowCurrentWidth();
    const { isVisible, targetLayout, handleMountPress, hide } = usePopover(wrapperRef);

    const dispatch = useDispatch();

    const onLogout = async () => {
      await dispatch(authOperations.logOut());
      window.location.reload();
    };

    const onChangeLanguage = async (languageCode) => {
      hide();
      await dispatch(appOperations.setLanguage(languageCode));
      setTimeout(() => window.location.reload(), 1);
    };

    const onDeleteAccountPress = () => {
      ModalsService.showModal(modalTypes.ALERT, {
        title: strings.undone_action_warning.title,
        description: strings.undone_action_warning.body,
        confirmButtonTitle: strings.common.confirm,
        cancelButtonTitle: strings.common.cancel,
        onSubmit: async () => {
          await dispatch(authOperations.deleteUserAccount());
          await dispatch(authOperations.logOut());
          window.location.reload();
        },
      });
    };

    const onPressPrivacyPolicy = () => {
      const url = getPrivacyPolicyUrl(appLanguage);
      window.open(url, '_blank').focus();
    };

    const onPressTermsAndConditions = () => {
      const url = getTermsAndConditionsUrl(appLanguage);
      window.open(url, '_blank').focus();
    };

    const handleMouse = (isHovered) => {
      setHovered(isHovered);
    };

    return (
      <Animated.View
        style={[
          !isMobileWindow && {
            width: scaleItemContainer?.interpolate({
              inputRange: [0.5, 1],
              outputRange: [216, 54],
            }),
            borderRadius: scaleItemContainer?.interpolate({
              inputRange: [0.5, 1],
              outputRange: [dimensions.borderRadius, 36],
            }),
          },
        ]}
      >
        <TouchableOpacity ref={wrapperRef}>
          <DrawerItem
            focused={isVisible}
            label={() =>
              !isOpenNotification && (
                <Text weight="bold" size="medium" color={colors.black} style={s.label}>
                  {chooseOptionsType(type).title}
                </Text>
              )
            }
            activeBackgroundColor={colors.grayLight}
            style={[
              isHovered ? { backgroundColor: colors.grayLight } : null,
              isOpenNotification ? s.drawerItemWithOpenNotification : s.drawerItem,
              isMobileWindow && { maxWidth: windowCurrentWidth - 20, width: '100%' },
            ]}
            icon={({ focused }) => (
              <View style={s.smallMarginHorizontal}>
                <FontIcon
                  name={chooseOptionsType(type).icon}
                  color={colors.text}
                  size={25}
                  withoutRTLScale
                />
              </View>
            )}
            onPress={handleMountPress}
            onMouseEnter={() => handleMouse(true)}
            onMouseLeave={() => handleMouse(false)}
          />
        </TouchableOpacity>

        {isVisible && (
          <Popover position={position} targetLayout={targetLayout} onPress={hide}>
            <View>
              {options
                .filter(({ withAdminAccess }) => !withAdminAccess || isCurrentUserAdmin)
                .map((item) => {
                  const onPress = () => {
                    switch (item.key) {
                      case 'items':
                        if (isShowReviewModal) {
                          setTimeout(() => ModalsService.showModal(modalTypes.ITEM_REVIEW), 600);
                        } else {
                          NavigationService.navigate(screens.EditItemStack, {
                            screen: screens.EditItem,
                          });
                        }
                        isMobileWindow && toggleDrawer();
                        break;
                      case 'wishes':
                        if (isShowReviewModal) {
                          setTimeout(
                            () => ModalsService.showModal(modalTypes.ITEM_REVIEW, { isWish: true }),
                            600,
                          );
                        } else {
                          NavigationService.navigate(screens.EditWishStack, {
                            screen: screens.EditWish,
                          });
                        }
                        isMobileWindow && toggleDrawer();
                        break;
                      case 'deactivate_account':
                        onDeleteAccountPress();
                        break;
                      case 'logout':
                        onLogout();
                        break;
                      case 'terms_and_conditions':
                        onPressTermsAndConditions();
                        break;
                      case 'privacy_policy':
                        onPressPrivacyPolicy();
                        break;
                      case appLanguages.EN:
                      case appLanguages.HE:
                      case appLanguages.BR:
                      case appLanguages.DE:
                        onChangeLanguage(item.key);
                        break;
                      default:
                        NavigationService.navigate(item.screen);
                        isMobileWindow && toggleDrawer();
                        if (isOpenNotification) {
                          toggleDrawerWidth && toggleDrawerWidth();
                          toggleNotification && toggleNotification();
                        }
                    }
                    hide();
                  };

                  return (
                    <>
                      <TouchableItem onPress={onPress} style={s.popoverOption}>
                        <Text style={[rs.smallMarginRight, { maxWidth: 180 }]} color={colors.white}>
                          {item.title}
                        </Text>
                        <FontIcon
                          withoutRTLScale
                          name={item.iconName}
                          color={colors.white}
                          size={20}
                        />
                      </TouchableItem>

                      {item !== options[options.length - 1] && (
                        <View style={s.separator}>
                          <Separator />
                        </View>
                      )}
                    </>
                  );
                })}
            </View>
          </Popover>
        )}
      </Animated.View>
    );
  },
);

export default UserPopover;
