import React from 'react';
import { StyleSheet } from 'react-native';

import Text from '../Text';
import TouchableItem from '../TouchableItem/index';
import { colors, dimensions } from '../../../styles';
import { getCurrentTheme } from '../../../templates';

const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: dimensions.borderRadius,
    marginRight: 0,
  },
  buttonText: {
    color: colors.white,
  },
  secondaryButtonContainer: {
    backgroundColor: colors.white,
    borderWidth: 1,
  },
});

const Button = ({ disabled, title, onPress, withMarginRight, medium, secondaryType = false }) => {
  const currentTheme = getCurrentTheme();

  const containerStyles = [
    styles.buttonContainer,
    {
      height: medium ? 36 : 40,
      minHeight: medium ? 36 : 40,
      backgroundColor: disabled ? colors.grayLight : currentTheme.colors.secondary,
      marginRight: withMarginRight ? dimensions.halfXLarge : 0,
      borderColor: disabled ? colors.grayLight : currentTheme.colors.secondary,
    },
    secondaryType && styles.secondaryButtonContainer,
  ];

  const textStyles = [
    styles.buttonText,
    {
      color: disabled ? colors.gray : colors.white,
    },
    secondaryType && { color: currentTheme.colors.secondary },
  ];
  return (
    <TouchableItem
      style={containerStyles}
      useOpacity={false}
      disabled={disabled}
      withMarginRight={withMarginRight}
      onPress={onPress}
      medium={medium}
    >
      <Text style={textStyles}>{title}</Text>
    </TouchableItem>
  );
};
export default Button;
