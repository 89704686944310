import { StyleSheet } from 'react-native';
import { colors, dimensions, fontSizes } from '../../../../styles';
import { isAndroid, isIos, isIphoneX, isSmallAndroid } from '../../../../utils/detectDevice';
import { isRTL } from '../../../../utils/rtlHelper';

export default StyleSheet.create({
  image: {
    width: 200,
    height: dimensions.height * 0.14,
    marginTop: isSmallAndroid ? 0 : dimensions.medium,
  },
  imageWeb: {
    width: 200,
    height: dimensions.height * 0.12,
    marginTop: 0,
  },
  phoneImage: {
    maxWidth: 500,
    minWidth: 350,
    marginRight: dimensions.doubleMedium,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 1,
    maxWidth: 300,
    borderRadius: 10,
    height: 490,
  },
  titleWeb: {
    marginVertical: dimensions.medium,
  },
  title: {
    marginVertical: isSmallAndroid ? dimensions.medium : dimensions.doubleMedium,
  },
  bottomContainer: {
    alignItems: 'center',
    marginBottom: isIphoneX ? dimensions.medium * 3 : dimensions.medium,
  },
  input: {
    fontSize: fontSizes.large,
    color: colors.black,
    borderColor: colors.grayLighter,
    fontFamily: 'Rubik-Regular',
    borderTopWidth: 1,
    height: '100%',
    flex: 1,
    paddingHorizontal: dimensions.halfMedium,
    textAlign: 'left',
    borderRightWidth: isIos && isRTL ? 1 : 0,
    borderLeftWidth: isIos && isRTL ? 0 : 1,
  },
  inputContainer: {
    flexDirection: 'row',
    height: 46,
    width: '100%',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: colors.grayLighter,
    paddingRight: isIos && isRTL ? dimensions.medium : 0,
    paddingLeft: isIos && isRTL ? 0 : dimensions.medium,
    maxWidth: 425,
  },
  numberCodeContainer: {
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
    borderColor: colors.grayLighter,
    paddingBottom: isAndroid ? 1 : 0,
  },
  selectCountryButton: {
    flexDirection: 'row',
    height: 46,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopWidth: 1,
    borderTopColor: colors.grayLighter,
    paddingLeft: dimensions.medium,
    paddingRight: dimensions.halfMedium,
    maxWidth: 425,
  },
  termsAndConditionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginHorizontal: dimensions.medium,
    marginTop: 20,
  },
  termsAndConditionsTextContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: dimensions.halfMedium,
    marginRight: dimensions.medium,
  },
});
