import React, { Fragment, memo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Animated } from 'react-native';
import {
  Separator,
  Text,
  View,
  Container,
  AnimatedOpacityHeader,
  Image,
} from '../../../ReusableComponents';
import { Collapse, CollapseHeader, CollapseBody } from './components';
import { rs, colors } from '../../../../styles';
import { communitySelectors } from '../../../../store/communityInfo';
import data from '../../../../constants/faq';
import s from './styles';
import strings from '../../../../localization';

const Item = memo(({ title, description }) => (
  <View style={[rs.paddingHorizontal, rs.backgroundWhite]}>
    <Collapse>
      <CollapseHeader style={s.itemHeaderContainer}>
        <Text medium>{title}</Text>
      </CollapseHeader>

      <CollapseBody style={[rs.bigMarginBottom]}>
        <Text color={colors.gray} style={s.descriptionText}>
          {description}
        </Text>
      </CollapseBody>
    </Collapse>
  </View>
));

const FAQ = ({ route }) => {
  const selectedTopicId = route.params?.topicId ?? null;

  const faqData = selectedTopicId
    ? data.filter(({ topicId }) => topicId === selectedTopicId)
    : data;
  const faqSectionList = useSelector((state) =>
    communitySelectors.getFAQSectionList(state, faqData),
  );

  const scrollY = new Animated.Value(0);
  return (
    <Container>
      <AnimatedOpacityHeader scrollY={scrollY} title={strings.support.faq} />

      <Animated.SectionList
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={8}
        sections={faqSectionList}
        keyExtractor={(item) => item.id}
        initialNumToRender={20}
        style={s.listStyle}
        contentContainerStyle={rs.tabBarPadding}
        keyboardShouldPersistTaps="handled"
        stickySectionHeadersEnabled={false}
        ListHeaderComponent={() => (
          <View style={s.headerImageContainer}>
            <Image source={{ uri: 'faq' }} style={s.headerImage} />
          </View>
        )}
        ItemSeparatorComponent={() => (
          <View style={rs.paddingHorizontal}>
            <Separator />
          </View>
        )}
        renderSectionHeader={({ section: { title } }) => (
          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {title}
            </Text>
          </View>
        )}
        renderItem={({ item }) => <Item title={item.title} description={item.description} />}
      />
    </Container>
  );
};

export default FAQ;
