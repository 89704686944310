import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../styles';

const s = StyleSheet.create({
  search: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.medium,
  },
  contentContainer: {
    paddingVertical: dimensions.medium,
    paddingHorizontal: dimensions.medium,
    alignItems: 'center',
  },
  columnWrapper: {
    justifyContent: 'center',
  },
  emptyList: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  circle: {
    position: 'absolute',
    top: 10,
    right: 14,
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.transparent,
  },
  sendButtonCircle: {
    position: 'absolute',
    top: 14,
    right: 56,
  },
});

export default s;
