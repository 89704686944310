import { StyleSheet } from 'react-native';
import { colors, dimensions, fontSizes, rs } from '../../../../styles';

export default StyleSheet.create({
  contentContainer: {
    backgroundColor: colors.white,
  },
  avatarWithInfoContainer: {
    alignItems: 'center',
    paddingTop: dimensions.doubleMedium,
    paddingBottom: dimensions.doubleMedium - 1, // 1 px for margin for shadow
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLight,
    ...rs.shadow,
    marginBottom: 1,
  },
  mainContainer: {
    paddingBottom: dimensions.medium,
  },
  userNameContainer: {
    height: 44,
    width: '100%',
    paddingLeft: dimensions.doubleMedium,
    justifyContent: 'center',
  },
  userNameInput: {
    width: '100%',
    height: '100%',
    fontSize: fontSizes.medium,
    color: colors.text,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: colors.grayLighter,
    paddingRight: dimensions.doubleMedium,
  },
  userNameLengthText: {
    position: 'absolute',
    right: dimensions.medium,
    marginLeft: dimensions.medium,
    color: colors.grayLight,
  },
  nicknameButton: {
    paddingVertical: dimensions.doubleMedium,
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
  },
  emailValidationText: {
    lineHeight: 20,
    marginTop: dimensions.halfMedium,
  },
  emailValidationButtonText: {
    textDecorationLine: 'underline',
  },
  webContainer: {
    maxWidth: dimensions.mobileWidth,
    shadowOffset: {
      width: 2,
      height: 0.2,
    },
    shadowRadius: 20,
    shadowColor: 'rgba(0, 0, 0, 0.4)',
  },
  bioInput: {
    paddingHorizontal: 0,
  },
});
