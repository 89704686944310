import * as React from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isAndroid, isIos, isIphoneX } from '../../../utils/detectDevice';
import { dimensions, rs } from '../../../styles';

const SafeAreaContainer = React.memo(({ children, style, ...props }) => {
  const insets = useSafeAreaInsets();
  const hasExtraPadding = isAndroid || (isIos && isIphoneX);

  return (
    <View
      style={[
        rs.flex,
        rs.backgroundWhite,
        {
          paddingBottom:
            insets.bottom + (hasExtraPadding ? dimensions.doubleMedium : dimensions.medium),
        },
        style,
      ]}
      {...props}
    >
      {children}
    </View>
  );
});

export default SafeAreaContainer;
