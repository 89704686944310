import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { colors, fontSizes, dimensions, reusableStyles } from '../../../../../styles';
import Text from '../../../Text';
import TouchableItem from '../../../TouchableItem';

const MainContainer = styled(TouchableItem)`
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding-right: ${dimensions.medium};
  opacity: ${(props) => props.disabled ? 0.4 : 1};
`;

const InfoView = styled.View`
  justify-content: center;
`;

const DetailContainer = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MemberStatus = styled(Text)`
  font-size: ${fontSizes.xsmall};
  color: ${props => (props.color ? props.color : colors.GreenLight)};
  margin-top: 4px;
  max-width: ${dimensions.width - 200};
`;

const ButtonsContainer = styled.View`
  flex-direction: row;
`;

const ActionContainer = styled.View`
  flex-direction: row;
  padding-left: ${dimensions.medium};
`;

const ActionText = styled(Text)`
  font-size: ${fontSizes.xxsmall};
  color: ${colors.white};
`;

const AngleIcon = styled(Icon).attrs({
  name: 'angle-right',
  size: dimensions.iconSize,
  color: '#E3E3E3',
})`
  position: absolute;
  right: ${dimensions.medium};
  ${reusableStyles.rtlScale};
`;

export {
  MainContainer,
  DetailContainer,
  MemberStatus,
  ActionContainer,
  ActionText,
  InfoView,
  AngleIcon,
  ButtonsContainer,
};
