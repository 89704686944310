import React, { Fragment, memo, useState } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import s, {
  ChatListDetailContainer,
  BubbleNotification,
  ContentContainer,
  MainContainer,
  SecondContainer,
  CheckIcon,
  ImageThumbnail,
} from './styles';
import {
  ItemAvatar,
  UserAvatar,
  Text,
  TouchableItem,
  FontIcon,
  Separator,
} from '../../../../../ReusableComponents';
import { getTimeForNotification } from '../../../../../../utils/dateHelper';
import { getIsImageLink } from '../../../../../../utils/stringHelper';
import memoHelper from '../../../../../../utils/memoHelper';
import { colors, rs } from '../../../../../../styles';
import chatTypes from '../../../../../../constants/chatTypes';
import strings from '../../../../../../localization';
import TypingIndicator from '../TypingIndicator';
import { isWeb } from '../../../../../../utils/detectDevice';
import { useGetWindowCurrentWidth } from '../../../../../../utils/getWindowCurrentWidth';
import { getCurrentTheme } from '../../../../../../templates';

const ChatItem = memo(
  ({ chat, onOpenChat, onOpenUserProfile, isEditMode, selectedChatIds }) => {
    const lastMessageText = R.path(['last_message', 'payload', 'content'], chat);
    const lastMessageType = R.path(['last_message', 'payload', 'content_type'], chat);
    const lastMessageDate = R.path(['last_message', 'created_at'], chat);
    const interlocutorId = R.path(['interlocutor_id'], chat);
    const [hoveredItem, setHoveredItem] = useState(null);
    const chatId = R.path(['id'], chat);
    const { insideChatRoomId } = useSelector((state) => state.chats);

    const currentTheme = getCurrentTheme();
    const theme = useSelector((state) => state.communityTheme.theme);

    const { windowCurrentWidth } = useGetWindowCurrentWidth();

    const handleItemMouseEnter = (chatId) => {
      setHoveredItem(chatId);
    };

    const handleItemMouseLeave = () => {
      setHoveredItem(null);
    };

    const chatName = R.path(['chat_name'], chat);
    const chatAvatar = R.path(['chat_avatar'], chat);

    const interlocutorName = R.path(['interlocutor_name'], chat);
    const interlocutorAvatar = R.path(['interlocutor_image'], chat);

    const itemImage = R.path(['item_image'], chat);
    const unreadCount = R.path(['unread_count'], chat);

    const isSelected = selectedChatIds && R.includes(chat.id, selectedChatIds);

    const typeIcon = {
      [chatTypes.PERSONAL_CHAT]: {
        iconName: 'Transactions_Income_Selling-copy',
        color: colors.coral,
      },
      [chatTypes.SUPPORT]: {
        iconName: 'Menu_Support',
        color: currentTheme.colors.secondary,
      },

      [chatTypes.BUY_CHAT]: {
        iconName: 'Transactions_Expense_Purchase-copy-1',
        color: colors.orange,
      },

      [chatTypes.SELL_CHAT]: {
        iconName: 'Transactions_Income_Refund-copy',
        color: colors.green,
      },
    }[chat.chat_type];

    const renderChatLastMessage = () => {
      const isImageLink = getIsImageLink(lastMessageText);
      const isContactCard = lastMessageType === 'contact';

      if (chat.isTypingMessage) {
        return <TypingIndicator />;
      }

      if (isContactCard) {
        return (
          <View style={[rs.verySmallPaddingTop, rs.row, rs.alignCenter]}>
            <FontIcon size={12} name="Transactions_Income_Selling-copy" color={colors.gray} />

            <Text color={colors.gray} style={rs.smallMarginLeft} xsmall>
              {strings.common.contact}
            </Text>
          </View>
        );
      }

      return (
        <Fragment>
          {isImageLink ? (
            <View style={[rs.verySmallPaddingTop, rs.row, rs.alignCenter]}>
              <ImageThumbnail source={{ uri: lastMessageText }} resizeMode="cover" />
              <Text color={colors.gray} xsmall>
                {strings.common.photo}
              </Text>
            </View>
          ) : (
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              color={colors.gray}
              style={rs.verySmallPaddingTop}
            >
              {lastMessageText}
            </Text>
          )}
        </Fragment>
      );
    };

    return (
      <View
        onMouseEnter={() => handleItemMouseEnter(chatId)}
        onMouseLeave={() => handleItemMouseLeave()}
        isSelected={isSelected}
        isHovered={hoveredItem}
        selectedChat={insideChatRoomId === chat.id}
        style={[
          s.rootContainer,
          {
            backgroundColor:
              isSelected || hoveredItem || insideChatRoomId === chat.id
                ? currentTheme.colors.secondaryLight
                : colors.white,
          },
          !!unreadCount && s.grayBackground,
        ]}
      >
        <ContentContainer useOpacity={false} onPress={() => onOpenChat(chat.id)}>
          <Fragment>
            <TouchableItem
              disabled={isEditMode || chat.chat_type === chatTypes.SUPPORT}
              onPress={() =>
                onOpenUserProfile(interlocutorId, interlocutorName, interlocutorAvatar)
              }
            >
              <UserAvatar
                big={isWeb}
                marginHorizontal={isWeb ? 12 : 0}
                uri={chatAvatar}
                isSupport={chatName === 'Shareitt Support'}
              />
              {isSelected && <CheckIcon name="check-circle" color={colors.green} size={20} />}
            </TouchableItem>

            {!!typeIcon && (
              <View
                style={[
                  isWeb ? s.typeIconContainerWeb : s.typeIconContainer,
                  { backgroundColor: typeIcon.color },
                ]}
              >
                <FontIcon withoutRTLScale color={colors.white} size={18} name={typeIcon.iconName} />
              </View>
            )}

            <MainContainer>
              <ChatListDetailContainer>
                <Text numberOfLines={2} semiBold>
                  {theme === strings.main.app_name_sharingood.toLowerCase() &&
                  chatName === strings.support.shareitt_support
                    ? strings.support.sharingood_support
                    : chatName}
                </Text>

                {renderChatLastMessage()}

                {!!lastMessageText && (
                  <Text xsmall color={colors.gray}>
                    {getTimeForNotification(lastMessageDate)}
                  </Text>
                )}
              </ChatListDetailContainer>

              <SecondContainer>
                {!!unreadCount && (
                  <View
                    style={[s.bubbleNotification, { borderColor: currentTheme.colors.secondary }]}
                  >
                    <Text xxsmall semiBold color={currentTheme.colors.secondary}>
                      {unreadCount}
                    </Text>
                  </View>
                )}

                {!!itemImage && <ItemAvatar big={isWeb} uri={itemImage} />}
              </SecondContainer>
            </MainContainer>
          </Fragment>
        </ContentContainer>
        {isWeb && <View style={s.separator} />}
      </View>
    );
  },
  (prev, next) => memoHelper(prev, next, ['chat', 'selectedChatIds', 'isEditMode']),
);

// ChatItem.whyDidYouRender = true;

ChatItem.propTypes = {
  chat: T.object.isRequired,
  onOpenChat: T.func.isRequired,
  onOpenUserProfile: T.func.isRequired,
};

export default ChatItem;
