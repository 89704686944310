import React, { useState, useEffect, Fragment } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { View, FlatList } from 'react-native';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { getUserActiveItemsData } from '../../../store/lots/selectors';
import { getUserActiveItems } from '../../../store/lots/operations';
import strings from '../../../localization';
import { formatDoubleColumns } from '../../../utils/multipleListColumsHelper';
import { colors } from '../../../styles';
import {
  CenteredView,
  Container,
  EmptyListWithImage,
  NavigationButton,
  ScreenHeader,
  SearchInput,
  Separator,
  Spinner,
  Text,
} from '../../ReusableComponents';
import ListItem from './components/ListItem';
import s from './styles';
import { isWeb } from '../../../utils/detectDevice';
import { getCurrentTheme } from '../../../templates';

const ItemsSelectionScreen = ({ route, itemsData, getItems }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    getItems(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getItemsDebounce] = useDebouncedCallback((value) => {
    getItems(false, value);
  }, 300);

  const onChangeSearchText = (value) => {
    setSearchValue(value);
    setSelectedItems([]);
    getItemsDebounce(value);
  };

  const onSelectItem = (item) => {
    // https://devunet.atlassian.net/browse/SHAREITT-1548
    // need to avoid press on empty item which was created by formatDoubleColumns()
    if (R.isEmpty(item)) {
      return;
    }

    const updatedItems = selectedItems.find(({ id }) => id === item.id)
      ? selectedItems.filter(({ id }) => id !== item.id)
      : [...selectedItems, item];

    setSelectedItems(updatedItems);
  };

  const onSend = () => {
    route.params.onSubmit(selectedItems);
  };

  const isSendButtonDisabled = selectedItems.length === 0;

  return (
    <Container>
      <ScreenHeader
        headerTitle={strings.modals.share_item}
        submitButton={() => (
          <Fragment>
            {!isSendButtonDisabled && (
              <View
                style={[
                  s.circle,
                  {
                    borderColor: currentTheme.colors.secondary,
                    backgroundColor: currentTheme.colors.secondary,
                  },
                  s.sendButtonCircle,
                ]}
              >
                <Text color={colors.white} semiBold xsmall>
                  {selectedItems.length}
                </Text>
              </View>
            )}
            <NavigationButton
              title={strings.common.send}
              disabled={isSendButtonDisabled}
              onButtonPress={onSend}
            />
          </Fragment>
        )}
        withoutBorder
      />

      <View style={s.search}>
        <SearchInput
          value={searchValue}
          onChangeText={onChangeSearchText}
          placeholder={strings.items.search_items}
        />
      </View>

      <Separator />

      <FlatList
        data={itemsData.isLoading ? [] : formatDoubleColumns(itemsData.data)}
        keyExtractor={(item) => item.id}
        showsVerticalScrollIndicator={false}
        onEndReachedThreshold={0.5}
        onEndReached={() => getItems(true, searchValue)}
        numColumns={2}
        contentContainerStyle={s.contentContainer}
        columnWrapperStyle={!isWeb && s.columnWrapper}
        emptyListStyle={s.emptyList}
        renderItem={({ item }) => {
          const isSelected = !!selectedItems.find(({ id }) => id === item.id);
          const index = R.findIndex(R.propEq('id', item.id))(selectedItems) + 1;

          return (
            <ListItem
              item={item}
              onSelect={onSelectItem}
              isSelected={isSelected}
              index={index}
              userId={route.params.userId}
            />
          );
        }}
        ListEmptyComponent={
          <CenteredView>
            {itemsData.isLoading ? (
              <Spinner />
            ) : (
              <EmptyListWithImage
                text={strings.emptyLists.empty_selling_offers_list}
                image="empty_state_your_items_for_sale"
              />
            )}
          </CenteredView>
        }
        ListFooterComponent={itemsData.isLoadingMore && <Spinner />}
      />
    </Container>
  );
};

ItemsSelectionScreen.propTypes = {
  itemsData: T.shape({
    data: T.arrayOf(T.shape({})),
    isLoading: T.bool,
    isLoadingMore: T.bool,
  }).isRequired,
  route: T.shape({
    params: T.shape({
      userId: T.number,
      inSubmit: T.func.isRequired,
    }),
  }).isRequired,
  getItems: T.func.isRequired,
};

export default connect(
  (state, { route }) => ({
    itemsData: getUserActiveItemsData(state, route.params.userId),
  }),
  (dispatch, { route }) => {
    return {
      getItems: (isLoadMore, searchTerm) =>
        dispatch(
          getUserActiveItems({
            userId: route.params.userId,
            communityIds: [],
            isLoadMore,
            searchTerm,
          }),
        ),
    };
  },
)(ItemsSelectionScreen);
