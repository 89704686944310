import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useFeatureFlag } from 'configcat-react';
import TouchableItem from '../../TouchableItem';
import Text from '../../Text';
import strings from '../../../../localization';
import { colors, rs, dimensions } from '../../../../styles';
import { ModalsService, NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { FontIcon } from '../../index';
import modalTypes from '../../../../constants/modalTypes';
import { connect, useSelector } from 'react-redux';
import guideTypes from '../../../../constants/guidelTypes';
import { guideOperations } from '../../../../store/guides';
import { getCurrentTheme } from '../../../../templates';
import SocialSkillShare from '../../../../assets/SocialSkillShare';
import { formatCommunityName } from '../../../../utils/formatCommunityName';
import { TALENTS_KEY } from '../../../../constants/configcat';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 80,
  },
  iconContainer: {
    height: 60,
    width: 60,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: dimensions.halfMedium,
    ...rs.shadow,
  },
});

const CreateLot = ({ isShowReviewModal, communityInfo, onPress, params }) => {
  const currentTheme = getCurrentTheme();

  const IS_SHOW_TALENTS_KEY = formatCommunityName(TALENTS_KEY, communityInfo.name);

  const { value: isShowTalentsValue } = useFeatureFlag(IS_SHOW_TALENTS_KEY, false);

  return (
    <View style={[s.contentContainer]}>
      {isShowTalentsValue && (
        <TouchableItem
          style={[rs.alignCenter, rs.bigMarinRight]}
          onPress={() => {
            onPress();
            if (!!isShowReviewModal) {
              setTimeout(
                () => ModalsService.showModal(modalTypes.ITEM_REVIEW, { isTalent: true }),
                600,
              );
            } else {
              NavigationService.navigate(screens.EditTalentStack, { screen: screens.EditTalent });
            }
          }}
        >
          <View style={[s.iconContainer, { backgroundColor: currentTheme.colors.secondary }]}>
            <SocialSkillShare color={colors.white} />
          </View>
          <Text bold color={colors.white}>
            {strings.talents.talents}
          </Text>
        </TouchableItem>
      )}

      <TouchableItem
        style={[rs.alignCenter, rs.bigMarinLeft, rs.bigMarinRight]}
        onPress={() => {
          onPress();
          if (!!isShowReviewModal) {
            setTimeout(() => ModalsService.showModal(modalTypes.ITEM_REVIEW), 600);
          } else {
            NavigationService.navigate(screens.EditItemStack, {
              screen: screens.EditItem,
            });
          }
        }}
      >
        <View style={[s.iconContainer, { backgroundColor: currentTheme.colors.secondary }]}>
          <FontIcon name="Item" size={36} color={colors.white} />
        </View>
        <Text bold color={colors.white}>
          {strings.items.item}
        </Text>
      </TouchableItem>

      <TouchableItem
        style={[rs.alignCenter, rs.bigMarinLeft]}
        onPress={() => {
          onPress();
          if (!!isShowReviewModal) {
            setTimeout(
              () => ModalsService.showModal(modalTypes.ITEM_REVIEW, { isWish: true }),
              600,
            );
          } else {
            NavigationService.navigate(screens.EditWishStack, { screen: screens.EditWish });
          }
        }}
      >
        <View
          style={[
            s.iconContainer,
            {
              backgroundColor: currentTheme.colors.secondary,
            },
          ]}
        >
          <FontIcon name="Wish1" size={36} color={colors.white} />
        </View>
        <Text bold color={colors.white}>
          {strings.wishes.wish}
        </Text>
      </TouchableItem>
    </View>
  );
};

export default connect(
  (state) => ({
    communityInfo: state.communityInfo,
    isShowReviewModal: state.guides.showAgain[guideTypes.ITEM_REVIEW],
  }),
  (dispatch) => ({
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
  }),
)(CreateLot);
