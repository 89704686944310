import React from 'react';
import { StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native';
import Lightbox from 'react-native-lightbox';
import { colors, rs } from '../../../../../styles';

const s = StyleSheet.create({
  container: {},
  image: {
    width: 150,
    height: 100,
    borderRadius: 13,
    margin: 3,
    resizeMode: 'cover',
    backgroundColor: colors.grayLighter,
  },
  imageActive: {
    flex: 1,
    resizeMode: 'contain',
  },
  closeButton: {
    fontSize: 35,
    color: 'white',
    lineHeight: 40,
    width: 40,
    textAlign: 'center',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 1.5,
    shadowColor: 'black',
    shadowOpacity: 0.8,
  },
});

const MessageImage = ({
  currentMessage,
  imageProps,
  containerStyle,
  imageStyle,
  lightboxProps = {},
}) => {
  if (!currentMessage) {
    return null;
  }

  return (
    <View style={[s.container, containerStyle]}>
      <Lightbox
        activeProps={{
          style: s.imageActive,
        }}
        underlayColor={colors.transparent}
        backgroundColor={colors.grayDarker}
        renderHeader={(close) => (
          <TouchableOpacity onPress={close} style={rs.bigMarginTop}>
            <Text style={s.closeButton}>×</Text>
          </TouchableOpacity>
        )}
        {...lightboxProps}
      >
        <Image
          {...imageProps}
          style={[s.image, imageStyle]}
          source={{ uri: currentMessage.image }}
        />
      </Lightbox>
    </View>
  );
};

export default MessageImage;
