import React from 'react';
import * as R from 'ramda';
import { LayoutAnimation, StyleSheet, View } from 'react-native';
import * as Sentry from '@sentry/react-native';
import { connect } from 'react-redux';
import { numberWithCommas } from '../../../../utils/stringHelper';
import { colors, dimensions, rs } from '../../../../styles';
import TouchableItem from '../../TouchableItem/index.android';
import Text from '../../Text';
import Ionicons from 'react-native-vector-icons/Ionicons';
import itemTypes from '../../../../constants/itemTypes';
import styled from 'styled-components/native';
import LinearGradient from 'react-native-linear-gradient';
import FavoriteIcon from '../../FavoriteIcon';
import AppImage from '../../AppImage';
import PromotionTag from '../../PromotionTag';
import Image from '../../Image';
import { isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';
import { SHARINGOOD_COMMUNITY } from '../../../../constants/communities';

const imageSize = (dimensions.width - 3 * dimensions.medium) / 3;

const s = StyleSheet.create({
  contentContainer: {
    borderColor: colors.grayLight,
    borderWidth: dimensions.hairline,
    marginBottom: dimensions.halfMedium,
    paddingBottom: dimensions.halfMedium,
    borderRadius: 5,
    marginHorizontal: 4,
    backgroundColor: colors.white,
    position: 'relative',
  },
  infoContainer: {
    height: 36,
    flexDirection: 'row',
    alignItems: 'center',

    justifyContent: 'space-between',
    paddingHorizontal: dimensions.halfMedium,
  },
  image: {
    width: imageSize,
    height: imageSize,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    maxHeight: 250,
    maxWidth: 250,
  },
  borderBottomRadius: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  oldPriceText: {
    alignSelf: 'flex-end',
    paddingHorizontal: dimensions.halfMedium,
    textDecorationLine: 'line-through',
  },
  emptyItem: {
    width: imageSize,
    height: imageSize + 36,
    marginBottom: dimensions.halfMedium,
    paddingBottom: dimensions.halfMedium,
    marginHorizontal: 4,
    maxHeight: 250,
    maxWidth: 250,
  },
  priceImage: {
    width: 12,
    height: 12,
    marginRight: 2,
  },
});

const DiscountContainer = styled.View`
  position: absolute;
  border-top-right-radius: 5px;
  overflow: hidden;
  bottom: 0;
  left: 0;
  height: 18%;
  min-width: 30%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DiscountOpacityView = styled(LinearGradient)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

class LotListItem extends React.PureComponent {
  // componentWillMount() {
  //   LayoutAnimation.easeInEaseOut();
  // }
  //
  // componentWillUnmount() {
  //   LayoutAnimation.easeInEaseOut();
  // }

  onLoadImageError = (error) => {
    if (error) {
      Sentry.captureException(error);
    }
  };

  render() {
    const {
      lot,
      currentUserId,
      onPress,
      onToggleFavorites,
      itemType,
      isSoldItem,
      showFavoriteIcon = true,
      hasActivePromotion = false,
      imageSize,
    } = this.props;

    if (R.isEmpty(lot)) {
      return <View style={[s.emptyItem, isWeb && { width: imageSize, height: imageSize }]} />;
    }
    const isWish = lot.is_wish;
    const isActiveDiscount = lot.discount_rate && lot.discounted_price;
    const imageUrl = isWish ? lot.wish_image : lot.item_image;

    const isActive = itemType !== itemTypes.SOLD && itemType !== itemTypes.PURCHASED;
    const isCurrentUserItem = Number(lot.seller_id) === currentUserId;
    const isPromotionTagVisible = hasActivePromotion && !isCurrentUserItem && !isWish;

    const currentTheme = getCurrentTheme();

    const { communityName } = this.props;

    return (
      <TouchableItem
        style={[s.contentContainer, { maxHeight: imageSize + 72 }]}
        withoutOpacity
        onPress={() => onPress(lot)}
      >
        <View>
          {isPromotionTagVisible && <PromotionTag />}
          <AppImage
            source={{ uri: imageUrl }}
            resizeMode="cover"
            style={[
              s.image,
              isWish && s.borderBottomRadius,
              isWeb && { width: imageSize, height: imageSize },
            ]}
            onError={this.onLoadImageError}
          />
          {isActiveDiscount && (
            <DiscountContainer>
              <DiscountOpacityView
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={[currentTheme.colors.primary, currentTheme.colors.secondaryLight]}
              />
              <View style={rs.smallPaddingHorizontal}>
                <Text semiBold color={colors.white} xsmall>
                  -{lot.discount_rate}%
                </Text>
              </View>
            </DiscountContainer>
          )}
        </View>
        {!isWish && (
          <View>
            <View style={s.infoContainer}>
              <View style={[rs.row, rs.alignCenter]}>
                {communityName !== SHARINGOOD_COMMUNITY && (
                  <Image style={s.priceImage} source={{ uri: 'price' }} resizeMode="contain" />
                )}
                <Text bold color={colors.secondary}>
                  {communityName === SHARINGOOD_COMMUNITY && '🧡'}{' '}
                  {numberWithCommas(lot.discounted_price) || numberWithCommas(lot.price)}
                </Text>

                {lot.discounted_price && (
                  <Text xsmall color={colors.gray} style={s.oldPriceText}>
                    {numberWithCommas(lot.price)}
                  </Text>
                )}
              </View>

              {!isSoldItem && !isWish && !isCurrentUserItem && isActive && showFavoriteIcon && (
                <TouchableItem onPress={() => onToggleFavorites(lot)}>
                  <FavoriteIcon isFavorite={lot.mark_as_favorite} size={18} />
                </TouchableItem>
              )}
            </View>
            {isWeb && (
              <Text
                numberOfLines={1}
                style={[rs.smallPaddingHorizontal, { maxWidth: imageSize }]}
                xsmall
                color={colors.secondary}
              >
                {lot.title}
              </Text>
            )}
          </View>
        )}
        {/*{isActiveDiscount && (*/}
        {/*  <DiscountContainer>*/}
        {/*    <DiscountOpacityView />*/}
        {/*    <PriceContainer>*/}
        {/*      <PriceValueText semiBold>-{lot.discount_rate}%</PriceValueText>*/}
        {/*    </PriceContainer>*/}
        {/*  </DiscountContainer>*/}
        {/*)}*/}
        {/*{lot.mark_as_favorite && (*/}
        {/*  <FavoriteContainer>*/}
        {/*    <Ionicons*/}
        {/*      name={lot.mark_as_favorite ? 'md-heart' : 'md-heart-empty'}*/}
        {/*      color={lot.mark_as_favorite ? colors.violetLight : colors.white}*/}
        {/*      size={20}*/}
        {/*    />*/}
        {/*  </FavoriteContainer>*/}
        {/*)}*/}
      </TouchableItem>
    );
  }
}

export default connect((state) => ({
  communityName: state.communityTheme.theme,
}))(LotListItem);
