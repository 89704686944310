import React, { memo } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { CategoriesContainer, CategoriesList, CategoryButton, CheckIcon, styles } from './styles';
import { colors } from '../../../../../../../styles';
import { FontIcon, Text } from '../../../../../../ReusableComponents';
import { groupsSelectors } from '../../../../../../../store/groups';
import { getCurrentTheme } from '../../../../../../../templates';

const CategoryItem = memo(({ isActive, onSelect, category }) => {
  const currentTheme = getCurrentTheme();
  return (
    <CategoryButton onPress={() => onSelect(category.id)}>
      {isActive && (
        <CheckIcon name="check-circle" size={14} color={currentTheme.colors.secondary} />
      )}
      <FontIcon
        name={category.icon_name}
        size={28}
        color={isActive ? currentTheme.colors.secondary : colors.text}
      />
      <Text
        xsmall
        style={[
          styles.categoryText,
          {
            color: isActive ? currentTheme.colors.secondaryLight : colors.text,
          },
        ]}
      >
        {category.name}
      </Text>
    </CategoryButton>
  );
});

const Categories = memo(({ selectedCategoryIds, parentCategories, onSelectCategory }) => {
  return (
    <CategoriesContainer>
      <CategoriesList
        data={parentCategories}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <CategoryItem
            isActive={selectedCategoryIds.includes(item.id)}
            category={item}
            onSelect={onSelectCategory}
          />
        )}
      />
    </CategoriesContainer>
  );
});

Categories.propTypes = {
  parentCategories: T.array,
  selectedCategoryIds: T.array,
  onSelectCategory: T.func,
};

export default connect((state, props) => ({
  parentCategories: groupsSelectors.getSelectedCommunityParentCategoriesList(props.categories),
}))(Categories);
