import React, { memo } from 'react';
import { StyleSheet, Switch } from 'react-native';
import { Box } from './styles';
import strings from '../../../../../localization';
import Text from '../../../../ReusableComponents/Text';
import { colors } from '../../../../../styles';
import { getCurrentTheme } from '../../../../../templates';

const MultipleGroupItemToggle = memo(({ hasMultipleSupply, onMultipleSupplyToggle }) => {
  const currentTheme = getCurrentTheme();
  return (
    <Box style={s.rowContainer}>
      <Text color={colors.gray}>{strings.items.group_item}</Text>
      <Switch
        trackColor={{ false: colors.grayLight, true: currentTheme.colors.secondary }}
        thumbColor={colors.white}
        ios_backgroundColor={colors.grayLight}
        value={hasMultipleSupply}
        onValueChange={onMultipleSupplyToggle}
      />
    </Box>
  );
});

const s = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default MultipleGroupItemToggle;
