import React, { Fragment, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { connect } from 'react-redux';
import { View } from 'react-native';
import T from 'prop-types';
import { offersOperations, offersSelectors } from '../../../store/offers';
import {
  EmptyListWithIcon,
  HeaderContainer,
  FilterHorizontalList,
  FilterOptions,
  FilterButton,
  Separator,
  EmptyListWithImage,
} from '../../ReusableComponents';
import SearchBar from '../../ReusableComponents/SearchBar';
import { rs } from '../../../styles';
import strings from '../../../localization';
import { BUYING } from '../../../constants/offerTypes';
import BuyingOffer from './components/BuyingOffer';
import OffersList from './components/OffersList';
import { LoadingService } from '../../../services';
import NavigationService from '../../../services/NavigationService';
import screens from '../../../navigation/screens';
import { groupsSelectors } from '../../../store/groups';
import { CONFIRM_CANCEL_PURCHASE, OFFER_CONFIRMED } from '../../../store/offers/offerStatuses';
import { isWeb } from '../../../utils/detectDevice';

const BuyingOffers = ({
  performAction,
  groups,
  offers,
  isLoading,
  isLoadingMore,
  getBuyingSearchedOffers,
}) => {
  const [isRefreshing, setRefreshing] = useState(false);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [selectedOfferStatuses, setSelectedOfferStatuses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getBuyingSearchedOffers({ isLoadMore: false, statusIds: [], groupIds: [] });
  }, []);

  const [getOffersDebounce] = useDebouncedCallback((newSearchTerm) => {
    getBuyingSearchedOffers({
      searchTerm: newSearchTerm,
      groupIds: selectedGroupIds,
      statusIds: selectedOfferStatuses,
    });
  }, 400);

  const onRefresh = async () => {
    setRefreshing(true);
    await getBuyingSearchedOffers({
      searchTerm,
      groupIds: selectedGroupIds,
      statusIds: selectedOfferStatuses,
    });
    setRefreshing(false);
  };

  const onLoadMore = () => {
    getBuyingSearchedOffers({
      isLoadMore: true,
      searchTerm,
      groupIds: selectedGroupIds,
      statusIds: selectedOfferStatuses,
    });
  };

  const onChangeSearch = async (value) => {
    setSearchTerm(value);
    getOffersDebounce(value);
  };

  const onPerformAction = async (offerId, actionId) => {
    LoadingService.showSuccessLoader();
    await performAction(offerId, actionId);
    LoadingService.hideSuccessLoader();
  };

  const onOpenChat = (chatId) => {
    NavigationService.push(screens.ChatRoom, { chatId });
  };

  const onChangeGroupFilter = (groupIds) => {
    setSelectedGroupIds(groupIds);
    getBuyingSearchedOffers({
      searchTerm,
      groupIds,
      statusIds: selectedOfferStatuses,
    });
  };

  const onChangeTypeFilter = (statusIds) => {
    setSelectedOfferStatuses(statusIds);
    getBuyingSearchedOffers({
      searchTerm,
      groupIds: selectedGroupIds,
      statusIds,
    });
  };

  const offerStatuses = [
    {
      title: strings.buy_sell.pending_delivery,
      statusId: OFFER_CONFIRMED,
    },
    {
      title: strings.buy_sell.asked_to_buy,
      statusId: CONFIRM_CANCEL_PURCHASE,
    },
  ];

  return (
    <Fragment>
      <HeaderContainer withoutBorderBottom>
        <SearchBar
          showCancel
          alwaysShowCancel
          value={searchTerm}
          placeholder={strings.other.search}
          onCancel={() => NavigationService.goBack()}
          onChangeText={onChangeSearch}
          enablesReturnKeyAutomatically
          returnKeyType="search"
        />
      </HeaderContainer>
      <View style={rs.row}>
        <FilterHorizontalList contentContainerStyle={rs.fullWidth}>
          <Fragment>
            <FilterOptions
              options={groups}
              optionKeyName="id"
              optionTitleName="group_name"
              selectedOptionIds={selectedGroupIds}
              onChange={onChangeGroupFilter}
            />
            <FilterOptions
              options={offerStatuses}
              optionKeyName="statusId"
              optionTitleName="title"
              selectedOptionIds={selectedOfferStatuses}
              onChange={onChangeTypeFilter}
            />
            <FilterButton
              primaryButtonTitle={strings.filters.group}
              options={groups}
              optionKeyName="id"
              optionTitleName="group_name"
              selectedOptionIds={selectedGroupIds}
              onChange={onChangeGroupFilter}
              filterTitle={strings.filters.filter_by_groups}
            />
            <FilterButton
              primaryButtonTitle={strings.buy_sell.type}
              options={offerStatuses}
              optionKeyName="statusId"
              optionTitleName="title"
              selectedOptionIds={selectedOfferStatuses}
              onChange={onChangeTypeFilter}
              filterTitle={strings.list_filters.filter_by_offer_type}
            />
          </Fragment>
        </FilterHorizontalList>
      </View>
      <Separator />

      <OffersList
        data={isLoading && !isRefreshing ? [] : offers}
        isRefreshing={isRefreshing}
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        onLoadMore={onLoadMore}
        onRefresh={onRefresh}
        ListEmptyComponent={() => (
          <EmptyListWithImage
            image="empty_state_buying"
            text={
              searchTerm
                ? strings.emptyLists.empty_items_search
                : strings.emptyLists.empty_offers_list
            }
          />
        )}
        renderItem={({ item }) => (
          <BuyingOffer
            isShowStatus
            offerType={BUYING}
            offer={item}
            onOpenChat={onOpenChat}
            onPerformAction={onPerformAction}
          />
        )}
      />
    </Fragment>
  );
};

BuyingOffers.propTypes = {
  performAction: T.func,
  groups: T.array,
  offers: T.array,
  isLoading: T.bool,
  isLoadingMore: T.bool,
  getBuyingSearchedOffers: T.func,
};

export default connect(
  (state, { searchValue }) => ({
    offers: offersSelectors.getBuyingSearchedOffers(state, searchValue),
    isLoading: state.offers.buyingOffers.searched.isLoading,
    isLoadingMore: state.offers.buyingOffers.searched.isLoadingMore,
    groups: groupsSelectors.getCommunityGroups(state),
  }),
  (dispatch) => ({
    performAction: (offerId, actionId) =>
      dispatch(offersOperations.performAction(offerId, actionId)),
    getBuyingSearchedOffers: ({ searchTerm, groupIds, statusIds, isLoadMore }) =>
      dispatch(
        offersOperations.getBuyingSearchedOffers({
          searchTerm,
          groupIds,
          statusIds,
          isLoadMore,
        }),
      ),
  }),
)(BuyingOffers);
