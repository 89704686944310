import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';

export default StyleSheet.create({
  filterList: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
  },
  listHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    paddingVertical: dimensions.small,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
  },
  listHeaderText: {
    flexGrow: 1,
  },
});
