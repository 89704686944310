import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import Icon from 'react-native-vector-icons/FontAwesome';
import {
  Separator,
  BackButton,
  View,
  Button,
  Text,
  Container,
  IconButton,
  TokensHorizontalList,
} from '../../../ReusableComponents';
import s from './styles';
import { colors, rs, headerStyle, dimensions } from '../../../../styles';
import strings from '../../../../localization';
import { NavigationService, LoadingService } from '../../../../services';
import { isRTL } from '../../../../utils/rtlHelper';
import tokensExchangeOperations from '../../../../store/tokensExchange/operations';
import { groupsSelectors } from '../../../../store/groups';
import screens from '../../../../navigation/screens';
import { getCurrentTheme } from '../../../../templates';

class TokensExchange extends Component {
  static navigationOptions = () => ({
    ...headerStyle,
    headerTitle: strings.tokens_exchange.title,
    headerLeft: <BackButton isCancel />,
    headerRight: <View />,
  });

  state = {
    offer: null,
    missingAmount: null,
    selectedToken: null,
    toTokenName: '',
    exchangeAmount: '0',
    tokensForExchange: [],
    itemTitle: '',
    itemPrice: 0,
    isLoading: false,
  };

  async componentDidMount() {
    const { route, getTokensForExchange } = this.props;
    const {
      missing_amount,
      to_token_name,
      item_title,
      item_price,
      offer,
    } = route.params.exchange_params;

    this.setState({
      offer,
      missingAmount: missing_amount,
      toTokenName: to_token_name,
      itemTitle: item_title,
      itemPrice: item_price,
    });

    this.setState({ isLoading: true });
    const tokens = await getTokensForExchange(missing_amount);

    this.setState({
      tokensForExchange: tokens,
      isLoading: false,
    });

    this.onSelectTokenForExchange(tokens[0]);
  }

  onSubmit = async () => {
    const { performExchange, groupInfo, triggerAskToBuy } = this.props;
    const { selectedToken, exchangeAmount, offer } = this.state;

    LoadingService.showSuccessLoader();

    try {
      await performExchange({
        token_id: selectedToken.token_id,
        to_token_id: groupInfo.token_id,
        exchange_amount: exchangeAmount,
      });
      await triggerAskToBuy(offer);

      LoadingService.hideSuccessLoader({
        callback: () => {
          NavigationService.navigate(screens.Inbox);
        },
      });
    } catch (error) {
      LoadingService.hideSuccessLoader({ isSuccess: false });
    }
  };

  onSelectTokenForExchange = (token) => {
    const { missingAmount, selectedToken } = this.state;

    if (selectedToken === token.id) return;

    const reversedExchangeRate = 1 / token.exchange_rate;
    const exchangeAmount = Math.round(reversedExchangeRate * missingAmount);

    this.setState({
      selectedToken: token,
      exchangeAmount: String(exchangeAmount),
      missingAmount,
    });
  };

  onOpenExchangeRules = () => {
    const { itemTitle } = this.state;
    const { groupInfo } = this.props;
    NavigationService.navigate(screens.ExchangeRules, {
      item_title: itemTitle,
      communityId: groupInfo.group_id,
    });
  };

  render() {
    const {
      missingAmount,
      selectedToken,
      exchangeAmount,
      tokensForExchange,
      toTokenName,
      itemTitle,
      itemPrice,
      isLoading,
    } = this.state;

    const currentTheme = getCurrentTheme();

    return (
      <Container>
        <View style={[rs.flex, rs.paddingVertical]}>
          <Text alignCenter large style={[rs.marginBottom, rs.paddingHorizontal]}>
            {strings.tokens_exchange.missing}{' '}
            <Text large semiBold color={currentTheme.colors.secondaryLight}>
              {missingAmount}
            </Text>{' '}
            {strings.tokens_exchange.out_of} {itemPrice} {toTokenName}
            {'\n'}
            {strings.tokens_exchange.to_purchase} {itemTitle}
          </Text>

          <Separator medium />

          <View style={[s.titleContainer, rs.bigMarginTop, rs.bigMarginBottom]}>
            <Text large semiBold>
              {strings.tokens_exchange.available_tokens_for_exchange}
            </Text>
            {/* <IconButton */}
            {/*  withSmallPadding */}
            {/*  name="info" */}
            {/*  size={16} */}
            {/*  color={colors.blue} */}
            {/*  containerStyle={s.infoIconContainer} */}
            {/*  onPress={this.onOpenExchangeRules} */}
            {/* /> */}
          </View>

          <View style={s.transactionDetailsContainer}>
            <TokensHorizontalList
              isLoading={isLoading}
              tokens={tokensForExchange}
              selectedToken={selectedToken}
              onSelectToken={this.onSelectTokenForExchange}
            />
            {!!selectedToken && (
              <Fragment>
                {isRTL ? (
                  <Text large alignCenter>
                    <Text large semiBold color={currentTheme.colors.secondaryLight}>
                      {missingAmount}
                    </Text>
                    {'  '}
                    {toTokenName}{' '}
                    <Icon name="long-arrow-left" size={dimensions.medium} color={colors.gray} />
                    {'  '}
                    <Text large semiBold color={currentTheme.colors.secondaryLight}>
                      {exchangeAmount}
                    </Text>{' '}
                    {selectedToken.token_name}
                  </Text>
                ) : (
                  <Text large alignCenter>
                    <Text large semiBold color={currentTheme.colors.secondaryLight}>
                      {exchangeAmount}
                    </Text>
                    {'  '}
                    {selectedToken.token_name}{' '}
                    <Icon name="long-arrow-right" size={dimensions.medium} color={colors.gray} />
                    {'  '}
                    <Text large semiBold color={currentTheme.colors.secondaryLight}>
                      {missingAmount}
                    </Text>{' '}
                    {toTokenName}
                  </Text>
                )}
              </Fragment>
            )}
          </View>
        </View>
        <View style={s.buttonContainer}>
          <Button
            title={strings.tokens_exchange.exchange_and_buy}
            onPress={this.onSubmit}
            disabled={isLoading}
          />
        </View>
      </Container>
    );
  }
}

TokensExchange.propTypes = {
  groupInfo: T.object,
  triggerAskToBuy: T.func,
  performExchange: T.func,
  getTokensForExchange: T.func,
};

export default connect(
  (state, { navigation }) => ({
    groupInfo: {},
    walletBalance: state.AppData.balance,
  }),
  (dispatch, { route }) => ({
    getTokensForExchange: (missingAmount) =>
      dispatch(
        tokensExchangeOperations.getTokensForExchange(
          missingAmount,
          route.params.exchange_params.offer.group_id,
        ),
      ),
    performExchange: ({ token_id, to_token_id, exchange_amount }) =>
      dispatch(
        tokensExchangeOperations.performExchange({
          token_id,
          to_token_id,
          exchange_amount,
        }),
      ),
    triggerAskToBuy: (offer) => {
      dispatch(tokensExchangeOperations.triggerAskToBuy(offer));
    },
  }),
)(TokensExchange);
