import React from 'react';
import { View, Linking, StyleSheet, TouchableWithoutFeedback } from 'react-native';
import FastImage from '../FastImage';
import T from 'prop-types';
import { colors, dimensions } from '../../../styles';
import Text from '../Text';

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
    paddingHorizontal: dimensions.halfMedium,
    overflow: 'hidden',
    marginTop: dimensions.medium,
    marginBottom: dimensions.halfMedium,
  },
  infoContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    borderLeftWidth: 2,
    borderLeftColor: colors.text,
    paddingLeft: dimensions.halfMedium,
    overflow: 'hidden',
  },
  grayBorder: {
    borderLeftColor: colors.gray,
  },
  smallContainer: {
    flexGrow: 1,
    borderWidth: 1,
    justifyContent: 'flex-start',
    padding: 5,
    margin: 10,
  },
  image: {
    resizeMode: 'contain',
    height: 84,
    width: 84,
    marginLeft: dimensions.halfMedium,
    borderRadius: dimensions.borderRadius,
  },
  textContent: {
    flex: 1,
    paddingTop: 2,
    paddingBottom: 4,
  },
  opengraphWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const OpenGraphDisplay = ({ data, isLight }) => {
  const onPressLink = async () => {
    const { url } = data;

    Linking.canOpenURL(url)
      .then((supported) => {
        if (!supported) {
          console.log(`Can't handle url: ${url}`);
          return null;
        }
        return Linking.openURL(url);
      })
      .catch((err) => console.error('An error occurred', err));
  };

  return (
    <View style={styles.opengraphWithIcon}>
      {data.title || data.description || data.image ? (
        <TouchableWithoutFeedback onPress={onPressLink}>
          <View style={styles.container}>
            <View style={[styles.infoContainer, !isLight && styles.grayBorder]}>
              <View style={styles.textContent}>
                <Text medium bold color={colors.text}>
                  {data.title || ''}
                </Text>
                <Text style={styles.description} numberOfLines={4} color={colors.text}>
                  {data.description || ''}
                </Text>
              </View>
              {data.image && <FastImage style={styles.image} source={{ uri: data.image }} />}
            </View>
          </View>
        </TouchableWithoutFeedback>
      ) : (
        <TouchableWithoutFeedback onPress={onPressLink}>
          <View style={styles.smallContainer}>
            <Text style={styles.url}>{data.url ? data.url.toLowerCase() : ''}</Text>
          </View>
        </TouchableWithoutFeedback>
      )}
    </View>
  );
};

OpenGraphDisplay.propTypes = {
  data: T.shape({
    url: T.string,
    image: T.string,
    title: T.string,
    description: T.string,
  }),
};

export default OpenGraphDisplay;
