import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import colors from '../../../../../../../styles/colors';
import { medium } from '../../../../../../../styles/dimensions';
import { Icon } from '../../../../../../ReusableComponents';

export const styles = StyleSheet.create({
  categoryText: {
    color: colors.text,
    textAlign: 'center',
    marginTop: 12,
  },
});

export const CategoriesContainer = styled.View`
  transform: scaleX(-1);
  align-items: flex-end;
`;

export const CategoriesList = styled.FlatList.attrs({
  horizontal: true,
  inverted: true,
  showsHorizontalScrollIndicator: false,
  contentContainerStyle: {
    paddingHorizontal: 1,
  },
})`
  min-height: 30px;
  background-color: ${colors.white};
  margin-bottom: ${medium};
`;

export const CategoryButton = styled.TouchableOpacity`
  transform: scaleX(-1);
  align-items: center;
  width: 74px;
  padding-top: ${medium};
  padding-horizontal: 1px;
`;

export const CheckIcon = styled(Icon)`
  position: absolute;
  top: 6;
  right: 12;
`;
