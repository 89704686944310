import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Linking, I18nManager } from 'react-native';
import T from 'prop-types';
import * as RNLocalize from 'react-native-localize';
import { authOperations } from '../../../../store/auth';
import {
  Text,
  TextButton,
  TouchableItem,
  Container,
  Image,
  ScreenHeader,
} from '../../../ReusableComponents';
import s from './styles';
import { colors, dimensions, fontSizes, rs } from '../../../../styles';
import strings from '../../../../localization';
import appLanguages from '../../../../constants/appLanguages';
import { CONTACT_US, CONTACT_US_BRAZIL } from '../../../../api/urls';
import CodeConfirmationInput from './components/CodeConfirmationInput';
import { AnalyticsService } from '../../../../services';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { useGetWindowCurrentWidthForClassComponent } from '../../../../utils/getWindowCurrentWidth';
import { getPhoneImages } from '../LoginScreen';
import StoreImages from '../LoginScreen/components/StoreImages';
import NavigationButton from '../../../ReusableComponents/NavigationButtons';

const INITIAL_COUNTER_VALUE = 29;

const getSupportLink = () => {
  const countryCode = RNLocalize.getCountry()?.toLowerCase();

  return countryCode === appLanguages.BR ? CONTACT_US_BRAZIL : CONTACT_US;
};

class CodeVerification extends Component {
  state = {
    code: '',
    phoneNumber: '',

    isAvailableSendAgain: false,
    languageCode: RNLocalize.getLocales()[0].languageCode,

    timer: null,
    counter: INITIAL_COUNTER_VALUE,
    isRTL: I18nManager.getConstants().isRTL,
  };

  componentDidMount() {
    const {
      navigation,
      route,
      isMobileWindow,
      windowCurrentWidth,
      windowCurrentHeight,
    } = this.props;

    navigation.setParams({
      isValidCode: false,
      nextClicked: this.nextClicked,
    });

    const phoneNumber = route.params.phoneNumber;

    let timer = setInterval(this.tick, 1000);
    this.setState({ phoneNumber, timer });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  tick = () => {
    if (this.state.counter > 1) {
      this.setState({
        counter: this.state.counter - 1,
      });
    } else {
      clearInterval(this.state.timer);
      this.setState({
        isAvailableSendAgain: true,
      });
    }
  };

  onChangeCode = (code) => {
    const { navigation } = this.props;

    this.setState({ code });
    if (code.length === 4) {
      navigation.setParams({
        isValidCode: true,
      });
      setTimeout(() => this.nextClicked(), 1);
      return;
    }
    navigation.setParams({
      isValidCode: false,
    });
  };

  onSendCodeAgain = () => {
    const { route } = this.props;
    const onSendCodeAgain = route.params.onSendCodeAgain;

    onSendCodeAgain();
    let timer = setInterval(this.tick, 1000);
    this.setState({ timer, isAvailableSendAgain: false, counter: INITIAL_COUNTER_VALUE });
    AnalyticsService.logEvent(analyticsEventTypes.onboarding_send_code_again);
  };

  nextClicked = async () => {
    const { code, phoneNumber } = this.state;
    const { codeVerification } = this.props;

    await codeVerification(phoneNumber, code);
    AnalyticsService.logEvent(analyticsEventTypes.onboarding_confirm_code);
  };

  render() {
    const { code, counter, isAvailableSendAgain, languageCode, isRTL } = this.state;
    const { isMobileWindow, windowCurrentWidth, windowCurrentHeight, route } = this.props;
    const counterFormatted = `0:${counter.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;

    return isMobileWindow ? (
      <Container style={[rs.alignCenter]}>
        <View style={[rs.webContainer, rs.alignCenter]}>
          <ScreenHeader
            submitButton={() => (
              <NavigationButton
                title={strings.common.next}
                disabled={!route.params?.isValidCode ?? false}
                onButtonPress={route.params?.nextClicked ?? null}
              />
            )}
            withoutBorder
            headerTitle={route.params?.phoneNumber}
          />
          <Image style={s.image} source={{ uri: 'verification_code' }} resizeMode="contain" />

          <Text alignCenter medium style={[rs.veryBigMarginBottom, rs.bigMarginTop]}>
            {strings.login.please_enter_code}
          </Text>

          <CodeConfirmationInput
            onChangeText={(verificationCode) => this.onChangeCode(verificationCode)}
            value={code}
            rootStyle={{
              flexDirection: isRTL ? 'row-reverse' : 'row',
            }}
          />

          <View style={rs.marginTop}>
            {isAvailableSendAgain ? (
              <TouchableItem onPress={this.onSendCodeAgain}>
                <Text color={colors.blue}>{strings.login.send_code_again}</Text>
              </TouchableItem>
            ) : (
              <Text>
                {strings.login.resend_code_in} {counterFormatted}
              </Text>
            )}
          </View>

          <View style={[rs.row, rs.bigMarginTop, rs.alignCenter]}>
            <Text> {strings.login.didnt_get_code} </Text>
            <TextButton
              small
              title={strings.login.contact_us}
              onPress={() => Linking.openURL(getSupportLink())}
            />
          </View>
        </View>
      </Container>
    ) : (
      <Container style={[rs.alignCenter, rs.justifyCenter]}>
        <View style={[rs.row, rs.justifyCenter, rs.alignCenter, { height: 600 }]}>
          <View>
            <Image
              style={[
                s.phoneImage,
                { width: windowCurrentWidth / 1.5, height: windowCurrentHeight },
              ]}
              source={{ uri: getPhoneImages(languageCode) }}
              resizeMode="contain"
            />
          </View>
          <View style={[rs.alignCenter, rs.justifyCenter, rs.bigMarginHorizontal, rs.marginTop]}>
            <View style={s.shadow}>
              <View style={[rs.webContainer, rs.alignCenter]}>
                <ScreenHeader
                  submitButton={() => (
                    <NavigationButton
                      title={strings.common.next}
                      disabled={!route.params?.isValidCode ?? false}
                      onButtonPress={route.params?.nextClicked ?? null}
                      style={rs.smallPaddingHorizontal}
                    />
                  )}
                  withoutBorder
                  headerTitle={route.params?.phoneNumber}
                  headerTitleStyle={s.headerTitleStyle}
                />
                <View style={[rs.alignCenter, { height: '75%' }]}>
                  <Image
                    style={s.imageWeb}
                    source={{ uri: 'verification_code' }}
                    resizeMode="contain"
                  />

                  <Text alignCenter xsmall style={[s.titleWeb]}>
                    {strings.login.please_enter_code}
                  </Text>

                  <CodeConfirmationInput
                    onChangeText={(verificationCode) => this.onChangeCode(verificationCode)}
                    value={code}
                    rootStyle={{
                      width: 275,
                      height: 45,
                      marginHorizontal: dimensions.medium,
                      paddingHorizontal: dimensions.small,
                      paddingTop: 0,
                      paddingBottom: 0,
                      flexDirection: isRTL ? 'row-reverse' : 'row',
                    }}
                  />

                  <View style={[rs.marginTop, rs.bigMarginBottom]}>
                    {isAvailableSendAgain ? (
                      <TouchableItem onPress={this.onSendCodeAgain}>
                        <Text small color={colors.blue}>
                          {strings.login.send_code_again}
                        </Text>
                      </TouchableItem>
                    ) : (
                      <Text small>
                        {strings.login.resend_code_in} {counterFormatted}
                      </Text>
                    )}
                  </View>

                  <View style={[rs.row, rs.bigMarginTop, rs.alignCenter]}>
                    <Text> {strings.login.didnt_get_code} </Text>
                    <TextButton
                      small
                      title={strings.login.contact_us}
                      onPress={() => Linking.openURL(getSupportLink())}
                    />
                  </View>
                </View>
              </View>
            </View>
            <StoreImages />
          </View>
        </View>
      </Container>
    );
  }
}

CodeVerification.propTypes = {
  codeVerification: T.func.isRequired,
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    codeVerification: (phoneNumber, code) =>
      dispatch(authOperations.codeVerificationForWeb(phoneNumber, code)),
  }),
)(useGetWindowCurrentWidthForClassComponent(CodeVerification));
