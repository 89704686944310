import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { View, SafeAreaView } from 'react-native';
import Clipboard from '@react-native-community/clipboard';
import T from 'prop-types';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';
import {
  Container,
  Text,
  TextButton,
  TouchableItem,
  BackButton,
  FontIcon,
  Image,
} from '../../ReusableComponents';
import s from './styles';
import { rewardsOperations } from '../../../store/rewards';
import strings from '../../../localization';
import { numberWithCommas } from '../../../utils/stringHelper';
import { NavigationService, ToastsService, AnalyticsService } from '../../../services';
import screens from '../../../navigation/screens';
import * as analyticsEventTypes from '../../../constants/analyticsEventTypes';
import { colors, rs } from '../../../styles';
import useOnce from '../../../utils/useOnce';
import { shareReferralDynamicLink } from '../../../utils/shareHelper';
import { JOIN_COMMUNITY } from '../../../constants/dynamicalLinkActionTypes';
import reactStringReplace from 'react-string-replace';
import uuid from 'uuid/v4';
import ScrollView from '../../ReusableComponents/Containers/ScrollView';
import { isWeb } from '../../../utils/detectDevice';
import { getCurrentTheme } from '../../../templates';

const InviteFriends = ({ communityInfo, user, getUserReferralRewards }) => {
  const [referralRewards, setReferralRewards] = useState({
    total_referral_reward: 0,
    total_sold_first_items: 0,
    total_users_signed: 0,
    referral_joined_community_reward: 0,
    referral_reward: 0,
    referral_sold_first_item_reward: 0,
    max_reward_amount_per_month: 0,
    max_token_rewards: 0,
  });

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    onFetch();
  }, []);

  const onFetch = async () => {
    const rewards = await getUserReferralRewards();
    setReferralRewards(rewards);
  };

  const onShareApp = useOnce(() => {
    AnalyticsService.logEvent(analyticsEventTypes.invite_friends_share_button_pressed);
    shareReferralDynamicLink({
      actionType: JOIN_COMMUNITY,
      referralCode: communityInfo.referral_code,
      socialTitle: `${strings.invite_friends.share_title} ${communityInfo.referral_code}`,
      socialDescription: strings.invite_friends.share_description,
      socialImageUrl:
        'https://storage.googleapis.com/group-background-images-prod/22_1615474537775_36381722.jpg',
      modalTitle: strings.invite_friends.invite_friends,
    });
  });

  const onCopyCode = () => {
    if (isWeb) {
      window.navigator.clipboard.writeText(communityInfo.referral_code);
    } else {
      Clipboard.setString(communityInfo.referral_code);
      ToastsService.showSuccess(strings.invite_friends.code_copied);
    }
  };

  const {
    referral_reward,
    total_users_signed,
    total_sold_first_items,
    total_referral_reward,
    referral_joined_community_reward,
    referral_sold_first_item_reward,
    max_reward_amount_per_month,
    max_token_rewards,
  } = referralRewards;

  let description_text = strings.invite_friends.invite_friends_description;

  description_text = reactStringReplace(description_text, 'XX', (match, i) => (
    <Text color={currentTheme.colors.secondary}>
      {referral_joined_community_reward} {strings.invite_friends.shareitt_points}
    </Text>
  ));

  description_text = reactStringReplace(description_text, 'YY', (match, i) => (
    <Text color={currentTheme.colors.secondary}>
      {referral_sold_first_item_reward} {strings.invite_friends.more_points}
    </Text>
  ));

  return (
    <Container>
      <ScrollView>
        <View style={s.topContainer}>
          <SafeAreaView>
            <BackButton isClose />
          </SafeAreaView>

          <View style={[rs.paddingHorizontal, rs.alignCenter]}>
            {/*<Text alignCenter semiBold title style={rs.marginBottom}>*/}
            {/*  {strings.invite_friends.give} {referral_reward}, {strings.invite_friends.get}{' '}*/}
            {/*  {referral_joined_community_reward + referral_sold_first_item_reward}*/}
            {/*</Text>*/}

            <Text alignCenter semiBold title style={rs.marginBottom}>
              {strings.invite_friends.invite_friends_title}
            </Text>

            <Image style={s.image} source={{ uri: 'invite_friends_page' }} resizeMode="contain" />

            <Text alignCenter medium semiBold style={rs.marginVertical}>
              {strings.invite_friends.description_title_1}
              {'\n'}
              <Text alignCenter medium semiBold color={currentTheme.colors.secondary}>
                {strings.invite_friends.description_title_2}
              </Text>
            </Text>

            <Text alignCenter style={s.mainText}>
              {description_text}
            </Text>

            <TouchableItem style={s.codeContainer} onPress={onCopyCode}>
              <View style={[rs.bigPaddingHorizontal, rs.alignCenter]}>
                <Text xsmall>{strings.invite_friends.your_referral_code}</Text>
                <Text title bold>
                  {communityInfo.referral_code}
                </Text>
              </View>

              <View style={s.codeSeparator} />

              <View style={s.copyContainer}>
                <Text xsmall>{strings.invite_friends.copy_code}</Text>
              </View>
            </TouchableItem>

            <TouchableItem
              onPress={onShareApp}
              style={[s.inviteFriendsButton, { backgroundColor: currentTheme.colors.secondary }]}
            >
              <Text medium bold color={colors.white}>
                {strings.invite_friends.invite_friends}
              </Text>
            </TouchableItem>
          </View>
        </View>

        <View style={[rs.alignCenter, rs.paddingVertical]}>
          <Text semiBold medium color={colors.gray}>
            {strings.invite_friends.you_have_earned}
          </Text>

          <Text title bold style={rs.verySmallPaddingVertical} color={colors.gray}>
            {numberWithCommas(total_referral_reward)}
          </Text>

          <View style={s.usersJoinedContainer}>
            <TouchableItem
              style={[rs.flex, rs.alignCenter]}
              onPress={() => {
                NavigationService.navigate(screens.Referrals);
              }}
            >
              <Text medium semiBold color={currentTheme.colors.secondaryLight}>
                {total_users_signed} {strings.invite_friends.friends_joined}
              </Text>
            </TouchableItem>

            <View style={s.usersJoinedSeparator} />
            <TouchableItem
              style={[rs.flex, rs.alignCenter]}
              onPress={() => {
                NavigationService.navigate(screens.Referrals, { isSoldItemReferrals: true });
              }}
            >
              <Text
                medium
                semiBold
                color={currentTheme.colors.secondaryLight}
                style={s.usersJoinedText}
              >
                {total_sold_first_items} {strings.invite_friends.sold_an_item}
              </Text>
            </TouchableItem>
          </View>
        </View>
        {/*<TextButton onPress={onOpenAbout} alignCenter title={strings.invite_friends.how_it_works} />*/}
      </ScrollView>
    </Container>
  );
};

InviteFriends.propTypes = {
  communityInfo: T.object,
  user: T.object,
  getUserReferralRewards: T.func,
};

export default connect(
  (state) => ({
    communityInfo: state.communityInfo,
    user: state.userInfo,
  }),
  (dispatch) => ({
    getUserReferralRewards: () => dispatch(rewardsOperations.getUserReferralRewards()),
  }),
)(InviteFriends);
