export default [
  {
    id: '1',
    topicId: 1,
    topicTitle: {
      en: 'About Shareitt',
      he: 'על Shareitt',
      br: 'Quem somos',
      de: 'Über Shareitt',
    },
    title: {
      en: 'About Us',
      he: 'מה זה Shareitt?',
      br: 'Quem somos',
      de: 'Über uns',
    },
    description: {
      en:
        'Shareitt is a unique social e-commerce platform that lets you exchange second hand used or new items easily and have fun at the same time. At Shareitt we want you to be able to reuse, exchange and benefit from those items that you\u0027re no longer using, earn Shareitt points and receive the items you want from other users.',
      he:
        'אפליקציית Shareitt היא פלטפורמה ייחודית למסחר חברתי שמאפשרת קניה ומכירה של פריטי יד שניה משומשים או חדשים בין אנשים, ללא צורך בכסף. Shareitt מאפשרת לכם לנצל בחוכמה את עודף הפריטים העצום שאינם בשימוש ולייצר מהם רווח בדמות נקודות המאפשרות לקבל פריטים רלוונטיים אחרים ממשתמשים אחרים.',
      br:
        'Nós somos a única plataforma de e-commerce social que permite você trocar itens de segunda mão, usados ou novos, de um jeito fácil e divertido. Queremos que você reutilize e troque itens que não usa mais, ganhe pontos Shareitt e receba os itens que quiser de outros usuários. Assim todos ganham.',
      de:
        'Shareitt ist ein einzigartiger sozialer Marktplatz, der es erleichtert, gebrauchte oder neue Gegenstände weiter zu geben. Das Teilen und Tauschen soll Freude machen. Shareitt bietet eine einfache Möglichkeit, Artikel, die du nicht mehr brauchst, weiter zu geben und dabei Shareitt-Punkte zu sammeln, mit denen du gewünschte Artikel von anderen erwerben kannst.',
    },
  },
  {
    id: '2',
    topicId: 1,
    topicTitle: {
      en: 'About Shareitt',
      he: 'על Shareitt',
      br: 'Quem somos',
      de: 'Über Shareitt',
    },
    title: {
      en: 'How do I start using Shareitt?',
      he: 'איך מתחילים להשתמש ב-Shareitt?',
      br: 'Como usar o Shareitt?',
      de: 'Wie beginne ich mit der Nutzung von Shareitt?',
    },
    description: {
      en:
        'If you\u0027re here, you\u0027ve already succeeded in overcoming the first obstacle – downloading the app. After a short (optional) registration process, you may begin looking for items that interest you in the different categories. At the same time, you may upload items for exchange, receive items from other users (if you\u0027ve registered, we\u0027ve already awarded you with a welcome gift of Shareitt points), make wishes come true and more. Once you start, it\u0027s easy to use.',
      he:
        'אם אתם כבר כאן סימן שעברתם את המשוכה הראשונה והיא הורדת האפליקציה. אחרי תהליך רישום קצר ובסיסי (לא חובה), אתם יכולים להתחיל ולחפש פריטים שמעניינים אתכם בקטגוריות השונות. במקביל אתם יכולים כבר להעלות פריטים למסירה, לקבל פריטים ממשתמשים אחרים (אם נרשמתם, קיבלתם מאיתנו נקודות כמתנת הצטרפות), להגשים משאלות ועוד. תתחילו, זה דיי פשוט.',
      br:
        'Se você está aqui então já passou pelo primeiro obstáculo: 	baixar o app. Após um curto processo de cadastro (opcional), você pode começar a procurar itens do seu interesse nas diferentes categorias. Ao mesmo tempo você já pode fazer o upload de itens para trocar, receber itens de outros usuários (se você já se cadastrou já te demos pontos Shareitt como presente de boas-vindas), realizar pedidos e mais. Assim que você começar vai ver que é fácil de usar.',
      de:
        'Wenn Du hier bist, hast Du die erste Hürde bereits überwunden - das Herunterladen der App. Nach einem kurzen (optionalen) Registrierungsprozess kannst Du in den verschiedenen Kategorien nach Artikeln suchen, die dich interessieren. Gleichzeitig kannst du Gegenstände zum Tausch hochladen oder von anderen erwerben (z.b. mit den Shareitt-Punkten, die wir dir, wenn du dich registrierst als Willkommensgeschenk gutschreiben), Wünsche erfüllen und vieles mehr. Einmal angefangen, ist es einfach zu bedienen.',
    },
  },
  {
    id: '3',
    topicId: 1,
    topicTitle: {
      en: 'About Shareitt',
      he: 'על Shareitt',
      br: 'Quem somos',
      de: 'Über Shareitt',
    },
    title: {
      en: 'Why should I use Shareitt?',
      he: 'למה לי בעצם להשתמש ב-Shareitt?',
      br: 'Por que devo usar o Shareitt?',
      de: 'Warum sollte ich Shareitt verwenden?',
    },
    description: {
      en:
        'By clicking + on the bottom toolbar, you\u0027ll reach the Upload Items Page where you can find instructions for uploading an item as well as all the information you should provide about the item. It\u0027s important to describe your item in as much detail as possible, put it in the right category, note its real world monetary value and tell users how they can collect the item. Don\u0027t forget to click the Upload Item button when you\u0027re done.',
      he:
        'שאלה מצוינת. Shareitt מאפשרת לכם מצד אחד לקבל פריטים בלי לבזבז כסף. מצד שני, היא מאפשרת לכם להפוך את הפריטים שאתם לא משתמשים בהם לערך אמיתי. אם אתם ממש רוצים לדעת, אז כשאתם משתמשים ב-Shareitt אתם גם תומכים ברווחה הכלכלית שלכם ושל המשתמשים האחרים, הופכים את החברה שלנו לטובה יותר ובו זמנית שומרים על כדור הארץ בניצול עודף קיים למען סביבה בריאה יותר.',
      br:
        'Ótima pergunta. O Shareitt permite que você, por um lado, obtenha itens sem gastar dinheiro. Por outro lado, permite que você transforme os itens que não está usando em valor real. Se você realmente quer saber, ao usar o Shareitt, você também apoia o seu bem-estar econômico e o de outros usuários, torna nossa sociedade melhor e, ao mesmo tempo, preserva o planeta, utilizando o excedente existente para um ambiente mais saudável.',
      de:
        'Wenn du in der unteren Symbolleiste auf "+" klickst, gelangst du auf die Seite "Artikel hochladen", auf der du Anweisungen zum Hochladen und Beschreiben deiner Artikel findest. Es ist wichtig, dass du deine Artikel so detailliert wie möglich beschreibst, sie in die richtige Kategorie einordnest, realistische Geldwerte angibst und der Shareitt community mitteilst, wie sie die Artikel erhalten können. Vergiss nicht, auf die Schaltfläche "Gegenstand hochladen" zu klicken, wenn du fertig bist',
    },
  },
  {
    id: '4',
    topicId: 3,
    topicTitle: {
      en: 'Points',
      he: 'הנקודות שלנו',
      br: 'Pontos',
      de: 'Punkte',
    },
    title: {
      en: 'What are Shareitt Points?',
      he: 'מהם נקודות Shareitt?',
      br: 'O que são pontos Shareitt?',
      de: 'Was sind Shareitt-Punkte?',
    },
    description: {
      en:
        'Shareitt points are the engine that drives our platform. Like an actual currency, they are used to price the value of the items you upload for exchange or receive from other users. It\u0027s pretty simple, use the points as you would use shekels. Just remember that Shareitt points aren\u0027t real money.',
      he:
        'נקודות Shareitt הם למעשה המנוע מאחורי האפליקציה. הם משמשים, בדומה לכסף אמיתי, לתמחור הפריטים שאתם מעלים למסירה או מקבלים ממשתמשים אחרים. העיקרון פשוט, תחשבו שהנקודות הללו מייצגים שקלים. רק תזכרו שלא מדובר בכסף אמיתי.',
      br:
        'Os pontos Shareitt são a parte chave da nossa plataforma. Como se fosse dinheiro, os pontos são usados para dar um preço aos itens que você colocar para troca ou aos itens que vai receber de outros usuários. É muito simples, use os pontos como se fosse usar dinheiro. Lembre-se só que os nossos pontos não são dinheiro de verdade.',
      de:
        'Shareitt-Punkte sind der Motor, der unsere Plattform antreibt. Sie werden wie eine echte Währung verwendet und stellen den Wert der Artikel dar, die du anbietest oder von anderen beziehst. Es ist ganz einfach: Verwende die Punkte so, wie du Franken oder Euro verwenden würdest. Bedenke aber, dass Shareitt-Punkte eine Tauschwährung und kein echtes Geld darstellen.',
    },
  },
  {
    id: '5',
    topicId: 3,
    topicTitle: {
      en: 'Points',
      he: 'הנקודות שלנו',
      br: 'Pontos',
      de: 'Punkte',
    },
    title: {
      en: 'How can I earn Shareitt Points?',
      he: 'איך מקבלים נקודות Shareitt?',
      br: 'Como ganho pontos Shareitt?',
      de: 'Wie kann ich Shareitt-Punkte verdienen?',
    },
    description: {
      en:
        'In order to get Shareitt points, you just need to use the app – that is, exchange and receive items. There are two main ways to earn points: 	First, from other users – the more good quality items you upload and exchange, the more points you can accumulate. And second, from Shareitt – look for the Gift icon at the top of the screen and check out how many gifts you\u0027ll receive.',
      he:
        'כדי לקבל נקודות Shareitt צריך בעיקר להשתמש באפליקציה, כלומר למסור ולקבל פריטים. את הנקודות אפשר לקבל בשתי דרכים עיקריות: 	הראשונה, ממשתמשים אחרים. ככל שתעלו ותמסרו יותר פריטים איכותיים, ככה תוכלו לצבור עוד ועוד נקודות. השניה, מאיתנו. חפשו את אייקון המתנה בחלק העליון של המסך, ותראו כמה מתנות אנחנו הולכים לחלק לכם לאורך הדרך.',
      br:
        'Para ganhar pontos Shareitt você só precisa usar o app, ou seja, trocar e receber itens. Existem duas formas de ganhar pontos: 	de outros usuários - quanto mais itens de boa qualidade você colocar e trocar, mais pontos você vai acumular. Ou diretamente do Shareitt - procure o ícone "Presentes" no topo da tela e veja quantos presentes você vai receber.',
      de:
        'Um Shareitt-Punkte zu erhalten, musst du nur die App nutzen, d. h. Gegenstände tauschen und empfangen. Es gibt zwei Hauptarten Punkte zu verdienen: Einerseits kannst du Punkte von anderen Nutzern erhalten: Je mehr qualitativ hochwertige Artikel du anbietest und weitergibst, desto mehr Punkte erhältst du. Andererseits belohnt dich Shareitt mit Punkten fürs Mitmachen: Achte auf das Geschenksymbol oben auf dem Bildschirm; dort kannst du jederzeit nachschauen, wie viele Punkte du als Geschenk von der Plattform zugut hast.',
    },
  },
  {
    id: '1',
    topicId: 3,
    topicTitle: {
      en: 'Points',
      he: 'הנקודות שלנו',
      br: 'Pontos',
      de: 'Punkte',
    },
    title: {
      en: 'Can you tell me more about Shareitt Gifts?',
      he: 'ספרו עוד קצת על המתנות',
      br: 'Você pode me contar mais sobre os prêmios Shareitt?',
      de: 'Kannst du mir mehr über Shareitt-Geschenke erzählen?',
    },
    description: {
      en:
        'The gifts we give you are based on your platform usage. We encourage you to upload as many quality items as you can, exchange and receive items, share the app with friends and grant wishes. We\u0027re sure you\u0027ll be happy with each gift you get!',
      he:
        'המתנות שאנחנו נותנים מבוססים על השימושיות שלכם באפליקציה. אנחנו רוצים לעודד אתכם להעלות כמה שיותר פריטים איכותיים, למסור ולקבל פריטים, לשתף את האפליקציה בין החברים שלכם ולגרום לכם להגשים משאלות למשתמשים אחרים. אנחנו מבטיחים שאתם תהיו מרוצים מכל מתנה שתצליחו לפתוח.',
      br:
        'Quanto mais você usar a nossa plataforma, mais presentes você vai receber. Nós queremos que você coloque o maior número possível de itens de qualidade, troque e receba itens, convide amigos para o app e realize desejos. Temos certeza que você vai gostar de todos os presentes que ganhar!',
      de:
        'Die Punkte, die du als Geschenk von uns erhältst, basieren auf deiner Aktivität auf der Plattform. Wir ermutigen dich, so viele hochwertige Artikel wie möglich hochzuladen, Artikel anzubieten und zu erhalten, die App mit anderen zu teilen und gegenseitig Wünsche zu erfüllen. Wir sind sicher, dass jedes Geschenk, das du von uns bekommst, Freude macht.',
    },
  },
  {
    id: '7',
    topicId: 4,
    topicTitle: {
      en: 'Give an item',
      he: 'מסירת פריט',
      br: 'Dê um item',
      de: 'Einen Artikel verschenken',
    },
    title: {
      en: 'How do I upload an item?',
      he: 'איך מעלים פריט?',
      br: 'Como eu incluo um item?',
      de: 'Wie lade ich einen Artikel hoch?',
    },
    description: {
      en:
        'By clicking + on the bottom toolbar, you\u0027ll reach the Upload Items Page where you can find instructions for uploading an item as well as all the information you should provide about the item. It\u0027s important to describe your item in as much detail as possible, put it in the right category, note its real world monetary value and tell users how they can collect the item. Don\u0027t forget to click the Upload Item button when you\u0027re done.',
      he:
        'בלחיצה על + הנמצא בסרגל התחתון תגיעו לעמוד העלאת פריט שם תוכלו למצוא גם את כללי העלאת הפריט וגם את כל המידע שאתם צריכים לשתף בנוגע לפריט שתעלו. חשוב שתתארו את הפריט בצורה הטובה ביותר, תתאימו אותו לקטגוריות הרלוונטיות, תציינו את הערך הכספי שלו בעולם האמיתי ותספרו לשאר המשתמשים איך הם יכולים לאסוף את הפריט. אל תשכחו ללחוץ על כפתור "העלה את הפריט".',
      br:
        'Clique + na barra de ferramentas abaixo para ser direcionado para a página "Colocar Itens". Lá, você vai ver todas as instruções de como fazer o upload de um item e as informações que deverá colocar sobre cada um deles. É importante descrever seus itens detalhadamente, colocá-los na categoria correta, pôr o seu valor monetário real e informar aos usuários como eles podem retirá-los. Não esqueça de clicar no botão "Faça o Upload do Item" quando finalizar.',
      de:
        'Wenn du in der unteren Symbolleiste auf das "+" klickst, gelangst du zur Seite "Artikel hochladen", wo du Hinweise zum Hochladen und zur Beschreibung von Artikeln findest. Es ist wichtig, dass du deinen Artikel so detailliert wie möglich beschreibst, ihn in die richtige Kategorien einordnest, einen realistischen Wert (in Punkten) angibst und den Benutzern mitteilst, wie sie den Artikel erhalten können. Vergiss nicht, auf die Schaltfläche "Artikel hochladen" zu klicken, wenn du fertig bist',
    },
  },
  {
    id: '8',
    topicId: 4,
    topicTitle: {
      en: 'Give an item',
      he: 'מסירת פריט',
      br: 'Dê um item',
      de: 'Gib einen Gegenstand ab',
    },
    title: {
      en: 'Do I have to upload a picture of my item?',
      he: 'האם חובה להעלות תמונות של הפריט?',
      br: 'Preciso colocar uma foto do meu item?',
      de: 'Muss ich ein Bild von meinem Gegenstand hochladen?',
    },
    description: {
      en:
        'Yes. We recommend taking the best picture or pictures you can. The better and more authentic the picture, the better your chance of selling the item. Take the picture yourself rather than using one from the internet. Make sure the lighting is good and the background is plain – a white background is best – and take pictures from different angles. For more picture taking recommendations, please see Home Page.',
      he:
        'כן. אנחנו ממליצים לכם להשקיע בתמונה. ככל שהיא תהיה טובה יותר מצד אחד ואותנטית מצד שני, ככה תגדילו את הסיכויים שלכם למכור את הפריט. השתדלו לצלם בעצמכם ולא להשתמש בתמונה מהאינטרנט. עשו זאת במקום עם תאורה טובה, על רקע אחיד עם עדיפות לרקע לבן וצלמו מספר תמונות מזוויות שונות. עוד המלצות על צילום נכון תוכלו למצוא בעמוד הבית.',
      br:
        'Sim. Recomendamos que você tire as melhores fotos possíveis. Quanto melhor e mais autêntica a foto, maior é a chance de passar o seu item adiante. Tire a foto você mesmo em vez de pegar da internet. Certifique-se que a luz esteja boa e que o fundo seja uma superfície lisa - de preferência branca - e tire a foto de vários ângulos. Para mais recomendações em como tirar as fotos vá até a página "Ínicio".',
      de:
        'Ja. Wir empfehlen dir, möglichst gute Bilder zu machen. Je besser und authentischer die Bilder, umso grösser die Chance, dass der Artikel bestellt wird. Mache die Bilder selber, anstatt eines aus dem Internet zu verwenden. Achte auf eine gute Beleuchtung und einen schlichten, hellen Hintergrund und nimm nach Möglichkeit Fotos aus verschiedenen Winkeln auf. Weitere Tipps zum Fotografieren findest du auf der Startseite',
    },
  },
  {
    id: '9',
    topicId: 4,
    topicTitle: {
      en: 'Give an item',
      he: 'מסירת פריט',
      br: 'Dê um item',
      de: 'Einen Artikel abgeben',
    },
    title: {
      en: 'How can I exchange an item?',
      he: 'איך מוסרים פריט?',
      br: 'Como posso trocar um item?',
      de: 'Wie kann ich einen Artikel umtauschen?',
    },
    description: {
      en:
        'After you\u0027ve successfully uploaded an item, other users can ask to buy it. You\u0027ll get a message with a request to receive the item which you can approve by clicking on the Approve button in the chat with the buyer.',
      he:
        'אחרי שהעלתם את הפריט בהצלחה, יוכלו המשתמשים האחרים לבקש מכם לקבל את הפריט. אתם תקבלו הודעה עם בקשה לקבל את הפריט ותוכלו לאשר אותה על ידי לחיצה על כפתור ׳אישור׳ בצ׳אט עם הקונה.',
      br:
        'Depois de fazer o upload do item, outros usuários podem pedir para obtê-lo. Você vai receber um pedido. Para aprová-lo clique no botão "Aprovar" no chat com o a pessoa interessada.',
      de:
        'Nachdem du einen Artikel erfolgreich hochgeladen hast, können andere darum bitten, ihn zu erwerben. Du erhältst dann eine Anfrage im Chat, die du annehmen oder ablehnen kannst.',
    },
  },
  {
    id: '10',
    topicId: 5,
    topicTitle: {
      en: 'Receive an item',
      he: 'קבלת פריט',
      br: 'Receber um item',
      de: 'Einen Artikel erhalten',
    },
    title: {
      en: 'How can I receive an item?',
      he: 'איך מקבלים פריט?',
      br: 'Como posso receber um item?',
      de: 'Wie kann ich einen Artikel erhalten?',
    },
    description: {
      en:
        'Enter our Marketplace from the bottom toolbar. Once you\u0027ve chosen an item you\u0027d like, click on it in order to reach the Item Page. On the item page, click Request Item. Have a question for the user offering the item? Next to the Request Item button, you can enter into a chat with the user and ask specific questions about the item. Once the user has approved your request, Shareitt points are automatically transferred from your wallet to their wallet. Once you\u0027ve received the item, click the Confirm Item Receipt button in the chat or under Receiving Items on your User Page.',
      he:
        'ניתן להכנס למרקטפלייס שלנו דרך הסרגל כלים בתחתית העמוד.  לאחר שבחרת פריט לטעמך, לחץ עליו בכדי להכנס לעמוד הפריט. בעמוד הפריט, לחץ ״אני רוצה. יש לך שאלה למוסר? ליד כפתור ״אני רוצה״ ישנה אפשרות להיכנס לצ׳אט מול המוסר ולשאול שאלות ספציפיות לגבי אותו פריט. כעת יש להמתין שהמוסר יאשר את בקשתך. ברגע שהמוסר מאשר, הנקודות עבור הפריט יועברו אוטומטית מהארנק שלך למוסר. ברגע שהפריט בידך יש ללחוץ על כפתור ״אישור הגעה״ בצ׳אט או תחת קבלת פריטים בעמוד האישי.',
      br:
        'Entre no nosso marketplace pela barra de ferramentas abaixo. Assim que escolher um item clique nele para ser direcionado para a página "Item". Na página Item clique em "Pedir item". Tem alguma pergunta para o usuário que está oferecendo o item? Ao lado de Pedir um item você pode entrar num chat com o usuário e fazer perguntas específicas sobre o item. Assim que o usuário aprovar o seu pedido, os pontos Shareitt são automaticamente transferidos da sua carteira para a carteira dele. Assim que você receber o item clique em "Confirmar Recebimento" no chat ou em "Itens Recebidos" na sua página de usuário.',
      de:
        'Besuche unseren Marktplatz indem du auf die unteren Symbolleiste klickst. Wenn du einen Artikel ausgewählt hast, der dir gefällt, klicke ihn an. So gelangst du zur Artikelseite auf der du mit  "Artikel anfordern" bestellen kannst. Hast du eine Frage an die Person, die den Artikel anbietet? Neben der Schaltfläche  "Artikel anfordern" kannst du einen Chat mit ihr beginnen und spezifische Fragen zum Artikel stellen. Sobald sie die Anfrage genehmigt hat, werden die Shareitt-Punkte automatisch von deinem Konto auf das Konto des/                                       r Anbieter:in übertragen. Wenn du den Artikel erhalten hast, bestätige dies bitte indem du auf  "Artikel erhalten" klickst, sei es im Chat oder auf der Benutzerseite der Person, die den Artikel anbietet.',
    },
  },
  {
    id: '11',
    topicId: 5,
    topicTitle: {
      en: 'Receive an item',
      he: 'קבלת פריט',
      br: 'Receber um item',
      de: 'Einen Gegenstand erhalten',
    },
    title: {
      en: 'I like a certain item, so I clicked the Heart icon. What does this do?',
      he: 'אהבתי פריט מסויים, אז לחצתי על אייקון הלב. מזה אומר?',
      br: 'Curti um item e cliquei no coração. O que isso significa?',
      de:
        'Ich mag einen bestimmten Gegenstand, also habe ich auf das Herz-Symbol geklickt. Was bewirkt das?',
    },
    description: {
      en:
        'Clicking the Heart icon marks an item as liked. You can filter and see any items you like under My Preferences and save them for later.',
      he:
        'לחיצה על אייקון הלב - לייק, משמש כסימון פריטים שאהבת כדי שתוכל לסנן ולראות אותם ב- ׳המועדפים שלי׳ ולשמור אותם להמשך.',
      br:
        'Clicar no coração é marcar um item como curtido. Você pode filtrar e ver qualquer item em "Meus Favoritos" e salvá-los para depois.',
      de:
        'Wenn du auf das Herz-Symbol klickst, wird ein Artikel als beliebt markiert. Du kannst alle Artikel, die dir gefallen, unter "Meine Präferenzen" anzeigen und für später speichern.',
    },
  },
  {
    id: '12',
    topicId: 5,
    topicTitle: {
      en: 'Receive an item',
      he: 'קבלת פריט',
      br: 'Receber um item',
      de: 'Einen Artikel erhalten',
    },
    title: {
      en: 'How do I search for a specific item or filter items?',
      he: 'איך אני מחפש פריט ספציפי או מסנן פריטים?',
      br: 'Como busco um item específico?',
      de: 'Wie kann ich nach einem bestimmten Artikel suchen oder Artikel filtern?',
    },
    description: {
      en:
        'On the top left side of the Marketplace Page, there\u0027s a magnifying glass you can use to search for an item by keyword. Just under the magnifying glass, you can filter items by price range, distance from you, discount items, friends you follow and more categories.',
      he:
        'בעמוד המרקט, מצד שמאל למעלה היכן שמופיע זכוכית מגדלת, ניתן לחפש פריט לפי מילה. בנוסף, בשורה מתחת לזכוכית מגדלת ניתן לעשות סינון פריטים לפי טווח מחירים, מרחק, פריטים בהנחה, רק מחברים שאני עוקב, קטגוריות ומיון לפי קריטריונים נוספים.',
      br:
        'No topo esquerdo da página "Marketplace" você vai ver um lupa que poderá ser usada para buscar um item através de uma palavra-chave. Embaixo da lupa você pode filtrar itens por preço, distância do item até você, itens com desconto, amigos que você segue e mais categorias.',
      de:
        'Oben links auf der Seite des Marktplatzes befindet sich eine Lupe, mit der du nach Stichworten suchen kannst. Direkt unter der Lupe kannst du die Artikel nach Kriterien filtern.',
    },
  },
  {
    id: '13',
    topicId: 6,
    topicTitle: {
      en: 'Wishes',
      he: 'משאלות',
      br: 'Desejos',
      de: 'Wünsche',
    },
    title: {
      en: 'What\u0027s a wish?',
      he: 'מה זה משאלה?',
      br: 'O que são os desejos?',
      de: 'Was ist ein Wunsch?',
    },
    description: {
      en:
        'In the same way you upload an item to offer for exchange, you can also upload a wish for an item you\u0027re interested in receiving. Someone may see your wish on the Wish Page and try to grant it. You can reach the Wishes Page from Home Page.',
      he:
        'בדומה להעלאת פריט למסירה, ניתן להעלות משאלה לפריט שאתה מעוניין לקבל. מישהו אחר יכול להיתקל במשאלה בעמוד המשאלות, ולנסות להגשים לך אותה. ניתן להגיע לעמוד המשאלות דרך עמוד הבית.',
      br:
        'Da mesma forma que você faz o upload de itens, você pode fazer o mesmo com um item que você deseja. Alguém pode ver o seu desejo na página "Desejos" e tentar realizá-lo. Vá até a página "Desejos" através da página "Início".',
      de:
        'Genauso wie du Gegenstände zum Weitergeben hochlädst, kannst du auch Wünsche für Gegenstände hochladen, die du gerne erhalten würdest. So kann jemand deinen Wunsch sehen und versuchen, ihn zu erfüllen. Du kannst die Seite mit Wünschen direkt von der Startseite aus erreichen.',
    },
  },
  {
    id: '14',
    topicId: 6,
    topicTitle: {
      en: 'Wishes',
      he: 'משאלות',
      br: 'Desejos',
      de: 'Wünsche',
    },
    title: {
      en: 'How do I upload a wish?',
      he: 'איך אני מעלה משאלה?',
      br: 'Como faço para incluir um desejo?',
      de: 'Wie lade ich einen Wunsch hoch?',
    },
    description: {
      en:
        'After clicking the + button, pick Wish and add a description of the item you\u0027re interested in receiving (see the rules for uploading a wish on this page). Don\u0027t forget to click Confirm when you\u0027re done.',
      he:
        'לאחר לחיצה על כפתור ה ""+"", בחר ׳משאלה׳ והוסף את כל הפרטים על הפריט אותו אתה מעוניין לקבל (אפשר לראות את כללי העלאת משאלה הנמצאים בדף זה). לא לשכוח ללחוץ בסיום אישור.',
      br:
        'Depois de clicar o + escolha um desejo e adicione uma descrição do item que você está interessado (ver regras para colocar um desejo nesta página). Não esqueça de clicar em "Confirmar" quando acabar.',
      de:
        'Nachdem du auf die Schaltfläche "+" geklickt hast, wähle "einen Wunsch" aus und füge eine Beschreibung des Gegenstands hinzu, den du erhalten möchtest (siehe die Regeln für das Hochladen eines Wunsches auf dieser Seite). Vergiss nicht, auf Bestätigen zu klicken, wenn du fertig bist.',
    },
  },
  {
    id: '15',
    topicId: 6,
    topicTitle: {
      en: 'Wishes',
      he: 'משאלות',
      br: 'Desejos',
      de: 'Wünsche',
    },
    title: {
      en: 'How can I grant someone\u0027s wish?',
      he: 'אני רוצה להגשים למישהו משאלה, איך אני עושה את זה?',
      br: 'Como realizo um desejo de alguém?',
      de: 'Wie kann ich jemandem einen Wunsch erfüllen?',
    },
    description: {
      en:
        'Choose a wish from the Wishes Page (which you can reach though Home Page) and click the blue Granting a Wish button. Now fill in the details on the item you\u0027d like to offer in return for points and click the Confirm button.',
      he:
        'בחר משאלה להגשמת בעמוד המשאלות (אליו ניתן להגיע דרך עמוד הבית) ולחץ על הכפתור הכחול ״הגשמת משאלה״. כעת מלא פרטים על הפריט אותו אתה מעוניין להגשים עבור נקודות ולחץ על כפתור ׳אישור׳.',
      br:
        'Escolha um desejo da página "Desejos" (através da página "Início") e clique o botão azul "Realizar um Desejo". Agora é só colocar os detalhes do item que você quer oferecer em troca de pontos e clicar em "Confirmar".',
      de:
        'Wähle einen Wunsch auf der Wunschseite aus (die du über die Startseite erreichst) und klicke auf die blaue Schaltfläche "Wunsch gewähren". Gib nun die Details zum Gegenstand ein, den du  für Punkte auf Shareitt anbieten möchtest, und klicke auf die Schaltfläche "Bestätigen".',
    },
  },
  {
    id: '16',
    topicId: 6,
    topicTitle: {
      en: 'Wishes',
      he: 'משאלות',
      br: 'Desejos',
      de: 'Wünsche',
    },
    title: {
      en: 'Who can see the wish item I\u0027m offering?',
      he: 'מי רואה את פריט ההגשמה שלי?',
      br: 'Quem pode ver o desejo que eu estou oferecendo?',
      de: 'Wer kann den Wunschgegenstand sehen, den ich anbiete?',
    },
    description: {
      en: 'Only the person who made the wish can see the item you uploaded.',
      he: 'רק מבקש המשאלה יוכל לראות את פריט ההגשמה שהעלת.',
      br: 'Somente a pessoa que fez o desejo pode ver o item que você colocou.',
      de:
        'Nur die Person, die den Wunsch geäussert hat, kann den Gegenstand sehen, den du hochgeladen hast.',
    },
  },
  {
    id: '17',
    topicId: 7,
    topicTitle: {
      en: 'Invite Friends',
      he: 'הזמנת חברים',
      br: 'Convide amigos',
      de: 'Freunde einladen',
    },
    title: {
      en: 'How can I share Shareitt with my friends?',
      he: 'איך ניתן להזמין חברות או חברים לאפליקציה?',
      br: 'Como posso convidar meus amigos?',
      de: 'Wie kann ich Shareitt mit meinen Freunden teilen?',
    },
    description: {
      en:
        'Go to Home Page, where you\u0027ll find a link you can use to invite friends. You earn Shareitt points for each new member you bring in! For each new member who exchanges an item, you\u0027ll earn more point.',
      he:
        'בעמוד הבית, יש לינק לעמוד הפניית חברים וחברות. על כל חבר שהצטרף תוכל להרוויח נקודות נוספות! על כל חבר שמסר פריט, תרוויח נקודות נוספות :)',
      br:
        'Na página "Início" você vai encontrar um link para convidar amigos. Para cada amigo que se cadastrar você ganha pontos Shareitt e para cada novo membro que trocar um item você ganha ainda mais.',
      de:
        'Gehe zur Startseite, wo du einen Link findest, mit dem du Freund:innen einladen kannst. Für jedes neue Mitglied, das du vermittelst, verdienst du Shareitt-Punkte! Für jedes neue Mitglied, das einen Artikel eintauscht, erhältst du weitere Punkte.',
    },
  },
  {
    id: '6',
    topicId: 8,
    topicTitle: {
      en: 'Delivery',
      he: 'משלוחים',
      br: 'Envio',
      de: 'Lieferung',
    },
    title: {
      en: 'How does delivery or collection of items work?',
      he: 'מהן האופציות לאיסוף/משלוח פריט?',
      br: 'Como funciona a entrega ou a retirada de cada item?',
      de: 'Wie funktioniert die Lieferung oder Abholung von Artikeln?',
    },
    description: {
      en:
        'Shareitt users transfer items to one another in a number of ways:\nThe first is pick-up - simple and convenient, coordinated between the two users.\nAnother option is conventional delivery services such as post and parcel lockers (like Boxit) - at an actual shekel cost, also coordinated between the two users.\nA third option is through other Shareitt users! These users act as person to person couriers and help deliver items in return for Shareitt points. For more information on this option, please see FAQs on Shareitt Person to Person Couriers.',
      he:
        'המשתמשים שלנו מעבירים פריטים במספר דרכים שונות:\nדרך ראשונה היא איסוף עצמי - התיאום יתבצע בין המוסר למקבל. הכי פשוט וקל.\nדרך שניה היא שירותים מחוץ לאפליקציה כמו דואר/בוקסיט... \nכל אלו בעלות שיקלית וגם כאן התיאום מתבצע בין המוסר והמקבל.  \nדרך שלישית היא בין משתמשים באפליקציה! ישנם משתמשים שנקראים ״משנעים״ המסייעים להעביר פריטים מהמוסר לנקודות איסוף נוספות תמורת נקודות. לפרטים נוספים על פיילוט השינועים, ניתן לראות שאלות ותשובות בנושא שינועים\n',
      br:
        'Os usuários do Shareitt tranferem itens uns para os outros de várias formas:\nA primeira é a retirada - simples e fácil, feita pelos dois usuários.\nOutra opção é o serviço de entrega convencional como os correios ou Boxit. Essa opção será paga em real e também será feita pelos dois usuários.\nE a terceira opção é através dos usuários do Shareitt. Esses usuários atuam como couriers personalizados e entregam itens em troca de pontos. Para mais informações sobre esta opção, veja nas Perguntas frequentes sobre couriers personalizados.',
      de:
        'Shareitt-Mitglieder können sich gegenseitig Gegenstände auf verschiedene Weise zukommen lassen: Die erste Möglichkeit ist die persönliche Abholung - einfach und bequem, koordiniert zwischen den beiden Parteien.\nEine weitere Möglichkeit sind herkömmliche Zustelldienste, wie die Post, zu den üblichen Tarifen (in CHF, Euro, etc.), ebenfalls von den beiden Beteiligten koordiniert.\nEine dritte Möglichkeit ist die Lieferung durch Shareitt-Botinnen und -Boten. Sie fungieren als persönliche Kuriere von Mensch zu Mensch und nehmen deine Artikel auf Routen mit, auf denen sie ohnehin im Alltag unterwegs sind. Sie erhalten für diesen Service Shareitt-Punkte. Auch Du kannst Shareitt-Bot:in werden. So können wir gemeinsam weiter zur Entlastung des Verkehrs und der Umwelt beitragen. Weitere Informationen zu dieser Option findest du in den FAQs zu Shareitt.',
    },
  },
  {
    id: '18',
    topicId: 8,
    topicTitle: {
      en: 'Delivery',
      he: 'משלוחים',
      br: 'Envio',
      de: 'Lieferung',
    },
    title: {
      en: 'I bought an item, how do I know if delivery will cost me extra points?',
      he: 'קניתי פריט, איך אני יודע אם נקודת האיסוף שלו היא בתוספת תשלום נקודות שינועים?',
      br: 'Comprei um item, como saber se a entrega vai me custar mais pontos?',
      de:
        'Ich habe einen Artikel gekauft, woher weiss ich, ob die Lieferung zusätzliche Punkte kostet?',
    },
    description: {
      en:
        'Delivery options for each item are listed on the Item Page – either using Shareitt points or by pick-up, at no extra cost.',
      he:
        'במפת עמוד הפריט, ניתן לראות אם אופציית איסוף הפריט היא באמצעות נקודות או לחילופין באיסוף עצמי, ללא נקודות.',
      br:
        'As opções de entrega para cada item está na página "Item" - ou usando pontos Shareitt ou retirando sem custo extra.',
      de:
        'Die Lieferoptionen für jeden Artikel sind auf der Artikelseite aufgeführt - entweder mit Shareitt-Punkten oder durch Abholung, ohne zusätzliche Kosten.',
    },
  },
  {
    id: '19',
    topicId: 8,
    topicTitle: {
      en: 'Delivery',
      he: 'משלוחים',
      br: 'Envio',
      de: 'Lieferung',
    },
    title: {
      en: 'How many points does a Shareitt person to person courier cost?',
      he: 'כמה נקודות שינוע הפריט עולה לי?',
      br: 'O courier personalizado do Shareitt custa quantos pontos?',
      de:
        'Wie viele Punkte kostet der Shareitt-Kurierdienst, die Auslieferung durch Shareitt-Bot:innen?',
    },
    description: {
      en:
        'There are two types of person to person couriers:\nShareitt storage couriers store items for you until you pick them up for 5 Shareitt points;\nShareitt transport couriers transport items at a cost of 10 Shareitt points per \u0027delivery stage\u0027.\n\nThe total delivery cost for an item to a certain destination will appear on the Item Page map. For example, if the delivery of an item to an address close to you involves one storage courier and 2 transport couriers, the total cost will be 25 Shareitt points.',
      he:
        'יש שני סוגים של משנעים:\nמשנעים אשר רק מאחסנים את הפריט עבורך בעלות של 5 נקודות Shareitt\nמשנעים אשר מעבירים את הפריט הלאה בעלות של 10 נקודות Shareitt\nעלות סך כל השנועים עבור היעד, יופיע על המיקום במפה של דף הפריט. \n\tלדוגמא, במידה והעברת הפריט לכתובת לידך הייתה כרוכה באחסון ושני משנעים, העלות תהיה 25 נקודות.',
      br:
        'Existem dois tipos de courier personalizado:\nO Courier de armazenamento do Shareitt guarda os itens até você pegá-lo pelo custo de até 5 pontos Shareitt.rtl4krkeO courier de transporte do Shareitt leva os itens por um custo de 10 pontos Shareitt para cada "estágio de entrega".\nO custo total de entrega de um item para um determinado destino aparecerá no mapa da página do item. Por exemplo, se a entrega de um item para um endereço próximo a você envolver um estafeta de armazenamento e 2 estafetas de transporte, o custo total será de 25 pontos Shareitt.',
      de:
        'Es gibt zwei Elemente des Shareitt-Kuriersystems: Einige Shareitt-Mitglieder sind bereit, Artikel zu lagern, bis sie (für 5 Shareitt-Punkte) abgeholt werden; andere fungieren als Kuriere und sind bereit, Artikel (zum Preis von 10 Shareitt-Punkten) an Orte mitzunehmen, die sie ohne oft frequentieren. Werden beispielsweise für die Lieferung an eine Adresse in deiner Nähe eine Übergabestelle ("Lager") und 2 Bot:innen benötigt, betragen die Gesamtkosten 25 Shareitt-Punkte',
    },
  },
  {
    id: '20',
    topicId: 8,
    topicTitle: {
      en: 'Delivery',
      he: 'משלוחים',
      br: 'Envio',
      de: 'Lieferung',
    },
    title: {
      en: 'How do I transfer Shareitt points for person to person delivery?',
      he: 'איך אני מעביר את הנקודות עבור השינועים?',
      br: 'Como transfiro pontos para entregas personalizadas?',
      de: 'Wie übertrage ich Shareitt-Punkte für die Lieferung von Person zu Person?',
    },
    description: {
      en:
        'When the item is transferred from the user to the courier and each time it is transferred from one courier to another, you\u0027ll receive a link to pay for a \u0027delivery stage\u0027 from the courier. Click each link to pay for delivery costs for the desired destination.',
      he:
        'לאחר העברת הפריט מהמוסר למשנע או ממשנע אחד לאחר, יישלחו אליך קישור/ים לתשלום על ״שינוע״ מהמשנעים עצמם. יש להיכנס ללינקים ולרכוש את השינועים עבור היעד המבוקש.',
      br:
        'Quando o item for transferido de um usuário para um courier e a cada vez que for transferido de um courier para o outro, você vai receber um link para pagar um "estágio de entrega". Clique em cada link para pagar os custos de entrega até o destino desejado.',
      de:
        'Jedes mal, wenn die Sendung an eine Botin oder einen Boten weitergegeben wird, erhältst Du einen Link, mit dem du für die entsprechende Lieferetappe bezahlen kannst. Klicke auf jeden Link, um die entsprechende Lieferung zu entschädigen.',
    },
  },
  {
    id: '21',
    topicId: 8,
    topicTitle: {
      en: 'Delivery',
      he: 'משלוחים',
      br: 'Envio',
      de: 'Lieferung',
    },
    title: {
      en: 'I\u0027d like to be a Shareitt person to person courier too, how can I start?',
      he: 'גם אני רוצה לקחת חלק בשינועים על הדרך, איך מתחילים?',
      br: 'Quero ser um courier personalizado do Shareitt, como faço?',
      de: 'Ich möchte Shareitt-Bot:in werden. Wie kann ich anfangen?',
    },
    description: {
      en: 'Please contact us via chat. We\u0027d be happy to provide further information!',
      he: 'נשמח להעביר פרטים נוספים! בבקשה צור קשר בצ׳אט עם Shareitt.',
      br: 'Entre em contato conosco via chat. Ficaremos felizes em te dar mais informações!',
      de: 'Bitte kontaktiere uns über den Chat. Wir geben dir gerne weitere Informationen!',
    },
  },
  {
    id: '22',
    topicId: 2,
    topicTitle: {
      en: 'Shipping',
      he: 'שינועים',
      br: 'Entrega',
      de: 'Versand',
    },
    title: {
      en: 'What is Shareitt person to person delivery?',
      he: 'מה זה שינועים?',
      br: 'O que é a entrega personalizada Shareitt?',
      de: 'Wie funktioniert die Lieferung mit Shareitt Bot:innen (Mensch zu Mensch Lieferung)?',
    },
    description: {
      en:
        'Shareitt person to person delivery is a community method to move items from one user to another. Certain users act as person to person couriers and move items from the user offering the item to various collection points in exchange for points.',
      he:
        'שינועים זו דרך משלוח חברתית להעברת פריטים שמציעים משתמשים באפליקציה. ישנם משתמשים שנקראים ״משנעים״ המסייעים להעביר פריטים מהמוסרים לנקודות איסוף נוספות תמורת נקודות.',
      br:
        'A nossa entrega personalizada é um método comunitário de levar itens de um usuário para o outro. Alguns usuários atuam como couriers personalizados e entregam itens a vários pontos de coleta em troca de pontos.',
      de:
        'Die Lieferung durch Shareitt-Bot:innen funktioniert dank Shareitt Mitgliedern, die bereit sind, Artikel auf ihren Alltagsrouten mitzunehmen und von einer Person zur anderen zu bringen. Eine solche Lieferung kann mehrere Etappen und Übergabe- oder Abholstellen umfassen. Die beteiligten Bot:innen erhalten für ihren Service Shareitt-Punkte.',
    },
  },
  {
    id: '23',
    topicId: 2,
    topicTitle: {
      en: 'Shipping',
      he: 'שינועים',
      br: 'Entrega',
      de: 'Versand',
    },
    title: {
      en: 'How does it work?',
      he: 'איך זה עובד?',
      br: 'Como funciona?',
      de: 'Wie funktioniert es?',
    },
    description: {
      en:
        'Once the item has been exchanged and the collection point has been selected by the user receiving the item, the user offering the item sends the item and the receiver\u0027s details to the person to person courier. The courier then sends the receiver a link to pay for delivery and coordinates the transfer of the item to them. If more than one courier is required to transfer the item (i.e. more than one delivery stage), the receiver will get a link from each person to person courier involved.',
      he:
        'לאחר מסירת הפריט ובחירת נקודת איסוף על ידי המקבל, המוסר מעביר למשנע את הפריט ופרטי המקבל. המשנע ישלח לינק לתשלום פריט שינוע ויתאם הגעה מול המקבל לצורך איסוף הפריט. במידה ויש יותר ממשנע אחד, ישלחו מספר לינקים לפי מספר המשנעים.',
      br:
        'Assim que o item for trocado e o ponto de coleta for selecionado pelo usuário que irá receber o item, o usuário que está oferecendo o item envia as informações do destinatário para o courier personalizado. O courier, então, envia um link de pagamento pela entrega ao usuário que irá receber o item e coordena a entrega. Se for preciso mais de um courier para fazer a entrega (se houver mais de um estágio de entrega, por exemplo), o destinatário receberá um link de cada courier envolvido no processo.',
      de:
        'Sobald der Artikel bestellt und die Abholstelle ausgewählt wurde, sendet die Person, die den Artikel angeboten hat, die Übergabe- oder Lieferadresse an die Shareitt-Bot:in(en). Die Bot:in, schickt dem Empfänger dann einen Link zur Entschädigung der Lieferung und koordiniert die Übergabe. Wenn es mehrere Bot:innen für die Übergabe des Artikels braucht (d.h. wenn mehrere Etappen nötig sind), erhält der Empfänger von allen beteiligten Shareritt-Bot:innen einen Link.',
    },
  },
  {
    id: '24',
    topicId: 2,
    topicTitle: {
      en: 'Shipping',
      he: 'שינועים',
      br: 'Entrega',
      de: 'Versand',
    },
    title: {
      en: 'Where can I find the collection points?',
      he: 'איפה רואים נקודות איסוף?',
      br: 'Onde posso achar os pontos de coleta?',
      de: 'Wo kann ich die Abholstellen finden?',
    },
    description: {
      en:
        'On the Item Page, you can open the map and see all the collection points - pick up and/or by person to person couriers (at an additional points cost). Once you\u0027ve found a suitable collection point, coordinate delivery with the user offering the item.',
      he:
        'בעמוד הפריט, ניתן לפתוח את המפה ולראות את כל נקודות האיסוף - איסוף עצמי ו/או על ידי משנעים (בעלות נקודות נוספים). לאחר שמצאת מיקום בקרבתך, תאם מול המוסר להיכן להעביר את הפריט.',
      br:
        'Na página "Item" você pode abrir o mapa e ver todos os pontos de coleta - retiradas ou couriers personalizados (com um custo adicional de pontos). Assim que achar o melhor ponto de coleta pra você, combine a entrega com o usuário que está oferecendo o item.',
      de:
        'Auf der Artikelseite kannst Du die Karte mit möglichen Abholstellen und dem Team von (kostenpflichtigen) Shareitt-Bot:innen sehen. Sobald du eine geeignete Abholstelle gefunden hast, könnt ihr die Übergabe arrangieren.',
    },
  },
  {
    id: '25',
    topicId: 2,
    topicTitle: {
      en: 'Shipping',
      he: 'שינועים',
      br: 'Entrega',
      de: 'Versand',
    },
    title: {
      en: 'What\u0027s the cost in Shareitt points?',
      he: 'כמה נקודות זה יעלה לי?',
      br: 'Quanto custa em pontos Shareitt?',
      de: 'Wie hoch sind die Kosten in Shareitt-Punkten?',
    },
    description: {
      en:
        'There are two types of person to person couriers:         Shareitt storage couriers who store items until pick-up at a cost of 5 Shareitt points and Shareitt transport couriers who transport items at a cost of 10 Shareitt points per \u0027delivery stage\u0027.The total delivery cost for an item to a certain destination will appear on the Item Page map.',
      he:
        'יש שני סוגים של משנעים: 	משנעים אשר רק מאחסנים את הפריט עבורך בעלות של 5 נקודות ומשנעים אשר מעבירים את הפריט לנקודות האיסוף בעלות של 10 נקודות. עלות סך כל השינועים עבור היעד, יופיע על המיקום במפה של דף הפריט.',
      br:
        'Existem dois tipos de entrega personalizada: 	Couriers de armazenamento Shareitt que guardam o seu item até a retirada por 5 pontos e os couriers de transporte Shareitt que levam os itens por um custo de 10 pontos por "estágio de entrega". O custo total da entrega de um item vai aparecer no mapa da página "Item".',
      de:
        'Es gibt zwei Arten von Shareitt-Bot:innen in unserem persönlichen Kurierdienst: Shareitt-Lagerkuriere, die Artikel bis zur Abholung aufbewahren und dafür 5 Shareitt-Punkte verlangen, und Shareitt-Transport-Bot:innen, die Artikel zu einem Preis von 10 Shareitt-Punkten für einen vereinbarten Lieferabschnitt mitnehmen.',
    },
  },
  {
    id: '26',
    topicId: 2,
    topicTitle: {
      en: 'Shipping',
      he: 'שינועים',
      br: 'Entrega',
      de: 'Versand',
    },
    title: {
      en: 'How do I transfer Shareitt points for person to person delivery?',
      he: 'איך אני מעביר את הנקודות עבור השינועים?',
      br: 'Como posso transferir pontos por uma entrega personalizada?',
      de: 'Wie übertrage ich Shareitt-Punkte an Shareitt-Bot:innen, die meine Produkte mitnehmen?',
    },
    description: {
      en:
        'Once the item has been transferred from the user offering the item to the courier or from one courier to another, you\u0027ll receive a link from the courier to pay for storage or the \u0027delivery stage\u0027. Click on the link to pay for storage or the delivery stage in points. It\u0027s important to save points for this purpose.',
      he:
        'לאחר העברת הפריט מהמוסר למשנע או ממשנע אחד לאחר, ישלח אליך קישור לתשלום על ״שינוע״ מהמשנעים עצמם. יש להיכנס ללינק ולשלם על השינוע או את האחסון עבור היעד המבוקש. חשוב מאד לשמור נקודות עבור שינועים אלו.',
      br:
        'Assim que o item é transferido do usuário para o courier ou de um courier para outro, você vai receber um link para pagar o armazenamento ou outro "estágio de entrega". Clique no link para pagar em pontos. Por isso é importante economizar pontos.',
      de:
        'Sobald die Shareitt-Bot:in den Auftrag bekommen hat, den Artikel mitzunehmen, erhältst du einen Link für die Entschädigung der entsprechenden Etappe. Klicke auf den Link, damit die Punkte übertragen werden und achte darauf, dass du für diesen Service genügend Punkte auf deinem Konto hast.',
    },
  },
  {
    id: '27',
    topicId: 2,
    topicTitle: {
      en: 'Shipping',
      he: 'שינועים',
      br: 'Entrega',
      de: 'Versand',
    },
    title: {
      en: 'Can I transport items via Shareitt person to person couriers too?',
      he: 'האם גם אני יכול למכור פריטים ולהעבירם באמצעות משנעים?',
      br: 'Posso transportar os itens através do serviço de courier personalizado do Shareitt?',
      de:
        'Kann ich Sendungen auch über Shareitt-Bot:innen (Personen, die Produkte mitnehmen) transportieren?',
    },
    description: {
      en:
        'In order to participate in the Shareitt  person to person courier pilot, contact us via chat and we\u0027ll be happy to tell you more.',
      he:
        'על מנת להשתתף בפיילוט ולהיעזר במשנעים, יש ליצור קשר בצ׳אט עם Shareitt ונשמח להעביר לך פרטים נוספים.',
      br:
        'Para participar do serviço de courier personalizado do Shareitt, entre em contato conosco através do chat. Ficaremos felizes em poder ajudar.',
      de:
        'Falls du im Team von Sharitt-Bot:innen mitmachen möchtest, kontaktiere uns per Chat und wir werden dich gerne weiter informieren',
    },
  },
  {
    id: '28',
    topicId: 9,
    topicTitle: {
      en: 'Endorsements',
      he: 'המלצות',
      br: 'Recomendações',
      de: 'Empfehlungen',
    },
    title: {
      en: 'What are endorsements?',
      he: 'מהן המלצות?',
      br: 'O que são recomendações?',
      de: 'Was sind Empfehlungen?',
    },
    description: {
      en:
        'On each user\u0027s profile there\u0027s an Endorsements section - where you can find recommendations made by users with verified accounts, according to various criteria: 	availability, pricing, quality, reliability, item supply, efficiency, friendliness.',
      he:
        'המלצות הן תוספת לעמוד הפרופיל של כל משתמש בו ניתן לראות המלצות במגוון קריטריונים מוגדרים שהוענקו על ידי משתמשים עם חשבונות מאמותים בלבד. הקריטריונים הם: 	זמינות, תמחור, איכות, אמינות, היצע, יעילות, חברתיות.',
      br:
        'A seção Recomendações está em cada perfil de usuário. Lá você pode achar recomendações feitas de usuários de contas verificadas de acordo com alguns critérios: 	disponibilidade, preços, qualidade, confiança, fornecimento, eficiência e simpatia.',
      de:
        'Auf jedem Profil gibt es einen Bereich "Empfehlungen", in dem du andere Mitglieder mit verifizierten Konten nach verschiedenen Kriterien empfehlen kannst: Verfügbarkeit, Preis, Qualität, Zuverlässigkeit, Lieferung von Artikeln, Effizienz, Freundlichkeit.',
    },
  },
  {
    id: '29',
    topicId: 9,
    topicTitle: {
      en: 'Endorsements',
      he: 'המלצות',
      br: 'Recomendações',
      de: 'Empfehlungen',
    },
    title: {
      en: 'Where can I see endorsements for a specific user?',
      he: 'היכן ניתן לראות המלצות על חשבון מסויים?',
      br: 'Como posso ver se um usuário tem recomendação?',
      de: 'Wo kann ich die Empfehlungen für bestimmte Mitglieder sehen?',
    },
    description: {
      en:
        'Click the endorsements button on the user\u0027s profile page in order to see the endorsements in the different categories.',
      he:
        'בעמוד הפרופיל של המשתמש ניתן ללחוץ על כפתור ההמלצות ולראות את ההמלצות בקריטריונים השונים.',
      br: 'Clique em "Recomendações" na página do usuário para ver os diferentes tipos de apoio.',
      de:
        'Klicke auf die Schaltfläche "Empfehlungen" auf der Profilseite der Person, wo du Rückmeldungen in verschiedenen Kategorien machen kannst..',
    },
  },
  {
    id: '30',
    topicId: 9,
    topicTitle: {
      en: 'Endorsements',
      he: 'המלצות',
      br: 'Recomendações',
      de: 'Empfehlungen',
    },
    title: {
      en: 'How can I give endorsements?',
      he: 'כיצד גם אני יכול לתת המלצות על חשבון אחר?',
      br: 'Como posso fazer recomendações?',
      de: 'Wie kann ich Empfehlungen geben?',
    },
    description: {
      en:
        'If you\u0027re account is verified, you can provide other users with endorsements. For more information see "Verified Accounts" in the FAQ section.',
      he:
        'במידה והחשבון שלך הינו חשבון מאומת, ניתן לתת המלצות על חשבונות אחרים. לפרטים נוספים על חשבונות מאומתים ראה ״חשבונות מאומתים״ בשאלות ותשובות.',
      br:
        'Se sua conta é verificada você pode apoiar outros usuários. Para mais informações veja "Contas Verificadas" na página perguntas frequentes.',
      de:
        'Wenn dein Konto verifiziert ist, kannst du auch andere Nutzer:innen weiter empfehlen und zu dieser Auszeichnung verhelfen. Weitere Informationen findest du unter "Verifizierte Konten" im Abschnitt FAQ',
    },
  },
  {
    id: '31',
    topicId: 9,
    topicTitle: {
      en: 'Endorsements',
      he: 'המלצות',
      br: 'Recomendações',
      de: 'Empfehlungen',
    },
    title: {
      en: 'How can I get my account verified?',
      he: 'כיצד גם אני יכול לתת המלצות על חשבון אחר?',
      br: 'Como posso ter uma conta verificada?',
      de: 'Wie kann ich mein Konto verifizieren lassen?',
    },
    description: {
      en:
        'Your account is automatically verified once you reach the following benchmarks: 	two months on the app, fifty different item transfers to at least 10 people and endorsements from three verified users.',
      he:
        'רק חשבון מאומת יכול להמליץ על חשבון אחר. לפרטים נוספים אודות חשבונות מאומתים ראה ״חשבונות מאומתים״ בשאלות ותשובות.',
      br:
        'Sua conta automaticamente se torna verificada quando você atinge um desses marcos: 	dois meses no app, 50 trocas de itens com pelo menos 10 pessoas e apoio de 3 usuários verificados.',
      de:
        'Dein Konto wird automatisch verifiziert, wenn du folgende Kriterien erfüllst: Nutzung der App über zwei Monate, fünfzig erfolgreiche Transaktionen an mindestens 10 Personen und Empfehlungen von drei verifizierten Mitgliedern.',
    },
  },
  {
    id: '32',
    topicId: 10,
    topicTitle: {
      en: 'Verified account',
      he: 'חשבון מאומת',
      br: 'Conta verificada',
      de: 'Verifiziertes Konto',
    },
    title: {
      en: 'What\u0027s a Verified Account?',
      he: 'מהו חשבון מאומת?',
      br: 'O que é uma conta verificada?',
      de: 'Was ist ein verifiziertes Konto?',
    },
    description: {
      en:
        'A Verified Account is an account that meets various Shareitt criteria - with the goal of improving the item exchange experience. The "Verified Account" badge on a user\u0027s profile page encourages other users to receive items from or exchange items with that user. Only verified users can provide other users with endorsements.',
      he:
        'חשבון מאומת הוא חשבון משתמש העומד במספר קריטריונים שהוגדרו על ידי Shareitt ומטרתו לשפר את תהליכי החלפת הפריטים בין המשתמשים באפליקציה. סימון “חשבון מאומת" בעמוד הפרופיל מגדיל את רמת הביטחון של משתמשים המעוניינים לקבל או למסור פריט למשתמש זה. משתמשים עם חשבונות מאומתים בלבד יכולים לתת המלצות כלליות על משתמשים אחרים במערכת.',
      br:
        'Uma conta verificada é uma conta que está de acordo com os nossos critérios e tem como objetivo melhorar a sua experiência de trocas . O selo de conta verificada na página de perfil de um usuário faz com que todos os outros usuários queiram receber ou trocar com ele. Somente usuários verificados podem apoiar outros usuários.',
      de:
        'Ein verifiziertes Konto ist ein Konto, das verschiedene Shareitt-Kriterien erfüllt - mit dem Ziel, Vertrauen zu bilden und so die Transaktionen zu fördern. Nur verifizierte Mitglieder können andere Mitglieder weiterempfehlen und so zur Bezeichnung  "verifiziertes Konto" verhelfen.',
    },
  },
  {
    id: '33',
    topicId: 10,
    topicTitle: {
      en: 'Verified account',
      he: 'חשבון מאומת',
      br: 'Conta verificada',
      de: 'Verifiziertes Konto',
    },
    title: {
      en: 'Where can I see if a certain user\u0027s account is verified?',
      he: 'היכן ניתן לראות האם חשבון הוא מאומת?',
      br: 'Como posso ver se a conta de um usuário é verificada?',
      de: 'Wo kann ich sehen, ob das Konto eines bestimmten Benutzers verifiziert ist?',
    },
    description: {
      en: 'The Verified Account badge appears on the user\u0027s profile page.',
      he: 'בעמוד הפרופיל של המשתמש ניתן לראות אם יש סימון של חשבון מאומת.',
      br: 'O selo de conta verificada aparece na página de perfil do usuário.',
      de: 'Das Symbol  "Verifiziertes Konto " wird auf der Profilseite des Benutzers angezeigt',
    },
  },
  {
    id: '34',
    topicId: 10,
    topicTitle: {
      en: 'Verified account',
      he: 'חשבון מאומת',
      br: 'Conta verificada',
      de: 'Verifiziertes Konto',
    },
    title: {
      en: 'How can I get my account verified?',
      he: 'כיצד גם אני יכול לקבל חשבון מאומת?',
      br: 'Como posso ter uma conta verificada?',
      de: 'Wie kann ich mein Konto verifizieren lassen?',
    },
    description: {
      en:
        'Your account is automatically verified once you reach the following benchmarks: 	two months on the app, fifty different item transfers to at least 10 people and endorsements from five verified users.',
      he:
        'אימות נעשה באופן אוטומטי לאחר שהגעת ליעדים הבאים: 	חודשיים באפליקצייה, חמישים מסירות שונות לעשרה אנשים או יותר והמלצות משלושה משתמשים מאומתים יהפכו את חשבונך למאומת.  חשבונות שעברו בדיקה ייפסלו ולא יהפכו לחשבונות מאומתים.',
      br:
        'Sua conta automaticamente se torna verificada quando você atinge um desses marcos: 	dois meses no app, 50 trocas de itens com pelo menos 10 pessoas e apoio de 3 usuários verificados.',
      de:
        'Dein Konto wird automatisch verifiziert, wenn du folgende Kriterien erfüllst: zwei Monate Nutzung der App, fünfzig verschiedene Artikelübertragungen an mindestens 10 Personen und Bestätigung von fünf verifizierten Mitgliedern.',
    },
  },
  {
    id: '35',
    topicId: 10,
    topicTitle: {
      en: 'Verified account',
      he: 'חשבון מאומת',
      br: 'Conta verificada',
      de: 'Verifiziertes Konto',
    },
    title: {
      en: 'My account was reviewed. Can I still get it verified?',
      he: 'חשבוני עבר בדיקה, האם עדיין אוכל לאמת אותו?',
      br: 'Minha conta foi revisada. Posso ter uma conta verificada mesmo assim?',
      de: 'Mein Konto wurde überprüft. Kann ich es trotzdem verifizieren lassen?',
    },
    description: {
      en:
        'Once your account has undergone review, it will automatically be invalidated. In order to request a renewed review of your account, please contact us at: 	… We will accept requests for renewed review once per account - at least two months after the original review date.',
      he:
        'ברגע שחשבונך עבר בדיקה, המערכת תפסול אותו אוטומטית. על מנת לבקש בדיקה מחודשת של החשבון, נא צרו קשר באימייל: 	Info@shareitt.com. אנחנו נקבל בקשות אימות לחשבונות שנבדקו פעם אחת בלבד. האימות יכול להתבצע כחודשיים או יותר לאחר בדיקת החשבון.',
      br:
        'Quando a sua conta entra em processo de revisão, ela será automaticamente invalidada. Para pedir um nova revisão da sua conta, por favor, entre em contato conosco aqui... Aceitamos somente uma nova revisão por conta e num prazo de pelo menos dois meses depois da primeira revisão.',
      de:
        'Sobald dein Konto überprüft wurde, wird es automatisch gesperrt. Falls du das Konto reaktivieren möchtest, wende dich sich bitte an uns: 	... Wir akzeptieren Anträge auf eine erneute Überprüfung frühestens zwei Monate nach dem Datum der ursprünglichen Überprüfung.',
    },
  },
];
