import { createSelector } from 'reselect';
import * as R from 'ramda';
import { getCommunityId } from '../communityInfo/selectors';
import { getAdminPermissions } from '../auth/selectors';

export const getCommunityIds = (state) => {
  return state.communities.ids;
};

export const getCommunityEntities = (state) => {
  return state.communities.entities;
};

export const getCommunitiesList = createSelector(
  [getCommunityIds, getCommunityEntities, getCommunityId],
  (ids, entities, currentCommunityId) => {
    const list = ids?.map((id) => ({
      ...entities[id],
      isActive: id === currentCommunityId,
    }));

    return R.sortWith([R.descend(R.prop('isActive'))])(list);
  },
);

export const getCommunityById = createSelector(
  [getCommunityEntities, (_, communityId) => communityId],
  (entities, communityId) => {
    return entities[communityId];
  },
);

export const getAdminCommunitiesList = createSelector(
  [getCommunitiesList, getAdminPermissions],
  (communities, adminPermissions) => {
    return communities.filter((community) => {
      return adminPermissions.communityIds.includes(community.id);
    });
  },
);

export default {
  getCommunitiesList,
  getAdminCommunitiesList,
  getCommunityById,
};
