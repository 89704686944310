import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors, dimensions } from '../../styles';
import Icon from './Icon';
import { getCurrentTheme } from '../../templates';

const s = StyleSheet.create({
  checkboxContainer: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayBorder,
    backgroundColor: colors.white,
    marginHorizontal: dimensions.halfMedium,
  },
});

const SquareCheckBox = ({ selected, style }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View
      style={[
        s.checkboxContainer,
        selected && {
          borderColor: currentTheme.colors.secondary,
          backgroundColor: currentTheme.colors.secondary,
        },
        style,
      ]}
    >
      <Icon name="check" size={14} color={colors.white} withoutRTLScale />
    </View>
  );
};

export default SquareCheckBox;
