import React, { useState, useEffect } from 'react';

import { Pressable, View, Animated } from 'react-native';

import Text from '../Text';
import { getCurrentTheme } from '../../../templates';

import { s } from './styles';

const Radio = ({ isMarked, onChangeMarked, label, customStyle }) => {
  const [fadeAnimation] = useState(new Animated.Value(0));
  const currentTheme = getCurrentTheme();

  useEffect(() => {
    Animated.timing(fadeAnimation, {
      toValue: isMarked ? 1 : 0,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [isMarked]);
  return (
    <Pressable style={[s.wrapper, customStyle]} onPress={onChangeMarked}>
      <View style={[s.container, { borderColor: currentTheme.colors.secondary }]}>
        <Animated.View
          style={[
            s.content,
            {
              backgroundColor: currentTheme.colors.secondary,
              opacity: fadeAnimation,
            },
          ]}
        />
      </View>
      <Text style={s.label}>{label}</Text>
    </Pressable>
  );
};

export default Radio;
