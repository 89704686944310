import api from './api';
import { COMMON_LIMIT } from '../constants/listLimits';

export default {
  getUserCommunity: () => api.get(`getUserCommunity`),

  getCities: ({ communityId, searchTerm = '', skip = 0, limit = COMMON_LIMIT }) =>
    api.get(`getCities/${communityId}?limit=${limit}&skip=${skip}&search_term=${searchTerm}`),

  getCampuses: ({ limit = COMMON_LIMIT, skip = 0, searchTerm = '' }) =>
    api.get(`campuses?limit=${limit}&skip=${skip}&search_term=${searchTerm}`),
  getSlides: (communityId) => api.get(`slides/${communityId}`),

  getCommunityMembers: ({ communityId, limit, offset, searchTerm }) =>
    api.get(
      `communities/members/${communityId}?limit=${limit}&offset=${offset}&search_term=${searchTerm}`,
    ),

  getCommunities: () => api.get('communities/list'),
};
