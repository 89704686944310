import React, { useState, useEffect, Fragment } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import debounce from 'debounce';
import { StyleSheet, Keyboard, View } from 'react-native';
import { communityMembersOperations } from '../../../../store/communityMembers';
import { communityMembersSelector } from '../../../../store/communityMembers';
import { dimensions, colors, rs } from '../../../../styles';
import strings from '../../../../localization';
import MembersHistory from '../../../Screens/MembersScreen/components/MembersHistory';
import MembersListSectionTitle from '../../../Screens/MembersScreen/components/MembersListSectionTitle';
import {
  Text,
  TouchableItem,
  NavigationIconButton,
  SearchInput,
  CommunityMembersList,
} from '../../';
import { isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';

const s = StyleSheet.create({
  root: {
    flex: 1,
    paddingTop: 60,
  },
  swipeView: {
    width: 60,
    height: 8,
    alignSelf: 'center',
    marginBottom: dimensions.medium,
    borderRadius: 4,
    backgroundColor: colors.grayLightest,
    opacity: 0.8,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  headerContainer: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    left: dimensions.medium,
  },
  shareButton: {
    position: 'absolute',
    right: 0,
    alignItems: 'center',
  },
});

const InAppSharingModal = ({
  params,
  membersSearchHistory,
  onClose,
  getCommunityMembers,
  getRecentMembersSearches,
  saveMemberSearch,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const currentTheme = getCurrentTheme();

  const getMembersDebounce = debounce((value) => {
    getCommunityMembers(value);
  }, 400);

  useEffect(() => {
    getRecentMembersSearches();
    getCommunityMembers();

    return () => {
      getCommunityMembers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeText = (value) => {
    setSearchValue(value);
    getMembersDebounce(value);
  };

  const onSelectUser = (user) => {
    saveMemberSearch(user.id);
    params.onSubmit(user);
    onClose();
  };

  const onSaveMemberSearch = (userId, user) => {
    saveMemberSearch(userId);
    params.onSubmit({
      id: user.user_id,
      name: user.user_name,
      profile_image: user.profile_image,
    });
    onClose();
    Keyboard.dismiss();
  };

  const showMemberSearchHistory = !R.isEmpty(membersSearchHistory) && R.isEmpty(searchValue);

  return (
    <View style={s.root}>
      {!isWeb && <View style={s.swipeView} />}

      <View style={s.contentContainer}>
        <View style={s.headerContainer}>
          <TouchableItem style={s.closeButton} onPress={onClose}>
            <Text color={currentTheme.colors.secondary}>{strings.common.cancel}</Text>
          </TouchableItem>

          <Text style={rs.alignCenter} color={colors.secondary} medium bold>
            {strings.share.share_item}
          </Text>

          <View style={s.shareButton}>
            <NavigationIconButton
              onPress={() => {
                if (isWeb) {
                  onClose();
                  params.onOutsideSharePress();
                } else {
                  params.onOutsideSharePress();
                }
              }}
              name="Upload_grey"
              color={currentTheme.colors.secondary}
              isFontIcon
            />
          </View>
        </View>

        <View style={rs.smallPaddingVertical}>
          <SearchInput
            placeholder={strings.members.search_members}
            value={searchValue}
            onChangeText={onChangeText}
          />
        </View>

        <CommunityMembersList
          searchValue={searchValue}
          onSelectUser={onSelectUser}
          isMulti={false}
          ListHeaderComponent={
            <Fragment>
              {showMemberSearchHistory && (
                <Fragment>
                  <MembersListSectionTitle title={strings.members.recent} />

                  <MembersHistory
                    communityMembersSearchHistory={membersSearchHistory}
                    onSaveMemberSearch={onSaveMemberSearch}
                    forceNavigateToProfile={false}
                  />

                  <MembersListSectionTitle title={strings.members.members} />
                </Fragment>
              )}
            </Fragment>
          }
        />
      </View>
    </View>
  );
};

InAppSharingModal.propTypes = {
  params: T.shape({
    onSharePress: T.func.isRequired,
    onSubmit: T.func.isRequired,
  }),
  membersSearchHistory: T.arrayOf(T.shape({})).isRequired,
  onClose: T.func.isRequired,
  getCommunityMembers: T.func.isRequired,
  getRecentMembersSearches: T.func.isRequired,
  saveMemberSearch: T.func.isRequired,
};

export default connect(
  (state) => ({
    membersSearchHistory: communityMembersSelector.getCommunityMembersHistory(state),
  }),
  (dispatch) => ({
    getCommunityMembers: (searchTerm = '') =>
      dispatch(
        communityMembersOperations.getCommunityMembers({ searchTerm, excludeCurrentUser: true }),
      ),
    getRecentMembersSearches: () => dispatch(communityMembersOperations.getRecentMembersSearches()),
    saveMemberSearch: (userId) => dispatch(communityMembersOperations.saveMemberSearch({ userId })),
  }),
)(InAppSharingModal);
