import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import { colors, dimensions } from '../../../../../styles';
import strings from '../../../../../localization';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  button: {
    paddingHorizontal: dimensions.medium,
    alignSelf: 'flex-end',
    marginTop: dimensions.medium,
    marginBottom: 60,
  },
});

const Footer = memo(() => {
  const currentTheme = getCurrentTheme();
  return (
    <TouchableItem style={s.button} onPress={() => NavigationService.navigate(screens.Rewards)}>
      <Text color={currentTheme.colors.secondary}>{strings.rewards.see_all_badges}</Text>
    </TouchableItem>
  );
});

export default Footer;
