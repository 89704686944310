/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import MapView from 'react-native-maps';
import MapMarker from './MapMarker';
import customMapStyle from '../../constants/googleMapCustomStyles';

const SmallMap = ({ selectedLocation, locations = [] }) => {
  const locationsArr = locations.length ? locations : [selectedLocation];

  return (
    <MapView
      style={{
        height: 240,
      }}
      region={{
        latitude: selectedLocation.latitude,
        longitude: selectedLocation.longitude,
        latitudeDelta: 0.08,
        longitudeDelta: 0.08,
      }}
      customMapStyle={customMapStyle}
    >
      {locationsArr.map((location) => {
        const isActive = selectedLocation.id === location.id;

        return (
          <MapMarker location={location} key={location.id} isActive={isActive} />
        );
      })}
    </MapView>
  );
};

export default SmallMap;
