import React, { Fragment, useState, useRef, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  RefreshControl,
  ScrollView,
  View,
  StyleSheet,
  ActivityIndicator,
  Modal,
} from 'react-native';
import {
  Container,
  HorizontalGroupsList,
  SectionTitle,
  HorizontalUsersList,
  HorizontalCategoriesList,
  HorizontalItemsList,
  ActionBanner,
  WishesBanner,
  MarketplaceBanner,
  InviteFriendsBanner,
} from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';
// import userTestimonials from '../../../../constants/userTestimonials';
import { groupsSelectors } from '../../../../store/groups';
import { homeOperations, homeSelectors } from '../../../../store/home';
import { lotsOperations } from '../../../../store/lots';
import {
  AnalyticsService,
  LoadingService,
  ModalsService,
  NavigationService,
  ToastsService,
} from '../../../../services';
import screens from '../../../../navigation/screens';
import { sortingTypes } from '../../../../constants/sortingTypes';
// import ActionSlider from './components/BannerCarousel';
// import HorizontalFAQList from './components/HorizontalFAQList';
// import HorizontalInfoList from './components/HorizontalInfoList';
// import UserTestimonialsList from './components/UserTestimonialsList';
import HorizontalRewardsList from './components/HorizontalRewardsList';
import HorizontalCitiesList from './components/HorizontalCitiesList';
import HorizontalCampusesList from './components/HorizontalCampusesList';
import InfoList from './components/InfoList';
import guideTypes from '../../../../constants/guidelTypes';
import { guideOperations } from '../../../../store/guides';
import { isRTL } from '../../../../utils/rtlHelper';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { appOperations } from '../../../../store/app';
import { getIsUSDiscoveryZone, getSchoolExtension } from '../../../../store/userInfo/selectors';
import { getIsUSCommunity } from '../../../../utils/usCommunity';
import MarketplaceCarousel from '../../../ReusableComponents/Banners/MarketplaceCarousel';
import { getLanguage } from '../../../../utils/localizationHelper';
import { isWeb } from '../../../../utils/detectDevice';
import MarketplaceSearchBar from '../../../ReusableComponents/MarketplaceSearchBar/index.web';
import { useGetWindowCurrentWidth } from '../../../../utils/getWindowCurrentWidth';
import { getAppLanguage } from '../../../../store/app/selectors';
import modalTypes from '../../../../constants/modalTypes';

const MainTab = ({
  groups,
  cities,
  campuses,
  rewards,
  myFollowersList,
  topSellerUsersList,
  mySellerUsersList,
  sellersNearMeList,
  mostFollowedUsersList,
  topCategoriesList,
  myTopCategoriesList,
  newestItems,
  itemsFromMembersIFollowList,
  topItemsList,
  nearMeItemsList,
  nearPrimaryLocationItemsList,
  discountedItemsList,
  newestItemsList,
  replaceMarketplaceFiltersWithNew,
  communityInfo,
  isShowTokenBanner,
  neverShowGuideAgain,
  getCommunityHomePage,
  userId,
  handleGeolocation,
  isLocationPermissionGranted,
  isUSDiscoveryZone,
  schoolExtension,
  newestWishes,
  communityName,
}) => {
  const [isRefreshing, setRefreshing] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [groupsData, setGroupsData] = useState(groups);

  const IS_SOCIAL_DELIVERY = communityInfo.id === 3;
  const isUSCommunity = getIsUSCommunity(communityInfo.id);

  const groupRef = useRef(null);
  const visibleItemsNearPrimaryLocationRef = useRef(null);
  const visibleItemsNearMeRef = useRef(null);
  const itemsFromMembersIFollowListRef = useRef(null);
  const newestItemsListRef = useRef(null);
  const newestWishesListRef = useRef(null);
  const topItemsListRef = useRef(null);
  const visibleItemsNearMeAndPrimaryLocationRef = useRef(null);
  const citiesRef = useRef(null);
  const campusesRef = useRef(null);
  const rewardsRef = useRef(null);
  const sellersNearMeListRef = useRef(null);
  const mySellerUsersListRef = useRef(null);
  const myFollowersListRef = useRef(null);
  const topSellerUsersListRef = useRef(null);
  const discountedItemsListRef = useRef(null);
  const mostFollowedUsersListRef = useRef(null);
  const topCategoriesListRef = useRef(null);
  const myTopCategoriesListRef = useRef(null);

  const language = useSelector(getAppLanguage);

  const {
    has_most_followed_users,
    has_my_followers,
    has_top_sellers,
    has_my_last_sellers,
    has_newest_in_group,
    has_items_from_members_i_follow,
    has_items_near_me,
    has_items_near_primary_location,
    has_discounted_items,
    has_my_top_categories,
    has_top_categories,
    has_sellers_near_you,
    has_top_items,
    has_newest_items,
    disable_popular_cities,
  } = R.propOr({}, 'home_settings', communityInfo);

  useEffect(() => {
    const updatedGroupsData = [...groups];

    updatedGroupsData.sort((a, b) => {
      const nameA = a?.group_name?.toUpperCase();
      const nameB = b?.group_name?.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    setGroupsData(updatedGroupsData);
  }, [groups.length]);

  const onRefresh = async () => {
    if (isRefreshing) {
      return;
    }

    setRefreshing(true);
    await getCommunityHomePage();
    if (isLocationPermissionGranted) {
      await handleGeolocation();
    }

    setRefreshing(false);
  };

  const onSelectGroup = ({ id }) => {
    replaceMarketplaceFiltersWithNew({ groupIds: [id] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };
  const onOpenMarketplaceSortedByDistance = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_items_near_me, {
      user_id: userId,
    });
    replaceMarketplaceFiltersWithNew({ sortBy: sortingTypes.by_distance });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
        params: 0,
      });
    }, 0);
  };

  const onSelectCategory = (item) => {
    replaceMarketplaceFiltersWithNew({ groupIds: [item.group_id], selectedCategoryIds: [item.id] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSelectDiscountedItems = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_discounted_items, {
      user_id: userId,
    });

    replaceMarketplaceFiltersWithNew({ isSearchByDiscount: true });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSelectItemsFromFollowings = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_items_from_followings, {
      user_id: userId,
    });
    replaceMarketplaceFiltersWithNew({ isSearchByFollowings: true });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onNeverShowTokenBannerAgain = () => {
    neverShowGuideAgain({ guideType: guideTypes.HOME_TOKEN_INFO_BANNER });
  };

  const onOpenMarketplace = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_see_all_categories, {
      user_id: userId,
    });
    NavigationService.navigate(screens.MarketplaceTab, { screen: screens.Marketplace });
    NavigationService.navigate(screens.GroupFilterStack);
  };

  const onSeeAllNewestItems = () => {
    replaceMarketplaceFiltersWithNew({});
    NavigationService.navigate(screens.Marketplace);
    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllNewestWishes = () => {
    NavigationService.navigate(screens.Wishes);
  };

  const onOpenAllRewards = () => {
    NavigationService.navigate(screens.EarnTokens);
  };

  const onSelectCity = (city) => {
    replaceMarketplaceFiltersWithNew({ selectedCities: [city] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSelectCampus = (campus) => {
    replaceMarketplaceFiltersWithNew({ selectedCampuses: [campus] });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllItemsNearMe = async () => {
    LoadingService.showLoader();

    const coordinates = await handleGeolocation();

    if (!coordinates) {
      ToastsService.showError();
      return;
    }
    LoadingService.hideLoader();

    replaceMarketplaceFiltersWithNew({ coordinates, sortBy: sortingTypes.by_distance });

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllItemsNearPrimaryAddress = () => {
    const updatedFilters = isUSDiscoveryZone
      ? { schoolExtension }
      : { isSearchByPrimaryLocation: true, sortBy: sortingTypes.by_distance };

    replaceMarketplaceFiltersWithNew(updatedFilters);

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllTopItems = () => {
    replaceMarketplaceFiltersWithNew({ isOnlyFeaturedItems: true }, true);
    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const onSeeAllCities = () => {
    NavigationService.navigate(screens.CitiesFilterStack);
  };

  const onSeeAllCampuses = () => {
    NavigationService.navigate(screens.CampusesFilter);
  };

  const onOpenSearch = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_select_search);
    NavigationService.navigate(screens.HomeSearch);
  };

  const isVisibleItemsNearMe =
    !!has_items_near_me && isLocationPermissionGranted && !!nearMeItemsList.length;

  const isVisibleItemsNearPrimaryLocation =
    !!has_items_near_primary_location && !!nearPrimaryLocationItemsList.length;

  const { windowCurrentWidth, isMobileWindow } = useGetWindowCurrentWidth();

  useEffect(() => {
    let timeoutId;
    if (communityInfo?.about) {
      timeoutId = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [communityInfo]);

  if (isLoading)
    return (
      <Modal visible={isLoading}>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator />
        </View>
      </Modal>
    );

  return (
    <Container>
      {isWeb && !isMobileWindow && (
        <View style={s({ windowCurrentWidth }).searchInput}>
          <MarketplaceSearchBar
            value={''}
            defaultValue={''}
            onFocus={onOpenSearch}
            onSubmitEditing={onOpenSearch}
            placeholder={strings.other.search_placeholder}
            showCancel={false}
          />
        </View>
      )}
      <ScrollView
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
      >
        <View style={[rs.alignCenter, isWeb && !isMobileWindow && rs.veryBigMarginTop]}>
          <MarketplaceCarousel
            pointsName={
              (communityInfo?.token_name &&
              communityInfo.token_name[language] === strings.home.hearts
                ? strings.home.hearts_points
                : communityInfo.token_name[language]) || strings.home.token_banner_blue_text
            }
          />
        </View>

        {groups.length > 1 && (
          <Fragment>
            <SectionTitle
              title={strings.groups.groups}
              onSeeAllPress={onOpenMarketplace}
              contentContainerStyle={rs.paddingTop}
              refToScroll={groupRef}
            />
            <HorizontalGroupsList
              refToScroll={groupRef}
              groups={groupsData}
              onSelect={onSelectGroup}
            />
          </Fragment>
        )}

        {!!has_items_from_members_i_follow && !!itemsFromMembersIFollowList.length && (
          <Fragment>
            <SectionTitle
              refToScroll={itemsFromMembersIFollowListRef}
              title={strings.home.items_from_members_i_follow}
              onSeeAllPress={onSelectItemsFromFollowings}
            />
            <HorizontalItemsList
              refToScroll={itemsFromMembersIFollowListRef}
              items={itemsFromMembersIFollowList}
              analyticsEventType={analyticsEventTypes.home_select_item_from_following}
            />
          </Fragment>
        )}

        {isVisibleItemsNearMe && (
          <Fragment>
            <SectionTitle
              refToScroll={visibleItemsNearMeRef}
              title={strings.home.items_near_me}
              onSeeAllPress={onSeeAllItemsNearMe}
            />
            <HorizontalItemsList
              refToScroll={visibleItemsNearMeRef}
              items={nearMeItemsList}
              analyticsEventType={analyticsEventTypes.home_select_item_near_me}
            />
          </Fragment>
        )}

        {!isVisibleItemsNearMe && isVisibleItemsNearPrimaryLocation && (
          <Fragment>
            <SectionTitle
              refToScroll={visibleItemsNearPrimaryLocationRef}
              title={
                isUSDiscoveryZone
                  ? strings.home.items_near_campus
                  : strings.home.items_near_primary_location
              }
              onSeeAllPress={onSeeAllItemsNearPrimaryAddress}
            />
            <HorizontalItemsList
              refToScroll={visibleItemsNearPrimaryLocationRef}
              items={nearPrimaryLocationItemsList}
              analyticsEventType={analyticsEventTypes.home_select_items_near_primary_location}
            />
          </Fragment>
        )}

        {!!has_newest_items && !!newestItemsList.length && (
          <Fragment>
            <SectionTitle
              refToScroll={newestItemsListRef}
              title={strings.home.newest_items}
              onSeeAllPress={onSeeAllNewestItems}
            />
            <HorizontalItemsList refToScroll={newestItemsListRef} items={newestItemsList} />
          </Fragment>
        )}

        <InviteFriendsBanner
          containerStyle={[rs.marginBottom, rs.bigMarginTop]}
          pointsName={
            (communityInfo?.token_name && communityInfo.token_name[language]) ||
            strings.home.token_banner_blue_text
          }
        />

        {!!has_top_items && !!topItemsList.length && (
          <Fragment>
            <SectionTitle
              refToScroll={topItemsListRef}
              title={strings.home.top_items}
              onSeeAllPress={onSeeAllTopItems}
            />
            <HorizontalItemsList
              refToScroll={topItemsListRef}
              items={topItemsList}
              analyticsEventType={analyticsEventTypes.home_select_featured_item}
            />
          </Fragment>
        )}

        {newestWishes.length > 0 && (
          <Fragment>
            <SectionTitle
              refToScroll={newestWishesListRef}
              title={strings.home.newest_wishes}
              onSeeAllPress={onSeeAllNewestWishes}
            />
            <HorizontalItemsList isWish items={newestWishes} refToScroll={newestWishesListRef} />
          </Fragment>
        )}

        {!!disable_popular_cities ||
          (!isUSCommunity && !R.isEmpty(cities) && (
            <Fragment>
              <SectionTitle
                refToScroll={citiesRef}
                title={strings.home.popular_cities}
                onSeeAllPress={onSeeAllCities}
              />
              <HorizontalCitiesList refToScroll={citiesRef} data={cities} onSelect={onSelectCity} />
            </Fragment>
          ))}

        {isUSCommunity && !R.isEmpty(campuses) && (
          <Fragment>
            <SectionTitle
              refToScroll={campusesRef}
              title={strings.home.popular_campuses}
              onSeeAllPress={onSeeAllCampuses}
            />
            <HorizontalCampusesList
              refToScroll={campusesRef}
              data={campuses}
              onSelect={onSelectCampus}
            />
          </Fragment>
        )}

        {!R.isEmpty(rewards) && (
          <Fragment>
            <SectionTitle
              refToScroll={rewardsRef}
              title={
                communityName === strings.main.app_name.toLowerCase()
                  ? strings.home.win_points +
                    ' ' +
                    ((communityInfo?.token_name && communityInfo.token_name[language]) ||
                      strings.home.token_banner_blue_text)
                  : strings.home.win_points_sharingood
              }
              onSeeAllPress={onOpenAllRewards}
            />
            <HorizontalRewardsList refToScroll={rewardsRef} data={rewards} />
          </Fragment>
        )}

        {isVisibleItemsNearMe && isVisibleItemsNearPrimaryLocation && (
          <Fragment>
            <SectionTitle
              refToScroll={visibleItemsNearMeAndPrimaryLocationRef}
              title={
                isUSDiscoveryZone
                  ? strings.home.items_near_campus
                  : strings.home.items_near_primary_location
              }
              onSeeAllPress={onSeeAllItemsNearPrimaryAddress}
            />
            <HorizontalItemsList
              refToScroll={visibleItemsNearMeAndPrimaryLocationRef}
              items={nearPrimaryLocationItemsList}
              analyticsEventType={analyticsEventTypes.home_select_items_near_primary_location}
            />
          </Fragment>
        )}

        {!!has_sellers_near_you && !!sellersNearMeList.length && (
          <Fragment>
            <SectionTitle refToScroll={sellersNearMeListRef} title={strings.home.sellers_near_me} />
            <HorizontalUsersList
              refToScroll={sellersNearMeListRef}
              users={sellersNearMeList}
              analyticsEventType={analyticsEventTypes.home_select_seller_near_me}
            />
          </Fragment>
        )}

        {has_my_last_sellers && !R.isEmpty(mySellerUsersList) && (
          <Fragment>
            <SectionTitle refToScroll={mySellerUsersListRef} title={strings.home.my_sellers} />
            <HorizontalUsersList
              refToScroll={mySellerUsersListRef}
              users={mySellerUsersList}
              analyticsEventType={analyticsEventTypes.home_select_my_seller}
            />
          </Fragment>
        )}

        <WishesBanner
          analyticsEventType={analyticsEventTypes.home_select_wish_banner}
          pointsName={
            (communityInfo?.token_name && communityInfo?.token_name[language]) ||
            strings.home.token_banner_blue_text
          }
        />

        {!!has_my_followers && !!myFollowersList.length && (
          <Fragment>
            <SectionTitle refToScroll={myFollowersListRef} title={strings.home.my_followers} />
            <HorizontalUsersList refToScroll={myFollowersListRef} users={myFollowersList} />
          </Fragment>
        )}
        {/*{isShowTokenBanner && (*/}
        {/*  <View style={[rs.bigMarginTop, rs.smallPaddingTop]}>*/}
        {/*    <TokenBanner onClose={onNeverShowTokenBannerAgain} />*/}
        {/*  </View>*/}
        {/*)}*/}

        {/*<ActionSlider />*/}

        {/*<SectionTitle title={strings.home.all_you_need_to_know} />*/}
        {/*<HorizontalFAQList />*/}

        {/*{!IS_SOCIAL_DELIVERY && (*/}
        {/*  <ActionBanner*/}
        {/*    analyticsEventType={analyticsEventTypes.home_select_invite_friends_banner}*/}
        {/*  />*/}
        {/*)}*/}

        {!!has_top_sellers && !!topSellerUsersList.length && (
          <Fragment>
            <SectionTitle refToScroll={topSellerUsersListRef} title={strings.home.top_sellers} />
            <HorizontalUsersList
              refToScroll={topSellerUsersListRef}
              users={topSellerUsersList}
              analyticsEventType={analyticsEventTypes.home_select_top_seller}
            />
          </Fragment>
        )}

        {has_newest_in_group && newestItems.lenght && (
          <Fragment>
            {newestItems.map((element, index) => {
              let newestItemsRef;
              if (isWeb) {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                newestItemsRef = useRef(null);
              }

              return (
                <Fragment key={element.groupId}>
                  <SectionTitle
                    refToScroll={newestItemsRef}
                    title={
                      isRTL
                        ? `${strings.home.newest_in}${element.groupName}`
                        : `${strings.home.newest_in} ${element.groupName}`
                    }
                    onSeeAllPress={() => {
                      AnalyticsService.logEvent(
                        analyticsEventTypes.home_see_all_items_in_category,
                        {
                          user_id: userId,
                        },
                      );
                      onSelectGroup({ id: element.groupId });
                    }}
                  />
                  <HorizontalItemsList
                    refToScroll={newestItemsRef}
                    items={element.items}
                    analyticsEventType={analyticsEventTypes.home_select_item_in_category}
                  />
                </Fragment>
              );
            })}
          </Fragment>
        )}

        {!!has_discounted_items && !!discountedItemsList.length && (
          <Fragment>
            <SectionTitle
              refToScroll={discountedItemsListRef}
              title={strings.home.discounted_items}
              onSeeAllPress={onSelectDiscountedItems}
            />
            <HorizontalItemsList
              refToScroll={discountedItemsListRef}
              items={discountedItemsList}
              analyticsEventType={analyticsEventTypes.home_select_discounted_item}
            />
          </Fragment>
        )}

        {!!has_most_followed_users && !!mostFollowedUsersList.length && (
          <Fragment>
            <SectionTitle
              refToScroll={mostFollowedUsersListRef}
              title={strings.home.most_followed_users}
            />
            <HorizontalUsersList
              refToScroll={mostFollowedUsersListRef}
              users={mostFollowedUsersList}
              analyticsEventType={analyticsEventTypes.home_select_most_followed}
            />
          </Fragment>
        )}

        {communityName === strings.main.app_name.toLowerCase() &&
          !!has_top_categories &&
          !!topCategoriesList.length && (
            <Fragment>
              <SectionTitle
                refToScroll={topCategoriesListRef}
                title={strings.home.popular_categories}
              />
              <HorizontalCategoriesList
                refToScroll={topCategoriesListRef}
                categories={topCategoriesList}
                onSelect={onSelectCategory}
                analyticsEventType={analyticsEventTypes.home_select_popular_category}
              />
            </Fragment>
          )}

        {!!has_my_top_categories && !!myTopCategoriesList.length && (
          <Fragment>
            <SectionTitle
              refToScroll={myTopCategoriesListRef}
              title={strings.home.my_top_categories}
            />
            <HorizontalCategoriesList
              refToScroll={myTopCategoriesListRef}
              categories={myTopCategoriesList}
              onSelect={onSelectCategory}
              analyticsEventType={analyticsEventTypes.home_select_my_top_category}
            />
          </Fragment>
        )}

        {/*<Fragment>*/}
        {/*  <SectionTitle title={strings.community_info.community_info} />*/}
        {/*  <InfoList />*/}
        {/*</Fragment>*/}

        {/*<Fragment>*/}
        {/*  <SectionTitle title={strings.home.user_testimonials} />*/}
        {/*  <UserTestimonialsList testimonials={userTestimonials} />*/}
        {/*</Fragment>*/}

        <View style={rs.marginVertical} />
      </ScrollView>
    </Container>
  );
};

const s = ({ windowCurrentWidth }) =>
  StyleSheet.create({
    searchInput: {
      backgroundColor: colors.white,
      position: 'fixed',
      padding: dimensions.medium,
      zIndex: 10,
      width: windowCurrentWidth * 1.08,
      alignSelf: 'center',
      maxWidth: 1100,
    },
  });

export default connect(
  (state) => ({
    communityName: state.communityTheme.theme,
    userId: state.userInfo.id,
    communityInfo: state.communityInfo,
    groups: groupsSelectors.getCommunityGroups(state),
    topItemsList: homeSelectors.getTopItems(state),
    myFollowersList: homeSelectors.getMyFollowersList(state),
    topSellerUsersList: homeSelectors.getTopSellerUsersList(state),
    mySellerUsersList: homeSelectors.getMySellerUsersList(state),
    sellersNearMeList: homeSelectors.getSellersNearmeList(state),
    mostFollowedUsersList: homeSelectors.getMostFollowedUsersList(state),
    topCategoriesList: homeSelectors.getTopCategoriesList(state),
    myTopCategoriesList: homeSelectors.getMyTopCategoriesList(state),
    itemsFromMembersIFollowList: homeSelectors.getItemsFromMembersIFollowList(state),
    nearMeItemsList: homeSelectors.getNearMeItemsList(state),
    nearPrimaryLocationItemsList: homeSelectors.getNearPrimaryLocationItemsList(state),
    discountedItemsList: homeSelectors.getDiscountedItemsList(state),
    // newestItems: state.home.newest_in,
    newestItems: homeSelectors.getNewest(state),
    rewards: homeSelectors.getRewardsList(state),
    cities: homeSelectors.getCitiesList(state),
    campuses: homeSelectors.getCampusesList(state),
    isShowTokenBanner: state.guides.showAgain[guideTypes.HOME_TOKEN_INFO_BANNER],
    newestItemsList: homeSelectors.getNewestItemsList(state),
    isLocationPermissionGranted: state.app.isLocationPermissionGranted,
    isUSDiscoveryZone: getIsUSDiscoveryZone(state),
    schoolExtension: getSchoolExtension(state),
    newestWishes: homeSelectors.getNewestWishesList(state),
  }),
  (dispatch) => ({
    getCommunityHomePage: () => dispatch(homeOperations.getCommunityHomePage()),
    replaceMarketplaceFiltersWithNew: (feedLotFilters, isNeedToResetItems) =>
      dispatch(lotsOperations.replaceMarketplaceFiltersWithNew(feedLotFilters, isNeedToResetItems)),
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
    handleGeolocation: () => dispatch(appOperations.handleGeolocation()),
  }),
)(MainTab);
