import React, { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { mobileHeight, mobileWidth } from '../styles/dimensions';
import { isWeb } from './detectDevice';

export const useGetWindowCurrentWidth = () => {
  const [windowCurrentWidth, setWindowCurrentWidth] = useState(Dimensions.get('window').width);
  const [windowCurrentHeight, setWindowCurrentHeight] = useState(Dimensions.get('window').height);

  const isMobileWindow = windowCurrentWidth < mobileWidth;
  // const isMobileWindow = windowCurrentWidth < mobileWidth && windowCurrentHeight < mobileHeight;

  useEffect(() => {
    function handleResize() {
      setWindowCurrentWidth(Dimensions.get('window').width);
      setWindowCurrentHeight(Dimensions.get('window').height);
    }

    isWeb && window.addEventListener('resize', handleResize);

    return () => {
      isWeb && window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    windowCurrentWidth:
      windowCurrentWidth > 1250
        ? 1100
        : windowCurrentWidth < 1250 && !isMobileWindow
        ? windowCurrentWidth - 300
        : windowCurrentWidth,
    windowCurrentHeight,
    isMobileWindow,
  };
};

export const useGetWindowCurrentWidthForClassComponent = (Component) => {
  return function WrappedComponent(props) {
    const { windowCurrentWidth, windowCurrentHeight, isMobileWindow } = useGetWindowCurrentWidth();
    return (
      <Component
        {...props}
        windowCurrentWidth={windowCurrentWidth}
        isMobileWindow={isMobileWindow}
        windowCurrentHeight={windowCurrentHeight}
      />
    );
  };
};
