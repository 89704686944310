import React, { memo } from 'react';
import T from 'prop-types';
import { Input, Text } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';
import { StyleSheet, View } from 'react-native';
import { isWeb } from '../../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
    paddingVertical: 20,
  },
  input: {
    width: 60,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
    textAlign: 'center',
    paddingBottom: 6,
  },
});

const PriceInput = memo(({ title, subtitle, value, onFocus, onChangeText, placeholder = '0', ...rest }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View style={s.contentContainer}>
      <View>
        <Text>{title}</Text>
        {!!subtitle && (
          <Text
            color={colors.grayBorder}
            style={[rs.verySmallPaddingTop, isWeb && { maxWidth: 300 }]}
          >
            {subtitle}
          </Text>
        )}
      </View>
      <Input
        style={[s.input, { color: currentTheme.colors.secondary }]}
        keyboardType="number-pad"
        onChangeText={onChangeText}
        value={value}
        onFocus={onFocus}
        placeholder={placeholder}
        underlineColorAndroid="transparent"
        textAlign={'center'}
        {...rest}
      />
    </View>
  );
});

PriceInput.propTypes = {
  title: T.string,
  subtitle: T.string,
  value: T.string,
  onFocus: T.func,
  onChangeText: T.func,
  placeholder: T.string,
};

export default PriceInput;
