import React from 'react';
import { StyleSheet, View } from 'react-native';
import strings from '../../../../localization';
import { FontIcon, Icon, Text, TouchableItem } from '../../';
import { MainContainer } from './styles';
import { colors, rs } from '../../../../styles';
import { getCurrentTheme } from '../../../../templates';

const s = StyleSheet.create({
  optionsContainer: {
    marginTop: 50,
    width: 200,
    flexWrap: 'nowrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  option: {
    width: 46,
    height: 46,
    borderRadius: 23,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 3,
    borderColor: colors.white,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
});

const OptionIcon = ({ name, color, zIndex, position }) => (
  <View
    style={[
      s.option,
      {
        backgroundColor: color,
        zIndex,
        left: -8 * position,
      },
    ]}
  >
    <FontIcon name={name} size={24} color={colors.white} withFixedSize={false} />
  </View>
);

const PaymentPlanComingSoon = ({ onPress: onClose }) => {
  const currentTheme = getCurrentTheme();

  const options = [
    // TODO: fix icons
    { name: 'heart', zIndex: 5, position: 0, color: '#C5CED0' },
    { name: 'magnifier', zIndex: 4, position: 1, color: colors.text },
    { name: 'bell', zIndex: 3, position: 2, color: currentTheme.colors.secondary },
    { name: 'spark', zIndex: 2, position: 3, color: currentTheme.colors.primary },
    { name: 'star', zIndex: 1, position: 4, color: '#5C1F98' },
  ];
  return (
    <View style={rs.alignCenter}>
      <TouchableItem onPress={onClose} style={s.closeButton}>
        <Icon
          name="x"
          size={16}
          color={colors.text}
          style={[rs.paddingVertical, rs.paddingHorizontal]}
        />
      </TouchableItem>

      <View style={s.optionsContainer}>
        {options.map(({ name, color, zIndex, position }) => (
          <OptionIcon key={name} name={name} color={color} zIndex={zIndex} position={position} />
        ))}
      </View>

      <MainContainer>
        <Text color={colors.text} medium semiBold alignCenter>
          {strings.payment_plan.modal_title}
        </Text>

        <Text color={colors.text} style={[rs.marginTop, rs.bigMarginBottom]} alignCenter>
          {strings.payment_plan.modal_description}
        </Text>
      </MainContainer>
    </View>
  );
};

export default PaymentPlanComingSoon;
