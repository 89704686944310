import React, { useState } from 'react';
import * as R from 'ramda';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { chatsOperations, chatsSelectors } from '../../../../../store/chats';
import ChatsList from './ChatsList';
import { EmptyListWithImage } from '../../../../ReusableComponents';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';
import strings from '../../../../../localization';
import { rs } from '../../../../../styles';

const ChatsListContainer = ({ isEditMode = false, onSelectChat, selectedChatIds }) => {
  const dispatch = useDispatch();
  const onOpen = (chatId) => {
    NavigationService.navigate(screens.ChatRoom, { chatId });
  };

  const onOpenChat = onSelectChat ?? onOpen;

  const isLoadingChats = useSelector((state) => state.chats.allChats.isLoading);
  const isLoadingMoreChats = useSelector((state) => state.chats.allChats.isLoadingMore);
  const [isRefreshing, setRefreshing] = useState(false);

  const getUnreadCountForAllChats = () => dispatch(chatsOperations.getUnreadCountForAllChats());

  const chats = useSelector((state) => chatsSelectors.getChats(state));

  const getChats = (isLoadMore) => dispatch(chatsOperations.getAllChats(isLoadMore));

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await getChats();

    getUnreadCountForAllChats();
    setRefreshing(false);
  };

  return (
    <ChatsList
      isEditMode={isEditMode}
      selectedChatIds={selectedChatIds}
      isLoading={isLoadingChats}
      isLoadingMore={isLoadingMoreChats}
      chatList={chats}
      onOpenChat={onOpenChat}
      onOpenUserProfile={onOpenUserProfile}
      onLoadMore={() => getChats(true)}
      isRefreshing={isRefreshing}
      onRefresh={onRefresh}
      ListEmptyComponent={() => (
        <View style={rs.bigPaddingTop}>
          <EmptyListWithImage
            image="empty_state_chats"
            text={strings.emptyLists.empty_buy_chat_list}
          />
        </View>
      )}
    />
  );
};

export default ChatsListContainer;
