import React, { useState } from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'react-native';
import T from 'prop-types';
import { Container, Text, View, Button, GenderSelector } from '../../../ReusableComponents';
import { userOperations } from '../../../../store/userInfo';
import { dimensions, rs } from '../../../../styles';
import { AnalyticsService, NavigationService } from '../../../../services';
import strings from '../../../../localization';
import { isIphoneX } from '../../../../utils/detectDevice';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { Image } from '../../../ReusableComponents';

const s = StyleSheet.create({
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium * 2 : dimensions.doubleMedium,
  },
  image: {
    width: 115,
    height: 115,
    borderRadius: 60,
    alignSelf: 'center',
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
  infoText: {
    lineHeight: 22,
  },
});

const AddGender = ({ userInfo, updateUserInfo }) => {
  const [gender, setGender] = useState(userInfo.gender);

  const onSubmit = async () => {
    updateUserInfo({
      userName: userInfo.name,
      userAvatar: userInfo.profile_image,
      gender: gender,
      email: userInfo.email,
    });
    AnalyticsService.logEvent(analyticsEventTypes.earn_points_add_gender);
    NavigationService.goBack();
  };

  return (
    <Container>
      <Container style={rs.alignCenter}>
        <Image style={s.image} source={{ uri: 'gender' }} resizeMode="cover" />

        <Text alignCenter large semiBold style={rs.paddingBottom}>
          {strings.user_info.gender_title}
        </Text>
        <Text alignCenter style={s.infoText}>
          {strings.user_info.gender_description}
        </Text>

        <View style={[rs.paddingHorizontal, rs.bigPaddingVertical, rs.backgroundWhite]}>
          <GenderSelector selectedGender={gender} onSelect={setGender} withoutTitle />
        </View>
      </Container>

      <View style={s.buttonContainer}>
        <Button title={strings.common.save} onPress={onSubmit} disabled={!gender} />
      </View>
    </Container>
  );
};

AddGender.propTypes = {
  userInfo: T.object.isRequired,
  updateUserInfo: T.func.isRequired,
};

export default connect(
  (state) => ({
    userInfo: state.userInfo,
  }),
  (dispatch) => ({
    updateUserInfo: (params) => dispatch(userOperations.updateUserInfo(params)),
  }),
)(AddGender);
