import React from 'react';
import { StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { dimensions, fontSizes } from '../../../../styles';
import strings from '../../../../localization';
import { isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';

const AdminAvatar = styled.Image`
  height: 68px;
  width: 68px;
  margin-top: -34px;
`;

const ContentContainer = styled.View`
  align-items: center;
`;

const MainContainer = styled.View`
  padding-vertical: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.doubleMedium};
  width: ${isWeb ? 'auto' : dimensions.width - 4 * dimensions.medium};
`;

const ButtonContainer = styled.View`
  width: 100%;
  height: 40px;
  margin-top: 40px;
`;

const Description = styled(Text)`
  font-size: ${fontSizes.large};
  text-align: center;
`;

const Reward = ({ onPress, params }) => {
  const currentTheme = getCurrentTheme();
  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />
      <MainContainer>
        <Text style={[styles.title, { color: currentTheme.colors.secondaryLight }]} bold>
          {params.title}
        </Text>

        <Description>{strings.modals.reward_text.replace('XX', params.amount)}</Description>

        <ButtonContainer>
          <Button title={strings.common.ok} onPress={onPress} />
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default Reward;

const styles = StyleSheet.create({
  title: {
    marginBottom: 30,
    textAlign: 'center',
    fontSize: 26,
  },
});
