import React from 'react';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { dimensions, fontSizes } from '../../../../styles';
import { userOperations } from '../../../../store/user';
import { isWeb } from '../../../../utils/detectDevice';

const ContentContainer = styled.View`
  align-items: center;
`;

const MainContainer = styled.View`
  padding-vertical: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.doubleMedium};
  width: ${isWeb ? 'auto' : dimensions.width - 4 * dimensions.medium};
`;

const ButtonContainer = styled.View`
  width: 100%;
  height: 40px;
  margin-top: 72px;
`;

const Title = styled(Text)`
  font-size: 28px;
  margin-bottom: 25px;
  text-align: center;
`;

const Description = styled(Text)`
  font-size: ${fontSizes.medium};
  text-align: center;
  margin-bottom: ${dimensions.halfIndent};
`;

const UnblockUser = ({ onPress, params, unblockUser }) => {
  const handleUnblock = async () => {
    await unblockUser(params.userId);
    onPress();
  };
  return (
    <ContentContainer>
      <MainContainer>
        <Title>User blocked</Title>
        <Description>You have to unblock user first to perform this operation</Description>

        <ButtonContainer>
          <Button title="Unblock" onPress={() => handleUnblock()} />
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default connect(null, (dispatch) => ({
  unblockUser: (userId) => {
    dispatch(userOperations.unblockUser(userId));
  },
}))(UnblockUser);
