import React from 'react';
import { connect } from 'react-redux';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Title,
  Description,
  ButtonContainer,
} from './styles';
import strings from '../../../../localization';
import { colors, rs } from '../../../../styles';
import { groupsOperations, groupsSelectors } from '../../../../store/groups';
import { getCurrentTheme } from '../../../../templates';

const UserLeftCommunity = ({ onPress }) => {
  const currentTheme = getCurrentTheme();
  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />
      <MainContainer>
        <Text
          large
          semiBold
          alignCenter
          color={currentTheme.colors.secondaryLight}
          style={rs.bigMarginBottom}
        >
          {strings.buy_sell.user_left_community}
        </Text>
        {/* <Description>{strings.settings.leave_community_text}</Description> */}

        <ButtonContainer>
          <Button withMarginRight title={strings.common.ok} onPress={onPress} />
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default UserLeftCommunity;
