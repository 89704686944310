import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { StyleSheet } from 'react-native';
import T from 'prop-types';
import { Container, TouchableItem, Text, View, Button, Image } from '../../../ReusableComponents';
import { userOperations } from '../../../../store/userInfo';
import { colors, dimensions, rs } from '../../../../styles';
import { AnalyticsService, NavigationService } from '../../../../services';
import strings from '../../../../localization';
import {isIphoneX, isWeb} from '../../../../utils/detectDevice';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import {isURL} from "../../../../utils/stringHelper";
import { getCurrentTheme } from '../../../../templates';

const s = StyleSheet.create({
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium * 2 : dimensions.doubleMedium,
  },
  image: {
    width: 115,
    height: 115,
    borderRadius: 60,
    alignSelf: 'center',
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
  infoText: {
    lineHeight: 22,
  },
});

const AddProfileImage = ({ userInfo, updateUserInfo, navigation }) => {
  const [userAvatarWeb, setUserAvatarWeb] = useState('');
  const [userAvatar, setUserAvatar] = useState(
    userInfo.is_default_profile_image ? '' : userInfo.profile_image,
  );
  const fileInputRef = useRef(null);

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    navigation.setParams({
      onSubmit: onSubmit,
      userAvatar,
    });
  }, [userAvatar]);

  const onOpenImagePicker = () => {
    fileInputRef.current?.click();
  };

  const setImageForWeb = (file) => {
    setUserAvatarWeb(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUserAvatar(reader.result);
    };
  };

  const onSubmit = async () => {
    updateUserInfo({
      userName: userInfo.name,
      userAvatar: userAvatarWeb ? userAvatarWeb : isURL(userAvatar) ? null : userAvatar,
      gender: userInfo.gender,
      email: userInfo.email,
    });
    AnalyticsService.logEvent(analyticsEventTypes.earn_points_add_avatar);
    NavigationService.goBack();
  };


  return (
    <Container>
      <Container>
        <TouchableItem onPress={onOpenImagePicker}>
          <Image style={s.image} source={isURL(userAvatar) || userAvatar === '' ? { uri: userAvatar || 'add_photo' } : userAvatar} resizeMode="cover" />
        </TouchableItem>

        {userAvatar ? (
          <TouchableItem onPress={onOpenImagePicker}>
            <Text medium alignCenter semiBold color={currentTheme.colors.secondary}>
              {strings.user_info.change_profile_photo}
            </Text>
          </TouchableItem>
        ) : (
          <Fragment>
            <Text alignCenter large semiBold style={rs.paddingBottom}>
              {strings.user_info.dont_be_stranger}
            </Text>
            <Text alignCenter style={s.infoText}>
              {strings.user_info.upload_photo_info}
            </Text>
          </Fragment>
        )}
      </Container>

      <View style={s.buttonContainer}>
        {userAvatar ? (
          <Button title={strings.common.save} onPress={onSubmit} />
        ) : (
          <Button title={strings.user_info.upload_photo} onPress={onOpenImagePicker} />
        )}
      </View>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={() => setImageForWeb(fileInputRef.current?.files[0])}
      />
    </Container>
  );
};

AddProfileImage.propTypes = {
  userInfo: T.object.isRequired,
  checkNicknameAvailability: T.func.isRequired,
  updateNickname: T.func.isRequired,
};

export default connect(
  (state) => ({
    userInfo: state.userInfo,
  }),
  (dispatch) => ({
    updateUserInfo: (params) => dispatch(userOperations.updateUserInfo(params)),
  }),
)(AddProfileImage);
