import styled from 'styled-components/native';
import { dimensions, colors } from '../../../../../styles';
import { isIphoneX } from '../../../../../utils/detectDevice';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import { StyleSheet } from 'react-native';

const s = StyleSheet.create({
  talentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.small * 2,
  },
  titleContainer: {
    flexDirection: 'row',
    paddingHorizontal: dimensions.medium,
    paddingBottom: dimensions.medium,
    paddingTop: dimensions.doubleMedium,
    alignItems: 'center',
    backgroundColor: colors.grayLighter,
    justifyContent: 'flex-start',
  },
  buttonContainer: {
    width: '100%',
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.medium,
    paddingBottom: isIphoneX ? 0 : dimensions.medium,
    marginTop: dimensions.medium * 2,
  },
});

export default s;

export const ContentContainer = styled.SafeAreaView`
  flex: 1;
  background-color: ${colors.white};
`;

export const Container = styled.SafeAreaView`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-left: ${dimensions.medium};
`;

export const SliderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: ${dimensions.medium};
`;

export const Distance = styled.View`
  flex: 1;
  justify-content: center;
  padding-left: ${dimensions.medium};
`;

export const EditPrimaryLocationContainer = styled.View`
  padding-left: ${dimensions.medium};
  padding-top: ${dimensions.halfMedium};
`;

export const ScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingBottom: dimensions.doubleMedium,
  },
})``;

export const DistanceDescrption = styled.View`
  margin-top: ${dimensions.medium};
  margin-bottom: ${dimensions.halfMedium};
  margin-left: ${dimensions.medium};
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  padding-horizontal: ${dimensions.medium};
  padding-bottom: ${dimensions.medium};
  padding-top: ${dimensions.doubleMedium};
  align-items: center;
  background-color: ${colors.grayLighter};
  justify-content: flex-start;
`;

export const DistanceTitleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: ${dimensions.medium};
  align-items: center;
`;

export const FollowingsTitleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: ${dimensions.medium};
  align-items: center;
`;

export const SortButton = styled(TouchableItem)`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: ${dimensions.medium};
  align-items: center;
`;

export const FavoritesTitleContainer = styled(FollowingsTitleContainer)`
  margin-top: ${dimensions.medium};
`;

export const DiscountItemsTitleContainer = styled(FollowingsTitleContainer)`
  margin-top: ${dimensions.medium};
`;

export const DistanceTextContainer = styled.View`
  width: 60px;
  align-items: flex-end;
`;

export const TitleText = styled(Text)`
  margin-horizontal: ${dimensions.medium};
`;

export const SelectedSizesText = styled(Text)`
  margin-horizontal: ${dimensions.medium};
  margin-top: ${dimensions.medium};
`;

export const SelectBrandButton = styled.TouchableOpacity``;

export const SelectedBrandsText = styled(Text)`
  margin-horizontal: ${dimensions.medium};
`;

export const BrandsContainer = styled.View`
  margin-vertical: ${dimensions.medium};
`;

export const ButtonContainer = styled.View`
  width: 100%;
  padding-horizontal: ${dimensions.medium};
  padding-top: ${dimensions.medium};
  padding-bottom: ${isIphoneX ? 0 : dimensions.medium};
  border-top-width: ${dimensions.hairline};
  border-top-color: ${colors.grayLight};
`;

export const ViewStyleContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: ${dimensions.medium};
  align-items: center;
`;

export const ViewIconContainer = styled.View`
  flex-direction: row;
`;
