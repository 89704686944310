import { createSelector } from 'reselect';
import * as R from 'ramda';
import { CONFIRM_CANCEL_PURCHASE, OFFER_CONFIRMED } from './offerStatuses';

const sortOffers = offers => {
  const sortByUpdatedDate = (a, b) => {
    const first = R.propOr(R.propOr(null, 'created_at', a), 'updated_at', a);
    const second = R.propOr(R.propOr(null, 'created_at', b), 'updated_at', b);

    return new Date(second).getTime() - new Date(first).getTime();
  };

  return R.sort(sortByUpdatedDate, offers);
};

const getOffers = createSelector(
  [R.pathOr([], ['offers', 'offerIds']), R.pathOr({}, ['offers', 'offerEntities'])],
  (ids, entities) => sortOffers(ids.map(id => entities[id])),
);

const getBuyingOfferByItemId = createSelector(
  [R.pathOr({}, ['offers', 'offerEntities']), (_, itemId) => itemId],
  (offerEntities, itemId) => {
    let result = {};

    R.forEachObjIndexed(val => {
      if (val.item_id === itemId) {
        result = val;
      }
    }, offerEntities);

    return result;
  },
);

export const getOfferById = createSelector(
  [R.pathOr({}, ['offers', 'offerEntities']), (_, offerId) => offerId],
  (entities, id) => R.propOr({}, id, entities),
);

const getUserId = state => R.pathOr(null, ['userInfo', 'id'], state);

const getInProgressOffersCounts = createSelector(
  [getOffers, getUserId],

  (offers, userId) => {
    const inProgressOffersCount = {
      sellingOffers: 0,
      buyingOffers: 0,
    };

    offers.forEach(offer => {
      const offerStatusId = parseInt(offer.status_id);
      const buyerId = parseInt(offer.buyer_id);
      const sellerId = parseInt(offer.seller_id);
      const isInProgress =
        offerStatusId === CONFIRM_CANCEL_PURCHASE || offerStatusId === OFFER_CONFIRMED;

      if (isInProgress) {
        if (buyerId === userId) {
          inProgressOffersCount.buyingOffers += 1;
        } else if (sellerId === userId) {
          inProgressOffersCount.sellingOffers += 1;
        }
      }
    });

    return inProgressOffersCount;
  },
);

const getSellingAskedToBuyOffers = createSelector(
  [
    R.path(['offers', 'sellingOffers', 'askedToBuy', 'offerIds']),
    R.path(['offers', 'offerEntities']),
  ],

  (ids, entities) => ids.map(id => entities[id]),
);

const getSellingPendingDeliveryOffers = createSelector(
  [
    R.path(['offers', 'sellingOffers', 'pendingDelivery', 'offerIds']),
    R.path(['offers', 'offerEntities']),
  ],

  (ids, entities) => ids.map(id => entities[id]),
);

const getArchivedOffers = createSelector(
  [R.path(['offers', 'sellingOffers', 'archived', 'offerIds']), R.path(['offers', 'offerEntities'])],

  (ids, entities) => ids.map((id) => entities[id]),
);

const getSellingSearchedOffers = createSelector(
  [
    R.path(['offers', 'sellingOffers', 'searched', 'offerIds']),
    R.path(['offers', 'offerEntities']),
  ],

  (ids, entities) => ids.map(id => entities[id]),
);

const getBuyingAskedToBuyOffers = createSelector(
  [
    R.path(['offers', 'buyingOffers', 'askedToBuy', 'offerIds']),
    R.path(['offers', 'offerEntities']),
  ],

  (ids, entities) => ids.map(id => entities[id]),
);

const getBuyingPendingDeliveryOffers = createSelector(
  [
    R.path(['offers', 'buyingOffers', 'pendingDelivery', 'offerIds']),
    R.path(['offers', 'offerEntities']),
  ],

  (ids, entities) => ids.map(id => entities[id]),
);

const getBuyingSearchedOffers = createSelector(
  [R.path(['offers', 'buyingOffers', 'searched', 'offerIds']), R.path(['offers', 'offerEntities'])],
  (ids, entities) => ids.map(id => entities[id]),
);

const getWishFulfillmentOffers = createSelector(
  [R.path(['offers', 'wishFulfillmentOffers', 'offerIds']), R.path(['offers', 'offerEntities'])],

  (ids, entities) => ids.map(id => entities[id]),
);

export default {
  getOffers,
  getBuyingOfferByItemId,
  getOfferById,
  getInProgressOffersCounts,

  getBuyingAskedToBuyOffers,
  getBuyingPendingDeliveryOffers,
  getBuyingSearchedOffers,

  getSellingAskedToBuyOffers,
  getSellingPendingDeliveryOffers,
  getSellingSearchedOffers,

  getWishFulfillmentOffers,
  getArchivedOffers,
};
