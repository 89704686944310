import api from './api';
import { GOOGLE_AUTOCOMPLETE_API_KEY } from '../../config/google';

export default {
  getUserLocations: (user_id) => api.get(`userLocations/${user_id}`),
  removeLocation: (account_id, location_id) =>
    api.put(`userLocations/removeLocation`, { account_id, location_id }),
  activateLocation: (account_id, location_id) =>
    api.put('userLocations/activateLocation', { account_id, location_id }),
  createLocation: ({ userId, location }) =>
    api.post(`userLocations/createLocation`, {
      account_id: userId,
      location,
    }),
  editLocation: (location) => api.put('userLocations/editLocation', { location }),
  setPrimaryLocation: (account_id, location_id) =>
    api.post(`userLocations/setUserPrimaryLocation`, { account_id, location_id }),
  getUsersForMap: ({ community_id, bounds, limit }) =>
    api.post('getUsersForMap', {
      community_id,
      bounds,
      limit,
    }),
  getLocationsByLatLng: ({ longitude, latitude }) =>
    api.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_AUTOCOMPLETE_API_KEY}`,
    ),
};
