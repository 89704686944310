import React from 'react';
import { connect, useSelector } from 'react-redux';
import { ScrollView, StatusBar, StyleSheet } from 'react-native';
import T from 'prop-types';
import { getIsUserDefaultProfileImage } from '../../../store/userInfo/selectors';
import { ActionBanner, StatusBarPadding } from '../../ReusableComponents';
import { colors, fontSizes, rs } from '../../../styles';
import CurrentUserInfo from './components/СurrentUserInfo';
import CurrentUserOptions from './components/CurrentUserOptions';
import guideTypes from '../../../constants/guidelTypes';
import { guideOperations } from '../../../store/guides';
import strings from '../../../localization';
import { getAppLanguage } from '../../../store/app/selectors';

const s = StyleSheet.create({
  rightTex: {
    fontSize: fontSizes.small,
    color: colors.blue,
  },
});

const Menu = ({
  user,
  isShowInviteFriendsBanner,
  neverShowGuideAgain,
  wallet,
  isUserDefaultProfileImage,
  communityInfo,
}) => {
  const onNeverShowGuideAgain = () => {
    neverShowGuideAgain({ guideType: guideTypes.MENU_INVITE_FRIENDS });
  };

  const language = useSelector(getAppLanguage);

  return (
    <ScrollView bounces={false} style={rs.backgroundWhite} contentContainerStyle={rs.tabBarPadding}>
      <CurrentUserInfo
        profileImage={user.profile_image}
        name={user.name}
        balance={Number(wallet.balance_amount) - Number(wallet.hold_balance)}
        isUserDefaultProfileImage={isUserDefaultProfileImage}
      />

      {/*<PaymentPlanBanner />*/}

      {isShowInviteFriendsBanner && (
        <ActionBanner
          containerStyle={[rs.marginBottom, rs.bigMarginTop]}
          onClose={onNeverShowGuideAgain}
          pointsName={
            (communityInfo?.token_name && communityInfo.token_name[language]) ||
            strings.home.token_banner_blue_text
          }
        />
      )}

      <CurrentUserOptions />

      <StatusBarPadding />
    </ScrollView>
  );
};

Menu.propTypes = {
  user: T.object.isRequired,
  followersTotalCount: T.number.isRequired,
  followingsTotalCount: T.number.isRequired,
  isUserDefaultProfileImage: T.bool,
};

export default connect(
  (state) => ({
    user: state.userInfo,
    isUserDefaultProfileImage: getIsUserDefaultProfileImage(state),
    followersTotalCount: state.followers.followersCount,
    followingsTotalCount: state.followers.followingsCount,
    wallet: state.wallets.wallet,
    isShowInviteFriendsBanner: state.guides.showAgain[guideTypes.MENU_INVITE_FRIENDS],
    communityInfo: state.communityInfo,
  }),
  (dispatch) => ({
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
  }),
)(Menu);
