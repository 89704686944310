import React, { Fragment } from 'react';
import { StyleSheet } from 'react-native';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import { colors, dimensions, fontSizes } from '../../../styles';
import { getCurrentTheme } from '../../../templates';

const Button = ({ small, disabled, title, onPress, withMarginRight, secondaryType = false }) => {
  const currentTheme = getCurrentTheme();

  const buttonContainerStyle = [
    styles.buttonContainer,
    { height: small ? 30 : 40 },
    small ? { flex: 1 } : { width: '100%' },
    withMarginRight && { marginRight: dimensions.halfXLarge },
    secondaryType &&
      (styles.secondaryButtonContainer, { borderColor: currentTheme.colors.secondary }),
    { backgroundColor: getButtonBackgroundColor(disabled, secondaryType, currentTheme) },
  ];

  const buttonTextStyle = [
    styles.buttonText,
    { color: getButtonTextColor(disabled, secondaryType, currentTheme) },
    small && { fontSize: fontSizes.small, fontFamily: 'Assistant-SemiBold' },
    small && { color: secondaryType ? currentTheme.colors.secondary : colors.white },
  ];

  const handlePress = () => {
    if (!disabled) {
      onPress();
    }
  };

  return (
    <Fragment>
      {secondaryType ? (
        <TouchableItem style={buttonContainerStyle} disabled={disabled} onPress={handlePress}>
          <Text style={buttonTextStyle} disabled={disabled}>
            {title}
          </Text>
        </TouchableItem>
      ) : (
        <TouchableItem style={buttonContainerStyle} disabled={disabled} onPress={handlePress}>
          <Text style={buttonTextStyle} disabled={disabled}>
            {title}
          </Text>
        </TouchableItem>
      )}
    </Fragment>
  );
};

const getButtonBackgroundColor = (disabled, secondaryType, currentTheme) => {
  return disabled
    ? colors.grayLighter
    : secondaryType
    ? colors.white
    : currentTheme.colors.secondary;
};

const getButtonTextColor = (disabled, secondaryType, currentTheme) => {
  return disabled ? colors.gray : secondaryType ? currentTheme.colors.secondary : colors.white;
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  buttonText: {
    fontSize: fontSizes.medium,
  },
  secondaryButtonContainer: {
    backgroundColor: colors.white,
    borderWidth: 1,
  },
});

export default Button;
