import React, { Fragment } from 'react';
import * as R from 'ramda';
import { LayoutAnimation, StyleSheet, View } from 'react-native';
import styled from 'styled-components/native';
import { connect } from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { numberWithCommas } from '../../../../utils/stringHelper';
import TouchableItem from '../../TouchableItem/index.ios';
import Text from '../../Text';
import AppImage from '../../AppImage';
import FavoriteIcon from '../../FavoriteIcon';
import { colors, dimensions, rs } from '../../../../styles';
import { isRTL } from '../../../../utils/rtlHelper';
import itemTypes from '../../../../constants/itemTypes';
import Image from '../../Image';
import PromotionTag from '../../PromotionTag';
import { isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';
import { SHARINGOOD_COMMUNITY } from '../../../../constants/communities';

const itemSize = (dimensions.width - dimensions.medium * 3) / 2;

const s = StyleSheet.create({
  contentContainer: {
    borderColor: colors.grayLight,
    borderWidth: dimensions.hairline,
    marginBottom: dimensions.medium,
    marginHorizontal: dimensions.halfMedium,
    paddingBottom: dimensions.halfMedium,
    borderRadius: 5,
    maxWidth: 300,
    maxHeight: 300,
  },
  image: {
    width: itemSize,
    height: itemSize,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    maxWidth: 300,
    maxHeight: 300,
  },
  infoContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
  },
  infoTextContainer: {
    maxWidth: itemSize - dimensions.doubleMedium,
    marginTop: 2,
  },
  sizeContainer: {
    paddingHorizontal: 6,
    borderWidth: dimensions.hairline,
    borderColor: colors.gray,
    borderRadius: 5,
    marginHorizontal: dimensions.medium,
  },
  oldPriceText: {
    alignSelf: 'flex-end',
    paddingHorizontal: 10,
    textDecorationLine: 'line-through',
  },
  emptyItem: {
    width: itemSize,
    height: itemSize,
    marginBottom: dimensions.medium,
    marginHorizontal: dimensions.halfMedium,
    paddingBottom: dimensions.halfMedium,
    maxWidth: 300,
    maxHeight: 300,
  },
  priceImage: {
    height: 14,
    width: 14,
    marginRight: 4,
  },
});

const DiscountContainer = styled.View`
  position: absolute;
  border-top-right-radius: 10px;
  overflow: hidden;
  bottom: 0;
  left: 0;
  height: 18%;
  min-width: 30%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DiscountOpacityView = styled(LinearGradient)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

const ItemCard = styled.View`
  height: ${(props) => (props.imageSize && isWeb ? props.imageSize : itemSize)};
  width: ${(props) => (props.imageSize && isWeb ? props.imageSize : itemSize)};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  overflow: hidden;
  margin-bottom: 2px;
  position: relative;
  max-width: 300px;
  max-height: 300px;
`;

class LotListItem extends React.PureComponent {
  // componentWillMount() {
  //   LayoutAnimation.easeInEaseOut();
  // }
  //
  // componentWillUnmount() {
  //   LayoutAnimation.easeInEaseOut();
  // }

  render() {
    const {
      lot,
      currentUserId,
      onPress,
      onToggleFavorites,
      itemType,
      isSoldItem,
      withoutOpacity = true,
      hasActivePromotion = false,
      imageSize,
    } = this.props;

    if (R.isEmpty(lot)) {
      return <View style={s.emptyItem} />;
    }

    const isWish = lot.is_wish;
    const imageUrl = isWish ? lot.wish_image : lot.item_image;
    const isActiveDiscount = !!lot.discount_rate && !!lot.discounted_price;

    const isActive = itemType !== itemTypes.SOLD && itemType !== itemTypes.PURCHASED;
    const isCurrentUserItem = Number(lot.seller_id) === currentUserId;
    const isPromotionTagVisible = hasActivePromotion && !isCurrentUserItem && !isWish;

    const currentTheme = getCurrentTheme();

    const { communityName } = this.props;

    return (
      <TouchableItem
        style={[s.contentContainer, { maxHeight: imageSize + 72 }]}
        withoutOpacity={withoutOpacity}
        onPress={() => onPress(lot)}
      >
        <ItemCard imageSize={imageSize}>
          {isPromotionTagVisible && <PromotionTag />}
          <AppImage
            style={[s.image, isWeb && { width: imageSize, height: imageSize }]}
            source={{ uri: imageUrl }}
            resizeMode="cover"
          />

          {isActiveDiscount && (
            <DiscountContainer>
              <DiscountOpacityView
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                colors={[currentTheme.colors.primary, currentTheme.colors.secondaryLight]}
              />
              <View style={rs.paddingHorizontal}>
                <Text semiBold medium color={colors.white}>
                  -{lot.discount_rate}%
                </Text>
              </View>
            </DiscountContainer>
          )}
        </ItemCard>

        <View style={s.infoContainer}>
          {!isWish && (
            <View style={[rs.row, rs.alignCenter]}>
              <View style={[rs.row, rs.alignCenter, rs.flex]}>
                {communityName !== SHARINGOOD_COMMUNITY && (
                  <Image style={s.priceImage} source={{ uri: 'price' }} resizeMode="contain" />
                )}
                <Text color={colors.secondary} bold medium>
                  {communityName === SHARINGOOD_COMMUNITY && '🧡'}{' '}
                  {numberWithCommas(lot.discounted_price || lot.price)}
                </Text>
                {lot.discounted_price && (
                  <Text color={colors.gray} style={s.oldPriceText}>
                    {numberWithCommas(lot.price)}
                  </Text>
                )}
                {!!lot.size_title_en && (
                  <View style={s.sizeContainer}>
                    <Text xsmall semiBold color={colors.gray}>
                      {isRTL ? lot.size_title_he : lot.size_title_en}
                    </Text>
                  </View>
                )}
              </View>
              {!isSoldItem && !isWish && !isCurrentUserItem && isActive && (
                <TouchableItem onPress={() => onToggleFavorites(lot)}>
                  <FavoriteIcon isFavorite={lot.mark_as_favorite} />
                </TouchableItem>
              )}
            </View>
          )}
          <View style={[s.infoTextContainer, isWeb && { maxWidth: imageSize - 50 }]}>
            <Text medium semiBold color={colors.text} numberOfLines={1}>
              {lot.title}
            </Text>
          </View>
        </View>
      </TouchableItem>
    );
  }
}

export default connect((state) => ({
  communityName: state.communityTheme.theme,
}))(LotListItem);
