import * as React from 'react';
import WebView from 'react-native-webview';
import { useSelector } from 'react-redux';
import strings from '../../../../localization';
import { getCommunityId } from '../../../../store/communityInfo/selectors';
import {
  PRICE_CALCULATOR_URL_BR,
  PRICE_CALCULATOR_URL_HE,
  PRICE_CALCULATOR_URL_DE,
} from '../../../../api/urls';
import {
  ISRAEL_COMMUNITY,
  BRAZIL_COMMUNITY,
  DEUTSCH_COMMUNITY,
} from '../../../../constants/communities';

export const getPriceCalculatorUrlByCommunityId = (communityId) => {
  switch (parseInt(communityId, 10)) {
    case BRAZIL_COMMUNITY:
      return PRICE_CALCULATOR_URL_BR;
    case DEUTSCH_COMMUNITY:
      return PRICE_CALCULATOR_URL_DE;

    default:
      return PRICE_CALCULATOR_URL_HE;
  }
};

const DomesticShippingCalculation = ({ navigation }) => {
  const communityId = useSelector(getCommunityId);

  React.useLayoutEffect(() => {
    navigation.setParams({
      title:
        communityId === ISRAEL_COMMUNITY
          ? strings.shipping.calculate_shipping_cost
          : strings.shipping.calculate_shipping_cost_netherlands,
    });
  }, [communityId]);

  const uri = getPriceCalculatorUrlByCommunityId(communityId);

  return <WebView source={{ uri }} />;
};

export default DomesticShippingCalculation;
