import React from 'react';
import { connect } from 'react-redux';
import { ImageBackground, Animated, StyleSheet, ScrollView } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import * as R from 'ramda';
import { View, Text, BackButton, FastImage } from '../../ReusableComponents';
import strings from '../../../localization';
import { colors, dimensions, rs } from '../../../styles';
import { chatsOperations } from '../../../store/chats';
import { transactionHeaderDate } from '../../../utils/dateHelper';

const s = StyleSheet.create({
  linearGradient: {
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingTop: dimensions.doubleMedium,
    paddingHorizontal: dimensions.doubleMedium,
  },
  image: {
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
    width: 360,
    height: 360,
  },
  confettiImageBackground: {
    height: '100%',
    width: '100%',
    opacity: 1,
  },
  imageShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.15,
    shadowRadius: 20,
    elevation: 2,
  },
});

const AchievedRewardDetails = ({ openPersonalChat, route }) => {
  const {
    subtitle,
    description,
    icon_url,
    achieved_icon_url,
    given_reward_amount,
    is_reached,
    reached_at,
    section,
  } = R.path(['params', 'reward'], route);

  const achievedText = strings.notifications.earned.replace('XX', given_reward_amount);

  const gradient = {
    common: ['#A44AFE', '#333951'],
    upload: ['#189BC9', '#0AC1B6'],
    buy: ['#59B88C', '#39BDDC'],
    wish_fulfillment: ['#F28C3B', '#F2C83B'],
    sell: ['#079189', '#0AC1B6'],
    referral: ['#E97881', '#F28C3B'],
  }[section] || ['#189BC9', '#0AC1B6'];

  return (
    <LinearGradient colors={gradient} style={rs.flex}>
      <ScrollView>
        <ImageBackground
          style={s.confettiImageBackground}
          resizeMode="cover"
          source={require('../../../assets/web/images/confetti_background.png')}
        >
          <BackButton isClose isWhite />
          <View style={s.contentContainer}>
            <Text title bold alignCenter color={colors.white}>
              {subtitle?.toUpperCase()}
            </Text>

            <View style={s.imageShadow}>
              <FastImage
                style={[s.image]}
                resizeMode={'contain'}
                priority={FastImage.priority.high}
                source={{ uri: is_reached ? achieved_icon_url : icon_url }}
              />
            </View>

            <Text bold large alignCenter color={colors.white}>
              {description}
            </Text>

            {!!given_reward_amount && (
              <Text medium color={colors.white} style={[rs.bigPaddingTop, rs.veryBigMarginBottom]}>
                {achievedText}
              </Text>
            )}

            {!!reached_at && (
              <Text xsmall color={colors.white} style={[rs.bigMarginTop]}>
                {strings.rewards.earned_on} {transactionHeaderDate(reached_at)}
              </Text>
            )}
          </View>
        </ImageBackground>
      </ScrollView>
    </LinearGradient>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    openPersonalChat: (user) => dispatch(chatsOperations.openPersonalChat(user)),
  }),
)(AchievedRewardDetails);
