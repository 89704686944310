import { createSelector } from 'reselect';
import { pathOr, isEmpty } from 'ramda';
import { numberWithCommas } from '../../utils/stringHelper';

export const getWallet = (state) => {
  return pathOr({}, ['wallets', 'wallet'], state);
};

export const getWalletBalance = createSelector([getWallet], (wallet) => {
  if (isEmpty(wallet)) {
    return 0;
  }

  return numberWithCommas(
    parseInt(wallet?.balance_amount ?? 0, 10) - parseInt(wallet?.hold_balance ?? 0, 10),
  );
});

export default {
  getWallet,
  getWalletBalance,
};
