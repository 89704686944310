import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { View, Alert, Keyboard, Switch } from 'react-native';
import { connect, useDispatch } from 'react-redux';

import R from 'ramda';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useFeatureFlag } from 'configcat-react';
import {
  DescriptionInput,
  ImagesSelector,
  PriceInput,
  TitleInput,
  WishInfo,
  ShippingInfo,
} from './components';
import Title from './components/Title';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';
import { Button, ItemDetailsButton, Separator, Text } from '../../../ReusableComponents';
import s, { PostButtonContainer } from './styles';
import MultipleSupplyToggle from './components/MultipleSupplyToggle';
import MultipleGroupItemToggle from './components/MultipleGroupItemToggle';
import appLanguages from '../../../../constants/appLanguages';
import { groupsSelectors } from '../../../../store/groups';
import withSelectedGroupId from './SelectedCommunityContainer';
import { LoadingService, NavigationService, ToastsService } from '../../../../services';
import screens from '../../../../navigation/screens';
import api from '../../../../api/api';
import { getImageType } from '../../../../utils/stringHelper';
import { lotsOperations } from '../../../../store/lots';
import { useFocusEffect } from '@react-navigation/native';
import { getCurrentTheme } from '../../../../templates';
import { shippingOperations } from '../../../../store/shipping';
import { subCommunityOperations, subCommunitySelectors } from '../../../../store/subCommunities';
import { formatCommunityName } from '../../../../utils/formatCommunityName';
import { SUB_COMMUNITIES_KEY } from '../../../../constants/configcat';
import itemTypes from '../../../../constants/itemTypes';

const EditTalentScreen = ({
  groupInfo,
  user,
  refundPolicies,
  groups,
  savedLocations,
  createNewSkill,
  editSkill,
  selectedGroupId,
  setSelectedGroupId,
  saveShippingForNextLot,
  canTagSubCommunityToItems,
  getUserSubCommunities,
  communityInfo,
  navigation,
  route,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [participantsCount, setParticipantsCount] = useState('');
  const [price, setPrice] = useState('');
  const [images, setImages] = useState(['', '', '', '']);
  const [itemId, setItemId] = useState('');
  const [selectedCategory, setSelectedCategory] = useState({
    parentCategory: null,
    childCategory: null,
  });
  const [hasMultipleSupply, setHasMultipleSupply] = useState(false);
  const [sizeId, setSizeId] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [refundPolicyId, setRefundPolicyId] = useState(null);
  const [itemsLocations, setItemsLocations] = useState([]);
  const [isOnlineOrRemote, setIsOnlineOrRemote] = useState(false);
  const [isActiveDiscount, setIsActiveDiscount] = useState(false);
  const [selectedSubCommunities, setSelectedSubCommunities] = useState([]);

  const dispatch = useDispatch();
  const currentTheme = getCurrentTheme();

  const skill = route.params?.skill ?? null;
  const wish = route.params?.wish ?? null;

  const IS_SHOW_SUB_COMMUNITIES_KEY = formatCommunityName(SUB_COMMUNITIES_KEY, communityInfo.name);

  const { value: isShowSubCommunitiesValue } = useFeatureFlag(IS_SHOW_SUB_COMMUNITIES_KEY, false);

  useFocusEffect(
    useCallback(() => {
      navigation.setParams({ onSubmit });
    }, [navigation, title, price, description, images, selectedCategory]),
  );

  useEffect(() => {
    if (isShowSubCommunitiesValue) {
      getUserSubCommunities();
    }
  }, [isShowSubCommunitiesValue]);

  useEffect(() => {
    setItemsLocations(savedLocations);

    if (skill) {
      setTitle(skill.title);
      setDescription(skill.description);
      setPrice(skill.price.toString());
      setParticipantsCount(skill.max_submissions_count.toString());
      setRefundPolicyId(R.propOr(null, 'refund_policy_id', skill));

      if (Array.isArray(skill.item_image)) {
        skill.item_image.forEach((el, id) => {
          images[id] = el;
        });
        images.length = 4;
        setImages(images);
      } else {
        setImages([skill.item_image, '', '', '']);
      }
      setItemId(skill.id);
      setSelectedCategory({
        parentCategory: {
          id: R.propOr(null, 'parent_category_id', skill),
          name: R.propOr(null, 'parent_category_name', skill),
        },
        childCategory: {
          id: R.propOr(null, 'category_id', skill),
          name: R.propOr(null, 'category_name', skill),
        },
      });

      setHasMultipleSupply(skill.has_multiple_supply);
      setSizeId(skill.size_id);
      setSelectedBrand(skill.brand);
    }
  }, []);

  useEffect(() => {
    navigation.setParams({
      isValidate:
        title.length > 0 &&
        price > 0 &&
        description.length > 0 &&
        images.some((el) => !!el) &&
        !!R.path(['parentCategory', 'id'], selectedCategory),
    });
  }, [title, price, description, images, selectedCategory]);

  const onInputChangesHandler = (value, stateName) => {
    const filteredValue = stateName === 'price' ? value.replace(/\D/g, '') : value;

    const newValue = appLanguages === 'en' ? filterBadWords(filteredValue) : filteredValue;

    if (stateName === 'title') {
      setTitle(newValue);
    }

    if (stateName === 'description') {
      setDescription(newValue);
    }

    if (stateName === 'participantsCount') {
      setParticipantsCount(newValue);
    }

    if (stateName === 'price') {
      setPrice(newValue);
    }
  };

  const selectedSizeTitle = sizeId
    ? R.propOr('', 'title', R.find(R.propEq('id', sizeId))(groupInfo.sizes))
    : '';

  const selectedRefundPolicyTitle = refundPolicyId
    ? R.propOr('', 'title', R.find(R.propEq('id', refundPolicyId))(refundPolicies))
    : '';

  const selectedSubCommunitiesValue = R.isEmpty(selectedSubCommunities)
    ? ''
    : selectedSubCommunities.length === 1
    ? selectedSubCommunities[0].name
    : strings.formatString(
        strings.lots.sub_community_multiple_tags,
        selectedSubCommunities[0].name,
        selectedSubCommunities.length - 1,
      );

  const onFocusPriceInput = () => {
    if (isActiveDiscount) {
      Alert.alert(
        strings.discounts.alert_edit_price_title,
        strings.discounts.alert_edit_price_text,
        [{ text: strings.common.ok, onPress: () => {} }],
      );

      setIsActiveDiscount(false);
    }
  };

  const onOpenSizesScreen = () => {
    NavigationService.navigate(screens.Sizes, {
      selectedSizeId: sizeId,
      sizes: groupInfo.sizes,
      onSelectSize: (newSizeId) => setSizeId(newSizeId),
    });
  };

  const onOpenBrandsScreen = () => {
    NavigationService.navigate(screens.Brands, {
      onSelectBrand: (newBrand) => setSelectedBrand(newBrand),
      brands: groupInfo.brands,
      selectedBrand,
    });
  };

  const onOpenCategories = () => {
    NavigationService.navigate(screens.Categories, {
      selectedCategoryId: R.path(['childCategory', 'id'], selectedCategory),
      categories: groupInfo.categories,
      onSelectCategory: (newCategory) => setSelectedCategory(newCategory),
    });
  };

  const onOpenGroups = () => {
    NavigationService.navigate(screens.Groups, {
      selectedGroupId: groupInfo.id,
      groups: groups,
      onSelectGroup: (newGroup) => {
        setSelectedGroupId(newGroup);
        NavigationService.pop();
      },
    });
  };

  const onOpenRefunds = () => {
    NavigationService.navigate(screens.RefundPolicies, {
      selectedRefundPolicyId: refundPolicyId,
      refundPolicies,
      onSelectRefundPolicy: (newRefundPolicyId) => setRefundPolicyId(newRefundPolicyId),
    });
  };

  const onNavigateToShipping = () => {
    NavigationService.navigate(screens.ItemShipping, {
      communityId: selectedGroupId,
      itemLocations: itemsLocations,
      isTalent: true,
      onSetShipping: (newItemLocations) => {
        setItemsLocations(newItemLocations.locations);
      },
    });
  };

  const onSelectSubCommunities = (subCommunities) => {
    setSelectedSubCommunities(subCommunities);
    NavigationService.goBack();
  };

  onOpenSubCommunityTags = () => {
    NavigationService.navigate(screens.MultipleSubCommunitySelect, {
      selectedSubCommunities,
      onSubmit: (subCommunities) => onSelectSubCommunities(subCommunities),
    });
  };

  const onSubmit = async () => {
    if (route.params?.isValidate ?? false) {
      navigation.setParams({ isValidate: false });
    } else {
      return;
    }

    Keyboard.dismiss();

    saveShippingForNextLot(itemsLocations, null, false);

    const submitItem = itemId ? editSkill : createNewSkill;

    LoadingService.showSuccessLoader();

    const response = await submitItem({
      title,
      description,
      price,
      images,
      brand_id: R.propOr(null, ['id'], selectedBrand),
      category_id: R.pathOr(null, ['childCategory', 'id'], selectedCategory),
      group_id: selectedGroupId,
      has_multiple_supply: hasMultipleSupply,
      refundPolicyId,
      id: skill?.id,
      wishId: R.propOr(null, 'id', wish), // if create new talent from wish
      locations: itemsLocations.map((location) =>
        location.id && !location.isItemLocation ? { user_location_id: location.id } : location,
      ),
      sub_community_ids: selectedSubCommunities.map(({ id }) => id),
      ...(hasMultipleSupply && { max_submissions_count: participantsCount }),
    });

    LoadingService.hideSuccessLoader({
      callback: async () => {
        if (!response) {
          return;
        }

        await dispatch(lotsOperations.getItems(true));
        NavigationService.navigate(screens.MarketplaceTab, { screen: screens.Marketplace });
        ToastsService.showSuccess(
          response.status === itemTypes.PENDING
            ? strings.success_messages.item_uploaded_successfully_and_waiting_for_review
            : strings.success_messages.item_uploaded_successfully,
        );
      },
    });
  };

  const isEditable = !skill;

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={dimensions.medium}
      enableResetScrollToCoords={false}
      keyboardShouldPersistTaps="handled"
      style={{ backgroundColor: colors.grayLighter }}
    >
      <View>
        {isShowSubCommunitiesValue && canTagSubCommunityToItems && !itemId && (
          <Fragment>
            <Title withPadding>{strings.lots.sub_community_tags_items}</Title>
            <ItemDetailsButton
              placeholder={strings.lots.sub_community_tags_placeholder_items}
              selectedValue={selectedSubCommunitiesValue}
              onPress={this.onOpenSubCommunityTags}
            />
          </Fragment>
        )}

        <WishInfo wish={wish} />

        <ImagesSelector images={images} setImage={setImages} isItem />

        <Title withPadding>{strings.lots.talent_description}</Title>

        <TitleInput
          value={title}
          placeholder={strings.lots.what_are_you_selling_talent}
          onChangeText={(name) => onInputChangesHandler(name, 'title')}
          editable={isEditable}
        />

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <DescriptionInput
          value={description}
          placeholder={strings.lots.describe_your_talent}
          onChangeText={(name) => onInputChangesHandler(name, 'description')}
          editable={isEditable}
        />

        <Title withPadding>{strings.lots.details}</Title>

        {!itemId && !wish && (
          <Fragment>
            <ItemDetailsButton
              title={strings.lots.group}
              placeholder={strings.filters.select_group}
              selectedValue={groupInfo.group_name}
              onPress={onOpenGroups}
            />
            <View style={rs.paddingHorizontal}>
              <Separator />
            </View>
          </Fragment>
        )}

        <ItemDetailsButton
          title={strings.lots.category}
          placeholder={strings.filters.select_category}
          selectedValue={
            selectedCategory.parentCategory
              ? `${selectedCategory.parentCategory.name} : ${selectedCategory.childCategory.name}`
              : ''
          }
          onPress={onOpenCategories}
          disabled={!isEditable}
        />

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        {groupInfo.has_brands && (
          <Fragment>
            <View style={rs.paddingHorizontal}>
              <Separator />
            </View>

            <ItemDetailsButton
              title={strings.lots.brand}
              placeholder={strings.lots.select_brand}
              selectedValue={R.prop('name', selectedBrand)}
              onPress={onOpenBrandsScreen}
            />
          </Fragment>
        )}

        {groupInfo.has_sizes && (
          <Fragment>
            <View style={rs.paddingHorizontal}>
              <Separator />
            </View>

            <ItemDetailsButton
              title={strings.lots.size}
              placeholder={strings.lots.select_size}
              selectedValue={selectedSizeTitle}
              onPress={onOpenSizesScreen}
            />
          </Fragment>
        )}

        {groupInfo.has_refund_policy && (
          <Fragment>
            <Title withPadding>{strings.lots.refund_policy}</Title>

            <ItemDetailsButton
              title={selectedRefundPolicyTitle ? '' : strings.lots.select_refund_policy}
              placeholder={''}
              selectedValue={selectedRefundPolicyTitle}
              onPress={onOpenRefunds}
            />
          </Fragment>
        )}

        <Fragment>
          <Title withPadding>{strings.lots.talent_address}</Title>

          <View style={s.containerTalentAddress}>
            <Text>{strings.lots.online_or_remote_talent}</Text>
            <Switch
              value={isOnlineOrRemote}
              onValueChange={setIsOnlineOrRemote}
              trackColor={{ false: colors.grayLight, true: currentTheme.colors.secondary }}
              thumbColor={colors.white}
              ios_backgroundColor={colors.grayLight}
            />
          </View>

          {!isOnlineOrRemote && (
            <>
              <ItemDetailsButton
                title={strings.lots.select_talent_address_option}
                placeholder={''}
                onPress={onNavigateToShipping}
              />

              <ShippingInfo locations={itemsLocations} customTitle={strings.lots.talent_address} />
            </>
          )}
        </Fragment>

        <Title withPadding>{strings.lots.item_reward}</Title>

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        {!wish && (
          <Fragment>
            <MultipleGroupItemToggle
              hasMultipleSupply={hasMultipleSupply}
              onMultipleSupplyToggle={setHasMultipleSupply}
            />

            {hasMultipleSupply && (
              <Fragment>
                <View style={rs.paddingHorizontal}>
                  <Separator />
                </View>
                <PriceInput
                  title={strings.lots.item_participant_placeholder}
                  onChangeText={(name) => onInputChangesHandler(name, 'participantsCount')}
                  value={participantsCount}
                  onFocus={onFocusPriceInput}
                  editable={isEditable}
                />
              </Fragment>
            )}
          </Fragment>
        )}

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <PriceInput
          title={strings.lots.item_reward_placeholder}
          onChangeText={(name) => onInputChangesHandler(name, 'price')}
          value={price}
          onFocus={onFocusPriceInput}
          editable={isEditable}
        />

        <View style={rs.paddingVertical} />

        {!wish && groupInfo.has_seller_item_repost && (
          <MultipleSupplyToggle
            hasMultipleSupply={hasMultipleSupply}
            onMultipleSupplyToggle={setHasMultipleSupply}
          />
        )}

        <PostButtonContainer>
          <Button
            disabled={!route.params?.isValidate ?? false}
            onPress={onSubmit}
            title={
              route.params?.item ?? false ? strings.common.save : strings.lots.create_lot_button
            }
          />
        </PostButtonContainer>
      </View>
    </KeyboardAwareScrollView>
  );
};

const EditTalentWithSelectedCommunityId = withSelectedGroupId(
  connect(
    (state, { selectedGroupId }) => ({
      user: state.userInfo,
      groupInfo: groupsSelectors.getGroupInfoById(state, selectedGroupId),
      refundPolicies: state.communityInfo.community_refund_policies,
      groups: groupsSelectors.getCommunityGroups(state),
      savedLocations: state.shipping.locations,
      canTagSubCommunityToItems: subCommunitySelectors.getCanTagSubCommunityToItems(state),
      communityInfo: state.communityInfo,
    }),
    (dispatch) => ({
      createNewSkill: (skillObject) => dispatch(lotsOperations.createNewSkill(skillObject)),
      editSkill: (skillObject) => dispatch(lotsOperations.editSkill(skillObject)),
      getUserSubCommunities: () => dispatch(subCommunityOperations.getUserSubCommunities()),
      saveShippingForNextLot: (locations, shippingPrice, isShippersDeliveryActive) =>
        dispatch(
          shippingOperations.saveShippingForNextLot(
            locations,
            shippingPrice,
            isShippersDeliveryActive,
          ),
        ),
    }),
  )(EditTalentScreen),
);

export default EditTalentWithSelectedCommunityId;
