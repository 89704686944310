import React from 'react';
import Icon from 'react-native-vector-icons/Ionicons';
import { SearchBar, ContentContainer, ClearButton, SearchBarButton } from './styles';
import { colors } from '../../../styles';
import strings from '../../../localization';
import { getCurrentTheme } from '../../../templates';

const SearchInput = ({
  onClear = () => null,
  value,
  onChangeText,
  onPress,
  withoutRightPadding,
  ...inputProps
}) => {
  const currentTheme = getCurrentTheme();
  return (
    <ContentContainer withoutRightPadding={withoutRightPadding}>
      {!!onPress && <SearchBarButton onPress={onPress} />}
      <SearchBar
        value={value}
        onChangeText={onChangeText}
        placeholder={strings.other.search_with_dots}
        editable={!onPress}
        {...inputProps}
      />
      {!!value && (
        <ClearButton withoutRightPadding={withoutRightPadding} onPress={() => onChangeText('')}>
          <Icon color={currentTheme.colors.secondaryLight} name="ios-close-circle" size={18} />
        </ClearButton>
      )}
    </ContentContainer>
  );
};

export default SearchInput;
