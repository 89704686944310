import { Platform, StyleSheet } from 'react-native';
import { colors, dimensions, fontSizes, rs } from '../../../../styles';
import { isRTL } from '../../../../utils/rtlHelper';
import { isIos, isWeb } from '../../../../utils/detectDevice';

export default StyleSheet.create({
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
  },
  appBarContainer: {
    height: dimensions.appBarHeight,
    flexDirection: 'row',
    alignItems: 'center',
  },
  priceText: {
    paddingHorizontal: dimensions.halfMedium,
    fontSize: 18,
  },
  itemImage: {
    height: 34,
    width: 34,
    borderRadius: 4,
    marginHorizontal: dimensions.medium,
  },
  userImage: {
    height: 34,
    width: 34,
    borderRadius: 17,
    marginHorizontal: dimensions.medium,
  },
  previewIndicator: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceContainer: {
    flexDirection: 'row',
    paddingLeft: isIos ? 32 : 50,
    alignItems: 'center',
  },
  offerButtonsContainer: {
    height: dimensions.additionalAppBarLayerHeight,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
  },
  supportBannerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.medium,
    backgroundColor: colors.blueLightest,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayBorder,
  },
  supportButton: {
    textDecorationLine: 'underline',
  },
  sendButtonText: {
    marginBottom: 14,
    marginRight: 12,
  },
  textInput:
    Platform.OS === 'ios'
      ? {
          height: 30,
          borderWidth: 0.3,
          borderColor: colors.gray,
          borderRadius: 15,
          backgroundColor: colors.white,
          paddingHorizontal: dimensions.halfMedium,
          marginHorizontal: dimensions.medium,
          paddingBottom: 8,
          paddingTop: 8,
          marginTop: 6,
          marginBottom: 6,
          textAlign: isRTL ? 'right' : 'left',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: fontSizes.medium,
          color: colors.text,
        }
      : {},
  systemMessageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: dimensions.doubleMedium,
    marginVertical: dimensions.medium,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    overflow: 'hidden',
  },
  systemMessageIconContainer: {
    height: 26,
    width: 26,
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: dimensions.medium,
  },
  systemMessageBadge: {
    height: '100%',
    width: dimensions.halfMedium,
  },
  systemMessageTextContainer: {
    width: '84%',
    paddingHorizontal: dimensions.medium,
  },
  priceImage: {
    height: 18,
    width: 18,
  },
  messageText: isIos
    ? {
        writingDirection: isRTL ? 'rtl' : 'ltr',
      }
    : {},
  userNameForAdminText: {
    paddingHorizontal: 4,
    paddingBottom: 4,
    paddingTop: dimensions.medium,
    alignSelf: isRTL ? 'flex-end' : 'flex-start',
  },
  textMessageContainer: {
    minWidth: 80,
    flexDirection: isWeb ? 'column' : 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: isWeb ? 'flex-end' : 'baseline',
  },
  textMessageFooter: {
    flexGrow: 1,
    top: 2,
  },
  messageFooter: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
  },
  messageBubble: {
    marginBottom: dimensions.halfMedium,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0.2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.0,
    elevation: 1,

    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  tickView: {
    flexDirection: 'row',
    marginRight: 3,
  },
  tick: {
    fontSize: fontSizes.xsmall,
    fontWeight: 'bold',
    color: colors.gray,
    textShadowColor: colors.chatBubbleCurrentUser,
    textShadowOffset: { width: 2, height: 0 },
    textShadowRadius: 1,
  },
  readMessageTick: {
    color: '#59B88C',
  },
  timeContainer: {
    marginLeft: 3,
    marginRight: 6,
    marginBottom: 6,
  },
  timeText: {
    fontSize: fontSizes.xsmall,
    color: colors.gray,
  },
  cameraWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatsContainer: {
    width: '64%',
    borderLeftWidth: 1,
    borderColor: colors.grayBorder,
  },
});
