import React from 'react';
import { StyleSheet, View } from 'react-native';
import { getCurrentTheme } from '../../../templates';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

const Container = ({ children }) => {
  const currentTheme = getCurrentTheme();

  return (
    <View style={[styles.container, { backgroundColor: currentTheme.colors.background }]}>
      {children}
    </View>
  );
};

export default Container;
