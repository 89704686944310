import { handleActions } from 'redux-actions';
import * as types from './types';
import { mergeDeep } from '../../utils/stateHelper';

const initialState = {
  ids: [],
  entities: {},
  groups: [],
  availableGroupsToJoin: [],
};

const reducer = handleActions(
  {
    [types.SET_SUB_COMMUNITIES]: mergeDeep(({ payload }) => ({
      groups: payload,
    })),
    [types.SET_SUB_COMMUNITIES_TO_JOIN]: mergeDeep(({ payload }, state) => ({
      availableGroupsToJoin: payload,
    })),
    [types.REMOVE_SUB_COMMUNITY]: mergeDeep(({ payload }, state) => ({
      ids: state.ids.filter((id) => id !== payload),
    })),
  },
  initialState,
);

export default reducer;
