import React from 'react';
import { View, StyleSheet } from 'react-native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { Text } from '../../../../ReusableComponents';
import { dimensions } from '../../../../../styles';
import { isRTL } from '../../../../../utils/rtlHelper';
import { isWeb } from '../../../../../utils/detectDevice';

const styles = StyleSheet.create({
  root: {},
  codeFieldRoot: {
    width: dimensions.width - dimensions.doubleMedium * 2,
    maxWidth: 450,
    paddingHorizontal: dimensions.doubleMedium,
    paddingTop: dimensions.halfMedium,
    paddingBottom: dimensions.medium,
    backgroundColor: '#F5F6F7',
    borderRadius: 40,
    flexDirection: isRTL ? 'row-reverse' : 'row',
    outlineWidth: 0,
  },
  cellRoot: {
    width: 44,
    height: 34,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: '#BFBFBF',
    borderBottomWidth: 1,
  },
  cellText: {
    fontSize: isWeb ? 20 : 24,
  },
  focusCell: {
    borderBottomColor: '#A44AFE',
    borderBottomWidth: 2,
  },
});

const CELL_COUNT = 4;

const CodeConfirmationInput = ({ value, onChangeText, rootStyle }) => {
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue: onChangeText,
  });

  return (
    <CodeField
      ref={ref}
      autoFocus={true}
      {...props}
      value={value}
      onChangeText={onChangeText}
      cellCount={CELL_COUNT}
      rootStyle={[styles.codeFieldRoot, rootStyle]}
      keyboardType="number-pad"
      textContentType="oneTimeCode"
      renderCell={({ index, symbol, isFocused }) => (
        <View
          onLayout={getCellOnLayoutHandler(index)}
          key={index}
          style={[styles.cellRoot, isFocused && styles.focusCell]}
        >
          <Text alignCenter3 semiBold style={styles.cellText}>
            {symbol || (isFocused ? <Cursor /> : null)}
          </Text>
        </View>
      )}
    />
  );
};

export default CodeConfirmationInput;
