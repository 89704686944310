import { StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../../styles';
import { isWeb } from '../../../utils/detectDevice';

const IMAGE_WIDTH = dimensions.width * 0.65;

export default StyleSheet.create({
  topContainer: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingBottom: dimensions.doubleMedium,
    backgroundColor: colors.grayLighter,
    marginBottom: dimensions.medium,
    ...rs.shadow,
  },

  copyContainer: {
    paddingHorizontal: dimensions.medium,
  },

  mainText: {
    lineHeight: 20,
    paddingHorizontal: dimensions.halfMedium,
  },

  codeContainer: {
    flexDirection: 'row',
    borderRadius: 4,
    borderColor: colors.gray,
    borderWidth: 1,
    borderStyle: 'dashed',
    paddingVertical: dimensions.halfMedium,
    alignItems: 'center',
    marginVertical: dimensions.doubleMedium,
  },

  codeSeparator: {
    height: '100%',
    width: 1,
    backgroundColor: colors.gray,
  },

  inviteFriendsButton: {
    height: 40,
    borderRadius: 20,
    width: isWeb ? 300 : 150,
    alignItems: 'center',
    justifyContent: 'center',
  },

  headerBackgroundImage: {
    width: '100%',
    paddingTop: 25 + dimensions.medium,
    paddingBottom: dimensions.doubleMedium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  earnedContainer: {
    width: dimensions.width - dimensions.doubleMedium * 2,
    paddingTop: 25 + dimensions.halfMedium,
    paddingBottom: dimensions.medium,
    borderWidth: 1,
    borderColor: colors.white,
    backgroundColor: colors.white,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    ...rs.shadow,
  },
  usersJoinedContainer: {
    width: dimensions.width - dimensions.doubleMedium * 2,
    height: 40,
    marginTop: dimensions.medium,
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderRadius: 10,
    alignItems: 'center',
    ...rs.shadow,
  },
  usersJoinedSeparator: {
    width: 1,
    height: '60%',
    backgroundColor: colors.grayLight,
    opacity: 0.5,
  },

  mainContainer: {
    flex: 1,
    alignItems: 'center',
    marginTop: dimensions.halfMedium,
    paddingHorizontal: dimensions.doubleMedium,
    justifyContent: 'space-around',
  },
  inviteButtonContainer: {
    width: '100%',
  },
  // 0.35
  image: {
    height: IMAGE_WIDTH * 0.35,
    width: IMAGE_WIDTH,
    marginBottom: dimensions.medium,
  },
});
