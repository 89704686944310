import React, { useCallback, useEffect, useState } from 'react';
import { TabView } from 'react-native-tab-view';
import { connect } from 'react-redux';
import T from 'prop-types';
import { I18nManager, StyleSheet } from 'react-native';
import { useActionSheet } from '@expo/react-native-action-sheet';
import * as R from 'ramda';
import { offersOperations, offersSelectors } from '../../../store/offers';
import { TabBar, Container, Spinner, EmptyListWithImage } from '../../ReusableComponents';
import strings from '../../../localization';
import { SELLING } from '../../../constants/offerTypes';
import SellingOffer from './components/SellingOffer';
import OffersList from './components/OffersList';
import { dimensions } from '../../../styles';
import { LoadingService, ModalsService } from '../../../services';
import NavigationService from '../../../services/NavigationService';
import screens from '../../../navigation/screens';
import modalTypes from '../../../constants/modalTypes';
import { SELLER_CONFIRMED_OFFER } from '../../../store/offers/offerActions';
import { lotsOperations } from '../../../store/lots';
import { isWeb } from '../../../utils/detectDevice';

const s = StyleSheet.create({
  tabViewInitialLayout: {
    height: 0,
    width: dimensions.width,
  },
});

const SellingOffers = ({
  performAction,
  askedToBuyOffers,
  pendingDeliveryOffers,
  askedToBuyOfferLoadersInfo,
  pendingDeliveryOfferLoadersInfo,
  getSellingAskedToBuyOffers,
  getSellingPendingDeliveryOffers,
  getItemInfo,
  route,
  navigation,
  archiveOffers,
  archiveAllOffers,
}) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const [index, setIndex] = useState(route.params?.tabIndex ?? 0);
  const [isRefreshing, setRefreshing] = useState(false);
  const [selectedOffersIds, setSelectedOfferIds] = useState([]);
  const isEditMode = route.params?.isEditMode ?? false;

  useEffect(() => {
    onFetchOffers();
  }, []);

  useEffect(() => {
    navigation.setParams({
      onOpenActionSheet,
      tabIndex: index,
      selectedOffersLength: 0,
    });
  }, []);

  useEffect(() => {
    navigation.setParams({
      onDonePress: () => {
        setSelectedOfferIds([]);
        navigation.setParams({ isEditMode: false, selectedOffersLength: 0 });
      },
      onArchivePress: () => {
        setSelectedOfferIds([]);
        navigation.setParams({ isEditMode: false, selectedOffersLength: 0 });
        archiveOffers({ selectedOffersIds });
      },
    });
  }, [selectedOffersIds]);

  const onChangeTabIndex = (tabIndex) => {
    navigation.setParams({
      tabIndex,
    });

    setIndex(tabIndex);
  };

  const onOpenActionSheet = useCallback(() => {
    const options = [
      strings.buy_sell.add_offers_to_archive,
      strings.buy_sell.archive_all_offers,
      strings.buy_sell.archived_offers,
      strings.common.cancel,
    ];

    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          navigation.setParams({ isEditMode: true });
        }
        if (buttonIndex === 1) {
          archiveAllOffers();
        }
        if (buttonIndex === 2) {
          NavigationService.navigate(screens.ArchivedSellingOffers);
        }
      },
    );
  }, []);

  const onSelectItem = ({ itemId, offerId }) => {
    if (!isEditMode) {
      NavigationService.navigate(screens.ItemDetails, { itemId });
      return;
    }
    let newSelectedOfferIds = null;

    if (R.includes(offerId, selectedOffersIds)) {
      newSelectedOfferIds = selectedOffersIds.filter((element) => element !== offerId);
    } else {
      newSelectedOfferIds = [...selectedOffersIds, offerId];
    }

    setSelectedOfferIds(newSelectedOfferIds);
    navigation.setParams({ selectedOffersLength: newSelectedOfferIds.length });
  };

  const onFetchOffers = async () => {
    await getSellingAskedToBuyOffers();
    await getSellingPendingDeliveryOffers();
  };

  const onRefresh = async () => {
    setRefreshing(true);

    if (index === 0) {
      await getSellingAskedToBuyOffers();
      await getSellingPendingDeliveryOffers();
    } else {
      await getSellingPendingDeliveryOffers();
      await getSellingAskedToBuyOffers();
    }

    setRefreshing(false);
  };

  const onLoadMoreAskedToBuyOffers = () => {
    getSellingAskedToBuyOffers(true);
  };

  const onLoadMorePendingDeliveryOffer = () => {
    getSellingPendingDeliveryOffers(true);
  };

  const onPerformAction = async (offerId, actionId) => {
    LoadingService.showSuccessLoader();
    await performAction(offerId, actionId);
    LoadingService.hideSuccessLoader();
  };

  const onPerformRefundBySeller = async (offerId) => {
    ModalsService.showSwipeableModal(modalTypes.REFUND, {
      offerId,
    });
  };

  const onPerformRepostBySeller = async (offerId, itemId) => {
    LoadingService.showLoader();
    const item = await getItemInfo(itemId);

    if (item.has_multiple_supply) {
      LoadingService.hideLoader();
      ModalsService.showModal(modalTypes.REPOST, { offerId });
    } else {
      await performAction(offerId, SELLER_CONFIRMED_OFFER);
      LoadingService.hideLoader();
    }
  };

  const onOpenChat = (chatId) => {
    NavigationService.push(screens.ChatRoom, { chatId });
  };

  const onOpenCreateNewItem = () => {
    NavigationService.navigate(screens.EditItemStack);
  };

  const routes = [
    { key: 'askedToBuy', title: strings.buy_sell.asked_to_buy },
    { key: 'pendingDelivery', title: strings.buy_sell.pending_delivery },
  ];

  const renderLazyPlaceholder = () => <Spinner />;

  return (
    <Container>
      <TabView
        navigationState={{ index, routes }}
        onIndexChange={onChangeTabIndex}
        style={{
          direction: isWeb ? 'ltr' : 'inherit',
        }}
        sceneContainerStyle={
          isWeb && { direction: I18nManager.getConstants().isRTL ? 'rtl' : 'ltr' }
        }
        renderTabBar={(props) => <TabBar {...props} />}
        initialLayout={s.tabViewInitialLayout}
        lazy
        renderLazyPlaceholder={renderLazyPlaceholder}
        renderScene={({ route }) =>
          ({
            askedToBuy: (
              <OffersList
                data={askedToBuyOffers}
                isRefreshing={isRefreshing}
                isLoading={askedToBuyOfferLoadersInfo.isLoading}
                isLoadingMore={askedToBuyOfferLoadersInfo.isLoadingMore}
                onLoadMore={onLoadMoreAskedToBuyOffers}
                onRefresh={onRefresh}
                ListEmptyComponent={() => (
                  <EmptyListWithImage
                    image="empty_state_selling"
                    text={strings.emptyLists.empty_selling_offers_list}
                    buttonTitle={strings.emptyLists.upload_an_item}
                    onPress={onOpenCreateNewItem}
                  />
                )}
                renderItem={({ item }) => (
                  <SellingOffer
                    offerType={SELLING}
                    offer={item}
                    onOpenChat={onOpenChat}
                    onPerformAction={onPerformAction}
                    onPerformRepostBySeller={onPerformRepostBySeller}
                  />
                )}
              />
            ),
            pendingDelivery: (
              <OffersList
                data={pendingDeliveryOffers}
                isRefreshing={isRefreshing}
                isLoading={pendingDeliveryOfferLoadersInfo.isLoading}
                isLoadingMore={pendingDeliveryOfferLoadersInfo.isLoadingMore}
                onLoadMore={onLoadMorePendingDeliveryOffer}
                onRefresh={onRefresh}
                ListEmptyComponent={() => (
                  <EmptyListWithImage
                    image="empty_state_selling_delivery"
                    text={strings.emptyLists.empty_selling_pending_delivery_offers_list}
                    buttonTitle={strings.emptyLists.upload_an_item}
                    onPress={onOpenCreateNewItem}
                  />
                )}
                renderItem={({ item }) => (
                  <SellingOffer
                    onSelectItem={onSelectItem}
                    selectedOffersIds={selectedOffersIds}
                    offerType={SELLING}
                    offer={item}
                    onOpenChat={onOpenChat}
                    onPerformRefundBySeller={onPerformRefundBySeller}
                  />
                )}
              />
            ),
          }[route.key])
        }
      />
    </Container>
  );
};

SellingOffers.propTypes = {
  performAction: T.func,
  getSellingAskedToBuyOffers: T.func,
  getSellingPendingDeliveryOffers: T.func,
  askedToBuyOffers: T.array,
  pendingDeliveryOffers: T.array,
  askedToBuyOfferLoadersInfo: T.shape({
    isLoading: T.bool,
    isLoadingMore: T.bool,
  }),
  pendingDeliveryOfferLoadersInfo: T.shape({
    isLoading: T.bool,
    isLoadingMore: T.bool,
  }),
};

export default connect(
  (state, { searchValue }) => ({
    askedToBuyOffers: offersSelectors.getSellingAskedToBuyOffers(state, searchValue),
    pendingDeliveryOffers: offersSelectors.getSellingPendingDeliveryOffers(state, searchValue),
    askedToBuyOfferLoadersInfo: state.offers.sellingOffers.askedToBuy,
    pendingDeliveryOfferLoadersInfo: state.offers.sellingOffers.pendingDelivery,
  }),
  (dispatch) => ({
    performAction: (offerId, actionId) =>
      dispatch(offersOperations.performAction(offerId, actionId)),
    getSellingAskedToBuyOffers: (isLoadMore) =>
      dispatch(offersOperations.getSellingAskedToBuyOffers({ isLoadMore })),
    getSellingPendingDeliveryOffers: (isLoadMore) =>
      dispatch(offersOperations.getSellingPendingDeliveryOffers({ isLoadMore })),
    getItemInfo: (itemId) => dispatch(lotsOperations.getItemInfo(itemId)),
    archiveOffers: ({ selectedOffersIds }) =>
      dispatch(offersOperations.archiveOffers({ selectedOffersIds })),
    archiveAllOffers: () => dispatch(offersOperations.archiveAllOffers()),
  }),
)(SellingOffers);
