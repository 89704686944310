import { pathOr, groupBy, prop } from 'ramda';
import { createSelector } from 'reselect';
import { getCommunitiesList } from '../communities/selectors';
import { getCommunityId } from '../communityInfo/selectors';

export const getSubCommunityIds = (state) => {
  return pathOr([], ['subCommunities', 'ids'], state);
};

export const getSubCommunityEntities = (state) => {
  return pathOr({}, ['subCommunities', 'entities'], state);
};

export const getSubCommunityGroupsList = (state) => {
  return pathOr([], ['subCommunities', 'groups'], state);
};

export const getSubCommunityGroupsAvailableToJoin = (state) => {
  return pathOr([], ['subCommunities', 'availableGroupsToJoin'], state);
};

export const getSubCommunitiesList = createSelector(
  [getSubCommunityIds, getSubCommunityEntities],
  (ids, entities) => {
    return ids.map((id) => entities[id]);
  },
);

export const getSubCommunitiesSectionList = createSelector(
  [getSubCommunitiesList],
  (subCommunities) => {
    const groupedSubCommunities = groupBy(prop('community_id'), subCommunities);
    const list = subCommunities.map((subCommunity) => ({
      ...subCommunity,
      data: [],
    }));

    return list;
  },
);

export const getSubCommunitiesListForCurrentCommunity = createSelector(
  [getSubCommunityGroupsList, getCommunityId],
  (list, communityId) => {
    return list.filter(
      (subCommunity) => parseInt(subCommunity.community_id, 10) === parseInt(communityId, 10),
    );
  },
);

export const getCanTagSubCommunityToItems = createSelector(
  [getSubCommunitiesListForCurrentCommunity],
  (subCommunities) => {
    return !!subCommunities?.length;
  },
);

export const getSubCommunityFromLotFilters = createSelector(
  [pathOr([], ['lots', 'feedLotFilters', 'subCommunityIds']), getSubCommunityEntities],
  (ids, entities) => {
    const [id] = ids;

    if (!id) {
      return null;
    }

    return entities[id];
  },
);

export const getSubCommunityFromWishFilters = createSelector(
  [pathOr([], ['lots', 'feedWishFilters', 'subCommunityIds']), getSubCommunityEntities],
  (ids, entities) => {
    const [id] = ids;

    if (!id) {
      return null;
    }

    return entities[id];
  },
);

export default {
  getSubCommunityIds,
  getSubCommunityEntities,
  getSubCommunitiesList,
  getSubCommunitiesSectionList,
  getSubCommunitiesListForCurrentCommunity,
  getCanTagSubCommunityToItems,
  getSubCommunityFromLotFilters,
  getSubCommunityFromWishFilters,
  getSubCommunityGroupsList,
  getSubCommunityGroupsAvailableToJoin,
};
