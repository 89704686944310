import React, { Fragment, memo } from 'react';
import { I18nManager, StyleSheet, View } from 'react-native';
import Text from './Text';
import TouchableItem from './TouchableItem';
import { dimensions, fontSizes, reusableStyles, rs } from '../../styles';
import IconIonicons from 'react-native-vector-icons/Ionicons';
import strings from '../../localization';
import { isRTL } from '../../utils/rtlHelper';
import T from 'prop-types';
import { isWeb } from '../../utils/detectDevice';
import { getCurrentTheme } from '../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.medium,
    alignItems: 'flex-end',
    paddingTop: 30,
    paddingBottom: 12,
  },
  titleText: {
    fontSize: fontSizes.large,
  },
  smallTile: {
    fontSize: fontSizes.medium,
  },
  icon: {
    paddingLeft: isRTL ? 0 : dimensions.halfMedium,
    paddingRight: isRTL ? dimensions.halfMedium : 0,
    ...reusableStyles.rtlScaleStyle,
  },
});

const SectionTitle = memo(({ title, small, onSeeAllPress, contentContainerStyle, refToScroll }) => {
  const currentTheme = getCurrentTheme();

  const scrollToRight = () => {
    refToScroll.current?.scrollToOffset({
      animated: true,
      offset: refToScroll.current._listRef._scrollMetrics.offset + 500,
    });
  };

  const scrollToLeft = () => {
    refToScroll.current?.scrollToOffset({
      animated: true,
      offset: refToScroll.current._listRef._scrollMetrics.offset - 500,
    });
  };

  const isRTL = isWeb ? I18nManager.getConstants().isRTL : I18nManager.isRTL;

  return (
    <View style={[s.contentContainer, contentContainerStyle]}>
      <Text semiBold style={small ? s.smallTile : s.titleText}>
        {title}
      </Text>
      {!!onSeeAllPress && (
        <View style={rs.row}>
          <TouchableItem style={[rs.row, rs.alignCenter, rs.paddingRight]} onPress={onSeeAllPress}>
            <Text color={currentTheme.colors.secondary}>{strings.common.see_all}</Text>
          </TouchableItem>
          {isWeb && (
            <Fragment>
              <IconIonicons
                onPress={isRTL ? scrollToRight : scrollToLeft}
                size={25}
                name="ios-arrow-back"
                style={{
                  paddingHorizontal: 10,
                  color: currentTheme.colors.secondary,
                  transform: [{ scaleX: isRTL ? -1 : 1 }],
                }}
              />
              <IconIonicons
                onPress={isRTL ? scrollToLeft : scrollToRight}
                size={25}
                name="ios-arrow-forward"
                style={{
                  paddingHorizontal: 10,
                  color: currentTheme.colors.secondary,
                  transform: [{ scaleX: isRTL ? -1 : 1 }],
                }}
              />
            </Fragment>
          )}
        </View>
      )}
      {isWeb && !onSeeAllPress && (
        <View style={rs.row}>
          <IconIonicons
            onPress={scrollToLeft}
            size={25}
            name="ios-arrow-back"
            style={{
              paddingHorizontal: 10,
              color: currentTheme.colors.secondary,
              transform: [{ scaleX: isRTL ? -1 : 1 }],
            }}
          />
          <IconIonicons
            onPress={scrollToRight}
            size={25}
            name="ios-arrow-forward"
            style={{
              paddingHorizontal: 10,
              color: currentTheme.colors.secondary,
              transform: [{ scaleX: isRTL ? -1 : 1 }],
            }}
          />
        </View>
      )}
    </View>
  );
});

SectionTitle.propTypes = {
  title: T.string.isRequired,
  contentContainerStyle: T.shape({}),
  onSeeAllPress: T.func,
};

export default SectionTitle;
