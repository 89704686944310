import React from 'react';
import T from 'prop-types';
import { StyleSheet } from 'react-native';
import strings from '../../../../../../localization';
import { TouchableItem, Text } from '../../../../../ReusableComponents';
import { colors, dimensions } from '../../../../../../styles';
import { numberWithCommas } from '../../../../../../utils/stringHelper';
import { getCurrentTheme } from '../../../../../../templates';

const s = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: dimensions.medium,
    paddingHorizontal: dimensions.halfMedium,
  },
});

const SubCategoryItem = ({ onSelect, category, isSelectAll }) => {
  const onPress = () => {
    onSelect(category.id);
  };

  const currentTheme = getCurrentTheme();

  const textColor = isSelectAll ? currentTheme.colors.secondary : colors.text;

  return (
    <TouchableItem onPress={onPress} style={s.container} useOpacity>
      <React.Fragment>
        <Text small semiBold color={textColor}>
          {category.name}
        </Text>
        <Text small color={textColor}>{`${numberWithCommas(category.items_count)} ${
          strings.groups.active_items
        }`}</Text>
      </React.Fragment>
    </TouchableItem>
  );
};

SubCategoryItem.propTypes = {
  onSelect: T.func.isRequired,
  category: T.shape({
    id: T.oneOfType([T.string, T.number]),
    name: T.string,
    items_count: T.oneOfType([T.string, T.number]),
  }),
  isSelectAll: T.bool,
};

SubCategoryItem.defaultProps = {
  isSelectAll: false,
};

export default SubCategoryItem;
