import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import screens from '../screens';
import SuspendedUserScreen from '../../components/Screens/AuthorizationGroup/SuspendedUserScreen';
import ContainerWeb from '../../components/ReusableComponents/Containers/ContainerWeb.web';

const Stack = createStackNavigator();

const SuspendedUserStack = () => (
  <ContainerWeb>
    <Stack.Navigator initialRouteName={screens.SuspendedUser}>
      <Stack.Screen
        name={screens.SuspendedUser}
        component={SuspendedUserScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  </ContainerWeb>
);

export default SuspendedUserStack;
