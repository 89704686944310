import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import * as R from 'ramda';
import { chatsOperations, chatsSelectors } from '../../../../store/chats';
import {
  Container,
  EmptyListWithImage,
  IconButton,
  NavigationButton,
  NavigationIconButton,
  View,
} from '../../../ReusableComponents';
import ChatsList from './components/ChatsList';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { isWeb } from '../../../../utils/detectDevice';
import { useGetWindowCurrentWidth } from '../../../../utils/getWindowCurrentWidth';
import InboxContainer from '../../../ReusableComponents/Containers/InboxContainer';
import { colors, dimensions, rs } from '../../../../styles';
import ChatsListContainer from './components/ChatsListContainer';
import { StyleSheet } from 'react-native';
import ChatArchivedListContainer from './components/ChatArchivedListContainer';

const HeaderButton = ({
  isEditMode,
  onUnarchivePress,
  selectedChatsLength,
  onDonePress,
  navigation,
}) => {
  return (
    <View style={s.headers}>
      <NavigationButton
        disabled={!selectedChatsLength}
        title={strings.chats.unarchive}
        onButtonPress={onUnarchivePress}
      />
      {isEditMode ? (
        <NavigationButton title={strings.common.cancel} onButtonPress={onDonePress} />
      ) : (
        <NavigationButton
          title={strings.common.edit}
          onButtonPress={() => navigation.setParams({ isEditMode: true })}
        />
      )}
    </View>
  );
};

const ArchivedChats = ({ unarchiveChats, navigation, route }) => {
  const isEditMode = route.params?.isEditMode ?? false;
  const [selectedChatIds, setSelectedChatIds] = useState([]);
  const { isMobileWindow } = useGetWindowCurrentWidth();

  const onDonePress = () => {
    setSelectedChatIds([]);
    navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
  };
  const onUnarchivePress = () => {
    setSelectedChatIds([]);
    navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
    unarchiveChats(selectedChatIds);
  };

  const onSelectChat = (chatId) => {
    if (!isEditMode) {
      NavigationService.navigate(screens.ChatRoom, { chatId, isArchivedChats: true });
      return;
    }
    let newSelectedChatIds = null;

    if (R.includes(chatId, selectedChatIds)) {
      newSelectedChatIds = selectedChatIds.filter((element) => element !== chatId);
    } else {
      newSelectedChatIds = [...selectedChatIds, chatId];
    }

    setSelectedChatIds(newSelectedChatIds);
    navigation.setParams({ selectedChatsLength: newSelectedChatIds.length });
  };

  return !isMobileWindow ? (
    <InboxContainer>
      <View style={[rs.row, rs.fullWidth, rs.fullHeight]}>
        <ChatArchivedListContainer
          isEditMode={isEditMode}
          onSelectChat={onSelectChat}
          selectedChatIds={selectedChatIds}
        />
        <View style={[s.chatsContainer]}>
          <HeaderButton
            selectedChatsLength={route.params?.selectedChatsLength}
            onDonePress={onDonePress}
            isEditMode={isEditMode}
            navigation={navigation}
            onUnarchivePress={onUnarchivePress}
          />
          <View style={[s.chatsContainer]}>
            <EmptyListWithImage image="empty_state_chats" />
          </View>
        </View>
      </View>
    </InboxContainer>
  ) : (
    <>
      <HeaderButton
        selectedChatsLength={route.params?.selectedChatsLength}
        onDonePress={onDonePress}
        isEditMode={isEditMode}
        navigation={navigation}
        onUnarchivePress={onUnarchivePress}
      />
      <ChatArchivedListContainer
        isEditMode={isEditMode}
        onSelectChat={onSelectChat}
        selectedChatIds={selectedChatIds}
      />
    </>
  );
};

ArchivedChats.propTypes = {
  archivedChats: T.array,
  isLoading: T.bool,
  isLoadingMore: T.bool,
  getArchivedChats: T.func,
};

const s = StyleSheet.create({
  chatsContainer: {
    width: '64%',
    borderLeftWidth: 1,
    borderColor: colors.grayBorder,
  },
  headers: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default connect(
  (state) => ({
    isLoading: state.chats.archivedChats.isLoading,
    isLoadingMore: state.chats.archivedChats.isLoadingMore,
    archivedChats: chatsSelectors.getArchivedChats(state),
  }),
  (dispatch) => ({
    getArchivedChats: (isLoadMore) =>
      dispatch(chatsOperations.getArchivedChats([], [], isLoadMore)),
    unarchiveChats: (chatIds) => dispatch(chatsOperations.unarchiveChats(chatIds)),
  }),
)(ArchivedChats);
