import styled from 'styled-components/native';
import { heightPercentageToDP, widthPercentageToDP } from '../../../helpers/responsive';
import { Text } from '../../ReusableComponents';
import { fontSizes, dimensions } from '../../../styles';
import { isIphoneX } from '../../../utils/detectDevice';

const ButtonContainer = styled.View`
  background-color: #ffffff;
  display: flex;
  width: ${(props) => (props.width ? props.width : widthPercentageToDP(100))};
  flex-direction: row;
  justify-content: center;
  z-index: 999;
  bottom: 0;
  padding-top: ${heightPercentageToDP(2)};
  padding-bottom: ${isIphoneX ? 40 : dimensions.doubleMedium};
  position: absolute;
`;

const CancelButton = styled.TouchableOpacity`
  width: ${(props) => (props.width ? props.width : widthPercentageToDP(46))};
  margin-left: ${widthPercentageToDP(3)};
  margin-right: ${widthPercentageToDP(1)};
  padding-top: ${heightPercentageToDP(1)};
  padding-bottom: ${heightPercentageToDP(1)};
  border-width: 1;
  border-color: #c7cacc;
  border-radius: 5;
  align-items: center;
`;

const ApplyButton = styled.TouchableOpacity`
  width: ${(props) => (props.width ? props.width : widthPercentageToDP(46))};
  padding-top: ${heightPercentageToDP(1)};
  padding-bottom: ${heightPercentageToDP(1)};
  margin-left: ${widthPercentageToDP(1)};
  margin-right: ${widthPercentageToDP(3)};
  align-items: center;
  border-radius: 5;
  background-color: #eaedf0;
`;

const CancelText = styled(Text)`
  font-size: ${fontSizes.medium};
`;

const ApplyText = styled(Text)`
  font-size: ${fontSizes.medium};
  color: #c7cacc;
`;

export { ButtonContainer, CancelButton, ApplyButton, CancelText, ApplyText };
