import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';

import { connect } from 'react-redux';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Text, InputWithTitleAndValidation, Button } from '../../../ReusableComponents';

import styles from './styles';

import strings from '../../../../localization';
import { dimensions, rs } from '../../../../styles';
import { userOperations } from '../../../../store/userInfo';

import { NavigationService, ToastsService } from '../../../../services';

const UserPersonalInsightsScreen = ({ getUserCustomProfile, createUserCustomProfile }) => {
  const [where_are_you_from, setWhere_are_you_from] = useState('');
  const [private_professional_background, setPrivate_professional_background] = useState('');
  const [sharingood_community_motivation, setSharingood_community_motivation] = useState('');
  const [life_driving_enchantment, setLife_driving_enchantment] = useState('');
  const [future_vision_ten_years, setFuture_vision_ten_years] = useState('');
  const [life_purpose_earth_presence, setLife_purpose_earth_presence] = useState('');
  const [contribution_for_change, setContribution_for_change] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadGetUserCustomProfile = async () => {
    try {
      setIsLoading(true);
      const response = await getUserCustomProfile();

      const { custom_profile } = response;

      if (!custom_profile) return;

      setWhere_are_you_from(custom_profile.where_are_you_from);
      setPrivate_professional_background(custom_profile.private_professional_background);
      setSharingood_community_motivation(custom_profile.sharingood_community_motivation);
      setLife_driving_enchantment(custom_profile.life_driving_enchantment);
      setFuture_vision_ten_years(custom_profile.future_vision_ten_years);
      setLife_purpose_earth_presence(custom_profile.life_purpose_earth_presence);
      setContribution_for_change(custom_profile.contribution_for_change);
    } catch (error) {
      ToastsService.showError(strings.error_messages.error);
      setIsSubmitting(false);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadGetUserCustomProfile();
  }, []);

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    try {
      await createUserCustomProfile({
        where_are_you_from,
        private_professional_background,
        sharingood_community_motivation,
        life_driving_enchantment,
        future_vision_ten_years,
        life_purpose_earth_presence,
        contribution_for_change,
      });

      NavigationService.goBack();
    } catch (error) {
      ToastsService.showError(strings.error_messages.error);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <KeyboardAwareScrollView
      extraScrollHeight={dimensions.doubleMedium}
      enableResetScrollToCoords={false}
      keyboardShouldPersistTaps="handled"
      style={rs.backgroundWhite}
    >
      {isLoading ? (
        <View
          style={[
            rs.justifyCenter,
            {
              height: dimensions.height,
            },
          ]}
        >
          <ActivityIndicator />
        </View>
      ) : (
        <>
          <View style={styles.container}>
            <Text medium bold style={[rs.bigPaddingTop]}>
              {strings.user_info.welcome_personal_insights}
            </Text>
            <View
              style={[
                rs.paddingHorizontal,
                rs.bigPaddingTop,
                rs.backgroundGray,
                rs.flex,
                rs.fullWidth,
              ]}
            >
              <InputWithTitleAndValidation
                title={'1. ' + strings.user_info.where_do_you_come_from}
                autoCapitalize="none"
                minLength={5}
                underlineColorAndroid="transparent"
                placeholder={strings.user_info.type_here}
                onChangeText={setWhere_are_you_from}
                value={where_are_you_from}
                errorText={
                  isSubmitting &&
                  !where_are_you_from.length &&
                  strings.user_info.field_validation_error
                }
                useForcedRTL
              />

              <InputWithTitleAndValidation
                title={'2. ' + strings.user_info.private_professional_background}
                autoCapitalize="none"
                minLength={5}
                underlineColorAndroid="transparent"
                placeholder={strings.user_info.type_here}
                onChangeText={setPrivate_professional_background}
                value={private_professional_background}
                errorText={
                  isSubmitting &&
                  !private_professional_background.length &&
                  strings.user_info.field_validation_error
                }
                useForcedRTL
              />

              <InputWithTitleAndValidation
                title={'3. ' + strings.user_info.sharingood_community_motivation}
                autoCapitalize="none"
                minLength={5}
                underlineColorAndroid="transparent"
                placeholder={strings.user_info.type_here}
                onChangeText={setSharingood_community_motivation}
                value={sharingood_community_motivation}
                errorText={
                  isSubmitting &&
                  !sharingood_community_motivation.length &&
                  strings.user_info.field_validation_error
                }
                useForcedRTL
              />

              <InputWithTitleAndValidation
                title={'4. ' + strings.user_info.life_driving_enchantment}
                autoCapitalize="none"
                minLength={5}
                underlineColorAndroid="transparent"
                placeholder={strings.user_info.type_here}
                onChangeText={setLife_driving_enchantment}
                value={life_driving_enchantment}
                errorText={
                  isSubmitting &&
                  !life_driving_enchantment.length &&
                  strings.user_info.field_validation_error
                }
                useForcedRTL
              />

              <InputWithTitleAndValidation
                title={'5. ' + strings.user_info.future_vision_ten_years}
                autoCapitalize="none"
                minLength={5}
                underlineColorAndroid="transparent"
                placeholder={strings.user_info.type_here}
                onChangeText={setFuture_vision_ten_years}
                value={future_vision_ten_years}
                errorText={
                  isSubmitting &&
                  !future_vision_ten_years.length &&
                  strings.user_info.field_validation_error
                }
                useForcedRTL
              />

              <InputWithTitleAndValidation
                title={'6. ' + strings.user_info.life_purpose_earth_presence}
                autoCapitalize="none"
                minLength={5}
                underlineColorAndroid="transparent"
                placeholder={strings.user_info.type_here}
                onChangeText={setLife_purpose_earth_presence}
                value={life_purpose_earth_presence}
                errorText={
                  isSubmitting &&
                  !life_purpose_earth_presence.length &&
                  strings.user_info.field_validation_error
                }
                useForcedRTL
              />

              <InputWithTitleAndValidation
                title={'7. ' + strings.user_info.contribution_for_change}
                autoCapitalize="none"
                minLength={5}
                underlineColorAndroid="transparent"
                placeholder={strings.user_info.type_here}
                onChangeText={setContribution_for_change}
                value={contribution_for_change}
                errorText={
                  isSubmitting &&
                  !contribution_for_change.length &&
                  strings.user_info.field_validation_error
                }
                useForcedRTL
              />

              <View style={rs.marginBottom}>
                <Button
                  title={strings.user_info.submit}
                  onPress={handleOnSubmit}
                  disabled={isSubmitting}
                />
              </View>
            </View>
          </View>
        </>
      )}
    </KeyboardAwareScrollView>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    getUserCustomProfile: dispatch(userOperations.getUserCustomProfile),
    createUserCustomProfile: (params) => dispatch(userOperations.createUserCustomProfile(params)),
  }),
)(UserPersonalInsightsScreen);
