import ChatsList from './ChatsList';
import { Container, EmptyListWithImage } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chatsOperations, chatsSelectors } from '../../../../../store/chats';
import NavigationService from '../../../../../services/NavigationService.web';
import screens from '../../../../../navigation/screens';

const ChatArchivedListContainer = ({ onSelectChat, selectedChatIds, isEditMode = false }) => {
  const [isRefreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();
  const archivedChats = useSelector((state) => chatsSelectors.getArchivedChats(state));
  const isLoading = useSelector((state) => state.chats.archivedChats.isLoading);
  const isLoadingMore = useSelector((state) => chatsSelectors.getArchivedChats(state));

  const onOpen = (chatId) => {
    NavigationService.navigate(screens.ChatRoom, { chatId, isArchivedChats: true });
  };

  const onOpenChat = onSelectChat ?? onOpen;
  const getArchivedChats = (isLoadMore) => {
    dispatch(chatsOperations.getArchivedChats([], [], isLoadMore));
  };

  useEffect(() => {
    getArchivedChats();
  }, []);

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await getArchivedChats();
    setRefreshing(false);
  };

  return (
    <Container>
      <ChatsList
        isEditMode={isEditMode}
        selectedChatIds={selectedChatIds}
        isHideArchiveButton
        isLoading={isLoading}
        isLoadingMore={isLoadingMore}
        chatList={archivedChats}
        onOpenChat={onOpenChat}
        onOpenUserProfile={onOpenUserProfile}
        onLoadMore={() => getArchivedChats(true)}
        isRefreshing={isRefreshing}
        onRefresh={onRefresh}
        ListEmptyComponent={() => (
          <EmptyListWithImage image="empty_state_chats" text={strings.emptyLists.emptyChatList} />
        )}
      />
    </Container>
  );
};

export default ChatArchivedListContainer;
