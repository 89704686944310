import React, { memo } from 'react';
import T from 'prop-types';
import { View, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import s, { ViewHovered } from './styles';
import Item from '../ListItems/Lots/TripleColumnsLotListItem';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import { lotsOperations } from '../../../store/lots';
import { isAndroid } from '../../../utils/detectDevice';
import { isRTL } from '../../../utils/rtlHelper';
import { getHasActivePromotion } from '../../../store/promotion/selectors';
import { useGetWindowCurrentWidth } from '../../../utils/getWindowCurrentWidth';

const ANDROID_ITEMS_COUNT = 10;

const HorizontalItemsList = memo(
  ({ items, analyticsEventType, showFavoriteIcon, refToScroll, isWish }) => {
    const dispatch = useDispatch();
    const hasActivePromotion = useSelector(getHasActivePromotion);
    // https://github.com/facebook/react-native/issues/19150
    const data = isAndroid && isRTL ? items.slice(0, ANDROID_ITEMS_COUNT) : items;

    const { windowCurrentWidth } = useGetWindowCurrentWidth();

    const onSelect = (item) => {
      if (analyticsEventType) {
        AnalyticsService.logEvent(analyticsEventType);
      }

      if (isWish) {
        NavigationService.push(screens.WishDetails, { wishId: item.id });
      } else {
        NavigationService.push(screens.ItemDetails, { itemId: item.id });
      }
    };
    const onToggleFavorites = (item) => {
      const { mark_as_favorite } = item;

      if (mark_as_favorite) {
        dispatch(lotsOperations.removeItemFromFavorites(item.id));
      } else {
        dispatch(lotsOperations.addItemToFavorites(item.id));
      }
    };

    return (
      <View style={s.contentContainer}>
        <FlatList
          ref={refToScroll}
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={s.listContentContainerStyle}
          data={data}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item }) => {
            return (
              <View style={s.itemContainer}>
                <Item
                  lot={item}
                  onPress={onSelect}
                  onToggleFavorites={onToggleFavorites}
                  showFavoriteIcon={showFavoriteIcon}
                  hasActivePromotion={hasActivePromotion}
                  imageSize={windowCurrentWidth / 3}
                />
              </View>
            );
          }}
        />
      </View>
    );
  },
);

HorizontalItemsList.propTypes = {
  items: T.array,
  showFavoriteIcon: T.bool,
};

HorizontalItemsList.defaultProps = {
  showFavoriteIcon: true,
};

export default HorizontalItemsList;
