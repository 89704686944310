import React, { PureComponent } from 'react';
import T from 'prop-types';
import {
  CategoriesContainer,
  CategoriesList,
  CategoryButton,
  CheckIcon,
  styles,
} from './styles';
import { colors } from '../../../styles';
import FontIcon from '../FontIcon';
import { getCurrentTheme } from '../../../templates';
import Text from '../Text';

class Categories extends PureComponent {
  render() {
    const {
      parentCategories,
      selectedCategoryIds,
      onSelectCategory,
      onSelectCategoryObject,
      isShowCheckIcon = true,
    } = this.props;

    const currentTheme = getCurrentTheme();

    return (
      <CategoriesContainer>
        <CategoriesList
          data={parentCategories}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item }) => {
            const isActive = selectedCategoryIds.includes(item.id);
            // const isActive = Number(item.id) === Number(activeParentCategoryId);
            return (
              <CategoryButton
                onPress={
                  onSelectCategory
                    ? () => onSelectCategory(item.id)
                    : () => onSelectCategoryObject(item)
                }
              >
                {isActive && isShowCheckIcon && (
                  <CheckIcon
                    name="check-circle"
                    size={14}
                    color={currentTheme.colors.secondaryLight}
                  />
                )}
                <FontIcon
                  name={item.icon_name}
                  size={28}
                  color={isActive ? currentTheme.colors.secondaryLight : colors.text}
                />

                <Text
                  style={[
                    styles.categoryText,
                    { color: isActive ? currentTheme.colors.secondaryLight : colors.text },
                  ]}
                  xsmall
                  isActive={isActive}
                >
                  {item.name}
                </Text>
              </CategoryButton>
            );
          }}
        />
      </CategoriesContainer>
    );
  }
}

Categories.propTypes = {
  parentCategories: T.array,
  selectedCategoryIds: T.array,
};

export default Categories;
