import React, { useEffect } from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation, useRoute } from '@react-navigation/native';
import { connect, useSelector } from 'react-redux';

import strings from '../../../../localization';
import { colors, dimensions, rs } from '../../../../styles';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import locationsTypes from '../../../../constants/locationsTypes';
import { userLocationsOperations } from '../../../../store/userLocations';
import { LoadingService, PermissionService, ToastsService } from '../../../../services';
import { appOperations } from '../../../../store/app';
import { Text } from '../../../ReusableComponents';
import { getCityName } from './AddLocationGPSView';
import { getCurrentTheme } from '../../../../templates';

const LocationWelcome = ({
  createLocation,
  setLocationPermission,
  handleGeolocation,
  isLocationPermissionGranted,
  getLocationsByLatLng,
}) => {
  const navigation = useNavigation();
  const { params } = useRoute();

  const currentTheme = getCurrentTheme();
  const communityName = useSelector((state) => state.communityTheme.theme);

  useEffect(() => {
    navigation.setParams({ onSubmit: onSkip });
  }, []);

  const onSkip = () => {
    NavigationService.navigate(screens.WelcomeScreen, {
      rewardAmount: params?.rewardAmount,
    });
  };

  const getCoordinates = async () => {
    LoadingService.showLoader();
    const coordinates = await handleGeolocation();

    if (!coordinates) {
      ToastsService.showError();
      LoadingService.hideLoader();
      return;
    }

    const result = await getLocationsByLatLng(coordinates);
    LoadingService.hideLoader();
    if (result?.formatted_address) {
      const city = getCityName(result.address_components);
      const coordinate = {
        ...coordinates,
        city: city,
        formatted_address: result.formatted_address,
      };

      NavigationService.navigate(screens.AddLocationGPSView, {
        selectLocations: onSelectLocation,
        coordinates: coordinate,
      });
    } else {
      ToastsService.showError(strings.error_messages.error);
    }
  };

  const onSelectLocationByGPS = async () => {
    if (isLocationPermissionGranted) {
      await getCoordinates();
    } else {
      const isGrantedLocationPermission = await PermissionService.checkAndRequestGeolocationPermission(
        { requestAgainIfBlocked: true },
      );
      setLocationPermission(isGrantedLocationPermission);
      if (isGrantedLocationPermission) {
        await getCoordinates();
      }
    }
  };

  const onSelectLocation = async (location) => {
    await createLocation({ location, type: locationsTypes.USER });
    NavigationService.navigate(screens.WelcomeScreen, {
      rewardAmount: params?.rewardAmount,
      isSearchByPrimaryLocation: true,
    });
  };

  const onAddLocation = () => {
    NavigationService.navigate(screens.AddLocationGPSView, {
      selectLocations: onSelectLocation,
    });
  };

  return (
    <SafeAreaView style={s.wrapper}>
      <View>
        <Image
          style={[s.image, rs.bigMarginBottom]}
          source={{
            uri:
              communityName === strings.main.app_name.toLowerCase()
                ? 'gps_location'
                : 'find_your_friends_nearby',
          }}
          resizeMode="contain"
        />
      </View>
      <View>
        <Text bold title alignCenter style={[rs.veryBigMarginTop, rs.bigMarginHorizontal]}>
          {communityName === strings.main.app_name.toLowerCase()
            ? strings.locations.find_items_near_you
            : strings.locations.find_your_friends_nearby}
        </Text>
        <Text small alignCenter style={[rs.marginTop, rs.bigMarginHorizontal]}>
          {communityName === strings.main.app_name.toLowerCase()
            ? strings.locations.share_location
            : strings.locations.by_indication_your_address}
        </Text>
      </View>
      <View style={rs.marginVertical}>
        <TouchableOpacity
          onPress={onSelectLocationByGPS}
          style={[s.buttonContainer, { backgroundColor: currentTheme.colors.secondary }]}
        >
          <Text bold color={colors.white}>
            {strings.locations.share_location_button}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={onAddLocation} style={[s.buttonContainer, s.secondaryButton]}>
          <Text bold color={currentTheme.colors.secondary}>
            {strings.locations.add_location_button}
          </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};

const s = StyleSheet.create({
  buttonContainer: {
    height: 48,
    width: dimensions.width - 32,
    paddingHorizontal: dimensions.doubleMedium,
    marginTop: dimensions.medium,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 24,
    marginHorizontal: 16,
  },
  wrapper: {
    backgroundColor: colors.white,
    justifyContent: 'space-evenly',
    height: '100%',
  },
  image: {
    width: dimensions.width * 0.9,
    height: dimensions.height * 0.4,
    alignSelf: 'center',
  },
  secondaryButton: {
    backgroundColor: '#DCF3FB',
  },
});

export default connect(
  (state) => ({
    isLocationPermissionGranted: state.app.isLocationPermissionGranted,
  }),
  (dispatch) => ({
    handleGeolocation: () => dispatch(appOperations.handleGeolocation(false)),
    setLocationPermission: (isGranted) => dispatch(appOperations.setLocationPermission(isGranted)),
    getLocationsByLatLng: (args) => dispatch(appOperations.getLocationsByLatLng(args)),
    createLocation: ({ location, type }) =>
      dispatch(userLocationsOperations.createLocation({ location, type })),
  }),
)(LocationWelcome);
