import React from 'react';
import { pathOr } from 'ramda';
import { Animated, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { getAppLanguage } from '../../../../store/app/selectors';
import promotionInfo from '../../../../constants/promotionFAQInfo';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import s from './styles';
import {
  AnimatedOpacityHeader,
  Container,
  Separator,
  Text,
  TouchableItem,
  View,
} from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import strings from '../../../../localization';
import { getCurrentTheme } from '../../../../templates';

const PromotionInfoScreen = () => {
  const language = useSelector(getAppLanguage);
  const scrollY = new Animated.Value(0);

  const currentTheme = getCurrentTheme();

  const onOpenFAQ = () => {
    NavigationService.navigate(screens.FAQ);
  };

  const title = pathOr(promotionInfo.title.en, ['title', language], promotionInfo);
  const description = pathOr(
    promotionInfo.description.en,
    ['description', language],
    promotionInfo,
  );

  return (
    <Container>
      <AnimatedOpacityHeader scrollY={scrollY} title={title} />

      <Animated.ScrollView
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: true,
        })}
        scrollEventThrottle={8}
      >
        <View style={s.headerImageContainer}>
          <Image source={{ uri: 'green_friday' }} style={s.headerImage} />
        </View>

        <View style={s.sectionHeaderContainer}>
          <Text semiBold medium>
            {title}
          </Text>
        </View>

        <View style={[rs.paddingHorizontal, rs.backgroundWhite]}>
          <View style={rs.marginVertical}>
            <Text color={colors.gray} style={[s.descriptionText]}>
              {description}
            </Text>
          </View>
        </View>

        <View style={rs.paddingHorizontal}>
          <Separator />
        </View>

        <TouchableItem style={[rs.bigPaddingVertical, rs.paddingHorizontal]} onPress={onOpenFAQ}>
          <Text style={s.descriptionText}>
            {strings.support.for_anything_else_check_FAQ}{' '}
            <Text semiBold color={currentTheme.colors.secondary}>
              {strings.support.faq}
            </Text>
          </Text>
        </TouchableItem>
      </Animated.ScrollView>
    </Container>
  );
};

export default PromotionInfoScreen;
