import React, { useEffect } from 'react';
import { View, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Text, AutoWidthButton } from '../../ReusableComponents';
import s from './styles';
import strings from '../../../localization';
import { colors, rs } from '../../../styles';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import { isSmallDevice } from '../../../utils/detectDevice';
import { connect, useSelector } from 'react-redux';
import { lotsOperations } from '../../../store/lots';
import * as analyticsEventTypes from '../../../constants/analyticsEventTypes';
import { isRTL } from '../../../utils/rtlHelper';
import { communityOperations } from '../../../store/communityInfo';
import {
  getIsUSDiscoveryZone,
  getSchoolExtension,
  getUserInfo,
} from '../../../store/userInfo/selectors';
import { appOperations } from '../../../store/app';
import { homeOperations } from '../../../store/home';
import { getIsBrazilCommunity } from '../../../store/communityInfo/selectors';
import { sortingTypes } from '../../../constants/sortingTypes';
import { getCurrentTheme } from '../../../templates';

const Welcome = ({
  route,
  schoolExtension,
  isUSDiscoveryZone,
  isBrazilCommunity,
  setFeedLotFiltersWithoutFetchingItems,
  setFeedLotFilters,
  getUserCommunity,
  fetchAppData,
  itemsStartLoading,
  fetchChatsData,
  getCommunityHomePage,
  userInfo,
}) => {
  const isSearchByPrimaryLocation = route.params?.isSearchByPrimaryLocation ?? false;

  const currentTheme = getCurrentTheme();
  const communityName = useSelector((state) => state.communityTheme.theme);

  useEffect(() => {
    onFetchData();
  }, []);

  const onLetsGetStarted = async () => {
    if (isUSDiscoveryZone) {
      setFeedLotFiltersWithoutFetchingItems({ schoolExtension });
    } else {
      setFeedLotFiltersWithoutFetchingItems({ isOnlyVerifiedSellers: !isBrazilCommunity });
    }

    setFeedLotFilters({
      isSearchByPrimaryLocation,
      sortBy: sortingTypes.by_distance,
      withShipping: false,
    });

    AnalyticsService.logEvent(analyticsEventTypes.onboarding_welcome_start);

    if (communityName === strings.main.app_name.toLowerCase()) {
      return NavigationService.reset(screens.AuthorizedApplication, {
        screen: screens.TabNavigator,
      });
    }
    NavigationService.reset(screens.MySubCommunitiesStack, {
      screen: screens.JoinSubCommunityList,
      params: { isOnboarding: true },
    });
  };

  const onFetchData = async () => {
    itemsStartLoading();
    // temporary fix, because home tab page is not rendering for some reasons
    await getUserCommunity();
    fetchAppData();
    fetchChatsData();
    getCommunityHomePage();
  };

  return (
    <SafeAreaView style={s.contentContainer}>
      <View style={s.titleContainer}>
        <Text bold style={s.titleText} alignCenter>
          {strings.login.welcome_to}
          <Text color={currentTheme.colors.secondary} bold style={s.titleText}>
            {communityName === strings.main.app_name.toLowerCase()
              ? strings.main.app_name
              : strings.main.app_name_sharingood}
          </Text>
        </Text>
      </View>

      <Text large semiBold alignCenter style={rs.paddingHorizontal}>
        {communityName === strings.main.app_name.toLowerCase()
          ? strings.login.buy_without_spending_money
          : strings.login.connect_love_inspire}
      </Text>

      {communityName === strings.main.app_name_sharingood.toLowerCase() && (
        <Text medium semiBold alignCenter style={[rs.paddingHorizontal, rs.paddingVertical]}>
          {strings.login.create_our_new_reality}
        </Text>
      )}

      <Image
        style={s.image}
        source={{
          uri:
            communityName === strings.main.app_name.toLowerCase()
              ? isSmallDevice
                ? 'welcome_small'
                : 'welcome'
              : 'welcome_sharingood',
        }}
        resizeMode="contain"
      />

      <Text headline bold style={[rs.bigMarginBottom, rs.paddingHorizontal]} alignCenter>
        {communityName === strings.main.app_name.toLowerCase()
          ? strings.login.you_received
          : strings.login.by_completing_your_profile}{' '}
        <Text color={currentTheme.colors.secondary} bold headline>
          {userInfo.welcome_reward_amount}{' '}
          {communityName === strings.main.app_name.toLowerCase()
            ? strings.invite_friends.shareitt_points
            : strings.invite_friends.hearts_points}
        </Text>
        {isRTL ? ' ' : '\n'}
        {communityName === strings.main.app_name.toLowerCase() && strings.login.as_gift}
      </Text>

      <AutoWidthButton title={strings.login.lets_get_started} onPress={onLetsGetStarted} />
    </SafeAreaView>
  );
};

export default connect(
  (state) => ({
    isUSDiscoveryZone: getIsUSDiscoveryZone(state),
    isBrazilCommunity: getIsBrazilCommunity(state),
    schoolExtension: getSchoolExtension(state),
    userInfo: getUserInfo(state),
  }),
  (dispatch) => ({
    getCommunityHomePage: () => dispatch(homeOperations.getCommunityHomePage()),
    setFeedLotFiltersWithoutFetchingItems: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFiltersWithoutFetchingItems(feedLotFilters)),
    setFeedLotFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
    itemsStartLoading: () => dispatch(lotsOperations.itemsStartLoading()),
    getUserCommunity: () => dispatch(communityOperations.getUserCommunity()),

    fetchAppData: () => dispatch(appOperations.fetchAppData()),
    fetchChatsData: () => dispatch(appOperations.fetchChatsData()),
  }),
)(Welcome);
