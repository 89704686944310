import React, { useEffect } from 'react';
import { StyleSheet, View, LayoutAnimation, Platform } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  withRepeat,
  cancelAnimation,
} from 'react-native-reanimated';
import strings from '../../../../localization';
import { Text } from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';

const IMAGE_SIZE = 46;

const s = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: dimensions.doubleMedium * 2,
    zIndex: 1000,
    alignSelf: 'center',
    width: dimensions.width - 50,
    borderRadius: 12,
    backgroundColor: colors.white,
    ...Platform.select({
      ios: {
        shadowColor: colors.black,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,
      },
      android: {
        elevation: 4,
      },
    }),
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: dimensions.doubleMedium,
    marginVertical: dimensions.large,
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  textContainer: {
    height: IMAGE_SIZE,
    marginLeft: dimensions.medium,
  },
});

const LocationPopup = () => {
  const imageScale = useSharedValue(0.85);

  useEffect(() => {
    LayoutAnimation.easeInEaseOut();
    imageScale.value = withRepeat(withTiming(1, { duration: 800 }), -1, true);

    return () => {
      cancelAnimation(imageScale);
      LayoutAnimation.easeInEaseOut();
    };
  }, []);

  const imageAnimatedStyle = useAnimatedStyle(() => ({ transform: [{ scale: imageScale.value }] }), []);

  return (
    <Animated.View style={[s.container]}>
      <View style={s.content}>
        <Animated.Image
          source={{ uri: 'user_map_marker' }}
          resizeMode="contain"
          style={[s.image, imageAnimatedStyle]}
        />

        <View style={s.textContainer}>
          <Text semiBold large>
            {strings.locations.location_popup_title}
          </Text>
          <Text color={colors.gray} style={rs.smallMarginTop} semiBold medium>
            {strings.locations.location_popup_body_text}
          </Text>
        </View>
      </View>
    </Animated.View>
  );
};

export default LocationPopup;
