import * as R from 'ramda';
import Rate, { AndroidMarket } from 'react-native-rate';
import * as Sentry from '@sentry/react-native';
import offerApi from '../../api/offer';
import itemApi from '../../api/item';
import accountApi from '../../api/account';
import { lotsOperations } from '../lots';
import { walletsOperations } from '../wallets';
import { appOperations } from '../app';
import * as actions from './actions';
import offerSelectors from './selectors';
import { AnalyticsService, LoadingService, ModalsService, ToastsService } from '../../services';
import strings from '../../localization';
import modalTypes from '../../constants/modalTypes';
import { OFFERS_LIMIT } from '../../constants/listLimits';
import { CONFIRM_CANCEL_PURCHASE, OFFER_CONFIRMED, ITEM_SOLD } from './offerStatuses';
import { normalize } from '../../utils/stateHelper';
import { SELLER_CONFIRMED_OFFER, BUYER_CONFIRMED_DELIVERY } from './offerActions';
import { MISSING_TOKENS_ERROR_MESSAGE } from '../../constants/errorMessages';
import * as analyticsEventTypes from '../../constants/analyticsEventTypes';
import { APP_STORE_ID, PACKAGE_NAME } from '../../../config/identifiers';
import pWaitFor from 'p-wait-for';

export const createOffer = (itemId) => async (dispatch, getState) => {
  try {
    const offer = await offerApi.createOffer(itemId);
    dispatch(actions.addNewBuyingOffer({ offer }));
    dispatch(getOfferCounters());

    AnalyticsService.logEvent(analyticsEventTypes.ASK_TO_BUY, {
      offer_id: offer.id,
      seller_id: offer.seller_id,
      buyer_id: offer.buyer_id,
    });

    return offer;
  } catch (err) {
    if (R.path(['response', 'status'], err) === 402) {
      const { price, discounted_price, title, token_name, group_id } = await itemApi.getItemInfo(
        itemId,
      );

      ModalsService.showModal(modalTypes.TOKENS_EXCHANGE_PROMPT, {
        missing_amount: err.response.data.missing_amount,
        price: discounted_price || price,
        item_title: title,
        token_name,
        offer: {
          id: null,
          item_id: itemId,
          group_id,
        },
      });
    }

    if (R.path(['response', 'status'], err) === 406) {
      if (R.path(['response', 'data', 'message'], err) === MISSING_TOKENS_ERROR_MESSAGE) {
        ModalsService.showModal(modalTypes.MISSING_TOKENS);
      } else {
        ModalsService.showModal(modalTypes.ERROR, R.path(['response', 'data', 'payload'], err));
      }
      return;
    }
  }
};

export const updateOfferStatus = ({ offer_id, offer_status_id }) => (dispatch, getState) => {
  const { userInfo } = getState();

  dispatch(
    actions.updateOfferStatus({
      userId: userInfo.id,
      offerId: offer_id,
      offerStatusId: offer_status_id,
    }),
  );
};

export const updateOffer = (offerId) => async (dispatch, getState) => {
  const { offers } = getState();

  // if offer is in store, don't need to show loader, possibility that offer was changed very low
  const isShowLoader = R.isEmpty(R.pathOr({}, ['offerEntities', offerId], offers));

  try {
    if (isShowLoader) dispatch(actions.offerStart());
    const offer = await offerApi.getOffer(offerId);
    dispatch(actions.offerSuccess({ offer }));
  } catch (e) {
    dispatch(actions.offerError());
  }
};

export const getNewSellingOfferById = (offerId) => async (dispatch, getState) => {
  try {
    const offer = await offerApi.getOffer(offerId);
    dispatch(actions.addNewSellingOffer({ offer }));
  } catch (e) {}
};

export const getNewWishFulfillmentOfferById = (offerId) => async (dispatch, getState) => {
  try {
    const offer = await offerApi.getOffer(offerId);
    dispatch(actions.addNewWishFulfillmentOffer({ offer }));
  } catch (e) {}
};

export const performAction = (offerId, actionId, hasMultipleSupply = false) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { userInfo, offers, communityInfo } = state;
  const { offerEntities } = offers;

  try {
    const offer = await offerApi.performAction(offerId, actionId, hasMultipleSupply);
    const { id, status_id } = offer;

    if (parseInt(status_id) === OFFER_CONFIRMED && !hasMultipleSupply) {
      dispatch(lotsOperations.itemWasSold({ soldItemId: offer.item_id }));
    }

    if (actionId === SELLER_CONFIRMED_OFFER && !hasMultipleSupply) {
      const askedToBuySellingOffers = offerSelectors.getSellingAskedToBuyOffers(state);
      askedToBuySellingOffers.forEach((element) => {
        if (element.item_id === offer.item_id && element.id !== id) {
          dispatch(updateOfferStatus({ offer_id: element.id, offer_status_id: ITEM_SOLD }));
        }
      });
    }

    const isCurrentUserVerified = userInfo.user_credibility_details.is_verified;

    if (actionId === BUYER_CONFIRMED_DELIVERY) {
      const isStoreReviewAvailable = await offerApi.getIsStoreReviewAvailable();

      if (isStoreReviewAvailable) {
        Rate.rate(
          {
            AppleAppID: APP_STORE_ID,
            GooglePackageName: PACKAGE_NAME,
            preferredAndroidMarket: AndroidMarket,
            preferInApp: true,
            openAppStoreIfInAppFails: true,
          },
          (success, error) => {
            if (success) {
              AnalyticsService.logEvent(analyticsEventTypes.rate_app_was_shown);
            }

            if (error) {
              Sentry.captureException(error);
            }
          },
        );
      }

      if (isCurrentUserVerified) {
        const isSellerAlreadyEndorsed = await accountApi.getIsMemberAlreadyEndorsed(
          offer.seller_id,
          communityInfo.id,
        );

        if (!isSellerAlreadyEndorsed) {
          ModalsService.showFullScreenModal(modalTypes.ENDORSE_USER, { userId: offer.seller_id });
        }
      }
    }

    dispatch(updateOfferStatus({ offer_id: id, offer_status_id: status_id }));
    dispatch(walletsOperations.getWallets());
    dispatch(getOfferCounters());

    const analyticsEventType = {
      BUYER_ASKED_TO_BUY: analyticsEventTypes.ASK_TO_BUY,
      SELLER_CONFIRMED_OFFER: analyticsEventTypes.SEll_ITEM,
    }[actionId];

    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType, {
        offer_id: offerId,
        seller_id: offer.seller_id,
        buyer_id: offer.buyer_id,
      });
    }
  } catch (err) {
    if (R.path(['response', 'status'], err) === 402) {
      const { price, discounted_price, title, token_name, group_id } = await itemApi.getItemInfo(
        offerEntities[offerId].item_id,
      );
      ModalsService.showModal(modalTypes.TOKENS_EXCHANGE_PROMPT, {
        missing_amount: err.response.data.missing_amount,
        price: discounted_price || price,
        item_title: title,
        token_name,
        offer: {
          id: offerId,
          group_id,
        },
      });
    }

    if (R.path(['response', 'status'], err) === 403) {
      ToastsService.showError(strings.error_messages.error);
    }

    if (R.path(['response', 'status'], err) === 406) {
      if (R.path(['response', 'data', 'message'], err) === MISSING_TOKENS_ERROR_MESSAGE) {
        ModalsService.showModal(modalTypes.MISSING_TOKENS);
      } else {
        ModalsService.showModal(modalTypes.ERROR, R.path(['response', 'data', 'payload'], err));
      }
      return;
    }
    if (R.path(['response', 'status'], err) === 418) {
      let blockedUserId = '';
      const selectedOffer = offerEntities[offerId];
      if (selectedOffer) {
        blockedUserId =
          Number(selectedOffer.buyer_id) === Number(userInfo.id)
            ? selectedOffer.seller_id
            : selectedOffer.buyer_id;
      }
      ModalsService.showModal(modalTypes.UNBLOCK_USER, {
        userId: blockedUserId,
      });
    }
  }
};

export const performRefundBySeller = (offerId, refundType) => async (dispatch) => {
  try {
    LoadingService.showSuccessLoader();
    const offer = await offerApi.performRefundBySeller(offerId, refundType);
    const { id, status_id } = offer;

    dispatch(updateOfferStatus({ offer_id: id, offer_status_id: status_id }));

    LoadingService.hideSuccessLoader();
    ModalsService.hideModal();

    dispatch(walletsOperations.getWallets());
    dispatch(getOfferCounters());
  } catch (err) {
    LoadingService.hideSuccessLoader({ isSuccess: false });
    ModalsService.hideModal();

    if (R.path(['response', 'status'], err) === 406) {
      await pWaitFor(() => !ModalsService.getIsVisible());
      setTimeout(() => ModalsService.showModal(modalTypes.MISSING_TOKENS), 500);
    }
  }
};

/** BUYING OFFERS */

const getBuyingOffers = ({
  entityName,
  statusIds,
  searchTerm = '',
  groupIds = [],
  isLoadMore,
}) => async (dispatch, getState) => {
  const { offers } = getState();
  const { offerIds, isLoading, isLoadingMore, isExistMore } = offers.buyingOffers[entityName];

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.buyingOffersStart({ entityName, isLoadMore }));

  const skip = isLoadMore ? offerIds.length : 0;

  try {
    const data = await offerApi.getBuyingOffers({ statusIds, groupIds, skip, searchTerm });

    const normalizedOffers = normalize(data, 'offerIds', 'offerEntities');

    dispatch(
      actions.buyingOffersSuccess({
        ...normalizedOffers,
        entityName,
        isLoadMore,
        isExistMore: data.length === OFFERS_LIMIT,
      }),
    );
  } catch (err) {
    dispatch(actions.buyingOffersError({ entityName, isLoadMore }));
  }
};

const getBuyingAskedToBuyOffers = ({ isLoadMore = false }) => async (dispatch) => {
  await dispatch(
    getBuyingOffers({
      isLoadMore,
      statusIds: [CONFIRM_CANCEL_PURCHASE],
      entityName: 'askedToBuy',
    }),
  );
};

const getBuyingPendingDeliveryOffers = ({ isLoadMore = false }) => async (dispatch) => {
  await dispatch(
    getBuyingOffers({
      isLoadMore,
      statusIds: [OFFER_CONFIRMED],
      entityName: 'pendingDelivery',
    }),
  );
};

const getBuyingSearchedOffers = ({ searchTerm, groupIds, statusIds, isLoadMore = false }) => async (
  dispatch,
) => {
  await dispatch(
    getBuyingOffers({
      isLoadMore,
      statusIds: R.isEmpty(statusIds) ? [OFFER_CONFIRMED, CONFIRM_CANCEL_PURCHASE] : statusIds,
      entityName: 'searched',
      groupIds,
      searchTerm,
    }),
  );
};

/** SELLING OFFERS */

const getSellingOffers = ({
  entityName,
  statusIds,
  searchTerm = '',
  groupIds = [],
  isLoadMore,
}) => async (dispatch, getState) => {
  const { offers } = getState();
  const { offerIds, isLoading, isLoadingMore, isExistMore } = offers.sellingOffers[entityName];

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.sellingOffersStart({ entityName, isLoadMore }));

  const skip = isLoadMore ? offerIds.length : 0;

  try {
    const data = await offerApi.getSellingOffers({ statusIds, groupIds, skip, searchTerm });

    const normalizedOffers = normalize(data, 'offerIds', 'offerEntities');

    dispatch(
      actions.sellingOffersSuccess({
        ...normalizedOffers,
        entityName,
        isLoadMore,
        isExistMore: data.length === OFFERS_LIMIT,
      }),
    );
  } catch (err) {
    dispatch(actions.sellingOffersError({ entityName, isLoadMore }));
  }
};

const getSellingAskedToBuyOffers = ({ isLoadMore = false }) => async (dispatch) => {
  await dispatch(
    getSellingOffers({
      isLoadMore,
      statusIds: [CONFIRM_CANCEL_PURCHASE],
      entityName: 'askedToBuy',
      searchTerm: '',
    }),
  );
};

const getSellingPendingDeliveryOffers = ({ isLoadMore = false }) => async (dispatch) => {
  await dispatch(
    getSellingOffers({
      isLoadMore,
      statusIds: [OFFER_CONFIRMED],
      entityName: 'pendingDelivery',
      searchTerm: '',
    }),
  );
};

const getSellingSearchedOffers = ({
  searchTerm,
  groupIds,
  statusIds,
  isLoadMore = false,
}) => async (dispatch) => {
  await dispatch(
    getSellingOffers({
      isLoadMore,
      statusIds: R.isEmpty(statusIds) ? [OFFER_CONFIRMED, CONFIRM_CANCEL_PURCHASE] : statusIds,
      entityName: 'searched',
      groupIds,
      searchTerm,
    }),
  );
};

export const getActiveOfferByItemId = (itemId) => async (dispatch) => {
  try {
    dispatch(actions.offerStart());
    const offer = await offerApi.getActiveOfferByItemId(itemId);

    dispatch(actions.offerSuccess({ offer }));
  } catch (err) {
    dispatch(actions.offerError());
  }
};

export const getRecentOffers = () => async (dispatch, getState) => {
  const { app } = getState();
  const { lastAccessDateInCurrentSession } = app;

  if (!lastAccessDateInCurrentSession) return;

  try {
    const offers = await offerApi.getRecentOffers(lastAccessDateInCurrentSession);

    const normalizedOffers = normalize(offers, 'offerIds', 'offerEntities');
    dispatch(actions.updateOffers({ normalizedOffers }));

    offers.forEach((offer) =>
      dispatch(updateOfferStatus({ offer_id: offer.id, offer_status_id: offer.status_id })),
    );

    dispatch(appOperations.setLastAccessDateInCurrentSession());
  } catch (err) {}
};

export const getOfferCounters = () => async (dispatch) => {
  try {
    const data = await offerApi.getOffersCounters();
    dispatch(actions.setOfferCounters(data));
  } catch (err) {}
};

const getWishFulfillmentOffers = ({ isLoadMore }) => async (dispatch, getState) => {
  const { offers } = getState();
  const { offerIds, isLoading, isLoadingMore, isExistMore } = offers.wishFulfillmentOffers;

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.wishFulfillmentOffersStart({ isLoadMore }));

  const skip = isLoadMore ? offerIds.length : 0;

  try {
    const data = await offerApi.getWishFulfillmentOffers({
      skip,
    });

    const normalizedOffers = normalize(data, 'offerIds', 'offerEntities');

    dispatch(
      actions.wishFulfillmentOffersSuccess({
        ...normalizedOffers,
        isLoadMore,
        isExistMore: data.length === OFFERS_LIMIT,
      }),
    );
  } catch (err) {
    dispatch(actions.wishFulfillmentOffersError({ isLoadMore }));
  }
};

const archiveOffers = ({ selectedOffersIds }) => async (dispatch) => {
  try {
    await offerApi.archiveOffers({ selectedOffersIds });
    dispatch(actions.archiveOffers({ selectedOffersIds }));
  } catch (e) {}
};

const archiveAllOffers = () => async (dispatch) => {
  try {
    await offerApi.archiveAllOffers();
    dispatch(actions.archiveAllOffers());
  } catch (e) {}
};

const unarchiveOffers = ({ selectedOffersIds }) => async (dispatch) => {
  try {
    await offerApi.unarchiveOffers({ selectedOffersIds });
    dispatch(actions.unarchiveOffers({ selectedOffersIds }));
  } catch (e) {}
};

const getArchivedOffers = ({ isLoadMore = false }) => async (dispatch, getState) => {
  const { offers } = getState();
  const { offerIds, isLoading, isLoadingMore, isExistMore } = offers.sellingOffers.archived;

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) {
    return;
  }

  dispatch(actions.sellingOffersStart({ entityName: 'archived', isLoadMore }));

  const offset = isLoadMore ? offerIds.length : 0;

  try {
    const data = await offerApi.getArchivedOffers({ offset });

    const normalizedOffers = normalize(data, 'offerIds', 'offerEntities');

    dispatch(
      actions.sellingOffersSuccess({
        ...normalizedOffers,
        entityName: 'archived',
        isLoadMore,
        isExistMore: data.length === OFFERS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.sellingOffersError({ entityName: 'archived', isLoadMore }));
  }
};

export default {
  createOffer,
  performAction,
  updateOfferStatus,
  updateOffers: actions.updateOffers,
  performRefundBySeller,
  getNewSellingOfferById,
  getNewWishFulfillmentOfferById,
  updateOffer,
  getActiveOfferByItemId,
  getRecentOffers,

  getBuyingAskedToBuyOffers,
  getBuyingPendingDeliveryOffers,
  getBuyingSearchedOffers,

  getSellingAskedToBuyOffers,
  getSellingPendingDeliveryOffers,
  getSellingSearchedOffers,
  getOfferCounters,

  getWishFulfillmentOffers,

  archiveOffers,
  archiveAllOffers,
  unarchiveOffers,
  getArchivedOffers,
};
