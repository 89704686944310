import styled from 'styled-components/native';
import { colors, dimensions } from '../../../styles';

const InboxContainer = styled.View`
  flex: 1;
  background-color: ${colors.white};
  margin: ${dimensions.medium}px;
  box-shadow: rgba(0, 0, 0, 0.42) 0 0 10px;
`;

export default InboxContainer;
