import React, { memo } from 'react';
import { StyleSheet, View, Animated } from 'react-native';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { colors, dimensions, rs } from '../../styles';
import { Image, TouchableItem, Separator } from '../../components/ReusableComponents';
import UserPopover from './WebNavigationPopover';
import { NavigationService } from '../../services';
import { useGetWindowCurrentWidth } from '../../utils/getWindowCurrentWidth';
import screens from '../screens';
import strings from '../../localization';
import DrawerItemComponent from './DrawerItemComponent';
import { useSelector } from 'react-redux';
import { getIsUserAdmin } from '../../store/communityInfo/selectors';
import { getUserInfo } from '../../store/userInfo/selectors';
import FastImage from '../../components/ReusableComponents/FastImage';

const CustomDrawer = memo((props) => {
  const {
    toggleDrawer,
    selectedItem,
    setSelectedItem,
    scaleItemContainer,
    toggleDrawerWidth,
    toggleNotification,
    isOpenNotification,
  } = props;
  const { isMobileWindow } = useGetWindowCurrentWidth();
  const isCurrentUserAdmin = useSelector(getIsUserAdmin);
  const userInfo = useSelector(getUserInfo);

  const notificationsCount = useSelector((state) => state.notifications.unreadCount);
  const buyingOffersCount = useSelector(
    (state) => state.offers.offersCounters.active_buying_offers_count,
  );
  const sellingOffersCount = useSelector(
    (state) => state.offers.offersCounters.active_selling_offers_count,
  );
  const wishFulfillmentOffersCount = useSelector(
    (state) => state.offers.offersCounters.wishes_with_active_offers_count,
  );
  const chatsCount = useSelector((state) => state.chats.totalUnreadCount);
  const countSupportChats = useSelector((state) => state.chats.adminSupportChatsTotalUnreadCount);

  const options = [
    {
      key: 'marketplace',
      title: strings.items.marketplace,
      iconName: 'NavBar_marketPlace',
      screen: screens.MarketplaceTab,
      withAdminAccess: false,
    },
    {
      key: 'wishFulfillmentOffers',
      title: strings.profile.wish_fulfillment,
      iconName: 'Wish',
      screen: screens.WishFulfillmentOffers,
      count: wishFulfillmentOffersCount,
      withAdminAccess: false,
    },
    {
      key: 'inbox',
      title: strings.chats.inbox,
      iconName: 'NavBar_Inbox',
      screen: screens.Inbox,
      count: chatsCount,
      withAdminAccess: false,
    },
    {
      key: 'admin_support',
      title: `${strings.chats.admin_support} ${strings.chats.chats}`,
      iconName: 'NavBar_Inbox',
      // screen: screens.ChatRoom,
      count: countSupportChats,
      withAdminAccess: true,
    },
    {
      key: 'savedItems',
      title: strings.profile.my_saved_items,
      iconName: 'ouline_heart',
      screen: screens.SavedItems,
      withAdminAccess: false,
    },
    {
      key: 'sellingOffers',
      title: strings.profile.selling,
      iconName: 'Selling',
      screen: screens.SellingOffers,
      count: sellingOffersCount,
      withAdminAccess: false,
    },
    {
      key: 'buyingOffers',
      title: strings.profile.buying,
      iconName: 'Buying',
      screen: screens.BuyingOffers,
      count: buyingOffersCount,
      withAdminAccess: false,
    },
    {
      key: 'myItems',
      title: strings.buy_sell.my_items,
      iconName: 'Items',
      screen: screens.CurrentUserItems,
      withAdminAccess: false,
    },
    {
      key: 'support',
      title: strings.support.support,
      iconName: 'Menu_Support',
      screen: screens.Support,
      withAdminAccess: false,
    },
    {
      key: 'pendingItems',
      title: strings.pending_items.pending_items,
      iconName: 'pending_items',
      screen: screens.PendingItems,
      withAdminAccess: true,
    },
  ];
  const profileOption = {
    key: 'profile',
    title: strings.profile.profile,
    iconName: 'Profile-icon',
    screen: screens.Transactions,
    withAdminAccess: false,
  };

  if (!isMobileWindow) {
    options.splice(3, 0, {
      key: 'Notifications',
      title: strings.notifications.notifications,
      iconName: 'caution',
      screen: screens.Notifications,
      count: notificationsCount,
      withAdminAccess: false,
    });
  }

  const onLogoPress = () => {
    setSelectedItem(null);
    isMobileWindow && toggleDrawer();
    isOpenNotification && toggleNotification();
    isOpenNotification && toggleDrawerWidth();
    NavigationService.navigate(screens.Home);
  };

  const onAvatarPress = () => {
    setSelectedItem(null);
    isMobileWindow && toggleDrawer();
    isOpenNotification && toggleNotification();
    isOpenNotification && toggleDrawerWidth();
    NavigationService.navigate(screens.Transactions);
  };

  const handleItemPress = (route) => {
    setSelectedItem(route.screen);
    isMobileWindow && toggleDrawer();

    switch (route.key) {
      case screens.Notifications:
        if (isMobileWindow) {
          NavigationService.navigate(route.screen);
        } else {
          toggleNotification();
          toggleDrawerWidth();
        }
        break;
      case 'admin_support':
        NavigationService.navigate(screens.Inbox, { isAdminChats: true });
        break;
      default:
        NavigationService.navigate(route.screen);
        isOpenNotification && toggleNotification();
        isOpenNotification && toggleDrawerWidth();
        break;
    }
  };

  return (
    <Animated.View
      style={[
        s.wrapper,
        !isMobileWindow && {
          width: scaleItemContainer?.interpolate({
            inputRange: [0.5, 1],
            outputRange: [248, 465],
          }),
        },
      ]}
    >
      <DrawerContentScrollView
        {...props}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={s.contentContainer}
      >
        {!isMobileWindow && (
          <TouchableItem onPress={onLogoPress}>
            <Animated.View style={s.logoWrapper}>
              <Image
                style={[s.logo, isOpenNotification && s.logoWithOpenNotification]}
                source={{ uri: isOpenNotification ? 'logo' : 'text_logo' }}
                resizeMode="contain"
              />
              {!isOpenNotification && (
                <TouchableItem onPress={onAvatarPress}>
                  <FastImage
                    source={
                      userInfo.profile_image
                        ? { uri: userInfo.profile_image }
                        : require('../../assets/web/images/user_avatar_placeholder.png')
                    }
                    style={s.userAvatar}
                    resizeMode="cover"
                  />
                </TouchableItem>
              )}
            </Animated.View>
          </TouchableItem>
        )}

        <View style={[isMobileWindow ? s.itemContainerMobile : s.itemContainer]}>
          <View style={!isMobileWindow && rs.alignSelfCenter}>
            {options
              .filter(({ withAdminAccess }) => !withAdminAccess || isCurrentUserAdmin)
              .map((option) => (
                <DrawerItemComponent
                  selectedItem={selectedItem}
                  handleItemPress={handleItemPress}
                  option={option}
                  scaleItemContainer={scaleItemContainer}
                  isOpenNotification={isOpenNotification}
                />
              ))}
            <UserPopover
              scaleItemContainer={scaleItemContainer}
              isOpenNotification={isOpenNotification}
              type="post"
              toggleDrawer={toggleDrawer}
              toggleDrawerWidth={toggleDrawerWidth}
              toggleNotification={toggleNotification}
            />
          </View>

          <View>
            {isMobileWindow && (
              <View style={s.separator}>
                <Separator />
              </View>
            )}
            <UserPopover
              scaleItemContainer={scaleItemContainer}
              isOpenNotification={isOpenNotification}
              type="settings"
              toggleDrawer={toggleDrawer}
              toggleDrawerWidth={toggleDrawerWidth}
              toggleNotification={toggleNotification}
            />
            <UserPopover
              scaleItemContainer={scaleItemContainer}
              isOpenNotification={isOpenNotification}
              type="languages"
              toggleDrawer={toggleDrawer}
              toggleDrawerWidth={toggleDrawerWidth}
              toggleNotification={toggleNotification}
            />
            <DrawerItemComponent
              selectedItem={selectedItem}
              handleItemPress={handleItemPress}
              option={profileOption}
              scaleItemContainer={scaleItemContainer}
              isOpenNotification={isOpenNotification}
              style={!isOpenNotification && s.profileButton}
            />
          </View>
        </View>
      </DrawerContentScrollView>
    </Animated.View>
  );
});

export default CustomDrawer;

const s = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: colors.grayLighter,
    alignItems: 'flex-start',
  },
  contentContainer: {
    flex: 1,
  },
  itemContainer: {
    justifyContent: 'space-between',
    flex: 1,
    marginBottom: dimensions.medium,
  },
  itemContainerMobile: {
    flex: 1,
    marginTop: dimensions.medium,
  },
  logo: {
    width: 120,
    height: 48,
    marginLeft: dimensions.medium,
    marginBottom: dimensions.medium,
  },
  drawerItem: {
    width: 216,
    height: 48,
  },
  drawerItemMobile: {
    width: '100%',
  },
  activeDrawerIndicator: {
    position: 'absolute',
    width: 2,
    height: 64,
    backgroundColor: colors.secondary,
    zIndex: 1,
    left: 10,
    alignSelf: 'center',
    borderBottomRightRadius: dimensions.borderRadius,
    borderTopRightRadius: dimensions.borderRadius,
  },
  separator: {
    paddingHorizontal: dimensions.medium,
  },
  label: {
    marginLeft: -dimensions.medium,
    width: 200,
  },
  itemBackgroundColor: {
    backgroundColor: colors.grayLight,
  },
  logoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  logoWithOpenNotification: {
    width: 48,
    marginLeft: 10,
  },
  userAvatar: {
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  profileButton: {
    marginLeft: 3,
  },
});
