import React, { useState } from 'react';
import { I18nManager, Keyboard, ScrollView } from 'react-native';
import {
  InputWithTitleAndValidation,
  Container,
  View,
  Button,
  Image,
} from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import { AnalyticsService, NavigationService } from '../../../../services';
import strings from '../../../../localization';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';
import screens from '../../../../navigation/screens';
import { isIos, isIphoneX, isWeb } from '../../../../utils/detectDevice';
import Text from '../../../ReusableComponents/Text';
import { connect } from 'react-redux';
import { userOperations } from '../../../../store/userInfo';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';

const s = StyleSheet.create({
  image: {
    width: 150,
    height: 115,
    alignSelf: 'center',
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
  inputContainer: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingVertical: isIos ? dimensions.halfMedium : 0,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium * 2 : dimensions.medium,
  },
  infoText: {
    lineHeight: 22,
  },
  keyboardAvoidingView: {
    flex: 1,
    marginBottom: isIos ? dimensions.doubleMedium : 0,
  },
});

const AddUserName = ({ userInfo, updateUserInfo }) => {
  const [userName, setUserName] = useState(userInfo.is_default_name ? '' : userInfo.name);
  const [isValidName, setValidName] = useState(false);
  const isRTL = isWeb ? I18nManager.getConstants().isRTL : I18nManager.isRTL;

  const onSubmit = async () => {
    updateUserInfo({
      email: userInfo.email,
      userName,
      userAvatar: userInfo.profile_image,
      gender: userInfo.gender,
    });
    Keyboard.dismiss();
    AnalyticsService.logEvent(analyticsEventTypes.earn_points_add_name);
    NavigationService.goBack();
  };

  const onChangeUserName = (name) => {
    if (isValidName) {
      setUserName(name);
    }
  };

  const onKeyPressUserName = (event) => {
    // https://devunet.atlassian.net/browse/SHAREITT-1625
    // allow only alphabetic characters (English/Hebrew) and spaces
    const userNameRegExp = new RegExp(/^[a-zA-Z\u0590-\u05fe\s]+$/);
    const { key } = event.nativeEvent;
    const isValidKey = userNameRegExp.test(key) || key === 'Backspace';

    setValidName(isValidKey);
  };

  const isSaveButtonDisabled = !isValidName || userName.trim().length <= 1;

  return (
    <Container>
      <KeyboardAvoidingView
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
        keyboardVerticalOffset={isIos ? 65 : 0}
      >
        <ScrollView>
          <Image style={s.image} source={{ uri: 'add_name' }} resizeMode="contain" />

          <InputWithTitleAndValidation
            autoFocus
            inputContainerStyle={s.inputContainer}
            inputStyle={{ direction: isRTL ? 'rtl' : 'ltr' }}
            minLength={5}
            underlineColorAndroid="transparent"
            placeholder={strings.login.type}
            maxLength={25}
            onChangeText={onChangeUserName}
            onKeyPress={onKeyPressUserName}
            value={userName}
            useForcedRTL
          />

          <View style={rs.paddingHorizontal}>
            <Text style={s.infoText} color={colors.gray}>
              {strings.user_info.add_name_info}
            </Text>
          </View>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button title={strings.common.save} onPress={onSubmit} disabled={isSaveButtonDisabled} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default connect(
  (state) => ({
    userInfo: state.userInfo,
  }),
  (dispatch) => ({
    updateUserInfo: (params) => dispatch(userOperations.updateUserInfo(params)),
  }),
)(AddUserName);
