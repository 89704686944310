// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splide__arrow {
    background-color: #ceebf5;
    width: 50px;
    height: 50px;
}

.splide__arrows--rtl .splide__arrow--next {
    left: 0;
    right: auto;
}

.splide__arrows--rtl .splide__arrow--prev {
    left: auto;
    right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ReusableComponents/Banners/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,OAAO;IACP,WAAW;AACf;;AAEA;IACI,UAAU;IACV,QAAQ;AACZ","sourcesContent":[".splide__arrow {\n    background-color: #ceebf5;\n    width: 50px;\n    height: 50px;\n}\n\n.splide__arrows--rtl .splide__arrow--next {\n    left: 0;\n    right: auto;\n}\n\n.splide__arrows--rtl .splide__arrow--prev {\n    left: auto;\n    right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
