import React, { Fragment } from 'react';
import { FlatList } from 'react-native';
import { connect } from 'react-redux';
import { Text, TouchableItem, View, Separator, Image } from '../../../../ReusableComponents';
import SubCategoryItem from './components/SubCategoryItem';
import { useCollapsible } from '../../../../ReusableComponents/CollapsibleLib/useCollapsible';
import { AnimatedSection } from '../../../../ReusableComponents/CollapsibleLib/AnimatedSection';
import { colors, rs } from '../../../../../styles';
import screens from '../../../../../navigation/screens';
import strings from '../../../../../localization/index';
import { numberWithCommas } from '../../../../../utils/stringHelper';
import {
  getGroupInfoById,
  getParentCategoriesListByGroupWithCount,
} from '../../../../../store/groups/selectors';
import { setFeedLotFilters } from '../../../../../store/lots/operations';
import s from './styles';

const CategoryItem = ({
  name,
  itemsCount,
  id,
  isSelected,
  childCategories,
  onSelect,
  isCollapsible = false,
}) => {
  const { animatedHeight, onPress, onLayout } = useCollapsible();

  const onSelectAll = () => {
    onSelect(id);
  };

  return (
    <TouchableItem
      style={[s.itemContainer, isSelected && s.activeItemContainer]}
      useOpacity={false}
      onPress={isCollapsible ? onPress : onSelect}
    >
      <Fragment>
        <Text medium semiBold style={rs.verySmallMarginBottom} color={colors.darkCyan}>
          {name}
        </Text>
        <Text color={colors.darkCyan} style={rs.marginBottom}>
          {`${numberWithCommas(itemsCount)} ${strings.groups.active_items}`}
        </Text>

        {isCollapsible && (
          <AnimatedSection animatedHeight={animatedHeight} onLayout={onLayout}>
            <SubCategoryItem
              onSelect={onSelectAll}
              category={{
                id,
                name: strings.formatString(strings.lots.all_in_group, name),
                items_count: itemsCount,
              }}
              isSelectAll
            />

            {childCategories?.map((category) => (
              <SubCategoryItem onSelect={onSelect} category={category} />
            ))}
          </AnimatedSection>
        )}
        <Separator color={colors.grayBorder} style={s.separator} />
      </Fragment>
    </TouchableItem>
  );
};

const MultipleCategories = ({ categories, group, setFilters, navigation, route: { params } }) => {
  const { groupId, onSetCategory } = params;

  const onSubmit = (categoryIds) => {
    if (!!onSetCategory) {
      onSetCategory({
        groupId: [groupId],
        categoryId: categoryIds,
      });
      navigation.navigate(screens.SendNotifications);
    } else {
      setFilters([groupId], categoryIds);
      navigation.navigate(screens.Marketplace);
    }
  };

  const onSelectCategory = (categoryId) => {
    onSubmit([categoryId]);
  };

  const onSelectAllCategories = () => {
    onSubmit([]);
  };

  return (
    <FlatList
      data={categories}
      keyExtractor={({ id }) => id}
      style={rs.backgroundWhite}
      renderItem={({ item }) => {
        return (
          <CategoryItem
            name={item.name}
            itemsCount={item.items_count}
            id={item.id}
            onSelect={onSelectCategory}
            childCategories={item.childCategories}
            isCollapsible
          />
        );
      }}
      ListHeaderComponent={
        <Fragment>
          <View style={s.groupInfoContainer}>
            <Image
              style={s.groupImage}
              resizeMode="cover"
              source={{ uri: group.background_image }}
            />

            <Text color={colors.text} large semiBold>
              {group.group_name}
            </Text>
          </View>

          <CategoryItem
            name={strings.formatString(strings.lots.all_in_group, group.group_name)}
            itemsCount={group.active_items_count}
            onSelect={onSelectAllCategories}
            isCollapsible={false}
          />
        </Fragment>
      }
    />
  );
};

export default connect(
  (state, { route }) => {
    const groupId = route.params.groupId;

    return {
      categories: getParentCategoriesListByGroupWithCount(state, groupId),
      group: getGroupInfoById(state, groupId),
    };
  },
  (dispatch) => ({
    setFilters: (groupIds, selectedCategoryIds) =>
      dispatch(setFeedLotFilters({ groupIds, selectedCategoryIds })),
  }),
)(MultipleCategories);
