import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import Text from './Text';
import TouchableItem from './TouchableItem';
import { FOLLOWERS } from '../../constants/followTypes';
import strings from '../../localization';
import { colors, fontSizes, dimensions, rs } from '../../styles';
import { NavigationService } from '../../services';
import screens from '../../navigation/screens';
import { FOLLOWINGS } from '../../constants/followTypes';
import T from 'prop-types';
import * as R from 'ramda';
import { FontIcon } from './index';
import { useGetWindowCurrentWidth } from '../../utils/getWindowCurrentWidth';
import { isWeb } from '../../utils/detectDevice';

const s = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    width: '74%',
    justifyContent: 'space-around',
  },
  button: {
    alignItems: 'center',
    width: '26%',
  },
  locationButton: {
    alignItems: 'center',
    width: '32%',
  },
  primaryLocation: {
    maxWidth: '66%',
    textAlign: 'center',
  },
  otherLocationsLabel: {
    backgroundColor: colors.grayLighter,
    borderRadius: 3,
    paddingHorizontal: 3,
    marginHorizontal: 2,
  },
  valueContainer: {
    height: 23,
  },
  locationIcon: {
    marginBottom: 4,
  },
  verticalSeparator: {
    height: '100%',
    width: dimensions.hairline,
    backgroundColor: colors.grayBorder,
  },
});

const UserFollowersButtons = ({
  followersCount,
  followingsCount,
  name,
  userId,
  isWhite,
  isShipper,
  userLocations,
  isCurrentUser,
}) => {
  const onOpenFollowers = () => {
    NavigationService.push(screens.Followers, {
      type: FOLLOWERS,
      followingsTotalCount: followingsCount,
      followersTotalCount: followersCount,
      userId,
      name,
    });
  };

  const { windowCurrentWidth } = useGetWindowCurrentWidth();
  const onOpenFollowings = () => {
    NavigationService.push(screens.Followers, {
      type: FOLLOWINGS,
      followingsTotalCount: followingsCount,
      followersTotalCount: followersCount,
      userId,
      name,
    });
  };

  const onOpenLocations = () => {
    NavigationService.navigate(screens.MapWithUserLocations, { userLocations });
  };

  const onAddLocation = () => {
    NavigationService.navigate(screens.UserLocations);
  };

  const primaryLocation = userLocations ? userLocations.find(({ is_primary }) => is_primary) : null;
  const otherLocationsCount = userLocations
    ? userLocations.filter(({ is_primary }) => !is_primary).length
    : null;

  return (
    <View style={s.buttonsContainer}>
      <TouchableItem onPress={onOpenFollowers} style={s.button}>
        <Text bold medium color={isWhite ? colors.white : colors.text} style={s.valueContainer}>
          {followersCount}
        </Text>
        <Text color={isWhite ? colors.white : colors.text}>{strings.follow.followers}</Text>
      </TouchableItem>

      <TouchableItem onPress={onOpenFollowings} style={s.button}>
        <Text bold medium color={isWhite ? colors.white : colors.text} style={s.valueContainer}>
          {followingsCount}
        </Text>
        <Text color={isWhite ? colors.white : colors.text}>{strings.follow.followings}</Text>
      </TouchableItem>

      {userLocations && !R.isEmpty(userLocations) && (
        <Fragment>
          <TouchableItem onPress={onOpenLocations} style={s.locationButton}>
            <View style={[rs.row, s.valueContainer]}>
              <FontIcon
                color={isWhite ? colors.white : colors.black}
                size={16}
                name="Location_Pin"
              />
              {isShipper && (
                <FontIcon
                  color={isWhite ? colors.white : colors.black}
                  size={19}
                  name="scooter"
                  style={rs.smallMarginLeft}
                />
              )}
            </View>

            <View style={[rs.row, rs.justifyCenter, rs.alignCenter]}>
              <Text
                style={[s.primaryLocation, isWeb && { width: windowCurrentWidth * 0.25 }]}
                numberOfLines={1}
                color={isWhite ? colors.white : colors.text}
              >
                {primaryLocation ? primaryLocation.city : strings.profile.locations}
              </Text>
              {!!otherLocationsCount && (
                <View style={s.otherLocationsLabel}>
                  <Text xsmall color={colors.text}>{`+${otherLocationsCount}`}</Text>
                </View>
              )}
            </View>
          </TouchableItem>
        </Fragment>
      )}

      {R.isEmpty(userLocations) && isCurrentUser && (
        <Fragment>
          <TouchableItem onPress={onAddLocation} style={s.locationButton}>
            <View style={[rs.row, rs.alignCenter, rs.verySmallMarginBottom]}>
              <FontIcon
                color={isWhite ? colors.white : colors.black}
                size={16}
                name="Location_Pin"
                style={rs.verySmallMarginBottom}
              />
              <Text medium color={isWhite ? colors.white : colors.text}>
                +
              </Text>
            </View>

            <View style={[rs.row, rs.justifyCenter]}>
              <Text
                style={s.primaryLocation}
                numberOfLines={1}
                color={isWhite ? colors.white : colors.text}
              >
                {strings.user.add_location}
              </Text>
            </View>
          </TouchableItem>
        </Fragment>
      )}
    </View>
  );
};

UserFollowersButtons.propTypes = {
  followersCount: T.number,
  followingsCount: T.number,
  name: T.string,
  userId: T.string,
  isWhite: T.bool,
  isCurrentUser: T.bool,
};

export default UserFollowersButtons;
