export const SET_OFFERS = 'offers/SET_OFFERS';
export const ADD_NEW_BUYING_OFFER = 'offers/ADD_NEW_BUYING_OFFER';
export const ADD_NEW_SELLING_OFFER = 'offers/ADD_NEW_SELLING_OFFER';
export const ADD_NEW_WISH_FULFILLMENT_OFFER = 'offers/ADD_NEW_WISH_FULFILLMENT_OFFER';
export const UPDATE_OFFER_STATUS = 'offers/UPDATE_OFFER_STATUS';
export const UPDATE_OFFER = 'offers/UPDATE_OFFER';
export const UPDATE_OFFERS = 'offers/UPDATE_OFFERS';

export const OFFER_START = 'offers/OFFER_START';
export const OFFER_SUCCESS = 'offers/OFFER_SUCCESS';
export const OFFER_ERROR = 'offers/OFFER_ERROR';

export const BUYING_OFFERS_START = 'offers/BUYING_OFFERS_START';
export const BUYING_OFFERS_SUCCESS = 'offers/BUYING_OFFERS_SUCCESS';
export const BUYING_OFFERS_ERROR = 'offers/BUYING_OFFERS_ERROR';

export const SELLING_OFFERS_START = 'offers/SELLING_OFFERS_START';
export const SELLING_OFFERS_SUCCESS = 'offers/SELLING_OFFERS_SUCCESS';
export const SELLING_OFFERS_ERROR = 'offers/SELLING_OFFERS_ERROR';

export const WISH_FULFILLMENT_OFFERS_START = 'offers/WISH_FULFILLMENT_OFFERS_START';
export const WISH_FULFILLMENT_OFFERS_SUCCESS = 'offers/WISH_FULFILLMENT_OFFERS_SUCCESS';
export const WISH_FULFILLMENT_OFFERS_ERROR = 'offers/WISH_FULFILLMENT_OFFERS_ERROR';

export const SET_OFFER_COUNTERS = 'offers/SET_OFFER_COUNTERS';

export const ARCHIVE_OFFERS = 'offers/ARCHIVE_OFFERS';
export const ARCHIVE_ALL_OFFERS = 'offers/ARCHIVE_ALL_OFFERS';
export const UNARCHIVE_OFFERS = 'offers/UNARCHIVE_OFFERS';
