import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FlatList } from 'react-native';
import { Container, EmptyList, HeaderContainer, Text } from '../../../ReusableComponents';
import CampusItem from './components/CampusItem';
import AdditionalItem from './components/AdditionalItem';
import { rs } from '../../../../styles';
import strings from '../../../../localization';
import { lotsOperations } from '../../../../store/lots';
import {
  LoadingService,
  NavigationService,
  PermissionService,
  ToastsService,
} from '../../../../services';
import { campusesSelectors, campusesOperations } from '../../../../store/campuses';
import { useDebouncedCallback } from 'use-debounce';
import Spinner from '../../../ReusableComponents/Spinner';
import SearchBar from '../../../ReusableComponents/SearchBar';
import screens from '../../../../navigation/screens';
import { userLocationsSelectors } from '../../../../store/userLocations';
import { appOperations } from '../../../../store/app';
import { sortingTypes } from '../../../../constants/sortingTypes';
import { getIsUSDiscoveryZone, getSchoolExtension } from '../../../../store/userInfo/selectors';
import { isWeb } from '../../../../utils/detectDevice';

const CampusesFilter = ({
  isLoading,
  isLoadingMore,
  campuses,
  schoolExtension,
  getCommunityCampuses,
  setFeedLotFilters,
  isLocationPermissionGranted,
  handleGeolocation,
  handleGeolocationWeb,
  setLocationPermission,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    return () => {
      getCommunityCampuses({ isLoadMore: false });
    };
  }, []);

  const [getOffersDebounce] = useDebouncedCallback((newSearchTerm) => {
    getCommunityCampuses({
      searchTerm: newSearchTerm,
    });
  }, 400);

  const onLoadMore = () => {
    getCommunityCampuses({
      isLoadMore: true,
      searchTerm,
    });
  };

  const onChangeSearch = async (value) => {
    setSearchTerm(value);
    getOffersDebounce(value);
  };

  const onSelect = (campus) => {
    setFeedLotFilters({
      selectedCampuses: [campus],
      isSearchByPrimaryLocation: false,
      coordinates: null,
      schoolExtension: null,
    });
    NavigationService.navigate(screens.MarketplaceTab, {
      screen: screens.Marketplace,
    });
  };

  const getCoordinatesAndFetchItems = async () => {
    LoadingService.showLoader();
    const coordinates = isWeb ? await handleGeolocationWeb() : await handleGeolocation();
    LoadingService.hideLoader();

    if (!coordinates) {
      ToastsService.showError();
      return;
    }
    setFeedLotFilters({
      selectedCampuses: [],
      isSearchByPrimaryLocation: false,
      coordinates,
      sortBy: sortingTypes.by_distance,
      schoolExtension: null,
    });
    NavigationService.navigate(screens.MarketplaceTab, {
      screen: screens.Marketplace,
    });
  };

  const onSelectNearMe = async () => {
    if (isWeb) {
      await getCoordinatesAndFetchItems();
    } else {
      if (isLocationPermissionGranted) {
        await getCoordinatesAndFetchItems();
      } else {
        const isGrantedLocationPermission = await PermissionService.checkAndRequestGeolocationPermission(
          { requestAgainIfBlocked: true },
        );
        setLocationPermission(isGrantedLocationPermission);
        if (isGrantedLocationPermission) {
          await getCoordinatesAndFetchItems();
        }
      }
    }
  };

  const onSelectNearCampus = () => {
    setFeedLotFilters({
      schoolExtension,
      selectedCampuses: [],
      coordinates: null,
      sortBy: sortingTypes.by_newest,
    });

    NavigationService.navigate(screens.MarketplaceTab, {
      screen: screens.Marketplace,
    });
  };

  return (
    <Container>
      <HeaderContainer withoutBorderBottom>
        <SearchBar
          showCancel
          alwaysShowCancel
          value={searchTerm}
          placeholder={strings.other.search}
          onCancel={() => NavigationService.goBack()}
          onChangeText={onChangeSearch}
          enablesReturnKeyAutomatically
          returnKeyType="search"
        />
      </HeaderContainer>

      <FlatList
        keyExtractor={(element) => element.id.toString()}
        keyboardShouldPersistTaps={'handled'}
        data={isLoading ? [] : campuses}
        initialNumToRender={20}
        onEndReachedThreshold={0.5}
        onEndReached={onLoadMore}
        ListEmptyComponent={
          isLoading ? <Spinner /> : <EmptyList text={strings.emptyLists.empty_cities_list} />
        }
        ListHeaderComponent={
          <Fragment>
            <AdditionalItem
              image="explore_nearby"
              title={strings.marketplace.explore_near_current_location}
              onPress={onSelectNearMe}
            />

            <AdditionalItem
              image="explore_near_home"
              title={strings.marketplace.explore_campus}
              onPress={onSelectNearCampus}
            />

            <Text
              style={[rs.bigPaddingTop, rs.paddingBottom, rs.paddingHorizontal]}
              medium
              semiBold
            >
              {strings.marketplace.popular_campuses}
            </Text>
          </Fragment>
        }
        renderItem={({ item, index }) => <CampusItem item={item} onPress={onSelect} />}
        ListFooterComponent={isLoadingMore && <Spinner />}
      />
    </Container>
  );
};

export default connect(
  (state) => ({
    isLocationPermissionGranted: state.app.isLocationPermissionGranted,
    campuses: campusesSelectors.getCommunityCampuses(state),
    isLoading: state.campuses.isLoading,
    isLoadingMore: state.campuses.isLoadingMore,
    hasPrimaryLocation: userLocationsSelectors.hasPrimaryLocation(state),
    schoolExtension: getSchoolExtension(state),
    isUSDiscoveryZone: getIsUSDiscoveryZone(state),
  }),
  (dispatch) => ({
    setFeedLotFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
    handleGeolocation: () => dispatch(appOperations.handleGeolocation()),
    handleGeolocationWeb: () => dispatch(appOperations.handleGeolocationWeb()),
    setLocationPermission: (isGranted) => dispatch(appOperations.setLocationPermission(isGranted)),
    getCommunityCampuses: ({ searchTerm, isLoadMore }) =>
      dispatch(
        campusesOperations.getCommunityCampuses({
          searchTerm,
          isLoadMore,
        }),
      ),
  }),
)(CampusesFilter);
