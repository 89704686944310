import React, { memo, useState, useEffect, useRef, Fragment } from 'react';
import { View, StyleSheet } from 'react-native';
import MapView from 'react-native-maps';
import strings from '../../../../localization';
import { AnalyticsService } from '../../../../services';
import * as analyticEventTypes from '../../../../constants/analyticsEventTypes';
import { TouchableItem, Text, FontIcon } from '../../../ReusableComponents';
import ThreeDotsLoader from '../../../ReusableComponents/Loaders/ThreeDots';
import UserMarker from './UserMarker';
import UserCard from './UserCard';
import LocationPopup from './LocationPopup';
import { rs, dimensions, colors } from '../../../../styles';
import customMapStyle from '../../../../constants/googleMapCustomStyles';
import { isWeb } from '../../../../utils/detectDevice';

const SEARCH_BUTTON_WIDTH = 170;

const s = StyleSheet.create({
  searchButton: {
    position: 'absolute',
    top: dimensions.doubleMedium,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,

    width: SEARCH_BUTTON_WIDTH,
    height: 50,
    borderRadius: 25,
    backgroundColor: colors.white,
    flexDirection: 'row',
    ...rs.shadow,
  },
});

const UsersMapView = memo(
  ({
    initialLocation,
    isLocationPermissionGranted,
    handleGeolocation,
    getUsersForMap,
    onOpenUserProfile,
  }) => {
    const mapRef = useRef(null);
    let popupTimeout = null;

    const [visibleUsers, setVisibleUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(initialLocation);

    useEffect(() => {
      onMapLoad();

      return () => {
        clearTimeout(popupTimeout);
      };
    }, []);

    const onRegionChangeComplete = (location) => {
      if (!isLoading) {
        setCurrentLocation(location);
      }
    };

    const onSearchUsers = async () => {
      setSelectedUser(null);
      setVisibleUsers([]);
      setLoading(true);

      const newUsers = await getUsersForMap(currentLocation);
      setVisibleUsers(newUsers);

      setLoading(false);
    };

    const onMapLoad = async () => {
      setLoading(true);
      let coordinates = currentLocation;

      if (isLocationPermissionGranted) {
        popupTimeout = setTimeout(() => setPopupVisible(true), 3000);
        const userLocation = await handleGeolocation();

        if (userLocation) {
          coordinates = {
            ...userLocation,
            latitudeDelta: 0.2,
            longitudeDelta: 0.2,
          };

          setCurrentLocation(coordinates);
          mapRef?.current?.animateToRegion(coordinates);
        }
      }

      const newUsers = await getUsersForMap(coordinates);
      setVisibleUsers(newUsers);

      setLoading(false);

      clearTimeout(popupTimeout);
      setPopupVisible(false);
    };

    const onUserMarkerPress = (user) => {
      const isAlreadySelected = selectedUser?.id === user.id;

      if (!isAlreadySelected) {
        setSelectedUser(user);

        AnalyticsService.logEvent(analyticEventTypes.users_map_highlight_user_avatar);
      } else {
        setSelectedUser(null);
      }
    };

    const onMapPress = (event) => {
      if (event?.nativeEvent?.action !== 'marker-press' && selectedUser) {
        setSelectedUser(null);
      }
    };

    return (
      <View style={rs.flex}>
        <TouchableItem
          style={s.searchButton}
          onPress={onSearchUsers}
          disabled={isLoading}
          useOpacity={false}
        >
          <Fragment>
            {isLoading ? (
              <ThreeDotsLoader size={isWeb ? 50 : 30} />
            ) : (
              <Fragment>
                <FontIcon
                  name="System_msgs_refund"
                  color={colors.text}
                  size={22}
                  style={rs.smallMarginRight}
                />
                <Text semiBold>{strings.locations.map_search_placeholder}</Text>
              </Fragment>
            )}
          </Fragment>
        </TouchableItem>

        <MapView
          ref={mapRef}
          initialRegion={initialLocation}
          onRegionChangeComplete={onRegionChangeComplete}
          onPress={onMapPress}
          // zoomTap causes delays before MapView.onPress() event capture
          // unfortunately, there are no workarounds for Apple Maps (Google Maps only)
          // zoomTapEnabled={false}
          style={rs.flex}
          customMapStyle={customMapStyle}
          moveOnMarkerPress={false}
        >
          {visibleUsers.map((user) => {
            const onPress = () => {
              onUserMarkerPress(user);
            };

            const isActive = selectedUser?.id === user.id;

            return (
              <UserMarker
                key={user.id}
                id={user.id}
                profileImage={user.profile_image}
                location={user.location}
                onPress={onPress}
                isActive={isActive}
              />
            );
          })}
        </MapView>

        <UserCard user={selectedUser} onOpenUserProfile={onOpenUserProfile} />
        {isPopupVisible && <LocationPopup />}
      </View>
    );
  },
);

export default UsersMapView;
