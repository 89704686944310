import React from 'react';
import { Image, View } from 'react-native';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import AngleIcon from '../AngleIcon';
import { dimensions, colors, rs } from '../../../styles';
import { StyleSheet } from 'react-native';
import strings from '../../../localization';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import Icon from '../Icon';
import { useGetWindowCurrentWidth } from '../../../utils/getWindowCurrentWidth';
import { isWeb } from '../../../utils/detectDevice';
import { getCurrentTheme } from '../../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    height: 70,
    paddingBottom: dimensions.medium,
    paddingLeft: dimensions.medium,
    paddingRight: dimensions.doubleMedium,
    borderBottomWidth: 4,
    borderColor: '#EFF1F2',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: dimensions.doubleMedium,
  },
  text: {
    lineHeight: 22,
  },
  image: {
    height: 64,
    width: 100,
  },

  closeIconButton: {
    position: 'absolute',
    top: dimensions.halfMedium,
    right: dimensions.halfMedium,
  },
});

const EarnPointsBanner = ({ analyticsEventType, onClose, pointsName }) => {
  const currentTheme = getCurrentTheme();

  const { windowCurrentWidth } = useGetWindowCurrentWidth();
  const onPress = () => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    NavigationService.navigate(screens.EarnTokens);
  };
  return (
    <TouchableItem onPress={onPress}>
      <View style={[s.contentContainer, isWeb && { width: '98%', minWidth: 400 }]}>
        <Text medium semiBold style={[s.text, rs.paddingHorizontal]}>
          {strings.rewards.get_more_tokens}{' '}
          <Text color={currentTheme.colors.secondary} medium semiBold style={s.text}>
            {pointsName}
          </Text>
        </Text>
      </View>

      {!!onClose && (
        <TouchableItem onPress={onClose} style={s.closeIconButton}>
          <Icon name={'x'} size={16} color={colors.grayBorder} />
        </TouchableItem>
      )}
    </TouchableItem>
  );
};

export default EarnPointsBanner;
