import { StyleSheet } from 'react-native';
import { fontSizes } from '../../../../styles/index';

const styles = StyleSheet.create({
  backButtonContainer: {
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  cancelTitleText: {
    fontSize: fontSizes.medium - 1,
  },
});

export { styles };
