import React, { Fragment } from 'react';
import { ScrollView } from 'react-native';
import s from './styles';
import { View, TouchableItem, Container, Text } from '../../../ReusableComponents/index';
import { colors, rs } from '../../../../styles';
import strings from '../../../../localization/index';
import { NavigationService } from '../../../../services';
import { getCurrentTheme } from '../../../../templates';

const SizeItem = ({ size, isSelected, onSelectSize }) => {
  const currentTheme = getCurrentTheme();
  return (
    <TouchableItem
      style={[s.itemContainer, isSelected && { backgroundColor: currentTheme.colors.secondary }]}
      useOpacity={false}
      onPress={() => {
        onSelectSize(size.id);
        NavigationService.pop();
      }}
    >
      <Fragment>
        <Text style={rs.verySmallMarginVertical} color={isSelected ? colors.white : colors.gray}>
          {size.title}
        </Text>
      </Fragment>
    </TouchableItem>
  );
};

const NoSizeItem = ({ size, isSelected, onSelectSize }) => {
  const currentTheme = getCurrentTheme();
  return (
    <TouchableItem
      style={[rs.paddingHorizontal, rs.paddingVertical]}
      onPress={() => {
        onSelectSize(size.id);
        NavigationService.pop();
      }}
    >
      <Fragment>
        <Text color={isSelected ? currentTheme.colors.secondary : colors.gray}>{size.title}</Text>
      </Fragment>
    </TouchableItem>
  );
};

const Sizes = ({ route }) => {
  const sizes = route.params.sizes;
  const selectedSizeId = route.params.selectedSizeId;
  const onSelectSize = route.params.onSelectSize;

  return (
    <Container>
      <View style={s.noSizeContainer}>
        <NoSizeItem
          isSelected={selectedSizeId === null}
          size={{ title: strings.lots.no_size, id: null }}
          onSelectSize={onSelectSize}
        />
      </View>
      <ScrollView keyboardShouldPersistTaps="handled">
        <View style={s.sizesContainer}>
          {sizes.map((size) => (
            <SizeItem
              key={size.id}
              size={size}
              isSelected={size.id === selectedSizeId}
              onSelectSize={onSelectSize}
            />
          ))}
        </View>
      </ScrollView>
    </Container>
  );
};

export default Sizes;
