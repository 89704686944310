import React, { Fragment } from 'react';
import T from 'prop-types';
import { StyleSheet, FlatList, View } from 'react-native';
import { colors, dimensions } from '../../../../../../../styles';
import { TouchableItem, Text } from '../../../../../../ReusableComponents';
import { F } from 'ramda';
import { getCurrentTheme } from '../../../../../../../templates';

const s = StyleSheet.create({
  container: {
    marginLeft: dimensions.halfMedium,
  },
  verticalSeparator: {
    height: '100%',
    width: dimensions.hairline,
    backgroundColor: colors.grayBorder,
    marginHorizontal: dimensions.halfMedium,
  },
});

const HorizontalCitiesList = ({
  items,
  selectedItem,
  withEllipsize,
  ellipsizedItemsCount,
  onPressItem,
}) => {
  const currentTheme = getCurrentTheme();
  return (
    <Fragment>
      <FlatList
        data={items}
        keyExtractor={({ id }) => id.toString()}
        renderItem={({ item }) => {
          return (
            <TouchableItem onPress={() => onPressItem(item)}>
              <Text color={item.id === selectedItem.id ? currentTheme.colors.secondary : colors.gray}>
                {item.city}
              </Text>
            </TouchableItem>
          );
        }}
        ItemSeparatorComponent={() => <View style={s.verticalSeparator} />}
        contentContainerStyle={s.container}
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
        horizontal
      />
      {withEllipsize && !!ellipsizedItemsCount && (
        <Fragment>
          <View style={s.verticalSeparator} />
          <Text color={currentTheme.colors.secondary}>{`+${ellipsizedItemsCount}`}</Text>
        </Fragment>
      )}
    </Fragment>
  );
};

HorizontalCitiesList.propTypes = {
  items: T.arrayOf(
    T.shape({
      id: T.oneOfType([T.number, T.string]),
      city: T.string,
    }),
  ).isRequired,
  selectedItem: T.shape({
    id: T.oneOfType([T.string, T.number]),
  }),
  withEllipsize: T.bool,
  ellipsizedItemsCount: T.number,
  onPressItem: T.func.isRequired,
};

HorizontalCitiesList.defaultProps = {
  selectedItem: {},
  withEllipsize: false,
  ellipsizedItemsCount: null,
};

export default HorizontalCitiesList;
