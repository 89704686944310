import React, { useEffect } from 'react';

import { StyleSheet, View, TouchableOpacity, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { SafeAreaView } from 'react-native-safe-area-context';

import Icon from 'react-native-vector-icons/Feather';

import { subCommunitySelectors, subCommunityOperations } from '../../../store/subCommunities';

import screens from '../../../navigation/screens';
import { LoadingService, NavigationService } from '../../../services';
import strings from '../../../localization';
import { Text } from '../../ReusableComponents';

import { colors, dimensions, rs } from '../../../styles';

import SubCommunityItem from './components/SubCommunityItem';
import { getCurrentTheme } from '../../../templates';

const MySubCommunitiesListScreen = () => {
  const dispatch = useDispatch();

  const currentTheme = getCurrentTheme();

  const sections = useSelector(subCommunitySelectors.getSubCommunityGroupsList);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    LoadingService.showLoader();
    await dispatch(subCommunityOperations.getUserSubCommunities());
    LoadingService.hideLoader();
  };

  const handleNavigateToJoinSubCommunityScreen = () => {
    NavigationService.navigate(screens.JoinSubCommunityList);
  };

  const renderSectionFooter = () => {
    return (
      <TouchableOpacity
        style={[rs.row, rs.alignCenter, rs.bigMarginTop, rs.paddingHorizontal]}
        onPress={handleNavigateToJoinSubCommunityScreen}
      >
        <View
          style={[
            s.boxButtonAddCommunity,
            rs.marginRight,
            {
              backgroundColor: currentTheme.colors.secondary,
            },
          ]}
        >
          <Icon name="plus" size={20} color={colors.white} />
        </View>

        <Text medium bold>
          {strings.my_sub_communities.add_group}
        </Text>
      </TouchableOpacity>
    );
  };

  const renderItem = ({ item }) => {
    const onPress = () => {
      dispatch(subCommunityOperations.leaveSubCommunity(item.id));
      NavigationService.goBack();
    };

    return (
      <View style={[rs.paddingHorizontal, rs.bigMarginTop]}>
        <SubCommunityItem item={item} onPress={onPress} />
      </View>
    );
  };

  const renderEmptyComponent = () => {
    return (
      <View style={[rs.flex, rs.center, rs.paddingHorizontal, rs.bigPaddingTop]}>
        <Text medium center>
          {strings.my_sub_communities.no_sub_communities}
        </Text>
        <View
          style={[rs.fullWidth, rs.bigMarginTop, { height: 1, backgroundColor: colors.grayLight }]}
        />
      </View>
    );
  };

  return (
    <SafeAreaView edges={['bottom']} style={[rs.flex, rs.backgroundWhite]}>
      <FlatList
        data={sections}
        renderItem={renderItem}
        ListEmptyComponent={renderEmptyComponent}
        ListFooterComponent={renderSectionFooter}
      />
    </SafeAreaView>
  );
};

const s = StyleSheet.create({
  sectionHeaderContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.grayLighter,
  },
  sectionHeaderImage: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: colors.grayLighter,
  },
  sectionFooterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingHorizontal: dimensions.large,
    paddingVertical: dimensions.medium,
  },
  sectionFooterLink: {
    textDecorationLine: 'underline',
  },
  itemContainer: {
    paddingLeft: dimensions.large,
    paddingRight: dimensions.medium,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemImage: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: colors.grayLighter,
  },
  boxButtonAddCommunity: {
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24,
    backgroundColor: colors.grayLight,
  },
});

export default MySubCommunitiesListScreen;
