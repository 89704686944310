import React, { useState, Fragment } from 'react';
import styled from 'styled-components/native';
import FastImage from './FastImage';
import * as Sentry from '@sentry/react-native';
import { Modal, StyleSheet, View, ImageBackground } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import T from 'prop-types';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide/dist/css/splide.min.css';
import { dimensions, colors } from '../../styles';
import Spinner from './Spinner';
import AppImage from './AppImage';
import { isIphoneX, isIos, isWeb } from '../../utils/detectDevice';
import { useGetWindowCurrentWidth } from '../../utils/getWindowCurrentWidth';

const s = StyleSheet.create({
  paginationContainer: {
    paddingHorizontal: 0,
    paddingVertical: dimensions.indent,
    zIndex: 5,
  },
  paginationDot: {
    width: 9,
    height: 9,
    borderRadius: 5,
    backgroundColor: colors.white,
  },
  inactiveDot: {
    borderWidth: 1,
    borderColor: colors.white,
    backgroundColor: 'transparent',
  },
  image: {
    height: '100%',
    width: '100%',
    minHeight: isWeb ? 425 : 'auto',
    minWidth: isWeb ? 425 : 'auto',
  },
  imageBackground: {
    height: dimensions.width,
    width: dimensions.width,
  },
});

const Slide = styled.TouchableWithoutFeedback`
  z-index: 4;
  height: ${dimensions.width};
  width: ${dimensions.width};
`;

const PaginationContainer = styled.View`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  bottom: ${dimensions.halfMedium};
  position: absolute;
  align-self: center;
  z-index: 6;
`;

const CloseButton = styled.TouchableOpacity`
  padding: ${dimensions.halfMedium}px;
`;

const ModalHeaderContainer = styled.View`
  z-index: 10;
  position: absolute;
  top: ${isIos ? (isIphoneX ? 44 : 20) : 8};
  padding-right: ${dimensions.halfMedium}px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const SlideItem = React.memo(({ item, onPress, style }) => {
  return (
    <Slide onPress={onPress}>
      <SplideSlide>
        <FastImage
          style={[s.image, style]}
          resizeMode="cover"
          source={{ uri: item, priority: FastImage.priority.high }}
          progressiveRenderingEnabled
        />
      </SplideSlide>
    </Slide>
  );
});

const SwipeGallery = React.memo(({ images, imagePreview }) => {
  const [isVisibleViewer, setVisibleViewer] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const imagesArr = images.map((img) => ({ url: img }));
  const { windowCurrentWidth } = useGetWindowCurrentWidth();

  const onLoadImageError = (error) => {
    Sentry.captureException(error);
  };

  const width = !isWeb
    ? dimensions.width
    : windowCurrentWidth / 1.75 > 425
    ? windowCurrentWidth / 1.75
    : 425;

  return (
    <Fragment>
      <Modal visible={isVisibleViewer} onRequestClose={() => setVisibleViewer(false)} transparent>
        <ImageViewer
          loadingRender={() => <Spinner />}
          renderIndicator={() => {}}
          renderImage={(props) => <AppImage {...props} onError={onLoadImageError} />}
          renderHeader={() => (
            <ModalHeaderContainer>
              <CloseButton onPress={() => setVisibleViewer(false)}>
                <Icon size={dimensions.iconSize} name="close" color={colors.white} />
              </CloseButton>
              <Pagination
                dotsLength={images.length}
                activeDotIndex={selectedId}
                containerStyle={s.paginationContainer}
                dotStyle={s.paginationDot}
                inactiveDotOpacity={0.4}
              />
              <View />
            </ModalHeaderContainer>
          )}
          index={selectedId}
          enableSwipeDown
          saveToLocalByLongPress={false}
          onCancel={() => setVisibleViewer(false)}
          onChange={(id) => setSelectedId(id)}
          imageUrls={imagesArr}
        />
      </Modal>

      <ImageBackground style={s.image} source={{ uri: imagePreview }}>
        <Splide
          options={{
            arrows: false,
            type: 'slide',
            perMove: 1,
            pagination: images.length > 1,
            cover: true,
          }}
        >
          {images.map((item, index) => (
            <SlideItem
              item={item}
              style={{
                height: width,
              }}
              imagePreview={imagePreview}
              onPress={() => {
                setSelectedId(index);
                setVisibleViewer(true);
              }}
            />
          ))}
        </Splide>
      </ImageBackground>
    </Fragment>
  );
});

SwipeGallery.propTypes = {
  images: T.array.isRequired,
};

export default SwipeGallery;
