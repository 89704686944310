import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 40,
    margin: 4,
    height: 32,
    paddingHorizontal: 12,
    borderRadius: 16,
  },
  profileImage: {
    width: 18,
    height: 18,
    borderRadius: 9,
    marginRight: 6,
  },
  nickname: {
    marginLeft: 6,
    fontSize: 12,
  },
  icon: {
    marginLeft: 12,
  },
});

export default styles;
