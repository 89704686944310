import { Dimensions } from 'react-native';
import { scaleFont } from './scalingUtils';
import { isWeb } from '../utils/detectDevice';

const { width } = Dimensions.get('window');

const mobileSize = width < 426;

const fontSizes = {
  title: isWeb && !mobileSize ? 26 : 22,
  headline: isWeb && !mobileSize ? 24 : 20,
  large: isWeb && !mobileSize ? 22 : 18,
  medium: isWeb && !mobileSize ? 20 : 16,
  small: isWeb && !mobileSize ? 18 : 14,
  xsmall: isWeb && !mobileSize ? 15 : 12,
  xxsmall: isWeb && !mobileSize ? 14 : 10,

  // deprecated don't use it, remove in the future
  xxbig: scaleFont(52),
  xbig: scaleFont(38),
  big: scaleFont(24),
  xxmedium: scaleFont(20),
  xmedium: scaleFont(18),
};

export default fontSizes;
