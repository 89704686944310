import * as R from 'ramda';
import * as actions from './actions';
import * as communityThemeActions from '../communityTheme/actions';
import communitiesApi from '../../api/communities';
import { ACCOUNT_SUSPENDED_BY_ADMIN } from '../../../config/appConstants/httpCodes';
import { NavigationService, ToastsService } from '../../services';
import screens from '../../navigation/screens';
import strings from '../../localization';
import { getCommunityId } from './selectors';

export const getUserCommunity = () => async (dispatch) => {
  try {
    const communityInfo = await communitiesApi.getUserCommunity();
    dispatch(actions.setCommunity({ communityInfo }));
    dispatch(
      communityThemeActions.setCommunityTheme(
        communityInfo.home_settings.template_name ?? strings.main.app_name.toLowerCase(),
      ),
    );
  } catch (e) {
    if (R.path(['response', 'status'], e) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }
  }
};

export const getCommunitySlides = () => async (dispatch, getState) => {
  try {
    const communityId = getCommunityId(getState());
    const data = await communitiesApi.getSlides(communityId);

    if (!data?.length) {
      dispatch(actions.setCommunitySlides([]));
    } else {
      dispatch(
        actions.setCommunitySlides(
          data.map((slide) => ({
            id: slide.id,
            imageUrl: slide.image_url,
            redirectUrl: slide.redirect_url,
            order: slide.order,
          })),
        ),
      );
    }

    dispatch(actions.setLoadingSlides(false));
  } catch (error) {
    ToastsService.showError(strings.error_messages.error);
  }
};

export default {
  getUserCommunity,
  getCommunitySlides,
};
