import DeviceInfo from 'react-native-device-info';
import AsyncStorage from '@react-native-community/async-storage';
import * as R from 'ramda';
import CryptoAES from 'crypto-js/aes';
import authApi from '../../api/auth';
import strings from '../../localization';
import { appOperations } from '../app';
import { chatsOperations } from '../chats';
import { userOperations } from '../userInfo';
import userBlockOperations from '../user/operations';
import authToken from '../../utils/authToken';
import api from '../../api/api';
import { ToastsService, NavigationService, LoadingService, AnalyticsService } from '../../services';
import screens from '../../navigation/screens';
import { ACCOUNT_SUSPENDED_BY_ADMIN } from '../../../config/appConstants/httpCodes';
import io from '../../api/io';
import { notificationOperations } from '../notifications';
import * as analyticsEventTypes from '../../constants/analyticsEventTypes';
import { setUserOriginInfo, setAuthorization } from './actions';
import { setOnboardingStatus, setReferralRequired } from '../userInfo/actions';
import { APP_KEY } from '../../constants/app';
import appStates from '../../constants/appStates';
import { isWeb } from '../../utils/detectDevice';
import { setCommunityTheme } from '../communityTheme/actions';
import { communityOperations } from '../communityInfo';

export const signInWithPhoneNumber = (phone, countryCode) => async (dispatch, getState) => {
  const { auth } = getState();
  const { campaign_name, conversion_type } = auth.userOriginInfo;

  const encryptedPhoneNumber = CryptoAES.encrypt(phone, APP_KEY).toString();

  try {
    await authApi.signIn({ phone_number: encryptedPhoneNumber, campaign_name, conversion_type });
    AsyncStorage.setItem('currentCountryCode', countryCode);
  } catch (e) {
    ToastsService.showError(strings.error_messages.sign_in);
    NavigationService.goBack();
  }
};

const applyLegalDocumentsUpdate = () => async (dispatch, getState) => {
  try {
    await authApi.applyLegalDocumentsUpdate();
  } catch (e) {}
};

export const codeVerification = (phoneNumber, code) => async (dispatch, getState) => {
  const deviceId = await DeviceInfo.getUniqueId();

  const store = getState();
  const referralCode = R.pathOr(null, ['userInfo', 'referral_code'], store);

  try {
    LoadingService.showLoader();

    if (referralCode) {
      await dispatch(userOperations.applyReferralCode(referralCode));
    }

    const data = await authApi.codeVerification(phoneNumber, code, deviceId);
    LoadingService.hideLoader();

    const { access_token, refresh_token } = data.tokens;

    authToken.setTokens(access_token, refresh_token);
    api.setToken(access_token);
    io.setToken(access_token);
    dispatch(chatsOperations.onNewMessage());
    dispatch(chatsOperations.onMarkAsRead());
    dispatch(chatsOperations.onMarkMessageAsRead());
    dispatch(chatsOperations.onUserTypingMessage());
    dispatch(userBlockOperations.onBlockFromAnotherUser());
    dispatch(notificationOperations.checkNotificationsPermissions());
    dispatch(communityOperations.getUserCommunity());

    const userInfo = R.omit(['tokens'], data);

    dispatch(userOperations.setUserInfo(userInfo));
    dispatch(setOnboardingStatus(true));

    dispatch(appOperations.logAppEntry(appStates.LOGIN));
    AnalyticsService.logEvent(analyticsEventTypes.VERIFICATION_CODE_CONFIRMED);

    /** If response without real user name than navigate to WelcomeScreen  */
    if (userInfo.has_email_verification) {
      NavigationService.reset(screens.CampusEmailConfirmationStack);
    } else if (userInfo.is_referral_required) {
      dispatch(setReferralRequired(true));
      NavigationService.reset(screens.RequiredReferralCode);
    } else if (userInfo.is_first_login) {
      NavigationService.reset(screens.AuthorizedApplication, {
        screen: screens.OnboardingSlides,
      });
    } else {
      NavigationService.reset(screens.AuthorizedApplication);
    }
  } catch (err) {
    LoadingService.hideLoader();
    if (R.path(['response', 'status'], err) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    } else {
      ToastsService.showError(strings.error_messages.code_verification);
    }
  }
};

export const codeVerificationForWeb = (phoneNumber, code) => async (dispatch, getState) => {
  const deviceId = await DeviceInfo.getUniqueId();

  const store = getState();
  const referralCode = R.pathOr(null, ['userInfo', 'referral_code'], store);

  try {
    LoadingService.showLoader();

    if (referralCode) {
      await dispatch(userOperations.applyReferralCode(referralCode));
    }

    const data = await authApi.codeVerification(phoneNumber, code, deviceId, isWeb);
    LoadingService.hideLoader();

    const { access_token, refresh_token } = data.tokens;

    authToken.setTokens(access_token, refresh_token);
    api.setToken(access_token);
    io.setToken(access_token);
    dispatch(chatsOperations.onNewMessage());
    dispatch(chatsOperations.onMarkAsRead());
    dispatch(chatsOperations.onMarkMessageAsRead());
    dispatch(chatsOperations.onUserTypingMessage());
    dispatch(userBlockOperations.onBlockFromAnotherUser());
    dispatch(notificationOperations.checkNotificationsPermissions());

    const userInfo = R.omit(['tokens'], data);

    dispatch(userOperations.setUserInfo(userInfo));
    setTimeout(() => window.location.reload(), 1);
  } catch (err) {
    LoadingService.hideLoader();
  }
};

export const logOut = () => async (dispatch) => {
  dispatch(appOperations.resetGroupState());
  dispatch(appOperations.resetState());
  await AsyncStorage.clear();
  api.deleteToken();

  NavigationService.reset(screens.UnauthorizedApplication);
};

export const deleteUserAccount = () => async () => {
  await authApi.deleteUserAccount();
};

export const checkAuthorization = () => async (dispatch) => {
  const accessToken = await authToken.getAccessToken();

  if (!accessToken) {
    dispatch(setAuthorization({ isAuthorized: false }));
    return;
  }

  api.setToken(accessToken);
  io.setToken(accessToken);
  dispatch(chatsOperations.onNewMessage());
  dispatch(chatsOperations.onMarkAsRead());
  dispatch(chatsOperations.onMarkMessageAsRead());
  dispatch(chatsOperations.onUserTypingMessage());
  dispatch(userBlockOperations.onBlockFromAnotherUser());
  dispatch(notificationOperations.checkNotificationsPermissions());

  const response = await authApi.checkAuthentication({ accessToken });

  if (!response.user_info) {
    return;
  }

  dispatch(
    setAuthorization({
      isAuthorized: response.is_authorized,
      isSuspended: response.is_suspended,
    }),
  );
  dispatch(userOperations.setUserInfo(response.user_info));
  dispatch(appOperations.fetchAppData());
};

export default {
  signInWithPhoneNumber,
  codeVerification,
  codeVerificationForWeb,
  logOut,
  setUserOriginInfo,
  applyLegalDocumentsUpdate,
  deleteUserAccount,
  checkAuthorization,
};
