import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { FlatList, I18nManager, RefreshControl, StyleSheet } from 'react-native';
import { TabView } from 'react-native-tab-view';
import T from 'prop-types';
import { chatsOperations, chatsSelectors } from '../../../../store/chats';
import {
  Container,
  TabBar,
  Badge,
  Spinner,
  View,
  EmptyListWithImage,
  Text,
  Button,
  IconButton,
  NavigationIconButton,
  NavigationButton,
} from '../../../ReusableComponents';
import ChatsList from './components/ChatsList';
import NotificationItem from './components/NotificationItem';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { colors, dimensions, rs } from '../../../../styles';
import screens from '../../../../navigation/screens';
import notificationOperation from '../../../../store/notifications/operations';
import { getNotifications } from '../../../../store/notifications/selectors';
import { usePrevious } from '../../../../utils/hooks';
import { getParamOr } from '../../../../utils/navHelper';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useIsFocused } from '@react-navigation/native';
import { communitySelectors } from '../../../../store/communityInfo';
import { isWeb } from '../../../../utils/detectDevice';
import InboxContainer from '../../../ReusableComponents/Containers/InboxContainer';
import { useGetWindowCurrentWidth } from '../../../../utils/getWindowCurrentWidth';
import ChatsListContainer from './components/ChatsListContainer';
import NotificationScreenWeb from './NotificationScreen.web';
import ChatListAdminContainer from './components/ChatListAdminContainer';

const s = StyleSheet.create({
  tabViewInitialLayout: {
    height: 0,
    width: dimensions.width,
  },
  chatsContainer: {
    width: '64%',
    borderLeftWidth: 1,
    borderColor: colors.grayBorder,
  },
  headers: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headersAdmin: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 48,
    paddingHorizontal: dimensions.medium,
  },
});

const HeaderButton = ({
  isEditMode,
  onDonePress,
  onOpenActionSheet,
  onArchivePress,
  selectedChatsLength,
}) => {
  return (
    <View style={s.headers}>
      {isEditMode ? (
        <NavigationButton title={strings.common.cancel} onButtonPress={onDonePress} />
      ) : (
        <View />
      )}
      {isEditMode ? (
        <NavigationButton
          disabled={!selectedChatsLength}
          title={strings.chats.archive}
          onButtonPress={onArchivePress}
        />
      ) : (
        <View style={rs.row}>
          <IconButton
            name="search"
            size={20}
            style={rs.marginHorizontal}
            onPress={() => NavigationService.navigate(screens.ChatsSearchResults)}
          />
          <NavigationIconButton onPress={onOpenActionSheet} name="more-vertical" />
        </View>
      )}
    </View>
  );
};

const HeaderAdminButton = () => {
  return (
    <View style={s.headersAdmin}>
      <IconButton
        name="search"
        size={20}
        style={rs.marginHorizontal}
        onPress={() => NavigationService.navigate(screens.AdminSupportChatsSearchResults)}
      />
    </View>
  );
};

const Chats = ({
  chats,
  supportChats,

  isAdmin,
  isLoadingChats,
  isLoadingMoreChats,
  isLoadingSupportChats,
  isLoadingMoreSupportChats,

  unreadCountChats,
  unreadCountSupportChats,
  unreadCountNotifications,

  getAllChats,
  getSupportChatsForAdmins,
  getUnreadCountForAllChats,
  getUnreadCountForAdminSupportChats,
  archiveChats,
  navigation,

  notifications,
  isLoadingNotifications,
  isLoadingMoreNotifications,
  isRefreshingNotifications,
  onLoadMore,
  onRefreshNotifications,
  onPressNotification,
  onMarkNotificationAsOpen,
  onMarkNotificationsAsRead,
  language,
  currentUserId,
  route,
}) => {
  const isFocused = useIsFocused();
  const { showActionSheetWithOptions } = useActionSheet();
  const isEditMode = route.params?.isEditMode ?? false;
  const selectedChatsLength = route.params?.selectedChatsLength ?? 0;

  const [index, setIndex] = useState(0);
  const [isRefreshing, setRefreshing] = useState(false);
  const [selectedChatIds, setSelectedChatIds] = useState([]);

  const { isMobileWindow, windowCurrentWidth } = useGetWindowCurrentWidth();

  const onDonePress = () => {
    setSelectedChatIds([]);
    navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
  };
  const onArchivePress = () => {
    setSelectedChatIds([]);
    navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
    archiveChats(selectedChatIds);
  };

  const prevProps = usePrevious({ isFocused });

  useEffect(() => {
    if (isFocused && index === 0) {
      onMarkNotificationsAsRead();
    }

    if (R.prop('isFocused', prevProps) && !isFocused && index === 0) {
      onMarkNotificationsAsRead();
    }
  }, [isFocused]);

  useEffect(() => {
    getAllChats();
    navigation.setParams({
      onOpenActionSheet,
      tabIndex: index,
    });
  }, []);

  const onChangeTabIndex = (tabIndex) => {
    navigation.setParams({
      tabIndex,
    });

    setIndex(tabIndex);
  };

  const onOpenActionSheet = useCallback(() => {
    const options = [
      strings.chats.add_chats_to_archive,
      strings.chats.archived_chats,
      strings.common.cancel,
    ];

    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          navigation.setParams({ isEditMode: true });
        }
        if (buttonIndex === 1) {
          NavigationService.navigate(screens.ArchivedChats);
        }
      },
    );
  }, []);

  const onNotificationPress = (notification) => {
    const { action_id, action_description, id } = notification;

    onMarkNotificationAsOpen(id);
    onPressNotification({ action_id, action_description: JSON.stringify(action_description) });
  };

  const onSelectChat = (chatId) => {
    if (!isEditMode) {
      NavigationService.navigate(screens.ChatRoom, { chatId });
      return;
    }
    let newSelectedChatIds = null;

    if (R.includes(chatId, selectedChatIds)) {
      newSelectedChatIds = selectedChatIds.filter((element) => element !== chatId);
    } else {
      newSelectedChatIds = [...selectedChatIds, chatId];
    }

    setSelectedChatIds(newSelectedChatIds);
    navigation.setParams({ selectedChatsLength: newSelectedChatIds.length });
  };

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  const renderLazyPlaceholder = () => <Spinner />;

  const onRefresh = async () => {
    setRefreshing(true);
    await getAllChats();
    if (isAdmin) {
      await getSupportChatsForAdmins();
      getUnreadCountForAdminSupportChats();
    }
    getUnreadCountForAllChats();
    setRefreshing(false);
  };

  const routes = [
    { key: 'notifications', title: strings.notifications.notifications },
    { key: 'chats', title: strings.chats.chats },
  ];

  if (isAdmin) {
    routes.push({ key: 'admin_support', title: strings.chats.admin_support });
  }

  return !isMobileWindow ? (
    <InboxContainer>
      {route.params?.isAdminChats ? (
        <View style={[rs.row, rs.fullWidth, rs.fullHeight]}>
          <ChatListAdminContainer
            isEditMode={isEditMode}
            selectedChatIds={selectedChatIds}
            onSelectChat={onSelectChat}
          />
          <View style={[s.chatsContainer]}>
            <HeaderAdminButton />
            <EmptyListWithImage image="empty_state_chats" />
          </View>
        </View>
      ) : (
        <View style={[rs.row, rs.fullWidth, rs.fullHeight]}>
          <ChatsListContainer
            isEditMode={isEditMode}
            selectedChatIds={selectedChatIds}
            onSelectChat={onSelectChat}
          />
          <View style={[s.chatsContainer]}>
            <HeaderButton
              isEditMode={isEditMode}
              onDonePress={onDonePress}
              onArchivePress={onArchivePress}
              onOpenActionSheet={onOpenActionSheet}
              selectedChatsLength={selectedChatsLength}
            />
            <EmptyListWithImage image="empty_state_chats" />
          </View>
        </View>
      )}
    </InboxContainer>
  ) : (
    <Container>
      <TabView
        lazy
        style={{
          direction: isWeb ? 'ltr' : 'inherit',
        }}
        sceneContainerStyle={
          isWeb && [rs.alignCenter, { direction: I18nManager.getConstants().isRTL ? 'rtl' : 'ltr' }]
        }
        renderLazyPlaceholder={renderLazyPlaceholder}
        initialLayout={s.tabViewInitialLayout}
        renderTabBar={(props) => (
          <TabBar
            {...props}
            renderLabel={({ route, focused, color }) => (
              <View style={[rs.row, rs.alignCenter]}>
                <Text style={{ color }}>{route.title}</Text>
                <Badge
                  count={
                    {
                      notifications: unreadCountNotifications,
                      chats: unreadCountChats,
                      admin_support: unreadCountSupportChats,
                    }[route.key]
                  }
                />
              </View>
            )}
          />
        )}
        navigationState={{ index, routes }}
        onIndexChange={onChangeTabIndex}
        renderScene={({ route }) => {
          switch (route.key) {
            case 'admin_support':
              return (
                <>
                  <HeaderAdminButton />
                  <ChatListAdminContainer
                    isEditMode={isEditMode}
                    selectedChatIds={selectedChatIds}
                    onSelectChat={onSelectChat}
                  />
                </>
              );
            case 'chats':
              return (
                <>
                  <HeaderButton
                    isEditMode={isEditMode}
                    onDonePress={onDonePress}
                    onArchivePress={onArchivePress}
                    onOpenActionSheet={onOpenActionSheet}
                    selectedChatsLength={selectedChatsLength}
                  />
                  <ChatsListContainer
                    isEditMode={isEditMode}
                    selectedChatIds={selectedChatIds}
                    onSelectChat={onSelectChat}
                  />
                </>
              );
            case 'notifications':
              return <NotificationScreenWeb />;
            default:
              return null;
          }
        }}
      />
    </Container>
  );
};

Chats.propTypes = {
  chats: T.array,

  isLoadingChats: T.bool,
  isLoadingMoreChats: T.bool,

  isUnreadChats: T.bool,
  isUnreadInSellTab: T.bool,
  isUnreadInPersonalTab: T.bool,

  getAllChats: T.func,
  getSupportChatsForAdmins: T.func,

  getUnreadCountForAllChats: T.func,
  getUnreadCountForAdminSupportChats: T.func,
  archiveChats: T.func,
};

export default connect(
  (state) => ({
    isLoadingChats: state.chats.allChats.isLoading,
    isAdmin: communitySelectors.getIsUserAdmin(state),
    isLoadingMoreChats: state.chats.allChats.isLoadingMore,
    unreadCountChats: state.chats.totalUnreadCount,
    unreadCountSupportChats: state.chats.adminSupportChatsTotalUnreadCount,
    chats: chatsSelectors.getChats(state),

    isLoadingSupportChats: state.chats.adminSupportChats.isLoading,
    isLoadingMoreSupportChats: state.chats.adminSupportChats.isLoadingMore,
    supportChats: chatsSelectors.getSupportChatsForAdmin(state),

    notifications: getNotifications(state),
    unreadCountNotifications: state.notifications.unreadCount,

    isLoadingNotifications: state.notifications.isLoading,
    isLoadingMoreNotifications: state.notifications.isLoadingMore,
    isRefreshingNotifications: state.notifications.isRefreshing,
    language: state.app.language,
    currentUserId: state.userInfo.id,
  }),
  (dispatch) => ({
    getAllChats: (isLoadMore) => dispatch(chatsOperations.getAllChats(isLoadMore)),
    getSupportChatsForAdmins: (isLoadMore) =>
      dispatch(chatsOperations.getSupportChatsForAdmins({ isLoadMore })),
    getUnreadCountForAllChats: () => dispatch(chatsOperations.getUnreadCountForAllChats()),
    getUnreadCountForAdminSupportChats: () =>
      dispatch(chatsOperations.getUnreadCountForAdminSupportChats()),
    archiveChats: (chatIds) => dispatch(chatsOperations.archiveChats(chatIds)),

    onLoadMore: () => dispatch(notificationOperation.getMoreNotifications()),
    onRefreshNotifications: () => dispatch(notificationOperation.getNotifications(true)),
    onPressNotification: (notificationData) =>
      dispatch(notificationOperation.onPressNotification(notificationData)),
    onMarkNotificationsAsRead: () => dispatch(notificationOperation.onMarkNotificationsAsRead()),
    onMarkNotificationAsOpen: (notificationId) =>
      dispatch(notificationOperation.onMarkNotificationAsOpen(notificationId)),
  }),
)(Chats);
