import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { I18nManager, StyleSheet, View, Animated } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';
import screens from '../screens';
import {
  BuyingOffers,
  ChatRoom,
  CommunityRules,
  CurrentUserItems,
  EditUserInfo,
  FAQ,
  GiftsInfo,
  Home,
  Inbox,
  Notifications,
  ItemDetails,
  Language,
  Marketplace,
  Menu,
  PendingItems,
  PhotoTips,
  SavedItems,
  SellingOffers,
  Settings,
  ShippingInfo,
  ShortShareittInfo,
  Support,
  TransactionDetails,
  Transactions,
  UserProfile,
  WishDetails,
  Wishes,
  WishFulfillmentOffers,
  SendNotifications,
  PromoCode,
  UserLocations,
  EarnTokens,
  MultipleConditions,
  MultipleBrands,
  MultipleSizes,
  Followers,
  Endorsements,
  MapWithUserLocations,
  SearchScreen,
  InviteFriends,
  Rewards,
  IssueMemberList,
  ItemsSelectionScreen,
  LocationView,
  AllBuyers,
  SnapshotItemDetailsScreen,
  AddEmail,
  EditNickname,
  SendTokensToBank,
  IssueToken,
  AchievedRewardDetails,
  HomeSearchScreen,
  Referrals,
  AddUserName,
  AddProfileImage,
  AddBirthday,
  AddGender,
  VerifyEmail,
  NotificationTargetGroup,
  NotificationAppAction,
  ArchivedChats,
  ChatsSearchResults,
  AdminSupportChatsSearchResults,
  ArchivedSellingOffers,
  BuyingOffersSearch,
  SellingOffersSearch,
  NotAchievedRewardDetails,
} from '../../components/Screens';
import GroupFilterStack from './GroupFilterStack';
import WebHeaderNavigator from '../components/WebHeaderNavigator';
import { colors, dimensions, headerStyle, rs } from '../../styles';
import { useGetWindowCurrentWidth } from '../../utils/getWindowCurrentWidth';
import CustomDrawer from '../components/CustomDrawer';
import { isRTL } from '../../utils/rtlHelper';
import {
  BackButton,
  IconButton,
  NavigationButton,
  NavigationIconButton,
} from '../../components/ReusableComponents';
import FilterStack from './FilterStack';
import strings from '../../localization';
import EditWishStack from './EditWishStack';
import EditItemStack from './EditItemStack';
import CitiesStack from './CitiesStack';
import WishFilterStack from './WishFilterStack';
import ContainerWeb from '../../components/ReusableComponents/Containers/ContainerWeb.web';
import { communityOperations } from '../../store/communityInfo';
import { homeOperations } from '../../store/home';
import { appOperations } from '../../store/app';
import { headerStyleTransparentWithBackground } from '../../styles/headerStyle';
import NavigationService from '../../services/NavigationService.web';
import AnalyticsService from '../../services/AnalyticsService.web';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const AuthorizedApplicationNavigatorWithoutModals = () => {
  return (
    <ContainerWeb>
      <Stack.Navigator
        initialRouteName={screens.Home}
        screenOptions={{
          headerShown: false,
          headerTitleStyle: {
            ...headerStyle.headerTitleStyle,
          },
          cardStyle: {
            backgroundColor: colors.white,
            maxWidth: 1100,
            marginHorizontal: 'auto',
            width: '100%',
          },
        }}
      >
        <Stack.Screen name={screens.Home} component={Home} />
        <Stack.Screen name={screens.FeedTab} component={Home} />
        <Stack.Screen name={screens.MarketplaceTab} component={Marketplace} />
        <Stack.Screen name={screens.Marketplace} component={Marketplace} />
        <Stack.Screen name={screens.Wishes} component={Wishes} />
        <Stack.Screen name={screens.ItemDetails} component={ItemDetails} />
        <Stack.Screen name={screens.WishDetails} component={WishDetails} />
        <Stack.Screen
          name={screens.SendTokensToBank}
          component={SendTokensToBank}
          options={{
            headerShown: true,
            headerLeft: () => <BackButton />,
            title: strings.bank.transfer_tokens,
          }}
        />
        <Stack.Screen
          name={screens.IssueToken}
          component={IssueToken}
          options={{
            headerShown: true,
            headerLeft: () => <BackButton />,
            title: strings.bank.issue_token,
          }}
        />
        <Stack.Screen
          name={screens.UserProfile}
          component={UserProfile}
          options={({ route }) => {
            const nickname = route.params?.nickname ?? '';

            return {
              headerShown: true,
              title: nickname ? `@${nickname}` : '',
              headerLeft: () => <BackButton />,
              headerRight: () => (
                <NavigationIconButton
                  onPress={route.params?.onOpenActionSheet ?? null}
                  name="more-vertical"
                  disabled={route.params?.isActionSheetDisabled ?? false}
                />
              ),
            };
          }}
        />
        <Stack.Screen name={screens.AddEmail} component={AddEmail} />
        <Stack.Screen name={screens.EditNickname} component={EditNickname} />
        <Stack.Screen
          options={{
            cardStyle: {
              backgroundColor: colors.white,
              maxWidth: 1500,
            },
          }}
          name={screens.Inbox}
          component={Inbox}
        />
        <Stack.Screen
          options={{
            cardStyle: {
              backgroundColor: colors.white,
              maxWidth: 1500,
            },
          }}
          name={screens.ArchivedChats}
          component={ArchivedChats}
        />
        <Stack.Screen name={screens.ChatsSearchResults} component={ChatsSearchResults} />
        <Stack.Screen
          name={screens.AdminSupportChatsSearchResults}
          component={AdminSupportChatsSearchResults}
        />
        <Stack.Screen name={screens.Notifications} component={Notifications} />
        <Stack.Screen
          name={screens.ChatRoom}
          component={ChatRoom}
          options={{
            cardStyle: {
              backgroundColor: colors.white,
              maxWidth: 1500,
            },
          }}
        />
        <Stack.Screen name={screens.Menu} component={Menu} />
        <Stack.Screen name={screens.Settings} component={Settings} />
        <Stack.Screen
          name={screens.Transactions}
          component={Transactions}
          options={({ route }) => {
            const nickname = route.params?.nickname ?? '';

            return {
              headerShown: true,
              title: nickname ? `@${nickname}` : '',
              headerLeft: () => <View />,
              headerRight: () => (
                <View style={[rs.row, rs.alignCenter]}>
                  <NavigationIconButton
                    isFontIcon
                    name="Edit-17"
                    onPress={route.params?.onEditProfile ?? null}
                  />
                  <NavigationIconButton
                    isFontIcon
                    name="Upload_grey"
                    onPress={route.params?.onShareProfile ?? null}
                  />
                </View>
              ),
            };
          }}
        />
        <Stack.Screen name={screens.PendingItems} component={PendingItems} />
        <Stack.Screen name={screens.CurrentUserItems} component={CurrentUserItems} />
        <Stack.Screen
          name={screens.BuyingOffers}
          component={BuyingOffers}
          options={{
            headerShown: true,
            ...headerStyleTransparentWithBackground,
            title: strings.profile.buying,
            headerLeft: () => <View />,
            headerRight: () => (
              <IconButton
                name="search"
                size={20}
                style={rs.marginHorizontal}
                onPress={() => NavigationService.navigate(screens.BuyingOffersSearch)}
              />
            ),
          }}
        />
        <Stack.Screen name={screens.BuyingOffersSearch} component={BuyingOffersSearch} />
        <Stack.Screen name={screens.SellingOffersSearch} component={SellingOffersSearch} />
        <Stack.Screen
          name={screens.SellingOffers}
          component={SellingOffers}
          options={({ route }) => {
            const { isEditMode, selectedOffersLength, onDonePress, onArchivePress } =
              route?.params || {};

            const title = isEditMode
              ? strings.formatString(strings.chats.chats_selected, selectedOffersLength)
              : strings.profile.selling;

            return {
              headerShown: true,
              ...headerStyleTransparentWithBackground,
              title,
              headerLeft: () =>
                isEditMode ? (
                  <NavigationButton title={strings.common.cancel} onButtonPress={onDonePress} />
                ) : (
                  <View />
                ),
              headerRight: () => {
                const index = route.params?.tabIndex ?? 0;
                if (isEditMode) {
                  return (
                    <NavigationButton
                      disabled={!selectedOffersLength}
                      title={strings.chats.archive}
                      onButtonPress={onArchivePress}
                    />
                  );
                }

                return (
                  <View style={rs.row}>
                    <IconButton
                      name="search"
                      size={20}
                      style={rs.marginHorizontal}
                      onPress={() => NavigationService.navigate(screens.SellingOffersSearch)}
                    />
                    {index === 1 && (
                      <NavigationIconButton
                        onPress={route.params?.onOpenActionSheet ?? null}
                        name="more-vertical"
                      />
                    )}
                  </View>
                );
              },
            };
          }}
        />
        <Stack.Screen
          name={screens.ArchivedSellingOffers}
          component={ArchivedSellingOffers}
          options={({ route, navigation }) => {
            const { isEditMode, selectedOffersLength, onDonePress, onUnarchivePress } = route.params
              ? route.params
              : {};

            const title = isEditMode
              ? strings.formatString(strings.chats.chats_selected, selectedOffersLength)
              : strings.chats.archived_chats;

            return {
              headerShown: true,
              ...headerStyle,
              title,
              headerRight: () =>
                isEditMode ? (
                  <NavigationButton title={strings.common.cancel} onButtonPress={onDonePress} />
                ) : (
                  <NavigationButton
                    title={strings.common.edit}
                    onButtonPress={() => navigation.setParams({ isEditMode: true })}
                  />
                ),

              headerLeft: () =>
                isEditMode ? (
                  <NavigationButton
                    disabled={!selectedOffersLength}
                    title={strings.chats.unarchive}
                    onButtonPress={onUnarchivePress}
                  />
                ) : (
                  <BackButton />
                ),
            };
          }}
        />
        <Stack.Screen name={screens.SavedItems} component={SavedItems} />
        <Stack.Screen name={screens.WishFulfillmentOffers} component={WishFulfillmentOffers} />
        <Stack.Screen name={screens.Support} component={Support} />
        <Stack.Screen name={screens.FAQ} component={FAQ} />
        <Stack.Screen name={screens.ShortShareittInfo} component={ShortShareittInfo} />
        <Stack.Screen name={screens.CommunityRules} component={CommunityRules} />
        <Stack.Screen name={screens.ShippingInfo} component={ShippingInfo} />
        <Stack.Screen name={screens.GiftsInfo} component={GiftsInfo} />
        <Stack.Screen name={screens.PhotoTips} component={PhotoTips} />
        <Stack.Screen name={screens.Language} component={Language} />
        <Stack.Screen name={screens.AchievedRewardDetails} component={AchievedRewardDetails} />
        <Stack.Screen
          name={screens.NotAchievedRewardDetails}
          component={NotAchievedRewardDetails}
        />
        <Stack.Screen
          name={screens.SendNotifications}
          component={SendNotifications}
          options={{ headerShown: true }}
        />
        <Stack.Screen
          name={screens.NotificationTargetGroup}
          component={NotificationTargetGroup}
          options={{
            headerShown: true,
            title: strings.settings.target_group,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen
          name={screens.NotificationAppAction}
          component={NotificationAppAction}
          options={{
            headerShown: true,
            title: strings.settings.app_action,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen name={screens.HomeSearch} component={HomeSearchScreen} />
        <Stack.Screen name={screens.PromoCode} component={PromoCode} />
        <Stack.Screen
          name={screens.UserLocations}
          component={UserLocations}
          options={({ route }) => ({
            headerShown: true,
            title: strings.locations.my_addresses,
            headerLeft: () => <BackButton />,
            headerRight: () => (
              <NavigationIconButton onPress={route.params?.onAddLocation ?? null} name="plus" />
            ),
          })}
        />
        <Stack.Screen
          name={screens.Location}
          component={LocationView}
          options={{
            headerShown: true,
            title: strings.other.change_location,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen
          name={screens.Referrals}
          component={Referrals}
          options={({ route }) => ({
            headerShown: true,
            headerLeft: () => <BackButton />,
            title:
              route.params?.isSoldItemReferrals ?? false
                ? strings.referrals.referrals_who_sold_item
                : strings.referrals.refer_a_friend,
          })}
        />
        <Stack.Screen name={screens.EditItemStack} component={EditItemStack} />
        <Stack.Screen name={screens.EditWishStack} component={EditWishStack} />
        <Stack.Screen name={screens.WishFilterStack} component={WishFilterStack} />
        <Stack.Screen
          name={screens.EditUserInfo}
          component={EditUserInfo}
          options={({ route }) => ({
            cardStyle: { backgroundColor: colors.white },
            headerShown: true,
            title: strings.login.edit_profile,
            headerLeft: () => <BackButton />,
            headerRight: () => (
              <NavigationButton
                title={strings.common.save}
                disabled={!route.params?.isValid ?? false}
                onButtonPress={route.params?.updateInfo ?? null}
              />
            ),
          })}
        />
        <Stack.Screen
          name={screens.TransactionDetails}
          component={TransactionDetails}
          options={({ route }) => ({
            headerShown: true,
            title: route.params.title,
            headerLeft: () => <BackButton />,
          })}
        />
        <Stack.Screen name={screens.ItemsSelectionScreen} component={ItemsSelectionScreen} />
        <Stack.Screen name={screens.IssueMemberList} component={IssueMemberList} />
        <Stack.Screen name={screens.CitiesFilterStack} component={CitiesStack} />
        <Stack.Screen name={screens.GroupFilterStack} component={GroupFilterStack} />
        <Stack.Screen name={screens.EarnTokens} component={EarnTokens} />
        <Stack.Screen name={screens.InviteFriends} component={InviteFriends} />
        <Stack.Screen name={screens.FilterStack} component={FilterStack} />
        <Stack.Screen name={screens.MultipleConditions} component={MultipleConditions} />
        <Stack.Screen name={screens.Rewards} component={Rewards} />
        <Stack.Screen name={screens.MultipleBrands} component={MultipleBrands} />
        <Stack.Screen name={screens.MultipleSizes} component={MultipleSizes} />
        <Stack.Screen
          name={screens.Followers}
          component={Followers}
          options={({ route }) => ({
            headerShown: true,
            title: route.params.name,
            headerLeft: () => <BackButton />,
          })}
        />
        <Stack.Screen
          name={screens.AllBuyers}
          component={AllBuyers}
          options={{
            headerShown: true,
            title: strings.user.members_sold_to,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen
          name={screens.AddUserName}
          component={AddUserName}
          options={{
            headerShown: true,
            ...headerStyle,
            title: strings.user_info.just_the_basics,
            headerLeft: () => <BackButton />,
            headerRight: () => <View />,
          }}
        />
        <Stack.Screen
          name={screens.AddProfileImage}
          component={AddProfileImage}
          options={({ route }) => ({
            headerShown: true,
            ...headerStyle,
            title: strings.user_info.profile_photo,
            headerLeft: () => <BackButton />,
            headerRight: () => <View />,
          })}
        />
        <Stack.Screen
          name={screens.AddGender}
          component={AddGender}
          options={({ route }) => ({
            headerShown: true,
            ...headerStyle,
            title: strings.user_info.gender,
            headerLeft: () => <BackButton />,
            headerRight: () => <View />,
          })}
        />
        <Stack.Screen
          name={screens.VerifyEmail}
          component={VerifyEmail}
          options={{
            headerShown: true,
            ...headerStyleTransparentWithBackground,
            title: strings.user_info.email_verification,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen name={screens.AddBirthday} component={AddBirthday} />
        <Stack.Screen
          name={screens.Endorsements}
          component={Endorsements}
          options={{
            headerShown: true,
            title: strings.user.endorsements,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen name={screens.MapWithUserLocations} component={MapWithUserLocations} />
        <Stack.Screen name={screens.SearchScreen} component={SearchScreen} />
        <Stack.Screen
          name={screens.SnapshotItemDetailsScreen}
          component={SnapshotItemDetailsScreen}
        />
      </Stack.Navigator>
    </ContainerWeb>
  );
};

const AuthorizedUserDrawer = () => {
  const { isMobileWindow, windowCurrentWidth } = useGetWindowCurrentWidth();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await dispatch(communityOperations.getUserCommunity());

    dispatch(homeOperations.getCommunityHomePage());
    dispatch(appOperations.fetchAppData());
    dispatch(appOperations.fetchChatsData());

    AnalyticsService.setUserPreferences(userInfo.id, userInfo.name);
  };

  const scrollToY = useRef(new Animated.Value(0)).current;
  const changeMenuIconScale = useRef(new Animated.Value(1)).current;
  const changeCloseIconScale = useRef(new Animated.Value(0)).current;
  const scaleItemContainer = useRef(new Animated.Value(0.5)).current;

  const scrollToX = useRef(new Animated.Value(0)).current;
  const isRtl = I18nManager.getConstants().isRTL;

  const menuIconAnimation = Animated.timing(changeMenuIconScale, {
    toValue: isOpen ? 1 : 0,
    duration: 200,
    useNativeDriver: true,
  });

  const closeIconAnimation = Animated.timing(changeCloseIconScale, {
    toValue: isOpen ? 0 : 1,
    duration: 200,
    useNativeDriver: true,
  });

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    Animated.parallel([
      isOpen
        ? Animated.sequence([closeIconAnimation, menuIconAnimation])
        : Animated.sequence([menuIconAnimation, closeIconAnimation]),

      Animated.timing(scrollToY, {
        toValue: isOpen ? 0 : 1,
        duration: 500,
        useNativeDriver: true,
      }),
    ]).start();
  };

  const toggleDrawerWidth = () => {
    setIsOpen(!isOpen);
    Animated.timing(scaleItemContainer, {
      toValue: !isOpen ? 1 : 0.5,
      useNativeDriver: true,
      duration: 400,
    }).start();
  };

  const toggleNotification = () => {
    setIsOpenNotification(!isOpenNotification);
    Animated.timing(scrollToX, {
      toValue: isOpenNotification ? 0 : 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  };

  const translateY = scrollToY.interpolate({
    inputRange: [0, 1],
    outputRange: [0 - dimensions.height - 20, 0],
  });

  const translateX = scrollToX.interpolate({
    inputRange: [isRtl ? 0 : 0.2, 1],
    outputRange: [isRtl ? dimensions.width : -dimensions.width, isRtl ? -65 : 65],
  });

  return (
    <>
      {isMobileWindow && (
        <WebHeaderNavigator
          toggleDrawer={toggleDrawer}
          changeMenuIconScale={changeMenuIconScale}
          changeCloseIconScale={changeCloseIconScale}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
      <Drawer.Navigator
        drawerContent={(props) => (
          <>
            {isMobileWindow ? (
              <Animated.View
                style={[
                  s.drawerContainer,
                  { transform: [{ translateY }], width: windowCurrentWidth },
                ]}
              >
                <CustomDrawer
                  toggleDrawer={toggleDrawer}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  {...props}
                />
              </Animated.View>
            ) : (
              <>
                <CustomDrawer
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  scaleItemContainer={scaleItemContainer}
                  toggleDrawerWidth={toggleDrawerWidth}
                  toggleNotification={toggleNotification}
                  isOpenNotification={isOpenNotification}
                  translateX={translateX}
                  isRtl={isRtl}
                  {...props}
                />
                <Animated.View
                  style={[
                    s.notificationContainer,
                    {
                      transform: [{ translateX }],
                      shadowOffset: {
                        width: isRtl ? -dimensions.small / 2 : dimensions.small / 2,
                        height: 0.2,
                      },
                    },
                  ]}
                >
                  <Notifications
                    toggleDrawerWidth={toggleDrawerWidth}
                    toggleNotification={toggleNotification}
                  />
                </Animated.View>
              </>
            )}
          </>
        )}
        screenOptions={{
          drawerStyle: {
            width: isMobileWindow ? 0 : 248,
          },
          drawerPosition: isRTL ? 'right' : 'left',
          headerShown: false,
          drawerType: 'permanent',
        }}
        useLegacyImplementation
        options={{
          backgroundColor: colors.white,
        }}
      >
        <Drawer.Screen
          options={{
            sceneContainerStyle: {
              backgroundColor: colors.white,
            },
          }}
          name={screens.AuthorizedApplication}
          key={screens.AuthorizedApplication}
          component={AuthorizedApplicationNavigatorWithoutModals}
        />
      </Drawer.Navigator>
    </>
  );
};

export default AuthorizedUserDrawer;

const s = StyleSheet.create({
  drawerContainer: {
    position: 'absolute',
    backgroundColor: colors.grayLight,
    height: '100%',
  },
  notificationContainer: {
    position: 'absolute',
    width: 400,
    zIndex: 100,
    height: '100vh',
    borderLeftWidth: 0.5,
    borderLeftColor: '#000',
    borderTopEndRadius: dimensions.borderRadius,
    shadowColor: colors.gray,
    shadowOpacity: 0.7,
    shadowRadius: 10,
    elevation: 1,
  },
});
