import * as R from 'ramda';
import moment from 'moment';
import * as actions from './actions';
import homeApi from '../../api/home';
import { normalize } from '../../utils/stateHelper';
import { getIsUSCommunity } from '../../utils/usCommunity';
import { CITIES_LIMIT } from '../../constants/listLimits';
import communitiesApi from '../../api/communities';
import { appOperations } from '../app';
import { getExcludedItemIds } from './selectors';
import wishApi from '../../api/wish';

const getMostFollowedUsersForHome = (communityId) => async (dispatch, getState) => {
  try {
    const data = await homeApi.getMostFollowedUsersForHome(communityId);
    dispatch(actions.setMostFollowedUsers(data));
  } catch (e) {}
};

const getMyFollowersForHomePage = (communityId) => async (dispatch, getState) => {
  try {
    const data = await homeApi.getMyFollowersForHomePage(communityId);
    dispatch(actions.setMyFollowers(data));
  } catch (e) {}
};

const getTopSellersForHome = (communityId) => async (dispatch, getState) => {
  try {
    const data = await homeApi.getTopSellersForHome(communityId);
    dispatch(actions.setTopSellers(data));
  } catch (e) {}
};

const getMyLastSellersForHome = (communityId) => {
  return async (dispatch) => {
    const sellers = await homeApi.getMyLastSellersForHome(communityId);

    dispatch(actions.setMySellers(sellers));
  };
};

const getNewestItemsForHome = (communityId) => {
  return async (dispatch, getState) => {
    const excludedIds = getExcludedItemIds(getState());
    const items = await homeApi.getNewestItemsForHome(communityId, excludedIds);
    const itemIds = items.map(({ id }) => id);

    dispatch(actions.addExcludedItemIdsCache(itemIds));
    dispatch(actions.setNewestItems(items));
  };
};

const getNewestItemsInCommunityGroupsForHome = (communityId) => async (dispatch, getState) => {
  try {
    const data = await homeApi.getNewestItemsInCommunityGroupsForHome(communityId);
    const newestItemsInCommunityGroups = {};

    const itemEntities = {};

    R.forEachObjIndexed((value, key) => {
      if (R.isEmpty(value)) return;

      newestItemsInCommunityGroups[key] = value.map((item) => {
        itemEntities[item.id] = item;
        return item.id;
      });
    }, data);

    return {
      newestItemsInCommunityGroups,
      itemEntities,
    };
  } catch (e) {}
};

const getRecentFollowingItemsForHome = (communityId) => async (dispatch, getState) => {
  try {
    const excludedIds = getExcludedItemIds(getState());
    const data = await homeApi.getRecentFollowingItemsForHome(communityId, excludedIds);
    const normalizedData = normalize(data, 'itemIds', 'itemEntities');

    dispatch(actions.addExcludedItemIdsCache(normalizedData.itemIds));
    return normalizedData;
  } catch (e) {}
};
const getSellersNearMeForHome = (communityId) => async (dispatch, getState) => {
  try {
    const data = await homeApi.getSellersNearMeForHome(communityId);
    dispatch(actions.setSellersNearMe(data));
  } catch (e) {}
};
const getItemsNearPrimaryLocationForHome = (communityId) => async (dispatch, getState) => {
  try {
    const excludedIds = getExcludedItemIds(getState());
    const data = await homeApi.getItemsNearPrimaryLocationForHome(communityId, excludedIds);
    const normalizedData = normalize(data, 'itemIds', 'itemEntities');

    dispatch(actions.addExcludedItemIdsCache(normalizedData.itemIds));
    return normalizedData;
  } catch (e) {}
};
const getTopItemsForHome = (communityId) => async (dispatch, getState) => {
  try {
    const excludedIds = getExcludedItemIds(getState());
    const data = await homeApi.getTopItemsForHome(communityId, excludedIds);
    const normalizedData = normalize(data, 'itemIds', 'itemEntities');

    dispatch(actions.addExcludedItemIdsCache(normalizedData.itemIds));
    return normalizedData;
  } catch (e) {}
};
const getDiscountedItemsForHome = (communityId) => async (dispatch, getState) => {
  try {
    const excludedIds = getExcludedItemIds(getState());
    const data = await homeApi.getDiscountedItemsForHome(communityId, excludedIds);
    const normalizedData = normalize(data, 'itemIds', 'itemEntities');

    dispatch(actions.addExcludedItemIdsCache(normalizedData.itemIds));
    return normalizedData;
  } catch (e) {}
};
const getMyTopCategoriesForHome = (communityId) => async (dispatch, getState) => {
  try {
    const data = await homeApi.getMyTopCategoriesForHome(communityId);
    dispatch(actions.setMyTopCategories(data));
  } catch (e) {}
};
const getTopUploadedCategoriesForHome = (communityId) => async (dispatch, getState) => {
  try {
    const data = await homeApi.getTopUploadedCategoriesForHome(communityId);
    dispatch(actions.setTopUploadedCategories(data));
  } catch (e) {}
};

const getRewardsForHome = () => async (dispatch, getState) => {
  const { communityInfo } = getState();
  try {
    const data = await homeApi.getRewardsForHome(communityInfo.id);
    dispatch(actions.setRewards(data));
  } catch (e) {}
};

const getItemsNearMeForHome = ({ latitude, longitude }) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  try {
    const excludedIds = getExcludedItemIds(getState());
    const data = await homeApi.getItemsNearMeForHome({
      communityId: communityInfo.id,
      latitude,
      longitude,
      excludedIds,
    });

    const normalizedData = normalize(data, 'itemIds', 'itemEntities');
    dispatch(actions.addExcludedItemIdsCache(normalizedData.itemIds));
    dispatch(actions.setItemsNearMe(normalizedData));
  } catch (e) {}
};

const getCitiesForHome = (communityId) => async (dispatch, getState) => {
  try {
    const data = await communitiesApi.getCities({
      communityId,
      limit: CITIES_LIMIT,
    });
    dispatch(actions.setCities(data));
  } catch (e) {}
};

const getCampusesForHome = () => async (dispatch) => {
  try {
    const data = await communitiesApi.getCampuses({ limit: CITIES_LIMIT });
    dispatch(actions.setCampuses(data));
  } catch (error) {
    console.warn(error);
  }
};

export const getNewestWishesForHome = () => async (dispatch, getState) => {
  const { userInfo, lots } = getState();
  const userId = userInfo.id;

  try {
    const { wishes } = await wishApi.getWishes({
      userId,
      searchTerm: lots.feedWishFilters.searchTerm,
      groupIds: lots.feedWishFilters.groupIds,
    });
    const newestWishes = wishes.filter(({ created_at }) =>
      moment(created_at).isSameOrAfter(moment().subtract(2, 'days')),
    );
    dispatch(actions.setNewestWishes(wishes));
  } catch (error) {
    console.warn(error);
  }
};

export const getCommunityHomePage = () => async (dispatch, getState) => {
  const { communityInfo, app } = getState();

  const {
    has_most_followed_users,
    has_my_followers,
    has_top_sellers,
    has_my_last_sellers,
    has_newest_in_group,
    has_items_from_members_i_follow,
    has_items_near_me,
    has_discounted_items,
    has_my_top_categories,
    has_top_categories,
    has_sellers_near_you,
    has_top_items,
    has_newest_items,
    has_items_near_primary_location,
    has_popular_in_category,
    has_newest_in_categories,
  } = communityInfo.home_settings;

  const { isLocationPermissionGranted } = app;
  const isUSCommunity = getIsUSCommunity(communityInfo.id);

  dispatch(actions.loadingStart());

  let allItemEntities = {};
  let itemIdsTop = [];
  let itemIdsFromMembersIFollow = [];
  let itemIdsNearMe = [];
  let itemIdsNearPrimaryLocation = [];
  let itemIdsDiscounted = [];
  let newestItemsInCommunityGroups = {};

  await dispatch(getNewestWishesForHome());

  await dispatch(getRewardsForHome());

  if (isUSCommunity) {
    await dispatch(getCampusesForHome());
  } else {
    await dispatch(getCitiesForHome(communityInfo.id));
  }

  if (has_top_sellers) {
    await dispatch(getTopSellersForHome(communityInfo.id));
  }
  if (has_my_last_sellers) {
    await dispatch(getMyLastSellersForHome(communityInfo.id));
  }
  if (has_most_followed_users) {
    await dispatch(getMostFollowedUsersForHome(communityInfo.id));
  }
  if (has_my_followers) {
    await dispatch(getMyFollowersForHomePage(communityInfo.id));
  }

  if (has_sellers_near_you) {
    await dispatch(getSellersNearMeForHome(communityInfo.id));
  }

  if (has_my_top_categories) {
    await dispatch(getMyTopCategoriesForHome(communityInfo.id));
  }
  if (has_top_categories) {
    await dispatch(getTopUploadedCategoriesForHome(communityInfo.id));
  }

  if (has_top_items) {
    const data = await dispatch(getTopItemsForHome(communityInfo.id));

    itemIdsTop = R.propOr([], 'itemIds', data);
    allItemEntities = {
      ...allItemEntities,
      ...R.propOr({}, 'itemEntities', data),
    };
  }

  if (has_newest_items) {
    await dispatch(getNewestItemsForHome(communityInfo.id));
  }

  if (has_newest_in_group) {
    const data = await dispatch(getNewestItemsInCommunityGroupsForHome(communityInfo.id));

    newestItemsInCommunityGroups = R.propOr({}, 'newestItemsInCommunityGroups', data);
    allItemEntities = {
      ...allItemEntities,
      ...R.propOr({}, 'itemEntities', data),
    };
  }

  if (has_items_from_members_i_follow) {
    const data = await dispatch(getRecentFollowingItemsForHome(communityInfo.id));

    itemIdsFromMembersIFollow = R.propOr([], 'itemIds', data);
    allItemEntities = {
      ...allItemEntities,
      ...R.propOr({}, 'itemEntities', data),
    };
  }
  // if (has_items_near_me) {
  //   const data = await dispatch(getItemsNearMeForHome(communityInfo.id));
  //
  //   itemIdsNearMe = R.propOr([], 'itemIds', data);
  //   allItemEntities = {
  //     ...allItemEntities,
  //     ...R.propOr({}, 'itemEntities', data),
  //   };
  // }

  if (has_items_near_primary_location) {
    const data = await dispatch(getItemsNearPrimaryLocationForHome(communityInfo.id));

    itemIdsNearPrimaryLocation = R.propOr([], 'itemIds', data);
    allItemEntities = {
      ...allItemEntities,
      ...R.propOr({}, 'itemEntities', data),
    };
  }
  if (has_discounted_items) {
    const data = await dispatch(getDiscountedItemsForHome(communityInfo.id));

    itemIdsDiscounted = R.propOr([], 'itemIds', data);
    allItemEntities = {
      ...allItemEntities,
      ...R.propOr({}, 'itemEntities', data),
    };
  }

  dispatch(
    actions.setHomeItems({
      newestItemsInCommunityGroups: newestItemsInCommunityGroups,
      itemIdsTop: itemIdsTop,
      itemIdsFromMembersIFollow: itemIdsFromMembersIFollow,
      // itemIdsNearMe: itemIdsNearMe,
      itemIdsNearPrimaryLocation: itemIdsNearPrimaryLocation,
      itemIdsDiscounted: itemIdsDiscounted,
      itemEntities: allItemEntities,
    }),
  );

  if (isLocationPermissionGranted) {
    dispatch(appOperations.handleGeolocation());
  }
  dispatch(actions.loadingSuccess());
};

export const getItemsNearMeAfterCreatingPrimaryLocation = () => async (dispatch, getState) => {
  const { communityInfo } = getState();

  const { has_items_near_primary_location, has_sellers_near_you } = communityInfo.home_settings;

  // if (has_items_near_primary_location) {
  //   await dispatch(getItemsNearPrimaryLocationForHome(communityInfo.id));
  // }
  if (has_sellers_near_you) {
    await dispatch(getSellersNearMeForHome(communityInfo.id));
  }
};

export default {
  getCommunityHomePage,
  getItemsNearMeAfterCreatingPrimaryLocation,
  getRewardsForHome,
  getItemsNearMeForHome,
};
