import React from 'react';
import T from 'prop-types';
import { StyleSheet, View, ViewPropTypes } from 'react-native';
import { colors, dimensions } from '../../../../../styles';
import { isRTL } from '../../../../../utils/rtlHelper';
import Text from '../../../../ReusableComponents/Text';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  flag: {
    paddingLeft: dimensions.doubleMedium,
    paddingRight: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
    alignItems: 'center',
  },
  flagTail: {
    position: 'absolute',
    top: 0,
    left: isRTL ? -1 : 0,

    width: 0,
    height: 0,
    backgroundColor: colors.transparent,
    borderLeftWidth: 14,
    borderLeftColor: colors.grayLighter,
    borderTopWidth: 14,
    borderTopColor: colors.transparent,
    borderBottomWidth: 14,
    borderBottomColor: colors.transparent,
  },
});

const FlagLabel = ({ title, style }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View style={[s.flag, { backgroundColor: currentTheme.colors.secondary }, style]}>
      <View style={s.flagTail} />
      <Text color={colors.white} xsmall>
        {title}
      </Text>
    </View>
  );
};

FlagLabel.propTypes = {
  title: T.string.isRequired,
  style: ViewPropTypes.style,
};

export default FlagLabel;
