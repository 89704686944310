import { StyleSheet } from 'react-native';

import { fontSizes, dimensions } from '../../../styles/index';


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: dimensions.medium,
    justifyContent: 'center',
    minHeight: 48,
  },
  title: {
    fontSize: fontSizes.medium - 1,
  },
});

export { styles };
