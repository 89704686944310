import React, { Fragment } from 'react';
import * as R from 'ramda';
import { BrandView, styles } from './styles';
import { getCurrentTheme } from '../../../../templates';
import { Text } from '../../../ReusableComponents/index';
import { colors } from '../../../../styles';

class Info extends React.PureComponent {
  render() {
    const { brand, onSelect, selectedBrand } = this.props;

    const currentTheme = getCurrentTheme();

    const isActive =
      Number(brand.id) === Number(R.prop('id', selectedBrand)) ||
      (brand.id === 'no_brand' && !R.prop('id', selectedBrand));

    return (
      <Fragment>
        <BrandView onPress={onSelect} useOpacity={false}>
          <Text
            style={[
              styles.brandNameText,
              {
                color: isActive ? currentTheme.colors.secondaryLight : colors.gray,
              },
            ]}
          >
            {brand.name}
          </Text>
        </BrandView>
      </Fragment>
    );
  }
}

export default Info;
