import React from 'react';
import T from 'prop-types';
import { StyleSheet, View, ViewPropTypes } from 'react-native';
import Icon from '../../../../ReusableComponents/Icon';
import { colors } from '../../../../../styles';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  checkboxContainer: {
    width: 16,
    height: 16,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayLight,
    backgroundColor: colors.white,
    marginTop: 3,
  },
});

const CheckBoxIcon = ({ selected, style }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View
      style={[
        s.checkboxContainer,
        selected && {
          borderColor: currentTheme.colors.secondary,
          backgroundColor: currentTheme.colors.secondary,
        },
        style,
      ]}
    >
      <Icon name="check" size={14} color={colors.white} withoutRTLScale />
    </View>
  );
};

CheckBoxIcon.propTypes = {
  selected: T.bool,
  style: ViewPropTypes.style,
};

CheckBoxIcon.defaultProps = {
  selected: false,
  style: null,
};

export default CheckBoxIcon;
