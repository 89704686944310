import React from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import Text from './Text';
import { colors, dimensions, fontSizes } from '../../styles';
import { getCurrentTheme } from '../../templates';

const Button = ({ disabled, title, onPress, withMarginRight, secondaryType = false }) => {
  const currentTheme = getCurrentTheme();

  const buttonStyle = [
    styles.buttonContainer,
    { backgroundColor: getButtonBackgroundColor(disabled, secondaryType, currentTheme) },
    getMarginStyle(withMarginRight, secondaryType, currentTheme),
  ];

  const textStyle = [
    styles.buttonText,
    { color: getButtonTextColor(disabled, secondaryType, currentTheme) },
  ];

  const handlePress = () => {
    if (!disabled) {
      onPress();
    }
  };

  return (
    <TouchableOpacity disabled={disabled} style={buttonStyle} onPress={handlePress}>
      <Text disabled={disabled} style={textStyle}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const getButtonBackgroundColor = (disabled, secondaryType, currentTheme) => {
  if (disabled) {
    return colors.grayLighter;
  }
  return secondaryType ? colors.white : currentTheme.colors.secondaryLight;
};

const getButtonTextColor = (disabled, secondaryType, currentTheme) => {
  return disabled ? colors.gray : secondaryType ? currentTheme.colors.secondaryLight : colors.white;
};

const getMarginStyle = (withMarginRight, secondaryType, currentTheme) => {
  const marginStyle = {};
  if (withMarginRight) {
    marginStyle.marginRight = dimensions.halfMedium;
  }
  if (secondaryType) {
    marginStyle.borderColor = getButtonBackgroundColor(false, secondaryType, currentTheme);
    marginStyle.borderWidth = 1;
  }
  return marginStyle;
};

const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: dimensions.halfMedium,
    minWidth: 56,
    height: dimensions.commonHeight,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  buttonText: {
    fontSize: fontSizes.xsmall,
  },
});

export default Button;
