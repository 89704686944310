import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { chatsOperations, chatsSelectors } from '../../../../store/chats';
import { Container, EmptyListWithImage, HeaderContainer } from '../../../ReusableComponents';
import ChatsList from './components/ChatsList';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import SearchBar from '../../../ReusableComponents/SearchBar/index';
import { useDebouncedCallback } from 'use-debounce';
import { isWeb } from '../../../../utils/detectDevice';

const AdminSupportChatsSearchResults = ({
  searchedChats,

  isLoadingSearchedChats,
  isLoadingMoreSearchedChats,

  getSearchedChats,
  resetSearchedChats,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    getSearchedChats({
      isLoadMore: false,
    });
    return () => {
      resetSearchedChats();
    };
  }, []);

  const [getChatsDebounce] = useDebouncedCallback((newSearchTerm) => {
    getSearchedChats({
      searchTerm: newSearchTerm,
      isLoadMore: false,
    });
  }, 400);

  const onOpenChat = (chatId) => {
    NavigationService.navigate(screens.ChatRoom, { chatId });
  };

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  const onRefresh = async () => {
    setRefreshing(true);
    await getSearchedChats({
      searchTerm,
      isLoadMore: false,
    });
    setRefreshing(false);
  };

  const onLoadMore = async () => {
    await getSearchedChats({
      searchTerm,
      isLoadMore: true,
    });
  };

  const onChangeSearch = (value) => {
    setSearchTerm(value);
    getChatsDebounce(value);
  };

  return (
    <Container>
      <HeaderContainer>
        <SearchBar
          showCancel
          alwaysShowCancel
          value={searchTerm}
          placeholder={strings.other.search}
          onCancel={() => NavigationService.goBack()}
          onChangeText={onChangeSearch}
          enablesReturnKeyAutomatically
          returnKeyType="search"
        />
      </HeaderContainer>

      <ChatsList
        isHideArchiveButton
        isLoading={isLoadingSearchedChats}
        isLoadingMore={isLoadingMoreSearchedChats}
        chatList={isLoadingSearchedChats && !isRefreshing ? [] : searchedChats}
        onOpenChat={onOpenChat}
        onOpenUserProfile={onOpenUserProfile}
        onLoadMore={onLoadMore}
        isRefreshing={isRefreshing}
        onRefresh={onRefresh}
        ListEmptyComponent={() => (
          <EmptyListWithImage
            image="empty_state_search"
            text={strings.emptyLists.empty_chat_search}
          />
        )}
      />
    </Container>
  );
};

AdminSupportChatsSearchResults.propTypes = {
  searchedChats: T.array,
  isLoadingSearchedChats: T.bool,
  isLoadingMoreSearchedChats: T.bool,
  getSearchedChats: T.func,
  resetSearchedChats: T.func,
};

export default connect(
  (state) => ({
    isLoadingSearchedChats: state.chats.searchedChats.isLoading,
    isLoadingMoreSearchedChats: state.chats.searchedChats.isLoadingMore,
    searchedChats: chatsSelectors.getSearchedChats(state),
  }),
  (dispatch) => ({
    getSearchedChats: ({ isLoadMore, searchTerm = '' }) =>
      dispatch(
        chatsOperations.getSupportChatsForAdmins({ isLoadMore, searchTerm, isSearchScreen: true }),
      ),
    resetSearchedChats: () => dispatch(chatsOperations.resetSearchedChats()),
  }),
)(AdminSupportChatsSearchResults);
