import React from 'react';
import { connect } from 'react-redux';
import appOperations from '../../../store/app/operations';
import { NavigationService, AnalyticsService } from '../../../services';
import screens from '../../../navigation/screens';
import * as analyticEventTypes from '../../../constants/analyticsEventTypes';
import { Container } from '../../ReusableComponents';
import UsersMapView from './components/UsersMapView';

const DEFAULT_LOCATION = {
  latitude: 32.109333,
  longitude: 34.855499,
  latitudeDelta: 0.2,
  longitudeDelta: 0.2,
};

const UsersMap = ({ route, handleGeolocation, getUsersForMap }) => {
  const isLocationPermissionGranted = route?.params?.isLocationPermissionGranted;

  const onOpenUserProfile = (user) => {
    AnalyticsService.logEvent(analyticEventTypes.users_map_open_user_profile);

    NavigationService.navigate(screens.UserProfile, {
      name: user.name,
      userId: user.id,
      profile_image: user.profile_image,
    });
  };

  return (
    <Container>
      <UsersMapView
        initialLocation={DEFAULT_LOCATION}
        isLocationPermissionGranted={isLocationPermissionGranted}
        handleGeolocation={handleGeolocation}
        getUsersForMap={getUsersForMap}
        onOpenUserProfile={onOpenUserProfile}
      />
    </Container>
  );
};

export default connect(null, (dispatch) => ({
  getUsersForMap: (coordinates) => dispatch(appOperations.getUsersForMap(coordinates)),
  handleGeolocation: () => dispatch(appOperations.handleGeolocation(false)),
}))(UsersMap);
