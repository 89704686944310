import React from 'react';
import { ImageBackground, View, I18nManager } from 'react-native';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import RoundButton from '../Buttons/RoundButton';
import { dimensions, colors, rs } from '../../../styles';
import { StyleSheet } from 'react-native';
import strings from '../../../localization';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import { connect, useSelector } from 'react-redux';
import { numberWithCommas } from '../../../utils/stringHelper';
import { lotsOperations } from '../../../store/lots';
import earn_points_banner_background from '../../../assets/web/images/earn_points_banner_background.jpeg';
import marketplace_banner_background from '../../../assets/web/images/marketplace_banner_background.jpeg';
import { getCurrentTheme } from '../../../templates';

const s = StyleSheet.create({
  imageBackground: {
    height: 340,
    width: dimensions.width,
    backgroundColor: colors.grayLighter,
  },
  contentContainer: {
    flex: 1,
    paddingVertical: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    justifyContent: 'space-around',
  },
  text: {
    lineHeight: 26,
    fontSize: 20,
  },
});

const MarketplaceBanner = ({ analyticsEventType, setFeedLotFilters, secondType }) => {
  const wallet = useSelector((state) => state.wallets.wallet);
  const isRTL = I18nManager.getConstants().isRTL;

  const currentTheme = getCurrentTheme();

  const formattedWalletBalance = numberWithCommas(
    Number(wallet.balance_amount) - Number(wallet.hold_balance),
  );

  const onPress = () => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    setFeedLotFilters({
      isSearchByFollowings: false,
      groupIds: [],
      selectedCategoryIds: [],
      selectedSizes: [],
      selectedBrands: [],
      selectedConditions: [],
      searchTerm: null,
      isSearchByDiscount: false,
      sortBy: 'by_newest',
      selectedCities: [],
      selectedCampuses: [],
    });
    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    }, 0);
  };

  const backgroundImageUri = secondType
    ? earn_points_banner_background
    : marketplace_banner_background;

  return (
    <TouchableItem style={rs.marginHorizontal} onPress={onPress}>
      <ImageBackground
        style={[s.imageBackground, rs.webContainer, { transform: [{ scaleX: isRTL ? -1 : 1 }] }]}
        source={backgroundImageUri}
        resizeMode="cover"
      >
        <View style={[s.contentContainer, { transform: [{ scaleX: isRTL ? -1 : 1 }] }]}>
          <Text>{strings.home.hi_there},</Text>
          <Text bold style={[s.text, rs.smallPaddingBottom]}>
            {strings.home.you_have}
            <Text bold color={currentTheme.colors.secondary} style={s.text}>
              {' '}
              {formattedWalletBalance}
            </Text>
            {'\n'}
            {isRTL ? '!' : ''}
            {strings.home.tokens}
            {isRTL ? '' : '!'}
          </Text>

          <RoundButton title={strings.home.start_spending} onPress={onPress} />
        </View>
      </ImageBackground>
    </TouchableItem>
  );
};

export default connect(null, (dispatch) => ({
  setFeedLotFilters: (feedLotFilters) => dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
}))(MarketplaceBanner);
