import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Alert } from 'react-native';
import * as R from 'ramda';
import {
  View,
  Input,
  Text,
  Separator,
  Button,
  Container,
  TouchableItem,
} from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { notificationOperations } from '../../../../store/notifications';
import { ModalsService, NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import marketingNotificationGroups from '../../../../constants/marketingNotificationGroups';
import s from './styles';
import { isWeb } from '../../../../utils/detectDevice';
import modalTypes from '../../../../constants/modalTypes';
import appActionTypes from '../../../../constants/appActions';
import { groupsSelectors } from '../../../../store/groups';

const SendNotificationsScreen = ({
  navigation,
  route,
  sendNotificationToCommunity,
  sendMarketingNotification,
  groups,
}) => {
  const [targetUserGroup, setTargetUserGroup] = useState(marketingNotificationGroups.ALL_USERS);
  const [appAction, setAppAction] = useState(null);
  const [category, setCategory] = useState({
    groupId: [],
    categoryId: [],
  });
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    const isValidate = !!title && !!body && body.trim().length > 4;
    navigation.setParams({ onSubmit, isValidate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, body, targetUserGroup, appAction]);

  const getGroupTitle = useMemo(() => {
    if (R.isEmpty(category.groupId) && R.isEmpty(category.categoryId)) {
      return;
    }
    const group = R.find(R.propEq('id', category.groupId[0]))(groups);

    if (!R.isEmpty(category.categoryId)) {
      const selectedCategory = R.find(R.propEq('id', category.categoryId[0]))(group.categories);

      return `${group?.group_name}: ${selectedCategory?.name}`;
    }

    return group.group_name;
  }, [category]);

  const onPressTargetGroup = () => {
    NavigationService.navigate(screens.NotificationTargetGroup, {
      selectedTargetGroupId: targetUserGroup,
      onSelectTargetGroup: setTargetUserGroup,
    });
  };

  const onPressAppAction = () => {
    NavigationService.navigate(screens.NotificationAppAction, {
      selectedAppAction: appAction,
      onSelectAppAction: (action) => {
        setAppAction(action);
        setCategory({
          groupId: [],
          categoryId: [],
        });
      },
    });
  };

  const onPressItemCategories = () => {
    NavigationService.navigate(screens.GroupFilterStack, {
      screen: screens.GroupsList,
      params: {
        selectedCategory: category,
        onSetCategory: setCategory,
      },
    });
  };

  const onPressWishCategories = () => {
    NavigationService.navigate(screens.WishFilterStack, {
      screen: screens.WishGroupFilter,
      params: {
        onSetCategory: setCategory,
      },
    });
  };

  const onSubmitHandler = () => {
    navigation.setParams({ isValidate: false });
    const isMarketingNotification = !!appAction;
    const params = {
      title: title.trim(),
      body: body.trim(),
      ...(isMarketingNotification && { targetGroupId: targetUserGroup, appAction }),
      ...(category.groupId.length && { group_id: category.groupId[0] }),
      ...(category.categoryId.length && { category_id: category.categoryId[0] }),
    };

    const operation = isMarketingNotification
      ? sendMarketingNotification
      : sendNotificationToCommunity;

    operation(params);
  };

  const onSubmit = () => {
    if (isWeb) {
      ModalsService.showModal(modalTypes.ALERT, {
        title: strings.send_notifications.send_notifications_description,
        confirmButtonTitle: strings.common.confirm,
        cancelButtonTitle: strings.common.cancel,
        onSubmit: onSubmitHandler,
      });
    } else {
      Alert.alert('', strings.send_notifications.send_notifications_description, [
        {
          text: strings.common.confirm,
          onPress: onSubmitHandler,
        },
        {
          text: strings.common.cancel,
          onPress: () => {},
          style: 'cancel',
        },
      ]);
    }
  };

  return (
    <Container>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
      >
        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.settings.target_group}
          </Text>

          <TouchableItem onPress={onPressTargetGroup} style={rs.bigPaddingVertical}>
            <Text medium>{strings.community_notification_groups[targetUserGroup]}</Text>
          </TouchableItem>
        </View>

        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.settings.app_action}
          </Text>

          <TouchableItem onPress={onPressAppAction} style={rs.bigPaddingVertical}>
            {appAction ? (
              <Text medium>{appAction.toUpperCase()}</Text>
            ) : (
              <Text color={colors.grayBorder} small>
                {strings.settings.app_action_placeholder}
              </Text>
            )}
          </TouchableItem>
        </View>

        {(appAction === appActionTypes.OPEN_ITEMS_MARKETPLACE ||
          appAction === appActionTypes.OPEN_WISHES_MARKETPLACE) && (
          <View style={s.amountInputContainer}>
            <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
              {strings.groups.groups}
            </Text>

            <TouchableItem
              onPress={
                appAction === appActionTypes.OPEN_ITEMS_MARKETPLACE
                  ? onPressItemCategories
                  : onPressWishCategories
              }
              style={rs.bigPaddingVertical}
            >
              {category.groupId.length ? (
                <Text medium>{getGroupTitle}</Text>
              ) : (
                <Text color={colors.grayBorder} small>
                  {strings.filters.categories_placeholder}
                </Text>
              )}
            </TouchableItem>
          </View>
        )}

        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.common.title}
          </Text>

          <Input
            maxLength={32}
            onChangeText={setTitle}
            value={title}
            style={rs.bigPaddingVertical}
            placeholder={strings.send_notifications.title_placeholder}
            underlineColorAndroid="transparent"
          />
        </View>

        <Separator />

        <View style={s.amountInputContainer}>
          <Text medium style={[rs.bigPaddingTop, rs.paddingBottom, rs.backgroundGray]}>
            {strings.send_notifications.body}
          </Text>

          <Input
            maxLength={isWeb ? 5105 : 105}
            onChangeText={setBody}
            value={body}
            placeholder={strings.send_notifications.body_placeholder}
            underlineColorAndroid="transparent"
            multiline
            numberOfLines={4}
            style={s.bodyInput}
          />
        </View>

        <View style={s.buttonContainer}>
          <Button
            disabled={!route.params?.isValidate ?? false}
            onPress={isWeb ? onSubmitHandler : onSubmit}
            title={strings.common.submit}
          />
        </View>
      </KeyboardAwareScrollView>
    </Container>
  );
};

export default connect(
  (state) => ({
    // adminCommunities: [],
    groups: groupsSelectors.getCommunityGroups(state),
  }),
  (dispatch) => ({
    sendNotificationToCommunity: (params) =>
      dispatch(notificationOperations.sendNotificationToCommunity(params)),
    sendMarketingNotification: (params) =>
      dispatch(notificationOperations.sendMarketingNotification(params)),
  }),
)(SendNotificationsScreen);
