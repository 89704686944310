import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';
import {isWeb} from "../../../../utils/detectDevice";

const itemWidth = (dimensions.width - 6 * dimensions.medium) / 5;

export default StyleSheet.create({
  sizesContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingHorizontal: dimensions.halfMedium,
  },
  itemContainer: {
    minWidth: itemWidth,
    marginHorizontal: dimensions.halfMedium,
    paddingVertical: dimensions.halfMedium,
    paddingHorizontal: 4,
    borderWidth: dimensions.hairline,
    borderColor: colors.grayLight,
    borderRadius: 3,
    marginVertical: dimensions.halfMedium,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noSizeContainer: {
    width: '100%',
    height: 46,
    justifyContent: 'flex-end',
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayBorder,
    marginBottom: dimensions.medium,
    marginTop: isWeb ? dimensions.medium : 0,
  },
});
