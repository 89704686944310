import { createAction } from 'redux-actions';
import * as types from './types';

export const createItemSuccess = createAction(types.CREATE_ITEM_SUCCESS);
export const createSkillSuccess = createAction(types.CREATE_SKILL_SUCCESS);
export const createWishSuccess = createAction(types.CREATE_WISH_SUCCESS);

export const managementLotStart = createAction(types.MANAGEMENT_LOT_START);
export const managementLotError = createAction(types.MANAGEMENT_LOT_ERROR);

export const removeItem = createAction(types.REMOVE_ITEM);
export const updateItem = createAction(types.UPDATE_ITEM);
export const resetItem = createAction(types.RESET_ITEM);

export const removeWish = createAction(types.REMOVE_WISH);
export const updateWish = createAction(types.UPDATE_WISH);
export const resetWish = createAction(types.RESET_WISH);

export const removeSkill = createAction(types.REMOVE_SKILL);

export const lotsError = createAction(types.LOTS_ERROR);

export const itemsStart = createAction(types.ITEMS_START);
export const itemsSuccess = createAction(types.ITEMS_SUCCESS);
export const resetItems = createAction(types.RESET_ITEMS);

export const itemsRefreshStart = createAction(types.ITEMS_REFRESH_START);

export const itemsMoreStart = createAction(types.ITEMS_MORE_START);
export const itemsMoreSuccess = createAction(types.ITEMS_MORE_SUCCESS);

export const wishesStart = createAction(types.WISHES_START);
export const wishesSuccess = createAction(types.WISHES_SUCCESS);

export const wishesRefreshStart = createAction(types.WISHES_REFRESH_START);
export const wishesRefreshSuccess = createAction(types.WISHES_REFRESH_SUCCESS);

export const wishesMoreStart = createAction(types.WISHES_MORE_START);
export const wishesMoreSuccess = createAction(types.WISHES_MORE_SUCCESS);

export const initializeUserLotsState = createAction(types.INITIALIZE_USER_LOTS_STATE);

export const addItemEntity = createAction(types.ADD_ITEM_ENTITY);
export const addWishEntity = createAction(types.ADD_WISH_ENTITY);

export const userLotsStart = createAction(types.USER_LOTS_START);
export const userLotsError = createAction(types.USER_LOTS_ERROR);
export const userLotsSuccess = createAction(types.USER_LOTS_SUCCESS);
export const userSoldItemsSuccess = createAction(types.USER_SOLD_ITEMS_SUCCESS);
export const userPurchasedItemsSuccess = createAction(types.USER_PURCHASED_ITEMS_SUCCESS);
export const resetUserLots = createAction(types.RESET_USER_LOTS);

export const setFeedLotFilters = createAction(types.SET_FEED_LOT_FILTERS);
export const replaceMarketplaceFilterWithNew = createAction(
  types.REPLACE_MARKETPLACE_FILTERS_WITH_NEW,
);
export const setFeedWishFilters = createAction(types.SET_FEED_WISH_FILTERS);
export const resetFeedLotFilters = createAction(types.RESET_FEED_LOT_FILTERS);
export const setFilterGroupId = createAction(types.SET_FILTER_GROUP_ID);

export const toggleTopItem = createAction(types.TOGGLE_TOP_ITEM);
export const toggleItemFavorites = createAction(types.TOGGLE_ITEM_FAVORITES);
export const itemWasAddedToFavorites = createAction(types.ITEM_WAS_ADDED_TO_FAVORITES);
export const discountFavoriteItem = createAction(types.DISCOUNT_FAVORITE_ITEM);

export const itemWasSold = createAction(types.ITEM_WAS_SOLD);

export const savedItemsStart = createAction(types.SAVED_ITEMS_START);
export const saveditemSuccess = createAction(types.SAVED_ITEMS_SUCCESS);
export const savedItemsError = createAction(types.SAVED_ITEMS_ERROR);

export const pendingItemsStart = createAction(types.PENDING_ITEMS_START);
export const pendingItemSuccess = createAction(types.PENDING_ITEMS_SUCCESS);
export const pendingItemsError = createAction(types.PENDING_ITEMS_ERROR);
export const removeItemFromPendingList = createAction(types.REMOVE_ITEM_FROM_PENDING_LIST);
