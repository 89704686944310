import React from 'react';
import styled from 'styled-components/native';
import { Image } from 'react-native';
import strings from '../../../../localization';
import { dimensions, fontSizes, colors } from '../../../../styles';
import { headerStyleTransparent } from '../../../../styles/headerStyle';
import { Text, Container, CenteredView } from '../../../ReusableComponents';

const ContentContainer = styled(CenteredView)`
  padding-horizontal: ${dimensions.medium};
`;
const Title = styled(Text)`
  font-size: 28px;
  margin-bottom: 25px;
  text-align: center;
`;

const Description = styled(Text)`
  font-size: ${fontSizes.medium};
  text-align: center;
  margin-bottom: ${dimensions.halfIndent};
`;

class SuspendedUser extends React.Component {
  static navigationOptions = ({ navigation }) => ({
    title: null,
    ...headerStyleTransparent,
  });

  render() {
    return (
      <Container>
        <ContentContainer>
          <Image
            source={{ uri: 'owl_security' }}
            resizeMode="contain"
            style={{ width: 130, height: 130 }}
          />
          <Title title semiBold>{strings.modals.suspended_user_title}</Title>
          <Description>{strings.modals.suspended_user_body}</Description>
        </ContentContainer>
      </Container>
    );
  }
}

export default SuspendedUser;
