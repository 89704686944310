import React, { memo } from 'react';
import T from 'prop-types';
import { View, FlatList } from 'react-native';
import s from './styles';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import { colors, rs } from '../../../styles';
import FontIcon from '../FontIcon';
import Separator from '../Separator';
import strings from '../../../localization';
import { numberWithCommas } from '../../../utils/stringHelper';
import { AnalyticsService } from '../../../services';
import { getCurrentTheme } from '../../../templates';

const Item = memo(({ item, onPress }) => {
  const currentTheme = getCurrentTheme();
  return (
    <TouchableItem style={s.itemContainer} onPress={() => onPress(item)}>
      <FontIcon name={item.category_icon} size={38} color={colors.secondary} />

      <Text semiBold style={rs.paddingTop} alignCenter>
        {item.category_name}
      </Text>

      <Separator style={s.separator} />

      <Text xsmall color={colors.gray}>
        <Text xsmall semiBold color={currentTheme.colors.secondary}>
          {numberWithCommas(item.uploaded_items_count)}
        </Text>{' '}
        {strings.home.items}
      </Text>
    </TouchableItem>
  );
});

const HorizontalCategoriesList = memo(
  ({ categories, onSelect, analyticsEventType, refToScroll }) => {
    const onPress = (item) => {
      if (analyticsEventType) {
        AnalyticsService.logEvent(analyticsEventType);
      }
      onSelect(item);
    };
    return (
      <View style={s.contentContainer}>
        <FlatList
          ref={refToScroll}
          horizontal
          inverted
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={s.listContentContainerStyle}
          data={categories}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item }) => <Item item={item} onPress={onPress} />}
        />
      </View>
    );
  },
);

HorizontalCategoriesList.propTypes = {
  categories: T.array,
};

export default HorizontalCategoriesList;
