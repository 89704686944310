import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { FlatList } from 'react-native';
import T from 'prop-types';
import { Container } from '../../../ReusableComponents';
import GroupItem from './components/GroupItem';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { groupsSelectors } from '../../../../store/groups';
import screens from '../../../../navigation/screens';
import { lotsOperations } from '../../../../store/lots';
import { SHARINGOOD_COMMUNITY } from '../../../../constants/communities';

const GroupsList = ({ groups, selectAllGroups, totalItemsAmount, route }) => {
  const [sortedGroups, setSortedGroups] = useState([]);
  //console.log('sortedGroups', groups);

  useEffect(() => {
    const updatedGroups = [...groups].sort((a, b) => a.group_name?.localeCompare(b.group_name));
    setSortedGroups(updatedGroups);
  }, [groups]);

  const communityName = useSelector((state) => state.communityTheme.theme);

  const onGroupPress = (groupId) => {
    NavigationService.navigate(screens.MultipleCategories, {
      groupId,
      onSetCategory: route.params?.onSetCategory,
    });
  };

  const onSelectAllGroups = () => {
    route.params?.onSetCategory({ groupId: [], categoryId: [] });
    selectAllGroups();
    NavigationService.pop();
  };

  return (
    <Container>
      <FlatList
        data={sortedGroups}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <GroupItem
            onPress={onGroupPress}
            id={item.id}
            name={item.group_name}
            activeItemsCount={item.active_items_count}
            backgroundImage={item.background_image}
          />
        )}
        ListHeaderComponent={
          <GroupItem
            onPress={onSelectAllGroups}
            id="group"
            name={
              communityName === SHARINGOOD_COMMUNITY
                ? strings.profile.view_all_sharingood
                : strings.profile.view_all
            }
            activeItemsCount={totalItemsAmount}
            backgroundImage={
              communityName === strings.main.app_name.toLowerCase()
                ? 'all_groups'
                : 'category_items'
            }
            isAllGroups
          />
        }
      />
    </Container>
  );
};

GroupsList.propTypes = {
  getCommunitiesList: T.func,
};

export default connect(
  (state) => ({
    groups: groupsSelectors.getCommunityGroups(state),
    totalItemsAmount: groupsSelectors.getTotalItemsAmountInAllCommunityGroups(state),
  }),
  (dispatch) => ({
    selectAllGroups: () =>
      dispatch(lotsOperations.setFeedLotFilters({ groupIds: [], selectedCategoryIds: [] })),
  }),
)(GroupsList);
