import React, { useEffect, useState, Fragment, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useActionSheet } from '@expo/react-native-action-sheet';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import he from 'dayjs/locale/he';
import br from 'dayjs/locale/pt-br';
import de from 'dayjs/locale/de';
import { SafeAreaView, View, ActivityIndicator, Text as RNText, I18nManager } from 'react-native';
import { BackButton, EmptyListWithImage, FastImage, Image } from '../../../ReusableComponents';
import {
  GiftedChat,
  Bubble,
  SystemMessage,
  Send,
  Composer,
  MessageText,
  Message,
  Time,
  LoadEarlier,
} from 'react-native-gifted-chat';
import * as R from 'ramda';
import { useIsFocused } from '@react-navigation/native';
import {
  OfferButtonsContainer,
  UrlSocialMetadata,
  InterlocutorInfo,
  ContactCard,
  MessageImage,
} from './components';
import UploadImagePreview from './components/UploadImagePreview';
import { colors, rs } from '../../../../styles';
import { chatsOperations, chatsSelectors } from '../../../../store/chats';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import { offersSelectors, offersOperations } from '../../../../store/offers';
import { appOperations } from '../../../../store/app';
import {
  Spinner,
  CenteredView,
  Text,
  TokenIcon,
  Separator,
  TouchableItem,
  FontIcon,
} from '../../../ReusableComponents';
import s from './styles';
import strings from '../../../../localization';
import { ModalsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';

import NavigationService from '../../../../services/NavigationService';
import { groupsSelectors } from '../../../../store/groups';
import screens from '../../../../navigation/screens';
import { OFFER_CONFIRMED, ITEM_DELIVERED } from '../../../../store/offers/offerStatuses';
import itemStatuses from '../../../../constants/itemStatuses';
import { numberWithCommas } from '../../../../utils/stringHelper';
import { createDynamicLink } from '../../../../utils/shareHelper';
import {
  OPEN_USER_PROFILE,
  OPEN_ITEM_DETAILS,
} from '../../../../constants/dynamicalLinkActionTypes';
import { communitySelectors } from '../../../../store/communityInfo';
import { getHasQuickSupportAccess } from '../../../../store/userInfo/selectors';
import { useGetWindowCurrentWidth } from '../../../../utils/getWindowCurrentWidth';
import WebcamCapture from './components/WebcamCapture';
import ChatsListContainer from '../ChatListsGroup/components/ChatsListContainer';
import InboxContainer from '../../../ReusableComponents/Containers/InboxContainer';
import ChatArchivedListContainer from '../ChatListsGroup/components/ChatArchivedListContainer';
import ChatListAdminContainer from '../ChatListsGroup/components/ChatListAdminContainer';
import { getCurrentTheme } from '../../../../templates';

const ChatRoom = ({
  navigation,
  route,
  appLanguage,

  insideChatRoomId,
  isLoadingChatInfo,
  chat,
  item,
  offer,

  createPersonalChat,
  createItemChat,
  getChatByItemId,
  getPersonalChatByUserId,
  getChatById,
  getItemSnapshotByOfferId,

  isAdmin,
  user,
  isLoading,
  isLoadingMore,
  isExistMore,
  messages,

  sendMessage,
  sendMessageWithWishConnection,
  isCurrentUserBlocker,
  isCurrentUserBlocked,
  hasQuickSupportAccess,

  uploadChatImage,
  getItemInfo,
  setInsideRoomId,
  getChatMessages,
  markAsRead,

  resetTemporaryChat,
  groupInfo,
  updateOffer,
  isLoadingOffer,

  processDeepLink,
  sendMarkMessageAsRead,
  sendUserTypingMessage,
  isTypingMessage,
  getContacts,
  openSupportChat,
}) => {
  const chatId = route.params?.chatId;
  const wishId = route.params?.wishId;
  const { showActionSheetWithOptions } = useActionSheet();
  const [itemSnapshot, setItemSnapshot] = useState(null);
  const [isNeedSendWishIdWishMessage, setNeedSendWishIdWishMessage] = useState(!!wishId);
  const [imageSource, setImageSource] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isChatFetched, setChatFetched] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [isLoadingCameraPermission, setIsLoadingCameraPermission] = useState(false);
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);
  const isRTL = I18nManager.getConstants().isRTL;

  const currentTheme = getCurrentTheme();

  const { windowCurrentWidth, isMobileWindow } = useGetWindowCurrentWidth();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused && insideChatRoomId === chatId) {
      setInsideRoomId(null);
    }

    if (isFocused && insideChatRoomId !== chatId) {
      setInsideRoomId(chatId);
    }

    if (isFocused && chat.unread_count) {
      markAsRead(chatId);
    }
  }, [isFocused, chatId]);

  useEffect(() => {
    const [lastMessage] = messages;
    const hasUnreadMessages = lastMessage && lastMessage.user_id !== user.id && !lastMessage.read;

    if (hasUnreadMessages) {
      sendMarkMessageAsRead(chatId);
    }
  }, [messages]);

  useEffect(() => {
    fetchChatInformation();
  }, [chatId]);

  useEffect(() => {
    return () => {
      setInsideRoomId(null);
      markAsRead(chatId);
      setIsCameraVisible(false);
    };
  }, []);

  useEffect(() => {
    return () => {
      markAsRead(chatId);
      sendMarkMessageAsRead(chatId);
    };
  }, [chatId]);

  useEffect(() => {
    // github issue (unsolved): https://github.com/FaridSafi/react-native-gifted-chat/issues/1806
    // gifted-chat doesn't show typing indicator if isTyping=true was provided to component on first render
    // add an extra timeout to show indicator in case of navigating from chat list to chat room
    if (!isChatFetched) {
      return;
    }

    setTimeout(() => setIsTyping(isTypingMessage), 500);
  }, [isChatFetched, isTypingMessage]);

  useEffect(() => {
    if (isChatFetched) {
      const initialMessage = route.params.initialMessage ?? null;

      if (initialMessage) {
        const isChatAlreadyExists = !chatId.toString().startsWith('TEMPORARY_CHAT_ID');
        const messageDelay = isChatAlreadyExists ? 1000 : 0;

        setTimeout(() => onSendMessage(initialMessage, 'text'), messageDelay);
      }
    }
  }, [isChatFetched]);

  const fetchChatInformation = async () => {
    if (R.isEmpty(chat) || !R.prop('id', chat)) {
      await getChatById(chatId, true);
    }

    const snapshotOfferStatuses = [
      // OFFER_CONFIRMED,
      ITEM_DELIVERED,
    ];

    const currentOfferStatusId = Number(R.propOr(0, 'status_id', offer));
    if (snapshotOfferStatuses.includes(currentOfferStatusId)) {
      const snapshot = await getItemSnapshotByOfferId(offer.id);
      setItemSnapshot(snapshot);
    } else if (chat.type === 'item') {
      getItemInfo(chat.item_id);
    }

    if (chatId.toString().startsWith('TEMPORARY_CHAT_ID')) {
      const chatResponse = await (chat.type === 'item'
        ? getChatByItemId(chat.item_id)
        : getPersonalChatByUserId(chat.interlocutor_id));

      if (!R.isEmpty(chatResponse)) {
        navigation.setParams({ chatId: chatResponse.id });
      }
    } else {
      getChatMessages(chatId);

      if (chat.offer_id) {
        updateOffer(chat.offer_id);
      }
    }

    setChatFetched(true);
  };

  const onNewChatWasCreatedFromAskToBuy = async (chatRoomId) => {
    navigation.setParams({ chatId: chatRoomId });
  };

  const onSendMessage = async (payload, payloadType) => {
    if (chatId.toString().startsWith('TEMPORARY_CHAT_ID')) {
      let chatResponse;
      if (chat.type === 'item') {
        chatResponse = await createItemChat(chat.item_id, payload, payloadType);
      } else if (isNeedSendWishIdWishMessage) {
        chatResponse = await createPersonalChat(chat.interlocutor_id, payload, payloadType, wishId);
        setNeedSendWishIdWishMessage(false);
      } else {
        chatResponse = await createPersonalChat(chat.interlocutor_id, payload, payloadType);
      }
      navigation.setParams({ chatId: chatResponse.id });
    } else if (isNeedSendWishIdWishMessage) {
      sendMessageWithWishConnection(chatId, payload, payloadType, wishId);
      setNeedSendWishIdWishMessage(false);
    } else {
      sendMessage(chatId, payload, payloadType);
    }
  };

  const onCheckBlockAndSendMessage = async (chatMessages) => {
    if (isCurrentUserBlocker) {
      ModalsService.showModal(modalTypes.UNBLOCK_USER, {
        userId: route?.params?.defaultValue,
      });
      return;
    }

    if (isCurrentUserBlocked) {
      ModalsService.showModal(modalTypes.SOMETHING_WENT_WRONG);
      return;
    }

    const payloadType = R.is(Array, chatMessages) ? 'text' : 'image';
    const payload = R.is(Array, chatMessages) ? chatMessages[0].text : chatMessages;

    onSendMessage(payload, payloadType);
  };

  const [onInputTextChanged] = useDebouncedCallback((text) => {
    // This check was added to prevent useless invokes on chat open
    // (react-native-gifted-chat bug)
    if (text) {
      sendUserTypingMessage(chatId);
    }
  }, 300);

  const onShareContact = (contact) => {
    const contentText = contact.phoneNumbers[0].number;

    sendMessage(chatId, contentText, 'contact', contact);
  };

  const onPressActionButton = () => {
    const options = [
      strings.modals.take_photo,
      strings.modals.choose_photo,
      strings.modals.share_member,
      strings.modals.share_item,
      // strings.share.share_contact,
      strings.common.cancel,
    ];

    const cancelButtonIndex = 4;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            onTakePhoto();
            break;
          case 1:
            onSelectImageFromGallery();
            break;

          case 2:
            onPressShareMember();
            break;
          case 3:
            onPressShareItem();
            break;
          // case 4:
          //   getContacts({ onSubmit: onShareContact });
          //   break;
          default:
            break;
        }
      },
    );
  };

  const onPressDeepLink = async (link) => {
    const resolvedLink = await dynamicLinks().resolveLink(link);

    return processDeepLink(resolvedLink);
  };

  const parsePatterns = (linkStyle) => {
    const deepLinkRegExp = new RegExp(/https:\/\/shareitt\.page\.link\/(\S+)/);

    return [{ pattern: deepLinkRegExp, style: linkStyle, onPress: onPressDeepLink }];
  };

  const getLocale = () => {
    switch (appLanguage) {
      case 'he':
        return he;
      case 'br':
        return br;
      case 'de':
        return de;
      default:
        return null;
    }
  };

  const onContactSupportPress = () => {
    openSupportChat();
  };

  const renderBubble = (props) => {
    const isUserNameVisible =
      isAdmin &&
      chat.type === 'support' &&
      R.path(['previousMessage', 'user', '_id'], props) !==
        R.path(['currentMessage', 'user', '_id'], props) &&
      R.path(['currentMessage', 'user', '_id'], props) !== R.path(['user', '_id'], props);
    const isLastMessageForUser =
      !props.nextMessage ||
      R.path(['currentMessage', 'user', '_id'], props) !==
        R.path(['nextMessage', 'user', '_id'], props);

    return (
      <View renderToHardwareTextureAndroid style={rs.fullWidth}>
        {isUserNameVisible && (
          <Text xsmall semiBold color={colors.gray} style={s.userNameForAdminText}>
            {props.currentMessage.user.name}
          </Text>
        )}

        <Bubble
          {...props}
          // renderTicks={() => <View style={{ height: 50, width: 100, backgroundColor: 'green' }} />}
          wrapperStyle={{
            left: {
              backgroundColor: isRTL ? colors.chatBubbleCurrentUser : colors.chatBubbleInterlocutor,
              ...s.messageBubble,
              ...(isLastMessageForUser ? { borderBottomLeftRadius: 0 } : {}),
            },
            right: {
              backgroundColor: isRTL ? colors.chatBubbleInterlocutor : colors.chatBubbleCurrentUser,
              ...s.messageBubble,
              ...(isLastMessageForUser ? { borderBottomRightRadius: 0 } : {}),
            },
          }}
          textStyle={{
            left: {
              color: colors.text,
            },
            right: {
              color: colors.text,
            },
          }}
        />
      </View>
    );
  };

  const getPosition = (props) => {
    let position = props.position;
    if (isRTL) {
      position = props.position === 'left' ? 'right' : 'left';
    }
    return position;
  };

  const renderMessage = (props) => {
    return <Message {...props} position={getPosition(props)} />;
  };

  const renderTime = (props) => {
    return (
      <Time
        {...props}
        position={getPosition(props)}
        containerStyle={{
          left: s.timeContainer,
          right: s.timeContainer,
        }}
        timeTextStyle={{
          left: s.timeText,
          right: s.timeText,
        }}
      />
    );
  };

  const renderTicks = (props) => {
    const { currentMessage, user } = props;

    if (currentMessage && user && currentMessage.user && currentMessage.user._id !== user._id) {
      return null;
    }

    if (currentMessage && (currentMessage.sent || currentMessage.received)) {
      const isRead = currentMessage.read;
      return (
        <View style={s.tickView}>
          <Fragment>
            {!!currentMessage.sent && (
              <RNText style={[s.tick, isRead && s.readMessageTick, { zIndex: 5 }]}>✓</RNText>
            )}
            {!!currentMessage.received && (
              <RNText style={[s.tick, isRead && s.readMessageTick, { left: -3 }]}>✓</RNText>
            )}
          </Fragment>
        </View>
      );
    }

    return null;
  };

  const renderMessageText = (props) => {
    const isOnlyLinkInMessageRegExp = new RegExp(/^https:\/\/shareitt\.page\.link\/\S+$/);
    const isOnlyLinkInMessage = isOnlyLinkInMessageRegExp.test(props.currentMessage.text);

    if (props.currentMessage.type === 'contact' && props.currentMessage.extra) {
      return (
        <View style={s.textMessageContainer}>
          <ContactCard
            contact={props.currentMessage.extra}
            renderFooter={() => (
              <View style={[s.messageFooter, s.textMessageFooter]}>
                {renderTime(props)}
                {renderTicks(props)}
              </View>
            )}
          />
        </View>
      );
    }

    return (
      <View style={s.textMessageContainer}>
        {!isOnlyLinkInMessage && (
          <MessageText
            {...props}
            customTextStyle={s.messageText}
            linkStyle={{ left: { color: colors.text }, right: { color: colors.text } }}
            position={getPosition(props)}
          />
        )}
        <UrlSocialMetadata {...props} />

        <View style={[s.messageFooter, s.textMessageFooter]}>
          {renderTime(props)}
          {renderTicks(props)}
        </View>
      </View>
    );
  };

  const renderMessageImage = (props) => {
    const { currentMessage } = props;

    return (
      <View>
        {!currentMessage.received && (
          <View style={s.previewIndicator}>
            <ActivityIndicator color={colors.white} />
          </View>
        )}
        <MessageImage {...props} />

        <View style={s.messageFooter}>
          {renderTime(props)}
          {renderTicks(props)}
        </View>
      </View>
    );
  };

  const renderSystemMessage = (props) => {
    const { currentMessage } = props;

    const backgroundColorStyle = {
      backgroundColor:
        {
          dismiss: colors.coral,
          success: colors.green,
          info: currentTheme.colors.secondary,
        }[currentMessage.type] || colors.grayLight,
    };

    const iconName =
      {
        dismiss: 'System_msgs_decline',
        success: 'System_msgs_approval',
        info: 'System_msgs',
      }[currentMessage.type] || 'Transactions_Issue';

    return (
      <View style={[s.systemMessageContainer]}>
        <View style={[s.systemMessageBadge, backgroundColorStyle]} />
        <View style={[rs.alignCenter, rs.row, rs.paddingVertical, rs.fullWidth]}>
          <View style={[s.systemMessageIconContainer, backgroundColorStyle]}>
            <FontIcon withoutRTLScale name={iconName} size={16} color={colors.white} />
          </View>

          <View style={s.systemMessageTextContainer}>
            <Text color={colors.gray}>{currentMessage.text}</Text>
          </View>
        </View>
      </View>
    );
  };

  const renderSend = (props) => (
    <Send {...props}>
      <Text style={s.sendButtonText} color={colors.blue} medium>
        {strings.common.send}
      </Text>
    </Send>
  );
  const renderLoadEarlier = (props) => {
    if (isExistMore) {
      return <LoadEarlier {...props} />;
    } else if (chat.type !== 'support' || isAdmin) {
      return <InterlocutorInfo chat={chat} />;
    }
  };

  const renderComposer = (props) => {
    if (chat.is_blocked) {
      return <View />;
    }

    return (
      <Composer
        {...props}
        textInputStyle={[s.textInput, { textAlign: 'left', direction: isRTL ? 'rtl' : 'ltr' }]}
      />
    );
  };

  const onOpenUserProfile = () => {
    NavigationService.navigate(screens.UserProfile, {
      name: chat.interlocutor_name,
      userId: chat.interlocutor_id,
      profile_image: chat.interlocutor_image,
    });
  };

  const onPressShareMember = () => {
    NavigationService.navigate(screens.IssueMemberList, {
      onSubmit: onShareMemberSubmit,
    });
  };

  const onShareMemberSubmit = (members) => {
    members.forEach(async (member) => {
      const link = await createDynamicLink({
        actionType: OPEN_USER_PROFILE,
        id: member.id,
        socialImageUrl: member.profile_image,
        socialTitle: member.name,
        socialDescription: member.nickname,
      });

      sendMessage(chatId, link, 'text');
    });
    NavigationService.pop();
  };

  const onPressShareItem = () => {
    NavigationService.navigate(screens.ItemsSelectionScreen, {
      userId: user.id,
      onSubmit: onShareItemSubmit,
    });
  };

  const onShareItemSubmit = (lots) => {
    lots.forEach(async (lot) => {
      const link = await createDynamicLink({
        actionType: OPEN_ITEM_DETAILS,
        id: lot.id,
        communityId: lot.group_id,
        socialImageUrl: lot.item_image,
        socialTitle: lot.title,
        socialDescription: lot.description,
        linkDescription: strings.deep_linking.item_link_info,
      });

      sendMessage(chatId, link, 'text');
    });

    NavigationService.pop();
  };

  const onOpenItemDetails = () => {
    if (itemSnapshot) {
      NavigationService.push(screens.SnapshotItemDetailsScreen, { item: itemSnapshot });
    } else {
      NavigationService.navigate(screens.ItemDetails, { itemId: chat.item_id });
    }
  };

  const sendImageMessage = async () => {
    setIsPreviewVisible(false);
    await onCheckBlockAndSendMessage(imageSource);
    setImageSource(null);
  };

  const onCancelImageUpload = () => {
    setIsPreviewVisible(false);
    setImageSource(null);
  };

  const capture = useCallback(() => {
    const image = webcamRef.current.getScreenshot();

    setImageSource(image);
    setIsPreviewVisible(true);
    setIsCameraVisible(false);
  }, [webcamRef]);

  const onTakePhoto = async () => {
    setIsLoadingCameraPermission(true);
    await window.navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((result) => {
        setIsCameraVisible(true);
        result.getTracks().forEach((track) => {
          track.stop();
        });
      })
      .catch((err) =>
        ModalsService.showModal(modalTypes.INFO, {
          title: err.message || strings.modals.deep_linking.oops,
          description: strings.permissions.permission_text,
        }),
      )
      .finally(() => setIsLoadingCameraPermission(false));
  };

  const onSelectImageFromGallery = () => {
    fileInputRef.current?.click();
  };

  const setImageForWeb = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSource(reader.result);
      setIsPreviewVisible(true);
    };
  };

  const headerItem = itemSnapshot || item;

  const isActive = headerItem.status === itemStatuses.ACTIVE;

  const itemPrice = chat.type === 'item' ? headerItem.discounted_price || headerItem.price : '';

  const isUserSupportChat = chat.type === 'support' && !isAdmin;
  const isCurrentUserBuyer = parseInt(chat.item_seller_id, 10) !== user.id;
  const isOfferConfirmed = parseInt(offer.status_id, 10) === OFFER_CONFIRMED;

  const showOfferContainer =
    // if chat has been blocked, show only refund option
    !chat.is_blocked || (chat.is_blocked && !isCurrentUserBuyer && isOfferConfirmed);
  const showQuickSupportBanner = chat.type !== 'support' && !isAdmin && hasQuickSupportAccess;

  return !isMobileWindow ? (
    <InboxContainer>
      <View style={[rs.row, rs.fullWidth, rs.fullHeight]}>
        {route.params?.isArchivedChats ? (
          <ChatArchivedListContainer />
        ) : route.params?.isAdminChats ? (
          <ChatListAdminContainer />
        ) : (
          <ChatsListContainer />
        )}
        <View style={[s.chatsContainer]}>
          {chat.type === 'item' ? (
            <View style={s.headerContainer}>
              <View style={s.appBarContainer}>
                <BackButton />
                <TouchableItem onPress={onOpenItemDetails} style={[rs.row, rs.alignCenter]}>
                  <FastImage style={s.itemImage} source={{ uri: chat.item_image }} />
                  <View>
                    <Text bold>{chat.item_title}</Text>
                    <Text xsmall>
                      {Number(chat.item_seller_id) === user.id
                        ? strings.buy_sell.buyer
                        : strings.buy_sell.seller}
                      : {chat.interlocutor_name}
                    </Text>
                  </View>
                </TouchableItem>
              </View>
              <View style={rs.paddingHorizontal}>
                <Separator />
              </View>
              {showOfferContainer && (
                <View style={s.offerButtonsContainer}>
                  <View style={[s.priceContainer, { paddingLeft: 0 }]}>
                    <Image style={s.priceImage} source={{ uri: 'price' }} resizeMode="contain" />
                    <Text medium bold color={currentTheme.colors.secondary} style={s.priceText}>
                      {itemPrice ? numberWithCommas(itemPrice) : ''}
                    </Text>
                  </View>
                  <OfferButtonsContainer
                    isLoadingOffer={isLoadingOffer}
                    offer={offer}
                    isCurrentUserBuyer={isCurrentUserBuyer}
                    isItemActive={isActive}
                    itemId={headerItem.id}
                    hasMultipleSupply={headerItem.has_multiple_supply}
                    onNewChatWasCreatedFromAskToBuy={onNewChatWasCreatedFromAskToBuy}
                  />
                </View>
              )}
            </View>
          ) : (
            <View style={s.headerContainer}>
              <View style={s.appBarContainer}>
                <BackButton />
                <TouchableItem
                  style={[rs.row, rs.alignCenter]}
                  onPress={onOpenUserProfile}
                  disabled={isUserSupportChat}
                >
                  <FastImage
                    style={s.userImage}
                    source={{ uri: isUserSupportChat ? chat.chat_avatar : chat.interlocutor_image }}
                  />
                  <Text medium bold>
                    {isUserSupportChat ? chat.chat_name : chat.interlocutor_name}
                  </Text>
                </TouchableItem>
              </View>
            </View>
          )}

          {showQuickSupportBanner && (
            <View style={s.supportBannerContainer}>
              <Text>
                {strings.chats.need_help}{' '}
                <Text
                  bold
                  color={colors.secondary}
                  style={s.supportButton}
                  onPress={onContactSupportPress}
                >
                  {strings.chats.contact_support}
                </Text>
              </Text>
            </View>
          )}

          <GiftedChat
            messages={messages}
            bottomOffset={0}
            onSend={onCheckBlockAndSendMessage}
            onPressAvatar={isUserSupportChat ? null : onOpenUserProfile}
            user={{
              _id: user.id,
              name: user.name,
            }}
            isAnimated
            renderLoading={() => (
              <CenteredView>
                <Spinner size="large" />
              </CenteredView>
            )}
            renderChatEmpty={() =>
              isLoading || isLoadingChatInfo ? (
                <CenteredView>
                  <Spinner size="large" />
                </CenteredView>
              ) : (
                <View style={rs.flex} />
              )
            }
            onPressActionButton={!chat.is_blocked ? onPressActionButton : null}
            onInputTextChanged={onInputTextChanged}
            renderBubble={renderBubble}
            renderMessage={renderMessage}
            renderSystemMessage={renderSystemMessage}
            renderMessageText={renderMessageText}
            renderMessageImage={renderMessageImage}
            renderSend={renderSend}
            placeholder={strings.other.chat_placeholder}
            renderComposer={renderComposer}
            loadEarlier={true}
            onLoadEarlier={() => getChatMessages(chatId, true)}
            isLoadingEarlier={isLoadingMore}
            parsePatterns={parsePatterns}
            renderLoadEarlier={renderLoadEarlier}
            renderTime={() => null}
            renderTicks={() => null}
            isTyping={isTyping}
            // listViewProps={{
            //   style: { backgroundColor: colors.chatBackground },
            // }}
            disableComposer={chat.is_blocked ?? false}
            locale={getLocale()}
          />

          <UploadImagePreview
            imageSource={imageSource}
            isVisible={isPreviewVisible}
            onSave={sendImageMessage}
            onCancel={onCancelImageUpload}
          />
          <WebcamCapture
            capture={capture}
            webcamRef={webcamRef}
            setIsCameraVisible={setIsCameraVisible}
            windowCurrentWidth={windowCurrentWidth}
            isVisible={isCameraVisible}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={() => setImageForWeb(fileInputRef.current?.files[0])}
          />
          {isLoadingCameraPermission && (
            <View style={[s.cameraWrapper, { backgroundColor: 'transparent' }]}>
              <Spinner size="large" />
            </View>
          )}
        </View>
      </View>
    </InboxContainer>
  ) : (
    <SafeAreaView style={[rs.flex, rs.backgroundWhite]}>
      {chat.type === 'item' ? (
        <View style={s.headerContainer}>
          <View style={s.appBarContainer}>
            <BackButton />
            <TouchableItem onPress={onOpenItemDetails} style={[rs.row, rs.alignCenter]}>
              <FastImage style={s.itemImage} source={{ uri: chat.item_image }} />
              <View>
                <Text bold>{chat.item_title}</Text>
                <Text xsmall>
                  {Number(chat.item_seller_id) === user.id
                    ? strings.buy_sell.buyer
                    : strings.buy_sell.seller}
                  : {chat.interlocutor_name}
                </Text>
              </View>
            </TouchableItem>
          </View>
          <View style={rs.paddingHorizontal}>
            <Separator />
          </View>
          {showOfferContainer && (
            <View style={s.offerButtonsContainer}>
              <View style={[s.priceContainer, { paddingLeft: 0 }]}>
                <Image style={s.priceImage} source={{ uri: 'price' }} resizeMode="contain" />
                <Text medium bold color={currentTheme.colors.secondary} style={s.priceText}>
                  {itemPrice ? numberWithCommas(itemPrice) : ''}
                </Text>
              </View>
              <OfferButtonsContainer
                isLoadingOffer={isLoadingOffer}
                offer={offer}
                isCurrentUserBuyer={isCurrentUserBuyer}
                isItemActive={isActive}
                itemId={headerItem.id}
                hasMultipleSupply={headerItem.has_multiple_supply}
                onNewChatWasCreatedFromAskToBuy={onNewChatWasCreatedFromAskToBuy}
              />
            </View>
          )}
        </View>
      ) : (
        <View style={s.headerContainer}>
          <View style={s.appBarContainer}>
            <BackButton />
            <TouchableItem
              style={[rs.row, rs.alignCenter]}
              onPress={onOpenUserProfile}
              disabled={isUserSupportChat}
            >
              <FastImage
                style={s.userImage}
                source={{ uri: isUserSupportChat ? chat.chat_avatar : chat.interlocutor_image }}
              />
              <Text medium bold>
                {isUserSupportChat ? chat.chat_name : chat.interlocutor_name}
              </Text>
            </TouchableItem>
          </View>
        </View>
      )}

      {showQuickSupportBanner && (
        <View style={s.supportBannerContainer}>
          <Text>
            {strings.chats.need_help}{' '}
            <Text
              bold
              color={colors.secondary}
              style={s.supportButton}
              onPress={onContactSupportPress}
            >
              {strings.chats.contact_support}
            </Text>
          </Text>
        </View>
      )}

      <GiftedChat
        messages={messages}
        bottomOffset={0}
        onSend={onCheckBlockAndSendMessage}
        onPressAvatar={isUserSupportChat ? null : onOpenUserProfile}
        user={{
          _id: user.id,
          name: user.name,
        }}
        isAnimated
        renderLoading={() => (
          <CenteredView>
            <Spinner size="large" />
          </CenteredView>
        )}
        renderChatEmpty={() =>
          isLoading || isLoadingChatInfo ? (
            <CenteredView>
              <Spinner size="large" />
            </CenteredView>
          ) : (
            <View style={rs.flex} />
          )
        }
        onPressActionButton={!chat.is_blocked ? onPressActionButton : null}
        onInputTextChanged={onInputTextChanged}
        renderBubble={renderBubble}
        renderMessage={renderMessage}
        renderSystemMessage={renderSystemMessage}
        renderMessageText={renderMessageText}
        renderMessageImage={renderMessageImage}
        renderSend={renderSend}
        placeholder={strings.other.chat_placeholder}
        renderComposer={renderComposer}
        loadEarlier={true}
        onLoadEarlier={() => getChatMessages(chatId, true)}
        isLoadingEarlier={isLoadingMore}
        parsePatterns={parsePatterns}
        renderLoadEarlier={renderLoadEarlier}
        renderTime={() => null}
        renderTicks={() => null}
        isTyping={isTyping}
        // listViewProps={{
        //   style: { backgroundColor: colors.chatBackground },
        // }}
        disableComposer={chat.is_blocked ?? false}
        locale={getLocale()}
      />

      <UploadImagePreview
        imageSource={imageSource}
        isVisible={isPreviewVisible}
        onSave={sendImageMessage}
        onCancel={onCancelImageUpload}
      />
      <WebcamCapture
        capture={capture}
        webcamRef={webcamRef}
        setIsCameraVisible={setIsCameraVisible}
        windowCurrentWidth={windowCurrentWidth}
        isVisible={isCameraVisible}
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={() => setImageForWeb(fileInputRef.current?.files[0])}
      />
      {isLoadingCameraPermission && (
        <View style={[s.cameraWrapper, { backgroundColor: 'transparent' }]}>
          <Spinner size="large" />
        </View>
      )}
    </SafeAreaView>
  );
};

export default connect((state, { route }) => ({
  chat: chatsSelectors.getChatInfoById(state, route.params.chatId),
}))(
  connect(
    (state, { chat }) => ({
      user: state.userInfo,
      appLanguage: state.app.language,
      isAdmin: communitySelectors.getIsUserAdmin(state),
      isCurrentUserBlocker: false,
      isCurrentUserBlocked: false,
      messages: chatsSelectors.getMessagesList(state, chat.id),
      ...chatsSelectors.getMessagesStateByChatId(state, chat.id),
      insideChatRoomId: state.chats.insideChatRoomId,
      isLoadingChatInfo: state.chats.isLoadingChatInfo,
      groupInfo: groupsSelectors.getCommunityInfoByCommunityId(state, R.prop('group_id', chat)),
      item: lotsSelectors.getItemInfoById(state, R.prop('item_id', chat)),
      offer: offersSelectors.getOfferById(state, R.prop('offer_id', chat)),
      isLoadingOffer: state.offers.isLoadingOffer,
      isTypingMessage: chatsSelectors.getIsTypingMessageByChatId(state, chat.id),
      hasQuickSupportAccess: getHasQuickSupportAccess(state),
    }),
    (dispatch) => ({
      getItemInfo: (itemId) => dispatch(lotsOperations.getItemInfo(itemId)),
      getItemSnapshotByOfferId: (offerId) =>
        dispatch(lotsOperations.getItemSnapshotByOfferId(offerId)),
      getChatByItemId: (itemId) => dispatch(chatsOperations.getChatByItemId(itemId)),
      getPersonalChatByUserId: (userId) =>
        dispatch(chatsOperations.getPersonalChatByUserId(userId)),
      resetTemporaryChat: () => dispatch(chatsOperations.resetTemporaryChat()),
      setInsideRoomId: (roomId) => dispatch(chatsOperations.setInsideRoomId(roomId)),
      getChatMessages: (chatId, isLoadMore) =>
        dispatch(chatsOperations.getChatMessages(chatId, isLoadMore)),
      sendMessage: (chatId, payload, payloadType, extra = null) =>
        dispatch(chatsOperations.sendMessage(chatId, payload, payloadType, extra)),
      sendMessageWithWishConnection: (chatId, payload, payloadType, wishId) =>
        dispatch(
          chatsOperations.sendMessageWithWishConnection(chatId, payload, payloadType, wishId),
        ),
      createPersonalChat: (userId, payload, payloadType, wishId) =>
        dispatch(chatsOperations.createPersonalChat(userId, payload, payloadType, wishId)),
      createItemChat: (userId, payload, payloadType) =>
        dispatch(chatsOperations.createItemChat(userId, payload, payloadType)),
      getChatById: (chatId, isShowLoader) =>
        dispatch(chatsOperations.getChatById(chatId, isShowLoader)),
      markAsRead: (chatId) => dispatch(chatsOperations.markAsRead(chatId)),
      uploadChatImage: (image) => dispatch(chatsOperations.uploadChatImage(image)),
      updateOffer: (offerId) => dispatch(offersOperations.updateOffer(offerId)),
      processDeepLink: (link) => dispatch(appOperations.processDeepLink(link)),
      sendUserTypingMessage: (chatId) => dispatch(chatsOperations.sendUserTypingMessage(chatId)),
      sendMarkMessageAsRead: (chatId) => dispatch(chatsOperations.sendMarkMessageAsRead(chatId)),
      getContacts: (params) => dispatch(appOperations.getContacts(params)),
      openSupportChat: () => dispatch(chatsOperations.openSupportChat()),
    }),
  )(ChatRoom),
);
