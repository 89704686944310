import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { KeyboardAvoidingView, ScrollView } from 'react-native';
import {
  View,
  Input,
  Text,
  Container,
  Button,
  InputWithTitleAndValidation,
  Image,
} from '../../../ReusableComponents';
import { headerStyle, rs, colors } from '../../../../styles/index';
import { transactionOperations } from '../../../../store/transactions';
import strings from '../../../../localization';
import s from './style';
import { isIos } from '../../../../utils/detectDevice';
import { userOperations } from '../../../../store/userInfo';

const PromoCode = ({ applyCode }) => {
  const [code, setCode] = useState('');

  const onSubmit = async () => {
    await applyCode(code);
    setCode('');
  };

  return (
    <Container>
      <KeyboardAvoidingView
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
        keyboardVerticalOffset={isIos ? 80 : 0}
      >
        <ScrollView contentContainerStyle={rs.alignCenter}>
          <Image style={s.image} source={{ uri: 'promo_code' }} resizeMode="contain" />

          <InputWithTitleAndValidation
            inputStyle={s.input}
            inputContainerStyle={s.inputContainer}
            underlineColorAndroid="transparent"
            autoFocus
            autoCapitalize={'characters'}
            maxLength={16}
            placeholder={strings.promo_codes.enter_code_here}
            onChangeText={setCode}
            value={code}
          />

          <Text style={s.infoText} color={colors.gray}>
            {strings.promo_codes.promo_code_description}
          </Text>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button title={strings.promo_codes.redeem} onPress={onSubmit} disabled={!code} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default connect(
  (state) => ({
    // user: state.userInfo,
  }),
  (dispatch) => ({
    createIssueTransaction: (args) => dispatch(transactionOperations.createIssueTransaction(args)),
    applyCode: (code) => dispatch(userOperations.applyCode(code)),
  }),
)(PromoCode);
