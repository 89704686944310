import React from 'react';
import { View } from 'react-native';
import Webcam from 'react-webcam';
import s from '../styles';
import { rs } from '../../../../../styles';
import { RoundButton } from '../../../../ReusableComponents';
import strings from '../../../../../localization';

const WebcamCapture = ({
  isVisible,
  windowCurrentWidth,
  webcamRef,
  setIsCameraVisible,
  capture,
}) => {
  const videoConstraints = {
    width: windowCurrentWidth,
    height: windowCurrentWidth / 1.6,
    facingMode: 'user',
  };
  return (
    isVisible && (
      <View style={s.cameraWrapper}>
        <Webcam
          ref={webcamRef}
          audio={false}
          height={windowCurrentWidth / 1.6}
          screenshotFormat="image/*"
          width={windowCurrentWidth}
          videoConstraints={videoConstraints}
          screenshotQuality={0.5}
        />
        <View style={[rs.row, rs.marginTop]}>
          <RoundButton
            onPress={capture}
            title={strings.modals.take_new_photo}
            withMarginRight={20}
          />
          <RoundButton onPress={() => setIsCameraVisible(false)} title={strings.common.cancel} />
        </View>
      </View>
    )
  );
};

export default WebcamCapture;
