import styled from 'styled-components/native';
import { colors } from '../../../styles';

const Container = styled.View`
  flex: 1;
  background-color: ${colors.white};
  width: 100%;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
`;

export default Container;
