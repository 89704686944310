import { Dimensions, Platform, StatusBar, StyleSheet } from 'react-native';

import { moderateScale, verticalScale } from './scalingUtils';
import { isIphoneX, isIos, isWeb, isIPhone14 } from '../utils/detectDevice';

export const { width, height } = Dimensions.get('window');
export const mobileSize = width < 426;

function getStatusBarHeight(skipAndroid) {
  return Platform.select({
    ios: isIphoneX ? 44 : isIPhone14 ? 54 : 20,
    android: skipAndroid ? 0 : StatusBar.currentHeight,
    default: 0,
  });
}

export const hairline = StyleSheet.hairlineWidth;

export const borderRadius = 5;
export const bigBorderRadius = 12;

export const small = isWeb && !mobileSize ? 28 : 14;
export const medium = isWeb && !mobileSize ? 24 : 12;
export const large = isWeb && !mobileSize ? 36 : 18;
export const xLarge = isWeb && !mobileSize ? 40 : 20;

export const halfMedium = medium / 2;
export const halfLarge = large / 2;
export const halfXLarge = xLarge / 2;

export const doubleMedium = 2 * medium;

export const iconSize = 24;

export const headerHeight = isIos ? (isIphoneX ? 88 : isIPhone14 ? 102 : 64) : 56;

export const smallListItemHeight = 46;

export const headerIconSize = 18;
export const commonHeight = 30;

export const appBarHeight = Platform.OS === 'ios' ? 44 : 56;
export const additionalAppBarLayerHeight = 48;
export const statusBarHeight = getStatusBarHeight();

// Deprecated don't use it
export const indent = 16;
export const halfIndent = indent / 2;
export const doubleIndent = indent * 2;

export const mobileWidth = 769;
export const mobileHeight = 916;
