/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { I18nManager, Keyboard } from 'react-native';
import { TabView } from 'react-native-tab-view';
import { useDebouncedCallback } from 'use-debounce';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import strings from '../../../../localization';
import { lotsOperations } from '../../../../store/lots';
import { feedSearchOperations, feedSearchSelector } from '../../../../store/feedSearch';
import {
  communityMembersOperations,
  communityMembersSelector,
} from '../../../../store/communityMembers';
import { getUserLocations } from '../../../../store/userLocations/selectores';
import communityMemberFilters from '../../../../constants/communityMemberFilters';
import { rs, colors } from '../../../../styles';
import {
  Container,
  HeaderContainer,
  Spinner,
  TabBar,
  View,
  Text,
} from '../../../ReusableComponents';
import SearchBar from '../../../ReusableComponents/SearchBar';
import MembersScene from './components/MembersScene';
import ItemsScene from './components/ItemsScene';
import s from './styles';
import { isWeb } from '../../../../utils/detectDevice';

const ROUTE_KEYS = {
  ITEMS: 'items',
  MEMBERS: 'members',
};

const HomeSearchScreen = ({
  searchResults,
  communityMembersSearchHistory,
  userLocations,
  getItemsSearchHistory,
  setFeedLotFilters,
  removeItemSearchTerm,
  getGroupItemsTitlesSuggestions,
  getCommunityMembers,
  getRecentMembersSearches,
  saveMemberSearch,
  removeMemberSearch,
}) => {
  // everything containing localization should be placed inside of React nodes
  // to have access to all live updates (changing app language)
  const routes = [
    { key: ROUTE_KEYS.ITEMS, title: strings.items.items },
    { key: ROUTE_KEYS.MEMBERS, title: strings.members.members },
  ];

  const sortingTypesList = [
    {
      type: communityMemberFilters.ALL,
      title: strings.members.show_all,
    },
    {
      type: communityMemberFilters.TOP_SELLERS,
      title: strings.members.top_sellers,
    },
    {
      type: communityMemberFilters.NEAR_ME,
      title: strings.members.near_me,
    },
    {
      type: communityMemberFilters.MOST_FOLLOWED,
      title: strings.members.most_followed,
    },
    {
      type: communityMemberFilters.PURCHASED_FROM,
      title: strings.members.purchased_from,
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [titleSuggestions, setTitleSuggestions] = useState([]);
  const [isItemSuggestionsLoading, setItemSuggestionsLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [membersFilter, setMembersFilter] = useState(communityMemberFilters.ALL);

  useEffect(() => {
    getItemsSearchHistory();
    getRecentMembersSearches();
    getCommunityMembers();

    return () => {
      getCommunityMembers();
    };
  }, []);

  useEffect(() => {
    !isWeb && getSuggestions(searchTerm, membersFilter);
  }, [membersFilter]);

  const [getSuggestions] = useDebouncedCallback(async (term) => {
    const itemSuggestions = await getGroupItemsTitlesSuggestions(term);
    setTitleSuggestions(itemSuggestions);
    setItemSuggestionsLoading(false);

    getCommunityMembers(term, membersFilter);
  }, 400);

  const onSearchTermChange = (term) => {
    setSearchTerm(term);

    setItemSuggestionsLoading(true);
    getSuggestions(term);
  };

  const onSelectSearchResult = useCallback(
    (title) => {
      const searchTermForFilter = R.is(String, title) ? title : searchTerm.trim() || null;

      setFeedLotFilters({
        searchTerm: searchTermForFilter,
        groupIds: [],
        selectedCategoryIds: [],
        selectedSizes: [],
        selectedBrands: [],
        selectedConditions: [],
        isSearchByFollowings: false,
        isSearchByDiscount: false,
      });

      NavigationService.goBack();
      NavigationService.navigate(screens.MarketplaceTab, {
        screen: screens.Marketplace,
      });
    },
    [searchTerm],
  );

  const onRemoveSearchHistory = useCallback((searchTermId) => {
    removeItemSearchTerm(searchTermId);
  }, []);

  const onSaveMemberSearch = useCallback((userId) => {
    saveMemberSearch({ userId });
    // need to clear keyboard after selecting a member
    // https://devunet.atlassian.net/browse/SHAREITT-1224
    Keyboard.dismiss();
  }, []);

  const onRemoveMemberSearch = useCallback(({ memberId, userId }) => {
    removeMemberSearch({ memberId, userId });
  }, []);

  const onPressResetFilter = useCallback(() => {
    setMembersFilter(communityMemberFilters.ALL);
  }, []);

  const onSetFilter = (keys) => {
    if (keys[0] !== membersFilter) {
      setMembersFilter(keys[0]);
    }
  };

  const onGoBack = () => {
    NavigationService.goBack();
  };

  const sortOptions = R.isEmpty(userLocations)
    ? sortingTypesList.filter(({ type }) => type !== communityMemberFilters.NEAR_ME)
    : sortingTypesList;

  const showItemsSearchHistory =
    R.isEmpty(titleSuggestions) && R.isEmpty(searchTerm) && !isItemSuggestionsLoading;

  const showMembersSearchHistory =
    !R.isEmpty(communityMembersSearchHistory) &&
    membersFilter === communityMemberFilters.ALL &&
    R.isEmpty(searchTerm);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case ROUTE_KEYS.ITEMS:
        return (
          <ItemsScene
            showItemsSearchHistory={showItemsSearchHistory}
            searchResults={searchResults}
            titleSuggestions={titleSuggestions}
            isItemSuggestionsLoading={isItemSuggestionsLoading}
            isEmptySearchTerm={!searchTerm}
            onSelectSearchResult={onSelectSearchResult}
            onRemoveSearchHistory={onRemoveSearchHistory}
          />
        );

      case ROUTE_KEYS.MEMBERS:
        return (
          <MembersScene
            sortOptions={sortOptions}
            membersFilter={membersFilter}
            onSetFilter={onSetFilter}
            onPressResetFilter={onPressResetFilter}
            searchTerm={searchTerm}
            onSaveMemberSearch={onSaveMemberSearch}
            communityMembersSearchHistory={communityMembersSearchHistory}
            showMembersSearchHistory={showMembersSearchHistory}
            onRemoveMemberSearch={onRemoveMemberSearch}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Container>
      <HeaderContainer backgroundColor={colors.white} withoutBorderBottom>
        <SearchBar
          value={searchTerm}
          onChangeText={onSearchTermChange}
          onSubmitEditing={onSelectSearchResult}
          onCancel={onGoBack}
          returnKeyType="search"
          placeholder={strings.other.search_placeholder}
          enablesReturnKeyAutomatically
          showCancel
          autoFocus
        />
      </HeaderContainer>

      <TabView
        lazy
        renderLazyPlaceholder={() => <Spinner />}
        initialLayout={s.initialTabLayout}
        style={{
          direction: isWeb ? 'ltr' : 'inherit',
        }}
        sceneContainerStyle={
          isWeb && { direction: I18nManager.getConstants().isRTL ? 'rtl' : 'ltr' }
        }
        renderTabBar={(props) => {
          return (
            <TabBar
              {...props}
              renderLabel={({ route, color }) => {
                return (
                  <View style={[rs.row, rs.alignCenter]}>
                    <Text style={{ color }}>{route.title}</Text>
                  </View>
                );
              }}
            />
          );
        }}
        navigationState={{ index, routes }}
        onIndexChange={setIndex}
        renderScene={renderScene}
      />
    </Container>
  );
};

HomeSearchScreen.propTypes = {
  searchResults: T.arrayOf(T.shape({})),
  communityMembersSearchHistory: T.arrayOf(T.shape({})).isRequired,
  userLocations: T.arrayOf(T.shape({})).isRequired,
  getItemsSearchHistory: T.func.isRequired,
  setFeedLotFilters: T.func.isRequired,
  removeItemSearchTerm: T.func.isRequired,
  getGroupItemsTitlesSuggestions: T.func.isRequired,
  getCommunityMembers: T.func.isRequired,
  getRecentMembersSearches: T.func.isRequired,
  saveMemberSearch: T.func.isRequired,
  removeMemberSearch: T.func.isRequired,
};

HomeSearchScreen.defaultProps = {
  searchResults: [],
};

export default connect(
  (state) => {
    return {
      userId: state.userInfo.id,
      searchResults: feedSearchSelector.getItemSearchHistory(state),
      communityMembersSearchHistory: communityMembersSelector.getCommunityMembersHistory(state),
      userLocations: getUserLocations(state),
    };
  },
  (dispatch) => {
    return {
      getItemsSearchHistory: () => dispatch(feedSearchOperations.getItemsSearchHistory()),
      removeItemSearchTerm: (searchTermId) =>
        dispatch(feedSearchOperations.removeItemSearchTerm(searchTermId)),
      getGroupItemsTitlesSuggestions: (searchTerm) =>
        dispatch(feedSearchOperations.getGroupItemsTitlesSuggestions(searchTerm)),
      setFeedLotFilters: (feedLotFilters) =>
        dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
      getCommunityMembers: (searchTerm, filterBy) =>
        dispatch(communityMembersOperations.getCommunityMembers({ searchTerm, filterBy })),
      getRecentMembersSearches: () =>
        dispatch(communityMembersOperations.getRecentMembersSearches()),
      saveMemberSearch: ({ userId }) =>
        dispatch(communityMembersOperations.saveMemberSearch({ userId })),
      removeMemberSearch: (params) =>
        dispatch(communityMembersOperations.removeMemberSearch(params)),
    };
  },
)(HomeSearchScreen);
