import React from 'react';
import * as R from 'ramda';
import { View, StyleSheet } from 'react-native';
import Button from '../../Buttons/Button';
import TouchableItem from '../../TouchableItem';
import Image from '../../Image';
import Text from '../../Text';
import { MainContainer } from './styles';
import strings from '../../../../localization';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { colors, rs, dimensions } from '../../../../styles';
import { rewardSelectors } from '../../../../store/rewards';
import { connect } from 'react-redux';
import { getCurrentTheme } from '../../../../templates';

const s = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    height: 100,
    width: 100,
    borderRadius: 50,
    position: 'absolute',
    top: -40,
    alignItems: 'center',
    paddingTop: 16,
  },

  buttonContainer: {
    width: '100%',
    height: 40,
    marginTop: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'row',
  },
});

const MissingTokensForGuest = ({ onPress, welcomeReward, params }) => {
  const tokensAmount = R.propOr('Many', 'reward_amount', welcomeReward);
  const itemId = R.pathOr(null, ['itemId'], params);

  const currentTheme = getCurrentTheme();

  return (
    <View style={rs.alignCenter}>
      <View style={s.mainContainer}>
        <Image source={{ uri: 'tokens' }} style={{ height: 38, width: 38 }} />
      </View>
      <MainContainer>
        <Text semiBold large alignCenter style={rs.paddingTop}>
          {strings.modals.missing_tokens.title}
        </Text>
        <Text alignCenter medium style={rs.paddingTop}>
          {strings.modals.missing_tokens.description}
        </Text>
        <Text
          alignCenter
          semiBold
          medium
          color={currentTheme.colors.secondary}
          style={rs.smallPaddingTop}
        >
          {strings.modals.missing_tokens.more_points}
        </Text>
        <View style={s.buttonContainer}>
          <Button
            title={strings.modals.missing_tokens.apply}
            onPress={() => {
              onPress();
              NavigationService.navigate(screens.EarnTokens);
            }}
          />
        </View>

        <TouchableItem style={rs.bigMarginTop} onPress={onPress}>
          <Text alignCenter color={colors.gray}>
            {strings.modals.missing_tokens.not_now}
          </Text>
        </TouchableItem>
      </MainContainer>
    </View>
  );
};

export default connect((state) => ({
  welcomeReward: rewardSelectors.getWelcomeReward(state),
}))(MissingTokensForGuest);
