import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import FontIcon from '../FontIcon';
import { colors, dimensions, reusableStyles } from '../../../styles';
import NavigationService from '../../../services/NavigationService';
import { getCurrentTheme } from '../../../templates';

const styles = StyleSheet.create({
  rootContainer: {
    height: 60,
    width: '100%',
  },
  contentContainer: {
    height: 60,
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: dimensions.medium,
    backgroundColor: colors.white,
  },
  mainContainer: {
    flex: 1,
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: dimensions.medium,
    paddingRight: dimensions.halfMedium,
    borderBottomColor: colors.grayLight,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 34,
  },
  bubbleAndArrowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightText: {
    marginRight: 4,
    color: colors.gray,
  },
  bubbleText: {
    fontSize: 13,
  },
  bubbleNotification: {
    height: 18,
    minWidth: 18,
    paddingHorizontal: 4,
    marginHorizontal: dimensions.doubleMedium,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const OptionItem = memo(
  ({
    title,
    count,
    rightText, // text in the end, before chevron
    rightTextStyle, // text in the end, before chevron
    pageName, // if need navigate to screen
    onPress,
    iconName, // when need use 'Feather' icon
    Icon, // for custom icons or images
    navigation,
    showChevron = false,
    textColor,
    withoutBottomSeparator = true,
    withoutIcon,
    useOpacity = false,
  }) => {
    const currentTheme = getCurrentTheme();

    const contentContainerStyles = [
      styles.contentContainer,
      {
        borderBottomWidth: withoutBottomSeparator ? 0 : StyleSheet.hairlineWidth,
      },
    ];

    const bubbleNotificationStyles = [
      styles.bubbleNotification,
      {
        backgroundColor: currentTheme.colors.secondary,
      },
    ];
    return (
      <View style={styles.rootContainer}>
        <TouchableItem
          style={contentContainerStyles}
          useOpacity={useOpacity}
          onPress={onPress || (() => NavigationService.navigate(pageName))}
        >
          <Fragment>
            {!withoutIcon && (
              <View style={styles.logoContainer}>
                {Icon ? (
                  <Icon />
                ) : (
                  <FontIcon withoutRTLScale name={iconName} color={colors.text} size={24} />
                )}
              </View>
            )}
            <View style={styles.mainContainer}>
              <Text medium color={textColor}>
                {title}
              </Text>

              {!!count && (
                <View style={bubbleNotificationStyles}>
                  <Text style={styles.bubbleText} color={colors.white}>
                    {count}
                  </Text>
                </View>
              )}

              <View style={styles.bubbleAndArrowContainer}>
                <Fragment>
                  {!!rightText && (
                    <Text style={[styles.rightText, rightTextStyle]} medium>
                      {rightText}
                    </Text>
                  )}
                </Fragment>
                {showChevron && (
                  <FeatherIcon
                    name="chevron-right"
                    color="#BFBFC1"
                    style={reusableStyles.rtlScaleStyle}
                    size={dimensions.iconSize}
                  />
                )}
              </View>
            </View>
          </Fragment>
        </TouchableItem>
      </View>
    );
  },
);

export default OptionItem;
