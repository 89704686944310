import * as React from 'react';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../assets/fonts/icons/selection.json';
import { colors, dimensions } from '../../../styles';
const Icon = ({ name, color = colors.grayDarker, size = dimensions.iconSize, style }) => (
  <IcomoonReact
    iconSet={iconSet}
    color={color}
    size={size}
    icon={name}
    // TODO: somehow it can cause error (Failed to set an indexed property on 'CSSStyleDeclaration': Index property setter is not supported)
    style={{ style }}
  />
);
export default Icon;
