import React, { Fragment } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Text, Spinner } from '../../../../ReusableComponents';
import { colors, dimensions, fontSizes } from '../../../../../styles';
import { getCurrentTheme } from '../../../../../templates';

const styles = StyleSheet.create({
  buttonContainer: {
    paddingHorizontal: dimensions.medium,
    minWidth: 100,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
    marginRight: dimensions.medium,
  },
  secondaryButtonContainer: {
    paddingHorizontal: dimensions.medium,
    minWidth: 100,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderRadius: 14,
    marginRight: dimensions.medium,
    borderWidth: 1,
  },
  buttonText: {
    color: colors.white,
    fontSize: fontSizes.small,
  },
  secondaryButtonText: {
    fontSize: fontSizes.small,
  },
});

const Button = ({
  disabled,
  title,
  onPress,
  withMarginRight,
  secondaryType = false,
  isLoading,
}) => {
  const currentTheme = getCurrentTheme();
  return (
    <Fragment>
      {secondaryType ? (
        <TouchableOpacity
          style={[
            styles.secondaryButtonContainer,
            {
              marginRight: withMarginRight ? dimensions.halfMedium : 0,
              borderColor: disabled ? colors.grayBorder : currentTheme.colors.secondary,
            },
          ]}
          disabled={disabled}
          onPress={onPress}
        >
          {isLoading ? (
            <Spinner color={colors.white} />
          ) : (
            <Text
              style={[
                styles.secondaryButtonText,
                {
                  color: disabled ? colors.gray : currentTheme.colors.secondaryLight,
                },
              ]}
              disabled={disabled}
            >
              {title}
            </Text>
          )}
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={[
            styles.buttonContainer,
            {
              backgroundColor: disabled ? colors.grayLighter : currentTheme.colors.secondary,
              marginRight: withMarginRight ? dimensions.medium : 0,
            },
          ]}
          disabled={disabled}
          onPress={onPress}
        >
          {isLoading ? (
            <Spinner color={colors.white} />
          ) : (
            <Text
              style={[
                styles.buttonText,
                {
                  color: disabled ? colors.gray : colors.white,
                },
              ]}
              disabled={disabled}
            >
              {title}
            </Text>
          )}
        </TouchableOpacity>
      )}
    </Fragment>
  );
};

export default Button;
