import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import strings from '../../../../localization';
import { colors, dimensions, rs } from '../../../../styles';
import { Text, Button } from '../../../ReusableComponents';
import { NavigationService, PermissionService } from '../../../../services';
import { Settings } from 'react-native-fbsdk-next';
import screens from '../../../../navigation/screens';
import { isIphoneX } from '../../../../utils/detectDevice';
import { getUserInfo } from '../../../../store/userInfo/selectors';

const s = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: colors.white,
  },
  mainContainer: {
    paddingTop: '40%',
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 40,
  },
  image: {
    height: 140,
    width: 140,
  },

  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : dimensions.medium,
  },
});

const AnalyticsPermission = ({ userInfo, communityName }) => {
  const onContinue = async () => {
    await PermissionService.checkAndRequestIosAppTrackingTransparencyPermission();
    Settings.initializeSDK();

    if (userInfo.is_first_login) {
      return NavigationService.navigate(screens.AddLocationScreen);
    }
    NavigationService.navigate(screens.TabNavigator);
  };

  return (
    <SafeAreaView edges={['bottom']} style={s.root}>
      <View style={s.mainContainer}>
        <Image source={{ uri: 'analytics' }} resizeMode="contain" style={s.image} />
        <Text title style={rs.bigMarginVertical} semiBold alignCenter>
          {strings.login.analytics_permission_title}
        </Text>
        <Text medium alignCenter style={rs.bigMarginBottom}>
          {communityName === strings.main.app_name.toLowerCase()
            ? strings.login.analytics_permission_description
            : strings.login.analytics_permission_description_sharingood}
        </Text>
      </View>
      <View style={s.buttonContainer}>
        <Button title={strings.user_info.continue} onPress={onContinue} />
      </View>
    </SafeAreaView>
  );
};

export default connect((state) => ({
  communityName: state.communityTheme.theme,
  userInfo: getUserInfo(state),
}))(AnalyticsPermission);
