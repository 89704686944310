export default {
  title: {
    en: 'Green Friday activity',
    he: 'Green Friday פעילות',
    br: 'Ações na Green Friday',
  },
  description: {
    he: `פעילות Green Friday מתקיימת לראשונה ב-Shareitt כדי להראות צד אחר של חודש נובמבר, חודש הקניות הגדול בעולם. גם חיסכון בכסף וגם עידוד לשימוש חוזר ושמירה על כדור הארץ.

בחודש נובמבר לא קונים - בחודש נובמבר מחליפים!
בדיוק בשביל זה הקמנו את הפעילות שתחזיר לכם 25% משווי הנקודות של כל פריט שתקבלו במהלך ימי הפעילות.

אז איך זה יעבוד:
- בין התאריכים 20.11.2022 ל-27.11.2022 יסומנו כל הפריטים בתגית של פעילות Green Friday.
- במסגרת הפעילות תחזיר Shareitt לכל משתמש 25% משווי הנקודות של הפריטים שהגיש בקשה עבורם ואושרו על ידי המוסר במסגרת תקופת המבצע.
- מוסר הפריט יקבל את כל הנקודות בהתאם לשווי הפריט המקורי אותו ביקש. לאחר אישור הבקשה תעביר Shareitt למקבל הפריט 25% מערך הנקודות של אותו פריט.
- ההחזר מוגבל ל 200 נקודות למשתמש.
- ההחזר יתבצע בצורה אוטומטית לאחר אישור מסירת הפריט.
- במקרים של ביטול בקשה על ידי מוסר הפריט, Shareitt תבצע חיוב חזרה של הנקודות שהונפקו בעבור פריט זה.

שיהיה לכולם Green Friday שמח!
בכל בעיה או תקלה מוזמנים לפנות לשירות הלקוחות שלנו באפליקציה ו/או בפייסבוק.
`,
    en: `The Green Friday activity takes place for the first time at Shareitt to show a different side of November, the biggest shopping month in the world. Both saving money and encouraging reuse and protecting the planet.

We don't buy in November. In November we swap! And that's exactly why we set up the activity that will give you back 25% of the value of the points of each item you receive during the days of the activity.

So how will it work:
- Between the dates 20.11.2022 and 27.11.2022, all items will be marked with the Green Friday activity tag.
- As part of the activity, Shareitt will return to each user 25% of the points of the items he received during this period. The points returned will go to the user who requested the item that has had their item request confirmed.
- The requester of the item will transfer all the points to the user according to the value of the original item. After the request is approved, Shareitt will transfer to the requester 25% of the point value of that item.
- The refund will be limited to 200 points per user.
- The refund will be made automatically up to 48 hours at most from the moment the item request is confirmed.

Have a happy Green Friday everyone!
For any problem or malfunction, please contact our customer service in the application and/or on Facebook.
`,
    br: `A Green Friday acontece pela primeira vez no Shareitt para mostrar um lado diferente de novembro, o maior mês de compras do mundo. Por aqui, economizamos dinheiro, incentivamos a reutilização de produtos e protegemos o planeta.

Não compramos em novembro. Em novembro, trocamos! E é exatamente por isso que montamos a ação que vai te devolver 25% do valor dos pontos de cada item que você receber durante esses dias.

Como vai funcionar:
- Entre os dias 20.11.2022 e 27.11.2022, todos os itens serão marcados com a etiqueta Green Friday.
- Como parte da ação, o Shareitt devolverá a cada usuário 25% dos pontos dos itens recebidos durante este período. Os pontos devolvidos irão para o usuário que solicitou o item que teve sua solicitação de item confirmada.
- O solicitante do item transferirá todos os pontos para o usuário de acordo com o valor do item original. Após a aprovação do pedido, o Shareitt repassará ao solicitante 25% do valor em pontos daquele item.
- O reembolso será limitado a 200 pontos por usuário.
- O reembolso será feito automaticamente em até 48 horas a partir do momento da confirmação da solicitação do item.

Uma ótima Green Friday a todos!
Para qualquer problema ou dúvida, entre em contato com o suporte no aplicativo e/ou no Facebook.
`,
  },
};
