import React from 'react';
import { Svg, Path, G } from 'react-native-svg';

const SocialSkillShare = ({ color = "#000000"}) => {
  return (
    <Svg
      id="Layer_1"
      width="32px"
      height="32px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
      fill={color}
    >
      <G id="SVGRepo_bgCarrier" stroke-width="0"></G>
      <G id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></G>
      <G id="SVGRepo_iconCarrier">
        <G>
          <Path
            fill="none"
            d="M32.092,55.916h14.575c1.087,0,2.563,2.496,4.734,4.039v-1.776c0,0-0.07-1.053,0.348-1.69 c0.424-0.639,1.308-0.809,1.584-0.809c0.279,0,14.575,0,14.575,0s4.401-0.74,9.294-3.044L23.408,53.15 C28.05,55.232,32.092,55.916,32.092,55.916z"
          ></Path>
          <Path d="M16.868,55.111c-0.385-0.293-0.733-0.576-1.061-0.855C16.054,54.652,16.416,54.959,16.868,55.111z"></Path>
          <Path d="M19.538,15.561h60.708v35.516c2.128-1.18,3.271-1.984,4.054-2.499V13.528c0-1.118-0.907-2.028-2.03-2.028H17.508 c-1.123,0-2.033,0.91-2.033,2.028v35.14c0.771,0.486,1.878,1.301,4.063,2.52V15.561z"></Path>
          <Path d="M87.791,47.978c-0.523-0.417-1.247-0.415-2.396,0c-0.335,0.122-0.674,0.325-1.095,0.6 c-0.783,0.515-1.925,1.319-4.054,2.499c-0.657,0.36-1.373,0.748-2.241,1.183c-0.272,0.136-0.534,0.247-0.803,0.377 c-4.893,2.304-9.294,3.044-9.294,3.044s-14.295,0-14.575,0c-0.276,0-1.16,0.17-1.584,0.809c-0.418,0.638-0.348,1.69-0.348,1.69 v1.776c0.526,0.373,1.082,0.707,1.695,0.918c4.394,1.498,7.367,0.192,8.664-1.628c1.517-2.134,2.289-3.329,3.955-2.705 c1.666,0.624,0,4.68-2.08,6.763c-2.085,2.084-4.584,3.749-8.12,3.749c-1.486,0-2.926-0.223-4.114-0.476c0,2.941,0,11.611,0,15.131 c0,4.27,4.538,6.552,7.658,6.552c3.119,0,9.367-1.768,12.387-9.263c3.017-7.493-0.205-17.381-0.205-17.381s4.888-2.191,9.991-5.414 c5.098-3.225,6.455-6.246,6.559-6.67C87.891,49.105,88.307,48.396,87.791,47.978z"></Path>
          <Path d="M63.635,63.303c2.08-2.083,3.746-6.139,2.08-6.763c-1.666-0.624-2.438,0.571-3.955,2.705 c-1.297,1.82-4.27,3.126-8.664,1.628c-0.613-0.211-1.169-0.545-1.695-0.918c-2.17-1.543-3.647-4.039-4.734-4.039H32.092 c0,0-4.043-0.684-8.684-2.766c-0.466-0.21-0.935-0.418-1.411-0.657c-0.968-0.485-1.751-0.91-2.459-1.306 c-2.185-1.219-3.292-2.033-4.063-2.52c-0.321-0.203-0.593-0.355-0.871-0.455c-1.144-0.416-1.873-0.416-2.394,0 c-0.518,0.418-0.104,1.128,0,1.553c0.08,0.325,0.913,2.184,3.596,4.49c0.328,0.279,0.676,0.562,1.061,0.855 c0.57,0.434,1.192,0.877,1.902,1.326c5.098,3.227,9.991,5.412,9.991,5.412s-3.227,9.886-0.205,17.381 c3.018,7.494,9.263,9.27,12.385,9.27c3.123,0,7.661-2.297,7.661-6.564c0-4.266,0-16.131,0-16.131s1.152,0.42,2.799,0.771 c1.188,0.253,2.628,0.476,4.114,0.476C59.051,67.052,61.55,65.387,63.635,63.303z"></Path>
          <Path d="M61.017,34.381c-5.003,0-9.058,4.057-9.058,9.057c0,4.999,4.055,9.056,9.058,9.056c4.998,0,9.052-4.057,9.052-9.056 C70.069,38.438,66.015,34.381,61.017,34.381z"></Path>
          <Path d="M39.544,34.381c-5,0-9.053,4.057-9.053,9.057c0,4.999,4.052,9.056,9.053,9.056c5.003,0,9.058-4.057,9.058-9.056 C48.602,38.438,44.547,34.381,39.544,34.381z"></Path>
        </G>
      </G>
    </Svg>
  );
};

export default SocialSkillShare;
