import React from 'react';
import { Linking, StyleSheet, View } from 'react-native';
import { Image, TouchableItem } from '../../../../ReusableComponents';
import { rs } from '../../../../../styles';
import { APP_STORE_ID, PACKAGE_NAME } from '../../../../../../config/identifiers';

const StoreImages = () => {
  const APP_STORE_LINK = `https://apps.apple.com/app/id${APP_STORE_ID}`;
  const PLAY_STORE_LINK = `https://play.google.com/store/apps/details?id=${PACKAGE_NAME}`;
  const onOpenAppStore = () => {
    Linking.openURL(APP_STORE_LINK);
  };

  const onOpenGooglePlay = () => {
    Linking.openURL(PLAY_STORE_LINK);
  };

  return (
    <View style={s.storeImages}>
      <TouchableItem style={s.logoImage} onPress={onOpenAppStore}>
        <Image
          style={[rs.fullHeight, rs.fullWidth]}
          source={{ uri: 'app_store' }}
          resizeMode="contain"
        />
      </TouchableItem>
      <TouchableItem style={s.logoImage} onPress={onOpenGooglePlay}>
        <Image
          style={[rs.fullHeight, rs.fullWidth]}
          source={{ uri: 'google_play' }}
          resizeMode="contain"
        />
      </TouchableItem>
    </View>
  );
};

const s = StyleSheet.create({
  logoImage: {
    width: '50%',
    height: 100,
  },
  storeImages: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
});

export default StoreImages;
