import { createSelector } from 'reselect';
import * as R from 'ramda';
import { getFilteredGroup } from '../lots/selectors';
import excludedGroupIds from '../../constants/excludedGroupIds';
import { MIN_ITEMS_PER_CATEGORY_TO_SHOW } from '../../constants/categories';

export const getCommunityGroups = createSelector(
  [R.pathOr([], ['groups', 'groupIds']), R.pathOr({}, ['groups', 'groupEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getCommunityGroupsListForWishes = createSelector(
  [R.pathOr([], ['groups', 'groupIds']), R.pathOr({}, ['groups', 'groupEntities'])],
  (ids, entities) => {
    const EXCLUDED_GROUPS = [12, 18];
    return ids.filter((id) => !EXCLUDED_GROUPS.includes(id)).map((id) => entities[id]);
  },
);

export const getCommunityGroupsWithWishes = createSelector(
  [R.pathOr([], ['groups', 'groupIds']), R.pathOr({}, ['groups', 'groupEntities'])],
  (ids, entities) => {
    const groups = ids.map((id) => entities[id]);

    return groups.filter(R.prop('has_wishes'));
  },
);

export const getGroupInfoById = createSelector(
  [(_, groupId) => groupId, R.pathOr({}, ['groups', 'groupEntities'])],
  (id, entities) => R.propOr({}, id, entities),
);

export const getCommunitiesList = createSelector(
  [R.pathOr([], ['groups', 'communityIds']), R.pathOr({}, ['groups', 'communityEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getCommunitiesListByIds = createSelector(
  [(_, communityIds) => communityIds, R.pathOr({}, ['groups', 'communityEntities'])],
  (ids, entities) => ids.map((id) => entities[id]),
);

export const getRandomActiveCommunityId = createSelector([getCommunitiesList], (arr) =>
  R.prop('group_id', arr.filter((community) => community.is_group_member)[0]),
);

export const getActiveCommunitiesList = createSelector(
  [R.pathOr([], ['groups', 'joinedCommunityIds']), R.pathOr({}, ['groups', 'communityEntities'])],
  (ids, entities) => {
    const communitiesList = ids.map((id) => entities[id]);
    return communitiesList.filter(R.prop('is_group_member'));
  },
);

export const getAllUserCommunitiesList = createSelector(
  [R.pathOr([], ['groups', 'communityIds']), R.pathOr({}, ['groups', 'communityEntities'])],
  (ids, entities) => {
    const communitiesList = ids.map((id) => entities[id]);
    return communitiesList.filter(
      (community) => R.prop('is_group_member', community) || R.prop('is_visited', community),
    );
  },
);

export const getSortedActiveCommunitiesList = createSelector(
  [getActiveCommunitiesList, R.pathOr([], ['groups', 'insideCommunityId'])],
  (activeCommunities, insideCommunityId) => {
    const index = R.findIndex(R.propEq('group_id', insideCommunityId))(activeCommunities);

    return index !== -1 ? R.move(index, 0, activeCommunities) : activeCommunities;
  },
);

export const getCommunityInfo = createSelector(
  [R.pathOr([], ['groups', 'insideCommunityId']), R.pathOr({}, ['groups', 'communityEntities'])],
  (id, entities) => entities[id] || {},
);

export const getCommunityInfoByCommunityId = createSelector(
  [R.pathOr({}, ['groups', 'communityEntities']), (_, communityId) => communityId],
  (entities, communityId) => entities[communityId] || {},
);

export const getParentCategoriesList = createSelector([getCommunityInfo], (info) => {
  const categories = R.propOr([], 'categories', info);
  return categories.filter((category) => category.parent_id === null);
});

export const getParentCategoriesListByGroupId = createSelector(
  [getGroupInfoById],
  (groupInfo) => {
    const categories = R.propOr([], 'categories', groupInfo);

    return categories.filter(({ parent_id }) => {
      return parent_id === null;
    });
  },
);

export const getParentCategoriesListByGroupWithCount = createSelector(
  [getGroupInfoById],
  (groupInfo) => {
    const categories = R.propOr([], 'categories', groupInfo);
    
    const filteredCategories = categories
    .filter(({ parent_id, items_count }) => {
        console.log('parent_id', parent_id, items_count)
        return parent_id === null && items_count >= 1;
      })
      .map((category) => {
        const childCategories = categories.filter(({ parent_id, items_count }) => {
          return parseInt(parent_id, 10) === parseInt(category.id, 10) && items_count > 0;
        });

        return {
          ...category,
          childCategories: R.sortWith([R.ascend(R.prop('order'))], childCategories),
        };
      });

    return R.sortWith([R.ascend(R.prop('order'))], filteredCategories);
  },
);

export const getSelectedCommunityParentCategoriesList = createSelector(
  [(categories) => categories],
  (categories) => {
    let parentCategories = [];

    if (categories) {
      const parentCategoriesList = categories.filter((category) => category.parent_id === null);
      parentCategories = R.sortBy(R.prop('order'), parentCategoriesList);
    }

    return parentCategories;
  },
);

export const getSectionListByCategories = createSelector(
  [(categories) => categories],
  (categories) => {
    const sections = {
      // [3]: { parentCategory: { id, title, name}, childCategories: []}
    };

    categories.forEach((category) => {
      const { id, parent_id } = category;

      if (parent_id === null) {
        sections[id] = {
          parentCategory: category,
          childCategories: R.pathOr([], [id, 'childCategories'], sections),
        };
      } else {
        sections[parent_id] = {
          parentCategory: R.pathOr({}, [parent_id, 'parentCategory'], sections),
          childCategories: R.isNil(R.path([parent_id, 'childCategories'], sections))
            ? [category]
            : [...sections[parent_id].childCategories, category],
        };
      }
    });

    const sectionList = [];

    R.forEachObjIndexed((val, key) => {
      sectionList.push({
        parentCategory: val.parentCategory,
        data: R.sortBy(R.prop('order'), val.childCategories),
      });
    }, sections);

    return R.sortBy(R.path(['parentCategory', 'order']), sectionList);
  },
);

export const getBrandsListForFilteredGroup = createSelector(
  [getFilteredGroup],
  (info) => info.brands,
);

export const getSelectedCommunitySubCategoriesList = createSelector(
  [({ categories }) => categories, ({ activeParentCategory }) => activeParentCategory],
  (categories, activeParentCategory) => {
    return categories.filter(
      (category) => Number(category.parent_id) === Number(activeParentCategory.id),
    );
  },
);

export const getTotalItemsAmountInAllCommunityGroups = createSelector(
  [getCommunityGroups],
  (groups) => {
    return groups.reduce((accumulator, currentValue) => {
      if (excludedGroupIds.includes(currentValue.id)) {
        return accumulator;
      }
      return accumulator + currentValue.active_items_count;
    }, 0);
  },
);

export const getGroupNameById = createSelector(
  [
    R.pathOr({}, ['groups', 'groupEntities']),
    (_, id) => id,
  ],
  (entities, id) => {
    return R.pathOr(null, ['group_name'], entities[id]);
  },
);

export default {
  getCommunityGroups,
  getCommunityGroupsListForWishes,
  getCommunityGroupsWithWishes,
  getGroupInfoById,

  getCommunitiesList,
  getCommunitiesListByIds,
  getRandomActiveCommunityId,
  getActiveCommunitiesList,
  getAllUserCommunitiesList,
  getSortedActiveCommunitiesList,
  getCommunityInfo,
  getCommunityInfoByCommunityId,
  getParentCategoriesList,
  getSelectedCommunityParentCategoriesList,
  getSelectedCommunitySubCategoriesList,
  getSectionListByCategories,
  getBrandsListForFilteredGroup,
  getTotalItemsAmountInAllCommunityGroups,
};
