import React, { Fragment } from 'react';
import * as R from 'ramda';
import T from 'prop-types';
import { CheckBox, Text } from '../../../../ReusableComponents';
import { BrandView, styles } from './styles';
import { getCurrentTheme } from '../../../../../templates';
import { colors } from '../../../../../styles';

class BrandItem extends React.PureComponent {
  render() {
    const { brand, onSelect, selectedBrands } = this.props;

    const isActive = R.includes(brand, selectedBrands);

    const currentTheme = getCurrentTheme();

    return (
      <Fragment>
        <BrandView onPress={onSelect} useOpacity={false}>
          <Fragment>
            <Text
              style={[
                styles.brandNameText,
                {
                  color: isActive ? currentTheme.colors.secondaryLight : colors.gray,
                },
              ]}
            >
              {brand.name}
            </Text>
            <CheckBox checked={isActive} onToggle={onSelect} />
          </Fragment>
        </BrandView>
      </Fragment>
    );
  }
}

BrandItem.propTypes = {
  brand: T.object,
  onSelect: T.func,
  selectedBrands: T.array,
};

export default BrandItem;
