import React, { Fragment } from 'react';
import { StyleSheet } from 'react-native';
import Text from '../Text';
import Spinner from '../Spinner';
import TouchableItem from '../TouchableItem';
import { colors, dimensions } from '../../../styles';
import { isWeb } from '../../../utils/detectDevice';
import { getCurrentTheme } from '../../../templates';

const Button = ({
  isLoading,
  disabled,
  title,
  onPress,
  withMarginRight,
  secondaryType = false,
  fullWidth = false,
}) => {
  const currentTheme = getCurrentTheme();

  const buttonContainerStyle = [
    styles.buttonContainer,
    { height: isWeb ? 40 : 28 },
    fullWidth && { flex: 1 },
    withMarginRight && { marginRight: dimensions.medium },
    secondaryType && styles.secondaryButtonContainer,
    { backgroundColor: getButtonBackgroundColor(disabled, secondaryType, currentTheme) },
  ];

  const buttonTextStyle = [
    styles.buttonText,
    { color: getButtonTextColor(disabled, secondaryType) },
  ];

  const handlePress = () => {
    if (!disabled) {
      onPress();
    }
  };

  return (
    <Fragment>
      {secondaryType ? (
        <TouchableItem style={buttonContainerStyle} disabled={disabled} onPress={handlePress}>
          {isLoading ? (
            <Spinner color={colors.white} />
          ) : (
            <Text style={buttonTextStyle} disabled={disabled}>
              {title}
            </Text>
          )}
        </TouchableItem>
      ) : (
        <TouchableItem style={buttonContainerStyle} disabled={disabled} onPress={handlePress}>
          {isLoading ? (
            <Spinner color={colors.white} />
          ) : (
            <Text style={buttonTextStyle} disabled={disabled}>
              {title}
            </Text>
          )}
        </TouchableItem>
      )}
    </Fragment>
  );
};

const getButtonBackgroundColor = (disabled, secondaryType, currentTheme) => {
  return disabled ? colors.grayLighter : secondaryType ? colors.transparent : currentTheme.colors.secondary;
};

const getButtonTextColor = (disabled, secondaryType) => {
  return disabled ? colors.gray : secondaryType ? colors.blue : colors.white;
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: isWeb ? dimensions.medium : dimensions.doubleMedium,
    borderRadius: 16,
  },
  buttonText: {
    color: colors.white,
  },
  secondaryButtonContainer: {
    backgroundColor: colors.transparent,
    borderWidth: 1,
  },
});

export default Button;
