import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from '../../../../ReusableComponents';
import { colors, dimensions } from '../../../../../styles';
import strings from '../../../../../localization';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: dimensions.medium,
    borderRadius: 4,
    backgroundColor: colors.grayLighter,
    marginHorizontal: dimensions.medium,
    marginBottom: dimensions.doubleMedium,
  },
});

const RewardHeader = memo(({ totalEarnedAmount }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View style={s.contentContainer}>
      <Text medium bold color={currentTheme.colors.secondary}>
        {totalEarnedAmount}
      </Text>
      <Text medium semiBold color={colors.gray}>
        {' '}
        {strings.rewards.bonus_tokens_earned}
      </Text>
    </View>
  );
});

export default RewardHeader;
