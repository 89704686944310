import React from 'react';
import Button from '../../Buttons/Button';
import strings from '../../../../localization';
import {
  ContentContainer,
  AdminAvatar,
  MainContainer,
  Description,
  ButtonContainer,
  styles,
} from './styles';
import { getCurrentTheme } from '../../../../templates';
import { Text } from '../../../ReusableComponents';

const Welcome = ({ onPress, params }) => {
  const currentTheme = getCurrentTheme();
  return (
    <ContentContainer>
      <AdminAvatar source={{ uri: 'round_logo' }} />

      <MainContainer>
        <Text
          style={[
            styles.title,
            {
              color: currentTheme.colors.secondaryLight,
            },
          ]}
          semiBold
        >
          {params.title}
        </Text>
        <Description>{params.description}</Description>

        <ButtonContainer>
          <Button title={strings.common.ok} onPress={onPress} />
        </ButtonContainer>
      </MainContainer>
    </ContentContainer>
  );
};

export default Welcome;
