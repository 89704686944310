export const SAVE_LOCATION = 'lots/SAVE_LOCATION';
export const ADD_ITEM_CATEGORIES = 'lots/ADD_ITEM_CATEGORIES';

export const REMOVE_ITEM = 'lots/REMOVE_ITEM';
export const UPDATE_ITEM = 'lots/UPDATE_ITEM';
export const RESET_ITEM = 'lots/RESET_ITEM';

export const REMOVE_WISH = 'lots/REMOVE_WISH';
export const UPDATE_WISH = 'lots/UPDATE_WISH';
export const RESET_WISH = 'lots/RESET_WISH';

export const REMOVE_SKILL = 'lots/REMOVE_SKILL';

export const CREATE_ITEM_SUCCESS = 'lots/CREATE_ITEM_SUCCESS';
export const CREATE_SKILL_SUCCESS = 'lots/CREATE_SKILL_SUCCESS';
export const CREATE_WISH_SUCCESS = 'lots/CREATE_WISH_SUCCESS';

export const MANAGEMENT_LOT_START = 'lots/MANAGEMENT_LOT_START';
export const MANAGEMENT_LOT_ERROR = 'lots/MANAGEMENT_LOT_ERROR';

export const SET_CURRENT_USER_ITEMS = 'lots/SET_CURRENT_USER_ITEMS';
export const SET_CURRENT_USER_WISHES = 'lots/SET_CURRENT_USER_WISHES';

export const LOTS_ERROR = 'lots/LOTS_ERROR';

export const ITEMS_START = 'lots/ITEMS_START';
export const ITEMS_SUCCESS = 'lots/ITEMS_SUCCESS';
export const ITEMS_ERROR = 'lots/ITEMS_ERROR';
export const RESET_ITEMS = 'lots/RESET_ITEMS';

export const ITEMS_MORE_START = 'lots/ITEMS_MORE_START';
export const ITEMS_MORE_SUCCESS = 'lots/ITEMS_MORE_SUCCESS';
export const ITEMS_MORE_ERROR = 'lots/ITEMS_MORE_ERROR';

export const ITEMS_REFRESH_START = 'lots/ITEMS_REFRESH_START';
export const ITEMS_REFRESH_SUCCESS = 'lots/ITEMS_REFRESH_SUCCESS';
export const ITEMS_REFRESH_ERROR = 'lots/ITEMS_REFRESH_ERROR';

export const WISHES_START = 'lots/WISHES_START';
export const WISHES_SUCCESS = 'lots/WISHES_SUCCESS';
export const WISHES_ERROR = 'lots/WISHES_ERROR';

export const WISHES_MORE_START = 'lots/WISHES_MORE_START';
export const WISHES_MORE_SUCCESS = 'lots/WISHES_MORE_SUCCESS';
export const WISHES_MORE_ERROR = 'lots/WISHES_MORE_ERROR';

export const WISHES_REFRESH_START = 'lots/WISHES_REFRESH_START';
export const WISHES_REFRESH_SUCCESS = 'lots/WISHES_REFRESH_SUCCESS';
export const WISHES_REFRESH_ERROR = 'lots/WISHES_REFRESH_ERROR';

export const INITIALIZE_USER_LOTS_STATE = 'lots/INITIALIZE_USER_LOTS_STATE';

export const ADD_ITEM_ENTITY = 'lots/ADD_ITEM_ENTITY';
export const ADD_WISH_ENTITY = 'lots/ADD_WISH_ENTITY';

export const RESET_USER_LOTS = 'lots/RESET_USER_LOTS';
export const USER_LOTS_START = 'lots/USER_LOTS_START';
export const USER_LOTS_ERROR = 'lots/USER_LOTS_ERROR';
export const USER_LOTS_SUCCESS = 'lots/USER_LOTS_SUCCESS';

export const USER_SOLD_ITEMS_SUCCESS = 'lots/USER_SOLD_ITEMS_SUCCESS';
export const USER_PURCHASED_ITEMS_SUCCESS = 'lots/USER_PURCHASED_ITEMS_SUCCESS';

export const SET_CURRENT_USER_SOLD_ITEMS = 'lots/SET_CURRENT_USER_SOLD_ITEMS';
export const SET_CURRENT_USER_PURCHASED_ITEMS = 'lots/SET_CURRENT_USER_PURCHASED_ITEMS';

export const REPLACE_MARKETPLACE_FILTERS_WITH_NEW = 'lots/REPLACE_MARKETPLACE_FILTERS_WITH_NEW';
export const SET_FEED_LOT_FILTERS = 'lots/SET_FEED_LOT_FILTERS';
export const SET_FEED_WISH_FILTERS = 'lots/SET_FEED_WISH_FILTERS';
export const RESET_FEED_LOT_FILTERS = 'lots/RESET_FEED_LOT_FILTERS';
export const SET_FILTER_GROUP_ID = 'lots/SET_FILTER_GROUP_ID';

export const TOGGLE_ITEM_FAVORITES = 'lots/TOGGLE_ITEM_FAVORITES';
export const TOGGLE_TOP_ITEM = 'lots/TOGGLE_TOP_ITEM';
export const ITEM_WAS_ADDED_TO_FAVORITES = 'lots/ITEM_WAS_ADDED_TO_FAVORITES';
export const DISCOUNT_FAVORITE_ITEM = 'lots/DISCOUNT_FAVORITE_ITEM';

export const ITEM_WAS_SOLD = 'lots/ITEM_WAS_SOLD';

export const SAVED_ITEMS_START = 'lots/SAVED_ITEMS_START';
export const SAVED_ITEMS_SUCCESS = 'lots/SAVED_ITEMS_SUCCESS';
export const SAVED_ITEMS_ERROR = 'lots/SAVED_ITEMS_ERROR';

export const PENDING_ITEMS_START = 'lots/PENDING_ITEMS_START';
export const PENDING_ITEMS_SUCCESS = 'lots/PENDING_ITEMS_SUCCESS';
export const PENDING_ITEMS_ERROR = 'lots/PENDING_ITEMS_ERROR';
export const REMOVE_ITEM_FROM_PENDING_LIST = 'lots/REMOVE_ITEM_FROM_PENDING_LIST';
