import { pathOr } from 'ramda';
import subCommunitiesApi from '../../api/subCommunities';
import { ToastsService } from '../../services';
import strings from '../../localization';
import { normalize } from '../../utils/stateHelper';
import * as actions from './actions';

export const getSubCommunitiesToJoin = (communityId) => async (dispatch) => {
  try {
    const response = await subCommunitiesApi.getSubCommunitiesToJoin(communityId);

    dispatch(actions.setSubCommunitiesToJoin(response));
  } catch (error) {
    ToastsService.showError(strings.error_messages.error);
  }
};

export const joinPublicSubCommunities = (subCommunityIds) => async () => {
  await subCommunitiesApi.joinPublicSubCommunities(subCommunityIds);
};

export const askToJoinPrivateSubCommunity = (code) => async () => {
  const response = await subCommunitiesApi.askToJoinPrivateSubCommunity(code);

  if (!response?.success) {
    ToastsService.showSuccess(
      pathOr(strings.error_messages.error, ['data', 'message', 'en'], response),
    );
    return;
  }

  return response?.data.request_status;
};

export const getUserSubCommunities = () => async (dispatch) => {
  try {
    const response = await subCommunitiesApi.getUserSubCommunities();

    dispatch(actions.setSubCommunities(response));
  } catch (error) {
    ToastsService.showError(strings.error_messages.error);
  }
};

export const leaveSubCommunity = (subCommunityId) => async (dispatch) => {
  try {
    await subCommunitiesApi.leaveSubCommunity(subCommunityId);
    dispatch(actions.removeSubCommunity(subCommunityId));
  } catch (error) {
    ToastsService.showError(strings.error_messages.error);
  }
};

export default {
  getSubCommunitiesToJoin,
  joinPublicSubCommunities,
  askToJoinPrivateSubCommunity,
  getUserSubCommunities,
  leaveSubCommunity,
};
