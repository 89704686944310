import React from 'react';
import { StyleSheet } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import { dimensions } from '../../../styles';
import { isRTL } from '../../../utils/rtlHelper';

const IMAGE_URL = 'promotion_tag';

const s = StyleSheet.create({
  tag: {
    width: 50,
    height: 50,
    position: 'absolute',
    top: dimensions.halfMedium,
    ...(isRTL ? { left: dimensions.halfMedium } : { right: dimensions.halfMedium }),
    zIndex: 1,
    elevation: 1,
  },
});

const PromotionTag = () => (
  <Animated.Image entering={FadeIn} source={{ uri: IMAGE_URL }} style={s.tag} />
);

export default PromotionTag;
