import React, { Component, Fragment, memo, useRef } from 'react';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { View, StyleSheet, Image } from 'react-native';
import * as R from 'ramda';
import ImagePicker from 'react-native-image-crop-picker';
import { colors, dimensions } from '../../styles';
import TouchableItem from './TouchableItem';
import Text from './Text';
import FastImage from './FastImage';
import strings from '../../localization';
import { PermissionService } from '../../services';
import { isWeb } from '../../utils/detectDevice';
import { useGetWindowCurrentWidth } from '../../utils/getWindowCurrentWidth';
import { getCurrentTheme } from '../../templates';

const itemSize = (dimensions.width - (dimensions.doubleMedium + 24)) / 4;

const s = StyleSheet.create({
  contentContainer: {
    width: '100%',
    paddingHorizontal: dimensions.medium - 4,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  itemContainer: {
    height: itemSize,
    width: itemSize,
    backgroundColor: colors.grayLighter,
    borderRadius: dimensions.borderRadius,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 4,
  },
  itemNumberContainer: {
    position: 'absolute',
    height: 22,
    width: 22,
    textAlign: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  itemImage: {
    height: '100%',
    width: '100%',
    borderRadius: dimensions.borderRadius,
  },

  primaryImageText: {
    alignSelf: 'center',
    paddingTop: dimensions.halfMedium,
  },
});

const Item = memo(({ id, url, onPress, setImage }) => {
  const fileInputRef = useRef(null);
  const { windowCurrentWidth, isMobileWindow } = useGetWindowCurrentWidth();
  const imageSize = isMobileWindow ? windowCurrentWidth / 4.5 : windowCurrentWidth / 5.5;
  const currentTheme = getCurrentTheme();

  const onItemPress = () => {
    if (isWeb && !url) {
      fileInputRef.current?.click();
    } else {
      onPress();
    }
  };

  const ImageComponent = isWeb ? Image : FastImage;

  return (
    <Fragment>
      <View>
        <TouchableItem
          style={[
            s.itemContainer,
            id === 1 && { borderColor: currentTheme.colors.secondary },
            isWeb && { width: imageSize, height: imageSize },
          ]}
          onPress={onItemPress}
        >
          <Fragment>
            {!!url && <ImageComponent style={s.itemImage} source={{ uri: url }} />}
            {!url && (
              <View style={s.itemNumberContainer}>
                {isWeb && (
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={() => setImage(fileInputRef.current?.files[0])}
                  />
                )}
                <Text
                  alignCenter
                  color={id === 1 ? currentTheme.colors.secondary : colors.gray}
                  medium
                >
                  {id}
                </Text>
              </View>
            )}
          </Fragment>
        </TouchableItem>
        {id === 1 && (
          <Text xsmall color={colors.gray} style={s.primaryImageText}>
            {strings.lots.primary}
          </Text>
        )}
      </View>
    </Fragment>
  );
});

@connectActionSheet
class ImageGallery extends Component {
  onOpenActionSheet = (id) => {
    const { showActionSheetWithOptions } = this.props;
    const options = [strings.modals.take_photo, strings.modals.choose_photo, strings.common.cancel];

    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          this.pickSingleWithCamera(id);
        }
        if (buttonIndex === 1) {
          this.pickSingle(id);
        }
      },
    );
  };

  removeImage = (id) => {
    const { images, setImage } = this.props;

    const tmpImages = R.clone(images);
    tmpImages.splice(id, 1);
    tmpImages.push('');

    setImage(tmpImages);
  };

  setPrimaryImage = (id) => {
    const { images, setImage } = this.props;

    const image = images[id];

    const tmpImages = R.clone(images);
    tmpImages.splice(id, 1);
    tmpImages.unshift(image);

    setImage(tmpImages);
  };

  onOpenActionSheetForSelected = (id) => {
    const { showActionSheetWithOptions } = this.props;
    const options = [strings.common.delete, strings.common.cancel];

    if (id !== 0) {
      options.unshift(strings.lots.set_as_primary);
    }

    const destructiveButtonIndex = options.length - 2;
    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0 && id !== 0) {
          this.setPrimaryImage(id);
        }
        if (buttonIndex === options.length - 2) {
          this.removeImage(id);
        }
      },
    );
  };

  async pickSingleWithCamera(id, cropping, mediaType = 'photo') {
    const { images, setImage } = this.props;

    ImagePicker.openCamera({
      compressImageQuality: 0.3,
      width: 800,
      height: 800,
      cropping: true,
      mediaType,
    })
      .then((image) => {
        const resultArray = [...images.filter((el) => !!el), image.path];

        const final = ['', '', '', ''];

        resultArray.forEach((item, index) => {
          final[index] = item;
        });

        setImage(final);
      })
      .catch((e) => {
        console.log(e.message ? e.message : e);
        PermissionService.checkCameraPermission();
      });
  }

  setImageForWeb = (files) => {
    // set image to push on server
    let arr = Object.values(files);

    const array = [...this.props.imagesWeb.filter((el) => !!el), files];
    const finalArray = ['', '', '', ''];
    array.forEach((item, index) => {
      finalArray[index] = item;
    });
    this.props.setWebImages(finalArray);

    // set image to display in preview
    const reader = new FileReader();
    reader.readAsDataURL(files);
    reader.onload = () => {
      const resultArray = [...this.props.images.filter((el) => !!el), reader.result];
      const final = Array(4).fill('');

      resultArray.forEach((item, index) => {
        final[index] = item;
      });

      this.props.setImage(final);
    };
  };

  async pickSingle() {
    const { images, setImage } = this.props;

    const maxFiles = 4 - images.reduce((accum, current) => (current ? accum + 1 : accum), 0) || 1;

    ImagePicker.openPicker({
      compressImageQuality: 0.3,
      multiple: true,
      maxFiles,
      mediaType: 'photo',
      smartAlbums: ['UserLibrary', 'PhotoStream', 'Bursts'],
    })
      .then((imagesArr) => {
        // TODO refactor this bullshit, it was 4th hour of overtime when i wrote it
        const urls = imagesArr.map(R.prop('path'));
        if (urls.length > maxFiles) {
          urls.length = maxFiles;
        }
        const resultArray = [...images.filter((el) => !!el), ...urls];
        const final = ['', '', '', ''];
        resultArray.forEach((item, index) => {
          final[index] = item;
        });

        setImage(final);
      })
      .catch((e) => {
        console.log(e.message ? e.message : e);
        PermissionService.checkPhotoLibraryPermission();
      });
  }

  render() {
    const { images } = this.props;
    return (
      <View style={s.contentContainer}>
        {images.map((el, id) => (
          <Item
            key={id.toString()}
            id={id + 1}
            url={el}
            onPress={() =>
              el ? this.onOpenActionSheetForSelected(id) : this.onOpenActionSheet(id)
            }
            setImage={this.setImageForWeb}
          />
        ))}
      </View>
    );
  }
}

export default ImageGallery;
