import React from 'react';
import { LayoutAnimation } from 'react-native';
import styled from 'styled-components/native';
import Spinner from '../Spinner';
import { colors } from '../../../styles';

const ContentContainer = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const CentredContainer = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const OpacityBackground = styled.View`
  background-color: ${colors.black};
  opacity: 0.1;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const RootLoader = React.forwardRef((_, ref) => {
  const callbackFn = React.useRef(null);
  const [show, setShow] = React.useState(false);
  React.useImperativeHandle(ref, () => ({
    showLoader,
    hideLoader,
    showSuccessLoader,
    hideSuccessLoader,
  }));

  React.useEffect(() => {
    LayoutAnimation.easeInEaseOut();

    return () => {
      LayoutAnimation.easeInEaseOut();
    };
  });
  const showLoader = () => {
    setShow(true);
  };

  const hideLoader = () => {
    setShow(false);
  };

  const showSuccessLoader = () => {
    setShow(true);
  };

  const hideSuccessLoader = ({ callback = null, isSuccess = true }) => {
    setShow(false);
    callbackFn.current = callback;

    if (callbackFn.current && isSuccess) {
      callbackFn.current();
      callbackFn.current = null;
    }
  };

  return show ? (
    <ContentContainer>
      <CentredContainer>
        <Spinner size={'large'} />
      </CentredContainer>
    </ContentContainer>
  ) : null;
});

export default RootLoader;
