import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EmptyListWithImage } from '../../../../ReusableComponents';
import strings from '../../../../../localization';
import ChatsList from './ChatsList';
import { chatsOperations, chatsSelectors } from '../../../../../store/chats';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';

const ChatListAdminContainer = ({ isEditMode = false, onSelectChat, selectedChatIds }) => {
  const [isRefreshing, setRefreshing] = useState(false);
  const dispatch = useDispatch();

  const getSupportChatsForAdmins = (isLoadMore) =>
    dispatch(chatsOperations.getSupportChatsForAdmins({ isLoadMore }));
  const getUnreadCountForAdminSupportChats = () =>
    dispatch(chatsOperations.getUnreadCountForAdminSupportChats());
  const supportChats = useSelector(chatsSelectors.getSupportChatsForAdmin);
  const isLoadingMoreSupportChats = useSelector(
    (state) => state.chats.adminSupportChats.isLoadingMore,
  );
  const isLoadingSupportChats = useSelector((state) => state.chats.adminSupportChats.isLoading);

  const onOpen = (chatId) => {
    NavigationService.navigate(screens.ChatRoom, { chatId, isAdminChats: true });
  };

  const onOpenChat = onSelectChat ?? onOpen;

  const onRefresh = async () => {
    setRefreshing(true);
    await getSupportChatsForAdmins();
    getUnreadCountForAdminSupportChats();
    setRefreshing(false);
  };

  const onOpenUserProfile = (userId, name, avatar) => {
    NavigationService.navigate(screens.UserProfile, {
      name,
      userId,
      profile_image: avatar,
    });
  };

  return (
    <ChatsList
      isEditMode={isEditMode}
      selectedChatIds={selectedChatIds}
      isLoading={isLoadingSupportChats}
      isLoadingMore={isLoadingMoreSupportChats}
      chatList={supportChats}
      onOpenChat={onOpenChat}
      onOpenUserProfile={onOpenUserProfile}
      onLoadMore={() => getSupportChatsForAdmins(true)}
      isRefreshing={isRefreshing}
      onRefresh={onRefresh}
      ListEmptyComponent={() => (
        <EmptyListWithImage
          image="empty_state_chats"
          text={strings.emptyLists.empty_buy_chat_list}
        />
      )}
    />
  );
};

export default ChatListAdminContainer;
