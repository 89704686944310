import React, { Fragment, memo, useState } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import {
  View,
  Text,
  StaticHeightButton,
  TouchableItem,
  UserAvatarWithViewer,
  UserFollowersButtons,
  Icon,
  Image,
} from '../../../../../ReusableComponents';
import { AnimatedSection } from '../../../../../ReusableComponents/CollapsibleLib/AnimatedSection';
import { useCollapsible } from '../../../../../ReusableComponents/CollapsibleLib/useCollapsible';
import s from './styles';
import { colors, rs } from '../../../../../../styles';
import { isRTL } from '../../../../../../utils/rtlHelper';
import strings from '../../../../../../localization';
import { monthAndYear } from '../../../../../../utils/dateHelper';
import { NavigationService } from '../../../../../../services';
import screens from '../../../../../../navigation/screens';
import { getCurrentTheme } from '../../../../../../templates';

const UserInfo = memo(
  ({
    isLoadingUserInfo,
    isLoadingUserLocations,
    selectedUserId,

    profileImage,
    name,
    bio,
    nickname,
    isShipper,
    memberSince,
    lastSeenAt,
    isCurrentUser,
    onFollow,
    onUnfollow,
    onSendMessage,
    followersCount,
    followingsCount,

    isMemberFollower,
    isContextUserFollower,
    userLocations,
    onOpenSearchItems,
    onOpenAllBuyers,
    userCredibilityDetails,
    onOpenEndorsements,
    contentContainerStyle,
    schoolInfo,
    isBlocked,
  }) => {
    const [isTrancated, setTrancated] = useState(bio?.length > 135);
    const {
      animatedHeight: animatedHeightVerifiedAccountInfoBox,
      onPress: onToggleVerifiedAccountInfoBox,
      onLayout: onLayoutVerifiedAccountInfoBox,
    } = useCollapsible();
    const {
      animatedHeight: animatedHeightMembersSoldToInfoBox,
      onPress: onToggleMembersSoldToInfoBox,
      onLayout: onLayoutMembersSoldToInfoBox,
    } = useCollapsible();
    const {
      animatedHeight: animatedHeightSchoolInfo,
      onPress: onToggleSchoolInfo,
      onLayout: onLayoutSchoolInfo,
    } = useCollapsible();

    const currentTheme = getCurrentTheme();

    const onOpenMembersSoldToFAQ = () => {
      NavigationService.navigate(screens.FAQ, { topicId: 10 });
    };

    return (
      <View style={[s.contentContainer, contentContainerStyle]}>
        <View style={s.avatarContainer}>
          <UserAvatarWithViewer profileImage={profileImage} />

          {!isLoadingUserLocations && !isLoadingUserInfo && (
            <UserFollowersButtons
              followersCount={followersCount}
              followingsCount={followingsCount}
              name={name}
              userId={selectedUserId}
              isShipper={isShipper}
              userLocations={userLocations}
            />
          )}
        </View>

        <Text large bold style={rs.paddingTop}>
          {name}
        </Text>

        {!isLoadingUserInfo ? (
          <Fragment>
            {!!memberSince && (
              <Text color={colors.gray} style={rs.smallPaddingTop}>
                {strings.profile.joined} {monthAndYear(memberSince)}
              </Text>
            )}

            {!!bio && (
              <TouchableItem
                style={rs.marginTop}
                onPress={() => bio.length > 135 && setTrancated(!isTrancated)}
              >
                <Text
                  numberOfLines={isTrancated ? 3 : 10}
                  color={colors.gray}
                  style={rs.smallPaddingTop}
                >
                  {isTrancated ? bio.substring(0, 135) + '...' : bio}{' '}
                  {bio.length > 135 && (
                    <Text semiBold>
                      {isTrancated ? strings.common.read_more : strings.common.see_less}
                    </Text>
                  )}
                </Text>
              </TouchableItem>
            )}

            {/*<Text xsmall color={colors.gray}>*/}
            {/*  {strings.profile.last_seen.replace(*/}
            {/*    /__TIME__/,*/}
            {/*    lastSeenAt && getTimeForNotification(lastSeenAt),*/}
            {/*  )}*/}
            {/*</Text>*/}

            <View style={rs.marginBottom} />

            <View>
              {!!userCredibilityDetails?.is_verified && (
                <TouchableItem
                  onPress={onToggleVerifiedAccountInfoBox}
                  style={[s.credibilityContainer, rs.marginTop]}
                >
                  <Image
                    style={s.credibilityImage}
                    source={{
                      uri: 'verified_account',
                    }}
                    resizeMode="contain"
                  />
                  <Text color={colors.gray}>{strings.user.verified_account}</Text>
                </TouchableItem>
              )}

              <AnimatedSection
                animatedHeight={animatedHeightVerifiedAccountInfoBox}
                onLayout={onLayoutVerifiedAccountInfoBox}
              >
                <View style={s.infoBoxContainer}>
                  <Text color={colors.white} style={s.infoBoxTriangle} xxsmall>
                    ▲
                  </Text>
                  <View style={s.infoBoxMainContainer}>
                    <Text>
                      <Text
                        onPress={onOpenMembersSoldToFAQ}
                        style={[s.infoBoxLinkText, { color: currentTheme.colors.secondary }]}
                      >
                        "{strings.user.verified_account}"
                      </Text>{' '}
                      {strings.user.verified_account_info_box}
                    </Text>

                    <TouchableItem
                      onPress={onToggleVerifiedAccountInfoBox}
                      style={s.infoBoxCloseIcon}
                    >
                      <Icon name="x" size={16} color={colors.grayLight} />
                    </TouchableItem>
                  </View>
                </View>
              </AnimatedSection>

              {!!schoolInfo.school_title && (
                <Fragment>
                  <TouchableItem
                    style={[s.credibilityContainer, rs.marginTop]}
                    onPress={onToggleSchoolInfo}
                  >
                    <Image
                      style={s.credibilityImage}
                      source={{ uri: 'university' }}
                      resizeMode="contain"
                    />

                    <Text color={colors.gray}>{schoolInfo.school_title}</Text>
                  </TouchableItem>

                  {!!schoolInfo.school_description && (
                    <AnimatedSection
                      animatedHeight={animatedHeightSchoolInfo}
                      onLayout={onLayoutSchoolInfo}
                    >
                      <View style={s.infoBoxContainer}>
                        <Text color={colors.white} style={s.infoBoxTriangle} xxsmall>
                          ▲
                        </Text>
                        <View style={s.infoBoxMainContainer}>
                          <Text>{schoolInfo.school_description}</Text>

                          <TouchableItem onPress={onToggleSchoolInfo} style={s.infoBoxCloseIcon}>
                            <Icon name="x" size={16} color={colors.grayLight} />
                          </TouchableItem>
                        </View>
                      </View>
                    </AnimatedSection>
                  )}
                </Fragment>
              )}

              <TouchableItem
                style={[s.credibilityContainer, rs.marginTop]}
                onPress={onOpenAllBuyers ? onOpenAllBuyers : onToggleMembersSoldToInfoBox}
              >
                <Image
                  style={s.credibilityImage}
                  source={{ uri: 'members_sold_to' }}
                  resizeMode="contain"
                />
                <Text color={colors.gray}>
                  {userCredibilityDetails?.members_sold_to_count ?? 0}{' '}
                  {isCurrentUser
                    ? strings.user.members_sold_to
                    : strings.user.members_sold_to_for_another_user}
                </Text>
              </TouchableItem>

              <AnimatedSection
                animatedHeight={animatedHeightMembersSoldToInfoBox}
                onLayout={onLayoutMembersSoldToInfoBox}
              >
                <View style={s.infoBoxContainer}>
                  <Text color={colors.white} style={s.infoBoxTriangle} xxsmall>
                    ▲
                  </Text>
                  <View style={s.infoBoxMainContainer}>
                    <Text>{strings.user.members_sold_to_info_box}</Text>
                    <TouchableItem
                      onPress={onToggleMembersSoldToInfoBox}
                      style={s.infoBoxCloseIcon}
                    >
                      <Icon name="x" size={16} color={colors.grayLight} />
                    </TouchableItem>
                  </View>
                </View>
              </AnimatedSection>

              <TouchableItem
                onPress={onOpenEndorsements}
                style={[s.credibilityContainer, rs.marginTop]}
              >
                <Image
                  style={s.credibilityImage}
                  source={{ uri: 'endorsments' }}
                  resizeMode="contain"
                />

                <Text color={colors.gray}>
                  {userCredibilityDetails?.endorsements_count ?? 0} {strings.user.endorsements}
                </Text>
              </TouchableItem>
            </View>

            {!isCurrentUser && !R.isNil(isMemberFollower) && !isBlocked ? (
              <View style={s.buttonsContainer}>
                {!isContextUserFollower && (
                  <StaticHeightButton
                    small
                    withMarginRight
                    title={isMemberFollower ? strings.user.follow_back : strings.user.follow}
                    onPress={onFollow}
                  />
                )}
                {isContextUserFollower && (
                  <StaticHeightButton
                    small
                    secondaryType
                    withMarginRight
                    title={strings.user.unfollow}
                    onPress={onUnfollow}
                  />
                )}
                <StaticHeightButton
                  secondaryType
                  small
                  title={strings.user.message}
                  onPress={onSendMessage}
                />
              </View>
            ) : (
              <View style={s.buttonsEmptyPlaceholder} />
            )}
          </Fragment>
        ) : (
          <Placeholder Animation={Fade}>
            <View style={s.placeholderContainer}>
              <PlaceholderLine
                width={40}
                height={16}
                style={[rs.marginTop, rs.veryBigMarginBottom]}
              />
              <PlaceholderLine width={50} height={14} style={rs.marginBottom} />
              <PlaceholderLine width={50} height={14} style={rs.marginBottom} />
              <PlaceholderLine width={50} height={14} style={rs.marginBottom} />

              <View style={s.placeholderButtonsContainer}>
                <PlaceholderLine height={30} noMargin style={[rs.flex, rs.marginRight]} />
                <PlaceholderLine height={30} noMargin style={rs.flex} />
              </View>
            </View>
          </Placeholder>
        )}
        {/*<IconButton name="search" size={20} onPress={onOpenSearchItems} />*/}
      </View>
    );
  },
);

UserInfo.propTypes = {
  profileImage: T.string,
  name: T.string,
  memberSince: T.string.isRequired,
  followersCount: T.number,
  followingsCount: T.number,
  userLocations: T.array,
  onOpenEndorsements: T.func,
};

export default UserInfo;
