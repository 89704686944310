import styled from 'styled-components/native';
import { colors, dimensions } from '../../../styles';
import { halfMedium } from '../../../styles/dimensions';
import Text from '../Text';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  contentContainer: {
    transform: [{ scaleX: -1 }],
    alignItems: 'flex-end',
  },
  listContentContainer: {
    paddingHorizontal: dimensions.halfMedium + dimensions.halfMedium / 2,
  },
  list: {
    backgroundColor: colors.white,
    marginTop: dimensions.halfMedium,
    marginBottom: dimensions.halfMedium,
  },
  sizeItemContainer: {
    marginHorizontal: halfMedium / 2,
    transform: [{ scaleX: -1 }],
    minWidth: 74,
    paddingHorizontal: halfMedium,
    height: 32,
    paddingHorizontal: 8,
    borderWidth: 1,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderColor: colors.gray,
  },
});

const SizeTitle = styled(Text)`
  text-align: center;
  color: ${({ isActive }) => (isActive ? colors.white : colors.gray)};
  text-align: center;
`;

export { SizeTitle };
