import React, { memo, Fragment } from 'react';
import T from 'prop-types';
import { View, FlatList } from 'react-native';
import s from './styles';
import { rs } from '../../../styles';
import TouchableItem from '../TouchableItem';
import Image from '../Image';
import Text from '../Text';
import { AnalyticsService } from '../../../services';
import * as analyticsEventTypes from '../../../constants/analyticsEventTypes';
import { isAndroid } from '../../../utils/detectDevice';
import { isRTL } from '../../../utils/rtlHelper';
import { useGetWindowCurrentWidth } from '../../../utils/getWindowCurrentWidth';

const ANDROID_ITEMS_COUNT = 10;

const Item = memo(({ item, onSelect }) => (
  <TouchableItem style={[s.itemContainer]} onPress={() => onSelect(item)}>
    <Fragment>
      <Image
        style={s.image}
        resizeMode="cover"
        source={{
          uri: item.background_image,
        }}
      />
      <View style={[rs.flex, rs.justifyCenter]}>
        <Text semiBold medium>
          {item.group_name}
        </Text>
      </View>
    </Fragment>
  </TouchableItem>
));

const HorizontalGroupsList = memo(({ groups, onSelect, isSmallItems, refToScroll }) => {
  // https://github.com/facebook/react-native/issues/19150
  // 20/06/2022 — still not fixed
  const data = isAndroid && isRTL ? groups.slice(0, ANDROID_ITEMS_COUNT) : groups;
  const onSelectGroup = (group) => {
    AnalyticsService.logEvent(analyticsEventTypes.home_select_group);
    onSelect(group);
  };

  const { windowCurrentWidth } = useGetWindowCurrentWidth();

  return (
    <View style={s.contentContainer}>
      <FlatList
        ref={refToScroll}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[s.listContentContainerStyle, { width: windowCurrentWidth }]}
        data={data}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => <Item item={item} onSelect={onSelectGroup} />}
      />
    </View>
  );
});

HorizontalGroupsList.propTypes = {
  groups: T.array,
  onSelect: T.func,
};

export default HorizontalGroupsList;
