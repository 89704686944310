import React, { memo } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { FlatList, RefreshControl, StyleSheet, View } from 'react-native';
import memoHelper from '../../../../../utils/memoHelper';
import {
  CenteredView,
  ListButton,
  OptionItem,
  Spinner,
  Text,
} from '../../../../ReusableComponents';
import { PERSONAL } from '../../../../../constants/chatTypes';
import strings from '../../../../../localization';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';
import ChatItem from './ChatItem';
import { isWeb } from '../../../../../utils/detectDevice';
import { colors, dimensions, rs } from '../../../../../styles';
import { useGetWindowCurrentWidth } from '../../../../../utils/getWindowCurrentWidth';

const s = StyleSheet.create({
  contentContainerStyle: {
    paddingBottom: 1,
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: dimensions.medium,
    borderBottomWidth: 1,
    borderColor: colors.grayLight,
  },
});

const ChatsList = memo(
  ({
    selectedChatIds,
    isLoading,
    chatList,
    type,
    isBuyer,
    onOpenChat,
    onOpenUserProfile,
    isLoadingMore,
    onLoadMore,
    isRefreshing,
    onRefresh,
    ListEmptyComponent,
    isHideArchiveButton,
    isEditMode,
  }) => {
    const { isMobileWindow, windowCurrentWidth } = useGetWindowCurrentWidth();
    return (
      <FlatList
        data={chatList}
        contentContainerStyle={[
          s.contentContainerStyle,
          isWeb && isMobileWindow && { minWidth: 390, width: windowCurrentWidth },
        ]}
        keyExtractor={(element) => R.prop('id', element).toString()}
        onEndReachedThreshold={0.7}
        onEndReached={onLoadMore}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />}
        refreshing={isRefreshing}
        ListFooterComponent={isLoadingMore && <Spinner />}
        // ListHeaderComponent={
        //   !isHideArchiveButton && (
        //     <ListButton
        //       title={strings.chats.archived_chats}
        //       onPress={() => NavigationService.navigate(screens.ArchivedChats)}
        //       iconName="archive"
        //     />
        //   )
        // }
        ListHeaderComponent={
          isWeb &&
          !isMobileWindow && (
            <View style={s.headerContainer}>
              <Text>{strings.chats.your_messages}</Text>
            </View>
          )
        }
        ListEmptyComponent={
          <CenteredView>
            {isLoading && !isRefreshing ? <Spinner /> : <ListEmptyComponent />}
          </CenteredView>
        }
        renderItem={({ item }) => (
          <ChatItem
            isEditMode={isEditMode}
            isSelected={selectedChatIds && R.includes(item.id, selectedChatIds)}
            selectedChatIds={selectedChatIds}
            isBuyer={isBuyer}
            type={type}
            chat={item}
            onOpenChat={onOpenChat}
            onOpenUserProfile={onOpenUserProfile}
          />
        )}
      />
    );
  },
  (prev, next) =>
    memoHelper(prev, next, [
      'chatList',
      'isLoading',
      'isRefreshing',
      'isLoadingMore',
      'selectedChatIds',
      'isEditMode',
    ]),
);

ChatsList.propTypes = {
  selectedChatIds: T.array,
  isLoading: T.bool,
  chatList: T.array,
  type: T.string,
  isBuyer: T.bool,
  onOpenChat: T.func,
  onOpenUserProfile: T.func,
  isLoadingMore: T.bool,
  onLoadMore: T.func,
  isRefreshing: T.bool,
  onRefresh: T.func,
  ListEmptyComponent: T.elementType,
};

export default ChatsList;
