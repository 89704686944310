import React, { Fragment, useState, useEffect } from 'react';
import { isEmpty } from 'ramda';
import { ScrollView } from 'react-native';
import s from './styles';
import { Container, View, Text, TouchableItem } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';
import strings from '../../../../../localization/index';
import { getCurrentTheme } from '../../../../../templates';

const SizeItem = ({ size, isSelected, onToggleSize }) => {
  const currentTheme = getCurrentTheme();

  const onPress = () => {
    onToggleSize(size.id);
  };

  return (
    <TouchableItem
      style={[s.itemContainer, isSelected && { backgroundColor: currentTheme.colors.secondary }]}
      useOpacity={false}
      onPress={onPress}
    >
      <Fragment>
        <Text style={rs.verySmallMarginVertical} color={isSelected ? colors.white : colors.gray}>
          {size.title}
        </Text>
      </Fragment>
    </TouchableItem>
  );
};

const NoSizeItem = ({ size, isSelected, onToggleSize }) => {
  const onPress = () => {
    onToggleSize(size.id);
  };

  const currentTheme = getCurrentTheme();

  return (
    <TouchableItem style={[rs.paddingHorizontal, rs.paddingVertical]} onPress={onPress}>
      <Fragment>
        <Text color={isSelected ? currentTheme.colors.secondary : colors.gray}>{size.title}</Text>
      </Fragment>
    </TouchableItem>
  );
};

const MultipleSizes = ({ navigation, route }) => {
  const sizes = route.params.sizes;
  const selectedSizeIds = route.params.selectedSizeIds;
  const onSelectSizes = route.params.onSelectSizes;

  const [selectedSizeIdsFromState, setSelectedSizeIdsFromState] = useState(selectedSizeIds);

  useEffect(() => {
    navigation.setParams({ onSubmit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSizeIdsFromState]);

  const onToggleSize = (sizeId) => {
    const updatedSelectedSizeIds = selectedSizeIdsFromState.includes(sizeId)
      ? selectedSizeIdsFromState.filter((id) => id !== sizeId)
      : [...selectedSizeIdsFromState, sizeId];

    setSelectedSizeIdsFromState(updatedSelectedSizeIds);
  };

  const onResetSelectedSizes = () => {
    setSelectedSizeIdsFromState([]);
  };

  const onSubmit = () => {
    onSelectSizes(selectedSizeIdsFromState);
    navigation.goBack();
  };

  return (
    <Container>
      <View style={s.noSizeContainer}>
        <NoSizeItem
          isSelected={isEmpty(selectedSizeIdsFromState)}
          size={{ title: strings.lots.no_size, id: null }}
          onToggleSize={onResetSelectedSizes}
        />
      </View>
      <ScrollView keyboardShouldPersistTaps="handled">
        <View style={s.sizesContainer}>
          {sizes.map((size) => {
            const isSelected = selectedSizeIdsFromState.includes(size.id);

            return (
              <SizeItem
                key={size.id}
                size={size}
                isSelected={isSelected}
                onToggleSize={onToggleSize}
              />
            );
          })}
        </View>
      </ScrollView>
    </Container>
  );
};

export default MultipleSizes;
