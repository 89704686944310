import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';
import appTypes from '../app/types';

const initialState = {
  ids: [],
  entities: {},
};

const reducer = handleActions(
  {
    [types.SET_USER_COMMUNITIES]: mergeDeep(({ payload }) => ({
      ids: payload.ids,
      entities: payload.entities,
    })),
    [types.REMOVE_USER_COMMUNITY]: mergeDeep(({ payload }, state) => ({
      ids: state.ids.filter((id) => id !== payload),
    })),
    [appTypes.RESET_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState,
);

export default reducer;
