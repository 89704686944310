import React, { memo } from 'react';
import { StyleSheet, Image, FlatList } from 'react-native';
import { View, Text, TouchableItem } from '../../../../../ReusableComponents';
import { dimensions, rs, colors } from '../../../../../../styles';
import strings from '../../../../../../localization';

const s = StyleSheet.create({
  contentContainer: {},
  listContentContainer: {
    paddingHorizontal: dimensions.halfMedium,
  },
  itemContainer: {
    flexDirection: 'row',
    marginHorizontal: dimensions.halfMedium,
    marginBottom: dimensions.medium,
    minWidth: 220,
  },
  itemImage: {
    height: 70,
    width: 70,
    borderRadius: 3,
  },
  imageStroke: {
    borderWidth: dimensions.hairline,
    borderColor: colors.grayLight,
  },
  infoContainer: {
    paddingHorizontal: dimensions.medium,
    justifyContent: 'center',
  },
});

const Item = memo(({ item, onSelect }) => {
  return (
    <TouchableItem onPress={() => onSelect(item)} style={s.itemContainer} key={item.id}>
      <Image
        source={{ uri: item.school_image || 'city_placeholder' }}
        style={[s.itemImage, item.school_image && s.imageStroke]}
        resizeMode="cover"
      />
      <View style={s.infoContainer}>
        <Text semiBold>{item.title}</Text>
        <Text style={rs.verySmallPaddingTop}>
          {item.items_count} {strings.items.items}
        </Text>
      </View>
    </TouchableItem>
  );
});

const HorizontalCampusesList = memo(({ data, onSelect, refToScroll }) => {
  return (
    <View style={s.contentContainer}>
      <FlatList
        ref={refToScroll}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={s.listContentContainer}
        data={data}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => <Item item={item} onSelect={onSelect} />}
      />
    </View>
  );
});

export default HorizontalCampusesList;
