import React from 'react';
import { View, Pressable, StyleSheet, Animated } from 'react-native';
import { useSelector } from 'react-redux';
import { colors, dimensions, rs } from '../../styles';
import { Icon, TouchableItem, Image, FontIcon } from '../../components/ReusableComponents';
import { AnalyticsService, NavigationService } from '../../services';
import screens from '../screens';
import * as analyticsEventTypes from '../../constants/analyticsEventTypes';
import FastImage from '../../components/ReusableComponents/FastImage';
import { getUserInfo } from '../../store/userInfo/selectors';

export const WebHeaderNavigator = ({
  toggleDrawer,
  changeMenuIconScale,
  changeCloseIconScale,
  setSelectedItem,
}) => {
  const userInfo = useSelector(getUserInfo);
  const onLogoPress = () => {
    setSelectedItem(null);
    changeCloseIconScale._value && toggleDrawer();
    NavigationService.navigate(screens.Home);
  };

  const onOpenSearch = () => {
    AnalyticsService.logEvent(analyticsEventTypes.home_select_search);
    NavigationService.navigate(screens.HomeSearch);
  };

  const onAvatarPress = () => {
    setSelectedItem(null);
    NavigationService.navigate(screens.Transactions);
  };

  return (
    <View style={s.container}>
      <TouchableItem onPress={onLogoPress}>
        <Image style={s.logo} source={{ uri: 'text_logo' }} resizeMode="contain" />
      </TouchableItem>
      <View style={[rs.row, rs.alignCenter]}>
        <TouchableItem onPress={onAvatarPress}>
          <FastImage
            source={
              userInfo.profile_image
                ? { uri: userInfo.profile_image }
                : require('../../assets/web/images/user_avatar_placeholder.png')
            }
            style={s.userAvatar}
            resizeMode="cover"
          />
        </TouchableItem>
        <Pressable style={rs.marginHorizontal} onPress={onOpenSearch}>
          <FontIcon name={'Search'} color={colors.text} size={24} style={[s.icon]} />
        </Pressable>
        <Pressable onPress={toggleDrawer}>
          <Animated.View
            style={{ transform: [{ scale: changeMenuIconScale }] }}
            onPress={toggleDrawer}
          >
            <Icon name={'menu'} color={colors.text} size={24} style={[s.icon]} />
          </Animated.View>
          <Animated.View
            style={{ transform: [{ scale: changeCloseIconScale }], position: 'absolute' }}
            onPress={toggleDrawer}
          >
            <Icon name={'x'} color={colors.text} size={24} style={[s.icon]} />
          </Animated.View>
        </Pressable>
      </View>
    </View>
  );
};

const s = StyleSheet.create({
  container: {
    height: 64,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.grayLighter,
    zIndex: 1,
    paddingHorizontal: dimensions.medium,
  },
  logo: {
    width: 120,
    height: 60,
  },
  userAvatar: {
    width: 28,
    height: 28,
    borderRadius: 24,
  },
});

export default WebHeaderNavigator;
