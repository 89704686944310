import React, { memo, useState } from 'react';
import { StyleSheet, View, Animated } from 'react-native';
import { DrawerItem } from '@react-navigation/drawer';
import { colors, dimensions, rs } from '../../styles';
import { useGetWindowCurrentWidth } from '../../utils/getWindowCurrentWidth';
import { FontIcon, Text } from '../../components/ReusableComponents';

const DrawerItemComponent = memo(
  ({ option, handleItemPress, selectedItem, scaleItemContainer, isOpenNotification, style }) => {
    const { isMobileWindow } = useGetWindowCurrentWidth();
    const [hoveredItem, setHoveredItem] = useState(false);

    const handleItemMouse = (isHovered) => {
      setHoveredItem(isHovered);
    };

    return (
      <Animated.View
        style={[
          s.container,
          style,
          !isMobileWindow && {
            width: scaleItemContainer?.interpolate({
              inputRange: [0.5, 1],
              outputRange: [216, 54],
            }),
            borderRadius: scaleItemContainer?.interpolate({
              inputRange: [0.5, 1],
              outputRange: [dimensions.borderRadius, 36],
            }),
            borderWidth:
              selectedItem === option.key &&
              scaleItemContainer?.interpolate({
                inputRange: [0.5, 0.9, 1],
                outputRange: [0, 0, 1],
              }),
            justifyContent: isOpenNotification ? 'center' : 'inherit',
          },
        ]}
      >
        {!isOpenNotification && selectedItem === option.screen && (
          <View style={s.activeDrawerIndicator} />
        )}

        <DrawerItem
          key={option.key}
          label={() =>
            !isOpenNotification && (
              <Text weight="bold" size="medium" style={[s.label]}>
                {option.title}
              </Text>
            )
          }
          onPress={() => handleItemPress(option)}
          focused={selectedItem === option.screen}
          onMouseEnter={isMobileWindow ? null : () => handleItemMouse(true)}
          onMouseLeave={isMobileWindow ? null : () => handleItemMouse(false)}
          style={[
            hoveredItem && s.itemBackgroundColor,
            isOpenNotification ? s.drawerItemWithOpenNotification : s.drawerItem,
            isMobileWindow && rs.flex,
          ]}
          icon={() => (
            <View>
              {option.count > 0 && !isOpenNotification && (
                <View style={s.countContainer}>
                  <Text weight="bold" color={colors.white} style={s.countSize}>
                    {option.count}
                  </Text>
                </View>
              )}
              <FontIcon withoutRTLScale name={option.iconName} color={colors.text} size={30} />
            </View>
          )}
          activeTintColor={colors.white}
          inactiveTintColor={colors.white}
          activeBackgroundColor={colors.grayLight}
          inactiveBackgroundColor={null}
        />
      </Animated.View>
    );
  },
);

export default DrawerItemComponent;

const s = StyleSheet.create({
  drawerItem: {
    width: 216,
    height: 44,
  },
  drawerItemWithOpenNotification: {
    width: 44,
    height: 44,
    borderRadius: 32,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  activeDrawerIndicator: {
    position: 'absolute',
    width: 2,
    height: 52,
    backgroundColor: colors.secondary,
    zIndex: 1,
    left: 10,
    alignSelf: 'center',
    borderBottomRightRadius: dimensions.borderRadius,
    borderTopRightRadius: dimensions.borderRadius,
  },
  label: {
    marginLeft: -dimensions.medium,
    width: 200,
  },
  itemBackgroundColor: {
    backgroundColor: colors.grayLight,
  },
  container: {
    marginLeft: 5,
    flexDirection: 'row',
  },
  countContainer: {
    backgroundColor: colors.secondary,
    width: 17,
    height: 17,
    borderRadius: 10,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: -4,
    right: -4,
  },
  countSize: {
    fontSize: 10,
  },
});
