export const OPEN_ITEM_DETAILS = 'openItemDetails';
export const OPEN_WISH_DETAILS = 'openWishDetails';
export const OPEN_USER_PROFILE = 'openUserProfile';
export const JOIN_COMMUNITY = 'joinCommunity';
export const OPEN_MARKETPLACE = 'openMarketplace';
export const OPEN_MARKETPLACE_FULL = 'openMarketplaceFull';
export const OPEN_WISHES = 'openWishes';
export const OPEN_FAQ = 'openFaq';
export const OPEN_SUPPORT_CHAT = 'openSupportChat';
export const OPEN_PHOTO_TIPS = 'openPhotoTips';
export const OPEN_INVITE_FRIENDS = 'openInviteFriends';
