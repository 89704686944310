import React, { useState, memo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Image, StyleSheet, View, TouchableOpacity } from 'react-native';
import Animated, { FadeIn, Layout } from 'react-native-reanimated';
import { subCommunitySelectors } from '../../../../store/subCommunities';
import strings from '../../../../localization';
import { Button, Icon, SafeAreaContainer, Separator, Text } from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import { getCurrentTheme } from '../../../../templates';

const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity);

const Item = memo(({ title, imageUrl, isSelected, onPress }) => {
  const currentTheme = getCurrentTheme();
  return (
    <AnimatedTouchableOpacity
      activeOpacity={0.5}
      onPress={onPress}
      style={s.itemContainer}
      entering={FadeIn}
    >
      <Fragment>
        <View style={s.itemMainDataContainer}>
          <Image source={{ uri: imageUrl }} resizeMode="cover" style={s.itemImage} />
          <Text color={isSelected ? currentTheme.colors.secondary : colors.text} medium semiBold>
            {title}
          </Text>
        </View>

        {isSelected && (
          <View style={[s.checkmarkContainer, { backgroundColor: currentTheme.colors.secondary }]}>
            <Icon name="check" size={14} color={colors.white} withoutRTLScale />
          </View>
        )}
      </Fragment>
    </AnimatedTouchableOpacity>
  );
});

const MultipleSubCommunitySelectScreen = ({ route }) => {
  let data = useSelector(subCommunitySelectors.getSubCommunitiesListForCurrentCommunity);
  if (route.params?.subCommunityIds) {
    data = data.filter((item) => route.params.subCommunityIds.includes(item.id));
  }
  const [selectedSubCommunities, setSelectedSubCommunities] = useState(
    route.params?.selectedSubCommunities || [],
  );

  const onSelect = (item) => {
    const isSelected = !!selectedSubCommunities.find(({ id }) => id === item.id);

    setSelectedSubCommunities((prevState) => {
      if (isSelected) {
        return prevState.filter(({ id }) => id !== item.id);
      }

      return [...prevState, item];
    });
  };

  const onSubmit = () => {
    route.params?.onSubmit(selectedSubCommunities);
  };

  return (
    <SafeAreaContainer>
      <Animated.FlatList
        data={data}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => {
          const isSelected = !!selectedSubCommunities.find(({ id }) => id === item.id);
          const onPress = () => {
            onSelect(item);
          };

          return (
            <Item
              title={item.name}
              imageUrl={item.icon}
              onPress={onPress}
              isSelected={isSelected}
            />
          );
        }}
        ItemSeparatorComponent={() => <Separator />}
        contentContainerStyle={s.mainContainer}
        itemLayoutAnimation={Layout.springify()}
      />

      <View style={s.buttonContainer}>
        <Button
          title={strings.common.done}
          onPress={onSubmit}
          disabled={!selectedSubCommunities.length}
        />
      </View>
    </SafeAreaContainer>
  );
};

const s = StyleSheet.create({
  mainContainer: {
    flexGrow: 1,
  },
  buttonContainer: {
    flexShrink: 1,
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.small,
    paddingVertical: dimensions.medium,
  },
  itemMainDataContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemImage: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.grayBackground,
    marginRight: dimensions.halfMedium,
  },
  checkmarkContainer: {
    width: 20,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.violet,
  },
  infoContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.medium,
    marginVertical: dimensions.medium,
    marginHorizontal: dimensions.large,
    backgroundColor: colors.grayLighter,
    flexDirection: 'row',
    alignItems: 'center',
    ...rs.shadow,
  },
  infoText: {
    flexShrink: 1,
    marginLeft: dimensions.medium,
    flexWrap: 'wrap',
  },
});

export default MultipleSubCommunitySelectScreen;
