import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ModalsService } from '../../../services';
import modalTypes from '.././../../constants/modalTypes';
import strings from '../../../localization';
import { TouchableItem, FontIcon, Text, AngleIcon } from '../../ReusableComponents';
import { colors, dimensions, rs } from '../../../styles';
import { getCurrentTheme } from '../../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    height: 50,
    paddingVertical: dimensions.halfMedium,
    paddingHorizontal: dimensions.medium,
    marginHorizontal: dimensions.medium,
    marginTop: 30,
    borderRadius: 4,
    alignItems: 'center',
    backgroundColor: colors.grayLighter,
  },
  iconContainer: {
    width: 26,
    height: 26,
    borderRadius: 13,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const PaymentPlanBanner = ({ containerStyle }) => {
  const currentTheme = getCurrentTheme();

  const onPress = () => {
    ModalsService.showModal(modalTypes.PAYMENT_PLAN_COMING_SOON);
  };

  return (
    <View style={rs.shadow}>
      <TouchableItem onPress={onPress}>
        <View style={[s.contentContainer, containerStyle]}>
          <View
            style={[
              s.iconContainer,
              rs.marginRight,
              { backgroundColor: currentTheme.colors.primary },
            ]}
          >
            <FontIcon name="spark" size={16} color={colors.grayLighter} />
          </View>

          <Text color={currentTheme.colors.primary} semiBold medium>
            {strings.payment_plan.banner_title}
          </Text>

          <AngleIcon />
        </View>
      </TouchableItem>
    </View>
  );
};

export default PaymentPlanBanner;
