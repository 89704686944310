import { StyleSheet } from 'react-native';

export const s = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  container: {
    width: 26,
    height: 26,
    borderRadius: 13,
    borderWidth: 1,
    padding: 2,
  },
  content: {
    flex: 1,
    borderRadius: 100,
  },
  label: {
    marginLeft: 10,
  },
});
