/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import * as R from 'ramda';
import T from 'prop-types';
import { Switch, View, SafeAreaView } from 'react-native';
import MultiSlider from '@ptomasroos/react-native-multi-slider';
import { useFeatureFlag } from 'configcat-react';
import s, {
  TitleContainer,
  ButtonContainer,
  ScrollView,
  FollowingsTitleContainer,
  ViewStyleContainer,
  ViewIconContainer,
} from './styles';
import {
  StaticHeightButton,
  BackButton,
  ItemDetailsButton,
  NavigationButton,
  TextButton,
  Text,
  IconButton,
  Separator,
  Container,
} from '../../../../ReusableComponents';
import { Distance } from './components';
import { colors, dimensions, headerStyle, rs } from '../../../../../styles';
import strings from '../../../../../localization';
import { lotsOperations } from '../../../../../store/lots';
import { getParamOr } from '../../../../../utils/navHelper';
import { isAndroid, isWeb } from '../../../../../utils/detectDevice';
import { isRTL } from '../../../../../utils/rtlHelper';
import { NavigationService } from '../../../../../services';
import screens from '../../../../../navigation/screens';
import { groupsSelectors } from '../../../../../store/groups';
import { appOperations } from '../../../../../store/app';
import { hasPrimaryLocation } from '../../../../../store/userLocations/selectores';
import { getIsBrazilCommunity } from '../../../../../store/communityInfo/selectors';
import { useGetWindowCurrentWidth } from '../../../../../utils/getWindowCurrentWidth';
import { getCurrentTheme } from '../../../../../templates';
import { TALENTS_KEY } from '../../../../../constants/configcat';
import { formatCommunityName } from '../../../../../utils/formatCommunityName';

const maxPriceRange = 200;

const ITEM_DETAILS_MAX_ITEMS_IN_VALUE = 2;

const Title = ({ label }) => (
  <TitleContainer>
    <Text medium semiBold>
      {label}
    </Text>
  </TitleContainer>
);

Title.propTypes = {
  label: T.string,
};

const Filter = ({
  communityInfo,
  navigation,
  feedLotFilters,
  filterBySearchOnlyTalent,
  filterBySearchOnlyItem,
  groups,
  hasPrimaryLocation,
  doubleColumnLists,
  setFeedColumnStyle,
  setFeedLotFilters,
}) => {
  const [groupId, setGroupId] = useState(R.pathOr(null, ['groupIds', 0], feedLotFilters));

  const groupInfo = useSelector((state) => groupsSelectors.getGroupInfoById(state, groupId));
  const isBrazilCommunity = useSelector(getIsBrazilCommunity);

  const IS_SHOW_TALENTS_KEY = formatCommunityName(
    TALENTS_KEY,
    communityInfo.name,
  );

  const { value: isShowTalentsValue } = useFeatureFlag(IS_SHOW_TALENTS_KEY, false);

  const [selectedSizes, setSelectedSizes] = useState(feedLotFilters.selectedSizes);
  const [selectedConditions, setSelectedConditions] = useState(feedLotFilters.selectedConditions);
  const [selectedBrands, setSelectedBrands] = useState(feedLotFilters.selectedBrands);
  const [distance, setDistance] = useState(feedLotFilters.distance);
  const [withShipping, setWithShipping] = useState(feedLotFilters.withShipping);
  const [priceRange, setPriceRange] = useState(feedLotFilters.priceRange);
  const [isSearchByDiscount, setSearchByDiscount] = useState(feedLotFilters.isSearchByDiscount);
  const [isSearchByFollowings, setSearchByFollowings] = useState(
    feedLotFilters.isSearchByFollowings,
  );
  const [isOnlyFeaturedItems, setOnlyFeaturedItems] = useState(feedLotFilters.isOnlyFeaturedItems);
  const [isSearchOnlyTalent, setSearchOnlyTalent] = useState(filterBySearchOnlyTalent);
  const [isSearchOnlyItem, setSearchOnlyItem] = useState(filterBySearchOnlyItem);
  const { windowCurrentWidth } = useGetWindowCurrentWidth();
  const sliderWidth = windowCurrentWidth;

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    navigation.setParams({ onReset: onResetFilters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onSelectGroup = (groupId) => {
  //   onResetFilters();
  //   setGroupId(groupId);
  // };

  const getItemDetailsValue = (values) => {
    if (values.length <= ITEM_DETAILS_MAX_ITEMS_IN_VALUE) {
      return values.join(', ');
    }

    const [begin, ...end] = values;

    return strings.formatString(
      strings.filters.details_button_multiple_values,
      begin.toString(),
      end.length,
    );
  };

  const conditionDetailsValue = getItemDetailsValue(
    selectedConditions.map((conditionId) => {
      return R.find(R.propEq('id', conditionId))(groupInfo.conditions).title;
    }),
  );

  const sizeDetailsValue = getItemDetailsValue(
    selectedSizes.map((sizeId) => {
      return R.find(R.propEq('id', sizeId))(groupInfo.sizes).title;
    }),
  );

  const brandDetailsValue = getItemDetailsValue(
    selectedBrands.map(({ name }) => {
      return name;
    }),
  );

  const onOpenConditions = () => {
    NavigationService.navigate(screens.MultipleConditions, {
      conditions: groupInfo.conditions,
      selectedConditionIds: selectedConditions,
      onSelectCondition: setSelectedConditions,
    });
  };

  const onOpenSizes = () => {
    NavigationService.navigate(screens.MultipleSizes, {
      sizes: groupInfo.sizes,
      selectedSizeIds: selectedSizes,
      onSelectSizes: setSelectedSizes,
    });
  };

  const onOpenBrands = () => {
    NavigationService.navigate(screens.MultipleBrands, {
      selectedBrands,
      onSelectBrands: setSelectedBrands,
    });
  };

  const onSetDistance = (value) => {
    setDistance(value === distance ? null : value);
  };

  const onResetFilters = () => {
    // setGroupId(R.pathOr(null, ['groupIds', 0], feedLotFilters));
    setSelectedSizes([]);
    setSelectedConditions([]);
    setSelectedBrands([]);
    setDistance(null);
    setPriceRange([0, 200]);
    setSearchByDiscount(false);
    setSearchByFollowings(false);
    setOnlyFeaturedItems(false);
    setWithShipping(false);
  };

  const onSubmit = () => {
    setFeedLotFilters({
      // groupIds: groupId ? [groupId] : [],
      selectedSizes,
      selectedConditions,
      selectedBrands,
      distance,
      priceRange,
      isSearchByDiscount,
      isSearchByFollowings,
      isOnlyFeaturedItems,
      withShipping,
      isSearchOnlyTalent,
      isSearchOnlyItem,
      ...(withShipping && {
        coordinates: null,
        isSearchByPrimaryLocation: false,
        selectedCities: [],
      }),
    });
    NavigationService.goBack();
  };

  return (
    <Container>
      <ScrollView>
        {!R.isEmpty(groupInfo) && <Title label={strings.filters.details} />}

        {!R.isEmpty(groupInfo) && groupInfo.conditions && (
          <Fragment>
            <ItemDetailsButton
              title={strings.filters.conditions}
              selectedValue={conditionDetailsValue}
              placeholder={strings.filters.conditions_placeholder}
              onPress={onOpenConditions}
            />

            <View style={rs.paddingHorizontal}>
              <Separator />
            </View>
          </Fragment>
        )}

        {!R.isEmpty(groupInfo) && groupInfo.has_sizes && (
          <Fragment>
            <ItemDetailsButton
              title={strings.filters.sizes}
              selectedValue={sizeDetailsValue}
              placeholder={strings.filters.sizes_placeholder}
              onPress={onOpenSizes}
            />

            <View style={rs.paddingHorizontal}>
              <Separator />
            </View>
          </Fragment>
        )}

        {!R.isEmpty(groupInfo) && groupInfo.has_brands && (
          <Fragment>
            <ItemDetailsButton
              title={strings.filters.brands}
              selectedValue={brandDetailsValue}
              placeholder={strings.filters.brands_placeholder}
              onPress={onOpenBrands}
            />

            <View style={[rs.paddingHorizontal]}>
              <Separator />
            </View>
          </Fragment>
        )}
        <Title label={strings.filters.price_range} />

        <View style={[rs.bigPaddingTop, rs.paddingBottom]}>
          {isRTL ? (
            <Text style={rs.marginHorizontal}>
              {priceRange[1] === maxPriceRange ? `${maxPriceRange}+` : priceRange[1]} -{' '}
              {priceRange[0]}
            </Text>
          ) : (
            <Text style={rs.marginHorizontal}>
              {priceRange[0]} {strings.common.points} -{' '}
              {priceRange[1] === maxPriceRange ? `${maxPriceRange}+` : priceRange[1]}{' '}
              {strings.common.points}
            </Text>
          )}

          <View style={rs.bigPaddingHorizontal}>
            <MultiSlider
              selectedStyle={{
                backgroundColor: isAndroid
                  ? colors.greenLightAndroid
                  : currentTheme.colors.secondaryLight,
              }}
              unselectedStyle={{
                backgroundColor: colors.grayLighter,
              }}
              {...(isAndroid
                ? {
                    customMarker: () => (
                      <View
                        style={{
                          height: 20,
                          width: 20,
                          borderRadius: 10,
                          backgroundColor: colors.greenAndroid,
                        }}
                      />
                    ),
                  }
                : {})}
              values={[priceRange[0], priceRange[1]]}
              sliderLength={sliderWidth - 2 * dimensions.doubleMedium}
              onValuesChange={setPriceRange}
              min={0}
              max={maxPriceRange}
              step={10}
              allowOverlap={false}
              // snapped
            />
          </View>
        </View>

        {/*<Title label={strings.filters.distance} />*/}
        {/*<View style={rs.bigPaddingTop}>*/}
        {/*  {hasPrimaryLocation ? (*/}
        {/*    <Distance distanceValue={distance} onSelectDistance={onSetDistance} />*/}
        {/*  ) : (*/}
        {/*    <TextButton*/}
        {/*      alignCenter*/}
        {/*      title={strings.locations.set_primary_text}*/}
        {/*      onPress={() => NavigationService.navigate(screens.UserLocations)}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</View>*/}

        <View style={[rs.paddingHorizontal, rs.bigPaddingVertical]}>
          <Separator />
        </View>

        {isBrazilCommunity && (
          <Fragment>
            <FollowingsTitleContainer>
              <Text medium bold>
                {strings.shipping.shipping_options}
              </Text>

              <Switch value={withShipping} onValueChange={setWithShipping} />
            </FollowingsTitleContainer>

            <View style={[rs.paddingHorizontal, rs.bigPaddingVertical]}>
              <Separator />
            </View>
          </Fragment>
        )}

        <FollowingsTitleContainer>
          <Text medium bold>
            {strings.filters.only_featured}
          </Text>

          <Switch value={isOnlyFeaturedItems} onValueChange={setOnlyFeaturedItems} />
        </FollowingsTitleContainer>

        <View style={[rs.paddingHorizontal, rs.bigPaddingVertical]}>
          <Separator />
        </View>

        <FollowingsTitleContainer>
          <Text medium bold>
            {strings.filters.discounted_items_title}
          </Text>

          <Switch value={isSearchByDiscount} onValueChange={setSearchByDiscount} />
        </FollowingsTitleContainer>

        <View style={[rs.paddingHorizontal, rs.bigPaddingVertical]}>
          <Separator />
        </View>

        <FollowingsTitleContainer>
          <View>
            <Text medium bold>
              {strings.filters.search_by_followings}
            </Text>
            <Text color={colors.gray} style={rs.verySmallPaddingTop}>
              {strings.filters.search_by_followings_subtitle}
            </Text>
          </View>
          <Switch value={isSearchByFollowings} onValueChange={setSearchByFollowings} />
        </FollowingsTitleContainer>

        <View style={[rs.paddingHorizontal, rs.bigPaddingVertical]}>
          <Separator />
        </View>

        <ViewStyleContainer>
          <Text medium bold>
            {strings.filters.view_style}
          </Text>

          <ViewIconContainer>
            <IconButton
              isMaterialCommunityIcon
              style={rs.marginRight}
              name="grid-large"
              size={24}
              selected={doubleColumnLists}
              selectedColor={currentTheme.colors.secondaryLight}
              color={colors.gray}
              onPress={() => setFeedColumnStyle(2)}
            />
            <IconButton
              isMaterialCommunityIcon
              name="grid"
              size={24}
              selected={!doubleColumnLists}
              selectedColor={currentTheme.colors.secondaryLight}
              color={colors.gray}
              onPress={() => setFeedColumnStyle(3)}
            />
          </ViewIconContainer>
        </ViewStyleContainer>

        <Fragment>
          <Title label={strings.filters.select_type} />
          {isShowTalentsValue && (
            <View style={s.talentContainer}>
              <View>
                <Text medium bold>
                  {strings.filters.search_by_talents}
                </Text>
              </View>
              <Switch
                value={isSearchOnlyTalent}
                onValueChange={setSearchOnlyTalent}
                trackColor={{ false: colors.grayLight, true: currentTheme.colors.secondary }}
              />
            </View>
          )}

          <View style={[rs.paddingHorizontal]}>
            <Separator />
          </View>

          <View style={s.talentContainer}>
            <View>
              <Text medium bold>
                {strings.filters.search_by_items}
              </Text>
            </View>
            <Switch
              value={isSearchOnlyItem}
              onValueChange={setSearchOnlyItem}
              trackColor={{ false: colors.grayLight, true: currentTheme.colors.secondary }}
            />
          </View>

          <View style={[rs.paddingHorizontal]}>
            <Separator />
          </View>
        </Fragment>
      </ScrollView>

      <SafeAreaView>
        <ButtonContainer>
          <StaticHeightButton title={strings.filters.find_items} onPress={onSubmit} />
        </ButtonContainer>
      </SafeAreaView>
    </Container>
  );
};

Filter.propTypes = {
  groupInfo: T.shape({
    conditions: T.arrayOf(T.shape({})),
    has_sizes: T.bool,
    has_brands: T.bool,
  }),
  feedLotFilters: T.object,
  setFeedLotFilters: T.func,
};

export default connect(
  (state) => ({
    communityInfo: state.communityInfo,
    feedLotFilters: state.lots.feedLotFilters,
    groups: groupsSelectors.getCommunityGroups(state),
    hasPrimaryLocation: hasPrimaryLocation(state),
    doubleColumnLists: state.app.doubleColumnLists,
    filterBySearchOnlyTalent: state.lots.feedLotFilters.isSearchOnlyTalent,
    filterBySearchOnlyItem: state.lots.feedLotFilters.isSearchOnlyItem,
  }),
  (dispatch) => ({
    setFeedLotFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
    setFeedColumnStyle: (numberItemsPerRow) =>
      dispatch(appOperations.setFeedColumnStyle(numberItemsPerRow)),
  }),
)(Filter);
