import React from 'react';
import OfferItem from './OfferItem';
import { CONFIRM_CANCEL_PURCHASE, OFFER_CONFIRMED } from '../../../../store/offers/offerStatuses';
import { SELLER_CANCELLED_OFFER } from '../../../../store/offers/offerActions';
import strings from '../../../../localization';

const InProgressItems = props => {
  const {
    offer,
    onPerformAction,
    onPerformRefundBySeller,
    isShowStatus,
    onPerformRepostBySeller,
    onSelectItem,
    selectedOffersIds,
  } = props;
  const { id, status_id, item_id } = offer;

  if (parseInt(status_id) === CONFIRM_CANCEL_PURCHASE) {
    return (
      <OfferItem
        {...props}
        isShowStatus={isShowStatus}
        hasDismissButton
        dismissButtonText={strings.common.cancel}
        onDismissPress={() => onPerformAction(id, SELLER_CANCELLED_OFFER)}
        hasSuccessButton
        successButtonText={strings.common.confirm}
        onSuccessPress={() => onPerformRepostBySeller(id, item_id)}
      />
    );
  }
  if (parseInt(status_id) === OFFER_CONFIRMED) {
    return (
      <OfferItem
        {...props}
        isShowStatus={isShowStatus}
        onSelectItem={onSelectItem}
        selectedOffersIds={selectedOffersIds}
        hasSuccessButton
        successButtonText={strings.buy_sell.refund}
        onSuccessPress={() => onPerformRefundBySeller(id)}
      />
    );
  }
};

export default InProgressItems;
