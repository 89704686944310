import React from 'react';
import { View, StyleSheet } from 'react-native';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import { dimensions, colors, rs, fontSizes } from '../../../styles';
import strings from '../../../localization';
import { AnalyticsService, NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import Image from '../Image';
import { isWeb } from '../../../utils/detectDevice';
import { getCurrentTheme } from '../../../templates';
import { useSelector } from 'react-redux';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
    paddingVertical: dimensions.medium,
    paddingRight: isWeb ? dimensions.medium : dimensions.doubleMedium,
    borderRadius: 4,
    alignItems: 'center',
    marginHorizontal: dimensions.medium,
    marginTop: 30,
    backgroundColor: colors.grayLighter,
    height: isWeb ? 'auto' : 84,
  },
  text: {
    lineHeight: isWeb ? 28 : 22,
    flex: 1,
    flexWrap: 'wrap',
    fontSize: isWeb ? fontSizes.title : fontSizes.medium,
  },
  imageContainer: {
    width: isWeb ? 152 : 97,
    alignItems: 'center',
  },
  image: {
    height: isWeb ? 106 : 68,
    width: isWeb ? 106 : 68,
  },
});

const InviteFriendsBanner = ({ containerStyle, analyticsEventType, pointsName }) => {
  const currentTheme = getCurrentTheme();
  const communityName = useSelector((state) => state.communityTheme.theme);

  const onPress = () => {
    if (analyticsEventType) {
      AnalyticsService.logEvent(analyticsEventType);
    }
    NavigationService.navigate(screens.InviteFriends);
  };

  return (
    <View style={rs.shadow}>
      <TouchableItem onPress={onPress}>
        <View style={[s.contentContainer, containerStyle]}>
          <View style={s.imageContainer}>
            <Image
              style={s.image}
              source={{
                uri:
                  communityName === strings.main.app_name.toLowerCase()
                    ? 'invite_friends_banner'
                    : 'invite_friends_banner_sharingood',
              }}
              resizeMode="contain"
            />
          </View>
          <Text semiBold style={[s.text, rs.paddingHorizontal]}>
            {communityName === strings.main.app_name.toLowerCase()
              ? strings.invite_friends.invite_friends_banner_home
              : strings.invite_friends.invite_friends_banner_home_sharingood}

            {communityName === strings.main.app_name.toLowerCase() && (
              <Text color={currentTheme.colors.secondary} semiBold style={s.text}>
                {pointsName}
              </Text>
            )}
            {communityName === strings.main.app_name.toLowerCase() ? '.' : ''}
          </Text>
        </View>
      </TouchableItem>
    </View>
  );
};

export default InviteFriendsBanner;
