import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { View } from 'react-native';
import { headerStyle } from '../../styles';
import { WishGroupFilter } from '../../components/Screens';
import screens from '../screens';
import strings from '../../localization';
import { BackButton } from '../../components/ReusableComponents';

const Stack = createStackNavigator();

const WishFilterStack = () => (
  <Stack.Navigator screenOptions={{ ...headerStyle, headerRight: () => <View /> }}>
    <Stack.Screen
      name={screens.WishGroupFilter}
      component={WishGroupFilter}
      options={{
        title: strings.groups.groups,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
  </Stack.Navigator>
);

export default WishFilterStack;
