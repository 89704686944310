import AsyncStorage from '@react-native-community/async-storage';
import DeviceInfo from 'react-native-device-info';
import messaging from '@react-native-firebase/messaging';
import * as R from 'ramda';
import { PermissionsAndroid } from 'react-native';
import {
  notificationsStart,
  notificationsSuccess,
  notificationsError,
  notificationsMoreStart,
  notificationsMoreSuccess,
  notificationsRefreshStart,
  notificationsRefreshSuccess,
  markNotificationsAsRead,
  markNotificationAsOpen,
  addNewNotification,
} from './actions';
import notificationsApi from '../../api/notifications';
import { normalize } from '../../utils/stateHelper';
import { NOTIFICATIONS_LIMIT } from '../../constants/listLimits';
import * as rewardTypes from '../../constants/rewardTypes';
import {
  onOpenChat,
  onOpenItemDetails,
  onOpenTransactionDetails,
  onOpenUserProfile,
  onOpenBirthdayNotification,
  onOpenPendingDeliveryNotification,
} from './notificationHelper';
import { NavigationService, LoadingService, ModalsService } from '../../services';
import strings from '../../localization';
import screens from '../../navigation/screens';
import modalTypes from '../../constants/modalTypes';
import {
  GREETING_HANDSHAKE,
  BUYER_MADE_OFFER,
  ITEM_NOT_AVAILABLE_ON_MARKET,
  ITEM_RETURNED_TO_MARKET,
  BUYER_CANCELLED_OFFER,
  SELLER_REFUSED_OFFER,
  SELLER_CONFIRMED_OFFER,
  ITEM_DELIVERED_TO_BUYER,
  ITEM_SOLD,
  USER_CREATED_ITEM_ON_YOUR_WISH,
  BUYER_LEFT_COMMUNITY,
  SELLER_LEFT_COMMUNITY,
  NEW_ISSUE_TRANSACTION_IN_LIST,
  NEW_ISSUE_BONUS_TRANSACTION_IN_LIST,
  NEW_TRANSFER_TRANSACTION_IN_LIST,
  USER_ADD_TO_NEW_GROUP,
  USER_WISH_HAS_BEEN_FULLFILLED,
  ADMIN_APPROVED_JOIN_TO_GROUP,
  ADMIN_DECLINED_JOIN_TO_GROUP,
  USER_REACHED_GOAL,
  NEW_CHAT_MESSAGE,
  NEW_REFUND_TRANSACTION_IN_LIST,
  NEW_JOIN_REQUEST,
  NEW_FOLLOWER,
  USER_LIKED_YOUR_ITEM,
  FAVORITE_ITEM_SOLD,
  FAVORITE_ITEM_DISCOUNT,
  REQUESTER_REJECTED_OFFER,
  ITEM_HAS_BEEN_MARKED_AS_TOP,
  ADMIN_REMOVED_SELLER_ITEM,
  ADMIN_APPROVED_PENDING_ITEM,
  NEW_ITEM_TAGGING,
  MARKETING_NOTIFICATION,
  BIRTHDAY_BONUS_NOTIFICATION,
  NEW_DONATION_TRANSACTION,
} from '../../constants/notificationTypes';
import { followersOperations } from '../followers';
import { lotsOperations } from '../lots';
import { walletsOperations } from '../wallets';
import {
  updateOfferStatus,
  getNewSellingOfferById,
  getNewWishFulfillmentOfferById,
  getOfferCounters,
} from '../offers/operations';
import { rewardsOperations } from '../rewards';
import io from '../../api/io';
import { homeOperations } from '../home';
import rewardSections from '../../constants/rewardSections';
import { getPersonalRewardNavigationScreen } from '../../utils/personalRewards';
import { isAndroid } from '../../utils/detectDevice';
import appActionTypes, { appActionScreenMap } from '../../constants/appActions';
import { chatsOperations } from '../chats';

const getNotifications = (isRefresh) => async (dispatch) => {
  if (isRefresh) dispatch(notificationsRefreshStart());
  else dispatch(notificationsStart());

  try {
    const { notifications, unread_count } = await notificationsApi.getNotifications();

    const normalizedNotifications = normalize(
      notifications,
      'notificationIds',
      'notificationEntities',
    );

    const existMore = notifications.length === NOTIFICATIONS_LIMIT;

    dispatch(
      notificationsSuccess({ normalizedNotifications, unreadCount: unread_count, existMore }),
    );
  } catch (e) {
    dispatch(notificationsError(e));
  }
};

const getMoreNotifications = () => async (dispatch, getState) => {
  const { notifications } = getState();
  const { isLoading, isLoadingMore, existMore } = notifications;
  const skip = notifications.notificationIds.length;

  if (isLoadingMore || isLoading || !existMore) return;
  dispatch(notificationsMoreStart());

  try {
    const data = await notificationsApi.getNotifications(skip);

    const normalizedNotifications = normalize(
      data.notifications,
      'notificationIds',
      'notificationEntities',
    );

    dispatch(
      notificationsMoreSuccess({
        normalizedNotifications,
        unreadCount: data.unread_count,
        existMore: data.notifications.length === NOTIFICATIONS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(notificationsError(e));
  }
};

const onMarkNotificationsAsRead = () => async (dispatch, getState) => {
  const { notifications } = getState();
  const { unreadCount } = notifications;

  if (!unreadCount) return;
  try {
    await notificationsApi.markNotificationsAsRead();

    dispatch(markNotificationsAsRead());
  } catch (e) {}
};

const onMarkNotificationAsOpen = (notificationId) => async (dispatch, getState) => {
  const { notifications } = getState();
  const { notificationEntities } = notifications;

  try {
    if (!notificationEntities[notificationId].is_opened) {
      dispatch(markNotificationAsOpen({ notificationId }));
      await notificationsApi.markNotificationAsOpened(notificationId);
    }
  } catch (e) {}
};

const sendNotificationToCommunity = ({ title, body }) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  try {
    LoadingService.showSuccessLoader();

    await notificationsApi.sendNotificationToCommunity({
      title,
      body,
      communityId: communityInfo.id,
    });
    LoadingService.hideSuccessLoader({
      callback: () => {
        NavigationService.goBack();
      },
    });
  } catch (e) {
    LoadingService.hideSuccessLoader({ isSuccess: false });
  }
};

const sendMarketingNotification = ({
  title,
  body,
  targetGroupId,
  appAction,
  category_id = '',
  group_id = '',
}) => {
  return async (dispatch, getState) => {
    const { communityInfo } = getState();

    try {
      LoadingService.showSuccessLoader();

      await notificationsApi.sendMarketingNotification({
        title,
        body,
        targetGroupId,
        appAction,
        communityId: communityInfo.id,
        group_id,
        category_id,
      });

      LoadingService.hideSuccessLoader({
        callback: () => {
          NavigationService.goBack();
        },
      });
    } catch (error) {
      LoadingService.hideSuccessLoader({ isSuccess: false });
    }
  };
};

const onAddNewNotification = (data) => async (dispatch, getState) => {
  const { id, action_description, icon, action_id, message_params } = data;

  const actionDescription = JSON.parse(action_description);
  const { group_id } = actionDescription;

  const formattedNotification = {
    group_name: data.title,
    is_opened: false,
    is_read: false,
    created_at: new Date(),
    message: data.message,
    message_params,
    group_id,
    icon,
    action_id,
    id,
    action_description: actionDescription,
  };

  dispatch(addNewNotification({ notification: formattedNotification }));
};

const getRegistrationToken = () => async (dispatch, getState) => {
  await AsyncStorage.removeItem('registration_token');
  const storedToken = await AsyncStorage.getItem('registration_token');
  if (!storedToken) {
    const { userInfo } = getState();
    const registrationToken = await messaging().getToken();
    const deviceId = await DeviceInfo.getUniqueId();
    dispatch(subscribeNotifications());
    await notificationsApi.registerDevice({
      userId: userInfo.id,
      deviceId,
      deviceToken: registrationToken,
      deviceLanguage: strings._language,
    });
    AsyncStorage.setItem('registration_token', registrationToken);
    return registrationToken;
  }
  return storedToken;
};

const requestPermissionForNotifications = () => async (dispatch) => {
  try {
    await messaging().requestPermission();
    dispatch(getRegistrationToken());
  } catch (err) {
    // User has rejected permissions
    dispatch(getRegistrationToken());
  }
};

const checkNotificationsPermissions = () => async (dispatch) => {
  try {
    if (isAndroid) {
      return dispatch(checkNotificationsPermissionAndroid());
    }

    const permissionStatus = await messaging().hasPermission();

    const { DENIED, AUTHORIZED, NOT_DETERMINED } = messaging.AuthorizationStatus;

    if (permissionStatus === DENIED) {
      return;
    } else if (permissionStatus === AUTHORIZED) {
      return dispatch(getRegistrationToken());
    } else if (permissionStatus === NOT_DETERMINED) {
      return dispatch(requestPermissionForNotifications());
    }
  } catch (err) {
    console.log(`checkNotificationsPermissions error: ${err.message}`);
  }
};

const checkNotificationsPermissionAndroid = () => async (dispatch) => {
  // TODO: still not implemented in current react-native version,
  //  replace with PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS after update
  const PERMISSION_POST_NOTIFICATIONS = 'android.permission.POST_NOTIFICATIONS';
  const isGranted = await PermissionsAndroid.check(PERMISSION_POST_NOTIFICATIONS);

  if (!isGranted) {
    const requestStatus = await PermissionsAndroid.request(PERMISSION_POST_NOTIFICATIONS);

    if (requestStatus === PermissionsAndroid.RESULTS.GRANTED) {
      return dispatch(getRegistrationToken());
    }
  } else {
    return dispatch(getRegistrationToken());
  }
};

const onPressNotification = (notificationData) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;
  const isUnverifiedEmail = !!userInfo.email && !userInfo.is_email_verified;

  const { action_description, action_id, action_message } = notificationData;
  const {
    offer_id,
    offer_status_id,
    transaction_id,
    transaction_type,
    item_id,
    chat_room_id,

    // NEW_FOLLOWER
    user_id,
    profile_image,
    user_name,
    app_action,

    reward,
    bonus,

    group_id,
    category_id,
  } = JSON.parse(action_description);

  if (!action_id) return;

  switch (parseInt(action_id)) {
    case BUYER_MADE_OFFER: {
      onOpenChat(chat_room_id);
      break;
    }
    case BUYER_CANCELLED_OFFER: {
      onOpenChat(chat_room_id);
      break;
    }
    case SELLER_REFUSED_OFFER: {
      onOpenChat(chat_room_id);
      break;
    }
    case SELLER_CONFIRMED_OFFER: {
      onOpenChat(chat_room_id);
      break;
    }
    case ITEM_DELIVERED_TO_BUYER: {
      onOpenChat(chat_room_id);
      break;
    }
    case NEW_CHAT_MESSAGE: {
      onOpenChat(chat_room_id);
      break;
    }
    case NEW_ISSUE_TRANSACTION_IN_LIST: {
      onOpenTransactionDetails(transaction_id, transaction_type);
      break;
    }
    case NEW_ISSUE_BONUS_TRANSACTION_IN_LIST: {
      onOpenTransactionDetails(transaction_id, transaction_type);
      break;
    }
    case NEW_TRANSFER_TRANSACTION_IN_LIST: {
      onOpenTransactionDetails(transaction_id, transaction_type);

      break;
    }
    case NEW_REFUND_TRANSACTION_IN_LIST: {
      if (chat_room_id) {
        onOpenChat(chat_room_id);
      } else {
        onOpenTransactionDetails(transaction_id, transaction_type);
      }
      break;
    }
    case NEW_FOLLOWER: {
      onOpenUserProfile(user_id, profile_image, user_name);
      break;
    }
    case USER_LIKED_YOUR_ITEM: {
      onOpenUserProfile(user_id, profile_image, user_name);
      break;
    }

    case USER_REACHED_GOAL: {
      dispatch(rewardsOperations.updateReward({ reward }));

      if (reward.section === rewardSections.PERSONAL_INFO) {
        ModalsService.showModal(modalTypes.ACHIEVED_PERSONAL_REWARD, reward);
      } else {
        // we need timout for finishing previous navigation (opening marketplace, after item was created, for example)
        setTimeout(() => {
          NavigationService.navigate(screens.AchievedRewardDetails, { reward });
        }, 2000);
      }
      break;
    }

    // case FAVORITE_ITEM_SOLD:
    case USER_CREATED_ITEM_ON_YOUR_WISH:
    case FAVORITE_ITEM_DISCOUNT:
    case ITEM_HAS_BEEN_MARKED_AS_TOP:
    case NEW_ITEM_TAGGING:
    case ADMIN_APPROVED_PENDING_ITEM: {
      onOpenItemDetails(item_id);
      break;
    }

    case MARKETING_NOTIFICATION: {
      switch (app_action) {
        case appActionTypes.OPEN_GIVING_PENDING_DELIVERY:
        case appActionTypes.OPEN_RECEIVING_PENDING_DELIVERY:
          onOpenPendingDeliveryNotification(app_action);
          break;
        case appActionTypes.OPEN_WISHES_MARKETPLACE:
          dispatch(lotsOperations.setFeedWishFilters({ groupIds: group_id ? [group_id] : [] }));
          NavigationService.navigate(appActionScreenMap[app_action]);
          break;
        case appActionTypes.OPEN_ITEMS_MARKETPLACE:
          dispatch(
            lotsOperations.setFeedLotFilters({
              groupIds: group_id ? [group_id] : [],
              selectedCategoryIds: category_id ? [category_id] : [],
              selectedSizes: [],
              selectedBrands: [],
              selectedConditions: [],
              searchTerm: null,
            }),
          );
          NavigationService.navigate(appActionScreenMap[app_action]);
          break;
        case appActionTypes.OPEN_ADMIN_CHAT:
          dispatch(chatsOperations.openSupportChat());
          break;
        default:
          const screen = getPersonalRewardNavigationScreen(app_action, { isUnverifiedEmail });
          NavigationService.navigate(screen);
          break;
      }
      break;
    }

    case BIRTHDAY_BONUS_NOTIFICATION: {
      onOpenBirthdayNotification(bonus);
      break;
    }

    case NEW_DONATION_TRANSACTION: {
      onOpenTransactionDetails(transaction_id, transaction_type);
    }

    default: {
      break;
    }
  }
};

const handleNotification = (data) => (dispatch) => {
  const { action_description, action_message, action_id } = data;
  const actionDescription = JSON.parse(action_description);
  const { group_id, item_id } = actionDescription;

  switch (parseInt(action_id)) {
    case BUYER_MADE_OFFER: {
      dispatch(getNewSellingOfferById(actionDescription.offer_id));
      dispatch(getOfferCounters());
      break;
    }
    case USER_CREATED_ITEM_ON_YOUR_WISH: {
      dispatch(getNewWishFulfillmentOfferById(actionDescription.offer_id));
      dispatch(getOfferCounters());
      break;
    }
    case BUYER_CANCELLED_OFFER: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(getOfferCounters());
      break;
    }
    case SELLER_REFUSED_OFFER: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(walletsOperations.getWallets());
      dispatch(getOfferCounters());
      break;
    }
    case SELLER_CONFIRMED_OFFER: {
      const { has_multiple_supply } = actionDescription;
      if (!has_multiple_supply) {
        dispatch(lotsOperations.itemWasSold({ soldItemId: item_id }));
      }
      dispatch(walletsOperations.getWallets());
      dispatch(updateOfferStatus(actionDescription));
      break;
    }
    case ITEM_DELIVERED_TO_BUYER: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(getOfferCounters());
      break;
    }
    case ITEM_SOLD: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(getOfferCounters());
      break;
    }
    case BUYER_LEFT_COMMUNITY: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(getOfferCounters());
      break;
    }
    case SELLER_LEFT_COMMUNITY: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(getOfferCounters());
      break;
    }
    case REQUESTER_REJECTED_OFFER: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(getOfferCounters());
      break;
    }
    case NEW_ISSUE_TRANSACTION_IN_LIST: {
      dispatch(walletsOperations.getWallets());
      break;
    }
    case NEW_ISSUE_BONUS_TRANSACTION_IN_LIST: {
      dispatch(walletsOperations.getWallets());
      break;
    }
    case NEW_TRANSFER_TRANSACTION_IN_LIST: {
      dispatch(walletsOperations.getWallets());
      break;
    }
    case NEW_REFUND_TRANSACTION_IN_LIST: {
      dispatch(updateOfferStatus(actionDescription));
      dispatch(walletsOperations.getWallets());
      break;
    }
    case ITEM_RETURNED_TO_MARKET: {
      dispatch(updateOfferStatus(actionDescription));
      break;
    }
    case NEW_FOLLOWER: {
      dispatch(followersOperations.increaseFollowersCounter());
      break;
    }
    case USER_WISH_HAS_BEEN_FULLFILLED: {
      break;
    }
    case USER_REACHED_GOAL: {
      const { reward } = actionDescription;
      dispatch(rewardsOperations.updateReward({ reward }));

      dispatch(rewardsOperations.getUserInfoRewards());
      dispatch(rewardsOperations.getRewardsProgress());
      dispatch(homeOperations.getRewardsForHome());

      dispatch(walletsOperations.getWallets());

      if (reward.section === rewardSections.PERSONAL_INFO) {
        ModalsService.showModal(modalTypes.ACHIEVED_PERSONAL_REWARD, reward);
      } else {
        NavigationService.navigate(screens.AchievedRewardDetails, { reward });
      }

      break;
    }
    case FAVORITE_ITEM_SOLD: {
      break;
    }
    case FAVORITE_ITEM_DISCOUNT: {
      const { discount_rate, discounted_price } = actionDescription;
      dispatch(
        lotsOperations.discountFavoriteItem({ itemId: item_id, discount_rate, discounted_price }),
      );
      break;
    }
    default: {
      console.log("Message from FCM doesn't handle");
      break;
    }
  }
};

const subscribeNotifications = () => async (dispatch, getState) => {
  io.onNewNotification(async (data) => {
    console.log('onNewNotificationThroughSocket from SOCKET ====>>>>', data);

    const notification = R.path(['payload', 'notification'], data);

    dispatch(handleNotification(notification));
    dispatch(onAddNewNotification(notification));
  });

  // iOS only
  // TODO: https://devunet.atlassian.net/browse/SHAREITT-1911
  // uncomment this in the next implementation
  // messaging().setBackgroundMessageHandler(async () => {
  //   await notifee.incrementBadgeCount();
  // });

  // messaging().onMessage((remoteMessage) => {
  //   console.log('onNotification <==========', remoteMessage);
  //
  //   let data = null;
  //   let notification = null;
  //
  //   // firebase has different notification structure for real device and simulator
  //   if (R.has('notification', remoteMessage)) {
  //     data = R.prop('data', remoteMessage);
  //     notification = R.prop('notification', remoteMessage);
  //   } else {
  //     data = R.omit(['notification'], remoteMessage.data);
  //     notification = R.path(['data', 'notification'], remoteMessage);
  //   }
  //
  //   // dispatch(handleNotification(data));
  //
  //   // checking if need show toast
  //   const { chats } = getState();
  //   const { insideChatRoomId } = chats;
  //
  //   const { action_description, action_id, icon, notification_subtitle } = data;
  //   const { chat_room_id, group_id } = JSON.parse(action_description);
  //
  //   const isChatNotification = Number(action_id) === NEW_CHAT_MESSAGE;
  //
  //   if (isChatNotification) {
  //     if (chat_room_id !== insideChatRoomId) {
  //       // ToastsService.showNotification(data, icon, notification_subtitle);
  //       ToastsService.showNotification({
  //         data,
  //         tokenIcon: icon,
  //         subtitle: notification_subtitle,
  //         title: notification.title,
  //         body: notification.body,
  //       });
  //     }
  //   } else {
  //     dispatch(onAddNewNotification(data, notification));
  //   }
  // });

  messaging().onNotificationOpenedApp((remoteMessage) => {
    console.log('onNotificationOpened ====>>>>', remoteMessage);

    // firebase has different notification structure for real device and simulator
    const data = R.has('notification', remoteMessage)
      ? R.prop('data', remoteMessage)
      : R.omit(['notification'], remoteMessage.data);

    dispatch(onPressNotification(data));
  });

  const remoteMessage = await messaging().getInitialNotification();
  if (remoteMessage) {
    const data = R.has('notification', remoteMessage)
      ? R.prop('data', remoteMessage)
      : R.omit(['notification'], remoteMessage.data);

    dispatch(onPressNotification(data));
  }
  // TODO: In June 2020 this become just a deviceID. Backward compatibility issue.
  const newDeviceId = await DeviceInfo.getUniqueId();
  messaging().onTokenRefresh((fcmToken) => {
    console.log('onTokenRefresh');
    const { userInfo } = getState();
    notificationsApi.refreshDeviceToken({
      userId: userInfo.id,
      deviceId: DeviceInfo.getDeviceId(),
      newDeviceId,
      deviceToken: fcmToken,
    });
  });
};

export default {
  getNotifications,
  getMoreNotifications,
  onMarkNotificationsAsRead,
  onMarkNotificationAsOpen,
  sendNotificationToCommunity,
  checkNotificationsPermissions,
  onPressNotification,
  sendMarketingNotification,
};
