import React, { useEffect, useState } from 'react';
import { TabView } from 'react-native-tab-view';
import { connect } from 'react-redux';
import T from 'prop-types';
import { I18nManager, StyleSheet } from 'react-native';
import { offersOperations, offersSelectors } from '../../../store/offers';
import { headerStyleTransparentWithBackground } from '../../../styles/headerStyle';
import {
  TabBar,
  Container,
  IconButton,
  EmptyListWithIcon,
  Spinner,
  EmptyListWithImage,
} from '../../ReusableComponents';
import strings from '../../../localization';
import { BUYING } from '../../../constants/offerTypes';
import BuyingOffer from './components/BuyingOffer';
import OffersList from './components/OffersList';
import { dimensions, rs } from '../../../styles';
import { LoadingService } from '../../../services';
import NavigationService from '../../../services/NavigationService';
import screens from '../../../navigation/screens';
import { isWeb } from '../../../utils/detectDevice';

const s = StyleSheet.create({
  tabViewInitialLayout: {
    height: 0,
    width: dimensions.width,
  },
});

const BuyingOffers = ({
  performAction,
  askedToBuyOffers,
  pendingDeliveryOffers,
  askedToBuyOfferLoadersInfo,
  pendingDeliveryOfferLoadersInfo,
  getBuyingAskedToBuyOffers,
  getBuyingPendingDeliveryOffers,
  route,
}) => {
  const [index, setIndex] = useState(route.params?.tabIndex ?? 0);
  const [isRefreshing, setRefreshing] = useState(false);

  useEffect(() => {
    onFetchOffers();
  }, []);

  const onFetchOffers = async () => {
    await getBuyingAskedToBuyOffers();
    await getBuyingPendingDeliveryOffers();
  };

  const onRefresh = async () => {
    setRefreshing(true);

    if (index === 0) {
      await getBuyingAskedToBuyOffers();
      await getBuyingPendingDeliveryOffers();
    } else {
      await getBuyingPendingDeliveryOffers();
      await getBuyingAskedToBuyOffers();
    }

    setRefreshing(false);
  };

  const onLoadMoreAskedToBuyOffers = () => {
    getBuyingAskedToBuyOffers(true);
  };

  const onLoadMorePendingDeliveryOffer = () => {
    getBuyingPendingDeliveryOffers(true);
  };

  const onPerformAction = async (offerId, actionId) => {
    LoadingService.showSuccessLoader();
    await performAction(offerId, actionId);
    LoadingService.hideSuccessLoader();
  };

  const onOpenChat = (chatId) => {
    NavigationService.push(screens.ChatRoom, { chatId });
  };

  const onOpenFeed = () => {
    NavigationService.navigate(screens.MarketplaceTab);
  };

  const routes = [
    { key: 'askedToBuy', title: strings.buy_sell.asked_to_buy },
    { key: 'pendingDelivery', title: strings.buy_sell.buying_pending_delivery },
  ];

  const renderLazyPlaceholder = () => <Spinner />;

  return (
    <Container>
      <TabView
        navigationState={{ index, routes }}
        onIndexChange={setIndex}
        style={{
          direction: isWeb ? 'ltr' : 'inherit',
        }}
        sceneContainerStyle={
          isWeb && { direction: I18nManager.getConstants().isRTL ? 'rtl' : 'ltr' }
        }
        renderTabBar={(props) => <TabBar {...props} />}
        initialLayout={s.tabViewInitialLayout}
        lazy
        renderLazyPlaceholder={renderLazyPlaceholder}
        renderScene={({ route }) =>
          ({
            askedToBuy: (
              <OffersList
                data={askedToBuyOffers}
                isRefreshing={isRefreshing}
                isLoading={askedToBuyOfferLoadersInfo.isLoading}
                isLoadingMore={askedToBuyOfferLoadersInfo.isLoadingMore}
                onLoadMore={onLoadMoreAskedToBuyOffers}
                onRefresh={onRefresh}
                ListEmptyComponent={() => (
                  <EmptyListWithImage
                    image="empty_state_buying"
                    text={strings.emptyLists.empty_buying_offers_list}
                    buttonTitle={strings.emptyLists.go_to_marketplace}
                    onPress={onOpenFeed}
                  />
                )}
                renderItem={({ item }) => (
                  <BuyingOffer
                    offerType={BUYING}
                    offer={item}
                    onOpenChat={onOpenChat}
                    onPerformAction={onPerformAction}
                  />
                )}
              />
            ),
            pendingDelivery: (
              <OffersList
                data={pendingDeliveryOffers}
                isRefreshing={isRefreshing}
                isLoading={pendingDeliveryOfferLoadersInfo.isLoading}
                isLoadingMore={pendingDeliveryOfferLoadersInfo.isLoadingMore}
                onLoadMore={onLoadMorePendingDeliveryOffer}
                onRefresh={onRefresh}
                ListEmptyComponent={() => (
                  <EmptyListWithImage
                    image="empty_state_buying_delivery"
                    text={strings.emptyLists.empty_buying_pending_delivery_offers_list}
                    buttonTitle={strings.emptyLists.go_to_marketplace}
                    onPress={onOpenFeed}
                  />
                )}
                renderItem={({ item }) => (
                  <BuyingOffer
                    offerType={BUYING}
                    offer={item}
                    onOpenChat={onOpenChat}
                    onPerformAction={onPerformAction}
                  />
                )}
              />
            ),
          }[route.key])
        }
      />
    </Container>
  );
};

BuyingOffers.propTypes = {
  performAction: T.func,
  getBuyingAskedToBuyOffers: T.func,
  getBuyingPendingDeliveryOffers: T.func,
  askedToBuyOffers: T.array,
  pendingDeliveryOffers: T.array,
  askedToBuyOfferLoadersInfo: T.shape({
    isLoading: T.bool,
    isLoadingMore: T.bool,
  }),
  pendingDeliveryOfferLoadersInfo: T.shape({
    isLoading: T.bool,
    isLoadingMore: T.bool,
  }),
};

export default connect(
  (state, { searchValue }) => ({
    askedToBuyOffers: offersSelectors.getBuyingAskedToBuyOffers(state, searchValue),
    pendingDeliveryOffers: offersSelectors.getBuyingPendingDeliveryOffers(state, searchValue),
    askedToBuyOfferLoadersInfo: state.offers.buyingOffers.askedToBuy,
    pendingDeliveryOfferLoadersInfo: state.offers.buyingOffers.pendingDelivery,
  }),
  (dispatch) => ({
    performAction: (offerId, actionId) =>
      dispatch(offersOperations.performAction(offerId, actionId)),
    getBuyingAskedToBuyOffers: (isLoadMore) =>
      dispatch(offersOperations.getBuyingAskedToBuyOffers({ isLoadMore })),
    getBuyingPendingDeliveryOffers: (isLoadMore) =>
      dispatch(offersOperations.getBuyingPendingDeliveryOffers({ isLoadMore })),
  }),
)(BuyingOffers);
