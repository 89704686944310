import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, View } from 'react-native';
import { headerStyle } from '../../styles';
import { Categories, Groups, EditWish, PhotoTips } from '../../components/Screens';
import screens from '../screens';
import strings from '../../localization';
import { BackButton, NavigationButton } from '../../components/ReusableComponents';

const Stack = createStackNavigator();

const EditWishStack = () => (
  <Stack.Navigator screenOptions={{ ...headerStyle, headerRight: () => <View /> }}>
    <Stack.Screen
      name={screens.EditWish}
      component={EditWish}
      options={({ route }) => ({
        title: route.params?.wish ?? false ? strings.wishes.edit_wish : strings.wishes.new_wish,
        headerRight: () =>
          !I18nManager.getConstants().isRTL ? (
            <NavigationButton
              title={
                route.params?.wish ?? false ? strings.common.save : strings.lots.create_lot_button
              }
              disabled={!route.params?.isValidate ?? false}
              onButtonPress={route.params?.onSubmit ?? null}
            />
          ) : (
            <BackButton isCancel />
          ),
        headerLeft: () =>
          !I18nManager.getConstants().isRTL ? (
            <BackButton isCancel />
          ) : (
            <NavigationButton
              title={
                route.params?.wish ?? false ? strings.common.save : strings.lots.create_lot_button
              }
              disabled={!route.params?.isValidate ?? false}
              onButtonPress={route.params?.onSubmit ?? null}
            />
          ),
      })}
    />
    <Stack.Screen
      name={screens.Categories}
      component={Categories}
      options={{
        title: strings.lots.categories,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.Groups}
      component={Groups}
      options={{
        title: strings.lots.group,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen name={screens.PhotoTips} component={PhotoTips} options={{ headerShown: false }} />
  </Stack.Navigator>
);

export default EditWishStack;
