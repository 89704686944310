import React, { useState } from 'react';
import {
  TextButton,
  CenteredView,
  Text,
  TouchableItem,
  Spinner,
  SquareCheckBox,
  Container,
  Button,
} from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';
import { Image, KeyboardAvoidingView, View } from 'react-native';
import { AnalyticsService, NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { connect } from 'react-redux';
import { authOperations } from '../../../../store/auth';
import s from './styles';
import { getCurrentTheme } from '../../../../templates';

const TermsConditionsAgreement = ({ applyLegalDocumentsUpdate }) => {
  const [isLoading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);

  const currentTheme = getCurrentTheme();

  const onOpenPrivacy = () => {
    NavigationService.navigate(screens.PrivacyPolicy);
    AnalyticsService.logEvent(analyticsEventTypes.onboarding_privacy_policy);
  };

  const onOpenTerms = () => {
    NavigationService.navigate(screens.TermsAndConditions);
    AnalyticsService.logEvent(analyticsEventTypes.onboarding_terms_and_conditions);
  };

  const onSubmit = async () => {
    setLoading(true);
    await applyLegalDocumentsUpdate();
    setLoading(false);
  };

  const onAgreeWithTerms = () => {
    setChecked(!isChecked);
  };

  return (
    <Container>
      <View style={[rs.flex, rs.justifyCenter, rs.alignCenter]}>
        <Image source={{ uri: 'owl_security' }} resizeMode="contain" style={s.image} />

        <View style={[rs.row, rs.smallMarginTop, rs.veryBigMarginBottom, rs.bigMarginTop]}>
          <Text medium alignCenter style={rs.bigMarginHorizontal}>
            is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text
          </Text>
        </View>

        <TouchableItem style={s.termsAndConditionsContainer} onPress={onAgreeWithTerms}>
          <SquareCheckBox selected={isChecked} />
          <View style={rs.paddingLeft}>
            <View style={rs.row}>
              <Text medium>{strings.login.by_signing_up_you_agree} </Text>
              <TouchableItem onPress={onOpenTerms}>
                <Text color={currentTheme.colors.secondary} medium>
                  {strings.login.terms_and_conditions}
                </Text>
              </TouchableItem>
            </View>
            <View style={rs.row}>
              <Text medium>{strings.login.and} </Text>
              <TouchableItem onPress={onOpenPrivacy}>
                <Text color={currentTheme.colors.secondary} medium>
                  {strings.login.privacy_policy}
                </Text>
              </TouchableItem>
            </View>
          </View>
        </TouchableItem>
      </View>

      {isLoading ? (
        <Spinner />
      ) : (
        <View style={s.buttonContainer}>
          <Button title={strings.common.submit} onPress={onSubmit} disabled={!isChecked} />
        </View>
      )}
    </Container>
  );
};

export default connect(null, (dispatch) => ({
  applyLegalDocumentsUpdate: () => dispatch(authOperations.applyLegalDocumentsUpdate()),
}))(TermsConditionsAgreement);
