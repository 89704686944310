import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { dimensions, fontSizes } from '../../../../styles';
import Text from '../../Text';
import Image from '../../Image';
import { isWeb } from '../../../../utils/detectDevice';

export const styles = StyleSheet.create({
  title: {
    fontSize: 28,
    marginBottom: 30,
    textAlign: 'center',
  },
});

export const ContentContainer = styled.View`
  align-items: center;
`;

export const AdminAvatar = styled(Image)`
  height: 68px;
  width: 68px;
  margin-top: -34px;
`;

export const MainContainer = styled.View`
  padding-vertical: ${dimensions.doubleMedium};
  padding-horizontal: ${dimensions.medium};
  width: ${isWeb ? 'auto' : dimensions.width - 4 * dimensions.medium};
`;

export const ButtonContainer = styled.View`
  width: 100%;
  height: 40px;
  margin-top: 40px;
  flex-direction: row;
`;

export const Description = styled(Text)`
  font-size: ${fontSizes.medium};
  text-align: center;
`;
