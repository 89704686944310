import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { TouchableItem } from '../../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../../styles';
import { isWeb } from '../../../../../../utils/detectDevice';

export default StyleSheet.create({
  grayBackground: {
    backgroundColor: colors.grayLighter,
  },
  typeIconContainer: {
    height: 26,
    width: 26,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 13,
    position: 'absolute',
    left: 39,
    bottom: 10,
    ...rs.shadow,
  },
  typeIconContainerWeb: {
    height: 26,
    width: 26,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 13,
    position: 'absolute',
    left: 50,
    bottom: 15,
    ...rs.shadow,
  },
  separator: {
    width: '75%',
    height: 1,
    backgroundColor: colors.grayLight,
    marginRight: '5%',
    marginLeft: '20%',
  },
  rootContainer: {
    width: '100%',
    backgroundColor: colors.white,
  },
  bubbleNotification: {
    minWidth: 16,
    height: 16,
    paddingHorizontal: 4,
    borderWidth: 1,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: dimensions.halfMedium,
  },
});

export const ContentContainer = styled(TouchableItem)`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-vertical: ${dimensions.medium};
  max-height: ${isWeb ? 110 : 'auto'};
`;

export const MainContainer = styled.View`
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
  padding-horizontal: ${dimensions.medium};
`;

export const SecondContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const ChatListDetailContainer = styled.View`
  max-width: 75%;
  justify-content: space-around;
`;

export const CheckIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const ImageThumbnail = styled.Image`
  height: 14;
  width: 14;
  margin-right: ${dimensions.halfMedium};
  border-radius: 2;
`;
