import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyA9AQq6TKWsXQWVjDKIMFtbIhcYy35nLCw",
    authDomain: "react-firebase-9d942.firebaseapp.com",
    databaseURL: "https://react-firebase-9d942.firebaseio.com",
    projectId: "react-firebase-9d942",
    storageBucket: "react-firebase-9d942.appspot.com",
    messagingSenderId: "936456617497",
    appId: "1:936456617497:web:5e0a9c3314786447cf7488",
    measurementId: "G-KRTYENLFHV"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };