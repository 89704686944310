import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';

import * as types from './types';

const initialState = {
  theme: 'shareitt',
};

const communityThemeReducer = handleActions(
  {
    [types.SET_THEME]: mergeDeep(({ payload }, state) => {
        return { theme: payload };
    }),
  },
  initialState,
);

export default communityThemeReducer;
