import React from 'react';
import { StyleSheet, ActivityIndicator } from 'react-native';
import { colors, dimensions } from '../../styles';
import { isAndroid, isWeb } from '../../utils/detectDevice';
import { getCurrentTheme } from '../../templates';

const s = StyleSheet.create({
  spinner: {
    margin: dimensions.medium,
  },
});

const Spinner = ({ style, ...props }) => {
  const currentTheme = getCurrentTheme();
  return (
    <ActivityIndicator
      style={[s.spinner, style]}
      {...props}
      color={isAndroid || isWeb ? currentTheme.colors.primary : null}
    />
  );
};

export default Spinner;
