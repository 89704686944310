import React from 'react';
import { View, StyleSheet } from 'react-native';
import Button from '../../Buttons/Button';
import Image from '../../Image';
import TouchableItem from '../../TouchableItem';
import Text from '../../Text';
import { MainContainer } from './styles';
import strings from '../../../../localization';
import { NavigationService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { colors, rs, dimensions } from '../../../../styles';
import { connect } from 'react-redux';
import { userLocationsOperations } from '../../../../store/userLocations';
import locationsTypes from '../../../../constants/locationsTypes';
import { homeOperations } from '../../../../store/home';
import { sortingTypes } from '../../../../constants/sortingTypes';
import { lotsOperations } from '../../../../store/lots';

const s = StyleSheet.create({
  mainContainer: {
    backgroundColor: colors.white,
    height: 100,
    width: 100,
    borderRadius: 50,
    position: 'absolute',
    top: -40,
    alignItems: 'center',
    paddingTop: 16,
  },

  buttonContainer: {
    width: '100%',
    height: 40,
    marginTop: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    flexDirection: 'row',
  },
});

const AddLocation = ({ onPress, createLocation, getCommunityHomePage, setFeedLotFilters }) => {
  const onSelectLocation = async (location) => {
    await createLocation({ location, type: locationsTypes.USER });
    setFeedLotFilters({
      selectedCities: [],
      isSearchByPrimaryLocation: true,
      coordinates: null,
      sortBy: sortingTypes.by_distance,
    });
    NavigationService.navigate(screens.MarketplaceTab, {
      screen: screens.Marketplace,
    });
    getCommunityHomePage();
  };

  const onAddLocation = () => {
    NavigationService.navigate(screens.Location, {
      selectLocations: onSelectLocation,
    });
  };
  return (
    <View style={rs.alignCenter}>
      <View style={s.mainContainer}>
        <Image source={{ uri: 'primary_location' }} style={{ height: 38, width: 38 }} />
      </View>
      <MainContainer>
        <Text semiBold large alignCenter style={rs.paddingTop}>
          {strings.modals.add_location.title}
        </Text>
        <Text alignCenter medium style={rs.paddingTop}>
          {strings.modals.add_location.description}
        </Text>

        <View style={s.buttonContainer}>
          <Button
            title={strings.locations.add_address}
            onPress={() => {
              onPress();
              onAddLocation();
            }}
          />
        </View>

        <TouchableItem style={rs.bigMarginTop} onPress={onPress}>
          <Text alignCenter color={colors.gray}>
            {strings.modals.missing_tokens.not_now}
          </Text>
        </TouchableItem>
      </MainContainer>
    </View>
  );
};

export default connect(null, (dispatch) => ({
  setFeedLotFilters: (feedLotFilters) => dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
  getCommunityHomePage: () => dispatch(homeOperations.getCommunityHomePage()),
  createLocation: ({ location, type }) =>
    dispatch(userLocationsOperations.createLocation({ location, type })),
}))(AddLocation);
