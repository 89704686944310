import React, { Fragment, useState } from 'react';
import { ImageBackground, Modal, StyleSheet } from 'react-native';
import ImageViewer from 'react-native-image-zoom-viewer';
import FastImage from '../FastImage';
import Spinner from '../Spinner';
import { TouchableItem, View } from '../';
import { colors, dimensions, rs } from '../../../styles';
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { isIos, isIphoneX, isIPhone14 } from '../../../utils/detectDevice';
import { getIsDefaultProfileImage } from '../../../utils/userInfo';
import T from 'prop-types';

const s = StyleSheet.create({
  contentContainer: {
    backgroundColor: colors.secondary,
    paddingHorizontal: dimensions.medium,
  },
  infoContainer: {
    flexDirection: 'row',
    paddingTop: dimensions.medium,
    paddingBottom: dimensions.doubleMedium,
    alignItems: 'center',
  },
  avatarBackground: {
    borderRadius: 40,
  },
  avatar: {
    height: 80,
    width: 80,
    borderRadius: 40,
  },
  modalHeaderContainer: {
    zIndex: 10,
    position: 'absolute',
    top: isIos ? (isIphoneX ? 44 : 20) : 8,
    paddingRight: dimensions.halfMedium,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

const UserAvatarWithViewer = ({ profileImage, isSameUser }) => {
  const [isVisibleViewer, setVisibleViewer] = useState(false);

  const isDefaultProfileImage = getIsDefaultProfileImage(profileImage);
  const image = [{ url: profileImage, width: dimensions.width, height: dimensions.width }];

  const onPressProfileImage = () => {
    setVisibleViewer(true);
  };

  return (
    <Fragment>
      <Modal visible={isVisibleViewer} transparent>
        <ImageViewer
          loadingRender={() => <Spinner />}
          renderImage={(props) => <FastImage {...props} />}
          renderIndicator={() => {}}
          renderHeader={() => (
            <View style={s.modalHeaderContainer}>
              <TouchableItem
                style={[
                  rs.smallPaddingHorizontal,
                  rs.smallPaddingVertical,
                  isIPhone14 && rs.bigMarginTop,
                ]}
                onPress={() => setVisibleViewer(false)}
              >
                <MaterialIcon size={dimensions.iconSize} name="close" color={colors.white} />
              </TouchableItem>
              <View />
            </View>
          )}
          enableSwipeDown
          saveToLocalByLongPress={false}
          onCancel={() => setVisibleViewer(false)}
          imageUrls={image}
        />
      </Modal>

      <TouchableItem disabled={isDefaultProfileImage} onPress={onPressProfileImage}>
        <ImageBackground
          source={{ uri: isDefaultProfileImage ? profileImage : 'user_avatar_placeholder' }}
          resizeMode="cover"
          style={s.avatar}
          imageStyle={[
            s.avatarBackground,
            isDefaultProfileImage &&
              isSameUser && { backgroundColor: colors.secondary, tintColor: colors.white },
          ]}
        >
          {profileImage && !isDefaultProfileImage && (
            <FastImage source={{ uri: profileImage }} style={s.avatar} resizeMode="cover" />
          )}
        </ImageBackground>
      </TouchableItem>
    </Fragment>
  );
};

UserAvatarWithViewer.propTypes = {
  profileImage: T.string,
  isSameUser: T.bool,
};

UserAvatarWithViewer.defaultProps = {
  isSameUser: false,
};

export default UserAvatarWithViewer;
