import React, { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { FlatList, RefreshControl, StyleSheet, View } from 'react-native';
import T from 'prop-types';
import { chatsOperations } from '../../../../store/chats';
import { Container, Spinner, EmptyListWithImage } from '../../../ReusableComponents';
import NotificationItem from './components/NotificationItem';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { dimensions, rs } from '../../../../styles';
import screens from '../../../../navigation/screens';
import notificationOperation from '../../../../store/notifications/operations';
import { getNotifications } from '../../../../store/notifications/selectors';
import { usePrevious } from '../../../../utils/hooks';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { isWeb } from '../../../../utils/detectDevice';

const s = StyleSheet.create({
  tabViewInitialLayout: {
    height: 0,
    width: dimensions.width,
  },
  container: { backgroundColor: 'transparent' },
});

const Chats = ({
  archiveChats,

  notifications,
  isLoadingNotifications,
  isLoadingMoreNotifications,
  isRefreshingNotifications,
  onLoadMore,
  onRefreshNotifications,
  onPressNotification,
  onMarkNotificationAsOpen,
  onMarkNotificationsAsRead,
  language,
  toggleDrawerWidth,
  toggleNotification,
}) => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { showActionSheetWithOptions } = useActionSheet();

  const [selectedChatIds, setSelectedChatIds] = useState([]);

  useEffect(() => {
    navigation.setParams({
      onDonePress: () => {
        setSelectedChatIds([]);
        navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
      },
      onArchivePress: () => {
        setSelectedChatIds([]);
        navigation.setParams({ isEditMode: false, selectedChatsLength: 0 });
        archiveChats(selectedChatIds);
      },
    });
  }, [selectedChatIds]);

  const prevProps = usePrevious({ isFocused });

  useEffect(() => {
    if (isFocused) {
      onMarkNotificationsAsRead();
    }

    if (R.prop('isFocused', prevProps) && !isFocused) {
      onMarkNotificationsAsRead();
    }
  }, [isFocused]);

  useEffect(() => {
    navigation.setParams({
      onOpenActionSheet,
    });
  }, []);

  const onOpenActionSheet = useCallback(() => {
    const options = [
      strings.chats.add_chats_to_archive,
      strings.chats.archived_chats,
      strings.common.cancel,
    ];

    const cancelButtonIndex = options.length - 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          navigation.setParams({ isEditMode: true });
        }
        if (buttonIndex === 1) {
          NavigationService.navigate(screens.ArchivedChats);
        }
      },
    );
  }, []);

  const onNotificationPress = (notification) => {
    const { action_id, action_description, id } = notification;

    onMarkNotificationAsOpen(id);
    toggleDrawerWidth && toggleDrawerWidth();
    toggleNotification && toggleNotification();

    onPressNotification({ action_id, action_description: JSON.stringify(action_description) });
  };

  return (
    <Container style={s.container}>
      <FlatList
        data={notifications}
        keyExtractor={(item) => item.id.toString()}
        contentContainerStyle={isWeb && { maxWidth: 400 }}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshingNotifications}
            onRefresh={onRefreshNotifications}
          />
        }
        refreshing={isRefreshingNotifications}
        onEndReachedThreshold={0.7}
        onEndReached={onLoadMore}
        ListFooterComponent={isLoadingMoreNotifications && <Spinner />}
        ListEmptyComponent={
          isLoadingNotifications ? (
            <Spinner />
          ) : (
            <EmptyListWithImage
              image="empty_state_notifications"
              text={strings.emptyLists.empty_notifications_list}
            />
          )
        }
        renderItem={({ item }) => (
          <NotificationItem notification={item} language={language} onPress={onNotificationPress} />
        )}
      />
    </Container>
  );
};

Chats.propTypes = {
  chats: T.array,

  isLoadingChats: T.bool,
  isLoadingMoreChats: T.bool,

  isUnreadChats: T.bool,
  isUnreadInSellTab: T.bool,
  isUnreadInPersonalTab: T.bool,

  getAllChats: T.func,
  getSupportChatsForAdmins: T.func,

  getUnreadCountForAllChats: T.func,
  getUnreadCountForAdminSupportChats: T.func,
  archiveChats: T.func,
};

export default connect(
  (state) => ({
    notifications: getNotifications(state),
    unreadCountNotifications: state.notifications.unreadCount,

    isLoadingNotifications: state.notifications.isLoading,
    isLoadingMoreNotifications: state.notifications.isLoadingMore,
    isRefreshingNotifications: state.notifications.isRefreshing,
    language: state.app.language,
    currentUserId: state.userInfo.id,
  }),
  (dispatch) => ({
    archiveChats: (chatIds) => dispatch(chatsOperations.archiveChats(chatIds)),

    onLoadMore: () => dispatch(notificationOperation.getMoreNotifications()),
    onRefreshNotifications: () => dispatch(notificationOperation.getNotifications(true)),
    onPressNotification: (notificationData) =>
      dispatch(notificationOperation.onPressNotification(notificationData)),
    onMarkNotificationsAsRead: () => dispatch(notificationOperation.onMarkNotificationsAsRead()),
    onMarkNotificationAsOpen: (notificationId) =>
      dispatch(notificationOperation.onMarkNotificationAsOpen(notificationId)),
  }),
)(Chats);
