import React, { memo } from 'react';
import T from 'prop-types';
import { StyleSheet, Image, FlatList, ScrollView } from 'react-native';
import FastImage from '../../../../ReusableComponents/FastImage';
import { useSelector } from 'react-redux';
import {
  View,
  Text,
  TouchableItem,
  FontIcon,
  MarketplaceBanner,
} from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';
import strings from '../../../../../localization';
import { NavigationService } from '../../../../../services';
import { getIsUnverifiedUserEmail } from '../../../../../store/userInfo/selectors';
import { getPersonalRewardNavigationScreen } from '../../../../../utils/personalRewards';
import { getCurrentTheme } from '../../../../../templates';
import { getAppLanguage } from '../../../../../store/app/selectors';

const s = StyleSheet.create({
  contentContainer: {
    borderBottomWidth: 4,
    borderBottomColor: colors.grayLighter,
    marginBottom: dimensions.doubleMedium,
  },
  listContentContainer: {
    paddingBottom: dimensions.doubleMedium,
    paddingHorizontal: dimensions.halfMedium,
  },
  titleText: {
    paddingHorizontal: dimensions.medium,
    marginTop: 30,
    marginBottom: dimensions.medium,
  },
  itemContainer: {
    marginHorizontal: dimensions.halfMedium,
    alignItems: 'center',
    borderRadius: 4,
  },
  imageContainer: {
    width: 74,
    height: 74,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.grayLighter,
    borderRadius: 4,
    marginHorizontal: dimensions.halfMedium,
    marginBottom: dimensions.medium,
  },
  unachivedimageContainer: {
    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3,
    elevation: 4,
  },
  itemImage: {
    height: 54,
    width: 54,
  },
  achievedRewardIconContainer: {
    position: 'absolute',
    top: dimensions.halfMedium,
    left: dimensions.halfMedium,
    zIndex: 1000,
  },
  veryVerySmallMarginTop: {
    marginTop: 2,
  },
});

const Item = memo(({ item, isUnverifiedEmail }) => {
  const onPress = () => {
    const screen = getPersonalRewardNavigationScreen(item.app_action, { isUnverifiedEmail });
    NavigationService.navigate(screen);
  };

  const currentTheme = getCurrentTheme();

  return (
    <TouchableItem onPress={onPress} style={s.itemContainer} disabled={item.is_reached}>
      <View style={[s.imageContainer, !item.is_reached && s.unachivedimageContainer]}>
        {item.is_reached && (
          <View style={s.achievedRewardIconContainer}>
            <FontIcon name="checkmark_filled" color={colors.secondary} size={21} withoutRTLScale />
          </View>
        )}
        <FastImage source={{ uri: item.icon_url }} style={s.itemImage} />
      </View>
      <Text
        alignCenter
        color={item.is_reached ? currentTheme.colors.secondary : colors.gray}
        semiBold={item.is_reached}
      >
        {item.short_title}
      </Text>

      <Text
        color={item.is_reached ? currentTheme.colors.secondary : colors.gray}
        style={s.veryVerySmallMarginTop}
        semiBold={item.is_reached}
      >
        {item.max_reward_amount} {strings.bank.tokens}
      </Text>
    </TouchableItem>
  );
});

const UserInfoRewardsList = memo(({ data }) => {
  const isUnverifiedEmail = useSelector(getIsUnverifiedUserEmail);
  const language = useSelector(getAppLanguage);
  const communityInfo = useSelector((state) => state.communityInfo);

  return (
    <View style={s.contentContainer}>
      <MarketplaceBanner
        secondType
        pointsName={
          (communityInfo?.token_name && communityInfo.token_name[language]) ||
          strings.home.token_banner_blue_text
        }
      />
      <Text large semiBold style={s.titleText}>
        {strings.rewards.user_info_rewards_title}
      </Text>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={s.listContentContainer}
        // data={userInfoRewards}
        data={data}
        keyExtractor={({ id }) => id.toString()}
        renderItem={({ item }) => <Item item={item} isUnverifiedEmail={isUnverifiedEmail} />}
      />
    </View>
  );
});

UserInfoRewardsList.propTypes = {
  // profileImage: T.string,
};

export default UserInfoRewardsList;
