import { analytics } from './firebaseConfig';
import { ENV } from '../../config/env';
import {
  setUserProperties,
  setUserId,
  logEvent,
  setAnalyticsCollectionEnabled,
} from 'firebase/analytics';

class AnalyticsService {
  constructor() {
    this.groupId = '';
    this.userId = '';
    this.sessionStartDate = null;
    this.screenName = '';
    this._analytics = analytics;
  }

  setUserPreferences(userId, userName) {
    if (ENV !== 'production') return;

    this.userId = `${userId}`;
    this.sessionStartDate = new Date().getTime().toString();

    setUserProperties(this._analytics, {
      userName: userName,
    });

    setUserId(this._analytics, `${userId}`);

    setAnalyticsCollectionEnabled(this._analytics, true);
  }

  setGroupId(groupId) {
    if (ENV !== 'production') return;
    this.groupId = `${groupId}`;
  }

  logEvent(eventName = '', eventValues = {}) {
    if (ENV !== 'production' || !eventName) return;

    logEvent(this._analytics, eventName, eventValues);
  }

  logEventOpenScreen(screenName) {
    if (ENV !== 'production') return;

    logEvent(this._analytics, 'open_screen', {
      screenName,
      userId: this.userId,
      sessionStartDate: this.sessionStartDate,
    });
  }
}

const analyticsService = new AnalyticsService();

export default analyticsService;
