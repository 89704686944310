import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/native';
import Button from '../../Buttons/Button';
import Text from '../../Text';
import { ContentContainer, AdminAvatar, ButtonContainer } from './styles';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';
import { groupsSelectors } from '../../../../store/groups';
import { lotsOperations } from '../../../../store/lots';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import { isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';

const MainContainer = styled.View`
  padding-vertical: ${dimensions.medium};
  padding-horizontal: ${dimensions.medium};
  width: ${isWeb ? 'auto' : dimensions.width - 4 * dimensions.medium};
`;

class TokensExchangePrompt extends Component {
  onOpenExchangeTokens = () => {
    const { onClose, params } = this.props;
    const { missing_amount, price, item_title, token_name, offer } = params;
    NavigationService.navigate(screens.AskToBuyTokensExchange, {
      exchange_params: {
        missing_amount,
        item_price: price,
        to_token_name: token_name,
        item_title,
        offer,
      },
    });
    onClose();
  };

  render() {
    const { params, onClose } = this.props;

    const { missing_amount, item_title, token_name } = params;

    const currentTheme = getCurrentTheme();
    return (
      <ContentContainer>
        <AdminAvatar source={{ uri: 'round_logo' }} resizeMode="contain" />
        <MainContainer>
          <Text alignCenter semiBold large>
            {strings.modals.tokens_exchange.missing}{' '}
            <Text large semiBold color={currentTheme.colors.secondaryLight}>
              {missing_amount}
            </Text>{' '}
            {token_name}
            {'\n'}
            {strings.modals.tokens_exchange.to_purchase} {item_title}
          </Text>
          <Text alignCenter medium style={[rs.marginTop, rs.paddingHorizontal]}>
            {strings.modals.tokens_exchange.use_other_tokens_to_purchase_the_item}
          </Text>

          <ButtonContainer>
            <Button withMarginRight title={strings.common.no_thanks} onPress={onClose} />
            <Button
              title={strings.modals.tokens_exchange.exchange_tokens}
              onPress={this.onOpenExchangeTokens}
            />
          </ButtonContainer>
        </MainContainer>
      </ContentContainer>
    );
  }
}

export default TokensExchangePrompt;
