import * as R from 'ramda';
import api from './api';
import { getImageType } from '../utils/stringHelper';
import { LOTS_LIMIT } from '../constants/listLimits';
import { isWeb } from '../utils/detectDevice';

export default {
  getUserWishes: ({ group_ids, sort_by, user_account_id, skip = 0, is_current_user = false }) =>
    api.post('getWishes', {
      group_ids,
      user_account_id,
      skip,
      limit: LOTS_LIMIT,
      sort_by,
      is_current_user,
    }),

  createNewWish({
    wisherId,
    group_id,
    title,
    description,
    images,
    // sizeId,
    category_id,
    item_type_id,
    sub_community_ids,
    // locations,
    // pay_for_shipping,
    // pay_for_boxit,
  }) {
    const form = new FormData();
    form.append('wisher_id', wisherId);
    form.append('group_id', group_id);
    form.append('title', title);
    form.append('description', description);
    form.append('category_id', category_id);
    form.append('item_type_id', item_type_id);
    form.append('sub_community_ids', JSON.stringify(sub_community_ids));
    // form.append('size_id', sizeId);
    // form.append('pay_for_shipping', pay_for_shipping);
    // form.append('pay_for_boxit', pay_for_boxit);
    // form.append('locations', JSON.stringify(locations));

    if (isWeb) {
      form.append('image_1', images[0] ? images[0] : '');
      form.append('image_2', images[1] ? images[1] : '');
      form.append('image_3', images[2] ? images[2] : '');
      form.append('image_4', images[3] ? images[3] : '');
    } else {
      form.append(
        'image_1',
        images[0]
          ? {
              uri: images[0],
              name: `image_1.${getImageType(images[0])}`,
              type: `image/${getImageType(images[0])}`,
            }
          : '',
      );
      form.append(
        'image_2',
        images[1]
          ? {
              uri: images[1],
              name: `image_2.${getImageType(images[1])}`,
              type: `image/${getImageType(images[1])}`,
            }
          : '',
      );
      form.append(
        'image_3',
        images[2]
          ? {
              uri: images[2],
              name: `image_3.${getImageType(images[2])}`,
              type: `image/${getImageType(images[2])}`,
            }
          : '',
      );
      form.append(
        'image_4',
        images[3]
          ? {
              uri: images[3],
              name: `image_4.${getImageType(images[3])}`,
              type: `image/${getImageType(images[3])}`,
            }
          : '',
      );
    }
    // form.append(
    //   'image_5',
    //   images[4]
    //     ? {
    //         uri: images[4],
    //         name: `image_5.${getImageType(images[4])}`,
    //         type: `image/${getImageType(images[4])}`,
    //       }
    //     : '',
    // );

    return api.post('createNewWish', form);
  },

  getWishInfo: (id) => api.get(`getWishInfo/${id}`),

  getWishDetails: (id) => api.get(`getWishDetails/${id}`),

  deleteWish: ({ wish_id, wisher_id }) => api.delete(`wish/${wish_id}/${wisher_id}`),

  hideWish: (wishId) =>
    api.post('hideWish', {
      wish_id: wishId,
    }),

  unhideWish: (wishId) =>
    api.post('unhideWish', {
      wish_id: wishId,
    }),

  editWish({
    wisherId,
    group_id,
    title,
    description,
    images,
    wishId,
    category_id,
    sizeId,
    item_type_id,
    // locations,
    // pay_for_shipping,
    // pay_for_boxit,
  }) {
    const form = new FormData();
    form.append('wisher_id', wisherId);
    form.append('wish_id', wishId);
    form.append('group_id', group_id);
    form.append('title', title);
    form.append('description', description);
    form.append('category_id', category_id);
    form.append('size_id', sizeId);
    form.append('item_type_id', item_type_id);
    // form.append('pay_for_shipping', pay_for_shipping);
    // form.append('pay_for_boxit', pay_for_boxit);
    // form.append('locations', JSON.stringify(locations));

    if (isWeb) {
      form.append('image_1', images[0] ? images[0] : '');
      form.append('image_2', images[1] ? images[1] : '');
      form.append('image_3', images[2] ? images[2] : '');
      form.append('image_4', images[3] ? images[3] : '');
    } else {
      form.append(
        'image_1',
        images[0].startsWith('http') || R.isEmpty(images[0])
          ? images[0]
          : {
              uri: images[0],
              name: `image_1.${getImageType(images[0])}`,
              type: `image/${getImageType(images[0])}`,
            },
      );

      form.append(
        'image_2',
        images[1].startsWith('http') || R.isEmpty(images[1])
          ? images[1]
          : {
              uri: images[1],
              name: `image_2.${getImageType(images[1])}`,
              type: `image/${getImageType(images[1])}`,
            },
      );
      form.append(
        'image_3',
        images[2].startsWith('http') || R.isEmpty(images[2])
          ? images[2]
          : {
              uri: images[2],
              name: `image_3.${getImageType(images[2])}`,
              type: `image/${getImageType(images[2])}`,
            },
      );
      form.append(
        'image_4',
        images[3].startsWith('http') || R.isEmpty(images[3])
          ? images[3]
          : {
              uri: images[3],
              name: `image_4.${getImageType(images[3])}`,
              type: `image/${getImageType(images[3])}`,
            },
      );
    }
    // form.append(
    //   'image_5',
    //   images[4].startsWith('http') || R.isEmpty(images[4])
    //     ? images[4]
    //     : {
    //         uri: images[4],
    //         name: `image_5.${getImageType(images[4])}`,
    //         type: `image/${getImageType(images[4])}`,
    //       },
    // );

    return api.put(`wish/${wishId}/${wisherId}`, form);
  },

  getWishes: ({ userId, categories = [], groupIds = [], searchTerm, skip = 0 }) => {
    return api.post('getGroupWishesByFilters', {
      user_id: userId,
      group_ids: groupIds,
      categories,
      search_term: searchTerm,
      skip,
      limit: LOTS_LIMIT,
    });
  },
};
