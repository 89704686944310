import React, { memo, Fragment } from 'react';
import { StyleSheet } from 'react-native';
import T from 'prop-types';

import { colors, dimensions } from '../../../styles';
import { AngleIcon, TouchableItem, Text } from '../';
import { isWeb } from '../../../utils/detectDevice';
import { getCurrentTheme } from '../../../templates';

const styles = StyleSheet.create({
  buttonContainer: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
  },
  buttonTitle: {
    minWidth: isWeb ? 160 : 110,
  },
  multipleSupplyDescriptionText: {
    lineHeight: 20,
  },
});

const ItemDetailsButton = memo(({ selectedValue, title, placeholder, onPress }) => {
  const currentTheme = getCurrentTheme();
  const hasSelectedValue = Boolean(selectedValue);

  return (
    <TouchableItem onPress={onPress} style={styles.buttonContainer} useOpacity={false}>
      <Fragment>
        {!!title && <Text style={styles.buttonTitle}>{title}</Text>}
        {hasSelectedValue ? (
          <Text color={currentTheme.colors.secondary} semiBold>
            {selectedValue}
          </Text>
        ) : (
          <Text color={colors.gray}>{placeholder}</Text>
        )}

        <AngleIcon />
      </Fragment>
    </TouchableItem>
  );
});

ItemDetailsButton.propTypes = {
  selectedValue: T.oneOfType([T.string, T.arrayOf(T.string)]),
  title: T.string,
  placeholder: T.string,
  onPress: T.func.isRequired,
};

ItemDetailsButton.defaultProps = {
  selectedValue: null,
  title: null,
  placeholder: '',
};

export default ItemDetailsButton;
