/* eslint-disable react/destructuring-assignment,react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { SafeAreaView, View, TextInput } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Separator, Container, Text, TouchableItem } from '../../../ReusableComponents';
import s from './styles';
import { colors, rs } from '../../../../styles';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import {
  getUserLocations,
  getUserShipperLocations,
} from '../../../../store/userLocations/selectores';
import SectionWithTitle from './components/SectionWithTitle';
import ShippingOption from './components/ShippingOption';
import { ButtonContainer } from '../../../ReusableComponents/Buttons/ListButton';
import StaticHeightButton from '../../../ReusableComponents/Buttons/StaticHeightButton';
import { isWeb } from '../../../../utils/detectDevice';
import { getPriceCalculatorUrlByCommunityId } from './DomesticShippingCalculationScreen';
import { getCommunityId, getIsBrazilCommunity } from '../../../../store/communityInfo/selectors';
import { getCurrentTheme } from '../../../../templates';

class Shipping extends Component {
  state = {
    shipping_price: '',
    locations: [],
    selectedLocations: [],
    isActiveMeet: false,
    isActiveShipping: false,
    isShippersDeliveryActive: false,
  };

  componentDidMount() {
    const { route, userLocations } = this.props;

    const shipping_price = route.params.shipping_price || '';
    const itemLocations = route.params.itemLocations;

    const isShippersDeliveryActive = route.params.isShippersDeliveryActive;

    const filteredArray = userLocations.filter(
      (userLocation) =>
        !itemLocations.some(
          (itemLocation) =>
            itemLocation.latitude === userLocation.latitude &&
            itemLocation.longitude === userLocation.longitude,
        ),
    );

    const locations = [...itemLocations.filter((el) => el), ...filteredArray];

    this.setState({
      selectedLocations: itemLocations.filter((el) => !!el),
      locations,
      shipping_price: shipping_price.toString(),
      isActiveMeet: !!itemLocations.length,
      isActiveShipping: !!shipping_price,
      isShippersDeliveryActive,
    });
  }

  componentDidUpdate(oldProps, oldState) {
    const newState = this.state;
    const isTalent = this.props.route.params.isTalent;

    if (isTalent) {
      this.props.navigation.setOptions({
        title: strings.lots.talent_address_options,
      });
    }

    if (
      oldState.shipping_price !== newState.shipping_price ||
      oldState.isShippersDeliveryActive !== newState.isShippersDeliveryActive ||
      oldState.selectedLocations.length !== newState.selectedLocations.length
    ) {
      this.props.navigation.setParams({
        isValid:
          !!newState.shipping_price ||
          newState.selectedLocations.length ||
          newState.isShippersDeliveryActive,
      });
    }
  }

  onSubmit = () => {
    const {
      selectedLocations,
      shipping_price,
      isShippersDeliveryActive,
      isActiveMeet,
    } = this.state;
    const { navigation, route } = this.props;

    const onSetShipping = route.params.onSetShipping;

    onSetShipping({
      locations: isActiveMeet ? selectedLocations : [],
      shipping_price: parseInt(shipping_price) || null,
      isShippersDeliveryActive,
    });

    navigation.goBack();
  };

  onShippingCostChange = (val) => {
    const res = val ? parseInt(val).toString() : '';

    if (!!val && !this.state.shipping_price) {
      this.setState({
        isActiveShipping: true,
      });
    }

    this.setState({
      shipping_price: res,
    });
  };

  onActivatePersonalPickup = () => {
    const { route } = this.props;
    const { isActiveMeet, selectedLocations } = this.state;
    const itemLocations = route.params.itemLocations;

    if (isActiveMeet) {
      this.setState({
        isActiveMeet: false,
      });
      return;
    }
    // else if (!R.isEmpty(selectedLocations)) {
    //   this.setState({
    //     isActiveMeet: true,
    //   });
    //   return;
    // }

    NavigationService.navigate(screens.ItemPickupLocations, {
      itemLocations,
      onSetPickupLocations: this.onSetPickupLocations,
    });
  };

  onSetPickupLocations = (locations) => {
    this.setState({
      isActiveMeet: true,
      selectedLocations: locations,
    });
  };

  onActivateShipping = () => {
    this.setState((prevState) => {
      return {
        isActiveShipping: !prevState.isActiveShipping,
        shipping_price: '',
      };
    });
  };

  onActivateShippersDelivery = () => {
    this.setState((prevState) => {
      return {
        isShippersDeliveryActive: !prevState.isShippersDeliveryActive,
      };
    });
  };

  onCalculateShippingCostPress = () => {
    if (isWeb) {
      const { communityId } = this.props;
      const uri = getPriceCalculatorUrlByCommunityId(communityId);
      window.open(uri, '_blank').focus();
    } else {
      NavigationService.navigate(screens.DomesticShippingCalculation);
    }
  };

  render() {
    const {
      shipping_price,
      locations,
      selectedLocations,
      isActiveMeet,
      isActiveShipping,
      isShippersDeliveryActive,
    } = this.state;
    const { communityInfo, isShipper, shipperLocations, isBrazilCommunity } = this.props;
    const isApplyDisabled =
      !isActiveMeet &&
      !isShippersDeliveryActive &&
      (!isActiveShipping || (isActiveShipping && !shipping_price));

    const currentTheme = getCurrentTheme();
    const isTalent = this.props.route.params.isTalent;

    return (
      <Container>
        <KeyboardAwareScrollView keyboardShouldPersistTaps="handled" enableResetScrollToCoords>
          <SectionWithTitle
            title={isTalent ? strings.shipping.location : strings.shipping.pickup_section_title}
            label={strings.shipping.recommended_label}
          >
            <ShippingOption
              title={isTalent ? strings.shipping.talent_address : strings.shipping.meet_in_person}
              subtitle={strings.shipping.arrange_to_meet}
              iconName="Shipping_personal-pickup"
              onPress={this.onActivatePersonalPickup}
              selected={isActiveMeet}
              infoIconText={strings.shipping.tooltip_info_personal_pickup}
              hasArrow
              isAlwaysShowChildren
            >
              {!R.isEmpty(selectedLocations) && (
                <Fragment>
                  <Separator style={rs.marginVertical} />

                  <View style={s.shippingOptionInner}>
                    {selectedLocations.map(({ formatted_address }, index) => {
                      return (
                        <View
                          key={index.toString()}
                          style={[rs.row, rs.alignCenter, s.locationsListItem]}
                        >
                          <Text
                            key={index.toString()}
                            color={colors.gray}
                            xsmall
                            style={s.addressText}
                          >
                            {formatted_address}
                          </Text>
                        </View>
                      );
                    })}
                  </View>
                </Fragment>
              )}
            </ShippingOption>
          </SectionWithTitle>

          {!!isShipper && !R.isEmpty(shipperLocations) && (
            <SectionWithTitle title={strings.shipping.shippers_delivery_section_title}>
              <ShippingOption
                title={strings.shipping.shippers_delivery}
                subtitle={strings.shipping.shippers_delivery_description}
                iconName="scooter"
                infoIconText={strings.shipping.tooltip_info_shippers_delivery}
                onPress={this.onActivateShippersDelivery}
                selected={isShippersDeliveryActive}
              />
            </SectionWithTitle>
          )}

          {!isTalent && (
            <SectionWithTitle
              title={
                isBrazilCommunity
                  ? strings.shipping.domestic_shipping_section_title_brazil
                  : strings.shipping.domestic_shipping_section_title
              }
            >
              <ShippingOption
                title={strings.shipping.domestic_shipping}
                subtitle={strings.shipping.ship_via_local_carrier}
                iconName="Shipping_domestic-shipping"
                onPress={this.onActivateShipping}
                selected={isActiveShipping}
              >
                <View style={s.shippingOptionInner}>
                  <TextInput
                    value={shipping_price}
                    onChangeText={this.onShippingCostChange}
                    placeholder={strings.shipping.shipping_placeholder.replace(
                      'CURRENCY',
                      communityInfo.zone_currency_code,
                    )}
                    keyboardType="numeric"
                    style={s.shippingPriceInput}
                    autoFocus
                  />
                  {isBrazilCommunity ? (
                    <Text style={rs.marginTop} xsmall>
                      {strings.shipping.zip_code}
                    </Text>
                  ) : (
                    <TouchableItem onPress={this.onCalculateShippingCostPress} style={rs.marginTop}>
                      <Text color={currentTheme.colors.secondary} xsmall>
                        {communityInfo.zone_name === 'israel'
                          ? strings.shipping.calculate_shipping_cost
                          : strings.shipping.calculate_shipping_cost_netherlands}
                      </Text>
                    </TouchableItem>
                  )}
                </View>
              </ShippingOption>
            </SectionWithTitle>
          )}
        </KeyboardAwareScrollView>

        <SafeAreaView style={rs.marginBottom}>
          <ButtonContainer withoutBorder>
            <StaticHeightButton
              title={strings.common.apply}
              onPress={this.onSubmit}
              disabled={isApplyDisabled}
            />
          </ButtonContainer>
        </SafeAreaView>
      </Container>
    );
  }
}

export default connect((state, { navigation }) => ({
  communityInfo: state.communityInfo,
  isShipper: state.userInfo.is_shipper,
  userLocations: getUserLocations(state),
  shipperLocations: getUserShipperLocations(state),
  communityId: getCommunityId(state),
  isBrazilCommunity: getIsBrazilCommunity(state),
}))(Shipping);
