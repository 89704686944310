export const VERIFICATION_CODE_CONFIRMED = 'verification_code_confirmed';
export const UPLOAD_ITEM = 'upload_item';
export const UPLOAD_WISH = 'upload_wish';
export const UPLOAD_SKILL = 'upload_skill'; 
export const UPLOAD_WISH_FULFILLMENT_ITEM = 'upload_wish_fulfillment_item';
export const ASK_TO_BUY = 'ask_to_buy';
export const SEll_ITEM = 'sell_item';
export const buy_ITEM = 'buy_item';

/** LOGIN */
export const onboarding_next_button = 'onboarding_next_button';
export const onboarding_start = 'onboarding_start';
export const onboarding_confirm_phone_number = 'onboarding_confirm_phone_number';
export const onboarding_privacy_policy = 'onboarding_privacy_policy';
export const onboarding_terms_and_conditions = 'onboarding_terms_and_conditions';
export const onboarding_send_code_again = 'onboarding_send_code_again';
export const onboarding_confirm_code = 'onboarding_confirm_code';
export const onboarding_welcome_start = 'onboarding_welcome_start';

/** EARN POINTIS */
export const earn_points_add_name = 'earn_points_add_name';
export const earn_points_add_avatar = 'earn_points_add_avatar';
export const earn_points_add_nickname = 'earn_points_add_nickname';
export const earn_points_add_email = 'earn_points_add_email';
export const earn_points_add_birthday = 'earn_points_add_birthday';
export const earn_points_add_gender = 'earn_points_add_gender';

/** HOME SCREEN */
export const home_see_all_categories = 'home_see_all_categories';
export const home_see_all_items_near_me = 'home_see_all_items_near_me';
export const home_see_all_items_from_followings = 'home_see_all_items_from_followings';
export const home_see_all_items_in_category = 'home_see_all_items_in_category';
export const home_see_all_discounted_items = 'home_see_all_discounted_items';

export const home_select_top_seller = 'home_select_top_seller';
export const home_select_seller_near_me = 'home_select_seller_near_me';
export const home_select_featured_item = 'home_select_featured_item';
export const home_select_item_near_me = 'home_select_item_near_me';
export const home_select_items_near_primary_location = 'home_select_items_near_primary_location';
export const home_select_item_from_following = 'home_select_item_from_following';
export const home_select_item_in_category = 'home_select_item_in_category';
export const home_select_discounted_item = 'home_select_discounted_item';
export const home_select_most_followed = 'home_select_most_followed';
export const home_select_my_seller = 'home_select_my_seller';
export const home_select_popular_category = 'home_select_popular_category';
export const home_select_my_top_category = 'home_select_my_top_category';

export const home_select_wish_banner = 'home_select_wish_banner';
export const home_select_invite_friends_banner = 'home_select_invite_friends_banner';
export const home_select_rewards = 'home_select_rewards';
export const home_select_current_user_profile = 'home_select_current_user_profile';
export const home_select_search = 'home_select_search';
export const home_select_group = 'home_select_group';

/** MARKETPLACE SCREEN */
export const marketplace_select_item = 'marketplace_select_item';

/** USER PROFILE SCREEN */
export const user_profile_select_item = 'user_profile_select_item';

export const open_screen = 'open_screen';

/** USERS MAP */

// will be triggered each time user presses map button on the Marketplace screen
export const users_map_open_map_screen = 'users_map_open_screen';

// will be triggered each time user presses on user avatar marker on the Map screen
export const users_map_highlight_user_avatar = 'users_map_highlight_user_avatar';

// will be triggered each time user navigates to another user profile through the Map screen
export const users_map_open_user_profile = 'users_map_open_user_profile';

/** PROMOTION SCREEN */

export const promotion_was_shown = 'promotion_was_shown';

export const promotion_closed_with_cross_button = 'promotion_was_closed_with_cross_button';

export const promotion_redirect_action_triggered = 'promotion_redirect_action_triggered';

/** NETWORK CONNECTION LOST */

export const network_connection_lost_wifi = 'network_connection_lost_wifi';

export const network_connection_lost_cellular = 'network_connection_lost_cellular';

/** INVITE FRIENDS */
export const invite_friends_share_button_pressed = 'invite_friends_share_button_pressed';

/** RATE THE APP */
export const rate_app_was_shown = 'rate_app_was_shown';
