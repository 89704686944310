import React, { useState, useEffect, memo, Fragment } from 'react';
import T from 'prop-types';
import { StyleSheet, View, FlatList } from 'react-native';
import { connect } from 'react-redux';
import { userOperations } from '../../../../store/user';
import strings from '../../../../localization';
import { isIos } from '../../../../utils/detectDevice';
import { Button, Container, Icon, NavigationButton, Separator, Text, TouchableItem, Image } from '../../';
import { colors, dimensions, rs } from '../../../../styles';

const s = StyleSheet.create({
  headerContainer: {
    paddingTop: isIos ? dimensions.statusBarHeight : 0,
    height: dimensions.headerHeight,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
  },
  modalDescription: {
    flexDirection: 'row',
    margin: dimensions.medium,
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.large,
    paddingVertical: dimensions.halfLarge,
    borderRadius: 4,
  },
  image: {
    width: 50,
    height: 66,
    marginRight: dimensions.large,
  },
  descriptionTitle: {
    lineHeight: 22,
  },
  descriptionText: {
    width: 250,
  },
  itemContainer: {
    flexDirection: 'row',
    paddingVertical: dimensions.doubleMedium,
    paddingHorizontal: dimensions.medium,
    alignItems: 'center',
    flexGrow: 1,
  },
  itemTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  itemBody: {
    maxWidth: '85%',
  },
  itemCheckbox: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderRadius: 2,
    borderColor: colors.grayBorder,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: dimensions.medium,
  },
  itemCheckboxSelected: {
    borderColor: colors.greenDark,
    backgroundColor: colors.greenDark,
  },
  submitButtonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.medium,
    marginBottom: dimensions.doubleMedium,
  },
});

const EndorsementItem = memo(({ title, body, isSelected, onPress }) => (
  <TouchableItem style={s.itemContainer} onPress={onPress}>
    <Fragment>
      <View style={rs.flex}>
        <View style={s.itemTitle}>
          <Text medium semiBold>
            {title}
          </Text>
        </View>
        <Text color={colors.gray} style={s.itemBody}>
          {body}
        </Text>
      </View>

      <View style={[s.itemCheckbox, isSelected && s.itemCheckboxSelected]}>
        <Icon
          name={isSelected ? 'check' : 'plus'}
          size={16}
          color={isSelected ? colors.white : colors.grayBorder}
          withoutRTLScale
        />
      </View>
    </Fragment>
  </TouchableItem>
));

const EndorseUserModal = ({ params, onClose, getUserEndorsements, endorseUser }) => {
  const [endorsements, setEndorsements] = useState([]);
  const [selectedEndorsementIds, setSelectedEndorsementIds] = useState([]);

  useEffect(() => {
    getEndorsements();
  }, []);

  const getEndorsements = async () => {
    const data = await getUserEndorsements(params.userId);
    setEndorsements(data);
  };

  const onEndorsementPress = (endorsementId) => {
    const isSelected = selectedEndorsementIds.includes(endorsementId);

    setSelectedEndorsementIds(
      isSelected
        ? selectedEndorsementIds.filter((id) => id !== endorsementId)
        : [...selectedEndorsementIds, endorsementId],
    );
  };

  const onDonePress = async () => {
    await Promise.all(
      selectedEndorsementIds.map(async (id) => {
        await endorseUser(id);
      }),
    );
    onClose();
  };

  const isDoneButtonDisabled = selectedEndorsementIds.length === 0;

  return (
    <Container>
      <View style={s.headerContainer}>
        <NavigationButton title={strings.common.later} onButtonPress={onClose} />
      </View>

      <FlatList
        data={endorsements}
        keyExtractor={({ id }) => id.toString()}
        ItemSeparatorComponent={() => <Separator />}
        renderItem={({ item }) => {
          const isSelected = selectedEndorsementIds.includes(item.id);
          const onPress = () => {
            onEndorsementPress(item.id);
          };

          return (
            <EndorsementItem
              title={item.title}
              body={item.body}
              isSelected={isSelected}
              onPress={onPress}
            />
          );
        }}
        style={rs.paddingBottom}
        ListHeaderComponent={() => (
          <View style={s.modalDescription}>
            <Image source={{ uri: 'endorsements_big' }} style={s.image} resizeMode="contain" />
            <View style={rs.justifyCenter}>
              <Text color={colors.text} style={s.descriptionTitle} semiBold medium>
                {strings.modals.endorse_seller.description_title}
              </Text>
              <Text color={colors.text} style={s.descriptionText} semiBold medium>
                {strings.modals.endorse_seller.description_text}
              </Text>
            </View>
          </View>
        )}
      />

      <View style={s.submitButtonContainer}>
        <Button title={strings.common.done} onPress={onDonePress} disabled={isDoneButtonDisabled} />
      </View>
    </Container>
  );
};

EndorseUserModal.propTypes = {
  params: T.shape({
    userId: T.oneOfType([T.string, T.number]).isRequired,
  }),
  onClose: T.func.isRequired,
  getUserEndorsements: T.func.isRequired,
  endorseUser: T.func.isRequired,
};

export default connect(null, (dispatch, { params }) => ({
  getUserEndorsements: (userId) => dispatch(userOperations.getUserEndorsements(userId)),
  endorseUser: (endorsementId) =>
    dispatch(
      userOperations.endorseUser({
        userId: params.userId,
        endorsementId,
      }),
    ),
}))(EndorseUserModal);
