import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyleSheet, KeyboardAvoidingView, Keyboard, View } from 'react-native';
import Animated, { SlideInRight, SlideOutLeft } from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Text, InputWithTitleAndValidation, Button } from '../../../ReusableComponents';
import { LoadingService, NavigationService } from '../../../../services';
import { subCommunityOperations } from '../../../../store/subCommunities';
import strings from '../../../../localization';
import { isIos } from '../../../../utils/detectDevice';
import { rs, colors, dimensions } from '../../../../styles';
import JoinRequestStatus from '../../../../constants/JoinRequestStatus';

const FlowSlide = {
  Slide1: 'slide1',
  Slide2: 'slide2',
};

const s = StyleSheet.create({
  mainContainer: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderRadius: 30,
    paddingVertical: 30,
    minHeight: 150,
    paddingHorizontal: dimensions.large,
    shadowColor: colors.gray,
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.24,
    shadowRadius: 13.84,
    elevation: 17,
  },
  inputContainer: {
    marginTop: 40,
    backgroundColor: colors.grayLighter,
    width: 200,
    borderRadius: 20,
    height: 40,
  },
  buttonSectionContainer: {
    flexShrink: 1,
  },
  buttonContainer: {
    height: 40,
    width: '100%',
    paddingHorizontal: dimensions.large,
  },
});

const JoinSubCommunityByCodeScreen = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState('');
  const [slide, setSlide] = useState(FlowSlide.Slide1);
  const [requestStatus, setRequestStatus] = useState(null);

  const onSubmitEditing = async () => {
    LoadingService.showLoader();
    const status = await dispatch(subCommunityOperations.askToJoinPrivateSubCommunity(code));
    LoadingService.hideLoader();

    Keyboard.dismiss();
    setCode('');
    setRequestStatus(status ?? null);

    if (status) {
      setSlide(FlowSlide.Slide2);
    }
  };

  const onJoinAnother = () => {
    setRequestStatus(null);
    setSlide(FlowSlide.Slide1);
  };

  const onFinish = () => {
    NavigationService.goBack();
  };

  const onChangeText = (value) => {
    setCode(value.trim());
  };

  return (
    <SafeAreaView edges={['bottom']} style={[rs.flex, rs.backgroundWhite]}>
      <KeyboardAvoidingView behavior={isIos ? 'padding' : null} style={[rs.flex]}>
        {slide === FlowSlide.Slide1 ? (
          <Animated.View
            // key is necessary to trigger animation properly
            // https://github.com/software-mansion/react-native-reanimated/issues/2517#issuecomment-1136417915
            key={FlowSlide.Slide1}
            entering={SlideInRight}
            exiting={SlideOutLeft}
            style={rs.flex}
          >
            <View style={s.mainContainer}>
              <View style={s.cardContainer}>
                <Text color={colors.text} medium alignCenter>
                  {strings.sub_communities.join_description}
                </Text>

                <InputWithTitleAndValidation
                  value={code}
                  onChangeText={onChangeText}
                  onSubmitEditing={onSubmitEditing}
                  minLength={1}
                  placeholder={strings.login.type}
                  placeholderTextColor={colors.text}
                  style={s.inputContainer}
                  underlineColorAndroid="transparent"
                  autoCapitalize="characters"
                  autoComplete="off"
                  textAlign="center"
                  autoCorrect={false}
                  returnKeyType="done"
                  enablesReturnKeyAutomatically
                  autoFocus
                />
              </View>
            </View>

            <View style={s.buttonContainer}>
              <Button
                title={strings.common.join}
                onPress={onSubmitEditing}
                disabled={!code?.length}
              />
            </View>
          </Animated.View>
        ) : (
          <Animated.View
            // key is necessary to trigger animation properly
            // https://github.com/software-mansion/react-native-reanimated/issues/2517#issuecomment-1136417915
            key={FlowSlide.Slide2}
            entering={SlideInRight}
            exiting={SlideOutLeft}
            style={rs.flex}
          >
            <View style={s.mainContainer}>
              <View style={s.cardContainer}>
                <Text color={colors.text} medium alignCenter>
                  {requestStatus === JoinRequestStatus.Approved
                    ? strings.my_sub_communities.join_request_approved
                    : strings.my_sub_communities.join_request_pending}
                </Text>
              </View>
            </View>

            <View style={s.buttonSectionContainer}>
              <View style={[s.buttonContainer, rs.marginBottom]}>
                <Button
                  title={strings.sub_communities.join_another}
                  onPress={onJoinAnother}
                  secondaryType
                />
              </View>

              <View style={s.buttonContainer}>
                <Button title={strings.common.go_back} onPress={onFinish} />
              </View>
            </View>
          </Animated.View>
        )}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default JoinSubCommunityByCodeScreen;
