import { handleActions } from 'redux-actions';
import { mergeDeep } from '../../utils/stateHelper';
import * as types from './types';
import appTypes from '../app/types';

const initialState = {
  id: null,
  startDate: null,
  endDate: null,
  percentBack: null,
  userProgress: null,
  maxPointsBack: null,
  color: null,
};

const reducer = handleActions(
  {
    [types.SET_PROMOTION]: mergeDeep(({ payload }) => ({
      id: payload.id,
      startDate: payload.startDate,
      endDate: payload.endDate,
      percentBack: payload.percentBack,
      userProgress: payload.userProgress,
      maxPointsBack: payload.maxPointsBack,
      color: payload.color,
    })),
    [types.RESET_PROMOTION]: () => ({
      ...initialState,
    }),
    [appTypes.RESET_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState,
);

export default reducer;
