/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import FastImage from '../FastImage';
import styled from 'styled-components/native';
import { dimensions, colors } from '../../../styles';

const getSize = ({ small = false, verySmall = false, big = false }) => {
  let size = 42;
  if (big) {
    size = 60;
  }
  if (small) {
    size = 38;
  }
  if (verySmall) {
    size = 33;
  }

  return size;
};

const getBackgroundSize = (props) => {
  const size = getSize(props);
  return `width: ${size}; height: ${size}`;
};

const getImageSize = (props) => {
  const size = getSize(props);
  return `width: ${size}; height: ${size}`;
};
const getBorder = ({ withoutBorder }) => {
  return withoutBorder
    ? ''
    : `
    border-radius: 4px;
	`;
};

const AvatarBackgroundImage = styled.ImageBackground`
  ${(props) => getBackgroundSize(props)};
  ${(props) => getBorder(props)};
  ${({ withMargin }) => (withMargin ? `margin-horizontal: ${dimensions.medium}` : '')};
  align-items: center;
  justify-content: center;
`;

const ItemImage = styled(FastImage)`
  ${(props) => getImageSize(props)};
  border-radius: ${({ withoutBorder }) => (withoutBorder ? 0 : dimensions.borderRadius)};
`;

class ItemAvatar extends React.PureComponent {
  render() {
    const { uri, big, small = false, verySmall = false, withMargin, withoutBorder } = this.props;

    return (
      <AvatarBackgroundImage
        big={big}
        small={small}
        verySmall={verySmall}
        source={require('../../../assets/web/images/item_avatar_placeholder.png')}
        resizeMode="cover"
        withMargin={withMargin}
        withoutBorder={withoutBorder}
        imageStyle={{ borderRadius: withoutBorder ? 0 : 4 }}
      >
        {uri ? (
          <ItemImage
            big={big}
            small={small}
            verySmall={verySmall}
            source={uri}
            resizeMode="cover"
            cache="force-cache"
            withoutBorder={withoutBorder}
          />
        ) : null}
      </AvatarBackgroundImage>
    );
  }
}

export default ItemAvatar;
