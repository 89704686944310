/* eslint-disable react-native/no-inline-styles */
import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import styled from 'styled-components/native';
import * as R from 'ramda';
import { colors, dimensions, rs } from '../../../../../../../styles';
import { isRTL } from '../../../../../../../utils/rtlHelper';
import {
  FontIcon,
  Text,
  IconButton,
  SmallMap,
  TouchableItem,
  Image,
} from '../../../../../../ReusableComponents';
import strings from '../../../../../../../localization';
import { Container, Subtitle } from '../styles';
import { ModalsService, NavigationService } from '../../../../../../../services';
import modalTypes from '../../../../../../../constants/modalTypes';
import screens from '../../../../../../../navigation/screens';
import { BOXIT_SIGN_UP } from '../../../../../../../api/urls';
import zoneProperties from '../../../../../../../constants/zoneProperties';
import HorizontalCitiesList from './HorizontalCitiesList';
import currencyCodesMap from '../../../../../../../constants/currencyCodes';
import { isWeb } from '../../../../../../../utils/detectDevice';
import { getPriceCalculatorUrlByCommunityId } from '../../../../ShippingGroup/DomesticShippingCalculationScreen';
import { getCurrentTheme } from '../../../../../../../templates';

const LocationContainer = styled(Container)`
  padding-bottom: ${dimensions.medium};
`;

const ShippingContainer = styled.View`
  margin-top: ${dimensions.medium};
  margin-bottom: ${dimensions.halfMedium};
`;

const s = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingVertical: dimensions.medium,
  },
  shippingOption: {
    paddingHorizontal: dimensions.medium,
    marginTop: 10,
    flexDirection: 'row',
  },
  shippingText: {
    paddingTop: 2,
  },
  iconContainer: {
    minWidth: 32,
  },
  boxitImage: {
    height: 20,
    width: 20,
  },
  locationText: {
    textDecorationLine: 'underline',
  },
  mapButton: {
    padding: dimensions.medium,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    // alignItems: 'center',
  },
});

const getLocationsWithCount = (locations) => {
  if (!locations || R.isEmpty(locations)) {
    return [null, null];
  }

  const locationsToShow = locations.slice(0, 2);
  const otherLocationsCount = locations.slice(2).length;

  return [locationsToShow, otherLocationsCount];
};

const ShippingOptions = ({
  isWish,
  zoneName,
  isActiveShipping,
  shippingPrice,
  isActiveBoxit,
  isShippersDeliveryActive,
  locations,
  shipperLocations,
  mapLocations,
  isReadyMap,
  selectedLocation,
  setSelectedLocation,
  onPressShippingInfo,
  currencyCode,
  communityId,
}) => {
  const currencySymbol = currencyCodesMap[currencyCode];

  const [shipperLocationsForList, otherShipperLocationsCount] = getLocationsWithCount(
    shipperLocations,
  );

  const currentTheme = getCurrentTheme();

  const onCalculateShippingCostPress = () => {
    if (isWeb) {
      const uri = getPriceCalculatorUrlByCommunityId(communityId);
      window.open(uri, '_blank').focus();
    } else {
      NavigationService.navigate(screens.DomesticShippingCalculation);
    }
  };

  return (
    <View style={s.container}>
      {!!locations.length && (
        <View style={s.shippingOption}>
          <View style={s.iconContainer}>
            <FontIcon
              withoutRTLScale
              name="Shipping_personal-pickup"
              size={22}
              color={colors.secondary}
            />
          </View>
          <View>
            <Text style={rs.verySmallMarginBottom}>{strings.shipping.meet_in_person}</Text>

            <View style={[rs.row, s.shippingText]}>
              <Text color={colors.gray}>{strings.shipping.from} </Text>
              <HorizontalCitiesList
                items={locations}
                onPressItem={setSelectedLocation}
                selectedItem={selectedLocation}
              />
            </View>
          </View>
        </View>
      )}

      {isShippersDeliveryActive && !R.isEmpty(shipperLocations) && (
        <View style={s.shippingOption}>
          <View style={s.iconContainer}>
            <FontIcon withoutRTLScale name="scooter" size={22} color={colors.secondary} />
          </View>
          <View>
            <Text style={rs.verySmallMarginBottom}>{strings.shipping.shippers_delivery}</Text>

            <View style={[rs.row, s.shippingText]}>
              <Text color={colors.gray}>{strings.shipping.from} </Text>
              <HorizontalCitiesList
                items={shipperLocationsForList}
                onPressItem={setSelectedLocation}
                selectedItem={selectedLocation}
                ellipsizedItemsCount={otherShipperLocationsCount}
                withEllipsize
              />
            </View>
          </View>
        </View>
      )}

      {!!isActiveShipping && (
        <View style={s.shippingOption}>
          <View style={s.iconContainer}>
            <FontIcon
              withoutRTLScale
              name="Shipping_domestic-shipping"
              size={22}
              color={colors.secondary}
            />
          </View>
          <View>
            <Text tyle={s.shippingText}>{strings.shipping.domestic_shipping}</Text>

            <View style={[rs.row, rs.smallMarginTop]}>
              {zoneName === 'israel' ? (
                <Fragment>
                  {!isRTL && (
                    <Text>
                      {currencySymbol} {shippingPrice}
                    </Text>
                  )}
                  {isRTL && (
                    <Text>
                      {shippingPrice} {currencySymbol}
                    </Text>
                  )}
                </Fragment>
              ) : (
                <Text>
                  {currencyCode === 'BRL' ? 'CEP' : currencySymbol} {shippingPrice}
                </Text>
              )}
              <TouchableItem onPress={onCalculateShippingCostPress}>
                <Text color={currentTheme.colors.secondary} style={rs.verySmallPaddingHorizontal}>
                  {strings.lots.shipping_description}
                </Text>
              </TouchableItem>
            </View>
          </View>
        </View>
      )}

      {!!isActiveBoxit && (
        <View style={s.shippingOption}>
          <View style={s.iconContainer}>
            <Image source={{ uri: 'boxit_small_logo' }} style={s.boxitImage} resizeMode="contain" />
          </View>
          <Text color={colors.gray} semiBold>
            {strings.shipping.ship_with_boxit}
          </Text>

          <IconButton
            onPress={() =>
              ModalsService.showFullScreenModal(modalTypes.WEB_VIEW, {
                headerTitle: strings.shipping.boxit_video_header,
                url: BOXIT_SIGN_UP,
              })
            }
            withSmallPadding
            name="info"
            size={14}
            color={currentTheme.colors.secondary}
          />
        </View>
      )}

      {/*{!!locations.length && (*/}
      {/*  <Fragment>*/}
      {/*    <LocationRegionsContainer>*/}
      {/*      {locations.map((location, id) => (*/}
      {/*        <LocationItem key={id.toString()} onPress={() => setSelectedLocationIndex(id)}>*/}
      {/*          <FontIcon*/}
      {/*            name="pickup"*/}
      {/*            size={dimensions.iconSize}*/}
      {/*            color={id === selectedLocationIndex ? colors.blue : colors.text}*/}
      {/*            style={{ marginRight: dimensions.halfMedium }}*/}
      {/*          />*/}
      {/*          <Text*/}
      {/*            medium*/}
      {/*            semiBold*/}
      {/*            color={id === selectedLocationIndex ? colors.blue : colors.text}*/}
      {/*          >*/}
      {/*            {location.city}*/}
      {/*          </Text>*/}
      {/*        </LocationItem>*/}
      {/*      ))}*/}
      {/*    </LocationRegionsContainer>*/}
      {/*  </Fragment>*/}
      {/*)}*/}
      {!!mapLocations.length && isReadyMap && (
        <View style={[rs.paddingVertical]}>
          <SmallMap locations={mapLocations} selectedLocation={selectedLocation} />
          <View style={s.mapButton}>
            <TouchableItem onPress={onPressShippingInfo}>
              <Text color={currentTheme.colors.secondary}>{strings.shipping.see_full_map}</Text>
            </TouchableItem>
          </View>
        </View>
      )}
    </View>
  );
};

export default ShippingOptions;
