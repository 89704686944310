import {
  APP_STORE_ID,
  BUNDLE_ID,
  PACKAGE_NAME,
  FIREBASE_DYNAMICAL_LINK_PREFIX,
} from '../../config/identifiers';
import { leftToRightIsolate } from './stringHelper';
import { config } from '../../config/firebase';
import api from '../api/api';
import { ModalsService } from '../services';
import modalTypes from '../constants/modalTypes';

export const createDynamicLink = async ({
  actionType,
  id,
  communityId,
  socialImageUrl = '',
  socialTitle = '',
  socialDescription = '',
}) => {
  const link = await api.post('account/generateDynamicLink', {
    data: {
      dynamicLinkInfo: {
        link: `https://shareitt.com/?actionType=${actionType}&id=${id}&communityId=${communityId}`,
        domainUriPrefix: FIREBASE_DYNAMICAL_LINK_PREFIX,
        socialMetaTagInfo: {
          socialDescription: socialDescription,
          socialImageLink: socialImageUrl,
          socialTitle: socialTitle,
        },
        iosInfo: {
          iosBundleId: BUNDLE_ID,
          iosAppStoreId: APP_STORE_ID,
        },
        androidInfo: {
          androidPackageName: PACKAGE_NAME,
        },
        navigationInfo: {
          enableForcedRedirect: true,
        },
      },
    },
  });

  return link;
};

export const shareDynamicLink = async ({
  actionType,
  id,
  communityId,
  socialImageUrl = '',
  socialTitle = '',
  socialDescription = '',
  linkDescription = '',
  modalTitle = '',
}) => {
  const link = await createDynamicLink({
    actionType,
    id,
    communityId,
    socialImageUrl,
    socialTitle,
    socialDescription,
  });

  ModalsService.showModal(modalTypes.SHARE, {
    link,
    title: modalTitle,
  });
};

export const shareReferralDynamicLink = async ({
  actionType,
  referralCode,
  socialImageUrl = '',
  socialTitle = '',
  socialDescription = '',
  linkDescription = '',
  modalTitle = '',
}) => {
  const link = await api.post('account/generateDynamicLink', {
    data: {
      dynamicLinkInfo: {
        link: `https://shareitt.com/?actionType=${actionType}&referral_code=${referralCode}`,
        domainUriPrefix: FIREBASE_DYNAMICAL_LINK_PREFIX,
        socialMetaTagInfo: {
          socialDescription: socialDescription,
          socialImageLink: socialImageUrl,
          socialTitle: socialTitle,
        },
        iosInfo: {
          iosBundleId: BUNDLE_ID,
          iosAppStoreId: APP_STORE_ID,
        },
        androidInfo: {
          androidPackageName: PACKAGE_NAME,
        },
        navigationInfo: {
          enableForcedRedirect: true,
        },
      },
    },
  });

  ModalsService.showModal(modalTypes.SHARE, {
    link,
    title: modalTitle,
  });
};

export const shareExistingLink = async (link, description) => {
  ModalsService.showModal(modalTypes.SHARE, {
    link,
    title: description,
  });
};
