/* eslint-disable react/destructuring-assignment,react/no-access-state-in-setstate */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Alert, Keyboard, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { withConfigCatClient } from 'configcat-react';
import { lotsOperations } from '../../../../store/lots';
import { shippingOperations } from '../../../../store/shipping';
import { getCurrencyCode } from '../../../../store/communityInfo/selectors';
import NavigationService from '../../../../services/NavigationService';
import { filterBadWords } from '../../../../utils/stringHelper';
import {
  NavigationButton,
  BackButton,
  Button,
  ItemDetailsButton,
  Separator,
  TouchableItem,
  RoundButton,
} from '../../../ReusableComponents';
import {
  RulesHeader,
  ImagesSelector,
  TitleInput,
  DescriptionInput,
  WishInfo,
  PublicWishToggle,
  PriceInput,
  ShippingInfo,
} from './components';
import MultipleSupplyToggle from './components/MultipleSupplyToggle';
import s, { PostButtonContainer } from './styles';
import { headerStyle, dimensions, colors, rs } from '../../../../styles';
import { getParamOr } from '../../../../utils/navHelper';
import AnalyticsService from '../../../../services/AnalyticsService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { LoadingService, ModalsService, ToastsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import ruleTypes from '../../../../constants/ruleTypes';
import zoneProperties from '../../../../constants/zoneProperties';
import { groupsOperations, groupsSelectors } from '../../../../store/groups';
import { Box, TitleContainer } from './components/styles';
import Title from './components/Title';
import withSelectedGroupId from './SelectedCommunityContainer';
import Text from '../../../ReusableComponents/Text';
import { createLotOperations } from '../../../../store/createLot';
import { hasPrimaryLocation } from '../../../../store/userLocations/selectores';
import { getAppLanguage } from '../../../../store/app/selectors';
import { userLocationsOperations } from '../../../../store/userLocations';
import itemTypes from '../../../../constants/itemTypes';
import currencyCodesMap from '../../../../constants/currencyCodes';
import { isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';
import { subCommunityOperations, subCommunitySelectors } from '../../../../store/subCommunities';
import { formatCommunityName } from '../../../../utils/formatCommunityName';
import { SUB_COMMUNITIES_KEY } from '../../../../constants/configcat';

class EditItem extends Component {
  state = {
    itemId: '',
    title: '',
    description: '',
    price: null,
    original_price: null,
    brandId: null,
    images: ['', '', '', ''],
    imagesWeb: ['', '', '', ''],
    conditionId: null,
    sizeId: null,
    refundPolicyId: null,
    wish: null,
    itemLocations: [],
    shipping_price: null,
    isShippersDeliveryActive: false,
    isActiveDiscount: false,
    isPublic: true,
    has_multiple_supply: false,
    selectedCategory: {
      parentCategory: null,
      childCategory: null,
    },
    selectedBrand: null,
    selectedMembers: [],
    selectedSubCommunities: [],
    isShowSubCommunitiesValue: false,
  };

  async componentDidMount() {
    const {
      savedRefundPolicyId,
      navigation,
      route,
      savedLocations,
      savedShippingPrice,
      savedIsShippersDeliveryActive,
      setSelectedGroupId,
    } = this.props;

    this.setupFeatureFlag();

    navigation.setParams({ onSubmit: this.onSubmit });

    const wish = route.params?.wish ?? null;

    const item = route.params?.item ?? null;
    const images = route.params?.images ?? null;
    const itemLocations = route.params?.locations ?? [];

    const formattedItemLocations = itemLocations.map((el) => ({ ...el, isItemLocation: true }));

    if (item) {
      this.setPropsFromItem(item, images, formattedItemLocations);
      if (R.isEmpty(item) || R.isNil(item.id)) {
        this.setState({
          itemLocations: savedLocations,
          shipping_price: savedShippingPrice,
          isShippersDeliveryActive: savedIsShippersDeliveryActive,
          isPublic: true,
        });
      }
    } else {
      this.setState({
        itemLocations: savedLocations,
        shipping_price: savedShippingPrice,
        isShippersDeliveryActive: savedIsShippersDeliveryActive,
        refundPolicyId: savedRefundPolicyId,
      });
    }

    if (wish) {
      this.setState({
        wish,
        isPublic: R.propOr(false, 'is_public', item),
      });
      setTimeout(() => {
        setSelectedGroupId(wish.group_id);
      }, 0);
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const newState = this.state;
    const newProps = this.props;

    // if (!R.equals(oldProps.groupInfo.id, newProps.groupInfo.id)) {
    //   this.onItemRulesApproveCheck();
    // }

    // todo find better solution to identify initial props setting
    if (
      !oldState.title &&
      !oldState.original_price &&
      !!newState.price &&
      !!newState.original_price
    ) {
      return;
    }

    if (oldProps?.lastUpdated !== this.props.lastUpdated) {
      this.setupFeatureFlag();
    }

    if (
      !R.equals(oldState.title, newState.title) ||
      !R.equals(oldState.original_price, newState.original_price) ||
      !R.equals(oldState.isShippersDeliveryActive, newState.isShippersDeliveryActive) ||
      !R.equals(oldState.price, newState.price) ||
      !R.equals(oldState.description, newState.description) ||
      !R.equals(oldState.conditionId, newState.conditionId) ||
      !R.equals(oldState.sizeId, newState.sizeId) ||
      !R.equals(oldState.selectedCategory, newState.selectedCategory) ||
      !R.equals(oldState.selectedBrand, newState.selectedBrand) ||
      !R.equals(oldState.has_multiple_supply, newState.has_multiple_supply) ||
      !R.equals(oldState.refundPolicyId, newState.refundPolicyId)
    ) {
      this.checkValidation();
    }
  }

  setupFeatureFlag() {
    const { getUserSubCommunities } = this.props;

    const IS_SHOW_SUB_COMMUNITIES_KEY = formatCommunityName(
      SUB_COMMUNITIES_KEY,
      this.props.communityInfo.name,
    );

    this.props.getValue(IS_SHOW_SUB_COMMUNITIES_KEY, false).then((isShowSubCommunitiesValue) => {
      this.setState({ isShowSubCommunitiesValue });

      if (isShowSubCommunitiesValue) {
        getUserSubCommunities();
      }
    });
  }

  setPropsFromItem = async (item, images, itemLocations) => {
    const { setSelectedGroupId } = this.props;

    this.setState({
      title: item.title,
      description: item.description,
      itemId: R.propOr('', 'id', item),
      price: item.price ? item.price.toString() : null,
      original_price: item.original_price.toString(),
      isShippersDeliveryActive: R.propOr(false, 'is_shippers_delivery_active', item),

      shipping_price: item.shipping_price ? item.shipping_price.toString() : '',
      brandId: R.propOr(null, 'brand_id', item),
      conditionId: R.propOr(null, 'item_condition', item),
      sizeId: R.propOr(null, 'size_id', item),
      refundPolicyId: R.propOr(null, 'refund_policy_id', item),
      isActiveDiscount: !!item.id && !!item.discounted_price, // for repost item
      itemLocations,
      isPublic: R.propOr(null, 'is_public', item),
      has_multiple_supply: R.propOr(false, 'has_multiple_supply', item),
      selectedCategory: {
        parentCategory: {
          id: R.propOr(null, 'parent_category_id', item),
          name: R.propOr(null, 'parent_category_name', item),
        },
        childCategory: {
          id: R.propOr(null, 'category_id', item),
          name: R.propOr(null, 'category_name', item),
        },
      },
      selectedBrand: {
        id: item.brand_id,
        name: item.brand_name,
      },
    });

    setTimeout(() => {
      setSelectedGroupId(item.group_id);
    }, 0);

    if (images) {
      const imgs = this.state.images;
      images.forEach((el, id) => {
        imgs[id] = el;
      });
      imgs.length = 4;

      this.setState({ images: imgs, imagesWeb: imgs });
    }
  };

  onOpenMarketplace = (groupId, isPendingReview) => {
    const { setFeedLotFilters } = this.props;
    setFeedLotFilters({
      groupIds: [groupId],
      selectedCategoryIds: [],
      selectedSizes: [],
      selectedBrands: [],
      selectedConditions: [],
      searchTerm: null,
      isSearchByFollowings: false,
      isSearchByDiscount: false,
      sortBy: 'by_newest',
    });
    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, { screen: screens.Marketplace });
      ToastsService.showSuccess(
        isPendingReview
          ? strings.success_messages.item_uploaded_successfully_and_waiting_for_review
          : strings.success_messages.item_uploaded_successfully,
      );
    }, 0);
  };

  onSubmit = async () => {
    const {
      navigation,
      route,
      saveShippingForNextLot,
      saveGroupForNextLot,
      saveRefundPolicyForNextLot,
      editItem,
      createNewItem,
      selectedGroupId,
      setInsideCommunityId,
      insideCommunityId,
      getItemDetails,
      hasLocation,
      getUserLocations,
    } = this.props;

    const {
      title,
      description,
      price,
      images,
      imagesWeb,
      conditionId,
      refundPolicyId,
      original_price,
      itemId,
      itemLocations,
      shipping_price,
      isShippersDeliveryActive,
      sizeId,
      wish,
      isPublic,
      has_multiple_supply,
      selectedCategory,
      selectedBrand,
      selectedMembers,
      selectedSubCommunities,
    } = this.state;

    if (price > original_price) {
      Alert.alert(
        strings.discounts.alert_price_higher_than_original_title,
        strings.discounts.alert_price_higher_than_original_text,
        [{ text: strings.common.ok, onPress: () => {} }],
      );

      return;
    }

    if (route.params?.isValidate ?? false) {
      navigation.setParams({ isValidate: false }); // disable create same items
    } else {
      return;
    }

    Keyboard.dismiss();

    saveShippingForNextLot(itemLocations, shipping_price, !!isShippersDeliveryActive);
    saveGroupForNextLot(selectedGroupId);
    saveRefundPolicyForNextLot(refundPolicyId);

    const submitItem = itemId ? editItem : createNewItem;

    LoadingService.showSuccessLoader();

    const createdItem = await submitItem({
      title,
      description,
      price,
      images: isWeb ? imagesWeb : images,
      brandId: R.propOr(null, ['id'], selectedBrand),
      sizeId,
      locations: itemLocations.map((location) =>
        location.id && !location.isItemLocation ? { user_location_id: location.id } : location,
      ),
      shipping_price,
      is_shippers_delivery_active: isShippersDeliveryActive,
      category_id: R.pathOr(null, ['childCategory', 'id'], selectedCategory),
      item_condition: conditionId,
      refundPolicyId,
      original_price,
      itemId, // if edit exist item
      wishId: R.propOr(null, 'id', wish), // if create new item
      isPublic, // if create new item
      group_id: selectedGroupId,
      has_multiple_supply,
      tagged_user_ids: selectedMembers.map(({ id }) => id),
      sub_community_ids: selectedSubCommunities.map(({ id }) => id),
    });

    LoadingService.hideSuccessLoader({
      callback: () => {
        // NavigationService.goBack();

        if (!createdItem) {
          ToastsService.showError(strings.error_messages.error);
          return;
        }

        if (!hasLocation && !R.isEmpty(itemLocations)) {
          getUserLocations();
        }

        if (itemId) {
          getItemDetails(itemId);
          NavigationService.goBack();
        } else {
          this.onOpenMarketplace(selectedGroupId, createdItem.status === itemTypes.PENDING);
        }
      },
    });

    AnalyticsService.logEvent('Marketplace_Add_New_Item');
  };

  checkValidation = () => {
    const { navigation, groupInfo } = this.props;
    const {
      title,
      original_price,
      price,
      description,
      images,
      conditionId,
      itemLocations,
      shipping_price,
      isShippersDeliveryActive,
      selectedCategory,
      refundPolicyId,
    } = this.state;

    navigation.setParams({
      isValidate:
        title.length > 0 &&
        original_price > 0 &&
        price > 0 &&
        description.length > 0 &&
        images.some((el) => !!el) &&
        !R.isNil(conditionId) &&
        (groupInfo.has_refund_policy ? !R.isNil(refundPolicyId) : true) &&
        !!R.path(['parentCategory', 'id'], selectedCategory) &&
        (!!itemLocations.length || shipping_price || isShippersDeliveryActive),
    });
  };

  onInputChangesHandler = (value, stateName) => {
    const filteredValue = stateName === 'price' ? value.replace(/\D/g, '') : value;

    this.setState((state, props) => ({
      [stateName]: props.appLanguage === 'en' ? filterBadWords(filteredValue) : filteredValue,
    }));
  };

  onChangeOriginalPrice = (value) => {
    const { conditionId } = this.state;
    const { groupInfo } = this.props;
    const filteredValue = value.replace(/\D/g, '');

    this.setState({
      original_price: filteredValue,
    });

    if (!R.isNil(conditionId)) {
      const priceCoefficient = groupInfo.conditions.find((el) => el.id === conditionId)
        .price_coefficient;

      this.setState({
        price: parseInt(Number(filteredValue) * priceCoefficient).toString(),
      });
    }
  };

  onNavigateToShipping = () => {
    const { selectedGroupId } = this.props;
    const { itemLocations, shipping_price, isShippersDeliveryActive } = this.state;
    NavigationService.navigate(screens.ItemShipping, {
      communityId: selectedGroupId,
      shipping_price,
      isShippersDeliveryActive,
      itemLocations: itemLocations || [],
      onSetShipping: this.onSetShipping,
    });
  };

  onSetShipping = (shipping) => {
    const { locations, shipping_price, isShippersDeliveryActive } = shipping;
    this.setState({ itemLocations: locations, shipping_price, isShippersDeliveryActive });
    setTimeout(() => this.checkValidation(), 0);
  };

  setImage = (images) => {
    this.setState({ images });
    this.checkValidation();
  };

  setWebImages = (imagesWeb) => {
    this.setState({ imagesWeb });
  };

  onFocusPriceInput = () => {
    const { isActiveDiscount } = this.state;
    if (isActiveDiscount) {
      if (isWeb) {
        ModalsService.showModal(modalTypes.ALERT, {
          title: strings.discounts.alert_edit_price_title,
          description: strings.discounts.alert_edit_price_text,
          cancelButtonTitle: strings.common.ok,
        });
      } else {
        Alert.alert(
          strings.discounts.alert_edit_price_title,
          strings.discounts.alert_edit_price_text,
          [{ text: strings.common.ok, onPress: () => {} }],
        );
      }

      this.setState({ isActiveDiscount: false }); // show warning modal only once
    }
  };

  onPublicToggle = (isPublic) => {
    this.setState({ isPublic });
  };

  onMultipleSupplyToggle = (has_multiple_supply) => {
    this.setState({ has_multiple_supply });
  };

  onOpenRules = () => {
    ModalsService.showFullScreenModal(modalTypes.RULES, {
      type: ruleTypes.ITEM_RULES,
    });
  };

  // onItemRulesApproveCheck = () => {
  //   const { groupInfo } = this.props;
  //   if (!groupInfo.is_approved_item_rules) {
  //     ModalsService.showFullScreenModal(modalTypes.RULES, {
  //       type: ruleTypes.ITEM_RULES,
  //       communityId: groupInfo.group_id,
  //     });
  //   }
  // };

  onSelectCategory = (selectedCategory) => {
    this.setState({
      selectedCategory,
    });
  };

  onOpenCategories = () => {
    const { groupInfo } = this.props;
    const { selectedCategory } = this.state;

    NavigationService.navigate(screens.Categories, {
      selectedCategoryId: R.path(['childCategory', 'id'], selectedCategory),
      categories: groupInfo.categories,
      onSelectCategory: this.onSelectCategory,
    });
  };

  onSelectBrand = (selectedBrand) => {
    this.setState({
      selectedBrand,
    });
  };

  onOpenBrands = () => {
    const { groupInfo } = this.props;
    const { selectedBrand } = this.state;

    NavigationService.navigate(screens.Brands, {
      onSelectBrand: this.onSelectBrand,
      brands: groupInfo.brands,
      selectedBrand,
    });
  };

  onSelectCondition = (conditionId) => {
    const { original_price } = this.state;
    const { groupInfo } = this.props;
    const priceCoefficient = groupInfo.conditions.find((el) => el.id === conditionId)
      .price_coefficient;

    this.setState({ conditionId });

    if (original_price && priceCoefficient) {
      this.setState({
        price: parseInt(Number(original_price) * priceCoefficient).toString(),
        conditionId,
      });
    }
  };

  onOpenConditions = () => {
    const { groupInfo } = this.props;
    const { conditionId } = this.state;

    NavigationService.navigate(screens.Conditions, {
      selectedConditionId: conditionId,
      conditions: groupInfo.conditions,
      onSelectCondition: this.onSelectCondition,
    });
  };

  onSelectSize = (sizeId) => {
    this.setState({ sizeId });
  };

  onOpenSizes = () => {
    const { groupInfo } = this.props;
    const { sizeId } = this.state;

    NavigationService.navigate(screens.Sizes, {
      selectedSizeId: sizeId,
      sizes: groupInfo.sizes,
      onSelectSize: this.onSelectSize,
    });
  };

  onSelectRefundPolicy = (refundPolicyId) => {
    this.setState({ refundPolicyId });
  };

  onOpenRefunds = () => {
    const { refundPolicies } = this.props;
    const { refundPolicyId } = this.state;

    NavigationService.navigate(screens.RefundPolicies, {
      selectedRefundPolicyId: refundPolicyId,
      refundPolicies,
      onSelectRefundPolicy: this.onSelectRefundPolicy,
    });
  };

  onSelectMembers = (members) => {
    this.setState({ selectedMembers: members });
    NavigationService.goBack();
  };

  onOpenMembers = () => {
    const { selectedMembers } = this.state;

    NavigationService.navigate(screens.IssueMemberList, {
      selectedMembers,
      onSubmit: this.onSelectMembers,
    });
  };

  onSelectSubCommunities = (subCommunities) => {
    this.setState({ selectedSubCommunities: subCommunities });
    NavigationService.goBack();
  };

  onOpenSubCommunityTags = () => {
    const { selectedSubCommunities } = this.state;

    NavigationService.navigate(screens.MultipleSubCommunitySelect, {
      selectedSubCommunities,
      onSubmit: this.onSelectSubCommunities,
    });
  };

  onSelectGroup = (groupId) => {
    const { setSelectedGroupId } = this.props;
    const { price, brandId, conditionId, sizeId, selectedCategory, selectedBrand } = this.state;

    if (
      !!price ||
      !!brandId ||
      !!conditionId ||
      !!sizeId ||
      !!selectedCategory.parentCategory ||
      !!selectedBrand
    ) {
      if (isWeb) {
        ModalsService.showModal(modalTypes.ALERT, {
          title: strings.discounts.change_community_description,
          description: strings.lots.change_community_description,
          confirmButtonTitle: strings.common.ok,
          cancelButtonTitle: strings.common.cancel,
          onSubmit: () => {
            this.setState({
              price: null,
              brandId: null,
              conditionId: null,
              sizeId: null,
              selectedCategory: {
                parentCategory: null,
                childCategory: null,
              },
              selectedBrand: null,
            });
            setSelectedGroupId(groupId);

            NavigationService.pop();
          },
        });
      } else {
        Alert.alert(
          strings.discounts.change_community_description,
          strings.lots.change_community_description,
          [
            {
              text: strings.common.ok,
              onPress: () => {
                this.setState({
                  price: null,
                  brandId: null,
                  conditionId: null,
                  sizeId: null,
                  selectedCategory: {
                    parentCategory: null,
                    childCategory: null,
                  },
                  selectedBrand: null,
                });
                setSelectedGroupId(groupId);

                NavigationService.pop();
              },
            },
            {
              text: strings.common.cancel,
              onPress: () => console.log('Cancel Pressed'),
              style: 'cancel',
            },
          ],
        );
      }
    } else {
      setSelectedGroupId(groupId);
      NavigationService.pop();
    }
  };

  onOpenGroups = () => {
    const { groupInfo, groups } = this.props;

    NavigationService.navigate(screens.Groups, {
      selectedGroupId: groupInfo.id,
      groups: groups,
      onSelectGroup: this.onSelectGroup,
    });
  };

  render() {
    const {
      itemId,
      images,
      imagesWeb,
      title,
      description,
      original_price,
      price,
      itemLocations,
      shipping_price,
      isShippersDeliveryActive,
      conditionId,
      sizeId,
      wish,
      isPublic,
      has_multiple_supply,
      selectedCategory,
      selectedBrand,
      selectedMembers,
      refundPolicyId,
      selectedSubCommunities,
      isShowSubCommunitiesValue,
    } = this.state;
    const {
      groupInfo,
      route,
      tokenName,
      user,
      refundPolicies,
      currencyCode,
      canTagSubCommunityToItems,
    } = this.props;

    const selectedConditionTitle = R.isNil(conditionId)
      ? ''
      : R.propOr('', 'title', R.find(R.propEq('id', conditionId))(groupInfo.conditions));

    const selectedSizeTitle = sizeId
      ? R.propOr('', 'title', R.find(R.propEq('id', sizeId))(groupInfo.sizes))
      : '';

    const selectedRefundPolicyTitle = refundPolicyId
      ? R.propOr('', 'title', R.find(R.propEq('id', refundPolicyId))(refundPolicies))
      : '';

    const selectedSubCommunitiesValue = R.isEmpty(selectedSubCommunities)
      ? ''
      : selectedSubCommunities.length === 1
      ? selectedSubCommunities[0].name
      : strings.formatString(
          strings.lots.sub_community_multiple_tags,
          selectedSubCommunities[0].name,
          selectedSubCommunities.length - 1,
        );

    let selectedMembersValue = '';

    if (!R.isEmpty(selectedMembers)) {
      selectedMembersValue =
        selectedMembers.length === 1
          ? `@${selectedMembers[0].name}`
          : `${selectedMembers.length} ${strings.lots.members_selected}`;
    }

    const originalPriceTitle = strings.formatString(
      strings.lots.original_price_nis,
      currencyCodesMap[currencyCode] ?? currencyCodesMap.USD,
    );

    const currentTheme = getCurrentTheme();

    return (
      <KeyboardAwareScrollView
        extraScrollHeight={dimensions.medium}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="handled"
        style={{ backgroundColor: colors.grayLighter }}
      >
        <View>
          {/*<RulesHeader*/}
          {/*  isItem*/}
          {/*  communityId={selectedGroupId}*/}
          {/*  communityName={R.propOr('', 'group_name', groupInfo)}*/}
          {/*/>*/}

          <TouchableItem onPress={this.onOpenRules} style={s.rulesContainer}>
            <Text color={currentTheme.colors.secondary}>{strings.rules.item_rules_title}</Text>
          </TouchableItem>

          {isShowSubCommunitiesValue && canTagSubCommunityToItems && !itemId && (
            <Fragment>
              <Title withPadding>{strings.lots.sub_community_tags_items}</Title>
              <ItemDetailsButton
                placeholder={strings.lots.sub_community_tags_placeholder_items}
                selectedValue={selectedSubCommunitiesValue}
                onPress={this.onOpenSubCommunityTags}
              />
            </Fragment>
          )}

          <WishInfo wish={wish} />

          <ImagesSelector
            images={images}
            imagesWeb={imagesWeb}
            setImage={this.setImage}
            setWebImages={this.setWebImages}
          />

          <Title withPadding>{strings.lots.description}</Title>

          <TitleInput
            value={title}
            placeholder={strings.lots.what_are_you_selling}
            onChangeText={(name) => this.onInputChangesHandler(name, 'title')}
          />
          <View style={rs.paddingHorizontal}>
            <Separator />
          </View>

          <DescriptionInput
            value={description}
            placeholder={strings.lots.describe_your_item}
            onChangeText={(name) => this.onInputChangesHandler(name, 'description')}
          />

          <Title withPadding>{strings.lots.details}</Title>

          {!itemId && !wish && (
            <Fragment>
              <ItemDetailsButton
                title={strings.lots.group}
                placeholder={strings.filters.select_group}
                selectedValue={groupInfo.group_name}
                onPress={this.onOpenGroups}
              />
              <View style={rs.paddingHorizontal}>
                <Separator />
              </View>
            </Fragment>
          )}

          <ItemDetailsButton
            title={strings.lots.category}
            placeholder={strings.filters.select_category}
            selectedValue={
              selectedCategory.parentCategory
                ? `${selectedCategory.parentCategory.name} : ${selectedCategory.childCategory.name}`
                : ''
            }
            onPress={this.onOpenCategories}
          />

          <View style={rs.paddingHorizontal}>
            <Separator />
          </View>

          <ItemDetailsButton
            title={strings.lots.condition}
            placeholder={strings.lots.select_condition}
            selectedValue={selectedConditionTitle}
            onPress={this.onOpenConditions}
          />

          {groupInfo.has_brands && (
            <Fragment>
              <View style={rs.paddingHorizontal}>
                <Separator />
              </View>

              <ItemDetailsButton
                title={strings.lots.brand}
                placeholder={strings.lots.select_brand}
                selectedValue={R.prop('name', selectedBrand)}
                onPress={this.onOpenBrands}
              />
            </Fragment>
          )}

          {groupInfo.has_sizes && (
            <Fragment>
              <View style={rs.paddingHorizontal}>
                <Separator />
              </View>

              <ItemDetailsButton
                title={strings.lots.size}
                placeholder={strings.lots.select_size}
                selectedValue={selectedSizeTitle}
                onPress={this.onOpenSizes}
              />
            </Fragment>
          )}

          {groupInfo.has_tagging_on_items &&
            !!R.prop('is_approved_to_upload_items', user) &&
            !itemId && (
              <Fragment>
                <View style={rs.paddingHorizontal}>
                  <Separator />
                </View>

                <ItemDetailsButton
                  title={strings.lots.tag_members}
                  placeholder={strings.lots.select_members}
                  selectedValue={selectedMembersValue}
                  onPress={this.onOpenMembers}
                />
              </Fragment>
            )}
          {groupInfo.has_refund_policy && (
            <Fragment>
              <Title withPadding>{strings.lots.refund_policy}</Title>

              <ItemDetailsButton
                title={selectedRefundPolicyTitle ? '' : strings.lots.select_refund_policy}
                placeholder={''}
                selectedValue={selectedRefundPolicyTitle}
                onPress={this.onOpenRefunds}
              />
            </Fragment>
          )}

          <Title withPadding>{strings.lots.shipping}</Title>

          <ItemDetailsButton
            title={strings.lots.select_shipping_option}
            placeholder={''}
            onPress={this.onNavigateToShipping}
          />

          <ShippingInfo
            locations={itemLocations}
            isActiveShipping={!!shipping_price}
            isShippersDeliveryActive={isShippersDeliveryActive}
          />

          <Title withPadding>{strings.lots.pricing}</Title>

          <PriceInput
            title={originalPriceTitle}
            subtitle={strings.lots.estimated_item_cost_as_new}
            value={original_price}
            onFocus={this.onFocusPriceInput}
            onChangeText={(value) => this.onChangeOriginalPrice(value)}
            style={s.inputOriginalPrice}
          />

          <View style={rs.paddingHorizontal}>
            <Separator />
          </View>

          <PriceInput
            title={
              this.props.communityName === 'shareitt' ? strings.items.price_in : 'Price in Hearts'
            }
            subtitle={strings.items.price_placeholder}
            onChangeText={(value) => this.onInputChangesHandler(value, 'price')}
            value={price}
            onFocus={this.onFocusPriceInput}
          />

          <View style={rs.paddingVertical} />

          {/*{wish && (*/}
          {/*  <PublicWishToggle*/}
          {/*    isPublic={isPublic}*/}
          {/*    onPublicToggle={this.onPublicToggle}*/}
          {/*    wisherName={wish.wisher_name}*/}
          {/*    groupName={groupInfo.group_name}*/}
          {/*  />*/}
          {/*)}*/}

          {!wish && groupInfo.has_seller_item_repost && (
            <MultipleSupplyToggle
              hasMultipleSupply={has_multiple_supply}
              onMultipleSupplyToggle={this.onMultipleSupplyToggle}
            />
          )}

          <PostButtonContainer style={isWeb && rs.marginBottom}>
            <Button
              disabled={!route.params?.isValidate ?? false}
              onPress={this.onSubmit}
              title={
                route.params?.item ?? false ? strings.common.save : strings.lots.create_lot_button
              }
            />
          </PostButtonContainer>
        </View>
      </KeyboardAwareScrollView>
    );
  }
}

const EditItemWithSelectedCommunityId = withSelectedGroupId(
  connect(
    (state, { selectedGroupId }) => ({
      user: state.userInfo,
      groupInfo: groupsSelectors.getGroupInfoById(state, selectedGroupId),
      refundPolicies: state.communityInfo.community_refund_policies,
      tokenName: state.communityInfo.token_name,
      communityInfo: state.communityInfo,
      groups: groupsSelectors.getCommunityGroups(state),
      isLoadingManagementLot: state.lots.isLoadingManagementLot,
      savedRefundPolicyId: state.createLot.refundPolicyId,
      savedLocations: state.shipping.locations,
      savedShippingPrice: state.shipping.shippingPrice,
      savedIsShippersDeliveryActive: state.shipping.isShippersDeliveryActive,
      hasLocation: hasPrimaryLocation(state),
      currencyCode: getCurrencyCode(state),
      appLanguage: getAppLanguage(state),
      communityName: state.communityTheme.theme,
      canTagSubCommunityToItems: subCommunitySelectors.getCanTagSubCommunityToItems(state),
    }),
    (dispatch) => ({
      editItem: (itemObj) => dispatch(lotsOperations.editItem(itemObj)),
      createNewItem: (itemObj) => dispatch(lotsOperations.createNewItem(itemObj)),
      getItemDetails: (itemId) => dispatch(lotsOperations.getItemDetails(itemId)),
      getUserSubCommunities: () => dispatch(subCommunityOperations.getUserSubCommunities()),
      setInsideCommunityId: (groupId) => dispatch(groupsOperations.setInsideCommunityId(groupId)),
      saveGroupForNextLot: (groupId) => dispatch(createLotOperations.saveGroupForNextLot(groupId)),
      saveRefundPolicyForNextLot: (refundPolicyId) =>
        dispatch(createLotOperations.saveRefundPolicyForNextLot(refundPolicyId)),
      saveShippingForNextLot: (locations, shippingPrice, isShippersDeliveryActive) =>
        dispatch(
          shippingOperations.saveShippingForNextLot(
            locations,
            shippingPrice,
            isShippersDeliveryActive,
          ),
        ),
      setFeedLotFilters: (feedLotFilters) =>
        dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),
      getUserLocations: () => dispatch(userLocationsOperations.getUserLocations()),
    }),
  )(EditItem),
);

export default withConfigCatClient(EditItemWithSelectedCommunityId);
