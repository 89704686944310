import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import React, { Fragment, memo } from 'react';
import { colors, dimensions, rs } from '../../styles';
import TouchableItem from './TouchableItem/index.android';
import Text from './Text';
import ResizableTokenIcon from './ResizableTokenIcon';
import { isRTL } from '../../utils/rtlHelper';
import { getCurrentTheme } from '../../templates';

const LOGO_SIZE = 56;

const s = StyleSheet.create({
  itemContentContainer: {
    paddingHorizontal: dimensions.halfMedium,
    paddingVertical: dimensions.halfMedium / 2,
  },
  itemContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderWidth: dimensions.hairline,
    borderColor: colors.grayLighter,
    padding: dimensions.medium,
    alignItems: 'center',
    borderRadius: 8,
  },
  balanceText: {
    fontSize: 26,
  },
  mainContainer: {
    marginLeft: dimensions.medium,
  },
});

const WalletItem = memo(({ wallet, onOpenTransactions }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View style={s.itemContentContainer}>
      <TouchableItem
        useOpacity={false}
        style={[s.itemContainer, rs.shadow]}
        onPress={onOpenTransactions}
      >
        <Fragment>
          <Fragment>
            <ResizableTokenIcon url={wallet.token_icon} size={LOGO_SIZE} />

            <View style={s.mainContainer}>
              <Text semiBold large color={colors.text}>
                {wallet.group_name}
              </Text>
              <Text medium color={currentTheme.colors.secondaryLight}>
                {isRTL && `${wallet.token_name}  `}
                <Text bold color={currentTheme.colors.secondaryLight} style={s.balanceText}>
                  {wallet.available_balance}
                </Text>
                {!isRTL && ` ${wallet.token_name}`}
              </Text>
            </View>
          </Fragment>
        </Fragment>
      </TouchableItem>
    </View>
  );
});

WalletItem.propTypes = {
  wallet: T.object,
  onOpenTransactions: T.func,
};

export default WalletItem;
