import React, { useState, useEffect, memo, useMemo, Fragment } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Animated, { FadeIn, Layout } from 'react-native-reanimated';
import { isEmpty } from 'ramda';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { subCommunityOperations, subCommunitySelectors } from '../../../../store/subCommunities';
import { LoadingService, NavigationService, ToastsService } from '../../../../services';
import { communityOperations } from '../../../../store/communityInfo';
import { getCommunityId } from '../../../../store/communityInfo/selectors';
import { Button, Separator, Text, Icon, SafeAreaContainer } from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import { getCurrentTheme } from '../../../../templates';

const s = StyleSheet.create({
  mainContainer: {
    flexGrow: 1,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: dimensions.small,
    paddingVertical: dimensions.medium,
  },
  itemMainDataContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemImage: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.grayBackground,
    marginRight: dimensions.halfMedium,
  },
  checkmarkContainer: {
    width: 20,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pressableText: {
    width: '100%',
    textAlign: 'center',
    textDecorationLine: 'underline',
    marginBottom: dimensions.medium,
  },
});

const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity);

const Item = memo(({ title, imageUrl, isSelected, onPress }) => {
  const currentTheme = getCurrentTheme();
  return (
    <AnimatedTouchableOpacity
      activeOpacity={0.5}
      onPress={onPress}
      style={s.itemContainer}
      entering={FadeIn}
    >
      <Fragment>
        <View style={s.itemMainDataContainer}>
          <Image source={{ uri: imageUrl }} resizeMode="cover" style={s.itemImage} />
          <Text color={isSelected ? currentTheme.colors.secondary : colors.text} medium semiBold>
            {title}
          </Text>
        </View>

        {isSelected && (
          <View style={[s.checkmarkContainer, { backgroundColor: currentTheme.colors.secondary }]}>
            <Icon name="check" size={14} color={colors.white} withoutRTLScale />
          </View>
        )}
      </Fragment>
    </AnimatedTouchableOpacity>
  );
});

const SubCommunitiesToJoinScreen = ({ navigation, route }) => {
  const dispatch = useDispatch();
  const currentCommunityId = useSelector(getCommunityId);

  const [isLoading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const isAuthorizationFlow = route.params?.isAuthorizationFlow ?? false;
  const communityId = route.params?.communityId ?? currentCommunityId;
  const isOnboarding = route.params?.isOnboarding;

  const currentTheme = getCurrentTheme();

  const data = useSelector(subCommunitySelectors.getSubCommunityGroupsAvailableToJoin);

  const submitButtonTitle = useMemo(() => {
    return isAuthorizationFlow
      ? isEmpty(selectedIds)
        ? strings.common.skip
        : strings.common.next
      : strings.common.done;
  }, [isAuthorizationFlow, selectedIds]);

  const onSkip = () => {
    NavigationService.reset(screens.AuthorizedApplication, {
      screen: screens.TabNavigator,
    });
  };

  useEffect(() => {
    if (isOnboarding) {
      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity onPress={onSkip}>
            <Text medium style={[{ color: currentTheme.colors.secondary }, rs.paddingRight]}>
              {strings.common.skip}
            </Text>
          </TouchableOpacity>
        ),
        headerLeft: () => <View />,
      });
    }
  }, [navigation, isOnboarding]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    await dispatch(subCommunityOperations.getSubCommunitiesToJoin(communityId));
    setLoading(false);
  };

  const onSubmit = async () => {
    LoadingService.showLoader();

    try {
      LoadingService.hideLoader();

      if (!isEmpty(selectedIds)) {
        await dispatch(subCommunityOperations.joinPublicSubCommunities(selectedIds));
      }

      if (isAuthorizationFlow) {
        await dispatch(communityOperations.setIsOnboarded());
      } else {
        await dispatch(subCommunityOperations.getUserSubCommunities());
        NavigationService.goBack();
      }
    } catch (error) {
      ToastsService.showError(strings.error_messages.error);
      LoadingService.hideLoader();
    }
  };

  const onListItemPress = (itemId) => {
    const isSelected = selectedIds.includes(itemId);

    setSelectedIds((prevState) =>
      isSelected ? prevState.filter((id) => id !== itemId) : [...prevState, itemId],
    );
  };

  const onJoinByCodePress = () => {
    NavigationService.navigate(screens.JoinSubCommunityByCode);
  };

  return (
    <SafeAreaContainer>
      <Animated.FlatList
        data={data}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => {
          const isSelected = selectedIds.includes(item.id);
          const onPress = () => {
            onListItemPress(item.id);
          };

          return (
            <Item
              title={item.name}
              imageUrl={item.icon}
              onPress={onPress}
              isSelected={isSelected}
            />
          );
        }}
        ItemSeparatorComponent={() => <Separator />}
        ListEmptyComponent={() =>
          !isLoading && (
            <View style={[rs.flexGrow, rs.alignCenter, rs.justifyCenter]}>
              <Text large semiBold>
                {strings.sub_communities.empty_list_to_join}
              </Text>
            </View>
          )
        }
        refreshing={isLoading}
        onRefresh={fetchData}
        contentContainerStyle={s.mainContainer}
        itemLayoutAnimation={Layout.springify()}
      />

      <View style={rs.flexShrink}>
        <Text onPress={onJoinByCodePress} style={s.pressableText} color={colors.blueLight} semiBold>
          {strings.sub_communities.have_code}
        </Text>

        <View style={s.buttonContainer}>
          <Button
            title={submitButtonTitle}
            onPress={onSubmit}
            secondaryType={isEmpty(selectedIds)}
          />
        </View>
      </View>
    </SafeAreaContainer>
  );
};

export default SubCommunitiesToJoinScreen;
