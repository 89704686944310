import React, { memo } from 'react';
import styled from 'styled-components/native';
import T from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { OptionItem, View } from '../../../ReusableComponents';
import strings from '../../../../localization';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import { communitySelectors } from '../../../../store/communityInfo';
import { useFeatureFlag } from 'configcat-react';
import { SUB_COMMUNITIES_KEY } from '../../../../constants/configcat';
import { formatCommunityName } from '../../../../utils/formatCommunityName';

const OptionsList = memo(
  ({
    buyingOffersCount,
    sellingOffersCount,
    wishFulfillmentOffersCount,
    communityInfo,
    isCommunityAdmin,
  }) => {
    const IS_SOCIAL_DELIVERY = communityInfo.id === 3;

    const IS_SHOW_SUB_COMMUNITIES_KEY = formatCommunityName(
      SUB_COMMUNITIES_KEY,
      communityInfo.name,
    );

    const { value: isShowSubCommunitiesValue } = useFeatureFlag(IS_SHOW_SUB_COMMUNITIES_KEY, false);

    const onPressListItem = (screen) => {
      NavigationService.navigate(screen);
    };

    const communityName = useSelector((state) => state.communityTheme.theme);

    let list = [];

    if (IS_SOCIAL_DELIVERY) {
      list = isCommunityAdmin
        ? [
            {
              key: 'savedItems',
              name: strings.profile.my_saved_items,
              iconName: 'ouline_heart',
              screen: screens.SavedItems,
            },
            {
              key: 'sellingOffers',
              name: strings.profile.selling,
              iconName: 'Selling',
              count: sellingOffersCount,
              screen: screens.SellingOffers,
            },
            {
              key: 'buyingOffers',
              name: strings.profile.buying,
              iconName: 'Buying',
              count: buyingOffersCount,
              screen: screens.BuyingOffers,
            },
            {
              key: 'wishFulfillmentOffers',
              name: strings.profile.wish_fulfillment,
              iconName: 'Wish',
              count: wishFulfillmentOffersCount,
              screen: screens.WishFulfillmentOffers,
            },
            {
              key: 'myItems',
              name: strings.buy_sell.my_items,
              iconName: 'Items',
              screen: screens.CurrentUserItems,
            },
          ]
        : [
            {
              key: 'savedItems',
              name: strings.profile.my_saved_items,
              iconName: 'ouline_heart',
              screen: screens.SavedItems,
            },
            {
              key: 'buyingOffers',
              name: strings.profile.buying,
              iconName: 'Buying',
              count: buyingOffersCount,
              screen: screens.BuyingOffers,
            },
            {
              key: 'wishFulfillmentOffers',
              name: strings.profile.wish_fulfillment,
              iconName: 'Wish',
              count: wishFulfillmentOffersCount,
              screen: screens.WishFulfillmentOffers,
            },
            {
              key: 'myItems',
              name: strings.buy_sell.my_items,
              iconName: 'Items',
              screen: screens.CurrentUserItems,
            },
          ];
    } else {
      list = [
        {
          key: 'savedItems',
          name: strings.profile.my_saved_items,
          iconName: 'ouline_heart',
          screen: screens.SavedItems,
        },
        {
          key: 'sellingOffers',
          name: strings.profile.selling,
          iconName: 'Selling',
          count: sellingOffersCount,
          screen: screens.SellingOffers,
        },
        {
          key: 'buyingOffers',
          name: strings.profile.buying,
          iconName: 'Buying',
          count: buyingOffersCount,
          screen: screens.BuyingOffers,
        },
        {
          key: 'wishFulfillmentOffers',
          name: strings.profile.wish_fulfillment,
          iconName: 'Wish',
          count: wishFulfillmentOffersCount,
          screen: screens.WishFulfillmentOffers,
        },
        // {
        //   key: 'promoCode',
        //   name: strings.promo_codes.redeem_code,
        //   iconName: 'redeem_code',
        //   screen: screens.PromoCode,
        // },
        {
          key: 'myItems',
          name: strings.buy_sell.my_items,
          iconName: 'Items',
          screen: screens.CurrentUserItems,
        },
        {
          key: 'support',
          name: strings.support.support,
          iconName: 'Menu_Support',
          screen: screens.Support,
        },
      ];

      if (isCommunityAdmin) {
        list.push({
          key: 'myItems2',
          name: strings.pending_items.pending_items,
          iconName: 'pending_items',
          screen: screens.PendingItems,
        });

        list.push({
          key: 'donatePoints',
          name: strings.donate_points.donate_points,
          iconName: 'Menu_issue-tokens',
          screen: screens.DonatePointsStack,
        });
      }

      if (isShowSubCommunitiesValue) {
        const newItem = {
          key: 'mySubCommunities',
          name: strings.profile.my_sub_communities,
          iconName: '2_M_home-and-garden',
          screen: screens.MySubCommunitiesStack,
        };

        list.unshift(newItem);
      }
    }

    return (
      <View>
        {list.map((item) => (
          <OptionItem
            count={item.count}
            key={item.key}
            title={item.name}
            Icon={item.icon}
            iconName={item.iconName}
            onPress={() => onPressListItem(item.screen)}
            rightText={item.value}
            textColor={item.textColor}
          />
        ))}
      </View>
    );
  },
);

OptionsList.propTypes = {
  notificationsCount: T.number,
  buyingOffersCount: T.number,
  sellingOffersCount: T.number,
  wishFulfillmentOffersCount: T.number,
};

export default connect((state) => ({
  notificationsCount: state.notifications.unreadCount,
  buyingOffersCount: state.offers.offersCounters.active_buying_offers_count,
  sellingOffersCount: state.offers.offersCounters.active_selling_offers_count,
  wishFulfillmentOffersCount: state.offers.offersCounters.wishes_with_active_offers_count,
  communityInfo: state.communityInfo,
  isCommunityAdmin: communitySelectors.getIsUserAdmin(state),
}))(OptionsList);
