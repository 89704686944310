import toastTypes from '../constants/toastTypes';
import strings from '../localization';

class ModalsService {
  constructor() {
    this._rootModal = null;
  }

  init(ref) {
    if (this._rootModal) {
      return;
    }
    this._rootModal = ref;
  }

  destroy() {
    this._rootModal = null;
  }

  showModal(name, params) {
    this._rootModal.showSimpleModal(name, params);
  }

  showTransparentModal(name, params) {
    this._rootModal.showTransparentModal(name, params);
  }

  showFullScreenModal(name, params) {
    this._rootModal.showFullScreenModal(name, params);
  }

  showSwipeableModal(name, params) {
    this._rootModal.showSwipeableModal(name, params);
  }
  showMandatoryResponseModal(name, params) {
    this._rootModal.showMandatoryResponseModal(name, params);
  }

  hideModal() {
    this._rootModal.onClose();
  }

  getIsVisible() {
    return this._rootModal.getIsModalVisible();
  }
}

const modalsService = new ModalsService();

export default modalsService;
