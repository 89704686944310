import communitiesApi from '../../api/communities';
import { getCommunityId } from '../communityInfo/selectors';
import { ToastsService } from '../../services';
import strings from '../../localization';

export const getCommunityMembers = ({ limit, offset, searchTerm = '' }) => async (dispatch, getState) => {
  const communityId = getCommunityId(getState());

  try {
    const items = await communitiesApi.getCommunityMembers({
      communityId,
      limit,
      offset,
      searchTerm,
    });
    console.log('items', items)
    const hasMore = limit === items?.length;

    return { items, hasMore };
  } catch (error) {
    ToastsService.showError(strings.error_messages.error);
  }
};

export default {
  getCommunityMembers,
};
