import React, { memo } from 'react';
import { Image, StyleSheet, ImageBackground, View } from 'react-native';
import FastImage from '../../../../ReusableComponents/FastImage';
import T from 'prop-types';
import { connect } from 'react-redux';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../styles';
import { chatsSelectors } from '../../../../../store/chats';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';
import strings from '../../../../../localization';

const s = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
  },
  imageBackground: {
    width: 60,
    height: 60,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginBottom: dimensions.medium,
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  button: {
    height: 28,
    borderRadius: 14,
    minWidth: 100,
    backgroundColor: colors.transparent,
    borderWidth: dimensions.hairline,
    borderColor: colors.text,
    paddingHorizontal: dimensions.medium,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: dimensions.medium,
  },
});

const InterlocutorInfo = memo(({ chat }) => {
  const { interlocutor_name, interlocutor_id, interlocutor_image } = chat;

  const onOpenUserProfile = () => {
    NavigationService.navigate(screens.UserProfile, {
      name: interlocutor_name,
      userId: interlocutor_id,
      profile_image: interlocutor_image,
    });
  };
  return (
    <View style={s.container}>
      <TouchableItem onPress={onOpenUserProfile} style={rs.alignCenter}>
        <ImageBackground
          source={{ uri: 'user_avatar_placeholder' }}
          resizeMode="cover"
          style={s.imageBackground}
          imageStyle={s.image}
        >
          {!!interlocutor_image && (
            <FastImage style={s.image} source={{ uri: interlocutor_image }} resizeMode="cover" />
          )}
        </ImageBackground>
        <Text xsmall color={colors.text} alignCenter>
          {strings.chats.interlocutor_info_title} {interlocutor_name}
        </Text>
      </TouchableItem>
      <TouchableItem style={s.button} onPress={onOpenUserProfile}>
        <Text xsmall color={colors.text}>
          {strings.chats.view_profile}
        </Text>
      </TouchableItem>
    </View>
  );
});

InterlocutorInfo.propTypes = {
  chat: T.object,
};

export default InterlocutorInfo;
