import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { ImageBackground, KeyboardAvoidingView, ScrollView } from 'react-native';
import {
  View,
  Input,
  Text,
  AngleIcon,
  TouchableItem,
  Button,
  Container,
  Image,
} from '../../../../ReusableComponents';
import { colors, headerStyle, rs } from '../../../../../styles/index';
import { transactionOperations } from '../../../../../store/transactions';
import NavigationService from '../../../../../services/NavigationService';
import strings from '../../../../../localization';
import screens from '../../../../../navigation/screens';
import SelectedMemberLabel from './components/SelectedMemberLabel';
import s from './style';
import { isIos, isWeb } from '../../../../../utils/detectDevice';
import { MASTER, REWARD } from '../../../../../constants/walletTypes';
import wallet_background from '../../../../../assets/web/images/wallet_background.png';
import rewards_wallet_background from '../../../../../assets/web/images/rewards_wallet_background.png';
import { getCurrentTheme } from '../../../../../templates';

const IssueToken = ({ navigation, createIssueTransaction }) => {
  const [walletType, setWalletType] = useState(MASTER);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');

  const currentTheme = getCurrentTheme();

  const isValid =
    !!amount &&
    !R.isEmpty(selectedMembers) &&
    !!note &&
    note.trim().length > 9 &&
    note.length <= 120;

  const onSubmit = async () => {
    navigation.setParams({ isValidate: false });

    const receiverIds = selectedMembers.map(({ id }) => {
      return id;
    });

    await createIssueTransaction({
      note,
      amount,
      receiverIds,
      walletType,
    });

    NavigationService.goBack();
  };

  const onRemoveMemberFromSelected = (member) => {
    setSelectedMembers(
      selectedMembers.filter(({ id }) => {
        return id !== member.id;
      }),
    );
  };

  const onSelectMembersSubmit = (members) => {
    setSelectedMembers(members);
    NavigationService.pop();
  };

  const onSetAmount = (value) => {
    setAmount(value.replace(/[^0-9]/g, ''));
  };

  return (
    <Container>
      <KeyboardAvoidingView
        keyboardShouldPersistTabs="handled"
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
        keyboardVerticalOffset={isIos ? 80 : 0}
      >
        <ScrollView keyboardShouldPersistTabs="handled">
          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.bank.select_wallet_title}
            </Text>
          </View>

          <View style={s.walletsContainer}>
            <TouchableItem
              style={s.walletBackgroundContainer}
              onPress={() => setWalletType(MASTER)}
            >
              <ImageBackground
                style={s.balanceBackground}
                imageStyle={s.walletImage}
                source={isWeb ? wallet_background : { uri: 'wallet_background' }}
                resizeMode="cover"
              >
                {walletType === MASTER && <View style={s.selectedWalletBorder} />}
                <Text color={colors.white} semiBold>
                  {strings.bank.balance}
                </Text>
              </ImageBackground>
            </TouchableItem>

            <TouchableItem
              style={s.walletBackgroundContainer}
              onPress={() => setWalletType(REWARD)}
            >
              <ImageBackground
                style={s.balanceBackground}
                imageStyle={s.walletImage}
                source={isWeb ? rewards_wallet_background : { uri: 'rewards_wallet_background' }}
                resizeMode="cover"
              >
                {walletType === REWARD && <View style={s.selectedWalletBorder} />}
                <Text color={currentTheme.colors.secondary} semiBold>
                  {strings.bank.reward_allowance}
                </Text>
              </ImageBackground>
            </TouchableItem>
          </View>

          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.bank.amount_title}
            </Text>
          </View>
          <View style={s.amountInputContainer}>
            <Image style={s.tokenIcon} source={{ uri: 'price' }} resizeMode="contain" />
            <Input
              autoFocus
              style={s.amountInput}
              maxLength={5}
              placeholder="0"
              keyboardType="numeric"
              onChangeText={onSetAmount}
              value={amount}
            />
          </View>

          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.bank.select_members_title}
            </Text>
          </View>

          <TouchableItem
            style={[s.userSelectorContainer, R.isEmpty(selectedMembers) && rs.bigPaddingBottom]}
            onPress={() =>
              NavigationService.navigate(screens.IssueMemberList, {
                selectedMembers,
                onSubmit: onSelectMembersSubmit,
              })
            }
          >
            <Text medium color={colors.gray}>
              {strings.lots.select_members}
            </Text>
            <AngleIcon />
          </TouchableItem>
          {!R.isEmpty(selectedMembers) && (
            <View style={s.selectedMembersContainer}>
              <Fragment>
                {selectedMembers.map((member) => {
                  const onPress = () => {
                    onRemoveMemberFromSelected(member);
                  };
                  return (
                    <SelectedMemberLabel
                      name={member.name}
                      nickname={member.nickname}
                      profileImage={member.profile_image}
                      onPress={onPress}
                      key={member.id}
                    />
                  );
                })}
              </Fragment>
            </View>
          )}

          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.bank.note_title}
            </Text>
          </View>

          <View style={s.noteContainer}>
            <Input
              style={s.notesInput}
              multiline
              blurOnSubmit
              numberOfLines={3}
              maxLength={120}
              maxHeight={90}
              placeholder={strings.bank.at_least}
              onChangeText={setNote}
              value={note}
            />
            <Text xsmall style={s.charactersCountText} color={colors.gray}>
              {note.length}/120
            </Text>
          </View>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button title={strings.common.send} onPress={onSubmit} disabled={!isValid} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default connect(
  (state) => ({
    // user: state.userInfo,
  }),
  (dispatch) => ({
    createIssueTransaction: ({ note, amount, receiverIds, walletType }) =>
      dispatch(
        transactionOperations.createIssueTransaction({ note, amount, receiverIds, walletType }),
      ),
  }),
)(IssueToken);
