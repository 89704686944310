import React, { Component, Fragment, useRef, useState, useEffect } from 'react';
import { View } from 'react-native';
import { connect, useSelector } from 'react-redux';
import T from 'prop-types';
import * as R from 'ramda';
import { useFeatureFlag } from 'configcat-react';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import { appOperations } from '../../../../store/app';
import { groupsSelectors, groupsOperations } from '../../../../store/groups';
import { guideOperations } from '../../../../store/guides';
import { offersOperations } from '../../../../store/offers';
import { numberWithCommas } from '../../../../utils/stringHelper';
import {
  Container,
  LotsList,
  FilterHorizontalList,
  FilterOption,
  EarnPointsBanner,
  LocationBanner,
  TouchableItem,
  FontIcon,
  Text,
  Image,
} from '../../../ReusableComponents';
import s from './styles';
import { colors, dimensions, rs } from '../../../../styles';
import FilterButton from './components/FilterButton';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { FilterService, PermissionService, AnalyticsService } from '../../../../services';
import { sortingTypes } from '../../../../constants/sortingTypes';
import { hasPrimaryLocation } from '../../../../store/userLocations/selectores';
import guideTypes from '../../../../constants/guidelTypes';
import { isAndroid, isWeb } from '../../../../utils/detectDevice';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';
import { SHIPPING_GROUP_ID } from '../../../../constants/excludedGroupIds';
import { getIsUSCommunity } from '../../../../utils/usCommunity';
import MarketplaceSearchBar from '../../../ReusableComponents/MarketplaceSearchBar/index.web';
import { useGetWindowCurrentWidth } from '../../../../utils/getWindowCurrentWidth';
import { getAppLanguage } from '../../../../store/app/selectors';
import { SHARINGOOD_COMMUNITY } from '../../../../constants/communities';
import { formatCommunityName } from '../../../../utils/formatCommunityName';
import { TALENTS_KEY } from '../../../../constants/configcat';

const columnListOptions = {
  DOUBLE: 'double',
  TRIPLE: 'triple',
};

const IS_DEFAULT_DOUBLE_COLUMN_LIST = true;

const Marketplace = ({
  communityId,
  communityInfo,
  communityName,
  groups,
  selectedFilters,

  doubleColumnLists,
  items,
  itemsCount,
  filteredGroups,
  selectedCities,
  selectedCampuses,

  isLoadingItems,
  isLoadingMoreItems,
  isRefreshingItems,

  refreshItems,
  getMoreItems,
  setFeedLotFilters,
  setFilterGroupIds,
  hasLocation,
  hasCoordinates,
  isSearchByPrimaryLocation,
  isShowingInviteFriendsBanner,
  isShowingLocationPermission,
  isLocationPermissionGranted,
  neverShowGuideAgain,
  navigation,
  route,
}) => {
  const filterListRef = useRef(null);
  const [isDoubleColumnList, setDoubleColumnList] = useState(
    communityName === SHARINGOOD_COMMUNITY ? IS_DEFAULT_DOUBLE_COLUMN_LIST : doubleColumnLists,
  );
  const { windowCurrentWidth, isMobileWindow } = useGetWindowCurrentWidth();

  const {
    sortBy,
    groupIds,
    selectedSizes,
    schoolExtension,
    selectedCategoryIds,
    searchTerm,
    selectedConditions,
    selectedBrands,
    distance,
    priceRange,
  } = selectedFilters;

  const IS_SHOW_TALENTS_KEY = formatCommunityName(TALENTS_KEY, communityInfo.name);

  const { value: isShowTalentsValue, loading: isShowTalentLoading } = useFeatureFlag(
    IS_SHOW_TALENTS_KEY,
    false,
  );

  const language = useSelector(getAppLanguage);

  useEffect(() => {
    if (!isShowTalentLoading) {
      setFeedLotFilters({ isSearchOnlyTalent: isShowTalentsValue });
    }
  }, [isShowTalentsValue]);

  useEffect(() => {
    navigation.setParams({ searchTerm });
  }, [searchTerm]);

  const isUSCommunity = getIsUSCommunity(communityId);

  const sortingTypesList = [
    {
      type: sortingTypes.by_newest,
      title: strings.sorting.newest,
    },
    {
      type: sortingTypes.by_oldest,
      title: strings.sorting.oldest,
    },
    {
      type: sortingTypes.by_highest,
      title: strings.sorting.highest_price,
    },
    {
      type: sortingTypes.by_lowest,
      title: strings.sorting.lowest_price,
    },
  ];

  if (isSearchByPrimaryLocation || hasCoordinates) {
    sortingTypesList.push({
      type: sortingTypes.by_distance,
      title: strings.sorting.nearest,
    });
  }

  const getGroupFilterButtonTitle = () => {
    if (R.isEmpty(groupIds) && R.isEmpty(selectedCategoryIds)) {
      return strings.groups.all_groups;
    }
    const [group] = filteredGroups;

    if (!R.isEmpty(selectedCategoryIds)) {
      const selectedCategory = R.find(R.propEq('id', selectedCategoryIds[0]))(group.categories);

      return `${group.group_name}: ${selectedCategory.name}`;
    }

    return group.group_name;
  };

  const onNeverShowLocationPermissionBanner = () => {
    neverShowGuideAgain({ guideType: guideTypes.MARKETPLACE_LOCATION_PERMISSION });
  };

  const onNeverShowEarnPointsBanner = () => {
    neverShowGuideAgain({ guideType: guideTypes.MARKETPLACE_INVITE_FRIENDS });
  };

  const onSelectSortBy = () => {
    FilterService.show({
      isMultipleSelection: false,
      options: sortingTypesList,
      optionKeyName: 'type',
      optionTitleName: 'title',
      // iconName,
      selectedOptionIds: [sortBy],
      onChange: (keys) => {
        if (keys[0] !== sortBy) {
          setFeedLotFilters({ sortBy: keys[0] });
        }
      },
      filterTitle: strings.filters.sort_results_by,
    });
  };

  const onSelectChangeView = () => {
    const currentKey = isDoubleColumnList ? columnListOptions.DOUBLE : columnListOptions.TRIPLE;

    FilterService.show({
      isMultipleSelection: false,
      options: [
        { type: columnListOptions.TRIPLE, title: strings.filters.basic },
        { type: columnListOptions.DOUBLE, title: strings.filters.extended },
      ],
      optionKeyName: 'type',
      optionTitleName: 'title',
      selectedOptionIds: [currentKey],
      onChange: (keys) => {
        if (keys[0] !== currentKey) {
          setDoubleColumnList(keys[0] === columnListOptions.DOUBLE);
        }
      },
      filterTitle: strings.filters.change_list_view,
    });
  };

  const onSelectGroup = () => {
    NavigationService.navigate(screens.GroupFilterStack);
  };

  const onOpenSearch = () => {
    NavigationService.navigate(screens.SearchScreen);
  };

  const onSelectCity = () => {
    if (isUSCommunity) {
      NavigationService.navigate(screens.CampusesFilter);
    } else {
      NavigationService.navigate(screens.CitiesFilterStack);
    }
  };

  const onDismissGroup = () => {
    const updatedSearchTerm = groupIds.includes(SHIPPING_GROUP_ID) ? null : searchTerm;

    setFeedLotFilters({
      groupIds: [],

      selectedSizes: [],
      selectedBrands: [],
      selectedConditions: [],
      selectedCategoryIds: [],
      searchTerm: updatedSearchTerm,
    });
  };

  // const onDismissSorting = () => {
  //   setFeedLotFilters({ sortBy: sortingTypes.by_newest });
  // };

  const onDismissCityFilter = () => {
    setFeedLotFilters({
      selectedCities: [],
      selectedCampuses: [],
      coordinates: null,
      isSearchByPrimaryLocation: false,
      sortBy: sortingTypes.by_newest,
      schoolExtension: null,
    });
  };

  const onDismissSearchTerm = () => {
    setFeedLotFilters({ searchTerm: null });
    if (isAndroid) {
      filterListRef.current.scrollTo({
        x: 0,
        y: 0,
        animated: false,
      });
    }
  };

  const onPressUsersMapButton = async () => {
    AnalyticsService.logEvent(analyticsEventTypes.users_map_open_map_screen);

    if (!isLocationPermissionGranted) {
      const isGrantedLocationPermission = await PermissionService.checkAndRequestGeolocationPermission(
        {
          requestAgainIfBlocked: false,
        },
      );

      NavigationService.navigate(screens.UsersMap, {
        isLocationPermissionGranted: isGrantedLocationPermission,
      });
    } else {
      NavigationService.navigate(screens.UsersMap, { isLocationPermissionGranted });
    }
  };

  const visibleSizes = selectedSizes.length
    ? selectedSizes.reduce((accumulator, currentValue) =>
        accumulator.length < 16 ? `${accumulator}, ${currentValue}` : accumulator,
      )
    : '';

  // const sizesMoreCount = selectedSizes.length - visibleSizes.split(', ').length;
  // let sizesFilterTitle = visibleSizes;
  // if (sizesMoreCount) {
  //   sizesFilterTitle = visibleSizes.concat(
  //     ` ${strings.filters.and} ${sizesMoreCount} ${strings.filters.more}`,
  //   );
  // }

  const renderHeader = () => {
    return (
      <View
        style={[
          { width: windowCurrentWidth },
          isWeb && !isMobileWindow && { paddingHorizontal: 100 },
        ]}
      >
        {isShowingInviteFriendsBanner && (
          <EarnPointsBanner
            onClose={onNeverShowEarnPointsBanner}
            pointsName={
              (communityInfo?.token_name && communityInfo.token_name[language]) ||
              strings.home.token_banner_blue_text
            }
          />
        )}

        {!isLoadingItems && (
          <View style={[s.listHeaderContainer, isWeb && { width: '100%' }]}>
            <Text color={colors.darkCyan} style={s.listHeaderText} semiBold large>
              {strings.formatString(strings.lots.items_found, numberWithCommas(itemsCount))}
            </Text>

            <TouchableItem onPress={onSelectSortBy}>
              <FontIcon
                name="sorting-option"
                color={colors.darkCyan}
                size={22}
                withFixedSize={false}
              />
            </TouchableItem>

            <TouchableItem onPress={onSelectChangeView} style={rs.marginLeft}>
              <FontIcon
                name="view-option"
                color={colors.darkCyan}
                size={22}
                withFixedSize={false}
              />
            </TouchableItem>
          </View>
        )}
      </View>
    );
  };

  let locationButtonTitle = '';

  if (hasCoordinates) {
    locationButtonTitle = strings.filters.filter_by_current_location;
  } else if (isSearchByPrimaryLocation) {
    locationButtonTitle = strings.filters.filter_by_primary_location;
  } else if (!isUSCommunity && R.isEmpty(selectedCities)) {
    locationButtonTitle = strings.lots.location;
  } else if (schoolExtension) {
    locationButtonTitle = strings.filters.filter_by_campus;
  } else if (isUSCommunity && !R.isEmpty(selectedCampuses)) {
    locationButtonTitle = selectedCampuses[0].title;
  } else if (isUSCommunity && R.isEmpty(selectedCampuses) && !schoolExtension) {
    locationButtonTitle = strings.lots.location;
  } else {
    locationButtonTitle = selectedCities[0].name;
  }

  return (
    <Container>
      {isWeb && (
        <View
          style={[
            rs.smallMarginVertical,
            !isMobileWindow && { marginHorizontal: windowCurrentWidth * 0.1 },
          ]}
        >
          <MarketplaceSearchBar
            value={route.params?.searchTerm ?? ''}
            defaultValue={route.params?.searchTerm ?? ''}
            onFocus={() => NavigationService.navigate(screens.SearchScreen)}
            onSubmitEditing={() => NavigationService.navigate(screens.SearchScreen)}
            placeholder={strings.other.marketplace_search_placeholder}
            showCancel={false}
            inputContainerStyle={{ marginLeft: 0, marginRight: 0 }}
            containerStyle={{ width: windowCurrentWidth }}
            updateOnClear
            showSubmitButton
          />
        </View>
      )}
      <View
        style={[
          s.headerContainer,
          isWeb && !isMobileWindow && { marginHorizontal: windowCurrentWidth * 0.1 },
        ]}
      >
        {!isWeb && (
          <Fragment>
            <TouchableItem onPress={onPressUsersMapButton} style={s.mapButtonContainer}>
              <Image
                source={{ uri: 'user_map_marker' }}
                resizeMode="contain"
                style={s.mapButtonImage}
              />
            </TouchableItem>

            <View style={[s.verticalSeparator, rs.smallMarginLeft]} />
          </Fragment>
        )}

        <FilterHorizontalList
          style={isWeb && { backgroundColor: colors.white }}
          forwardRef={filterListRef}
        >
          <FilterButton isSquare />
          {/*{selectedFilters.searchTerm && (*/}
          {/*  <FilterOption*/}
          {/*    primaryButtonTitle={selectedFilters.searchTerm}*/}
          {/*    isActive*/}
          {/*    onPress={onOpenSearch}*/}
          {/*    onDismissPress={onDismissSearchTerm}*/}
          {/*    isSquare*/}
          {/*  />*/}
          {/*)}*/}
          <FilterOption
            primaryButtonTitle={getGroupFilterButtonTitle()}
            onPress={onSelectGroup}
            onDismissPress={onDismissGroup}
            isActive={!R.isEmpty(groupIds)}
            isSquare
          />
          <FilterOption
            primaryButtonTitle={locationButtonTitle}
            isActive={
              !R.isEmpty(selectedCities) ||
              isSearchByPrimaryLocation ||
              hasCoordinates ||
              schoolExtension ||
              !R.isEmpty(selectedCampuses)
            }
            onPress={onSelectCity}
            onDismissPress={onDismissCityFilter}
            isSquare
          />
          {/*<FilterOption*/}
          {/*  primaryButtonTitle={strings.sorting.sort_by}*/}
          {/*  // isActive={selectedFilters.sortBy !== sortingTypes.by_newest}*/}
          {/*  onPress={onSelectSortBy}*/}
          {/*  onDismissPress={onDismissSorting}*/}
          {/*/>*/}

          {/*{!R.isEmpty(selectedSizes) && (*/}
          {/*  <FilterOption*/}
          {/*    title={sizesFilterTitle}*/}
          {/*    onPress={onSelectFilters}*/}
          {/*  />*/}
          {/*)}*/}

          {/*  {!R.isEmpty(selectedConditions) && (*/}
          {/*  <FilterOption title={`${strings.filters.conditions}`} onPress={onSelectFilters} />*/}
          {/*)}*/}
          {/*  {!R.isEmpty(selectedBrands) && (*/}
          {/*    <FilterOption*/}
          {/*      title={`${selectedBrands[0].name} ${strings.filters.and} ${selectedSizes.length - 1} ${*/}
          {/*        strings.filters.more*/}
          {/*        }`}*/}
          {/*      onPress={onSelectFilters}*/}
          {/*    />*/}
          {/*  )}*/}
        </FilterHorizontalList>
      </View>

      <LotsList
        analyticsEventType={analyticsEventTypes.marketplace_select_item}
        screenName={screens.Marketplace}
        doubleColumnLists={isDoubleColumnList}
        data={items}
        onLoadMore={getMoreItems}
        onRefresh={refreshItems}
        isRefreshing={isRefreshingItems}
        isLoadingMore={isLoadingMoreItems}
        isLoading={isLoadingItems}
        ListHeaderComponent={renderHeader}
      />
    </Container>
  );
};

Marketplace.propTypes = {
  items: T.array,
};

export default connect(
  (state) => ({
    communityId: state.communityInfo.id,
    communityInfo: state.communityInfo,
    communityName: state.communityTheme.theme,
    groups: groupsSelectors.getCommunityGroups(state),
    selectedFilters: state.lots.feedLotFilters,
    filteredGroups: lotsSelectors.getFilteredGroups(state),

    sortBy: state.lots.feedLotFilters.sortBy,
    selectedCities: state.lots.feedLotFilters.selectedCities,
    selectedCampuses: state.lots.feedLotFilters.selectedCampuses,
    groupIds: state.lots.feedLotFilters.groupIds,
    isSearchByPrimaryLocation: state.lots.feedLotFilters.isSearchByPrimaryLocation,

    searchTerm: state.lots.feedLotFilters.searchTerm,
    items: lotsSelectors.getItems(state),
    itemsCount: lotsSelectors.getItemsTotalCount(state),

    isLoadingItems: state.lots.isLoadingItems,
    isLoadingMoreItems: state.lots.isLoadingMoreItems,
    isRefreshingItems: state.lots.isRefreshingItems,

    doubleColumnLists: state.app.doubleColumnLists,
    isLocationPermissionGranted: state.app.isLocationPermissionGranted,
    hasLocation: hasPrimaryLocation(state),
    hasCoordinates: !!state.lots.feedLotFilters.coordinates,
    isShowingInviteFriendsBanner: state.guides.showAgain[guideTypes.MARKETPLACE_INVITE_FRIENDS],
    isShowingLocationPermission: state.guides.showAgain[guideTypes.MARKETPLACE_LOCATION_PERMISSION],
  }),
  (dispatch) => ({
    getMoreItems: () => dispatch(lotsOperations.getMoreItems()),
    getMoreWishes: () => dispatch(lotsOperations.getMoreWishes()),
    refreshItems: () => dispatch(lotsOperations.getItems(true)),
    getItems: () => dispatch(lotsOperations.getItems()),

    setFeedLotFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedLotFilters(feedLotFilters)),

    setFilterGroupIds: (groupIds) => dispatch(lotsOperations.setFilterGroupIds(groupIds)),

    refreshWishes: () => dispatch(lotsOperations.getWishes(true)),
    fetchAppData: () => dispatch(appOperations.fetchAppData()),
    fetchChatsData: () => dispatch(appOperations.fetchChatsData()),
    setInsideCommunityId: (groupId) => dispatch(groupsOperations.setInsideCommunityId(groupId)),

    setAllCommunitiesOnFeed: () => dispatch(groupsOperations.setAllCommunitiesOnFeed()),
    joinCommunity: (communityId) => dispatch(groupsOperations.joinCommunity(communityId)),
    setLastAccessDateInCurrentSession: () =>
      dispatch(appOperations.setLastAccessDateInCurrentSession()),
    getRecentOffers: () => dispatch(offersOperations.getRecentOffers()),
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
  }),
)(Marketplace);
