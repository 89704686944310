import React, { forwardRef, useState } from 'react';
import { Keyboard } from 'react-native';
import T from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Container,
  View,
  Button,
  ScreenHeader,
  TouchableItem,
  Image,
} from '../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../styles';
import { AnalyticsService, NavigationService } from '../../../../services';
import strings from '../../../../localization';
import { StyleSheet } from 'react-native';
import screens from '../../../../navigation/screens';
import { isIos, isIphoneX, isWeb } from '../../../../utils/detectDevice';
import Text from '../../../ReusableComponents/Text';
import { connect } from 'react-redux';
import { userOperations } from '../../../../store/userInfo';
import { getAppLocaleCode } from '../../../../store/app/selectors';
import { MIN_USER_AGE } from '../../../../constants/user';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { day } from '../../../../utils/dateHelper';
import { rewardsOperations } from '../../../../store/rewards';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';

const s = StyleSheet.create({
  image: {
    width: 150,
    height: 115,
    alignSelf: 'center',
    marginTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
  selectDateButton: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingVertical: isIos ? dimensions.halfMedium + dimensions.medium : dimensions.medium,
  },
  buttonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.halfMedium,
    marginBottom: isIphoneX ? dimensions.doubleMedium : dimensions.medium,
  },
  infoText: {
    lineHeight: 22,
  },
  contentContainer: {
    marginBottom: isIos ? dimensions.doubleMedium : 0,
  },
});

const AddBirthday = ({ userInfo, updateUserInfo, appLocaleCode }) => {
  const birthdayDefaultValue = (userInfo.birth_date && new Date(userInfo.birth_date)) || null;

  const [birthday, setBirthday] = useState(birthdayDefaultValue);

  const currentTheme = getCurrentTheme
  ();

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TouchableItem ref={ref} onPress={onClick} style={s.selectDateButton}>
      {birthday ? (
        <Text color={currentTheme.colors.secondary}>{day(birthday)}</Text>
      ) : (
        <Text color={colors.grayBorder}>{strings.user_info.date_of_birth}</Text>
      )}
    </TouchableItem>
  ));

  const onSubmit = async () => {
    updateUserInfo({
      email: userInfo.email,
      userName: userInfo.name,
      userAvatar: userInfo.profile_image,
      gender: userInfo.gender,
      birthDate: birthday,
    });
    Keyboard.dismiss();
    AnalyticsService.logEvent(analyticsEventTypes.earn_points_add_birthday);
    NavigationService.goBack();
  };

  const handleConfirm = (date) => {
    setBirthday(date);
  };

  const maxUserBirthDate = moment().subtract(MIN_USER_AGE, 'years').toDate();

  return (
    <Container style={s.contentContainer}>
      <ScreenHeader withoutBorder headerTitle={strings.user_info.date_of_birth_title} />
      <Container>
        <Image style={s.image} source={{ uri: 'birthday' }} resizeMode="contain" />

        <DatePicker
          locale={appLocaleCode}
          value={birthday || maxUserBirthDate}
          onChange={handleConfirm}
          customInput={<ExampleCustomInput />}
          showYearDropdown
          maxDate={maxUserBirthDate}
          yearDropdownItemNumber={50}
          scrollableYearDropdown
          withPortal
          portalId="root-portal"
        />

        <View style={[rs.paddingHorizontal, rs.paddingTop]}>
          <Text style={s.infoText} color={colors.gray}>
            {strings.user_info.date_of_birth_info}
          </Text>
        </View>
      </Container>

      <View style={s.buttonContainer}>
        <Button title={strings.common.save} onPress={onSubmit} disabled={!birthday} />
      </View>
    </Container>
  );
};

AddBirthday.propTypes = {
  userInfo: T.object.isRequired,
  checkNicknameAvailability: T.func.isRequired,
  updateNickname: T.func.isRequired,
};

export default connect(
  (state) => ({
    userInfo: state.userInfo,
    appLocaleCode: getAppLocaleCode(state),
  }),
  (dispatch) => ({
    updateUserInfo: (params) => dispatch(userOperations.updateUserInfo(params)),
  }),
)(AddBirthday);
