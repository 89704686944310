import React, { Component } from 'react';
import { FlatList, View } from 'react-native';
import s, { SizeTitle } from './styles';
import { dimensions, colors } from '../../../styles';
import { isRTL } from '../../../utils/rtlHelper';
import TouchableItem from '../TouchableItem';
import { getCurrentTheme } from '../../../templates';

class Sizes extends Component {
  state = {
    activeSize: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.activeSize !== prevState.activeSize) {
      return {
        activeSize: nextProps.activeSize,
      };
    }
    return null;
  }

  render() {
    const { sizes, activeSize, onSelectSize } = this.props;

    const currentTheme = getCurrentTheme();

    return (
      <View style={s.contentContainer}>
        <FlatList
          horizontal
          inverted
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            paddingHorizontal: dimensions.halfMedium,
          }}
          styles={{
            backgroundColor: colors.white,
            marginTop: dimensions.halfMedium,
            marginBottom: dimensions.halfMedium,
          }}
          data={sizes}
          ref={(ref) => {
            this.flatListRef = ref;
          }}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => {
            const isActive = activeSize === item.id;
            return (
              <TouchableItem
                style={[
                  s.sizeItemContainer,
                  {
                    backgroundColor: isActive ? currentTheme.colors.secondary : colors.white,
                    borderColor: isActive ? currentTheme.colors.secondary : colors.gray,
                  },
                ]}
                useOpacity={false}
                isActive={isActive}
                onPress={() => onSelectSize(item.id)}
              >
                <SizeTitle isActive={isActive} medium>
                  {isRTL ? item.title_he : item.title_en}
                </SizeTitle>
              </TouchableItem>
            );
          }}
        />
      </View>
    );
  }
}

export default Sizes;
