import React from 'react';
import { TouchableOpacity } from 'react-native';
import * as R from 'ramda';
import Icon from 'react-native-vector-icons/Feather';
import { styles } from './styles';
import FontIcon from '../FontIcon';
import Text from '../Text';
import { colors, dimensions } from '../../../styles';
import { getCurrentTheme } from '../../../templates';

export default ({ disabled, onButtonPress, title, style }) => {
  const currentTheme = getCurrentTheme();

  const titleStyles = [
    styles.title,
    {
      color: disabled ? colors.gray : currentTheme.colors.secondary,
    },
  ];
  return (
    <TouchableOpacity
      onPress={R.is(Function, onButtonPress) ? onButtonPress : null}
      disabled={disabled}
      style={[styles.container, style]}
    >
      <Text style={titleStyles} disabled={disabled}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export const NavigationIconButton = ({ name, onPress, disabled, color, style, isFontIcon }) => (
  <TouchableOpacity
    style={styles.container}
    onPress={R.is(Function, onPress) ? onPress : null}
    disabled={disabled}
  >
    {isFontIcon ? (
      <FontIcon
        size={dimensions.iconSize}
        name={name}
        style={[{ color: color || colors.gray }, style]}
      />
    ) : (
      <Icon
        size={dimensions.iconSize}
        name={name}
        style={[{ color: color || colors.gray }, style]}
      />
    )}
  </TouchableOpacity>
);
