import React, { useState, useEffect, useCallback, memo } from 'react';
import { reverse } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import Animated, {
  FadeIn,
  FadeOut,
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';
import { StyleSheet, View, ImageBackground, I18nManager } from 'react-native';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import Icon from 'react-native-vector-icons/Ionicons';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide/dist/css/splide.min.css';
import './styles.css';
import strings from '../../../localization';
import TouchableItem from '../TouchableItem';
import Text from '../Text';
import { colors, dimensions, rs } from '../../../styles';
import { isWeb } from '../../../utils/detectDevice';
import { lotsOperations } from '../../../store/lots';
import { getWalletBalance } from '../../../store/wallets/selectors';
import { NavigationService } from '../../../services';
import screens from '../../../navigation/screens';
import RoundButton from '../Buttons/RoundButton';
import { getCommunitySlides, getIsLoadingSlides } from '../../../store/communityInfo/selectors';
import { appOperations } from '../../../store/app';
import { useGetWindowCurrentWidth } from '../../../utils/getWindowCurrentWidth';
import marketplace_banner_background from '../../../assets/web/images/marketplace_banner_background.jpeg';
import { getCurrentTheme } from '../../../templates';

const WALLET_SLIDE_ID = 'wallet';

const style = ({ windowCurrentWidth, isMobileWindow, slides }) =>
  StyleSheet.create({
    mainContainer: {
      marginTop: dimensions.medium,
      marginHorizontal: dimensions.medium,
      width: windowCurrentWidth - dimensions.medium,
      position: 'relative',
      ...rs.shadow,
    },
    sliderContainer: {
      width: '100%',
      borderRadius: 20,
      backgroundColor: colors.white,
      overflow: 'hidden',
    },
    imageBackground: {
      height: isMobileWindow
        ? windowCurrentWidth / 2
        : windowCurrentWidth > 700
        ? windowCurrentWidth / 3
        : windowCurrentWidth / 2,
      minHeight: isMobileWindow && 190,
      width:
        !isMobileWindow && windowCurrentWidth > 700 && slides.length !== 1
          ? (windowCurrentWidth - 42) / 2
          : windowCurrentWidth - 12,
      backgroundColor: colors.grayLight,
    },
    backdrop: {
      flexGrow: 1,
      zIndex: 1,
      elevation: 1,
      backgroundColor: colors.transparentGray,
    },
    slideContent: {
      flexGrow: 1,
      zIndex: 2,
      elevation: 2,
      paddingVertical: dimensions.doubleMedium,
      paddingHorizontal: dimensions.doubleMedium,
      justifyContent: 'space-around',
      alignItems: I18nManager.getConstants().isRTL ? 'flex-start' : 'flex-end',
      transform: [{ scaleX: I18nManager.getConstants().isRTL ? -1 : 1 }],
    },
    slideText: {
      lineHeight: 26,
      fontSize: 20,
    },
    dotsContainer: {
      zIndex: 5,
      elevation: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dotsInner: {
      paddingBottom: 0,
    },
    dot: {
      backgroundColor: colors.dark700,
    },
    inactiveDot: {
      backgroundColor: colors.dark200,
    },
    borderR: {
      borderRadius: 20,
    },
  });

const CustomArrows = ({ style }) => {
  const currentTheme = getCurrentTheme();
  return (
    <div className="splide__arrows">
      <button className="splide__arrow splide__arrow--prev">
        <Icon size={31} name="ios-arrow-forward" style={{ color: currentTheme.colors.secondary }} />
      </button>
      <button className="splide__arrow splide__arrow--next">
        <Icon size={31} name="ios-arrow-back" style={{ color: currentTheme.colors.secondary }} />
      </button>
    </div>
  );
};

const WalletSlide = memo(({ balance, onPress, onPressIn, onPressOut, s }) => {
  const currentTheme = getCurrentTheme();
  return (
    <SplideSlide>
      <TouchableItem onPress={onPress} onPressIn={onPressIn} onPressOut={onPressOut} withoutOpacity>
        <ImageBackground
          source={marketplace_banner_background}
          resizeMode="cover"
          style={[
            s.imageBackground,
            s.borderR,
            { transform: [{ scaleX: I18nManager.getConstants().isRTL ? -1 : 1 }] },
          ]}
          imageStyle={s.borderR}
        >
          <View style={[s.backdrop, s.borderR]}>
            <View style={[s.slideContent]}>
              <Text>{strings.home.hi_there},</Text>
              <Text bold style={[s.slideText, rs.smallPaddingBottom]}>
                {strings.home.you_have}
                <Text bold color={currentTheme.colors.secondary} style={s.slideText}>
                  {' '}
                  {balance}
                </Text>
                {'\n'}
                {I18nManager.getConstants().isRTL ? '!' : ''}
                {strings.home.tokens}
                {I18nManager.getConstants().isRTL ? '' : '!'}
              </Text>

              <View>
                <RoundButton title={strings.home.start_spending} onPress={onPress} />
              </View>
            </View>
          </View>
        </ImageBackground>
      </TouchableItem>
    </SplideSlide>
  );
});

const CarouselSlide = memo(({ item, onPress, onPressIn, onPressOut, s }) => (
  <SplideSlide>
    <TouchableItem onPress={onPress} onPressIn={onPressIn} onPressOut={onPressOut} withoutOpacity>
      <ImageBackground
        source={{ uri: item.imageUrl }}
        resizeMode="cover"
        style={[s.imageBackground, s.borderR]}
        imageStyle={s.borderR}
      >
        <View style={[s.backdrop, s.borderR]} />
      </ImageBackground>
    </TouchableItem>
  </SplideSlide>
));

const MarketplaceCarousel = memo(() => {
  const dispatch = useDispatch();
  const [slides, setSlides] = useState([]);
  const walletBalance = useSelector(getWalletBalance);
  const communitySlides = useSelector(getCommunitySlides);
  const isLoadingSlides = useSelector(getIsLoadingSlides);

  const sliderScale = useSharedValue(1);
  const { windowCurrentWidth, isMobileWindow } = useGetWindowCurrentWidth();
  const s = style({ windowCurrentWidth, isMobileWindow, slides });

  const onSliderPressIn = useCallback(() => {
    sliderScale.value = withTiming(0.95, { duration: 150 });
  }, [sliderScale]);

  const onSliderPressOut = useCallback(() => {
    sliderScale.value = withTiming(1, { duration: 150 });
  }, [sliderScale]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: sliderScale.value }],
  }));

  useEffect(() => {
    if (!isLoadingSlides) {
      setSlides(I18nManager.getConstants().isRTL ? reverse(communitySlides) : communitySlides);
    }
  }, [communitySlides, isLoadingSlides]);

  const onWalletSlidePress = () => {
    dispatch(
      lotsOperations.setFeedLotFilters({
        isSearchByFollowings: false,
        groupIds: [],
        selectedCategoryIds: [],
        selectedSizes: [],
        selectedBrands: [],
        selectedConditions: [],
        searchTerm: null,
        isSearchByDiscount: false,
        sortBy: 'by_newest',
        selectedCities: [],
        selectedCampuses: [],
      }),
    );

    setTimeout(() => {
      NavigationService.navigate(screens.MarketplaceTab, { screen: screens.Marketplace });
    }, 0);
  };

  const onSlidePress = async (link) => {
    const deepLinkRegExp = new RegExp(/https:\/\/shareitt\.page\.link\/(\S+)/);

    if (deepLinkRegExp.test(link) && !isWeb) {
      const resolvedLink = await dynamicLinks().resolveLink(link);
      dispatch(appOperations.processDeepLink(resolvedLink));
      return;
    }

    try {
      window.open(link, '_blank').focus();
    } catch (error) {
      console.warn(`Cannot open ${link}: invalid url provided`);
    }
  };

  if (isLoadingSlides || !slides.length) {
    return null;
  }

  return (
    <Animated.View style={s.mainContainer} entering={FadeIn} exiting={FadeOut}>
      <Animated.View style={[s.sliderContainer, animatedStyle]}>
        <Splide
          hasTrack={false}
          options={{
            arrows: slides.length > 1 && true,
            gap: 20,
            autoplay: slides.length > 1 && true,
            type: 'slide',
            perPage: (!isMobileWindow && windowCurrentWidth < 700) || slides.length === 1 ? 1 : 2,
            perMove: 1,
            rewind: true,
            cover: true,
            direction: 'rtl',
            snap: true,
            interval: 5000,
            pauseOnHover: false,
            pauseOnFocus: false,
            autoWidth: true,
            autoHeight: true,
            trimSpace: true,
            pagination: slides.length > 1,
          }}
          style={{
            backgroundColor: colors.white,
          }}
        >
          <SplideTrack>
            {slides.map((item) => {
              if (item?.id === WALLET_SLIDE_ID) {
                return (
                  <WalletSlide
                    key={item.id}
                    balance={walletBalance}
                    onPress={onWalletSlidePress}
                    onPressIn={onSliderPressIn}
                    onPressOut={onSliderPressOut}
                    s={s}
                  />
                );
              }
              const onPress = () => {
                onSlidePress(item.redirectUrl);
              };
              return (
                <CarouselSlide
                  key={item.id}
                  item={item}
                  onPress={onPress}
                  onPressIn={onSliderPressIn}
                  onPressOut={onSliderPressOut}
                  s={s}
                />
              );
            })}
          </SplideTrack>
          <CustomArrows style={s} />
        </Splide>
      </Animated.View>
    </Animated.View>
  );
});

export default MarketplaceCarousel;
