import React, { Fragment, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import T from 'prop-types';
import Text from './Text';
import TouchableItem from './TouchableItem';
import FontIcon from './FontIcon';

import { colors, dimensions, rs } from '../../styles';
import strings from '../../localization';
import { isRTL } from '../../utils/rtlHelper';
import { getCurrentTheme } from '../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flexDirection: 'row',
  },
  itemsContainer: {
    flexDirection: 'row',
    paddingHorizontal: dimensions.doubleMedium,
  },
  itemContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.halfMedium,
  },
  verticalSeparator: {
    width: 1,
    height: '100%',
    backgroundColor: colors.grayLight,
  },
});

const genders = [
  { key: 'male', iconName: isRTL ? 'Get-started_Male_he' : 'Get-started_Male' },
  { key: 'female', iconName: isRTL ? 'Get-started_Female_he' : 'Get-started_Female' },
  { key: 'other', iconName: 'Get-started_Fe-Male' },
];

const GenderSelector = memo(({ selectedGender, onSelect, withoutTitle }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View style={s.contentContainer}>
      {!withoutTitle && <Text medium>{strings.user_info.gender}</Text>}
      <View style={s.itemsContainer}>
        {genders.map((gender, index) => {
          const isSelected = selectedGender === gender.key;
          return (
            <Fragment key={gender.key}>
              <TouchableItem
                disabled={isSelected}
                style={[s.itemContainer]}
                onPress={() => onSelect(gender.key)}
              >
                <Fragment>
                  <FontIcon
                    color={isSelected ? currentTheme.colors.secondary : colors.grayBorder}
                    size={44}
                    withoutRTLScale
                    name={gender.iconName}
                  />
                </Fragment>
              </TouchableItem>
              {index !== genders.length - 1 && <View style={s.verticalSeparator} />}
            </Fragment>
          );
        })}
      </View>
    </View>
  );
});

GenderSelector.propTypes = {
  withoutTitle: T.bool,
  selectedGender: T.string,
  onSelect: T.func.isRequired,
};

export default GenderSelector;
