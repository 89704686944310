import React from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { dimensions, reusableStyles, colors } from '../../styles';

const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${dimensions.medium};
  ${reusableStyles.rtlScale};
`;

const AngleIcon = ({ isAngleDown, size = dimensions.iconSize, color = colors.grayBorder }) => (
  <StyledIcon name={isAngleDown ? 'angle-down' : 'angle-right'} size={size} color={color} />
);

export default AngleIcon;
