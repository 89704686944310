import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { View, StyleSheet } from 'react-native';
import SignIn from '../../components/Screens/AuthorizationGroup/LoginScreen';
import CodeVerification from '../../components/Screens/AuthorizationGroup/CodeVerificationScreen';
import screens from '../screens';
import { headerStyleTransparent } from '../../styles';
import NavigationButton from '../../components/ReusableComponents/NavigationButtons';
import strings from '../../localization';
import headerStyle, { grayHeaderStyle } from '../../styles/headerStyle';

const Stack = createStackNavigator();

const UnauthorizedUserStack = () => (
  <Stack.Navigator initialRouteName={screens.SignIn}>
    <Stack.Screen
      name={screens.SignIn}
      component={SignIn}
      options={({ route }) => ({
        headerShown: false,
        ...headerStyleTransparent,
        title: null,
        headerLeft: () => <View />,
        headerRight: () => (
          <NavigationButton
            title={strings.common.next}
            disabled={route.params?.isDisabledNext ?? false}
            onButtonPress={route.params?.nextClicked ?? null}
          />
        ),
      })}
    />

    <Stack.Screen
      name={screens.CodeVerification}
      component={CodeVerification}
      options={({ route }) => ({
        headerShown: false,
        ...grayHeaderStyle,
        title: route.params?.phoneNumber ?? '',
        headerRight: () => (
          <NavigationButton
            title={strings.common.next}
            disabled={!route.params?.isValidCode ?? false}
            onButtonPress={route.params?.nextClicked ?? null}
          />
        ),
      })}
    />
  </Stack.Navigator>
);

const s = StyleSheet.create({
  container: {
    maxWidth: 500,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 1,
  },
});

export default UnauthorizedUserStack;
