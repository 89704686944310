import React, { useRef, useEffect } from 'react';
import T from 'prop-types';
import { View, Animated, Easing, StyleSheet } from 'react-native';
import LottieView from 'lottie-react-native';

const animation = require('../../../assets/animations/typingIndicator.json');
const AnimatedView = Animated.createAnimatedComponent(View);

const s = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
});

const ThreeDotsLoader = ({ size }) => {
  const animationRef = useRef(null);
  const opacity = new Animated.Value(0);

  const fadeIn = () => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };

  useEffect(() => {
    fadeIn();
    if (animationRef?.current) {
      animationRef.current.play();
    }

    return () => {
      if (animationRef?.current) {
        animationRef.current.reset();
      }
    };
  }, []);

  return (
    <AnimatedView style={[s.container, { opacity }]}>
      <LottieView
        ref={animationRef}
        source={animation}
        speed={2}
        resizeMode="contain"
        style={[s.lottie, { height: size }]}
        loop
      />
    </AnimatedView>
  );
};

ThreeDotsLoader.propTypes = {
  size: T.number,
};

ThreeDotsLoader.defaultProps = {
  size: 10,
};

export default ThreeDotsLoader;
