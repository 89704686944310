import React, { useState } from 'react';
import {
  View,
  Alert,
  Image,
  ImageBackground,
  KeyboardAvoidingView,
  ScrollView,
} from 'react-native';
import { connect } from 'react-redux';
import { transactionOperations } from '../../../../store/transactions';
import strings from '../../../../localization';
import { isIos, isWeb } from '../../../../utils/detectDevice';
import { numberWithCommas } from '../../../../utils/stringHelper';
import { Input, Text, Container, Button } from '../../../ReusableComponents';
import { colors } from '../../../../styles';
import s from './styles';
import { ModalsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';
import NavigationService from '../../../../services/NavigationService.web';

const DonatePoints = ({ route, wallet, createDonationTransaction }) => {
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');

  const foundation = route.params.item;
  const walletBalance = parseInt(wallet.balance_amount, 10) - parseInt(wallet.hold_balance, 10);

  const isValid =
    !!amount &&
    Number(amount) <= walletBalance &&
    !!note &&
    note.trim().length > 9 &&
    note.length <= 120;

  const onSetAmount = (value) => {
    setAmount(value.replace(/[^0-9]/g, ''));
  };

  const onSubmit = () => {
    const bodyText = strings.donate_points.confirm_donation_alert_body.replace('XX', amount);

    if (isWeb) {
      ModalsService.showModal(modalTypes.ALERT, {
        title: strings.bank.confirm_donation_alert_title,
        description: bodyText,
        confirmButtonTitle: strings.common.confirm,
        cancelButtonTitle: strings.common.cancel,
        onSubmit: () => {
          createDonationTransaction({ receiverId: foundation.id, amount, note: note.trim() });
        },
      });
    } else {
      Alert.alert(strings.bank.confirm_donation_alert_title, bodyText, [
        {
          text: strings.common.confirm,
          onPress: () => {
            createDonationTransaction({ receiverId: foundation.id, amount, note: note.trim() });
          },
        },
        {
          text: strings.common.cancel,
          onPress: () => {},
          style: 'cancel',
        },
      ]);
    }
  };

  return (
    <Container>
      <KeyboardAvoidingView
        keyboardShouldPersistTabs="handled"
        behavior={isIos ? 'padding' : null}
        style={s.keyboardAvoidingView}
        keyboardVerticalOffset={isIos ? 80 : 0}
      >
        <ScrollView keyboardShouldPersistTabs="handled">
          <View style={s.walletsContainer}>
            <View style={s.walletBackgroundContainer}>
              <ImageBackground
                style={s.balanceBackground}
                imageStyle={s.walletImage}
                source={{
                  uri: 'wallet_background',
                }}
                resizeMode="cover"
              >
                <View style={s.balanceContainer}>
                  <Text color={colors.white} semiBold>
                    {strings.bank.balance}
                  </Text>
                  <Text medium bold color={colors.white} style={s.balanceText}>
                    {numberWithCommas(walletBalance)}
                  </Text>
                </View>
              </ImageBackground>
            </View>
          </View>

          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.donate_points.type_amount}
            </Text>
          </View>

          <View style={s.amountInputContainer}>
            <Image style={s.tokenIcon} source={{ uri: 'price' }} resizeMode="contain" />
            <Input
              autoFocus
              style={s.amountInput}
              maxLength={5}
              placeholder="0"
              keyboardType="numeric"
              onChangeText={onSetAmount}
              value={amount}
            />
          </View>

          <View style={s.sectionHeaderContainer}>
            <Text semiBold medium>
              {strings.donate_points.note}
            </Text>
          </View>

          <View style={s.noteContainer}>
            <Input
              style={s.notesInput}
              multiline
              blurOnSubmit
              numberOfLines={3}
              maxLength={120}
              maxHeight={90}
              placeholder={strings.bank.at_least}
              onChangeText={setNote}
              value={note}
            />
            <Text xsmall style={s.charactersCountText} color={colors.gray}>
              {note.length}/120
            </Text>
          </View>
        </ScrollView>

        <View style={s.buttonContainer}>
          <Button title={strings.common.send} onPress={onSubmit} disabled={!isValid} />
        </View>
      </KeyboardAvoidingView>
    </Container>
  );
};

export default connect(
  (state) => ({
    wallet: state.wallets.wallet,
  }),
  (dispatch) => ({
    createDonationTransaction: ({ receiverId, amount, note }) =>
      dispatch(transactionOperations.createDonationTransaction({ receiverId, amount, note })),
  }),
)(DonatePoints);
