const theme = {
  colors: {
    primary: '#A44AFE',
    secondary: '#189BC9',
    background: '#FFFFFF',
    primaryLight: '#6c8ae9',
    secondaryLight: '#189BC9',
  },
};

export { theme };
