import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { headerStyle, headerStyleTransparent } from '../../styles';
import {
  MultipleBrands,
  Filter,
  MultipleConditions,
  MultipleSizes,
} from '../../components/Screens';
import screens from '../screens';
import strings from '../../localization';
import { BackButton, NavigationButton } from '../../components/ReusableComponents';
import { View } from 'react-native';
import { isWeb } from '../../utils/detectDevice';

const Stack = createStackNavigator();

const FilterStack = () => (
  <Stack.Navigator screenOptions={{ ...headerStyle, headerRight: () => <View /> }}>
    <Stack.Screen
      name={screens.Filter}
      component={Filter}
      options={({ route }) => ({
        title: strings.filters.filters,
        headerLeft: () => <BackButton isCancel={!isWeb} />,
        headerRight: () => (
          <NavigationButton
            title={strings.filters.reset}
            onButtonPress={route.params?.onReset ?? null}
          />
        ),
      })}
    />
    <Stack.Screen
      name={screens.MultipleConditions}
      component={MultipleConditions}
      options={({ route }) => ({
        title: strings.filters.conditions,
        headerLeft: () => <BackButton isCancel />,
        headerRight: () => (
          <NavigationButton
            title={strings.common.confirm}
            onButtonPress={route.params?.onSubmit ?? null}
          />
        ),
      })}
    />
    <Stack.Screen
      name={screens.MultipleBrands}
      component={MultipleBrands}
      options={({ route }) => ({
        ...headerStyleTransparent,
        title: strings.lots.brands,
        headerLeft: () => <BackButton isCancel />,
        headerRight: () => (
          <NavigationButton
            title={strings.common.done}
            onButtonPress={route.params?.onSubmit ?? null}
          />
        ),
      })}
    />
    <Stack.Screen
      name={screens.MultipleSizes}
      component={MultipleSizes}
      options={({ route }) => ({
        title: strings.filters.sizes,
        headerLeft: () => <BackButton isCancel />,
        headerRight: () => (
          <NavigationButton
            title={strings.common.confirm}
            onButtonPress={route.params?.onSubmit ?? null}
          />
        ),
      })}
    />
  </Stack.Navigator>
);

export default FilterStack;
