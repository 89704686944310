import styled from 'styled-components/native';
import { StyleSheet } from 'react-native';
import { colors, dimensions } from '../../../../styles';
import { Text, TouchableItem } from '../../../ReusableComponents/index';

export const styles = StyleSheet.create({
  brandNameText: {
    color: colors.gray,
    marginHorizontal: dimensions.medium,
  },
});

export const BrandView = styled(TouchableItem)`
  flex-direction: row;
  align-items: center;
  height: 46px;
  justify-content: space-between;
  padding-right: ${dimensions.halfMedium};
  background-color: ${colors.white};
`;
export const MainContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

/** List Components * */
export const KeyName = styled(Text)`
  color: ${colors.text};
  padding-horizontal: ${dimensions.medium};
  padding-vertical: ${dimensions.halfMedium};
  width: 100%;
`;

export const Separator = styled.View`
  width: 100%;
  height: ${StyleSheet.hairlineWidth};
  margin-left: ${50 + dimensions.medium * 2};
  background-color: ${colors.gray};
`;

/** Container * */
export const ContentContainer = styled.View`
  flex: 1;
  padding-top: 4px;
`;

export const SearchBarContainer = styled.View`
  padding-bottom: ${dimensions.halfMedium};
`;

export const NoBrandSeparatorBlock = styled.View`
  height: ${dimensions.halfMedium};
  background-color: ${colors.grayLighter};
`;
