import React, { memo } from 'react';
import { Image, StyleSheet, ImageBackground } from 'react-native';
import FastImage from '../../../../ReusableComponents/FastImage';
import T from 'prop-types';
import { connect } from 'react-redux';
import { TouchableItem } from '../../../../ReusableComponents';
import { dimensions } from '../../../../../styles';
import { chatsSelectors } from '../../../../../store/chats';
import NavigationService from '../../../../../services/NavigationService';
import screens from '../../../../../navigation/screens';

const s = StyleSheet.create({
  container: {
    marginRight: dimensions.medium,
  },
  imageBackground: {
    width: 32,
    height: 32,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  image: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
});

const ChatNameHeaderTitle = memo(({ chat }) => {
  const { interlocutor_name, interlocutor_id, interlocutor_image } = chat;

  const onOpenUserProfile = () => {
    NavigationService.navigate(screens.UserProfile, {
      name: interlocutor_name,
      userId: interlocutor_id,
      profile_image: interlocutor_image,
    });
  };
  return (
    <TouchableItem onPress={onOpenUserProfile} style={s.container}>
      <ImageBackground
        source={{ uri: 'user_avatar_placeholder' }}
        resizeMode="cover"
        style={s.imageBackground}
        imageStyle={s.image}
      >
        {!!interlocutor_image && (
          <FastImage style={s.image} source={{ uri: interlocutor_image }} resizeMode="cover" />
        )}
      </ImageBackground>
    </TouchableItem>
  );
});

ChatNameHeaderTitle.propTypes = {
  chat: T.object,
};

export default connect((state, { chatId }) => ({
  chat: chatsSelectors.getChatInfoById(state, chatId),
}))(ChatNameHeaderTitle);
