import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { headerStyle } from '../../styles';
import { CitiesFilter, LocationView } from '../../components/Screens';
import screens from '../screens';
import strings from '../../localization';
import { BackButton } from '../../components/ReusableComponents';
import { View } from 'react-native';

const Stack = createStackNavigator();

const CitiesStack = () => (
  <Stack.Navigator screenOptions={{ ...headerStyle, headerRight: () => <View /> }}>
    <Stack.Screen
      name={screens.CitiesFilter}
      component={CitiesFilter}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={screens.Location}
      component={LocationView}
      options={{
        title: strings.other.change_location,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
  </Stack.Navigator>
);

export default CitiesStack;
