import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import RNRestart from 'react-native-restart';
import { Alert } from 'react-native';
import T from 'prop-types';
import DeviceInfo from 'react-native-device-info';
import SplashScreen from 'react-native-splash-screen';
import { View, TouchableItem, Text, Container, Icon } from '../../../ReusableComponents';
import { colors, headerStyle } from '../../../../styles/index';
import { appOperations } from '../../../../store/app';
import strings from '../../../../localization';
import languages from '../../../../constants/languages';
import notificationsApi from '../../../../api/notifications';
import s from './styles';

const Language = ({ language, setLanguage }) => {
  const changeLanguage = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      Alert.alert(
        strings.languages.alert_change_language_title,
        strings.languages.alert_change_language_description.replace(
          'XX',
          languages.find((el) => el.code === selectedLanguage).title,
        ),
        [
          {
            text: strings.common.confirm,
            onPress: async () => {
              /** Save language in redux */
              SplashScreen.show();
              const deviceId = await DeviceInfo.getUniqueId();
              setLanguage(selectedLanguage);
              notificationsApi.changeDeviceLanguage(selectedLanguage, deviceId);
              setTimeout(() => RNRestart.Restart(), 1);
            },
          },
          {
            text: strings.common.cancel,
            onPress: () => {},
            style: 'cancel',
          },
        ],
      );
    }
  };

  return (
    <Container>
      {languages.map((item) => (
        <TouchableItem
          useOpacity={false}
          style={s.languageContainer}
          key={item.code}
          onPress={() => changeLanguage(item.code)}
        >
          <Fragment>
            <Text medium>{item.title}</Text>
            {language === item.code && (
              <Icon name="check" size={18} color={colors.blue} withoutRTLScale />
            )}
          </Fragment>
        </TouchableItem>
      ))}
    </Container>
  );
};

Language.propTypes = {
  language: T.oneOf(languages.map((el) => el.code)), // ['en', 'he']
  setLanguage: T.func,
};

export default connect(
  (state) => ({
    language: state.app.language,
  }),
  (dispatch) => ({
    setLanguage: (language) => dispatch(appOperations.setLanguage(language)),
  }),
)(Language);
