import React from 'react';
import { connect } from 'react-redux';
import { FlatList } from 'react-native';
import T from 'prop-types';
import { Container } from '../../../ReusableComponents';
import GroupItem from './components/GroupItem';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { groupsSelectors } from '../../../../store/groups';
import { lotsOperations } from '../../../../store/lots';
import { lotsSelectors } from '../../../../store/lots';

const GroupsList = ({ groups, selectGroup, selectAllGroups, totalWishesAmount, route: { params } }) => {
  const onGroupPress = (groupId) => {
    if (!!params?.onSetCategory) {
      params.onSetCategory({ groupId: [groupId], categoryId: [] });
    } else {
      selectGroup(groupId);
    }

    NavigationService.pop();
  };

  const onSelectAllGroups = () => {
    if (!!params?.onSetCategory) {
      params.onSetCategory({ groupId: [], categoryId: [] });
    }

    selectAllGroups();
    NavigationService.pop();
  };

  return (
    <Container>
      <FlatList
        data={groups}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <GroupItem
            onPress={onGroupPress}
            id={item.id}
            name={item.group_name}
            activeItemsCount={item.active_wishes_count}
            backgroundImage={item.background_image}
            withArrow={false}
          />
        )}
        ListHeaderComponent={
          <GroupItem
            onPress={onSelectAllGroups}
            id="group"
            name={strings.profile.view_all}
            activeItemsCount={totalWishesAmount}
            backgroundImage="all_groups"
            withArrow={false}
            isAllGroups
          />
        }
      />
    </Container>
  );
};

GroupsList.propTypes = {
  getCommunitiesList: T.func,
};

export default connect(
  (state) => ({
    groups: groupsSelectors.getCommunityGroupsListForWishes(state),
    totalWishesAmount: lotsSelectors.getWishesTotalCount(state),
  }),
  (dispatch) => ({
    selectAllGroups: () => dispatch(lotsOperations.setFeedWishFilters({ groupIds: [] })),
    selectGroup: (groupId) => dispatch(lotsOperations.setFeedWishFilters({ groupIds: [groupId] })),
  }),
)(GroupsList);
