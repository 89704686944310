import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { View } from 'react-native';
import { headerStyle } from '../../styles';
import { GroupsList, MultipleCategories } from '../../components/Screens';
import screens from '../screens';
import strings from '../../localization';
import { BackButton } from '../../components/ReusableComponents';
import { isWeb } from '../../utils/detectDevice';
import { getCurrentTheme } from '../../templates';

const Stack = createStackNavigator();

const GroupFilterStack = () => {
  const currentTheme = getCurrentTheme();
  return (
    <Stack.Navigator
      screenOptions={{
        ...headerStyle,
        headerTintColor: currentTheme.colors.secondary,
        headerRight: () => <View />,
      }}
    >
      <Stack.Screen
        name={screens.GroupsList}
        component={GroupsList}
        options={{
          title: strings.groups.groups,
          headerLeft: () => <BackButton isCancel={!isWeb} />,
        }}
      />
      <Stack.Screen
        name={screens.MultipleCategories}
        component={MultipleCategories}
        options={{
          title: strings.filters.categories,
          headerLeft: () => <BackButton />,
        }}
      />
    </Stack.Navigator>
  );
};

export default GroupFilterStack;
