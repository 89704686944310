import React from 'react';
import { StyleSheet } from 'react-native';
import T from 'prop-types';
import { Pagination } from 'react-native-snap-carousel';
import { colors, dimensions } from '../../styles';
import { getCurrentTheme } from '../../templates';

const s = StyleSheet.create({
  paginationDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 0,
  },
  paginationInactiveDot: {
    backgroundColor: colors.gray,
  },
  paginationContainer: {
    paddingVertical: dimensions.medium,
  },
});

const CarouselPagination = ({
  activeSlideIndex,
  dotsLength,
  containerStyle,
  dotStyle,
  inactiveDotStyle,
}) => {
  const currentTheme = getCurrentTheme();
  return (
    <Pagination
      dotsLength={dotsLength}
      activeDotIndex={activeSlideIndex}
      dotStyle={[
        s.paginationDot,
        { backgroundColor: currentTheme.colors.secondaryLight },
        dotStyle,
      ]}
      inactiveDotStyle={[s.paginationInactiveDot, inactiveDotStyle]}
      containerStyle={[s.paginationContainer, containerStyle]}
      inactiveDotOpacity={0.4}
      inactiveDotScale={0.6}
    />
  );
};

CarouselPagination.propTypes = {
  activeSlideIndex: T.number,
  dotsLength: T.number,
};

export default CarouselPagination;
