import React from 'react';
import * as RNLocalize from 'react-native-localize';
import { WebView } from 'react-native-webview';
import {
  PRIVACY_POLICY_URL_EN,
  PRIVACY_POLICY_URL_HE,
  PRIVACY_POLICY_URL_BR,
  PRIVACY_POLICY_URL_DE,
} from '../../../../api/urls';
import { HIDE_SHAREITT_SITE_HEADER_JS } from '../../../../constants/webview';

export const getPrivacyPolicyUrl = (languageCode) => {
  switch (languageCode) {
    case 'pt':
    case 'br':
      return PRIVACY_POLICY_URL_BR;
    case 'he':
      return PRIVACY_POLICY_URL_HE;
    case 'de':
      return PRIVACY_POLICY_URL_DE;
    default:
      return PRIVACY_POLICY_URL_EN;
  }
};

const PrivacyPolicy = () => {
  const languageCode = RNLocalize.getLocales()[0].languageCode;
  const sourceUrl = getPrivacyPolicyUrl(languageCode);

  return (
    <WebView
      source={{ uri: sourceUrl }}
      injectedJavaScript={HIDE_SHAREITT_SITE_HEADER_JS}
      // onMessage is necessary to set to handle injected javascript
      // for more information:
      // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#injectedjavascript
      onMessage={() => {}}
    />
  );
};

export default PrivacyPolicy;
