import styled from 'styled-components/native';
import { Platform, StyleSheet } from 'react-native';
import { colors, dimensions, rs } from '../../../../../../styles';
import { Text, TouchableItem } from '../../../../../ReusableComponents';
import { isWeb } from '../../../../../../utils/detectDevice';

export default StyleSheet.create({
  descriptionText: {
    marginTop: 2,
  },
  itemAvatar: {
    marginHorizontal: dimensions.medium,
  },
  iconContainer: {
    height: isWeb ? 36 : 24,
    width: isWeb ? 36 : 24,
    borderRadius: 23,
    backgroundColor: colors.orange,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: isWeb ? 60 : 36,
    left: isWeb ? 55 : 36,
    ...rs.shadow,
  },
  greenBackground: {
    backgroundColor: colors.green,
  },
  grayBackground: {
    backgroundColor: colors.gray,
  },
  whiteBackground: {
    backgroundColor: colors.white,
  },
});

const chatItemHeight = Platform.OS === 'ios' ? 78 : 80;

export const ContentContainer = styled(TouchableItem)`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding-vertical: ${isWeb ? 24 : dimensions.medium};
  background-color: ${colors.white};
`;

export const MainContainer = styled.View`
  flex: 1;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const TransactionInfoContainer = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TransactionTypeContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const ProductContainer = styled.View`
  align-items: flex-end;
  justify-content: center;
  margin-right: ${dimensions.medium};
  margin-left: ${dimensions.doubleMedium};
  padding-top: 2px;
`;

export const PriceContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Price = styled(Text)`
  margin-left: 4px;
`;
