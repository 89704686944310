import React, { useState, useRef, useEffect, Fragment } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { StyleSheet, View, Keyboard } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch } from 'react-redux';
import { dimensions, colors, rs } from '../../../../styles';
import userOperations from '../../../../store/user/operations';
import strings from '../../../../localization';
import reports from '../../../../constants/reports';
import { Button, Text, TouchableItem, Input } from '../../';
import { ToastsService } from '../../../../services';
import { isIphoneX, isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';

const CUSTOM_REPORT_ID = 4;

const s = StyleSheet.create({
  root: {
    flex: 1,
    paddingTop: 30,
  },
  swipeView: {
    width: 60,
    height: 8,
    alignSelf: 'center',
    marginBottom: dimensions.medium,
    borderRadius: 4,
    backgroundColor: colors.grayLightest,
    opacity: 0.8,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  headerContainer: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    left: dimensions.medium,
  },
  description: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: 'column',
  },
  descriptionNote: {
    marginTop: 4,
  },
  optionsContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  optionButton: {
    width: '100%',
    paddingVertical: 30,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.transparent,
    justifyContent: 'center',
  },
  submitButtonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.doubleMedium,
    marginBottom: isIphoneX ? dimensions.halfMedium : dimensions.doubleMedium,
  },
  input: {
    marginTop: dimensions.medium,
    fontSize: dimensions.medium,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
    paddingVertical: 4,
  },
});

const Report = ({ params, onClose }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [activeId, setActiveId] = useState(null);
  const [customReason, setCustomReason] = useState('');

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', () => {
      if (scrollRef.current) {
        scrollRef.current.scrollToEnd();
      }
    });

    return () => {
      keyboardDidShow.remove();
    };
  }, []);

  const isApplyButtonDisabled = !activeId || (activeId === CUSTOM_REPORT_ID && !customReason);

  const onSendReport = async () => {
    const reportReason = activeId === CUSTOM_REPORT_ID ? customReason : activeId;

    await dispatch(
      userOperations.sendReport({
        report_type: params.reportType,
        reporter_id: params.reporterUserId,
        profile_id: params.reportedUserId,
        item_id: params.itemId,
        report_reason: reportReason,
      }),
    );

    ToastsService.showSuccess(strings.user.report_success_message);
    onClose();
  };

  return (
    <View style={s.root}>
      {!isWeb && <View style={s.swipeView} />}

      <View style={s.contentContainer}>
        <View style={s.headerContainer}>
          <TouchableItem style={s.closeButton} onPress={onClose}>
            <Text color={currentTheme.colors.secondary}>{strings.common.cancel}</Text>
          </TouchableItem>

          <Text style={rs.alignCenter} color={colors.secondary} medium bold>
            {strings.user.report}
          </Text>
        </View>

        <View style={s.description}>
          <Text color={colors.text} semiBold>
            {strings.formatString(
              strings.user.report_subtitle_main,
              strings.user.report_types[params.reportType],
            )}
          </Text>
          <Text color={colors.gray} style={s.descriptionNote} small>
            {strings.user.report_subtitle_note}
          </Text>
        </View>

        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          extraScrollHeight={dimensions.medium}
          ref={scrollRef}
          enableResetScrollToCoords
        >
          <View style={s.optionsContainer}>
            {reports
              .filter((report) => !R.hasPath(['type'], report) || report.type === params.reportType)
              .map((report, index) => {
                const isActive = activeId === report.id;
                const isCustom = report.id === CUSTOM_REPORT_ID;

                const onPress = () => {
                  setActiveId(report.id);
                };

                return (
                  <TouchableItem
                    key={index.toString()}
                    isActive={isActive}
                    onPress={onPress}
                    style={[
                      s.optionButton,
                      isActive &&
                        !isCustom && { backgroundColor: currentTheme.colors.secondaryLight },
                      isCustom && { backgroundColor: colors.grayLighter },
                    ]}
                    useOpacity={false}
                  >
                    <Fragment>
                      <Text color={isActive && !isCustom ? colors.white : colors.gray}>
                        {strings.user.report_titles[report.title]}
                      </Text>

                      {isCustom && isActive && (
                        <Input
                          value={customReason}
                          onChangeText={setCustomReason}
                          style={[s.input, { color: currentTheme.colors.secondaryLight }]}
                          placeholder={strings.user.report_input_placeholder}
                          underlineColorAndroid="transparent"
                          autoFocus
                          blurOnSubmit
                        />
                      )}
                    </Fragment>
                  </TouchableItem>
                );
              })}
          </View>

          <View style={s.submitButtonContainer}>
            <Button
              title={strings.common.send}
              onPress={onSendReport}
              disabled={isApplyButtonDisabled}
            />
          </View>
        </KeyboardAwareScrollView>
      </View>
    </View>
  );
};

Report.propTypes = {
  params: T.shape({
    reporterUserId: T.oneOfType([T.number, T.string]),
    reportedUserId: T.oneOfType([T.number, T.string]),
    reportType: T.string,
    itemId: T.string,
  }),
  onClose: T.func.isRequired,
};

export default Report;
