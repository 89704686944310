import React from 'react';
import { StyleSheet } from 'react-native';
import { TextInput, I18nManager } from 'react-native-web';
import { colors, fontSizes } from '../../../styles';
const isRTL = I18nManager.getConstants().isRTL;

const s = StyleSheet.create({
  input: {
    fontSize: fontSizes.small,
    fontFamily: 'Rubik-Regular',
    color: colors.text,
    paddingVertical: 0,
    textAlign: isRTL ? 'right' : 'left',
    outline: 'none',
    outlineStyle: 'none',
  },
});

const Input = ({ style, ...props }) => (
  <TextInput
    style={[s.input, style]}
    placeholderTextColor={colors.grayBorder}
    underlineColorAndroid={'transparent'}
    {...props}
  />
);

export default Input;
