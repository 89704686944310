import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';

import * as types from './types';

const initialState = {
  slides: [],
  isLoadingSlides: true,
  // community_id: 1,
  // about: 'First Israeli Community',
  // background_image:
  //   'https://storage.googleapis.com/group-background-images-prod/12_1562504405504.png',
  // community_icon: 'https://storage.googleapis.com/user-avatars-prod/MixCoins_1581869207265.png',
  // community_rules: [],
  // community_settings: { is_private: false, is_open_for_visitors: false },
  // created_at: null,
  // name: 'common',
  // home_settings: {
  //   has_most_followed_users: true,
  //   has_my_followers: true,
  //   has_top_sellers: true,
  //   has_newest_in_group: true,
  //   has_items_from_members_i_follow: true,
  //   has_items_near_me: true,
  //   has_discounted_items: true,
  //   has_my_top_categories: true,
  //   has_top_categories: true,
  //   has_popular_in_category: false,
  //   has_newest_in_categories: false,
  //   home_action_slider_settings: {
  //     has_name: true,
  //     has_profile_image: true,
  //     has_location: true,
  //     has_email: true,
  //     has_nickname: true,
  //   },
  // },
};

const communitiesReducer = handleActions(
  {
    [types.SET_COMMUNITY]: mergeDeep(({ payload }, state) => payload.communityInfo),
    [types.SET_COMMUNITY_SLIDES]: mergeDeep(({ payload }) => ({
      slides: payload,
    })),
    [types.SET_LOADING_SLIDES]: mergeDeep(({ payload }) => ({
      isLoadingSlides: payload,
    })),
  },
  initialState,
);

export default communitiesReducer;
