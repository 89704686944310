import React, { memo } from 'react';
import T from 'prop-types';
import s from './styles';
import { colors } from '../../../../styles';
import TouchableItem from '../../TouchableItem';
import Icon from '../../Icon';
import Text from '../../Text';
import { FilterService } from '../../../../services';
import { getCurrentTheme } from '../../../../templates';

const FilterOptions = memo(
  ({
    primaryButtonTitle,
    options,
    optionKeyName = 'id',
    optionTitleName = 'title',
    iconName = 'iconUrl',
    isMultipleSelection = true,
    isActive = false,
    resetOnActivePress = false,
    selectedOptionIds,
    onChange,
    onPressResetFilter,
    filterTitle,
    isSquare,
  }) => {
    const currentTheme = getCurrentTheme();

    const textColor = isActive ? colors.white : colors.text;
    const icon = resetOnActivePress && isActive ? 'x' : 'chevron-down';

    const onPress = () => {
      FilterService.show({
        options,
        optionKeyName,
        optionTitleName,
        iconName,
        selectedOptionIds,
        onChange,
        filterTitle,
        isMultipleSelection,
      });
    };

    const onPressReset = () => {
      if (resetOnActivePress && isActive) {
        onPressResetFilter();
      }
    };

    return (
      <TouchableItem
        key="SELECTOR"
        style={[
          s.buttonContainer,
          {
            backgroundColor: currentTheme.colors.secondaryLight,
            borderColor: currentTheme.colors.secondaryLight,
          },
          s.primaryButtonContainer,
          isActive && {
            backgroundColor: currentTheme.colors.secondary,
            borderColor: currentTheme.colors.secondary,
          },
          isSquare && s.squareButtonContainer,
        ]}
        onPress={onPress}
      >
        <Text color={textColor}>{primaryButtonTitle}</Text>
        {isActive && resetOnActivePress ? (
          <TouchableItem onPress={onPressReset}>
            <Icon name={icon} size={18} color={textColor} style={s.icon} />
          </TouchableItem>
        ) : (
          <Icon name={icon} size={18} color={textColor} style={s.icon} />
        )}
      </TouchableItem>
    );
  },
);

FilterOptions.propTypes = {
  primaryButtonTitle: T.string,
  options: T.array,
  optionKeyName: T.string,
  optionTitleName: T.string,
  iconName: T.string,
  selectedOptionIds: T.array,
  onChange: T.func,
  isMultipleSelection: T.bool,
  isActive: T.bool,
  resetOnActivePress: T.bool,
  isSquare: T.bool,
};

FilterOptions.defaultProps = {
  isSquare: false,
};

export default FilterOptions;
