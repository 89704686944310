import React, { useState, useEffect, useRef, Fragment } from 'react';
import T from 'prop-types';
import { StyleSheet, View, Keyboard } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch } from 'react-redux';
import { offersOperations } from '../../../../store/offers';
import { colors, dimensions, rs } from '../../../../styles';
import strings from '../../../../localization';
import { Button, Text, TouchableItem, Input } from '../../';
import { isIphoneX, isWeb } from '../../../../utils/detectDevice';
import useOnce from '../../../../utils/useOnce';
import { getCurrentTheme } from '../../../../templates';

const CUSTOM_REFUND_TYPE_ID = 'other';

const s = StyleSheet.create({
  rootContainer: {
    flex: 1,
    paddingTop: 30,
  },
  swipeView: {
    width: 60,
    height: 8,
    alignSelf: 'center',
    marginBottom: dimensions.medium,
    borderRadius: 4,
    backgroundColor: colors.grayLightest,
    opacity: 0.8,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  headerContainer: {
    width: '100%',
    paddingTop: 16,
    paddingBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    left: dimensions.medium,
  },
  description: {
    backgroundColor: colors.grayLighter,
    paddingHorizontal: dimensions.medium,
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: 'column',
  },
  descriptionNote: {
    marginTop: 4,
  },
  optionsContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  optionButton: {
    width: '100%',
    paddingVertical: 30,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.transparent,
    justifyContent: 'center',
  },
  submitButtonContainer: {
    width: '100%',
    height: 40,
    paddingHorizontal: dimensions.medium,
    marginTop: dimensions.doubleMedium,
    marginBottom: isIphoneX ? dimensions.halfMedium : dimensions.doubleMedium,
  },
  input: {
    marginTop: dimensions.medium,
    fontSize: dimensions.medium,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayBorder,
    paddingVertical: 4,
  },
});

const Refund = ({ params, onClose }) => {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [activeId, setActiveId] = useState(null);
  const [customRefundReason, setCustomRefundReason] = useState('');

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', () => {
      if (scrollRef.current) {
        scrollRef.current.scrollToEnd();
      }
    });

    return () => {
      keyboardDidShow.remove();
    };
  }, []);

  // https://devunet.atlassian.net/browse/SHAREITT-1698
  // need to prevent multiple calls for refunds
  const onSendRefund = useOnce(async () => {
    const refundReason = activeId === CUSTOM_REFUND_TYPE_ID ? customRefundReason : activeId;

    await dispatch(offersOperations.performRefundBySeller(params.offerId, refundReason));
  });

  const refundTypes = [
    {
      id: 'buyer_cant_pickup_item',
      title: strings.refund_reasons.buyer_cant_pickup_item,
    },
    {
      id: 'buyer_requested_refund_before_item_shipped',
      title: strings.refund_reasons.buyer_requested_refund_before_item_shipped,
    },
    {
      id: 'buyer_requested_to_refund_after_item_reached_them',
      title: strings.refund_reasons.buyer_requested_to_refund_after_item_reached_them,
    },
    {
      id: 'approved_by_mistake',
      title: strings.refund_reasons.approved_by_mistake,
    },
    {
      id: 'no_longer_have_item',
      title: strings.refund_reasons.no_longer_have_item,
    },
    {
      id: CUSTOM_REFUND_TYPE_ID,
      title: strings.refund_reasons.other,
    },
  ];

  const isSendButtonDisabled =
    !activeId || (activeId === CUSTOM_REFUND_TYPE_ID && !customRefundReason);

  return (
    <View style={s.rootContainer}>
      {!isWeb && <View style={s.swipeView} />}
      <View style={s.contentContainer}>
        <View style={s.headerContainer}>
          <TouchableItem style={s.closeButton} onPress={onClose}>
            <Text color={currentTheme.colors.secondary}>{strings.common.cancel}</Text>
          </TouchableItem>

          <Text style={rs.alignCenter} color={colors.secondary} medium bold>
            {strings.modals.refund_title}
          </Text>
        </View>

        <View style={s.description}>
          <Text color={colors.text} semiBold>
            {strings.modals.refund_text}
          </Text>
        </View>

        <KeyboardAwareScrollView
          keyboardShouldPersistTaps="handled"
          extraScrollHeight={dimensions.medium}
          ref={scrollRef}
          enableResetScrollToCoords
        >
          <View style={s.optionsContainer}>
            {refundTypes.map((refundType, index) => {
              const isActive = activeId === refundType.id;
              const isCustom = refundType.id === CUSTOM_REFUND_TYPE_ID;

              const onPress = () => {
                setActiveId(refundType.id);
              };

              return (
                <TouchableItem
                  key={index.toString()}
                  isActive={isActive}
                  onPress={onPress}
                  style={[
                    s.optionButton,
                    isActive &&
                      !isCustom && { backgroundColor: currentTheme.colors.secondaryLight },
                    isCustom && { backgroundColor: colors.grayLighter },
                  ]}
                  useOpacity={false}
                >
                  <Fragment>
                    <Text color={isActive && !isCustom ? colors.white : colors.gray}>
                      {refundType.title}
                    </Text>

                    {isCustom && isActive && (
                      <Input
                        value={customRefundReason}
                        onChangeText={setCustomRefundReason}
                        style={[s.input, { color: currentTheme.colors.secondaryLight }]}
                        placeholder={strings.user.report_input_placeholder}
                        underlineColorAndroid="transparent"
                        autoFocus
                        blurOnSubmit
                      />
                    )}
                  </Fragment>
                </TouchableItem>
              );
            })}
          </View>

          <View style={s.submitButtonContainer}>
            <Button
              title={strings.common.send}
              onPress={onSendRefund}
              disabled={isSendButtonDisabled}
            />
          </View>
        </KeyboardAwareScrollView>
      </View>
    </View>
  );
};

Refund.propTypes = {
  params: T.shape({
    offerId: T.oneOfType([T.string, T.number]),
  }),
  onClose: T.func.isRequired,
};

export default Refund;
