import { createSelector } from 'reselect';
import * as R from 'ramda';

export const selectTheme = (state) => {
  return R.pathOr('', ['communityTheme', 'theme'], state);
};

export default {
  selectTheme,
};
