/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { I18nManager } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import * as styled from './styles';
import { colors } from '../../../../styles/index';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import { isAndroid, isIos, isWeb } from '../../../../utils/detectDevice';
import { getCurrentTheme } from '../../../../templates';
import Text from '../../Text';
import TouchableItem from '../../TouchableItem';

const { styles } = styled;

const BackButton = ({ transitioning, isCancel, isClose, goTo, onPress, isWhite }) => {
  const onButtonPress = () =>
    goTo ? NavigationService.navigate(goTo) : NavigationService.goBack();

  const isRTL = isWeb ? I18nManager.getConstants().isRTL : I18nManager.isRTL;

  const currentTheme = getCurrentTheme();

  return (
    <TouchableItem
      style={styles.backButtonContainer}
      onPress={onPress || onButtonPress}
      disabled={transitioning}
    >
      {isCancel && (
        <Text style={[styles.cancelTitleText, { color: currentTheme.colors.secondary }]}>
          {strings.common.cancel}
        </Text>
      )}

      {isClose && (
        <Icon
          size={31}
          name="ios-close"
          style={{
            color: isWhite ? colors.white : colors.gray,
            transform: [{ scaleX: isRTL ? -1 : 1 }],
          }}
        />
      )}

      {isAndroid && !isClose && !isCancel && (
        <MaterialIcons
          size={24}
          name="arrow-back"
          style={{
            paddingLeft: 6,
            color: isWhite ? colors.white : currentTheme.colors.secondary,
            transform: [{ scaleX: isRTL ? -1 : 1 }],
          }}
        />
      )}

      {(isIos || isWeb) && !isClose && !isCancel && (
        <Icon
          size={31}
          name="ios-arrow-back"
          style={{
            color: isWhite ? colors.white : currentTheme.colors.secondary,
            transform: [{ scaleX: isRTL ? -1 : 1 }],
          }}
        />
      )}
    </TouchableItem>
  );
};

export default BackButton;
