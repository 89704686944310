import { I18nManager, Linking } from 'react-native';
import RNRestart from 'react-native-restart';
import Contacts from 'react-native-contacts';
import AsyncStorage from '@react-native-community/async-storage';
import uuid from 'uuid/v4';
import * as R from 'ramda';
import pWaitFor from 'p-wait-for';
import {
  setLanguage,
  setConnection,
  setLocationPermission,
  resetGroupState,
  setFeedColumnStyle,
  resetState,
  setLastAccessDateInCurrentSession,
  setGeolocationCoordinates,
  setContacts,
  setContactsPermission,
  setAppEntryLogged,
} from './actions';
import { resetItems } from '../lots/actions';

import { lotsOperations } from '../lots';
import { chatsOperations } from '../chats';
import { followersOperations } from '../followers';
import { rewardsOperations } from '../rewards';
import { groupsOperations, groupsSelectors } from '../groups';
import { notificationOperations } from '../notifications';
import { userOperations } from '../userInfo';
import { offersOperations } from '../offers';
import { citiesOperations } from '../communityCities';
import { campusesOperations } from '../campuses';
import getUrlQueryParams from '../../utils/getUrlQueryParams';
import accountApi from '../../api/account';
import promotionsApi from '../../api/promotions';
import locationsApi from '../../api/locations';
import {
  LoadingService,
  ModalsService,
  PermissionService,
  ToastsService,
  NavigationService,
} from '../../services';
import {
  OPEN_ITEM_DETAILS,
  OPEN_USER_PROFILE,
  OPEN_WISH_DETAILS,
  JOIN_COMMUNITY,
  OPEN_MARKETPLACE,
  OPEN_FAQ,
  OPEN_SUPPORT_CHAT,
  OPEN_PHOTO_TIPS,
  OPEN_INVITE_FRIENDS,
  OPEN_MARKETPLACE_FULL,
  OPEN_WISHES,
} from '../../constants/dynamicalLinkActionTypes';
import {
  onOpenItemDetails,
  onOpenUserProfile,
  onOpenWishDetails,
} from '../notifications/notificationHelper';
import modalTypes from '../../constants/modalTypes';
import strings from '../../localization';
import screens from '../../navigation/screens';
import { userLocationsOperations } from '../userLocations';
import { subscriptionsOperations } from '../subscriptions';
import { walletsOperations } from '../wallets';
import { promotionOperations } from '../promotion';
import dynamicLinks from '@react-native-firebase/dynamic-links';
import Geolocation from 'react-native-geolocation-service';
import { homeOperations } from '../home';
import { communityOperations, communitySelectors } from '../communityInfo';
import { COMMON_LIMIT } from '../../constants/listLimits';
import { getIsUSCommunity } from '../../utils/usCommunity';
import { capitalize } from '../../utils/stringHelper';
import { getMapViewBounds } from '../../utils/distance';
import { getLanguage } from '../../utils/localizationHelper';
import { setLocale } from '../../utils/dateHelper';
import { isRTL } from '../../utils/rtlHelper';
import { isWeb } from '../../utils/detectDevice';

const handleDeepLink = () => async (dispatch, getState) => {
  const [urlFromLinking, urlFromDynamicLinks] = await Promise.all([
    Linking.getInitialURL(),
    dynamicLinks().getInitialLink(),
  ]);

  const link = urlFromDynamicLinks
    ? urlFromDynamicLinks
    : urlFromLinking && (await dynamicLinks().resolveLink(urlFromLinking));

  if (link) {
    dispatch(processDeepLink(link));
  }

  dynamicLinks().onLink(async (dynamicLink) => {
    dispatch(processDeepLink(dynamicLink));
  });
};

const processDeepLink = (dynamicLink) => async (dispatch) => {
  try {
    const { actionType, id, referral_code } = getUrlQueryParams(dynamicLink.url);

    if (actionType === JOIN_COMMUNITY) {
      dispatch(userOperations.setReferralCode(referral_code));
      return;
    }

    LoadingService.showLoader();

    const { is_community_member, is_link_valid } = await accountApi.validateDeepLink({
      action_type: actionType,
      id,
    });

    if (!is_community_member && actionType === OPEN_USER_PROFILE) {
      LoadingService.hideLoader();

      ModalsService.showModal(modalTypes.INFO, {
        title: strings.modals.deep_linking.oops,
        description: strings.modals.deep_linking.no_shared_communities,
      });
      return;
    }

    if (!is_community_member && actionType === OPEN_ITEM_DETAILS) {
      LoadingService.hideLoader();

      ModalsService.showModal(modalTypes.INFO, {
        title: strings.modals.deep_linking.join_community,
        description: strings.modals.deep_linking.join_community_body,
        // description: strings.modals.deep_linking.join_community_body.replace(
        //   'XX',
        //   communityEntities[communityId].group_name,
        // ),
      });
      return;
    }

    if (!is_link_valid && actionType !== OPEN_MARKETPLACE) {
      LoadingService.hideLoader();

      ModalsService.showModal(modalTypes.INFO, {
        title: strings.modals.deep_linking.inactive_link_title,
        description: strings.modals.deep_linking.inactive_link_body,
      });
      return;
    }

    if (actionType === OPEN_ITEM_DETAILS) {
      await onOpenItemDetails(id);
    } else if (actionType === OPEN_WISH_DETAILS) {
      await onOpenWishDetails(id);
    } else if (actionType === OPEN_USER_PROFILE) {
      await onOpenUserProfile(id);
    } else if (actionType === OPEN_MARKETPLACE) {
      dispatch(onOpenMarketplaceWithDeepLink(id));
    } else if (actionType === OPEN_MARKETPLACE_FULL) {
      dispatch(onOpenMarketplaceWithDeepLink());
    } else if (actionType === OPEN_WISHES) {
      NavigationService.navigate(screens.Wishes);
    } else if (actionType === OPEN_FAQ) {
      NavigationService.navigate(screens.FAQ);
    } else if (actionType === OPEN_SUPPORT_CHAT) {
      await dispatch(chatsOperations.openSupportChat());
    } else if (actionType === OPEN_PHOTO_TIPS) {
      NavigationService.navigate(screens.PhotoTips);
    } else if (actionType === OPEN_INVITE_FRIENDS) {
      NavigationService.navigate(screens.InviteFriends);
    }
    LoadingService.hideLoader();
  } catch (e) {
    LoadingService.hideLoader();
  }
};

const onOpenMarketplaceWithDeepLink = (groupId) => async (dispatch, getState) => {
  NavigationService.navigate(screens.MarketplaceTab);

  await pWaitFor(() => !getState().lots.isLoadingItems);
  dispatch(resetItems());
  dispatch(lotsOperations.setFeedLotFilters({ groupIds: groupId ? [groupId] : [] }));
};

const getLocationsByLatLng = ({ longitude, latitude }) => async () => {
  try {
    const result = await locationsApi.getLocationsByLatLng({ longitude, latitude });
    return result?.results[0];
  } catch (err) {
    ToastsService.showError(strings.error_messages.error);
  }
};

const handleGeolocation = (fetchItems = true) => async (dispatch, getState) => {
  // const { app } = getState();

  // const longitude = 34.9872828;
  // const latitude = 32.8254101;

  // const oldLongitude = app.geolocationCoordinates.longitude;
  // const oldLatitude = app.geolocationCoordinates.latitude;

  return await new Promise((resolve, error) =>
    Geolocation.getCurrentPosition(
      async (result) => {
        const { longitude, latitude } = result.coords;

        // todo this is temporary solution for test, change to distance difference
        // if (oldLongitude !== longitude && oldLatitude !== latitude) {
        dispatch(
          setGeolocationCoordinates({
            longitude,
            latitude,
          }),
        );

        if (fetchItems) {
          await dispatch(
            homeOperations.getItemsNearMeForHome({
              longitude,
              latitude,
            }),
          );
        }
        // }

        resolve({ longitude, latitude });
      },
      (err) => {
        console.log('$$$$$$ getCurrentPosition Error:', err);
        resolve(null);
      },
      {
        accuracy: {
          android: 'high',
          ios: 'best',
        },
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 10000,
        distanceFilter: 0,
        forceRequestLocation: true,
      },
    ),
  );
};

const handleGeolocationWeb = (fetchItems = true) => async (dispatch) => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
    distanceFilter: 0,
    forceRequestLocation: true,
  };

  return await new Promise((resolve) =>
    window.navigator.geolocation.getCurrentPosition(
      async (result) => {
        const { latitude, longitude } = result.coords;

        dispatch(
          setGeolocationCoordinates({
            longitude,
            latitude,
          }),
        );

        if (fetchItems) {
          await dispatch(
            homeOperations.getItemsNearMeForHome({
              longitude,
              latitude,
            }),
          );
        }
        resolve({ longitude, latitude });
      },
      (err) => {
        console.log('$$$$$$ getCurrentPosition Error:', err);
        resolve(null);
      },
      options,
    ),
  );
};

/** Every time when open app from background * */
const fetchAppData = () => async (dispatch, getState) => {
  const isUSCommunity = getIsUSCommunity(getState().communityInfo.id);

  dispatch(groupsOperations.getCommunityGroups());
  dispatch(lotsOperations.getItems());
  dispatch(rewardsOperations.getRewards());
  dispatch(followersOperations.getCurrentUserFollowerCounts());

  if (isUSCommunity) {
    dispatch(campusesOperations.getCommunityCampuses({}));
  } else {
    dispatch(citiesOperations.getCommunityCities({}));
  }

  dispatch(notificationOperations.getNotifications());
  dispatch(userLocationsOperations.getUserLocations());
  dispatch(subscriptionsOperations.getSubscriptionDetails());
  dispatch(walletsOperations.getWallets());
  dispatch(offersOperations.getOfferCounters());
  dispatch(promotionOperations.getActivePointsBackPromotion());
  dispatch(communityOperations.getCommunitySlides());
};

const fetchAppDataOnAppStateChange = () => async (dispatch) => {
  dispatch(groupsOperations.getCommunityGroups());
  dispatch(rewardsOperations.getRewards());
  dispatch(notificationOperations.getNotifications());
  dispatch(walletsOperations.getWallets());
  dispatch(offersOperations.getOfferCounters());
  dispatch(promotionOperations.getActivePointsBackPromotion());
  dispatch(communityOperations.getCommunitySlides());
};

/** Once, when open app first time * */
const fetchChatsData = () => async (dispatch, getState) => {
  const state = getState();
  const isAdmin = communitySelectors.getIsUserAdmin(state);

  dispatch(chatsOperations.getAllChats());

  if (isAdmin) {
    dispatch(chatsOperations.getSupportChatsForAdmins({}));
    dispatch(chatsOperations.getUnreadCountForAdminSupportChats());
  }
  // dispatch(chatsOperations.getBuyChats());
  // dispatch(chatsOperations.getSellChats());
  // dispatch(chatsOperations.getPersonalChats());
  dispatch(chatsOperations.getUnreadCountForAllChats());
};

const processContacts = async (contacts) => {
  if (!contacts?.length) {
    return [];
  }

  const countryCode = await AsyncStorage.getItem('currentCountryCode');
  const dialCode = !!countryCode ? `+${countryCode}` : '+0';

  const contactList = [];

  contacts.forEach((contact) => {
    const { recordID, phoneNumbers, givenName, middleName, familyName } = contact;
    const id = recordID.toLowerCase();

    if (!phoneNumbers?.length) {
      return;
    }

    const numbers = [];

    phoneNumbers.forEach(({ label, number }) => {
      let phoneNumber = number;

      if (phoneNumber.replace(/\D/, '').length >= 5) {
        const formattedNumber = phoneNumber.replace(/\(|\)|-|\s| /g, '');

        if (formattedNumber.startsWith('+')) {
          phoneNumber = formattedNumber;
        } else if (formattedNumber.startsWith('0')) {
          phoneNumber = formattedNumber.replace('0', dialCode);
        } else {
          phoneNumber = `${dialCode}${formattedNumber}`;
        }
      }

      numbers.push({ id: uuid(), label, number: phoneNumber });
    });

    const firstName = capitalize(givenName);
    const lastName = familyName ? capitalize(familyName) : capitalize(middleName);

    contactList.push({
      id,
      phoneNumbers: numbers,
      firstName,
      lastName,
    });
  });

  return R.sortBy(
    R.compose(R.toLower, ({ firstName, lastName }) => {
      return lastName || firstName;
    }),
  )(contactList);
};

export const getContacts = (params = {}) => async (dispatch, getState) => {
  const isContactsPermissionGranted = await PermissionService.checkAndRequestContactsPermission();
  dispatch(setContactsPermission({ isContactsPermissionGranted }));

  if (isContactsPermissionGranted) {
    const existingContacts = R.pathOr([], ['app', 'contacts'], getState());

    if (!existingContacts.length) {
      const rawData = await Contacts.getAll();
      const contacts = await processContacts(rawData);
      dispatch(setContacts({ contacts }));
    }

    ModalsService.showSwipeableModal(modalTypes.CONTACT_LIST, params);
  }
};

export const getPromotionInfo = () => async (_, getState) => {
  const promotion = await promotionsApi.getPromotion();
  const communityName = getState().communityInfo.home_settings.template_name ?? strings.main.app_name.toLowerCase();

  if (promotion && communityName === strings.main.app_name.toLowerCase()) {
    ModalsService.showModal(modalTypes.PROMOTION, { promotion });
  }
};

export const getUsersForMap = (coordinates) => async (dispatch, getState) => {
  const communityId = getState().communityInfo.id;
  const bounds = getMapViewBounds(coordinates);

  const data = await locationsApi.getUsersForMap({
    community_id: communityId,
    bounds,
    limit: COMMON_LIMIT,
  });

  return data;
};

export const logAppEntry = (appState) => async () => {
  await accountApi.logAppEntry(appState);
};

export const setupLocalization = () => async (dispatch, getState) => {
  let appLanguage = getState().app.language;

  if (!appLanguage) {
    appLanguage = getLanguage();
    dispatch(setLanguage(appLanguage));
  }

  setLocale(appLanguage);

  const isForceRTL = appLanguage === 'he' || appLanguage === 'ar';

  I18nManager.forceRTL(isForceRTL);
  I18nManager.allowRTL(isForceRTL);

  if (isWeb) {
    if (
      (!isForceRTL && I18nManager.getConstants().isRTL) ||
      (isForceRTL && !I18nManager.getConstants().isRTL)
    ) {
      setTimeout(() => window.location.reload(), 1);
    }
  } else {
    if ((!isForceRTL && isRTL) || (isForceRTL && !isRTL)) {
      setTimeout(() => RNRestart.Restart(), 1);
    }
  }

  strings.setLanguage(appLanguage);
};

export default {
  setLanguage,
  setConnection,
  setLocationPermission,
  resetGroupState,
  fetchAppData,
  fetchAppDataOnAppStateChange,
  fetchChatsData,
  setFeedColumnStyle,
  resetState,
  processDeepLink,
  handleDeepLink,
  setLastAccessDateInCurrentSession,
  handleGeolocation,
  handleGeolocationWeb,
  getContacts,
  getPromotionInfo,
  getUsersForMap,
  logAppEntry,
  setupLocalization,
  getLocationsByLatLng,
};
