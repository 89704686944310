import axios from 'axios';
import * as R from 'ramda';
import pWaitFor from 'p-wait-for';
import * as Sentry from '@sentry/react-native';
import { store } from '../store';
import { BASE_URL } from '../../config/urls';
import { ENV } from '../../config/env';
import authToken from '../utils/authToken';
import { authOperations } from '../store/auth';
import { NetworkConnectionService } from '../services';
import delay from '../utils/delay';

const axiosDefaults = axios.defaults;
const { common } = axiosDefaults.headers;

const MAX_NETWORK_ERRORS_COUNT = 3;

const is401Error = error => R.path(['response', 'status'], error) === 401;
const isNetworkError = (error) => error?.toJSON()?.message === 'Network Error';

class Api {
  constructor(baseURL) {
    axiosDefaults.baseURL = baseURL;
    this.isRefreshingToken = false;
    common.Timezone = -new Date().getTimezoneOffset() / 60;
  }

  // eslint-disable-next-line class-methods-use-this
  setToken(token) {
    if (ENV !== 'production') {
      console.log('setToken', token);
    }
    common.Authorization = `Bearer ${token}`;
  }

  // eslint-disable-next-line class-methods-use-this
  deleteToken() {
    common.Authorization = null;
  }

  async refreshToken() {
    if (this.isRefreshingToken) {
      await this.wait();
      return;
    }

    try {
      this.isRefreshingToken = true;
      const refreshToken = await authToken.getRefreshToken();
      const newTokensPair = await authOperations.refreshToken(refreshToken);

      this.setToken(newTokensPair.access_token);
      authToken.setTokens(newTokensPair.access_token, newTokensPair.refresh_token);
      this.isRefreshingToken = false;
    } catch (error) {
      this.isRefreshingToken = false;
      console.warn('REFRESH TOKEN ERROR STATUS', R.pathOr(e, ['response', 'status'], e));
      if (is401Error(error)) store.dispatch(authOperations.logOut());
    }
  }

  async wait() {
    await pWaitFor(() => !this.isRefreshingToken);
    await pWaitFor(() => NetworkConnectionService.isOnline);
  }

  async post(url, data, failsCount = 0) {
    if (url !== 'refreshToken') await this.wait();

    return axios
      .post(url, data)
      .then(R.prop('data'))
      .catch(async error => {
        console.warn(
          'RESPONSE ERROR -->> ',
          url,
          R.pathOr(error, ['response', 'status'], error),
          R.pathOr(error, ['response', 'data'], error),
        );

        if (isNetworkError(error) && failsCount < MAX_NETWORK_ERRORS_COUNT) {
          await delay(5000);
          return this.post(url, data, failsCount + 1);
        }

        Sentry.captureException(error);
        /** Doesn't refresh token if refreshToken token request failed with 401 */
        if (url !== 'refreshToken' && is401Error(error)) {
          await this.refreshToken();
          return axios.post(url, data).then(R.prop('data'));
        }
        throw error;
      });
  }

  async put(url, data, failsCount = 0) {
    await this.wait();

    return axios
      .put(url, data)
      .then(R.prop('data'))
      .catch(async error => {
        console.warn(
          'RESPONSE ERROR -->> ',
          url,
          R.pathOr(error, ['response', 'status'], error),
          R.pathOr(error, ['response', 'data'], error),
        );

        if (isNetworkError(error) && failsCount < MAX_NETWORK_ERRORS_COUNT) {
          await delay(5000);
          return this.put(url, data, failsCount + 1);
        }

        Sentry.captureException(error);

        if (is401Error(error)) {
          await this.refreshToken();
          return axios.put(url, data).then(R.prop('data'));
        }
        throw error;
      });
  }

  async get(url, failsCount = 0, headers) {
    await this.wait();

    return axios
      .get(url, { headers })
      .then(R.prop('data'))
      .catch(async error => {
        console.warn(
          'RESPONSE ERROR -->> ',
          url,
          R.pathOr(error, ['response', 'status'], error),
          R.pathOr(error, ['response', 'data'], error),
        );

        if (isNetworkError(error) && failsCount < MAX_NETWORK_ERRORS_COUNT) {
          await delay(5000);
          return this.get(url, failsCount + 1);
        }

        Sentry.captureException(error);

        if (is401Error(error)) {
          await this.refreshToken();
          return axios.get(url).then(R.prop('data'));
        }
        throw error;
      });
  }

  async delete(url, failsCount = 0) {
    await this.wait();

    return axios
      .delete(url)
      .then(R.prop('data'))
      .catch(async error => {
        console.warn(
          'RESPONSE ERROR -->> ',
          url,
          R.pathOr(error, ['response', 'status'], error),
          R.pathOr(error, ['response', 'data'], error),
        );

        if (isNetworkError(error) && failsCount < MAX_NETWORK_ERRORS_COUNT) {
          await delay(5000);
          return this.delete(url, failsCount + 1);
        }

        Sentry.captureException(error);

        if (is401Error(error)) {
          await this.refreshToken();
          return axios.delete(url).then(R.prop('data'));
        }
        throw error;
      });
  }
}

const api = new Api(BASE_URL);

export default api;
