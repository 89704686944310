import { handleActions } from 'redux-actions';
import { mergeDeep, mergeIn } from '../../utils/stateHelper';
import types from './types';
import appTypes from '../app/types';

const initialState = {
  id: null,
  name: '',
  profile_image: '',
  nickname: '',
  phone_number: '',
  is_super_admin: false,
  is_shipper: false,
  has_email_subscription: false,
  has_email_verification: false,
  has_quick_support_access: true,
  school_info: {
    school_title: null,
    school_description: null,
  },
  is_first_login: false,

  referral_code: null,
  is_verified: false,
  is_email_verified: false,

  user_credibility_details: {
    is_verified: false,
    endorsements_count: 0,
    members_sold_to_count: 0,
  },
  isReferralRequired: false,
  isShowOnboarding: false,

  // id: 17
  // name: "Alex"
  // profile_image: "https://storage.googleapis.com/user-avatars-staging/17_1559284699294.png"
  // phone_number: "+380976592392"
  // is_super_admin: false
};

const userInfoReducer = handleActions(
  {
    [types.SET_USER_INFO]: mergeDeep(({ payload }) => ({
      ...payload,
    })),
    [types.SET_REFERRAL_CODE]: mergeDeep(({ payload }) => ({
      referral_code: payload,
    })),
    [types.VERIFY_EMAIL_SUCCESS]: mergeDeep(() => ({
      is_email_verified: true,
    })),
    [types.VERIFY_CAMPUS_EMAIL_SUCCESS]: mergeDeep(() => ({
      is_email_verified: true,
      has_email_verification: false,
    })),
    [types.SET_REFERRAL_REQUIRED]: mergeDeep(({ payload: isReferralRequired }) => ({
      isReferralRequired,
    })),
    [types.SET_ONBOARDING_STATUS]: mergeDeep(({ payload: isShowOnboarding }) => ({
      isShowOnboarding,
    })),
    [appTypes.RESET_STATE]: mergeIn({
      ...initialState,
    }),
  },
  initialState,
);

export default userInfoReducer;
