import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import Swiper from 'react-native-swiper';
import {
  Image,
  SafeAreaView,
  View,
  LayoutAnimation,
  UIManager,
  Modal,
  ActivityIndicator,
} from 'react-native';
import SplashScreen from 'react-native-splash-screen';
import { Settings } from 'react-native-fbsdk-next';
import { Text, TouchableItem } from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import strings from '../../../../localization';
import s from './styles';
import { AnalyticsService, NavigationService, PermissionService } from '../../../../services';
import screens from '../../../../navigation/screens';
import { isRTL } from '../../../../utils/rtlHelper';
import { isAndroid, isIos } from '../../../../utils/detectDevice';
import {
  onboarding_next_button,
  onboarding_start,
} from '../../../../constants/analyticsEventTypes';
import { getCurrentTheme } from '../../../../templates';
import { setOnboardingStatus } from '../../../../store/userInfo/actions';
import { communityOperations } from '../../../../store/communityInfo';
import { getUserInfo } from '../../../../store/userInfo/selectors';

const Slide = memo(({ slide }) => {
  const currentTheme = getCurrentTheme();
  return (
    <View style={s.slide}>
      <Image style={s.image} source={{ uri: slide.icon }} resizeMode="contain" />

      <Text large bold>
        {slide.title}
      </Text>

      <View style={rs.bigPaddingHorizontal}>
        <Text medium alignCenter style={[rs.marginTop, s.descriptionText]}>
          {slide.description}
          {slide.customDescription && (
            <Text bold alignCenter style={{ color: currentTheme.colors.secondary }}>
              {' ' + slide.customDescription}
            </Text>
          )}
        </Text>
      </View>
    </View>
  );
});

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

// todo very hot fix, refactor in the future
class Button extends React.PureComponent {
  componentWillMount() {
    LayoutAnimation.easeInEaseOut();
  }

  componentWillUnmount() {
    LayoutAnimation.easeInEaseOut();
  }

  render() {
    const currentTheme = getCurrentTheme();

    return (
      <TouchableItem
        style={[s.getStartedButton, { backgroundColor: currentTheme.colors.secondary }]}
        onPress={this.props.onPress}
      >
        <Text color={colors.white} medium bold>
          {strings.onboarding_slides.get_started}
        </Text>
      </TouchableItem>
    );
  }
}

const defaultIndex = isRTL && isAndroid ? 2 : 0;

const OnboardingSlides = ({ getUserCommunity, communityName, userInfo }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);
  const [isNeedToAskPermission, setNeedToAskPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isLastSlide = isRTL && isAndroid ? currentIndex === 0 : currentIndex === 2;

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    if (isIos) {
      checkIosTrackingPermission();
    }
  }, []);

  const checkIosTrackingPermission = async () => {
    const isNeedToAsk = await PermissionService.checkIosAppTrackingTransparencyPermission();
    if (isNeedToAsk) {
      setNeedToAskPermission(isNeedToAsk);
    }
  };

  const onSkip = () => {
    NavigationService.navigate(screens.SignIn);
  };

  const onGetStart = async () => {
    AnalyticsService.logEvent(onboarding_next_button);
    dispatch(setOnboardingStatus(false));

    if (isIos && isNeedToAskPermission) {
      NavigationService.replace(screens.AnalyticsPermission);
    } else if (userInfo.is_first_login) {
      return NavigationService.reset(screens.AuthorizedApplication, {
        screen: screens.AnalyticsPermission,
      });
    } else {
      NavigationService.navigate(screens.TabNavigator);
    }
  };

  const onNext = () => {
    ref.current.scrollBy(isRTL && isAndroid ? -1 : 1);
    AnalyticsService.logEvent(onboarding_start);
  };

  useEffect(() => {
    getUserCommunity();
    SplashScreen.hide();
  }, []);

  const shareitt_slides = [
    {
      id: '1',
      icon: 'onboarding_1',
      title: strings.onboarding_slides.engage_your_community,
      description: strings.onboarding_slides.engage_your_community_description,
    },
    {
      id: '2',
      icon: 'onboarding_2',
      title: strings.onboarding_slides.save_money,
      description: strings.onboarding_slides.save_money_description,
    },
    {
      id: '3',
      icon: 'onboarding_3',
      title: strings.onboarding_slides.reduce_ecological_footprint,
      description: strings.onboarding_slides.reduce_ecological_footprint_description,
    },
  ];

  const sharingood_slides = [
    {
      id: '1',
      icon: 'text_logo_sharingood',
      title: strings.onboarding_slides.share_your_talents,
      description: strings.onboarding_slides.dreams_message,
    },
    {
      id: '2',
      icon: 'logo_sharingood',
      title: strings.onboarding_slides.share_your_love,
      description: strings.onboarding_slides.action_earnings,
    },
    {
      id: '3',
      icon: 'logo_sharingood',
      title: strings.onboarding_slides.new_reality,
      description: strings.onboarding_slides.new_reality_description,
      customDescription: strings.onboarding_slides.let_make_it_happen,
    },
  ];

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (isLoading)
    return (
      <Modal visible={isLoading}>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator />
        </View>
      </Modal>
    );

  const slides =
    communityName === strings.main.app_name.toLowerCase() ? shareitt_slides : sharingood_slides;

  return (
    <SafeAreaView style={[rs.flex, rs.backgroundWhite, rs.alignCenter]}>
      <Swiper
        ref={ref}
        // style={{ flexDirection: isRTL ? 'row-reverse' : 'row' }}
        showsPagination
        index={defaultIndex}
        paginationStyle={s.pagination}
        loop={false}
        dotStyle={s.dot}
        dotColor={colors.white}
        activeDotColor={colors.text}
        onIndexChanged={setCurrentIndex}
      >
        {slides.map((slide) => (
          <Slide key={slide.id} slide={slide} />
        ))}
      </Swiper>

      {isLastSlide ? (
        <Button onPress={onGetStart} />
      ) : (
        <View style={s.buttonsContainer}>
          {/*<TouchableItem style={s.nextButton} onPress={onSkip}>*/}
          {/*  <Text color={colors.grayBorder}>{strings.common.skip}</Text>*/}
          {/*</TouchableItem>*/}
          <View />
          <TouchableItem style={s.nextButton} onPress={onNext}>
            <Text color={currentTheme.colors.secondary} bold>
              {strings.common.next}
            </Text>
          </TouchableItem>
        </View>
      )}
    </SafeAreaView>
  );
};

export default connect(
  (state) => ({
    communityName: state.communityTheme.theme,
    userInfo: getUserInfo(state),
  }),
  (dispatch) => ({
    getUserCommunity: () => dispatch(communityOperations.getUserCommunity()),
  }),
)(OnboardingSlides);
