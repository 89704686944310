import React, { Fragment, memo } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, IconButton, TouchableItem, FontIcon } from '../../../../ReusableComponents';
import { dimensions, colors, rs } from '../../../../../styles';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  contentContainer: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.grayBorder,
    borderRadius: 5,
    height: 150,
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.medium,
  },
  descriptionText: {
    lineHeight: 16,
  },
});

const TypeItem = memo(({ isSelected, icon, title, description, onPress, withMarginRight }) => {
  const currentTheme = getCurrentTheme();
  return (
    <TouchableItem
      onPress={onPress}
      disabled={isSelected}
      style={[
        s.contentContainer,
        isSelected && { borderColor: currentTheme.colors.secondary },
        withMarginRight && rs.marginRight,
      ]}
    >
      <FontIcon
        withoutRTLScale
        name={icon}
        size={32}
        color={isSelected ? currentTheme.colors.secondary : colors.gray}
      />
      <Text
        alignCenter
        semiBold
        color={isSelected ? currentTheme.colors.secondary : colors.text}
        style={[rs.paddingTop, rs.smallPaddingBottom]}
      >
        {title}
      </Text>
      <Text
        alignCenter
        xsmall
        color={isSelected ? currentTheme.colors.secondary : colors.gray}
        style={s.descriptionText}
      >
        {description}
      </Text>
    </TouchableItem>
  );
});
export default TypeItem;
