import React, { memo } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { dimensions, colors, rs } from '../../styles';
import BackButton from './NavigationButtons/BackButton';
import Text from './Text';
import T from 'prop-types';

const s = StyleSheet.create({
  contentContainer: {
    height: dimensions.headerHeight,
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  mainContainer: {
    width: '100%',
  },
  opacityBackground: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: colors.grayLighter,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayLight,
  },
  buttonContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const AnimatedOpacityHeader = memo(({ scrollY, title }) => {
  const insets = useSafeAreaInsets();
  const imageOpacity = scrollY.interpolate({
    inputRange: [0, 50, 120],
    outputRange: [0, 0, 1],
    extrapolate: 'clamp',
  });
  return (
    <View style={s.contentContainer}>
      <View style={[s.mainContainer, { height: insets.top + dimensions.headerHeight / 2 }]}>
        <Animated.View
          style={[s.opacityBackground, { opacity: imageOpacity, paddingTop: insets.top }]}
        >
          <Text large semiBold>
            {title}
          </Text>
        </Animated.View>
        <View style={[s.buttonContainer, { paddingTop: insets.top }]}>
          <BackButton />
        </View>
      </View>
    </View>
  );
});

AnimatedOpacityHeader.propTypes = {
  title: T.string,
  scrollY: T.number,
};

export default AnimatedOpacityHeader;
