import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, View } from 'react-native';
import { headerStyle } from '../../styles';
import {
  EditItem,
  ItemShipping,
  ItemPickupLocations,
  Categories,
  Brands,
  LocationView,
  Conditions,
  RefundPolicies,
  Sizes,
  Groups,
  PhotoTips,
  FAQ,
  DomesticShippingCalculation,
} from '../../components/Screens';
import screens from '../screens';
import strings from '../../localization';
import {
  BackButton,
  NavigationButton,
  NavigationIconButton,
} from '../../components/ReusableComponents';

const Stack = createStackNavigator();

const EditItemStack = () => (
  <Stack.Navigator screenOptions={{ ...headerStyle, headerRight: () => <View /> }}>
    <Stack.Screen
      name={screens.EditItem}
      component={EditItem}
      options={({ route }) => ({
        title: !!route.params?.item ?? false ? strings.items.edit_item : strings.items.new_item,
        headerRight: () =>
          !I18nManager.getConstants().isRTL ? (
            <NavigationButton
              title={
                !!route.params?.item ?? false ? strings.common.save : strings.lots.create_lot_button
              }
              disabled={!route.params?.isValidate ?? false}
              onButtonPress={route.params?.onSubmit ?? null}
            />
          ) : (
            <BackButton isCancel />
          ),
        headerLeft: () =>
          !I18nManager.getConstants().isRTL ? (
            <BackButton isCancel />
          ) : (
            <NavigationButton
              title={
                !!route.params?.item ?? false ? strings.common.save : strings.lots.create_lot_button
              }
              disabled={!route.params?.isValidate ?? false}
              onButtonPress={route.params?.onSubmit ?? null}
            />
          ),
        // headerStyle: {
        //     // flexDirection: 'row',
        //     // flexDirection: 'row-reverse',
        //   transform: [{ scaleX: -1 }],
        // },
      })}
    />
    <Stack.Screen
      name={screens.ItemShipping}
      component={ItemShipping}
      options={{
        title: strings.shipping.shipping_options,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.ItemPickupLocations}
      component={ItemPickupLocations}
      options={({ route }) => ({
        title: strings.shipping.select_addresses,
        headerLeft: () => <BackButton isCancel />,
        headerRight: () => (
          <NavigationIconButton onPress={route.params?.onAddLocation ?? null} name="plus" />
        ),
      })}
    />
    <Stack.Screen
      name={screens.Categories}
      component={Categories}
      options={{
        title: strings.lots.categories,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.Brands}
      component={Brands}
      options={{
        title: strings.lots.brands,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.Location}
      component={LocationView}
      options={{
        title: strings.other.change_location,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.Conditions}
      component={Conditions}
      options={{
        title: strings.lots.condition,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.RefundPolicies}
      component={RefundPolicies}
      options={{
        title: strings.lots.refund_policy,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.Sizes}
      component={Sizes}
      options={{
        title: strings.lots.sizes,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen
      name={screens.Groups}
      component={Groups}
      options={{
        title: strings.lots.group,
        headerLeft: () => <BackButton isCancel />,
      }}
    />
    <Stack.Screen name={screens.PhotoTips} component={PhotoTips} options={{ headerShown: false }} />
    <Stack.Screen name={screens.FAQ} component={FAQ} options={{ headerShown: false }} />
    <Stack.Screen
      name={screens.DomesticShippingCalculation}
      component={DomesticShippingCalculation}
      options={({ route }) => ({
        title: route.params?.title ?? '',
        headerLeft: () => <BackButton isClose />,
      })}
    />
  </Stack.Navigator>
);

export default EditItemStack;
