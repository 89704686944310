import React, { Fragment } from 'react';
import Contacts from 'react-native-contacts';
import { StyleSheet, View } from 'react-native';
import strings from '../../../../../localization';
import { Text, TouchableItem } from '../../../../ReusableComponents';
import { colors, dimensions } from '../../../../../styles';
import { getCurrentTheme } from '../../../../../templates';

const s = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 210,
    flexDirection: 'row',
    paddingHorizontal: dimensions.halfMedium,
    overflow: 'hidden',
    marginTop: dimensions.medium,
  },
  infoContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    borderLeftWidth: 2,
    borderLeftColor: colors.text,
    paddingLeft: dimensions.halfMedium,
    overflow: 'hidden',
  },
  textContent: {
    flex: 1,
    paddingTop: 2,
    paddingBottom: 4,
  },
  button: {
    width: '100%',
    marginVertical: dimensions.halfMedium,
    paddingTop: dimensions.halfMedium,
    alignItems: 'center',
    justifyContent: 'center',

    borderTopWidth: dimensions.hairline,
    borderTopColor: colors.gray,
  },
});

const ContactCard = ({ contact, renderFooter }) => {
  const { firstName, lastName, phoneNumbers } = contact;
  const fullName = `${firstName} ${lastName}`.trim();

  const currentTheme = getCurrentTheme();

  const onPress = async () => {
    const newContact = {
      displayName: fullName,
      givenName: firstName,
      familyName: lastName,
      phoneNumbers,
    };

    await Contacts.openContactForm(newContact);
  };

  return (
    <View style={s.wrapper}>
      <TouchableItem onPress={onPress}>
        <Fragment>
          <View style={s.container}>
            <View style={s.infoContainer}>
              <View style={s.textContent}>
                <Text medium bold color={colors.text}>
                  {fullName || strings.chats.no_contact_name_placeholder}
                </Text>
                <Text numberOfLines={1} color={colors.text}>
                  {phoneNumbers[0].number}
                </Text>
              </View>
            </View>
          </View>

          {renderFooter()}

          <View style={s.button}>
            <Text color={currentTheme.colors.secondary}>{strings.chats.save_contact}</Text>
          </View>
        </Fragment>
      </TouchableItem>
    </View>
  );
};

export default ContactCard;
