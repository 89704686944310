import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import {
  Container,
  FilterHorizontalList,
  IconButton,
  LotsList,
  WishesInfoBanner,
  Separator,
  FilterOption,
  Text,
  TouchableItem,
  FontIcon,
} from '../../../ReusableComponents';
import { lotsOperations, lotsSelectors } from '../../../../store/lots';
import { getFeedWishFilters, getFilteredGroupsForWishes } from '../../../../store/lots/selectors';
import { getCommunityGroups } from '../../../../store/groups/selectors';
import { guideOperations } from '../../../../store/guides';
import guideTypes from '../../../../constants/guidelTypes';
import { rs, headerStyle, colors } from '../../../../styles';
import { View } from 'react-native';
import strings from '../../../../localization';
import { NavigationService, FilterService } from '../../../../services';
import screens from '../../../../navigation/screens';
import s from './styles';
import { headerStyleTransparentWithBackground } from '../../../../styles/headerStyle';
import { numberWithCommas } from '../../../../utils/stringHelper';
import { useGetWindowCurrentWidth } from '../../../../utils/getWindowCurrentWidth';
import { isWeb } from '../../../../utils/detectDevice';

const columnListOptions = {
  DOUBLE: 'double',
  TRIPLE: 'triple',
};

const Wishes = ({
  doubleColumnLists,
  wishes,
  wishesCount,
  selectedFilters,
  filteredGroups,
  communityGroups,
  setFeedWishFilters,

  isLoadingWishes,
  isLoadingMoreWishes,
  isRefreshingWishes,

  refreshWishes,
  getMoreWishes,
  getWishes,
  isShowWishesBanner,
  neverShowGuideAgain,
}) => {
  const [isDoubleColumnList, setDoubleColumnList] = useState(doubleColumnLists);
  const { windowCurrentWidth } = useGetWindowCurrentWidth();

  useEffect(() => {
    getWishes();
  }, []);

  const onNeverShowWishBannerAgain = () => {
    neverShowGuideAgain({ guideType: guideTypes.WISHES_BANNER });
  };

  const onOpenSearch = () => {
    NavigationService.navigate(screens.WishesSearch);
  };

  const onOpenFilterByGroup = () => {
    NavigationService.navigate(screens.WishFilterStack);
  };

  const onDismissFilterByGroup = () => {
    setFeedWishFilters({ groupIds: [] });
  };

  const onDismissSearchTerm = () => {
    setFeedWishFilters({ searchTerm: null });
  };

  const onSelectChangeView = () => {
    const currentKey = isDoubleColumnList ? columnListOptions.DOUBLE : columnListOptions.TRIPLE;

    FilterService.show({
      isMultipleSelection: false,
      options: [
        { type: columnListOptions.TRIPLE, title: strings.filters.basic },
        { type: columnListOptions.DOUBLE, title: strings.filters.extended },
      ],
      optionKeyName: 'type',
      optionTitleName: 'title',
      selectedOptionIds: [currentKey],
      onChange: (keys) => {
        if (keys[0] !== currentKey) {
          setDoubleColumnList(keys[0] === columnListOptions.DOUBLE);
        }
      },
      filterTitle: strings.filters.change_list_view,
    });
  };

  const renderHeader = () => {
    return (
      <Fragment>
        {isShowWishesBanner && (
          <View style={[rs.smallMarginBottom]}>
            <WishesInfoBanner onClose={onNeverShowWishBannerAgain} />
          </View>
        )}
        {!isLoadingWishes && (
          <View style={[s.listHeaderContainer, isWeb && { width: windowCurrentWidth * 0.9 }]}>
            <Text color={colors.darkCyan} style={s.listHeaderText} semiBold large>
              {strings.formatString(strings.lots.wishes_found, numberWithCommas(wishesCount))}
            </Text>

            <TouchableItem onPress={onSelectChangeView} style={rs.smallMarginLeft}>
              <FontIcon
                name="view-option"
                color={colors.darkCyan}
                size={22}
                withFixedSize={false}
              />
            </TouchableItem>
          </View>
        )}
      </Fragment>
    );
  };

  return (
    <Container>
      <View style={rs.row}>
        <FilterHorizontalList withBorderBottom style={s.filterList}>
          {!!selectedFilters.searchTerm && (
            <FilterOption
              primaryButtonTitle={selectedFilters.searchTerm}
              isActive
              onPress={onOpenSearch}
              onDismissPress={onDismissSearchTerm}
              isSquare
            />
          )}

          <FilterOption
            primaryButtonTitle={
              R.isEmpty(selectedFilters.groupIds)
                ? strings.groups.all_groups
                : filteredGroups[0].group_name
            }
            onPress={onOpenFilterByGroup}
            onDismissPress={onDismissFilterByGroup}
            isActive={!R.isEmpty(selectedFilters.groupIds)}
            isSquare
          />
        </FilterHorizontalList>
      </View>

      <LotsList
        doubleColumnLists={isDoubleColumnList}
        data={wishes}
        onLoadMore={getMoreWishes}
        onRefresh={refreshWishes}
        isRefreshing={isRefreshingWishes}
        isLoadingMore={isLoadingMoreWishes}
        isLoading={isLoadingWishes}
        onEndReachedThreshold={0.3}
        ListHeaderComponent={renderHeader}
      />
    </Container>
  );
};

export default connect(
  (state) => ({
    wishes: lotsSelectors.getWishes(state),
    wishesCount: lotsSelectors.getWishesTotalCount(state),
    selectedFilters: getFeedWishFilters(state),
    communityGroups: getCommunityGroups(state),
    filteredGroups: getFilteredGroupsForWishes(state),

    isLoadingWishes: state.lots.isLoadingWishes,
    isLoadingMoreWishes: state.lots.isLoadingMoreWishes,
    isRefreshingWishes: state.lots.isRefreshingWishes,

    doubleColumnLists: state.app.doubleColumnLists,
    isShowWishesBanner: state.guides.showAgain[guideTypes.WISHES_BANNER],
  }),
  (dispatch) => ({
    getMoreWishes: () => dispatch(lotsOperations.getMoreWishes()),
    refreshWishes: () => dispatch(lotsOperations.getWishes(true)),
    getWishes: () => dispatch(lotsOperations.getWishes()),
    neverShowGuideAgain: (prop) => dispatch(guideOperations.neverShowGuideAgain(prop)),
    setFeedWishFilters: (feedLotFilters) =>
      dispatch(lotsOperations.setFeedWishFilters(feedLotFilters)),
  }),
)(Wishes);
