import React from 'react';

import { Image, View, StyleSheet, TouchableOpacity } from 'react-native';
import { Text } from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import strings from '../../../../localization';

const SubCommunityItem = ({ item, onPress }) => {
  return (
    <View style={[rs.row, rs.alignCenter, s.container, rs.justifyBetween]}>
      <View style={[rs.row, rs.alignCenter]}>
        <Image source={{ uri: item.icon }} resizeMode="contain" style={s.itemImage} />
        <Text medium semiBold style={rs.smallMarginLeft}>
          {item.name}
        </Text>
      </View>

      <TouchableOpacity style={s.boxButtonLeave} onPress={onPress}>
        <Text style={s.textLeave}>{strings.common.leave}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default SubCommunityItem;

const s = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: colors.grayLight,
    paddingBottom: 16,
  },
  itemImage: {
    width: 32,
    height: 32,
    borderRadius: 24,
    backgroundColor: colors.grayLighter,
  },
  textLeave: {
    color: colors.red,
  },
});
