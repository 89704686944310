/* eslint-disable */
import React, { Fragment } from 'react';
import styled from 'styled-components/native';
import { useSelector } from 'react-redux';
import T from 'prop-types';
import { dimensions, colors } from '../../styles';
import { getIsDefaultProfileImage } from '../../utils/userInfo';
import Text from './Text';
import AppImage from './AppImage';
import strings from '../../localization';
import { isWeb } from '../../utils/detectDevice';

const ProfileBackgroundImage = styled.ImageBackground`
  width: ${({ big }) => (big ? 68 : 44)};
  height: ${({ big }) => (big ? 68 : 44)};
  border-radius: ${({ big }) => (big ? 34 : 22)};
  margin-horizontal: ${({ marginHorizontal }) =>
    marginHorizontal ? marginHorizontal : dimensions.medium};
  border-width: 1px;
  border-color: ${colors.grayLighter};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ProfileImage = styled(AppImage)`
  width: ${({ big }) => (big ? 66 : 42)};
  height: ${({ big }) => (big ? 66 : 42)};
  border-radius: ${({ big }) => (big ? 33 : 21)};
`;

const SmallProfileBackgroundImage = styled.ImageBackground`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-horizontal: ${({ withoutMargin }) => (withoutMargin ? 0 : dimensions.medium)};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const SmallProfileImage = styled(AppImage)`
  width: 40;
  height: 40;
  border-radius: 20;
`;

const EditLabelContainer = styled.View`
  z-index: 10;
  position: absolute;
  height: 20px;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  background-color: ${colors.grayDarker};
  opacity: 0.7;
`;

const UserAvatar = ({
  uri,
  small,
  withoutMargin,
  big,
  withEditLabel,
  withLogoPlaceholder,
  marginHorizontal,
  isSupport,
}) => {
  const isDefaultProfileImage = getIsDefaultProfileImage(uri);
  const theme = useSelector((state) => state.communityTheme.theme);

  const renderImageUri = () => {
    if (theme === strings.main.app_name_sharingood.toLowerCase() && isSupport) {
      return 'logo_sharingood';
    } else {
      return uri;
    }
  };

  const getBackgroundImage = () => {
    if (isDefaultProfileImage) {
      return uri;
    }

    if (!isWeb && theme === strings.main.app_name_sharingood.toLowerCase()) {
      return 'logo_sharingood';
    }

    if (isWeb) {
      const logo = require('../../assets/web/images/logo.png');
      const user_avatar_placeholder = require('../../assets/web/images/user_avatar_placeholder.png');
      return withLogoPlaceholder ? logo : user_avatar_placeholder;
    } else {
      return withLogoPlaceholder ? 'logo' : 'user_avatar_placeholder';
    }
  };

  return (
    <Fragment>
      {small ? (
        <SmallProfileBackgroundImage
          withoutMargin={withoutMargin}
          source={isWeb ? getBackgroundImage() : { uri: getBackgroundImage() }}
          resizeMode="cover"
          imageStyle={{ borderRadius: 20 }}
        >
          {!!uri && !isDefaultProfileImage && (
            <SmallProfileImage source={isWeb ? uri : { uri }} resizeMode="cover" />
          )}
        </SmallProfileBackgroundImage>
      ) : (
        <ProfileBackgroundImage
          big={big}
          marginHorizontal={marginHorizontal}
          source={isWeb ? getBackgroundImage() : { uri: getBackgroundImage() }}
          resizeMode="cover"
          imageStyle={{ borderRadius: big ? 34 : 22 }}
        >
          {!!uri && !isDefaultProfileImage && (
            <ProfileImage
              big={big}
              source={isWeb ? uri : { uri: renderImageUri() }}
              resizeMode="cover"
            />
          )}
          {withEditLabel && (
            <EditLabelContainer>
              <Text xsmall bold color={colors.white}>
                {strings.common.edit}
              </Text>
            </EditLabelContainer>
          )}
        </ProfileBackgroundImage>
      )}
    </Fragment>
  );
};

UserAvatar.propTypes = {
  uri: T.string,
  big: T.bool,
  withEditLabel: T.bool,
};

export default UserAvatar;
