import colors from '../../../../../../../styles/colors';
import { StyleSheet } from 'react-native';
import { dimensions } from '../../../../../../../styles';

export default StyleSheet.create({
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: dimensions.halfMedium + dimensions.halfMedium / 2,
  },
  itemContainer: {
    flex: 1,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: colors.gray,
    backgroundColor: colors.white,
    marginHorizontal: dimensions.halfMedium / 2,
    alignItems: 'center',
    justifyContent: 'center',
  }
});
