import { StyleSheet } from 'react-native';
import { dimensions, fontSizes, colors } from '../../../../../styles';
import { isWeb } from '../../../../../utils/detectDevice';

export default StyleSheet.create({
  contentContainer: {
    marginTop: dimensions.medium,
    borderRadius: dimensions.borderRadius,
    backgroundColor: colors.white,
  },
  itemImage: {
    height: isWeb ? 126 : 96,
    width: isWeb ? 126 : 96,
    borderTopLeftRadius: dimensions.borderRadius,
  },
  userImage: {
    height: 28,
    width: 28,
    borderRadius: 14,
    marginRight: dimensions.halfMedium,
  },
  priceText: {
    marginHorizontal: 4,
  },
  topContainer: {
    flexDirection: 'row',
    marginBottom: dimensions.medium,
    borderBottomWidth: dimensions.hairline,
    borderBottomColor: colors.grayBorder,
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  nameContainer: {
    marginLeft: dimensions.medium,
    marginTop: dimensions.medium,
  },
  infoContainer: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: dimensions.medium,
  },
  infoTitleText: {
    width: 60 + dimensions.medium,
  },
  buttonsContainer: {
    flexDirection: 'row',
    width: '100%',
    padding: dimensions.medium,
  },
  priceImage: {
    height: isWeb ? 20 : 14,
    width: isWeb ? 20 : 14,
  },
  avatarContainer: {
    backgroundColor: colors.transparent,
  },
});
