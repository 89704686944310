import React, { Fragment, memo } from 'react';
import * as R from 'ramda';
import T from 'prop-types';
import s from './styles';
import { colors } from '../../../../styles';
import TouchableItem from '../../TouchableItem';
import Icon from '../../Icon';
import Text from '../../Text';
import { getCurrentTheme } from '../../../../templates';

const FilterOptions = memo(
  ({
    options,
    optionKeyName = 'id',
    optionTitleName = 'title',
    selectedOptionIds,
    onChange,
    isSquare,
  }) => {
    const currentTheme = getCurrentTheme();
    return (
      <Fragment>
        {options.map((option) => {
          const isActive = selectedOptionIds.includes(option[optionKeyName]);
          if (isActive) {
            return (
              <TouchableItem
                key={R.prop(optionTitleName, option)}
                style={[
                  s.buttonContainer,
                  isSquare && s.squareContainer,
                  {
                    backgroundColor: currentTheme.colors.secondary,
                    borderColor: currentTheme.colors.secondary,
                  },
                ]}
                onPress={() =>
                  onChange(selectedOptionIds.filter((el) => option[optionKeyName] !== el))
                }
              >
                <Text color={colors.white}>{R.prop(optionTitleName, option)}</Text>
                <Icon name="x" size={16} color={colors.white} style={s.icon} />
              </TouchableItem>
            );
          }
          return null;
        })}
      </Fragment>
    );
  },
);

FilterOptions.propTypes = {
  options: T.array,
  optionKeyName: T.string,
  optionTitleName: T.string,
  selectedOptionIds: T.array,
  isSquare: T.bool,
  onChange: T.func,
};

FilterOptions.defaultProps = {
  isSquare: false,
};

export default FilterOptions;
