import React from 'react';
import { Image, View, StyleSheet } from 'react-native';
import Text from '../../Text';
import { colors } from '../../../../styles';
import strings from '../../../../localization';
import { SafeAreaView } from 'react-native-safe-area-context';

const BannerMaintenance = () => {
  return (
    <View style={styles.container}>
      <View style={styles.elipse1} />
      <View style={styles.elipse2} />
      <SafeAreaView style={styles.content}>
        <Image source={{ uri: 'maintenance' }} style={styles.image} />
        <Text bold style={styles.text}>
          {strings.home.improve_in_progress}
        </Text>
        <Text style={styles.description}>{strings.home.improve_in_progress_description}</Text>
      </SafeAreaView>
    </View>
  );
};

export default BannerMaintenance;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 20,
    textAlign: 'center',
    paddingTop: 20,
  },
  description: {
    fontSize: 16,
    textAlign: 'center',
    paddingTop: 20,
    paddingHorizontal: 20,
  },
  image: {
    width: 200,
    height: 200,
    alignSelf: 'center',
  },
  elipse1: {
    position: 'absolute',
    top: -150,
    left: -150,
    right: 0,
    width: 300,
    height: 300,
    borderRadius: 150,
    backgroundColor: '#F6F6F6',
  },
  elipse2: {
    position: 'absolute',
    bottom: -150,
    left: 250,
    right: 0,
    width: 300,
    height: 300,
    borderRadius: 150,
    backgroundColor: '#F6F6F6',
  },
});
