/* eslint-disable */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  TouchableOpacity,
  LayoutAnimation,
  UIManager,
  StyleSheet,
  View,
  ActivityIndicator,
  Text,
} from 'react-native';
import { connect } from 'react-redux';

import { Input } from './Input';
import { Icon } from './Icon';
import renderNode from './renderNode';
import { colors, dimensions, fontSizes } from '../../../styles';
import strings from '../../../localization';
import { getIsRTLString } from '../../../utils/rtlHelper';
import { setFeedLotFilters } from '../../../store/lots/operations';
import {
  useGetWindowCurrentWidthForClassComponent
} from "../../../utils/getWindowCurrentWidth";

const IOS_GRAY = '#8E8D92';
const defaultSearchIcon = {
  type: 'ionicon',
  size: 20,
  name: 'ios-search',
  color: IOS_GRAY,
};
const defaultClearIcon = {
  type: 'ionicon',
  name: 'ios-close-circle',
  size: 18,
  color: IOS_GRAY,
};

class SearchBar extends Component {
  constructor(props) {
    super(props);
    const { value } = props;

    this.state = {
      hasFocus: false,
      isEmpty: value ? value === '' : true,
      cancelButtonWidth: null,
    };
  }

  static getDerivedStateFromProps({ value }, state) {
    return {
      ...state,
      isEmpty: value ? value === '' : true,
    };
  }

  getInputAlignment = (value, placeholder) => {
    const text = value || placeholder;
    const isRTLText = getIsRTLString(text);

    return isRTLText ? 'right' : 'left';
  };

  focus = () => {
    this.input.focus();
  };

  blur = () => {
    this.input.blur();
  };

  clear = () => {
    this.input.clear();
    this.onChangeText('');
    this.props.onClear();

    if (this.props.updateOnClear) {
      this.props.resetSearchFilters();
    }
  };

  cancel = () => {
    this.onChangeText('');

    if (this.props.showCancel) {
      UIManager.configureNextLayoutAnimation && LayoutAnimation.easeInEaseOut();
      this.setState({ hasFocus: false });
    }

    setTimeout(() => {
      this.blur();
      this.props.onCancel();
    }, 0);
  };

  onFocus = () => {
    this.props.onFocus();
    UIManager.configureNextLayoutAnimation && LayoutAnimation.easeInEaseOut();

    this.setState({
      hasFocus: true,
      isEmpty: this.props.value === '',
    });
  };

  onBlur = () => {
    this.props.onBlur();
    UIManager.configureNextLayoutAnimation && LayoutAnimation.easeInEaseOut();

    if (!this.props.showCancel) {
      this.setState({
        hasFocus: false,
      });
    }
  };

  onChangeText = (text) => {
    this.props.onChangeText(text);
    this.setState({ isEmpty: text === '' });
  };

  render() {
    const {
      value,
      placeholder,
      cancelButtonProps,
      cancelButtonTitle,
      clearIcon,
      containerStyle,
      leftIconContainerStyle,
      rightIconContainerStyle,
      inputContainerStyle,
      inputStyle,
      placeholderTextColor,
      showLoading,
      loadingProps,
      searchIcon,
      showCancel,
      alwaysShowCancel,
      windowCurrentWidth,
      isMobileWindow,
      ...attributes
    } = this.props;
    const { hasFocus, isEmpty } = this.state;

    const { style: loadingStyle, ...otherLoadingProps } = loadingProps;

    const {
      buttonStyle,
      buttonTextStyle,
      color: buttonColor,
      disabled: buttonDisabled,
      buttonDisabledStyle,
      buttonDisabledTextStyle,
      ...otherCancelButtonProps
    } = cancelButtonProps;

    return (
      <View style={StyleSheet.flatten([styles.container, {width: '95%'}])}>
        <Input
          testID="searchInput"
          {...attributes}
          value={value}
          placeholder={placeholder}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChangeText={this.onChangeText}
          ref={(input) => {
            this.input = input;
          }}
          inputStyle={StyleSheet.flatten([styles.input, inputStyle])}
          containerStyle={{
            paddingHorizontal: 0,
          }}
          inputContainerStyle={StyleSheet.flatten([
            styles.inputContainer,
            (alwaysShowCancel || hasFocus) && { marginRight: this.state.cancelButtonWidth },
            inputContainerStyle,
          ])}
          leftIcon={renderNode(Icon, searchIcon, defaultSearchIcon)}
          leftIconContainerStyle={StyleSheet.flatten([
            styles.leftIconContainerStyle,
            leftIconContainerStyle,
          ])}
          placeholderTextColor={placeholderTextColor}
          rightIcon={
            <View style={{ flexDirection: 'row' }}>
              {showLoading && (
                <ActivityIndicator
                  key="loading"
                  style={StyleSheet.flatten([{ marginRight: 5 }, loadingStyle])}
                  {...otherLoadingProps}
                />
              )}
              {!isEmpty &&
                renderNode(Icon, clearIcon, {
                  ...defaultClearIcon,
                  key: 'cancel',
                  onPress: this.clear,
                })}
            </View>
          }
          rightIconContainerStyle={StyleSheet.flatten([
            styles.rightIconContainerStyle,
            rightIconContainerStyle,
          ])}
          textAlign={this.getInputAlignment(value, placeholder)}
        />

        <View
          style={StyleSheet.flatten([
            styles.cancelButtonContainer,
            {
              opacity: this.state.cancelButtonWidth === null ? 0 : 1,
              right: hasFocus || alwaysShowCancel ? 0 : -this.state.cancelButtonWidth,
            },
          ])}
          onLayout={(event) => this.setState({ cancelButtonWidth: event.nativeEvent.layout.width })}
        >
          <TouchableOpacity
            accessibilityRole="button"
            onPress={this.cancel}
            disabled={buttonDisabled}
            {...otherCancelButtonProps}
          >
            <View style={[buttonStyle, buttonDisabled && buttonDisabledStyle]}>
              <Text
                style={[
                  styles.buttonTextStyle,
                  buttonColor && { color: buttonColor },
                  buttonTextStyle,
                  buttonDisabled && (buttonDisabledTextStyle || styles.buttonTextDisabled),
                ]}
                disabled={buttonDisabled}
              >
                {strings.common.cancel}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

SearchBar.propTypes = {
  value: PropTypes.string,
  cancelButtonProps: PropTypes.object,
  cancelButtonTitle: PropTypes.string,
  clearIcon: PropTypes.any,
  searchIcon: PropTypes.any,
  loadingProps: PropTypes.object,
  showLoading: PropTypes.bool,
  onClear: PropTypes.func,
  onCancel: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeText: PropTypes.func,
  containerStyle: PropTypes.any,
  leftIconContainerStyle: PropTypes.any,
  rightIconContainerStyle: PropTypes.any,
  inputContainerStyle: PropTypes.any,
  placeholderTextColor: PropTypes.string,
  showCancel: PropTypes.bool,
  updateOnClear: PropTypes.bool,
  resetSearchFilters: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  value: '',
  cancelButtonTitle: strings.common.cancel,
  loadingProps: {},
  cancelButtonProps: {},
  showLoading: false,
  onClear: () => null,
  onCancel: () => null,
  onFocus: () => null,
  onBlur: () => null,
  onChangeText: () => null,
  placeholderTextColor: IOS_GRAY,
  searchIcon: defaultSearchIcon,
  clearIcon: defaultClearIcon,
  showCancel: false,
  updateOnClear: false,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    overflow: 'hidden',
    alignItems: 'center',
    alignSelf: 'center',
  },
  input: {
    marginLeft: 10,
    overflow: 'hidden',
    outlineWidth: 0,
    textAlign: 'left'
  },
  inputContainer: {
    borderBottomWidth: 0,
    backgroundColor: '#EFF1F2',
    borderRadius: 10,
    minHeight: 36,
    marginLeft: 8,
    marginRight: 8,
    flexDirection: 'row',
  },
  rightIconContainerStyle: {
    marginRight: 8,
  },
  leftIconContainerStyle: {
    marginLeft: 10,
  },
  buttonTextStyle: {
    color: colors.blueLight,
    textAlign: 'center',
    padding: 10,
    fontSize: fontSizes.medium,
  },
  buttonTextDisabled: {
    color: '#cdcdcd',
  },
  cancelButtonContainer: {
    position: 'absolute',
  },
});

export default connect(null, (dispatch) => ({
  resetSearchFilters: () => dispatch(setFeedLotFilters({ searchTerm: null })),
}))(useGetWindowCurrentWidthForClassComponent(SearchBar));
