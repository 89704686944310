import React from 'react';
import T from 'prop-types';
import { StyleSheet, View } from 'react-native';
import MapView from 'react-native-maps';
import { colors, dimensions, rs } from '../../styles';
import locationsTypes from '../../constants/locationsTypes';
import FontIcon from './FontIcon';
import { getCurrentTheme } from '../../templates';

const s = StyleSheet.create({
  marker: {
    width: 32,
    height: 32,
    borderRadius: 6,
    backgroundColor: colors.white,
    paddingHorizontal: dimensions.halfMedium,
    paddingVertical: dimensions.halfLarge,
    justifyContent: 'center',
    alignItems: 'center',
    ...rs.shadow,
  },
  markerActive: {
    backgroundColor: colors.secondary,
  },
  tail: {
    width: 0,
    height: 0,
    zIndex: 2,
    top: -1,
    backgroundColor: colors.transparent,
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderTopWidth: 6,
    borderLeftColor: colors.transparent,
    borderRightColor: colors.transparent,
    borderTopColor: colors.white,
    alignSelf: 'center',
    ...rs.shadow,
  },
  tailActive: {
    borderTopColor: colors.secondary,
  },
});

const MapMarker = ({ location, isActive, isSpecial, onPress }) => {
  const currentTheme = getCurrentTheme();

  const iconProps =
    location.type === locationsTypes.SHIPPER
      ? { name: 'scooter', withoutRTLScale: false }
      : { name: 'Shipping_personal-pickup', withoutRTLScale: true };
  const iconColor = isActive ? colors.white : colors.secondary;

  const getZIndex = () => {
    if (isSpecial) {
      return 1000;
    }

    return isActive ? 1001 : 0;
  };

  return (
    <MapView.Marker
      key={location.id}
      coordinate={{
        latitude: location.latitude,
        longitude: location.longitude,
      }}
      style={{ zIndex: getZIndex() }}
      onPress={onPress}
      tracksViewChanges={false}
    >
      <View
        style={[
          s.marker,
          isActive && !isSpecial && s.markerActive,
          isSpecial && { backgroundColor: currentTheme.colors.secondary },
        ]}
      >
        {isSpecial ? (
          <FontIcon name="Settings_Location" size={24} color={colors.white} withoutRTLScale />
        ) : (
          <FontIcon size={24} color={iconColor} {...iconProps} />
        )}
      </View>
      <View
        style={[
          s.tail,
          isActive && !isSpecial && s.tailActive,
          isSpecial && { borderTopColor: currentTheme.colors.secondary },
        ]}
      />
    </MapView.Marker>
  );
};

MapMarker.propTypes = {
  location: T.shape({
    id: T.number,
    latitude: T.number,
    longitude: T.number,
    type: T.string,
  }).isRequired,
  isActive: T.bool,
  isSpecial: T.bool,
  onPress: T.func,
};

MapMarker.defaultProps = {
  isActive: false,
  isSpecial: false,
  onPress: () => {},
};

export default MapMarker;
