import React, { Fragment, useEffect, useState } from 'react';
import { View, Linking, I18nManager } from 'react-native';
import * as RNLocalize from 'react-native-localize';
import { connect } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FeatherIcon from 'react-native-vector-icons/Feather';
import T from 'prop-types';
import { authOperations } from '../../../../store/auth';
import s from './styles';
import { colors, dimensions, fontSizes, reusableStyles, rs } from '../../../../styles';
import {
  Container,
  Text,
  TouchableItem,
  Input,
  Image,
  ScreenHeader,
} from '../../../ReusableComponents';

import strings from '../../../../localization';
import SelectCountryModal from './SelectCountryModal';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import { getPhoneNumberCountry } from '../../../../utils/localizationHelper';
import {
  PRIVACY_POLICY_URL_BR,
  PRIVACY_POLICY_URL_EN,
  PRIVACY_POLICY_URL_HE,
  TERMS_AND_CONDITIONS_URL_BR,
  TERMS_AND_CONDITIONS_URL_EN,
  TERMS_AND_CONDITIONS_URL_HE,
} from '../../../../api/urls';
import { useGetWindowCurrentWidth } from '../../../../utils/getWindowCurrentWidth';
import NavigationButton from '../../../ReusableComponents/NavigationButtons';
import StoreImages from './components/StoreImages';
import { getCurrentTheme } from '../../../../templates';

const getTermsAndConditionsUrl = (languageCode) => {
  switch (languageCode) {
    case 'pt':
      return TERMS_AND_CONDITIONS_URL_BR;
    case 'he':
      return TERMS_AND_CONDITIONS_URL_HE;
    default:
      return TERMS_AND_CONDITIONS_URL_EN;
  }
};

const getPrivacyPolicyUrl = (localeCode) => {
  switch (localeCode) {
    case 'pt':
      return PRIVACY_POLICY_URL_BR;
    case 'he':
      return PRIVACY_POLICY_URL_HE;
    default:
      return PRIVACY_POLICY_URL_EN;
  }
};

export const getPhoneImages = (localeCode) => {
  switch (localeCode) {
    case 'pt':
      return 'brazil_sign_in';
    case 'he':
      return 'heb_sign_in';
    case 'de':
      return 'german_sign_in';
    default:
      return 'eng_sign_in';
  }
};

const Login = ({ navigation, signInWithPhoneNumber, route }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phoneNumberInternationalFormat, setPhoneNumberInternationalFormat] = useState(null);
  const [isChecked, setChecked] = useState(true);
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(false);
  const languageCode = RNLocalize.getLocales()[0].languageCode;
  const isRTL = I18nManager.getConstants().isRTL;
  const { isMobileWindow, windowCurrentWidth, windowCurrentHeight } = useGetWindowCurrentWidth();

  const isDisabledNext = !isChecked || !isValidPhoneNumber;

  const currentTheme = getCurrentTheme();

  const defaultCountry = getPhoneNumberCountry();
  const [country, setCountry] = useState(defaultCountry);

  useEffect(() => {
    navigation.setParams({
      isDisabledNext: isDisabledNext,
    });
  }, [isDisabledNext]);

  useEffect(() => {
    navigation.setParams({
      nextClicked: nextClicked,
    });
  }, [country, phoneNumberInternationalFormat]);

  useEffect(() => {
    onNumberChange(phoneNumber);
  }, [country]);

  const nextClicked = () => {
    const countryCode = country.dialCode;
    navigation.setParams({ isDisabledNext: true });
    // AnalyticsService.logEvent(analyticsEventTypes.onboarding_confirm_phone_number);
    NavigationService.navigate(screens.CodeVerification, {
      phoneNumber: phoneNumberInternationalFormat,
      onSendCodeAgain: () => signInWithPhoneNumber(phoneNumberInternationalFormat, countryCode),
    });

    signInWithPhoneNumber(phoneNumberInternationalFormat, countryCode);
  };

  const onNumberChange = (value) => {
    setPhoneNumber(value);

    const numberWithCode = `+${country.dialCode}${value}`;

    const parsedPhoneNumber = parsePhoneNumberFromString(numberWithCode);

    if (parsedPhoneNumber) {
      const formattedPhoneNumber = parsedPhoneNumber.formatInternational().replace(/ /g, '');
      setPhoneNumberInternationalFormat(formattedPhoneNumber);
      const isNewNumberValid = parsedPhoneNumber.isValid();
      if (isValidPhoneNumber !== isNewNumberValid) {
        setValidPhoneNumber(isNewNumberValid);
      }
    }
  };

  const onShowModal = () => {
    setModalVisible(true);
  };

  const onHideModal = () => {
    setModalVisible(false);
  };

  const onOpenPrivacy = () => {
    Linking.openURL(getPrivacyPolicyUrl(languageCode));
  };

  const onOpenTerms = () => {
    Linking.openURL(getTermsAndConditionsUrl(languageCode));
  };

  return isMobileWindow ? (
    <Container>
      <ScreenHeader
        submitButton={() => (
          <NavigationButton
            title={strings.common.next}
            disabled={isDisabledNext ?? false}
            onButtonPress={nextClicked ?? null}
          />
        )}
        withoutCancel
        withoutBorder
      />
      <View style={[rs.flex, rs.webContainer]}>
        <Container style={[rs.alignCenter]}>
          <Image style={s.image} source={{ uri: 'phone' }} resizeMode="contain" />

          <Text medium alignCenter style={s.title}>
            {strings.login.enter_phone_number}
          </Text>

          <TouchableItem useOpacity={false} style={s.selectCountryButton} onPress={onShowModal}>
            <Fragment>
              <Text medium color={currentTheme.colors.secondaryLight}>
                {country.name}
              </Text>
              <FeatherIcon
                name="chevron-right"
                color="#BFBFC1"
                style={{ transform: [{ scaleX: isRTL ? -1 : 1 }] }}
                size={dimensions.iconSize}
              />
            </Fragment>
          </TouchableItem>

          <View style={s.inputContainer}>
            {!isRTL && (
              <View style={s.numberCodeContainer}>
                <Text large color={colors.black} style={rs.smallPaddingHorizontal}>
                  +{country.dialCode}
                </Text>
              </View>
            )}
            <Input
              style={[
                s.input,
                {
                  fontSize: fontSizes.large,
                  borderRightWidth: isRTL ? 1 : 0,
                  borderLeftWidth: isRTL ? 0 : 1,
                  textAlign: isRTL ? 'right' : 'left',
                },
              ]}
              dir={isRTL ? 'rtl' : 'ltr'}
              autoFocus
              keyboardType="phone-pad"
              placeholder={strings.login.your_phone_number}
              maxLength={11}
              onChangeText={onNumberChange}
              textAlign="left"
              value={phoneNumber}
              onBlur={() => window.scrollTo(0, 0)}
            />

            {isRTL && (
              <View style={s.numberCodeContainer}>
                <Text large color={colors.black} style={[rs.smallPaddingRight, rs.paddingLeft]}>
                  +{country.dialCode}
                </Text>
              </View>
            )}
          </View>

          <SelectCountryModal
            modalVisible={modalVisible}
            onHideModal={onHideModal}
            onChange={setCountry}
          />
        </Container>

        <View style={s.bottomContainer}>
          <Text xsmall>{strings.login.by_signing_up_you_agree}</Text>
          <View style={rs.row}>
            <TouchableItem onPress={onOpenTerms}>
              <Text xsmall color={currentTheme.colors.secondary}>
                {strings.login.terms_and_conditions}
              </Text>
            </TouchableItem>
            <Text xsmall> {strings.login.and} </Text>
            <TouchableItem onPress={onOpenPrivacy}>
              <Text xsmall color={currentTheme.colors.secondary}>
                {strings.login.privacy_policy}
              </Text>
            </TouchableItem>
          </View>
        </View>
      </View>
    </Container>
  ) : (
    <Container style={[rs.alignCenter, rs.justifyCenter]}>
      <View style={[rs.row, rs.justifyCenter, rs.alignCenter, { height: 600 }]}>
        <View>
          <Image
            style={[s.phoneImage, { width: windowCurrentWidth / 1.5, height: windowCurrentHeight }]}
            source={{ uri: getPhoneImages(languageCode) }}
            resizeMode="contain"
          />
        </View>
        <View style={[rs.alignCenter, rs.justifyCenter, rs.bigMarginHorizontal, rs.marginTop]}>
          <View style={s.shadow}>
            <View style={[rs.flex, rs.webContainer, rs.verySmallPaddingHorizontal]}>
              <ScreenHeader
                submitButton={() => (
                  <NavigationButton
                    title={strings.common.next}
                    disabled={isDisabledNext ?? false}
                    onButtonPress={nextClicked ?? null}
                    style={rs.smallPaddingHorizontal}
                  />
                )}
                withoutCancel
                withoutBorder
              />
              <View style={[rs.alignCenter, { height: '75%' }]}>
                <Image style={s.imageWeb} source={{ uri: 'phone' }} resizeMode="contain" />

                <Text xsmall alignCenter style={s.titleWeb}>
                  {strings.login.enter_phone_number}
                </Text>

                <TouchableItem
                  useOpacity={false}
                  style={s.selectCountryButton}
                  onPress={onShowModal}
                >
                  <Fragment>
                    <Text small color={currentTheme.colors.secondaryLight}>
                      {country.name}
                    </Text>
                    <FeatherIcon
                      name="chevron-right"
                      color="#BFBFC1"
                      style={{ transform: [{ scaleX: isRTL ? -1 : 1 }] }}
                      size={dimensions.iconSize}
                    />
                  </Fragment>
                </TouchableItem>

                <View
                  style={[
                    s.inputContainer,
                    {
                      paddingHorizontal: 0,
                    },
                  ]}
                >
                  {!isRTL && (
                    <View style={s.numberCodeContainer}>
                      <Text small color={colors.black} style={rs.smallPaddingHorizontal}>
                        +{country.dialCode}
                      </Text>
                    </View>
                  )}
                  <Input
                    style={[
                      s.input,
                      {
                        fontSize: fontSizes.small,
                        borderRightWidth: isRTL ? 1 : 0,
                        borderLeftWidth: isRTL ? 0 : 1,
                        textAlign: isRTL ? 'right' : 'left',
                      },
                    ]}
                    autoFocus
                    keyboardType="phone-pad"
                    placeholder={strings.login.your_phone_number}
                    maxLength={11}
                    onChangeText={onNumberChange}
                    value={phoneNumber}
                    onBlur={() => window.scrollTo(0, 0)}
                  />

                  {isRTL && (
                    <View style={s.numberCodeContainer}>
                      <Text
                        small
                        color={colors.black}
                        style={[rs.smallPaddingRight, rs.paddingLeft]}
                      >
                        +{country.dialCode}
                      </Text>
                    </View>
                  )}
                </View>

                <SelectCountryModal
                  modalVisible={modalVisible}
                  onHideModal={onHideModal}
                  onChange={setCountry}
                />
              </View>

              <View style={s.bottomContainer}>
                <Text xsmall>{strings.login.by_signing_up_you_agree}</Text>
                <View style={rs.row}>
                  <TouchableItem onPress={onOpenTerms}>
                    <Text xsmall color={currentTheme.colors.secondary}>
                      {strings.login.terms_and_conditions}
                    </Text>
                  </TouchableItem>
                  <Text xsmall> {strings.login.and} </Text>
                  <TouchableItem onPress={onOpenPrivacy}>
                    <Text xsmall color={currentTheme.colors.secondary}>
                      {strings.login.privacy_policy}
                    </Text>
                  </TouchableItem>
                </View>
              </View>
            </View>
          </View>
          <StoreImages />
        </View>
      </View>
    </Container>
  );
};

Login.propTypes = {
  signInWithPhoneNumber: T.func.isRequired,
};

export default connect(null, (dispatch) => ({
  signInWithPhoneNumber: (phoneNumber, countryCode) =>
    dispatch(authOperations.signInWithPhoneNumber(phoneNumber, countryCode)),
}))(Login);
