import React, { Fragment, Component, useEffect } from 'react';
import T from 'prop-types';
import * as R from 'ramda';
import { ImageBackground, StyleSheet } from 'react-native';
import {
  View,
  Text,
  UserFollowersButtons,
  UserAvatarWithViewer,
  Separator,
  Image,
} from '../../../../../ReusableComponents';
import { colors, dimensions, rs } from '../../../../../../styles';
import strings from '../../../../../../localization';
import { connect, useSelector } from 'react-redux';
import {
  getIsUserDefaultProfileImage,
  getUserInfo,
} from '../../../../../../store/userInfo/selectors';
import { numberWithCommas } from '../../../../../../utils/stringHelper';
import { isIos, isWeb } from '../../../../../../utils/detectDevice';
import UserInfo from '../../../../UserProfileGroup/UserProfileScreen/components/UserInfo';
import { userLocationsSelectors } from '../../../../../../store/userLocations';
import { NavigationService } from '../../../../../../services';
import screens from '../../../../../../navigation/screens';
import { getCurrentTheme } from '../../../../../../templates';

const BANNER_WIDTH = dimensions.width - dimensions.medium * 6;
const rewards_wallet_background = require('../../../../../../assets/web/images/rewards_wallet_background.png');
const wallet_background_big = require('../../../../../../assets/web/images/wallet_background_big.png');
const wallet_background = require('../../../../../../assets/web/images/wallet_background.png');

const s = StyleSheet.create({
  contentContainer: {},
  userInfoContentContainer: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    paddingBottom: 0,
    backgroundColor: colors.grayLighter,
  },
  walletsContainer: {
    paddingVertical: dimensions.doubleMedium,
    backgroundColor: colors.white,
    zIndex: -1,
    flexDirection: 'row',
    paddingHorizontal: dimensions.halfMedium,
  },
  backgroundContainer: {
    alignItems: 'center',
    flex: 1,
    paddingHorizontal: dimensions.halfMedium,

    ...rs.shadow,
  },
  walletImage: {
    borderRadius: 5,
  },
  balanceBackground: {
    height: 95,
    width: '100%',
    justifyContent: 'flex-end',
  },
  balanceContainer: {
    paddingHorizontal: dimensions.medium,
    paddingVertical: dimensions.medium,
  },
  balanceText: {
    lineHeight: isIos ? 26 : undefined,
  },

  logoImage: {
    height: 60,
    width: 115,
    position: 'absolute',
    right: dimensions.medium,
    top: -5,
  },
});

const UserInfoHeader = ({ userInfo, wallet, rewardWallet, userLocations }) => {
  const {
    id,
    followers_count,
    followings_count,
    member_since,
    last_seen_at,
    profile_image,
    name,
    is_shipper,
    nickname,
    user_credibility_details,
    school_info,
    bio,
  } = userInfo;

  const currentTheme = getCurrentTheme();
  const communityName = useSelector((state) => state.communityTheme.theme);

  const { is_verified, endorsements_count, members_sold_to_count } = user_credibility_details;

  const onOpenEndorsements = () => {
    NavigationService.navigate(screens.Endorsements, {
      userId: id,
      endorsementsCount: endorsements_count,
    });
  };

  const onOpenAllBuyers = () => {
    NavigationService.navigate(screens.AllBuyers);
  };

  return (
    <Fragment>
      <View style={s.contentContainer}>
        <UserInfo
          profileImage={profile_image}
          name={name}
          bio={bio}
          nickname={nickname}
          isShipper={is_shipper}
          memberSince={member_since}
          selectedUserId={id}
          isCurrentUser
          followersCount={followers_count}
          followingsCount={followings_count}
          userLocations={userLocations}
          onOpenEndorsements={onOpenEndorsements}
          onOpenAllBuyers={onOpenAllBuyers}
          userCredibilityDetails={user_credibility_details}
          contentContainerStyle={s.userInfoContentContainer}
          schoolInfo={school_info}
        />

        <View style={s.walletsContainer}>
          <View style={s.backgroundContainer}>
            <ImageBackground
              style={s.balanceBackground}
              imageStyle={s.walletImage}
              source={
                isWeb
                  ? R.isEmpty(rewardWallet)
                    ? wallet_background_big
                    : wallet_background
                  : {
                      uri: R.isEmpty(rewardWallet)
                        ? communityName === strings.main.app_name.toLowerCase()
                          ? 'wallet_background_big'
                          : 'wallet_background_big_sharingood'
                        : 'wallet_background',
                    }
              }
              resizeMode="cover"
            >
              <View style={s.balanceContainer}>
                <Text color={colors.white} semiBold>
                  {strings.bank.balance}
                </Text>
                <Text medium bold color={colors.white} style={s.balanceText}>
                  {numberWithCommas(Number(wallet.balance_amount) - Number(wallet.hold_balance))}
                </Text>
              </View>
              <Image
                style={s.logoImage}
                source={{
                  uri:
                    communityName === strings.main.app_name.toLowerCase()
                      ? 'tex_log_white'
                      : 'text_logo_white_sharingood',
                }}
                resizeMode="contain"
              />
            </ImageBackground>
          </View>

          {!R.isEmpty(rewardWallet) && (
            <View style={s.backgroundContainer}>
              <ImageBackground
                style={s.balanceBackground}
                imageStyle={s.walletImage}
                source={isWeb ? rewards_wallet_background : { uri: 'rewards_wallet_background' }}
                resizeMode="cover"
              >
                <View style={s.balanceContainer}>
                  <Text color={currentTheme.colors.secondary} semiBold>
                    {strings.bank.reward_allowance}
                  </Text>
                  <Text medium bold color={currentTheme.colors.secondary}>
                    {numberWithCommas(
                      Number(rewardWallet.balance_amount) - Number(rewardWallet.hold_balance),
                    )}
                  </Text>
                </View>
              </ImageBackground>
            </View>
          )}
        </View>
      </View>
    </Fragment>
  );
};

UserInfoHeader.propTypes = {
  userInfo: T.object,
  wallet: T.object,
  rewardWallet: T.object,
  userLocations: T.array,
};

export default connect((state) => ({
  userInfo: getUserInfo(state),
  wallet: state.wallets.wallet,
  rewardWallet: state.wallets.rewardWallet,
  userLocations: userLocationsSelectors.getUserLocations(state),
}))(UserInfoHeader);
