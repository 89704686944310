/* eslint-disable max-len */

export const TERMS_AND_CONDITIONS_URL_EN = 'https://www.shareitt.com/termsandconditions';
export const TERMS_AND_CONDITIONS_URL_HE = 'https://www.shareitt.co.il/termsandconditions';
export const TERMS_AND_CONDITIONS_URL_BR = 'https://www.shareitt.com/termsandconditions?lang=pt';
export const TERMS_AND_CONDITIONS_URL_DE = 'https://www.shareitt.ch/termsandconditions?lang=de';

export const PRIVACY_POLICY_URL_EN = 'https://www.shareitt.com/privacypolicy';
export const PRIVACY_POLICY_URL_HE = 'https://www.shareitt.co.il/privacypolicy';
export const PRIVACY_POLICY_URL_BR = 'https://www.shareitt.com/privacypolicy?lang=pt';
export const PRIVACY_POLICY_URL_DE = 'https://www.shareitt.ch/privacypolicy?lang=de';
export const BOXIT_SIGN_UP =
  'https://boxit.co.il/box2box/%D7%94%D7%A8%D7%A9%D7%9E%D7%94-%D7%9C%D7%A9%D7%99%D7%A8%D7%95%D7%AA/';
export const CALCULATE_SHIPPING_PRICE =
  'https://www.israelpost.co.il/npostcalc.nsf/Calculator2NOHE';
export const CALCULATE_SHIPPING_PRICE_NETHERLANDS = 'https://service.post.ch/vsc/ui/sending-type';
export const FAQ = 'https://www.shareitt.com/faq';
export const CONTACT_US = 'https://m.facebook.com/shareittIsrael';
export const CONTACT_US_BRAZIL = 'mailto:suporte@shareitt.com';
export const PRICE_CALCULATOR_URL_HE = 'https://www.israelpost.co.il/npostcalc.nsf/Calculator2NOHE';
export const PRICE_CALCULATOR_URL_BR = 'https://www2.correios.com.br/sistemas/precosprazos';
export const PRICE_CALCULATOR_URL_DE = 'https://service.post.ch/vsc/ui/sending-type';
