import { StyleSheet } from 'react-native';
import { dimensions, colors, rs } from '../../../../../../styles';
import { isAndroid } from '../../../../../../utils/detectDevice';

export default StyleSheet.create({
  contentContainer: {
    width: '100%',
    paddingTop: dimensions.medium,
    paddingBottom: dimensions.medium,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.white,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    ...rs.shadow,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingTop: dimensions.doubleMedium,
    marginBottom: dimensions.doubleMedium,
  },
  followersButtonContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'transparent',
    paddingHorizontal: dimensions.medium,
    paddingTop: dimensions.doubleMedium,
    paddingBottom: dimensions.medium,
    justifyContent: 'space-around',
  },
  verticalSeparator: {
    height: '100%',
    width: dimensions.hairline,
    backgroundColor: colors.grayBorder,
  },
  credibilityContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  credibilityImage: {
    height: 24,
    width: 24,
    marginRight: 10,
  },
  unfollowButton: {
    height: 26,
    width: 40,
    borderRadius: 4,
    marginRight: dimensions.halfMedium,
    borderWidth: 1,
    borderColor: colors.grayLight,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsEmptyPlaceholder: {
    height: 26,
  },
  infoBoxContainer: {
    backgroundColor: colors.white,
    marginLeft: dimensions.halfMedium + 24,
    marginRight: dimensions.medium,
    marginBottom: dimensions.medium,
    marginTop: 7,
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.17,
    shadowRadius: 3,
    elevation: 4,
  },
  infoBoxMainContainer: {
    flex: 1,
    borderRadius: 5,
    backgroundColor: colors.white,
    paddingLeft: dimensions.medium,
    paddingRight: dimensions.doubleMedium,
    paddingVertical: dimensions.medium,
  },
  infoBoxTriangle: {
    zIndex: -100,
    position: 'absolute',
    top: -9,
    transform: [{ scaleX: 2 }],
    left: dimensions.medium,
    textShadowColor: `rgba(0, 0, 0, ${isAndroid ? 0.8 : 0.2})`,
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 3,
  },
  infoBoxCloseIcon: {
    position: 'absolute',
    top: 10,
    right: 8,
  },
  infoBoxLinkText: {
    textDecorationLine: 'underline',
  },

  /** loading placeholders */
  placeholderContainer: {
    height: 220,
  },
  placeholderButtonsContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    marginTop: 20,
  },
});
