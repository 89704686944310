import { I18nManager, Platform, StyleSheet } from 'react-native';
import { medium, halfMedium } from './dimensions';
import colors from './colors';
import { isIphoneX } from '../utils/detectDevice';

export default StyleSheet.create({
  flex: {
    flex: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifyBetween: {
    justifyContent: 'space-between'
  },
  alignCenter: {
    alignItems: 'center',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  alignBaseline: {
    alignItems: 'baseline',
  },
  absolute: {
    position: 'absolute',
  },
  rtlScale: {
    transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
  },
  marginBottom: {
    marginBottom: medium,
  },
  bigMarginBottom: {
    marginBottom: medium * 2,
  },
  veryBigMarginBottom: {
    marginBottom: medium * 3,
  },
  smallMarginBottom: {
    marginBottom: halfMedium,
  },
  verySmallMarginBottom: {
    marginBottom: 4,
  },
  marginTop: {
    marginTop: medium,
  },
  bigMarginTop: {
    marginTop: medium * 2,
  },
  veryBigMarginTop: {
    marginTop: medium * 3,
  },
  smallMarginTop: {
    marginTop: halfMedium,
  },
  marginLeft: {
    marginLeft: medium,
  },
  smallMarginLeft: {
    marginLeft: halfMedium,
  },
  bigMarinLeft: {
    marginLeft: medium * 2,
  },
  marginRight: {
    marginRight: medium,
  },
  smallMarginRight: {
    marginRight: halfMedium,
  },
  bigMarinRight: {
    marginRight: medium * 2,
  },
  marginVertical: {
    marginVertical: medium,
  },
  bigMarginVertical: {
    marginVertical: medium * 2,
  },
  smallMarginVertical: {
    marginVertical: halfMedium,
  },
  verySmallMarginVertical: {
    marginVertical: 4,
  },
  marginHorizontal: {
    marginHorizontal: medium,
  },
  smallMarginHorizontal: {
    marginHorizontal: halfMedium,
  },
  bigMarginHorizontal: {
    marginHorizontal: medium * 2,
  },
  bigPaddingBottom: {
    paddingBottom: medium * 2,
  },
  paddingBottom: {
    paddingBottom: medium,
  },
  smallPaddingBottom: {
    paddingBottom: halfMedium,
  },
  paddingTop: {
    paddingTop: medium,
  },
  bigPaddingTop: {
    paddingTop: medium * 2,
  },
  smallPaddingTop: {
    paddingTop: halfMedium,
  },
  verySmallPaddingTop: {
    paddingTop: 4,
  },
  paddingLeft: {
    paddingLeft: medium,
  },
  smallPaddingLeft: {
    paddingLeft: halfMedium,
  },
  verySmallPaddingLeft: {
    paddingLeft: 4,
  },
  paddingRight: {
    paddingRight: medium,
  },
  smallPaddingRight: {
    paddingRight: halfMedium,
  },
  verySmallPaddingRight: {
    paddingRight: 4,
  },
  paddingVertical: {
    paddingVertical: medium,
  },
  smallPaddingVertical: {
    paddingVertical: halfMedium,
  },
  verySmallPaddingVertical: {
    paddingVertical: 4,
  },
  bigPaddingVertical: {
    paddingVertical: medium * 2,
  },
  paddingHorizontal: {
    paddingHorizontal: medium,
  },
  bigPaddingHorizontal: {
    paddingHorizontal: medium * 2,
  },
  smallPaddingHorizontal: {
    paddingHorizontal: halfMedium,
  },
  verySmallPaddingHorizontal: {
    paddingHorizontal: 4,
  },
  tabBarPadding: {
    paddingBottom: isIphoneX ? 100 : 80,
  },
  backgroundWhite: {
    backgroundColor: colors.white,
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  backgroundGray: {
    backgroundColor: colors.grayBackground,
  },
  shadow: Platform.select({
    ios: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.18,
      shadowRadius: 1.0,
    },
    android: {
      elevation: 4,
    },
  }),
  webContainer: {
    width: '100%',
    maxWidth: 1200,
    marginHorizontal: 'auto',
  },
});
