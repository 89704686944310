import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';
import { Circle, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { connect } from 'react-redux';
import LoadingService from '../../../services/LoadingService';
import MapCenter from '../../../assets/web/images/map_marker.png';
import { ButtonContainer, CancelButton, ApplyButton, CancelText, ApplyText } from './styles';
import { lotsOperations } from '../../../store/lots';
import { colors } from '../../../styles';
import { GOOGLE_AUTOCOMPLETE_API_KEY } from '../../../../config/google';
import strings from '../../../localization';
import { useGetWindowCurrentWidth } from '../../../utils/getWindowCurrentWidth';
import { getCurrentTheme } from '../../../templates';

const formatCoordinate = (coordinate) => {
  // seventh decimal is worth up to 11mm of location accuracy
  const COORDINATE_ACCURACY = 7;
  return parseFloat(coordinate.toFixed(COORDINATE_ACCURACY));
};

const LocationView = ({ navigation, route, location, saveLocation }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [city, setCity] = useState('');
  const [formatted_address, setFormatted_address] = useState(null);
  const [value, setValue] = useState(null);

  const { windowCurrentWidth } = useGetWindowCurrentWidth();

  const currentTheme = getCurrentTheme();

  useEffect(() => {
    const locationToEdit = route.params.location;

    if (locationToEdit) {
      setLatitude(locationToEdit.latitude);
      setLatitude(locationToEdit.latitude);
      setLongitude(locationToEdit.longitude);
      setCity(locationToEdit.city);
      setFormatted_address(locationToEdit.formatted_address);
      setValue({
        label: locationToEdit.formatted_address,
        value: {
          description: locationToEdit.formatted_address,
        },
      });
    } else if (location.city) {
      setLatitude(location.latitude);
      setLongitude(location.longitude);
    }
  }, []);

  useEffect(() => {
    if (value) {
      setCoordinates(value);
    }
  }, [value]);

  const setCoordinates = async (value) => {
    LoadingService.showLoader();
    const geocode = await geocodeByAddress(value.label)
      .then((result) => getLatLng(result[0]))
      .then((lanLng) => lanLng)
      .catch((error) => console.log(error));

    setCity(value?.value?.description);
    setFormatted_address(value.label);
    setLatitude(formatCoordinate(geocode.lat) ?? null);
    setLongitude(formatCoordinate(geocode.lng) ?? null);
    LoadingService.hideLoader();
  };

  const applyLocation = async () => {
    LoadingService.showLoader();

    const selectLocations = route.params.selectLocations;
    const locationToEdit = route.params.location;
    const locationToSave = locationToEdit
      ? { ...locationToEdit, ...{ latitude, longitude, city, formatted_address } }
      : { latitude, longitude, city, formatted_address };

    if (latitude && selectLocations) {
      await selectLocations(locationToSave);
      navigation.goBack();
    } else if (latitude) {
      saveLocation(locationToSave);
      navigation.pop();
    }

    LoadingService.hideLoader();
  };

  const cancel = () => {
    navigation.pop();
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_AUTOCOMPLETE_API_KEY, // ,
    // ...otherOptions
  });

  return (
    <View style={{ flex: 1 }}>
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_AUTOCOMPLETE_API_KEY}
        minLengthAutocomplete={2}
        apiOptions={{
          language: 'en',
        }}
        selectProps={{
          value,
          onChange: setValue,
        }}
        debounce={200}
      />

      <GoogleMap // https://react-google-maps-api-docs.netlify.app/#marker
        zoom={longitude ? 13 : 10}
        mapContainerStyle={{ flex: 1, zIndex: 0 }}
        center={{
          lat: latitude || 31.0461,
          lng: longitude || 34.8516,
          latitudeDelta: 0.05,
          longitudeDelta: 0.05,
        }}
      >
        <Circle
          visible={!!longitude}
          center={{
            lat: latitude,
            lng: longitude,
          }}
          options={{
            strokeColor: 'rgb(84,189,249)',
            fillColor: 'rgba(158,206,234,0.5)',
          }}
          radius={2000}
        />
        <Marker
          visible={!!longitude}
          position={{
            lat: latitude,
            lng: longitude,
          }}
          icon={{
            url: `${MapCenter}`,
            scaledSize: {
              height: 10,
              width: 10,
            },
          }}
          zIndex={1000}
        />
      </GoogleMap>

      <ButtonContainer width={windowCurrentWidth}>
        <CancelButton onPress={cancel} width={windowCurrentWidth * 0.4}>
          <CancelText>{strings.common.cancel}</CancelText>
        </CancelButton>
        <ApplyButton
          width={windowCurrentWidth * 0.4}
          style={city !== '' ? { backgroundColor: currentTheme.colors.secondary } : null}
          onPress={applyLocation}
        >
          <ApplyText style={city !== '' ? { color: colors.white } : null}>
            {strings.common.apply}
          </ApplyText>
        </ApplyButton>
      </ButtonContainer>
    </View>
  );
};

export default connect(
  (state) => ({
    location: state.lots.location,
  }),
  (dispatch) => ({
    saveLocation: (args) => dispatch(lotsOperations.saveLocation(args)),
  }),
)(LocationView);
