import React from 'react';
import { StyleSheet } from 'react-native';
import Text from '../Text';
import TouchableItem from '../TouchableItem';
import { colors, dimensions } from '../../../styles';
import { getCurrentTheme } from '../../../templates';

const s = StyleSheet.create({
  button: {
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: dimensions.medium,
    borderRadius: 4,
    marginHorizontal: dimensions.medium,
  },
  buttonText: {
    color: colors.white,
  },
});

const Button = ({ disabled, title, onPress, withMarginRight, withSmallPadding, dark, color }) => {
  const currentTheme = getCurrentTheme();
  return (
    <TouchableItem
      useOpacity={false}
      disabled={disabled}
      withMarginRight={withMarginRight}
      withSmallPadding={withSmallPadding}
      onPress={onPress}
      dark={dark}
      color={color}
      style={[
        s.button,
        {
          paddingHorizontal: withSmallPadding ? dimensions.medium : dimensions.doubleMedium,
          backgroundColor: disabled
            ? colors.grayLighter
            : dark
            ? colors.white
            : color && !disabled
            ? color
            : currentTheme.colors.secondary,
          borderColor: dark
            ? colors.gray
            : color && !disabled
            ? color
            : currentTheme.colors.secondary,
          marginRight: withMarginRight ? dimensions.medium : 0,
        },
      ]}
    >
      <Text
        medium
        bold
        disabled={disabled}
        dark={dark}
        style={{
          color: disabled || dark ? colors.text : colors.white,
        }}
      >
        {title}
      </Text>
    </TouchableItem>
  );
};

export default Button;
