import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import { Marker } from '@react-google-maps/api';
import { Image } from '../../../../ReusableComponents';
import { colors, rs } from '../../../../../styles';

const s = StyleSheet.create({
  marker: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: colors.grayLighter,
    borderWidth: 3,
    borderColor: colors.white,
    ...rs.shadow,
  },
  markerActive: {
    borderColor: colors.blueLighter,
  },
});

const UserMarker = memo(({ location, id, profileImage, isActive, onPress }) => {
  return (
    <Marker
      key={id}
      style={[s.marker]}
      tracksViewChanges={false}
      position={{
        lng: location.longitude,
        lat: location.latitude,
      }}
      onClick={onPress}
      zIndex={isActive ? 1000 : 0}
      icon={`${profileImage}`}
      options={{
        icon: {
          url: profileImage,
        },
      }}
    >
      <Image
        source={{ uri: profileImage }}
        resizeMode="cover"
        style={[s.marker, isActive && s.markerActive]}
      />
    </Marker>
  );
});

export default UserMarker;
