import React, { Component, Fragment } from 'react';
import {
  Animated,
  View,
  Image,
  Dimensions,
  Platform,
  StatusBar,
  StyleSheet,
  PanResponder,
  TouchableWithoutFeedback,
} from 'react-native';
import { connect } from 'react-redux';
import strings from '../../../localization';
import { isIphoneX } from '../../../utils/detectDevice';
import { getIsImageLink } from '../../../utils/stringHelper';
import toastTypes from '../../../constants/toastTypes';
import { notificationOperations } from '../../../store/notifications';
import Text from '../Text';
import { dimensions, colors } from '../../../styles';
import { FastImage } from '../index';

const { width: deviceWidth, height: deviceHeight } = Dimensions.get('window');

const CONTAINER_MARGIN_TOP = Platform.OS === 'ios' ? (isIphoneX ? 48 : 24) : dimensions.halfMedium;

const slideOffsetYToTranslatePixelMapping = {
  inputRange: [0, 1],
  outputRange: [-150, 0],
};

const HORIZONTAL_MARGIN = dimensions.halfMedium;

const getAnimatedContainerStyle = ({
  containerSlideOffsetY,
  containerDragOffsetY,
  containerScale,
}) => {
  const slideInAnimationStyle = {
    transform: [
      {
        translateY: containerSlideOffsetY.interpolate(slideOffsetYToTranslatePixelMapping),
      },
      { translateY: containerDragOffsetY },
      { scale: containerScale },
    ],
  };

  const animatedContainerStyle = [styles.popupContainer, slideInAnimationStyle];

  return animatedContainerStyle;
};

class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,

      containerSlideOffsetY: new Animated.Value(0),
      slideOutTimer: null,

      containerDragOffsetY: new Animated.Value(0),

      containerScale: new Animated.Value(1),

      onPressAndSlideOut: null,
      headerIconUrl: null,
      headerTitle: null,
      title: null,
      body: null,
    };
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (e, gestureState) => true,
      onMoveShouldSetPanResponder: (e, gestureState) => true,
      onPanResponderGrant: this._onPanResponderGrant,
      onPanResponderMove: this._onPanResponderMove,
      onPanResponderRelease: this._onPanResponderRelease,
    });
  }

  _onPanResponderGrant = (e, gestureState) => {
    this.onPressInFeedback();
  };

  _onPanResponderMove = (e, gestureState) => {
    const { containerDragOffsetY } = this.state;
    const newDragOffset = gestureState.dy < 100 ? gestureState.dy : 100;
    containerDragOffsetY.setValue(newDragOffset);
  };

  _onPanResponderRelease = (e, gestureState) => {
    const { onPressAndSlideOut, containerDragOffsetY } = this.state;

    this.onPressOutFeedback();

    if (
      gestureState.dy <= 2 &&
      gestureState.dy >= -2 &&
      gestureState.dx <= 2 &&
      gestureState.dx >= -2
    ) {
      onPressAndSlideOut();
    }

    if (containerDragOffsetY._value < -30) {
      this.slideOutAndDismiss(200);
    } else {
      this.clearTimerIfExist();
      Animated.timing(containerDragOffsetY, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(({ finished }) => {
        this.countdownToSlideOut();
      });
    }
  };

  getBodyText = () => {
    const { body } = this.state;

    if (!body) {
      return '';
    }

    const isImageLink = getIsImageLink(body);

    if (isImageLink) {
      return strings.common.photo;
    }

    return body;
  };

  render() {
    const {
      show,
      containerSlideOffsetY,
      containerDragOffsetY,
      containerScale,
      onPressAndSlideOut,
      headerIconUrl,
      headerTitle,
      title,
      body,
    } = this.state;

    if (!show) {
      return null;
    }

    const bodyText = this.getBodyText(body);

    return (
      <Animated.View
        style={getAnimatedContainerStyle({
          containerSlideOffsetY,
          containerDragOffsetY,
          containerScale,
        })}
        {...this._panResponder.panHandlers}
      >
        {
          {
            [toastTypes.NOTIFICATION]: (
              <TouchableWithoutFeedback onPress={onPressAndSlideOut}>
                <Fragment>
                  {!!headerTitle && (
                    <View style={styles.popupHeaderContainer}>
                      <View style={styles.headerIconContainer}>
                        <FastImage style={styles.headerIcon} source={{ uri: headerIconUrl }} />
                      </View>
                      <View style={styles.headerTextContainer}>
                        <Text style={styles.headerText} numberOfLines={1}>
                          {headerTitle || ''}
                        </Text>
                      </View>
                    </View>
                  )}
                  <View style={styles.contentContainer}>
                    {!!title && (
                      <View style={styles.contentTitleContainer}>
                        <Text semiBold style={styles.contentTitle}>
                          {title}
                        </Text>
                      </View>
                    )}
                    <View style={styles.contentTextContainer}>
                      <Text semiBold style={styles.contentText}>
                        {bodyText}
                      </Text>
                    </View>
                  </View>
                </Fragment>
              </TouchableWithoutFeedback>
            ),
            [toastTypes.ERROR]: (
              <View style={styles.errorContentContainer}>
                <View style={styles.contentTitleContainer}>
                  <Text style={styles.errorContentTitle}>{title || ''}</Text>
                </View>
              </View>
            ),
            [toastTypes.SUCCESS]: (
              <View style={styles.successContentContainer}>
                <View style={styles.contentTitleContainer}>
                  <Text semiBold style={styles.successContentTitle}>
                    {title || ''}
                  </Text>
                </View>
              </View>
            ),
          }[this.state.type]
        }
      </Animated.View>
    );
  }

  onPressInFeedback = () => {
    const { containerScale } = this.state;
    Animated.spring(containerScale, { toValue: 0.95, friction: 8, useNativeDriver: true }).start();
  };

  onPressOutFeedback = () => {
    const { containerScale } = this.state;
    Animated.spring(containerScale, { toValue: 1, friction: 8, useNativeDriver: true }).start();
  };

  createOnPressWithCallback = (callback) => () => {
    this.slideOutAndDismiss(200);

    if (callback) {
      callback();
    }
  };

  clearTimerIfExist = () => {
    const { slideOutTimer } = this.state;
    if (slideOutTimer) {
      clearTimeout(slideOutTimer);
    }
  };

  slideIn = (duration) => {
    const { containerSlideOffsetY } = this.state;
    Animated.timing(containerSlideOffsetY, {
      toValue: 1,
      duration: duration || 400,
      useNativeDriver: true,
    }).start(({ finished }) => {
      this.countdownToSlideOut();
    });
  };

  countdownToSlideOut = () => {
    const slideOutTimer = setTimeout(() => {
      this.slideOutAndDismiss();
    }, 3000);
    this.setState({ slideOutTimer });
  };

  slideOutAndDismiss = (duration) => {
    const { containerSlideOffsetY } = this.state;
    Animated.timing(containerSlideOffsetY, {
      toValue: 0,
      duration: duration || 400,
      useNativeDriver: true,
    }).start(({ finished }) => {
      this.setState({ show: false });
    });
  };

  show = (messageConfig) => {
    this.clearTimerIfExist();
    const _messageConfig = messageConfig || {};
    const { type, onPress, headerIconUrl, headerTitle, title, body, data } = _messageConfig;

    const onPressCallback =
      type === toastTypes.NOTIFICATION ? () => this.props.onPressNotification(data) : null;

    const onPressAndSlideOut = this.createOnPressWithCallback(onPress || onPressCallback);
    this.setState(
      {
        show: true,
        containerSlideOffsetY: new Animated.Value(0),
        slideOutTimer: null,
        containerDragOffsetY: new Animated.Value(0),
        containerScale: new Animated.Value(1),
        type,
        onPressAndSlideOut,
        headerIconUrl,
        headerTitle,
        title,
        body,
      },
      this.slideIn,
    );
  };
}

export default connect(
  null,
  (dispatch) => ({
    onPressNotification: (notificationData) =>
      dispatch(notificationOperations.onPressNotification(notificationData)),
  }),
  null,
  { forwardRef: true },
)(Toast);

const styles = StyleSheet.create({
  popupContainer: {
    position: 'absolute',
    minHeight: 56,
    width: deviceWidth - HORIZONTAL_MARGIN * 2,
    left: HORIZONTAL_MARGIN,
    right: HORIZONTAL_MARGIN,
    top: CONTAINER_MARGIN_TOP,
    backgroundColor: colors.white,
    borderRadius: 12,
    zIndex: 1000,
    maxWidth: 500,
    marginHorizontal: 'auto',
    elevation: 2,
    shadowColor: '#000000',
    shadowOpacity: 0.5,
    shadowRadius: 3,
    shadowOffset: {
      height: 1,
      width: 0,
    },
  },
  popupHeaderContainer: {
    height: 32,
    backgroundColor: colors.grayLightest,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIconContainer: {
    height: 20,
    width: 20,
    marginLeft: dimensions.medium,
    marginRight: dimensions.halfMedium,
    borderRadius: 10,
  },
  headerIcon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  headerTextContainer: {
    flex: 1,
  },
  headerText: {
    lineHeight: 20,
  },
  contentContainer: {
    width: '100%',
    paddingTop: dimensions.halfMedium,
    paddingBottom: 12,
    paddingHorizontal: dimensions.medium,
  },
  errorContentContainer: {
    flex: 1,
    padding: dimensions.medium,
    backgroundColor: colors.error,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  successContentContainer: {
    flex: 1,
    paddingHorizontal: dimensions.medium,
    backgroundColor: colors.secondary,
    borderRadius: dimensions.borderRadius,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentTitleContainer: {},
  contentTitle: {},
  errorContentTitle: {
    color: colors.white,
    textAlign: 'center',
  },
  successContentTitle: {
    color: colors.white,
    textAlign: 'center',
  },
  contentTextContainer: {},
  contentText: {
    color: colors.gray,
    marginTop: 4,
  },
});
