import React, { Fragment, memo } from 'react';
import T from 'prop-types';
import { Image } from 'react-native';
import FastImage from '../../../../../../ReusableComponents/FastImage';
import { TouchableItem, Text, Icon } from '../../../../../../ReusableComponents';
import { colors } from '../../../../../../../styles';
import s from './style';
import { getCurrentTheme } from '../../../../../../../templates';

const SelectedMemberLabel = memo(({ name, nickname, profileImage, onPress }) => {
  const currentTheme = getCurrentTheme();
  return (
    <TouchableItem
      style={[s.container, { backgroundColor: currentTheme.colors.secondary }]}
      onPress={onPress}
    >
      <Fragment>
        <FastImage source={{ uri: profileImage }} style={s.profileImage} />

        <Text color={colors.white}>{name}</Text>

        {!!nickname && (
          <Text color={colors.grayLighter} style={s.nickname}>
            {`@${nickname}`}
          </Text>
        )}

        <Icon name="x" size={18} style={s.icon} color={colors.white} />
      </Fragment>
    </TouchableItem>
  );
});

SelectedMemberLabel.propTypes = {
  name: T.string.isRequired,
  profileImage: T.string.isRequired,
  nickname: T.string,
  onPress: T.func,
};

SelectedMemberLabel.defaultProps = {
  nickname: null,
  onPress: null,
};

export default SelectedMemberLabel;
