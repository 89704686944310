export default {
  text: '#34494E',
  gray: '#777A7E',
  grayBorder: '#BFBFBF',
  grayLight: '#DBDCDD',
  grayLighter: '#F5F6F7',

  //deprecated
  grayLightest: '#F7F7F7',
  grayForTextInput: '#b7b7b7',
  grayBackground: '#FAFAFA',
  grayDarker: '#373737',
  // grayLight: '#D4D4D4',
  // grayLighter: '#EFF0F1',

  secondary: '#333951',

  blueLightest: '#E0F0FC',
  blueLighter: '#39BDDC',
  blueLight: '#189BC9',
  blue: '#2270c9',
  blueDarker: '#1c579a',
  blueDarkest: '#1A303D',

  violetLightest: '#abb6df',
  violetLight: '#6c8ae9',
  violet: '#A44AFE',

  orange: '#F28C3B',

  coral: '#E97881',

  green: '#59B88C',
  greenDark: '#079189',
  greenLightest: '#DDEADF',
  greenLightAndroid: '#1fb28a',
  greenAndroid: '#1a9274',
  darkCyan: '#5E7074',

  white: '#FFFFFF',
  black: '#131314',

  // toasts colors
  error: '#E97881',
  success: '#2ecc71',
  warning: '#f1c40f',
  info: '#3498db',

  red: '#e74c3c',
  redDarker: '#bf2926',

  PinkLight: '#ec9dac',
  GreenLight: '#5ddaa8',
  transparentBlack: '#00000040',
  transparentGray: '#37373710',
  transparent: 'transparent',

  chatBackground: '#FAFBFC',
  chatBubbleCurrentUser: '#A4E2F0',
  chatBubbleInterlocutor: '#EFF1F2',
  promotionBackground: '#08918A',

  dark200: '#CED4D5',
  dark700: '#34494E',
};
