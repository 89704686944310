import React from 'react';
import { connect } from 'react-redux';
import DeviceInfo from 'react-native-device-info';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { Alert, FlatList } from 'react-native';
import { MainContainer } from './styles';
import { Text, OptionItem } from '../../../ReusableComponents';
import { colors, rs } from '../../../../styles';
import { isIos, isWeb } from '../../../../utils/detectDevice';
import NavigationService from '../../../../services/NavigationService';
import strings from '../../../../localization';
import screens from '../../../../navigation/screens';
import { ENV } from '../../../../../config/env';
import { authOperations } from '../../../../store/auth';
import RNRestart from 'react-native-restart';
import { communitySelectors } from '../../../../store/communityInfo';
import { AnalyticsService } from '../../../../services';
import * as analyticsEventTypes from '../../../../constants/analyticsEventTypes';

const BUILD_NUMBER = DeviceInfo.getBuildNumber();

@connectActionSheet
class Settings extends React.Component {
  onPressListItem = (key) => {
    const { logOut } = this.props;

    switch (key) {
      case 'members':
        NavigationService.navigate(screens.Members);
        break;
      case 'member_requests':
        NavigationService.navigate(screens.MemberRequests);
        break;
      case 'community_notifications':
        NavigationService.navigate(screens.SendNotifications);
        break;
      case 'item_rules':
        NavigationService.navigate(screens.ItemRules);
        break;
      case 'language':
        NavigationService.navigate(screens.Language);
        break;
      case 'my_address':
        NavigationService.navigate(screens.UserLocations);
        break;
      case 'leave':
        NavigationService.navigate(screens.LeaveCommunity);
        break;
      case 'bank':
        NavigationService.navigate(screens.Bank);
        break;
      case 'issue_tokens':
        NavigationService.navigate(screens.IssueToken);
        break;
      case 'send_tokens':
        NavigationService.navigate(screens.SendTokensToBank);
        break;
      case 'edit':
        NavigationService.navigate(screens.EditGroup);
        break;
      case 'notification':
        if (isIos) {
          this.alertForNotificationsPermission();
        }
        break;
      case 'token_exchange':
        NavigationService.navigate(screens.TokensExchange);
        break;
      case 'promoCode':
        NavigationService.navigate(screens.PromoCode);
        break;
      case 'terms_and_conditions':
        NavigationService.navigate(screens.TermsAndConditions);
        AnalyticsService.logEvent(analyticsEventTypes.onboarding_terms_and_conditions);
        break;
      case 'privacy_policy':
        NavigationService.navigate(screens.PrivacyPolicy);
        AnalyticsService.logEvent(analyticsEventTypes.onboarding_privacy_policy);
        break;
      case 'logout':
        logOut();
        RNRestart.Restart();
        break;
      case 'deactivate_account':
        this.onDeleteAccountPress();
        break;
      default:
        return null;
    }
  };

  onDeleteAccountPress = () => {
    Alert.alert(strings.undone_action_warning.title, strings.undone_action_warning.body, [
      {
        text: strings.common.cancel,
        onPress: () => console.log('Cancelled'),
        style: 'cancel',
      },
      {
        text: strings.common.confirm,
        onPress: () => {
          this.props.deleteUserAccount();
          this.props.logOut();
          RNRestart.Restart();
        },
      },
    ]);
  };

  render() {
    const { isCommunityAdmin, communityInfoId } = this.props;
    const list = [
      // {
      //   key: 'member_requests',
      //   name: strings.members.member_requests,
      //   iconName: 'members',
      //   isRightIcon: true,
      //   access: 'Admin',
      // },
      // {
      //   key: 'bank',
      //   name: strings.settings.tokens_bank,
      //   iconName: 'Settings_tokens-bank',
      //   access: 'Admin',
      // },
      {
        key: 'issue_tokens',
        name: strings.bank.issue_token,
        iconName: 'Settings_tokens-bank',
        access: 'Admin',
      },
      {
        ...(communityInfoId === 1 &&
          isCommunityAdmin && {
            key: 'send_tokens',
            name: strings.bank.transfer_tokens,
            iconName: 'Settings_tokens-bank',
            access: 'All',
          }),
      },
      {
        key: 'my_address',
        name: strings.settings.your_addresses,
        iconName: 'Settings_Location',
        access: 'All',
      },
      // {
      //   key: 'edit',
      //   name: strings.settings.edit_community,
      //   iconName: 'pen',
      //   access: 'Admin',
      // },
      {
        key: 'language',
        name: strings.languages.language,
        iconName: 'Settings_language',
        isRightIcon: true,
        access: 'All',
      },
      {
        key: 'community_notifications',
        name: strings.settings.community_notifications,
        iconName: 'settings_community-notifications',
        isRightIcon: true,
        access: 'Admin',
      },
      {
        key: 'promoCode',
        name: strings.promo_codes.redeem_code,
        iconName: 'redeem_code',
        isRightIcon: true,
        access: 'All',
      },
      {
        key: 'terms_and_conditions',
        name: strings.login.terms_and_conditions,
        iconName: 'terms_and_conditions',
        isRightIcon: true,
        access: 'All',
      },
      {
        key: 'privacy_policy',
        name: strings.login.privacy_policy,
        iconName: 'privacy_policy',
        isRightIcon: true,
        access: 'All',
      },
      {
        key: 'deactivate_account',
        name: strings.settings.delete_account,
        iconName: 'leave-community',
        isRightIcon: true,
        access: 'All',
      },
      // {
      //   key: 'token_exchange',
      //   name: strings.tokens_exchange.title,
      //   iconName: 'transactions',
      //   isRightIcon: true,
      //   access: 'All',
      // },
    ];

    if (ENV !== 'production') {
      list.push({
        key: 'logout',
        name: 'Log out',
        iconName: 'leave-community',
        isRightIcon: true,
        access: 'All',
      });
    }

    return (
      <MainContainer>
        <FlatList
          bounces={false}
          data={list}
          renderItem={({ item }) => {
            if (isCommunityAdmin || item.access === 'All') {
              return (
                <OptionItem
                  title={item.name}
                  Icon={item.icon}
                  iconName={item.iconName}
                  onPress={() => this.onPressListItem(item.key)}
                  rightText={item.value}
                  textColor={item.textColor}
                />
              );
            }
            return null;
          }}
          contentContainerStyle={isWeb && rs.webContainer}
        />
      </MainContainer>
    );
  }
}

export default connect(
  (state) => ({
    isCommunityAdmin: communitySelectors.getIsUserAdmin(state),
    userInfo: state.userInfo,
    communityInfoId: communitySelectors.getCommunityId(state),
  }),
  (dispatch) => ({
    logOut: () => dispatch(authOperations.logOut()),
  }),
)(Settings);
