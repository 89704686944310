import React, { useState } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
import { Alert, Linking } from 'react-native';
import {
  View,
  Text,
  Container,
  HorizontalTokenSelector,
  StaticHeightButton,
  TextButton,
} from '../../../ReusableComponents';
import { colors, headerStyle, rs } from '../../../../styles/index';
import strings from '../../../../localization';
import s from './styles';
import { groupsOperations, groupsSelectors } from '../../../../store/groups';
import { INFO_EMAIL } from '../../../../constants/adminInfo';
import NavigationService from '../../../../services/NavigationService';
import screens from '../../../../navigation/screens';
import { isWeb } from '../../../../utils/detectDevice';
import { ModalsService } from '../../../../services';
import modalTypes from '../../../../constants/modalTypes';

const LeaveCommunity = ({ insideCommunityId, communities, communityEntities, leaveCommunity }) => {
  const [selectedCommunityId, setSelectedCommunityId] = useState(insideCommunityId);

  const communityName = communityEntities[selectedCommunityId].group_name;

  const onSelectCommunityId = (communityId) => {
    setSelectedCommunityId(communityId);
  };
  const onLeaveCommunity = () => {
    if (isWeb) {
      ModalsService.showModal(modalTypes.ALERT, {
        title: strings.settings.leave_community_title.replace('XX', communityName),
        confirmButtonTitle: strings.common.leave,
        cancelButtonTitle: strings.common.cancel,
        onSubmit: () => {
          leaveCommunity(selectedCommunityId);
          NavigationService.navigate(screens.FeedTab);
        },
      });
    } else {
      Alert.alert(strings.settings.leave_community_title.replace('XX', communityName), '', [
        {
          text: strings.common.leave,
          onPress: () => {
            leaveCommunity(selectedCommunityId);
            NavigationService.navigate(screens.FeedTab);
          },
        },
        {
          text: strings.common.cancel,
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
      ]);
    }
  };
  return (
    <Container>
      <Text medium style={[rs.paddingHorizontal, rs.paddingVertical]}>
        {strings.bank.select_community}
      </Text>
      <HorizontalTokenSelector
        isCommunitySelector
        communities={communities}
        selectedCommunityId={selectedCommunityId}
        onSelect={onSelectCommunityId}
      />

      <View style={s.mainContainer}>
        <Text medium>- {strings.leave_community.your_items_and_account_will_be_hidden}</Text>
        <Text medium style={rs.verySmallPaddingVertical}>
          - {strings.leave_community.your_account_will_be_placed_on_hold}
        </Text>
        <Text medium>- {strings.leave_community.all_item_requests_will_be_cancelled}</Text>
        <Text medium style={rs.verySmallPaddingVertical}>
          - {strings.leave_community.your_membership_may_be_restored}
        </Text>

        <View style={rs.bigMarginVertical}>
          <TextButton
            alignCenter
            title={strings.leave_community.contact_us_to_remove_account}
            onPress={() =>
              Linking.openURL(`mailto:${INFO_EMAIL}?subject=Request for remove account`)
            }
          />
        </View>

        <StaticHeightButton
          onPress={onLeaveCommunity}
          title={`${strings.common.leave} ${communityName}`}
        />
      </View>
    </Container>
  );
};

LeaveCommunity.propTypes = {
  communities: T.array,
  insideCommunityId: T.number,
  communityEntities: T.object,
  leaveCommunity: T.func,
};

export default connect(
  (state) => ({
    insideCommunityId:
      state.communities.insideCommunityId || groupsSelectors.getRandomActiveCommunityId(state),
    communityEntities: state.communities.communityEntities,
    communities: groupsSelectors.getActiveCommunitiesList(state),
  }),
  (dispatch) => ({
    leaveCommunity: (communityId) => {
      dispatch(groupsOperations.leaveCommunity(communityId));
    },
  }),
)(LeaveCommunity);
