import api from './api';

export default {
  getSubCommunitiesToJoin: (communityId) => api.get(`sub_communities/list/join/${communityId}`),
  getUserSubCommunities: () => api.get('sub_communities/list/user'),
  joinPublicSubCommunities: (subCommunityIds) =>
    api.post('sub_communities/join', { sub_community_ids: subCommunityIds }),
  askToJoinPrivateSubCommunity: (code) => api.post('sub_communities/askToJoin', { code }),
  leaveSubCommunity: (subCommunityId) =>
    api.post('sub_communities/leave', { sub_community_id: subCommunityId }),
};
