import api from './api';
import { OFFERS_LIMIT } from '../constants/listLimits';

export default {
  createOffer: (item_id) =>
    api.post('createNewOffer', {
      item_id,
    }),

  performAction: (offer_id, action_id, has_multiple_supply) =>
    api.post('performOfferAction', {
      offer_id,
      action_id,
      has_exchange_functionality: true,
      has_multiple_supply,
    }),

  performRefundBySeller: (offer_id, refund_type) =>
    api.post('performRefundBySeller', {
      offer_id,
      refund_type,
    }),

  getOffer: (offerId) => api.get(`getOffer/${offerId}`),

  // for buyer only one offer can be active for item
  getActiveOfferByItemId: (itemId) => api.get(`getActiveOfferByItemId/${itemId}`),

  getRecentOffers: (lastAccessDate) => api.get(`getRecentOffers/${lastAccessDate}`),

  getWishFulfillmentOffers: ({ skip = 0 }) =>
    api.post('getWishFulfillmentOffers', {
      skip,
      limit: OFFERS_LIMIT,
    }),

  getBuyingOffers: ({ statusIds, groupIds, searchTerm, skip = 0 }) =>
    api.post('getBuyingOffers', {
      status_ids: statusIds,
      group_ids: groupIds,
      search_term: searchTerm,
      skip,
      limit: OFFERS_LIMIT,
    }),

  getSellingOffers: ({ statusIds, groupIds, searchTerm, skip = 0 }) =>
    api.post('getSellingOffers', {
      status_ids: statusIds,
      group_ids: groupIds,
      search_term: searchTerm,
      skip,
      limit: OFFERS_LIMIT,
    }),

  getIsStoreReviewAvailable: () => api.get('getIsStoreReviewAvailable'),

  getOffersCounters: () => api.get('getOffersCounters'),

  archiveOffers: ({ selectedOffersIds }) =>
    api.put('offers/archiveConfirmedOffers', {
      ids: selectedOffersIds,
    }),
  archiveAllOffers: () => api.put('offers/archiveAllOffers'),
  unarchiveOffers: ({ selectedOffersIds }) =>
    api.put('offers/unarchiveOffers', {
      ids: selectedOffersIds,
    }),
  getArchivedOffers: ({ limit = OFFERS_LIMIT, offset = 0 }) =>
    api.get(`offers/getArchivedOffers?limit=${limit}&offset=${offset}`),
};
