import * as R from 'ramda';
import pWaitFor from 'p-wait-for';
import * as actions from './actions';
import itemApi from '../../api/item';
import adminApi from '../../api/admin';
import wishApi from '../../api/wish';
import { SAVE_LOCATION, ADD_ITEM_CATEGORIES } from './types';
import { getItemInfoById } from './selectors';
import {
  ToastsService,
  NavigationService,
  ModalsService,
  LoadingService,
  AnalyticsService,
} from '../../services';
import groupsApi from '../../api/groups';
import strings from '../../localization';
import screens from '../../navigation/screens';
import { ACCOUNT_SUSPENDED_BY_ADMIN } from '../../../config/appConstants/httpCodes';
import { normalize } from '../../utils/stateHelper';
import { ITEMS_LIMIT_TO_SELECT_SHIPPING, LOTS_LIMIT } from '../../constants/listLimits';
import modalTypes from '../../constants/modalTypes';
import itemTypes from '../../constants/itemTypes';
import * as analyticsEventTypes from '../../constants/analyticsEventTypes';
import { initialState } from './reducers';
import { getIsBrazilCommunity } from '../communityInfo/selectors';
import { Alert } from 'react-native';
import { INVALID_PRICE_MESSAGE } from '../../constants/createItem';

const getFilters = (store) => {
  const {
    withShipping,
    selectedCities,
    selectedCampuses,
    selectedSizes,
    selectedBrands,
    selectedConditions,
    selectedCategoryIds,
    distance,
    isSearchByFollowings,
    isSearchByFavorites,
    isSearchByDiscount,
    isSearchByPrimaryLocation,
    isOnlyFeaturedItems,
    isOnlyVerifiedSellers,
    isOnlyPublic,
    searchTerm,
    sortBy,
    priceRange,
    groupIds,
    coordinates,
    schoolExtension,
    isSearchOnlyTalent,
    isSearchOnlyItem,
  } = store.lots.feedLotFilters;

  return {
    withShipping,
    cities: selectedCities.map(R.prop('id')),
    campuses: selectedCampuses.map(R.prop('id')),
    sizes: selectedSizes,
    brands: selectedBrands.map(R.prop('id')),
    conditions: selectedConditions,
    categories: selectedCategoryIds,
    distance,
    isSearchByFollowings,
    isSearchByFavorites,
    isSearchByDiscount,
    isSearchByPrimaryLocation,
    isOnlyFeaturedItems,
    isOnlyVerifiedSellers,
    isOnlyPublic,
    searchTerm,
    sortBy,
    priceRange,
    groupIds,
    coordinates,
    schoolExtension,
    isSearchOnlyTalent,
    isSearchOnlyItem,
  };
};

export const getItems = (isRefresh) => async (dispatch, getState) => {
  const store = getState();
  const {
    userInfo,
    lots: { feedLotFilters },
  } = store;
  const userId = userInfo.id;

  if (isRefresh) dispatch(actions.itemsRefreshStart());
  else dispatch(actions.itemsStart());

  try {
    const { items, total, item_shippings_count } = await groupsApi.getGroupItemsByFilters({
      userId,
      ...getFilters(store),
    });

    const normalizedItems = normalize(items, 'itemIds', 'itemEntities');

    const isExistMore = items.length === LOTS_LIMIT;
    const isBrazilCommunity = getIsBrazilCommunity(store);

    dispatch(actions.itemsSuccess({ normalizedItems, isExistMore, total }));
    if (
      (feedLotFilters.isSearchByPrimaryLocation || feedLotFilters.coordinates) &&
      total < ITEMS_LIMIT_TO_SELECT_SHIPPING &&
      !isRefresh &&
      isBrazilCommunity
    ) {
      ModalsService.showModal(modalTypes.ADD_SHIPPING, {
        totalItemsCount: total,
        itemShippingCount: item_shippings_count?.count,
      });
    }
  } catch (err) {
    dispatch(actions.lotsError(err.message));
  }
};

export const getMoreItems = () => async (dispatch, getState) => {
  const store = getState();
  const { userInfo, lots } = store;
  const { itemIds, isLoadingItems, isLoadingMoreItems, isExistMoreItems } = lots;

  const userId = userInfo.id;
  const skip = itemIds.length;

  if (isLoadingItems || isLoadingMoreItems || !isExistMoreItems) return;

  dispatch(actions.itemsMoreStart());

  try {
    const { items } = await groupsApi.getGroupItemsByFilters({
      userId,
      skip,
      ...getFilters(store),
    });

    const normalizedItems = normalize(items, 'itemIds', 'itemEntities');

    const isExistMore = items.length === LOTS_LIMIT;

    dispatch(actions.itemsMoreSuccess({ normalizedItems, isExistMore }));
  } catch (err) {
    dispatch(actions.lotsError(err.message));
  }
};

export const getWishes = (isRefresh) => async (dispatch, getState) => {
  const { groups, userInfo, lots } = getState();
  const userId = userInfo.id;

  const { selectedCategoryIds } = lots.feedLotFilters;

  if (isRefresh) dispatch(actions.wishesRefreshStart());
  else dispatch(actions.wishesStart());

  try {
    const { wishes, total_wishes_count } = await wishApi.getWishes({
      userId,
      searchTerm: lots.feedWishFilters.searchTerm,
      groupIds: lots.feedWishFilters.groupIds,
      // categories: selectedCategoryIds,
    });

    const normalizedWishes = normalize(wishes, 'wishIds', 'wishEntities');

    const isExistMore = wishes.length === LOTS_LIMIT;

    dispatch(actions.wishesSuccess({ normalizedWishes, isExistMore, total: total_wishes_count }));
  } catch (err) {
    dispatch(actions.lotsError(err.message));
  }
};

export const getMoreWishes = () => async (dispatch, getState) => {
  const { groups, userInfo, lots } = getState();
  const { wishIds, isLoadingWishes, isLoadingMoreWishes, isExistMoreWishes, feedLotFilters } = lots;
  const userId = userInfo.id;
  const skip = wishIds.length;

  const { selectedCategoryIds } = feedLotFilters;

  if (isLoadingWishes || isLoadingMoreWishes || !isExistMoreWishes) return;

  dispatch(actions.wishesMoreStart());
  try {
    const { wishes } = await wishApi.getWishes({
      userId,
      searchTerm: lots.feedWishFilters.searchTerm,
      groupIds: lots.feedWishFilters.groupIds,
      // categories: selectedCategoryIds,
      skip,
    });

    const normalizedWishes = normalize(wishes, 'wishIds', 'wishEntities');

    const isExistMore = wishes.length === LOTS_LIMIT;

    dispatch(actions.wishesMoreSuccess({ normalizedWishes, isExistMore }));
  } catch (err) {
    dispatch(actions.lotsError(err.message));
  }
};

export const getUserSavedItems = ({ isLoadMore = false }) => async (dispatch, getState) => {
  let { lots } = getState();
  const { ids, isLoading, isLoadingMore, isExistMore } = lots.savedItems;

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.savedItemsStart({ isLoadMore }));

  try {
    const { items, items_count } = await itemApi.getUserSavedItems({
      skip: isLoadMore ? ids.length : 0,
    });

    const normalizedData = normalize(items, 'ids', 'entities');

    dispatch(
      actions.saveditemSuccess({
        isLoadMore,
        ids: normalizedData.ids,
        entities: normalizedData.entities,
        totalAmount: items_count,
        isExistMore: items.length === LOTS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.savedItemsError({ isLoadingMore }));
  }
};

export const getPendingItems = ({ isLoadMore = false }) => async (dispatch, getState) => {
  let { lots } = getState();
  const { ids, isLoading, isLoadingMore, isExistMore } = lots.pendingItems;

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.pendingItemsStart({ isLoadMore }));

  try {
    const { items, items_count } = await adminApi.getPendingItems({
      skip: isLoadMore ? ids.length : 0,
    });

    const normalizedData = normalize(items, 'ids', 'entities');

    dispatch(
      actions.pendingItemSuccess({
        isLoadMore,
        ids: normalizedData.ids,
        entities: normalizedData.entities,
        totalAmount: items_count,
        isExistMore: items.length === LOTS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.pendingItemsError({ isLoadingMore }));
  }
};

export const approvePendingItem = (itemId) => async (dispatch) => {
  try {
    dispatch(actions.removeItemFromPendingList({ itemId }));
    await adminApi.approvePendingItem(itemId);
  } catch (err) {}
};

export const declinePendingItem = (itemId) => async (dispatch) => {
  try {
    dispatch(actions.removeItemFromPendingList({ itemId }));
    await adminApi.declinePendingItem(itemId);
  } catch (err) {}
};

export const approveUserToUploadItems = (userId) => async (dispatch) => {
  try {
    await adminApi.approveUserToUploadItems(userId);
  } catch (err) {}
};

export const suspendUserToUploadItems = (userId) => async (dispatch) => {
  try {
    await adminApi.suspendUserToUploadItems(userId);
  } catch (err) {}
};

export const getUserActiveItems = ({
  userId,
  sortBy,
  communityIds,
  searchTerm = '',
  isLoadMore = false,
}) => async (dispatch, getState) => {
  let store = getState();

  const isInitializedUser = !!R.path(['lots', 'userLots', userId], store);

  if (!isInitializedUser) {
    dispatch(actions.initializeUserLotsState({ userId }));
    store = getState();
  }

  const { ids, isLoading, isLoadingMore, isExistMore } = R.path(
    ['lots', 'userLots', userId, 'userActiveItems'],
    store,
  );

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.userLotsStart({ userId, entityName: 'userActiveItems', isLoadMore }));

  try {
    const { items, items_count } = await itemApi.getUserItems({
      group_ids: communityIds,
      user_account_id: userId,
      type: itemTypes.ACTIVE,
      skip: isLoadMore ? ids.length : 0,
      sort_by: sortBy,
      search_term: searchTerm,
    });

    const normalizedData = normalize(items, 'ids', 'entities');

    dispatch(
      actions.userLotsSuccess({
        userId,
        entityName: 'userActiveItems',
        isLoadMore,
        ids: normalizedData.ids,
        entities: normalizedData.entities,
        totalAmount: items_count,
        isExistMore: items.length === LOTS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.userLotsError({ userId, entityName: 'userActiveItems', isLoadMore }));
  }
};

export const getUserSoldItems = ({ userId, sortBy, communityIds, isLoadMore = false }) => async (
  dispatch,
  getState,
) => {
  let store = getState();

  const isInitializedUser = !!R.path(['lots', 'userLots', userId], store);

  if (!isInitializedUser) {
    dispatch(actions.initializeUserLotsState({ userId }));
    store = getState();
  }

  const { items, isLoading, isLoadingMore, isExistMore } = R.path(
    ['lots', 'userLots', userId, 'userSoldItems'],
    store,
  );

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.userLotsStart({ userId, entityName: 'userSoldItems', isLoadMore }));

  try {
    const data = await itemApi.getUserItems({
      group_ids: communityIds,
      user_account_id: userId,
      type: itemTypes.SOLD,
      skip: isLoadMore ? items.length : 0,
      sort_by: sortBy,
    });

    dispatch(
      actions.userSoldItemsSuccess({
        userId,
        isLoadMore,
        items: data.items,
        totalAmount: data.items_count,
        isExistMore: data.items.length === LOTS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.userLotsError({ userId, entityName: 'userSoldItems', isLoadMore }));
  }
};

export const getUserPurchasedItems = ({
  userId,
  sortBy,
  communityIds,
  isLoadMore = false,
}) => async (dispatch, getState) => {
  let store = getState();

  const isInitializedUser = !!R.path(['lots', 'userLots', userId], store);

  if (!isInitializedUser) {
    dispatch(actions.initializeUserLotsState({ userId }));
    store = getState();
  }

  const { items, isLoading, isLoadingMore, isExistMore } = R.path(
    ['lots', 'userLots', userId, 'userPurchasedItems'],
    store,
  );

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.userLotsStart({ userId, entityName: 'userPurchasedItems', isLoadMore }));

  try {
    const data = await itemApi.getUserItems({
      group_ids: communityIds,
      user_account_id: userId,
      type: itemTypes.PURCHASED,
      skip: isLoadMore ? items.length : 0,
      sort_by: sortBy,
    });

    dispatch(
      actions.userPurchasedItemsSuccess({
        userId,
        isLoadMore,
        items: data.items,
        totalAmount: data.items_count,
        isExistMore: data.items.length === LOTS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.userLotsError({ userId, entityName: 'userPurchasedItems', isLoadMore }));
  }
};

export const getUserWishes = ({
  userId,
  sortBy,
  communityIds,
  isLoadMore = false,
  isCurrentUser = false,
}) => async (dispatch, getState) => {
  let store = getState();

  const entityName = 'userWishes';

  const isInitializedUser = !!R.path(['lots', 'userLots', userId], store);

  if (!isInitializedUser) {
    dispatch(actions.initializeUserLotsState({ userId }));
    store = getState();
  }

  const { ids, isLoading, isLoadingMore, isExistMore } = R.path(
    ['lots', 'userLots', userId, entityName],
    store,
  );

  if (isLoading || isLoadingMore || (isLoadMore && !isExistMore)) return;

  dispatch(actions.userLotsStart({ userId, entityName, isLoadMore }));

  try {
    const { wishes, wishes_count } = await wishApi.getUserWishes({
      group_ids: communityIds,
      user_account_id: userId,
      skip: isLoadMore ? ids.length : 0,
      sort_by: sortBy,
      is_current_user: isCurrentUser,
    });

    const normalizedData = normalize(wishes, 'ids', 'entities');

    dispatch(
      actions.userLotsSuccess({
        userId,
        entityName,
        isLoadMore,
        ids: normalizedData.ids,
        entities: normalizedData.entities,
        totalAmount: wishes_count,
        isExistMore: wishes.length === LOTS_LIMIT,
      }),
    );
  } catch (e) {
    dispatch(actions.userLotsError({ userId, entityName, isLoadMore }));
  }
};

export const resetUserLots = (userId) => async (dispatch, getState) => {
  dispatch(actions.resetUserLots({ userId }));
};

export const createNewItem = (item) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;
  try {
    const lot = await itemApi.saveNewItem({
      sellerId: userId,
      ...item,
    });
    dispatch(actions.createItemSuccess({ item: lot }));

    if (lot.wish_id) {
      AnalyticsService.logEvent(analyticsEventTypes.UPLOAD_WISH_FULFILLMENT_ITEM, {
        user_id: userId,
        item_id: lot.id,
        group_id: lot.group_id,
        city: lot.city,
        parent_category_id: lot.parent_category_id,
        wish_id: lot.wish_id,
      });
    } else {
      AnalyticsService.logEvent(analyticsEventTypes.UPLOAD_ITEM, {
        user_id: userId,
        item_id: lot.id,
        group_id: lot.group_id,
        city: lot.city,
        parent_category_id: lot.parent_category_id,
      });
    }

    return lot;
  } catch (err) {
    dispatch(actions.managementLotError(''));
    if (R.path(['response', 'status'], err) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }

    if (R.path(['response', 'data'], err).includes(INVALID_PRICE_MESSAGE)) {
      Alert.alert(
        strings.discounts.alert_price_higher_than_original_title,
        strings.discounts.alert_price_higher_than_original_text,
      );
    }
  }
};

export const createNewWish = (wish) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    const lot = await wishApi.createNewWish({
      wisherId: userId,
      ...wish,
    });

    dispatch(actions.createWishSuccess({ wish: lot }));

    AnalyticsService.logEvent(analyticsEventTypes.UPLOAD_WISH, {
      user_id: userId,
      wish_id: lot.id,
    });
  } catch (err) {
    dispatch(actions.managementLotError(err.response));
  }
};

export const createNewSkill = (item) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;
  try {
    const lot = await itemApi.saveNewSkill({
      sellerId: userId,
      ...item,
    });
    dispatch(actions.createItemSuccess({ item: lot }));

    AnalyticsService.logEvent(analyticsEventTypes.UPLOAD_SKILL, {
      user_id: userId,
      item_id: lot.id,
      group_id: lot.group_id,
      city: lot.city,
      parent_category_id: lot.parent_category_id,
    });

    return lot;
  } catch (err) {
    dispatch(actions.managementLotError(''));
    if (R.path(['response', 'status'], err) === ACCOUNT_SUSPENDED_BY_ADMIN) {
      NavigationService.reset(screens.SuspendedUser);
    }
  }
};

export const editWish = (wish) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  // dispatch(actions.managementLotStart());
  try {
    const updatedWish = await wishApi.editWish({
      wisherId: userId,
      ...wish,
    });
    dispatch(
      actions.updateWish({
        wish: {
          ...updatedWish,
          images: R.filter((el) => !R.isEmpty(el), wish.images),
          locations: wish.locations,
        },
      }),
    );
  } catch (err) {
    dispatch(actions.managementLotError(err.response));
    ToastsService.showError('Something went wrong when trying to edit wish');
  }
};

export const removeItem = ({ item_id, seller_id }) => async (dispatch) => {
  // for best user experience don't wait response from server
  dispatch(actions.removeItem({ itemId: item_id, sellerId: seller_id }));
  ToastsService.showSuccess(strings.success_messages.item_removed);
  try {
    await itemApi.deleteItem({ item_id, seller_id });
  } catch (err) {
    ToastsService.showError('Something went wrong when trying to remove item');
  }
};

export const removeSkill = ({ skill_id, seller_id }) => async (dispatch) => {
  dispatch(actions.removeItem({ itemId: skill_id, sellerId: seller_id }));
  ToastsService.showSuccess(strings.success_messages.skill_removed);
  try {
    await itemApi.deleteSkill({ skill_id, seller_id });
  } catch (err) {
    ToastsService.showError('Something went wrong when trying to remove skill');
  }
};

export const editSkill = (skill) => async (dispatch, getState) => {
  try {
    const { userInfo } = getState();
    const userId = userInfo.id;
    const updatedSkill = await itemApi.editSkill({
      sellerId: userId,
      skillId: skill.id,
      ...skill,
    });
    dispatch(
      actions.updateItem({
        item: {
          ...updatedSkill,
          images: R.filter((el) => !R.isEmpty(el), skill.images),
        },
      }),
    );
    return updatedSkill;
  } catch (err) {
    dispatch(actions.managementLotError(err.response));
    ToastsService.showError('Something went wrong when trying to edit skill');
  }
};

export const deactivateItem = ({ item_id, account_id }) => (dispatch) => {
  try {
    itemApi.deactivateItem({ item_id, account_id });
  } catch (err) {}
};

export const activateItem = ({ item_id, account_id }) => (dispatch) => {
  try {
    itemApi.activateItem({ item_id, account_id });
  } catch (err) {}
};

export const hideWish = (wishId) => (dispatch) => {
  try {
    wishApi.hideWish(wishId);
    dispatch(actions.removeWish({ wishId }));
  } catch (err) {}
};

export const unhideWish = (wishId) => (dispatch) => {
  try {
    wishApi.unhideWish(wishId);
  } catch (err) {}
};

export const removeWish = ({ wish_id, wisher_id }) => (dispatch) => {
  // for best user experience don't wait response from server
  dispatch(actions.removeWish({ wishId: wish_id, wisherId: wisher_id }));
  ToastsService.showSuccess(strings.success_messages.wish_removed);
  try {
    wishApi.deleteWish({ wish_id, wisher_id });
  } catch (err) {
    // ToastsService.showError('Something went wrong when trying to remove wish');
  }
};

export const editItem = (item) => async (dispatch, getState) => {
  const { groups, userInfo } = getState();
  const userId = userInfo.id;
  const communityId = groups.insideCommunityId;
  // dispatch(actions.managementLotStart());
  try {
    const updatedItem = await itemApi.editItem({
      groupId: communityId,
      sellerId: userId,
      ...item,
    });
    dispatch(
      actions.updateItem({
        item: {
          ...updatedItem,
          images: R.filter((el) => !R.isEmpty(el), item.images),
          // locations: item.locations,
        },
      }),
    );

    return updatedItem;
  } catch (err) {
    dispatch(actions.managementLotError(err.response));
    ToastsService.showError('Something went wrong when trying to edit item');
  }
};

export const getItemInfo = (id) => async (dispatch, getState) => {
  try {
    const item = await itemApi.getItemInfo(id);
    dispatch(actions.addItemEntity({ item }));
    return item;
  } catch (err) {
    // ToastsService.showError('Something went wrong when trying to get item details');
  }
};

export const getItemSnapshotByOfferId = (id) => async (dispatch, getState) => {
  try {
    const item = await itemApi.getItemSnapshotByOfferId(id);
    return item;
  } catch (err) {
    // ToastsService.showError('Something went wrong when trying to get item details');
  }
};

export const getWishInfo = (id) => async (dispatch) => {
  try {
    const wish = await wishApi.getWishInfo(id);

    dispatch(actions.addWishEntity({ wish }));
    return wish;
  } catch (err) {
    // ToastsService.showError('Something went wrong when trying to get item details');
  }
};

export const getWishDetails = (id) => async (dispatch) => {
  try {
    const wishDetails = await wishApi.getWishDetails(id);

    if (wishDetails.is_blocked_by_wisher) {
      dispatch(actions.resetWish(id));
      return;
    }

    dispatch(actions.updateWish({ wish: { id, ...wishDetails } }));
  } catch (err) {
    if (R.path(['response', 'status'], err) === 406) {
      ModalsService.showModal(modalTypes.USER_LEFT_COMMUNITY);
    }
  }
};

export const getItemDetails = (id) => async (dispatch, getState) => {
  const state = getState();

  try {
    const itemDetails = await itemApi.getItemDetails(id);

    if (itemDetails.is_blocked_by_seller) {
      dispatch(actions.resetItem(id));
      return;
    }

    dispatch(actions.updateItem({ item: { id, ...itemDetails } }));

    /* Check if item is connected to wish (contains wish_id)
       if yes fetch this wish info and add to store because
       need to show information about wish on ItemDetails Screen */
    const itemInfo = getItemInfoById(state, id);
    const itemWishId = itemInfo.wish_id;

    if (itemWishId) {
      dispatch(getWishInfo(itemWishId));
    }

    await dispatch(getItemDetailsLists(id));

    return itemDetails;
  } catch (err) {
    if (R.path(['response', 'status'], err) === 406) {
      ModalsService.showModal(modalTypes.USER_LEFT_COMMUNITY);
    }
  }
};

export const getItemDetailsLists = (itemId) => {
  return async (dispatch) => {
    const itemDetailsLists = await itemApi.getItemDetailsLists(itemId);
    dispatch(actions.updateItem({ item: { id: itemId, ...itemDetailsLists } }));
  };
};

export const activateSoldItem = (id) => async (dispatch, getState) => {
  const { userInfo, groups } = getState();

  try {
    LoadingService.showSuccessLoader();
    await itemApi.activateSoldItem(id);
    await dispatch(getItems());

    LoadingService.hideSuccessLoader({
      callback: () => {
        NavigationService.navigate(screens.FeedTab);
      },
    });
  } catch (err) {
    LoadingService.hideSuccessLoader({ isSuccess: false });
  }
};

/** Move logic to local state in the future */
export const saveLocation = (args) => (dispatch) =>
  dispatch({
    type: SAVE_LOCATION,
    location: args,
  });

export const saveSelectedCategories = (categories) => (dispatch) =>
  dispatch({
    type: ADD_ITEM_CATEGORIES,
    selectedCategory: categories,
  });

export const setFeedLotFilters = (feedLotFilters) => async (dispatch) => {
  dispatch(actions.setFeedLotFilters(feedLotFilters));
  dispatch(getItems());
};

export const replaceMarketplaceFiltersWithNew = (
  updatedFilters,
  isNeedToResetItems = false,
) => async (dispatch, getState) => {
  if (isNeedToResetItems) {
    await pWaitFor(() => !getState().lots.isLoadingItems);
    dispatch(actions.resetItems());
  }

  dispatch(
    actions.replaceMarketplaceFilterWithNew({ ...initialState.feedLotFilters, ...updatedFilters }),
  );
  dispatch(getItems());
};

export const setFeedWishFilters = (feedLotFilters) => async (dispatch) => {
  dispatch(actions.setFeedWishFilters(feedLotFilters));
  dispatch(getWishes());
};

export const setFilterGroupIds = (groupIds) => async (dispatch) => {
  dispatch(actions.setFilterGroupId({ groupIds }));
  dispatch(getItems());
};

export const addItemToFavorites = (itemId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    dispatch(actions.toggleItemFavorites({ itemId, isFavorite: false }));
    await itemApi.newFavoriteItem(userId, itemId);
  } catch (err) {
    // dispatch(actions.lotsMoreError(err.message));
  }
};

export const removeItemFromFavorites = (itemId) => async (dispatch, getState) => {
  const { userInfo } = getState();
  const userId = userInfo.id;

  try {
    dispatch(actions.toggleItemFavorites({ itemId, isFavorite: true }));
    await itemApi.unFavoriteItem(userId, itemId);
  } catch (err) {
    // dispatch(actions.lotsMoreError(err.message));
  }
};

export const markItemAsTop = (itemId) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    dispatch(actions.toggleTopItem({ itemId, isTop: true }));
    await itemApi.markItemAsTop({ itemId, communityId });
    ToastsService.showSuccess(strings.success_messages.item_was_marked_as_top);
  } catch (err) {
    // dispatch(actions.lotsMoreError(err.message));
  }
};

export const unmarkItemAsTop = (itemId) => async (dispatch, getState) => {
  const { communityInfo } = getState();
  const communityId = communityInfo.id;

  try {
    dispatch(actions.toggleTopItem({ itemId, isTop: false }));
    await itemApi.unmarkItemAsTop({ itemId, communityId });
    ToastsService.showSuccess(strings.success_messages.item_was_unmarked_as_top);
  } catch (err) {
    // dispatch(actions.lotsMoreError(err.message));
  }
};

export default {
  getMoreItems,
  getWishes,
  getMoreWishes,
  getItems,
  createNewItem,
  createNewSkill,
  createNewWish,
  removeItem,
  removeSkill,
  removeWish,
  hideWish,
  unhideWish,
  editItem,
  editSkill,
  editWish,
  getUserActiveItems,
  getUserWishes,
  resetUserLots,
  getWishDetails,
  getItemInfo,
  getItemSnapshotByOfferId,
  getWishInfo,
  getItemDetails,
  saveLocation,
  saveSelectedCategories,
  getUserSoldItems,
  getUserPurchasedItems,
  setFeedLotFilters,
  setFeedLotFiltersWithoutFetchingItems: actions.setFeedLotFilters,
  replaceMarketplaceFiltersWithNew,
  setFeedWishFilters,
  setFilterGroupIds,
  deactivateItem,
  activateItem,
  addItemToFavorites,
  removeItemFromFavorites,
  resetFeedLotFilters: actions.resetFeedLotFilters,
  itemWasAddedToFavorites: actions.itemWasAddedToFavorites,
  discountFavoriteItem: actions.discountFavoriteItem,
  itemWasSold: actions.itemWasSold,
  itemsStartLoading: actions.itemsStart,
  activateSoldItem,
  getUserSavedItems,
  getPendingItems,
  approvePendingItem,
  declinePendingItem,
  approveUserToUploadItems,
  suspendUserToUploadItems,
  markItemAsTop,
  unmarkItemAsTop,
};
